<div class="modal-body">
    <div class="column left">
        <label class="h4-heading" (click)="toogleSubGroups()"><em class="icon-arrow-right" *ngIf="showSub==false"></em><em class="icon-arrow-down" *ngIf="showSub==true"></em>{{title}}</label>
        <div class="sub-groups-container" *ngIf="showSub==true">
            <div class="row sub-group" *ngFor="let subGroup of subGroups" (click)="showEndGroups(subGroup)">
                <em class="icon-folder-closed"></em>
                <label class="sub-group-name">{{subGroup.name}}</label>
            </div>

            <ng-container *ngFor="let subfolder of myPorjectsSubfolders">
                <div class="folder ml-37"  (click)="selectShared(subfolder?.folderName)">
                    <hr class="subfolder-treelink">
                    <div class="d-flex flex-cont">
                        <em class="icon-folder-closed"></em>
                        <label class="fld-name">{{subfolder?.folderName}}</label>
                    </div>
                </div>
            </ng-container>
        </div>
        <label class="h4-heading" (click)="tooglePrivate()" *ngIf="privateSubGroup?.length!=0">
            <em class="icon-arrow-right" *ngIf="showPrivate==false"></em>
            <em class="icon-arrow-down" *ngIf="showPrivate==true"></em>
            {{currentCompany?.companyName}} {{title}}
        </label>
        <div class="sub-groups-container" *ngIf="showPrivate==true">
            <div class="row sub-group" *ngFor="let subGroup of privateSubGroup" (click)="showEndGroups(subGroup)">
                <em class="icon-folder-closed"></em>
                <label class="sub-group-name">{{subGroup.name}}</label>
            </div>
        </div>
    </div>
    <div class="column mid" *ngIf="title != 'Shared and imported products'">
        <div class="search-container">
            <div class="search-box">
            <em class="icon-search icon-search-left"></em>
            <input  type="text" class="input-search" [(ngModel)]="searchEGroup" (ngModelChange)="searchEndGroup()">
            </div>
        </div>
        <label class="select" *ngIf="!canShowEndGroups">Please select a {{title}} group...</label>
        <div class="end-groups-container"  *ngIf="canShowEndGroups&&searchEGroup.length==0">
            <ng-container *ngFor="let endGroup of endGroups ; let i=index">
                <div class="row end-group" (click)="selectEndGroup(endGroup)" data-toggle="collapse" [attr.href]="'#collapseExample'+i" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <em class="icon-folder-closed"></em>
                    <label class="end-group-name">{{endGroup.name}}</label>
                </div>
                <div class="collapse" [id]="'collapseExample'+i">
                    <div class="row end-group pl-4" *ngFor="let process of endGroup.processes" (click)="selectProcess(process)">
                        <em class="icon-folder-closed"></em>
                        <label class="end-group-name">{{process.name}}</label>
                    </div>
                </div> 
            </ng-container>
        </div>
        <div class="end-groups-container"  *ngIf="canShowEndGroups&&searchEGroup.length!=0">
            <ng-container *ngFor="let res of searchResults ; let i=index">
                <div class="row end-group" (click)="selectSearchItem(res)" >
                    <em class="icon-folder-closed"></em>
                    <label class="end-group-name" [innerHTML]="highlightingToken(res.name)"></label>
                </div>
                
            </ng-container>
        </div>
    </div>

    <div class="column mid" *ngIf="title=='Shared and imported products'">
        <div class="end-groups-container">
            <ng-container *ngFor="let sharedComponent of sharedComponents ; let i=index">
                <div class="row end-group" (click)="selectSharedComponent(sharedComponent)">
                    <em class="icon-folder-closed"></em>
                    <label class="end-group-name">{{sharedComponent.name}}</label>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="column right">
        <div class="close">
            <em class="icon-close" (click)="close()"></em>
        </div>
        <ng-container *ngIf="canShowDescription">
            <div class="end-group-details">
                <label class="selected-name m-0">{{selectedItem?.name}}</label>
                <label class="selected-description m-0">{{selectedItem?.description}}</label>
                <label class="location-title m-0">Location</label>
                <div *ngIf="selectedLocation!=null" class="selected-locations">
                    <div class="location-item ">
                        <label class="m-0">{{selectedLocation.location}}</label>
                        <em class="icon-close remove-location" (click)="removeLocation(selectedLocation)"></em>
                    </div>
                </div>
                <ng-container *ngIf="selectedLocation==null">
                    <div class="row dropdpwn-container mt-2" >
                        <div class="select-location dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <label class="location-name">Select Region</label>
                            <em class="icon-arrow-right" ></em>
                        </div>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button class="dropdown-item" *ngFor="let location of locations" (click)="selectLocation(location)">{{location.location}}</button>
                        </div>
                    </div>
                </ng-container>

            </div>
        </ng-container>
        <ng-container *ngIf="canShowSharedDetails">
            <div class="row row-wrap">
                <label class="shared-title">Company name:</label>
                <label class="shared-content">{{selectedShared.company.companyName}}</label>
            </div>
            <div class="row row-wrap">
                <label class="shared-title">Product name:</label>
                <label class="shared-content">{{selectedShared.name}}</label>
            </div>
            <div class="row row-wrap">
                <label class="shared-title">Type:</label>
                <label class="shared-content">{{selectedShared.note}}</label>
            </div>
            <div class="row row-wrap">
                <label class="shared-title">Weight:</label>
                <label class="shared-content">{{selectedShared.weight}}</label>
            </div>
            <div class="row row-wrap">
                <label class="shared-title">Total carbon footprint:</label>
                <label class="shared-content">{{selectedShared.emission | number: '1.0-2' }}</label>
            </div>
        </ng-container>
        <div class="buttons" *ngIf="canShowAdd">
            <button class="small-turq add" (click)="addMaterial()">
                <label>
                    Add
                </label>
            </button>
        </div>
    </div>
</div>
