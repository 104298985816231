<div #modal class="sidebar " id="sidebar">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create Location</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="locessToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="locName"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="locLocation"
                        required
                    />
                    <label class="float-label">Location</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            
        </form>
        <br>
        <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
    </div>

</div>