import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yss-questionnaire-overview',
  templateUrl: './yss-questionnaire-overview.component.html',
  styleUrls: ['./yss-questionnaire-overview.component.scss']
})
export class YssQuestionnaireOverviewComponent implements OnInit {
  public questions = [
    "What do the scores mean?",
    "How are the scores calculated?",
    "What is evaluated in the different categories?",
    "How are the ratings verified?"
  ];

  public categories=[
    {color:"#FD5B5B", size:"15%", title:"Environment and Climate"},
    {color:"#94DB5C", size:"90%", title:"Social Responsibility"},
    {color:"#FF9E00", size:"37%", title:"Technical Efficiency"},
    {color:"#FD5B5B", size:"18%", title:"HSEQ Professionality"},
  ];
  public isCollapsed: boolean[];
  public canShowSubmit: boolean=false;
  public canShowHistory: boolean=false;

  constructor() { }

  ngOnInit(): void {
    this.isCollapsed = new Array(this.questions.length).fill(true);
  }

  toggleCollapse(index: number): void {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  public toggleSubmitModal(event){
    this.canShowSubmit=false;
  }

  public toggleHistoryModal(event){
    this.canShowHistory=false;
  }

}
