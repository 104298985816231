import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-sheet',
  templateUrl: './master-sheet.component.html',
  styleUrls: ['./master-sheet.component.scss']
})
export class MasterSheetComponent implements OnInit {

  public stack=[];
  public statics=["Database", "Groups", "Sub-groups", "End-groups", "Process", "Locations"]
  constructor() { }

  ngOnInit(): void {
    
  }
  
  public goToOverview(state){
    
  }
  
  public pushToStack(state){
    this.stack.push(state);
  }

  public goback(){
    this.stack.splice(this.stack.length-1,1);
  }

}
