import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vo-notifiation',
  templateUrl: './vo-notifiation.component.html',
  styleUrls: ['./vo-notifiation.component.css']
})
export class VoNotifiationComponent implements OnInit {

  @Input() companyId: number;
  @Output() dismiss = new EventEmitter();
  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  public navigate() {
    this.dismiss.emit(true);
    this.route.navigate([
      "/homeVesselOperator",
      this.companyId,
      "components",
    ])
  }
  public dismissed() {
    this.dismiss.emit(true);
  }
}
