<div class="reflow-container rc-sd">
    <h1 class="title">
        System definition
    </h1>
    <p class="description">In this section, the scope of the project is defined by choosing which part of the product’s
        life cycle is included in the assessment.</p>
        <hr style="margin-top: 30px;">
        <div class="scoop-div">
          <p class="include-scoop-text" >Include Scopes of emissions: &nbsp; </p>
          <em class="icon-info" style="color: #000000;opacity: 1;margin-top: 3px;" 
              style="font-size: 13px;" animation='false' container="body" [ngbPopover]="popupContent" triggers="hover"
              placement="right"></em>
          <ng-template #popupContent>
              <div class="pop-container1">
                  <div class="pop-header d-flex">
                      <label class="pop-title">Add the time Span:
                      </label>

                  </div>
                  <div class="sections-container">
                      <div class="basic-section">
                          <p class="section-description">
                            This option allows the study to focus on a specific period, rather than the complete life time.
                            For instance, if a product is expected to endure 20 years, this study can undergo a period of 5 years,
                            5 months, 5 days or even 5 hours. Note that this period shall not surpass the product's expected lifetime.
                          </p>
                      </div>
                  </div>

              </div>
          </ng-template>
          <div style="display: flex; flex-direction: row; margin-left: 135px;">
            <p class="no-p" style="margin-left: 176px;" *ngIf="hasScope"></p>
            <div style="margin-left: 9px;">
                <input *ngIf="!hasScope" class="switch1" type="checkbox" (click)="ScopeToogle($event)">
                <input *ngIf="hasScope" class="switch2" type="checkbox" (click)="ScopeToogle($event)">
            </div>
          </div>
      </div>
      <div class="desc-div">
        <div class="img-container">
        <img src="../../../../../assets/Scope.png"
        style="width: 30px; height: 28px;">
       </div>
        <p class="description">Calculate the total scopes of emissions for the product with a breakdown and overview of the specific scopes like Scope 1, 2,
            and 3 as indicated in the assessment. Scopes of emissions can be helpful in GHG reporting of company-level emissions.</p>
      </div>
      <div class="scoop-div">
        <p class="include-scoop-text">Time-based calculation: &nbsp; </p>
        <em class="icon-info" style="color: #000000;opacity: 1;margin-top: 3px;" 
            style="font-size: 13px;" animation='false' container="body" [ngbPopover]="popupContent" triggers="hover"
            placement="right"></em>
        <ng-template #popupContent>
            <div class="pop-container1">
                <div class="pop-header d-flex">
                    <label class="pop-title">Add the time Span:
                    </label>

                </div>
                <div class="sections-container">
                    <div class="basic-section">
                        <p class="section-description">
                          This option allows the study to focus on a specific period, rather than the complete life time.
                          For instance, if a product is expected to endure 20 years, this study can undergo a period of 5 years,
                          5 months, 5 days or even 5 hours. Note that this period shall not surpass the product's expected lifetime.
                        </p>
                    </div>
                </div>

            </div>
        </ng-template>
        <div style="display: flex; flex-direction: row;margin-left: 170px;">
            <p class="no-p" style="margin-left: 176px;" *ngIf="showbasedtimecalculation"></p>
            <div style="margin-left: 9px;">
                <input *ngIf="!showbasedtimecalculation" class="switch1" type="checkbox" (click)="showBasedTimeCalculation()">
                <input *ngIf="showbasedtimecalculation" class="switch2" type="checkbox" (click)="showBasedTimeCalculation()">
            </div>
        </div>
    </div>
    <div class="desc-div">
      <div class="img-container">
      <img src="../../../../../assets/Time.png"
      style="width: 30px; height: 28px;">
     </div>
      <p class="description">
         Determine the lifecycle emissions of this product over a chosen time interval.
         Your emissions will be calculated in accordance with the selected time period, for example, a year.
          The usage/consumption for the selected timeframe will include a contribution factor that considers the proportion of the product’s life span corresponding to this period.
        </p>
    </div>
      <button class="btn-add-timespan" *ngIf="!showbasedtimecalculation" (click)="showTimeSpanInputs()">Add new time Span <em class="fa fa-plus"></em></button>
      <div class="timespan-container" *ngIf="showbasedtimecalculation">
      <input type="text" [(ngModel)]="timeSpan" class="timespan-input" placeholder="&nbsp;&nbsp;{{screening.product.lifeSpan}}years MAX" *ngIf="showbasedtimecalculation">
      <div class="dropdown" disabled style="width: 50px; height: 10px;" *ngIf="showbasedtimecalculation">
        <button class="dropdownbtn spec time-based-spec" type="button" id="5555" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <label style="margin-left: 6px; margin-top: 9px;">
                {{selectedduration}}
                <em class="fa fa-caret-down caret-down"></em>
            </label>

        </button>
        <div class="dropdown-menu"   style="border: 1px solid #dddddd; border-radius: 4px; width: 109px;">
            <a class="dropdown-item dropdown-item-first activity-item time-based-item" (click)="changeSelectDuration('Year')" *ngIf="selectedduration != 'Year'">
              <div class="activity-content">
                  <div style="width: 60px;">
                      <label class="internal-act">
                        Year
                      </label>
                  </div>
              </div>
            </a>
            <a class="dropdown-item dropdown-item-first activity-item time-based-item" (click)="changeSelectDuration('Month')" *ngIf="selectedduration != 'Month'">
                <div class="activity-content">
                    <div style="width: 60px;">
                        <label class="internal-act">
                           Month
                        </label>
                    </div>
                </div>
            </a>
            <a class="dropdown-item dropdown-item-first activity-item time-based-item" (click)="changeSelectDuration('Day')" *ngIf="selectedduration != 'Day'">
              <div class="activity-content">
                  <div style="width: 60px;">
                      <label class="internal-act">
                         Day
                      </label>
                  </div>
              </div>
              </a>
              <a class="dropdown-item dropdown-item-first activity-item time-based-item" (click)="changeSelectDuration('Hour')" *ngIf="selectedduration != 'Hour'">
                <div class="activity-content">
                    <div style="width: 60px;">
                        <label class="internal-act">
                          Hour
                        </label>
                    </div>
                </div>
              </a>

        </div>
    </div>
    </div>
    <h2 class="title-h2">
        Setting up your product's life cycle
    </h2>
    <p class="description ">Please choose all life cycle stages relevant to the project’s scope.</p>
    <div class="tip-bloc">
        <img src="../../../../../assets/tip_icon@4x.png" alt="">
        <p>
            <span>Tip:</span> Depending on the product’s life cycle, you can include the Distribution, Use,Installation and
            Maintenance stages multiple times.
        </p>
    </div>
    <div class="predifined-div">
        <div class="info-div">
            <img src="../../../../../assets/info-circle-fill (1).png"
                style="width: 30px; height: 28px;margin-top: 41px;">
        </div>
        <div class="description-text-predef">
            <p class="pre-def">The pre-defined system scope includes the following life cycle stages:</p>
            <p class="cradle-text"><strong>Cradle-to-gate:</strong> includes manufacturing</p>
            <p class="cradle-text" style="width: 650px;"><strong>Cradle-to-grave:</strong> includes manufacturing, distribution, use, distribution, disposal.
            </p>
            <p class="cradle-text" style="width: 650px;"><strong>Cradle-to-cradle:</strong> includes manufacturing, distribution, use, distribution,
                recycling.</p>
        </div>
    </div>
    
    <div class="loading-container" *ngIf="loadData">
        <div class="loading-content" style="margin-left: 205px;
        display: flex;
        justify-content: center;
        flex-direction: column;">
            <img src="../../../../../assets/Loading-icon-animation.svg" style="height: 125px; width: 125px;" alt="" />
            <label class="h4-heading loading" style="margin-left: -104px; color: #000000;">Please wait while we save the
                lifeCycle added</label>
        </div>
    </div>
    <div class="section2" *ngIf="!loadData">
        <div cdkDropList #lifeCycleList="cdkDropList" [cdkDropListData]="selectedItems" class="example-list"
            (cdkDropListDropped)="onDrop($event)">
            <div *ngFor="let item of selectedItems;
                    let i = index" class="divlayout" cdkDrag>
                <em class="fa fa-bars order"></em>
                <label>{{item.stage}}</label>
                <div style="height: 60px;margin-top: -8px;">
                    <em class="fa fa-trash delete-order" style="margin-top: -42px;"
                        [ngClass]="{'disabled': disable==true}" (click)="removeToselectedList(i)" *ngIf="hasScope"></em>
                </div>
                <div class="dropdown" disabled style="width: 50px; height: 10px; margin-top:-96px ;" *ngIf="hasScope">
                    <button class="dropdownbtn spec" type="button" id="dropdownMenuButton{{i}}" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <label style="margin-left: 6px; margin-top: 9px;">
                            {{item.activity}}
                            <em class="fa fa-caret-down caret-down"></em>
                        </label>

                    </button>
                    <div class="dropdown-menu" [attr.aria-labelledby]="'dropdownMenuButton'+i" style="border: 1px solid #dddddd; border-radius: 4px;">
                        <a class="dropdown-item dropdown-item-first activity-item" *ngFor="let elem of listOfActivity">
                            <div class="activity-content">
                                <div style="width: 60px;" (click)="updateLifeCycleActivity(elem.stage,item,i)">
                                    <label class="internal-act">
                                        {{elem.stage}}
                                    </label>
                                </div>
                                <div style="margin-left: 67px;margin-top: 9px;">
                                    <em class="icon-info" style="font-size: 17px;" animation='false' container="body"
                                        [ngbPopover]="popupContent" triggers="hover" placement="right"></em>
                                    <ng-template #popupContent>
                                        <div class="pop-container1">
                                            <div class="pop-header d-flex">
                                                <em class="icon-info bigger-info"></em>
                                                <label class="pop-title">{{elem.stage}}
                                                </label>

                                            </div>
                                            <div class="sections-container">
                                                <div class="basic-section">
                                                    <label for="" class="section-title">Description:</label>
                                                    <p class="section-description">{{elem.desc}}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
                <div class="dropdown" disabled style="width: 50px; height: 10px; margin-top:-96px ;">
                    <button class="dropdownbtn spec" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="font: normal normal bold 15px/20px Nunito Sans, sans-serif;
                        letter-spacing: 0px;
                        color: #DDDDDD;
                        opacity: 1; border: 1px dashed #dddddd;" disabled *ngIf="!hasScope">
                        <label style="margin-left: 6px; margin-top: 9px;color: #DDDDDD;">
                            Specify activity
                            <em class="fa fa-caret-down caret-down"></em>
                        </label>

                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                    </div>
                </div>

                <em class="fa fa-trash delete-order" style="margin-top: -15px;" [ngClass]="{'disabled': disable==true}"
                    (click)="removeToselectedList(i)" *ngIf="!hasScope"></em>
            </div>
        </div>
        <div class="btn-group">
            <div class="dropdown-menu select-menu">
                <label class="dropdown-item select-item d-flex text-style">
                    Pre-defined System
                </label>
                <div *ngFor="let item1 of preDefinedSystems; let i = index" class="listed">
                    <ng-template #popupContent>
                        <div class="pop-container">
                            <div class="pop-header d-flex">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title">{{item1.stage}}
                                    <em class="icon-re_stage circular" *ngIf="item1.circular"></em>
                                </label>

                            </div>
                            <div class="sections-container">
                                <div class="basic-section">
                                    <label for="" class="section-title">Description:</label>
                                    <p class="section-description">{{item1.desc}}</p>
                                </div>

                                <div class="basic-section mt24">
                                    <label for="" class="section-title">Includes:</label>
                                    <p class="section-description">{{item1.includes}}</p>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                    <span (click)="generateLifeCycles(item1)">
                        <label class="dropdown-item select-item d-flex">
                            {{item1.stage}}
                            <div class="">
                                <em *ngIf="item1.circular" class="icon-re_stage select-item-icon"></em>

                                <em *ngIf="item1.info" class="icon-info hovered select-item-icon" animation='false'
                                    container="body" [ngbPopover]="popupContent" triggers="hover"
                                    placement="right"></em>
                            </div>
                        </label>
                        <div class="dropdown-divider screen-divider"></div>

                    </span>
                </div>
                <label class="dropdown-item select-item d-flex text-style">
                    Life cycle stages
                </label>
                <div *ngFor="let item of dataToSelect; let i = index" class="listed">
                    <ng-template #popupContent>
                        <div class="pop-container">
                            <div class="pop-header d-flex">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title">{{item.stage}}
                                    <em class="icon-re_stage circular" *ngIf="item.circular"></em>
                                </label>

                            </div>
                            <div class="sections-container">
                                <div class="basic-section">
                                    <label for="" class="section-title">Description:</label>
                                    <p class="section-description">{{item.desc}}</p>
                                </div>

                                <div class="basic-section mt24">
                                    <label for="" class="section-title">Modeling:</label>
                                    <p class="section-description">{{item.modeling}}</p>
                                </div>

                                <div class="tips-section mt24">
                                    <img src="../../../../../assets/idea.png" alt="">
                                    <div class="tips-container">
                                        <p class="tip-text" *ngFor="let tip of item.tips">
                                            {{tip}}
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                    <span (click)="addToselectedList(i)">
                        <label class="dropdown-item select-item d-flex">
                            {{item.stage}}
                            <div class="">
                                <em *ngIf="item.circular" class="icon-re_stage select-item-icon"></em>

                                <em *ngIf="item.info" class="icon-info hovered select-item-icon" animation='false'
                                    container="body" [ngbPopover]="popupContent" triggers="hover"
                                    placement="right"></em>
                            </div>
                        </label>
                        <div class="dropdown-divider screen-divider"></div>

                    </span>
                </div>
            </div>
            <button type="button" [attr.disabled]="disable?'':null" class="btn btn-select" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" disabled>
                <label>
                    Add new life cycle stage
                </label>
                <em class="icon-arrow-right"></em>
            </button>
        </div>



        <div class="buttons" style="float: right;">
            <button class="simple-button prev" (click)="previous()">
                <label>
                    Previous step
                </label>
            </button>
            <button class="big-turq" (click)="next()">
                <label>
                    Next
                </label>
            </button>
            <button class="simple-button save" (click)="save()" *ngIf="!disable">
                <label>
                    Save and close
                </label>
            </button>
        </div>

    </div>
    <div *ngIf="showAlert" class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Error: </strong>one or more specifications must be selected.
    </div>
    <div *ngIf="showTimeAlert" class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Error: </strong>Time span should be less than project life span.
    </div>
</div>
<div class="projectId">
    <p class="description">Project ID: {{screening?.reflowId}}</p>
</div>
<app-life-cycle-modal *ngIf="lifeCycle" (close)="closeLifeCycleModal($event)" [stage]="stage"
    [maxValue]="screening?.product.lifeSpan"></app-life-cycle-modal>
