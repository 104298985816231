<div class="body">
  <label for="" class="title">
    {{item?.sharedProjectName}}
  </label>
  <div class="info-item closer-element">
    <label for="" class="label">Project Date:</label>
    <div class="status-container">
        <label for="" class="status stat-date">{{item?.importDate}}</label>
    </div>
</div>
<div class="info-item closer-element">
  <label for="" class="label">Project ID:</label>
  <div class="status-container">
      <label for="" class="status stat-date">{{item?.sharingCode}}</label>
  </div>
</div>
<img src="../../../assets/r_p_completed_projects@2x.png" alt="" class="logo">
  <label for="" class="belongs-to">
      <div class="move-folder-container">
          <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-manufacturer"
              aria-expanded="false" aria-controls="collapse-move-manufacturer">
              <label class="item-title">Manufacturer
              </label>
              <em
                  class="icon-arrow-down"></em>
          </button>
          <div id="collapse-move-manufacturer" class="collapse mvf-dropdown no-bb show">
                  <div class="info-item">
                    <label for="" class="label">Manufacturer Name:</label>
                    <div class="status-container">
                        <label for="" class="status stat-date">{{item?.manufacturerName}}</label>
                    </div>
                </div>
          </div>
      </div>
      <div class="move-folder-container">
        <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-spec"
            aria-expanded="false" aria-controls="collapse-move-spec">
            <label class="item-title">Specifications
            </label>
            <em
                class="icon-arrow-down"></em>
        </button>
        <div id="collapse-move-spec" class="collapse mvf-dropdown no-bb show">
                <div class="info-item">
                  <label for="" class="label">Product name:</label>
                  <div class="status-container">
                      <label for="" class="status stat-date">{{item?.name}}</label>
                  </div>
              </div>
              <div class="info-item">
                <label for="" class="label">Product type:</label>
                <div class="status-container">
                    <label for="" class="status stat-date">{{item?.type}}</label>
                </div>
            </div>
            <div class="info-item">
              <label for="" class="label">Weight:</label>
              <div class="status-container">
                  <label for="" class="status stat-date">{{item?.weight}} kg</label>
              </div>
          </div>
          <div class="info-item values-div" style="margin-top: -23px;">
            <label for="" class="label">Lifespan:</label>
            <div class="status-container">
                <label for="" class="status stat-date values">{{item?.lifeSpan}} years + {{screeningResult?.extendLifeSpan}} years <br><span class="value-small-text">(Initial)</span>&nbsp;&nbsp;<span class="value-small-text">(Extended)</span></label>
            </div>
        </div>
        </div>
    </div>
    <div class="move-folder-container">
      <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-assesment"
          aria-expanded="false" aria-controls="collapse-move-assesment">
          <label class="item-title">Project impact assessment
          </label>
          <em
              class="icon-arrow-down"></em>
      </button>
      <div id="collapse-move-assesment" class="collapse mvf-dropdown no-bb show">
              <div class="info-item">
                <label for="" class="label"></label>
                <div class="status-container">
                    <label for="" class="status stat-date"></label>
                </div>
            </div>
        <div class="info-item values-div pull-top">
          <label for="" class="label">Total carbon footprint:</label>
          <div class="status-container">
              <label for="" class="status stat-date values">{{item?.emission | number: '1.0-2'}}<br><span class="value-small-text">kg CO<sub></sub> eq</span></label>
          </div>
      </div>
      </div>
  </div>
  <div class="move-folder-container">
    <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-results"
        aria-expanded="false" aria-controls="collapse-move-results">
        <label class="item-title">Assessment results
        </label>
        <em
            class="icon-arrow-down"></em>
    </button>
    <div id="collapse-move-results" class="collapse mvf-dropdown no-bb show">
        <div class="info-item" *ngFor="let lc of item?.sharedLifeCycles">
          <label for="" class="label">{{lc.lifeCycle}}</label>
          <div class="status-container">
              <label for="" class="status stat-date">{{lc.lifeCycleImpact.impact | number: '1.0-2'}} kg CO<sub>2</sub> eq</label>
          </div>
        </div>
        </div>
  </div>
  <div class="move-folder-container">
    <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-insight"
        aria-expanded="false" aria-controls="collapse-move-spec">
        <label class="item-title">Data insights
        </label>
        <em
            class="icon-arrow-down"></em>
    </button>
    <div id="collapse-move-insight" class="collapse mvf-dropdown no-bb show">
            <div class="info-item">
              <label for="" class="label">Ecoinvent Database:</label>
              <div class="status-container">
                  <label for="" class="status stat-date">v{{item?.inventoryVersion}}</label>
              </div>
          </div>
          <div class="info-item">
            <label for="" class="label">Date of Inventory Data:</label>
            <div class="status-container">
                <label for="" class="status stat-date">{{item?.inventoryDate | date:'dd/MM/yyyy'}}</label>
            </div>
        </div>
    </div>
  </div>
  </label>
</div>
