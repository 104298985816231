<div class="master-sheet-container">
    <div class="new-db-btn" (click)="showUpsertEndGroup(null)">
        <label > New End-group</label>
    </div>
    <div class="records-list mt-4">
        <table class="re-table" >
        <thead>
            <tr>
                <th id="" class="pl-1"><label class="m-0"  style="margin:0">End-group Name</label></th>
               
                <th id="">Unit</th>
                <th id="">Description</th>
                <th id=""></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let endGrp of endGrpsList; let i=index">
                <td class="pl-1"  style=" font-weight: bold;">
                    {{endGrp.name}}
                </td>
                
                <td> {{endGrp.unit}}</td>
                <td> 
                    <label class="with-description">
                        {{endGrp.description&&endGrp.description.length>20?endGrp.description.substr(0,20)+"...":endGrp.description}}
                    </label>
                </td>
                <td>
                    <em class="icon-burger" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></em>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
                        <a class="dropdown-item"  [routerLink]="['../../processes',endGrp.id]">Processes</a>
                        <a class="dropdown-item" (click)="showUpsertEndGroup(endGrp)">Edit</a>
                    </div>
                </td>
            </tr>
        </tbody>
        </table>
        
    </div>
   
</div>

<div #modal class="sidebar " id="sidebar" *ngIf="displayUpsertEndGroup">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create End-group</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="endGroupToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="name"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            <div class="row" [ngClass]="{'mt-5': endGroupToEdit.value['hasChildren']=='No'}">
                <div class="field">
                    <div class="re-input re-styled d-flex mt-2 ">
                        <div class="rb d-flex align-items-center">
                            <input type="radio" value="No" formControlName="hasChildren" (change)="toggleProcess(true)" [checked]="endGroupToEdit.value.hasChildren=='No'">
                            <span  class="ml-1">No</span>
                        </div>
                        <div class="rb d-flex align-items-center  ml-2">
                            <input type="radio" value="Yes" formControlName="hasChildren" (change)="toggleProcess(false)" [checked]="endGroupToEdit.value.hasChildren=='Yes'">
                            <span class="ml-1">Yes</span>
                        </div>
                    </div>
                    <label class="float-label">Has process ?</label>
                    <small *ngIf="false">
                    *Version is required
                    </small>
                </div>
            </div>
            <span *ngIf="endGroupToEdit.value['hasChildren']=='No'">
                <div class="row">
                    <div class="field">
                        <select class="re-input" formControlName="unit">
                            <option selected value="Litres">Litres </option>
                            <option selected value="Cubic meters">Cubic meters </option>
                            <option selected value="Tonnes">Tonnes </option>
                            <option selected value="Kg">Kg </option>
                            <option selected value="Km">Km </option>
                            <option selected value="Kwh">Kwh </option>
                            <option selected value="Passenger Km">Passenger Km </option>
                            <option selected value="Tonne Km">Tonne Km </option>
                        </select>
                        <label class="float-label">Unit</label>
                        <small *ngIf="false">*unit is required</small>
                    </div>
                </div>
                <div class="row">
                    <div class="field">
                        <select class="re-input" formControlName="scopeType">
                            <option selected value="Scope 1">Scope 1 </option>
                            <option selected value="Scope 2">Scope 2 </option>
                            <option selected value="Scope 3">Scope 3 </option>
                        </select>
                        <label class="float-label">Scope</label>
                        <small *ngIf="false">*scope is required</small>
                    </div>
                </div>
                <div class="row">
                    <div class="field">
                        <textarea class="re-input mt-2"
                            type="text"
                            formControlName="description"
                            required rows="3" cols=""></textarea>
                        <label class="float-label">Description</label>
                        <small *ngIf="false">
                        *Name is required
                        </small>
                    </div>
                </div>
            </span>
            
            
            
            <div class="row inline" style="border-bottom: solid 1px #ddd;margin-bottom:15px">
                <div class="field" style="width: 44%;">
                    <input class="re-input" type="text" formControlName="emission" />
                    <label class="float-label">Emission factor</label>
                </div>
                <div class="field" style="width: 44%;">
                    <input class="re-input" type="text"  formControlName="emissionEV"/>
                    <label class="float-label">Emission factor EV</label>
                </div>
            </div>
        </form>
        <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
    </div>

</div>