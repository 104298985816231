<div class="cmp-container">
    <div class="data-collection-container" *ngIf="!showWarning">
        <div class="ls-container">
            <div class="left-side" [ngClass]="{'scroll-div': addLeftSideScroll}">
                <label class="emision-title">Emission Categories</label>
                <p class="emission-description">We comply with the GHG Protocol by assessing upstream emissions and direct emissions under the company's control, applying the cradle-to-gate concept. Downstream emissions, since they depend on third-party actions, are not included in our analysis.</p>
                <div class="left-side-container">
                    <ng-container *ngFor="let item of categories;let idx=index">
                        <div class="process" [ngClass]="{'selected-process': selectedCategory?.id==item?.id || selectedCategory == item?.name}" (click)="selectCategory(item)">
                            <div class="bullet bullet-done" *ngIf="item?.status=='confirmed'&&selectedCategory?.id!=item?.id">
                                <img src="../../../../../assets/checkmark-white.svg" alt="">
                            </div>
                            <div class="bullet bullet-inprogress" *ngIf="item?.status=='in-progress'&&selectedCategory?.id!=item?.id">
                                <img src="../../../../../assets/alert.svg" alt="">
                            </div>
                            <div class="bullet bullet-pending" *ngIf="selectedCategory?.id==item?.id">
                                <div class="sub-bullet"></div>
                            </div>
                            <div class="bullet" *ngIf="selectedCategory!=item&&item?.status!='confirmed'&&item?.status!='in-progress'"></div>
                            <label for="" class="category-name">{{item.name}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>




        <div class="right-side" >
            <ng-container *ngIf="selectedCategory==null">
                <div class="none-selected-container">
                    <h3>Welcome to ClimateHub: Your Step-by-Step Tutorial</h3>
                    <p>
                        Welcome to Reflow's ClimateHub beginning tutorial! This video guide is meant to offer a seamless and thorough experience as you embark on the journey of understanding and analyzing your company's carbon footprint. <br><br>
                        Our guide gives a clear roadmap for navigating our platform, from data collecting to extensive category breakdowns. This tutorial is meant to accommodate all levels of competence, whether you're a seasoned professional or just starting out in the realm of greenhouse gas emissions. <br><br>
                        Dive in, and let's all work together to make a difference on our planet!
                    </p>
                    <video style="height: 300px;width:100%;margin-top:40px" controls>
                        <source src="https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/intro.mp4" type="video/mp4">
                    </video>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedCategory!=null">
                <div class="add-process-container" (click)="showProcessModal=true;selectedProcess=null" *ngIf="showTutorial==false">
                    <label class="icone">+</label>
                    <label class="add-text">Add {{selectedCategory.name}}</label>
                </div>

                <div class="guidelines-container" *ngIf="showTutorial==true">
                    <div class="close-button-container">
                        <div class="close-button" (click)="toggleTutorial()">
                            <em class="icon-close"></em>
                        </div>
                    </div>
                    <div class="info-container">
                        <label class="guidelines-title">Please follow these guidelines:</label>
                        <div id="guidelines-content" [innerHTML]='tutorialGuidlines[selectedCategory!["name"]]["guidelines"]'>

                        </div>
                        <video style="height: 300px;width:100%;margin-top:20px" controls>
                            <source [src]="tutorialGuidlines[selectedCategory.name]['videpUrl']" type="video/mp4">
                        </video>
                    </div>
                </div>

                <div class="table-container" *ngIf="showTutorial==false">
                    <div class="table-header">
                        <label class="th-item column-29">Process selection</label>
                        <label class="th-item column-13" *ngIf="th_toDiplay==0">Amount</label>
                        <label class="th-item column-13" *ngIf="th_toDiplay!=0">Country</label>
                        <label class="th-item column-10" *ngIf="th_toDiplay==0">Unit</label>
                        <label class="th-item column-10" *ngIf="th_toDiplay==1">Nights</label>
                        <label class="th-item column-9" *ngIf="th_toDiplay==2">Worker</label>
                        <label class="th-item column-13">Data quality</label>
                        <label class="th-item column-14">Documents</label>
                        <label class="th-item column-14 text-center">Actions</label>
                    </div>
                    <div class="added-process-list">
                        <ng-container *ngFor="let item of processes;let idx=index">
                            <div class="generated-item">
                                <div class="generated-data-container">
                                    <div class="table-row">
                                        <label class="tr-item column-29">{{item.selectedProcessName}}</label>
                                        <label class="tr-item column-13" placement="left" ngbTooltip="{{item?.quantity}}" *ngIf="th_toDiplay==0">{{(item?.quantity+"").length>9 ? (item?.quantity+"").substring(0,9)+"..." : item?.quantity}}</label>
                                        <label class="tr-item column-13" *ngIf="th_toDiplay!=0">{{item.data|jsonParser:'country'}}</label>
                                        <label class="tr-item column-10" placement="left" ngbTooltip="{{item?.unit}}" *ngIf="th_toDiplay==0">{{(item.unit+"").length>5 ? (item.unit+"").substring(0,5)+"..." : item.unit}}</label>
                                        <label class="tr-item column-10" *ngIf="th_toDiplay==1">{{(item.data|jsonParser:'numberOfNights').length>5 ? (item.data|jsonParser:'numberOfNights').substring(0,5)+"..." : (item.data|jsonParser:'numberOfNights')}}</label>
                                        <label class="tr-item column-9" *ngIf="th_toDiplay==2">{{item.data|jsonParser:'quantity'}}</label>
                                        <label class="tr-item column-13" style="font-weight: 900;font-size:14px">{{qualityMap[item.dataQuality]}}</label>
                                        <label class="tr-item column-14">
                                            <div class="doc-status-container" *ngIf="item?.documentation!=''&&item?.documentation!=null" (click)="openFile(item.documentationUrl)">
                                                <img src="../../../../../assets/File.png" alt="">
                                                <label for="">{{item?.documentation}}</label>
                                            </div>
                                            <div class="doc-status-container no-file" *ngIf="item?.documentation==''||item?.documentation==null">
                                                <img src="../../../../../assets/No file.png" alt="">
                                                <label for="">No file</label>
                                            </div>
                                        </label>
                                        <label class="tr-item column-14 text-right actions-container">
                                            <div class="action-button" (click)="editProcess(item)" placement="top" ngbTooltip="Edit">
                                                <img src="../../../../../assets/data-collection-edit.svg" alt="">
                                            </div>
                                            <div
                                                placement="top" ngbTooltip="Show note"
                                                class="action-button"
                                                data-toggle="collapse" [attr.data-target]="'#note'+idx"
                                                >
                                                <img src="../../../../../assets/data-collection-note.svg" alt="">
                                            </div>
                                            <div class="action-button" (click)="deleteProcess(item)" placement="top" ngbTooltip="Delete">
                                                <img src="../../../../../assets/data-collection-delete.png" alt=""  style="width: 50% !important;">
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <span class="collapse" id="note{{idx}}" >
                                    <div class="generated-note" >
                                        <p *ngIf="item?.note.length>0" class="data-collection-note"><span class="bolded">Note: </span>{{item?.note}}</p>
                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <p class="bottom-fixer">&nbsp;</p>
                <div class="toggle-info-container">
                    <div class="toggle-info-button" (click)="toggleTutorial()"> {{showTutorial==false?'Show':'Hide'}} introduction information and video tutorial</div>
                    <div class="" *ngIf="showTutorial==true">&nbsp;</div>
                    <div class="next-button" (click)="confirmCategory()">
                        <div class="blank-space">&nbsp;</div>
                        <label class="next-text" title="Click here to confirm">Confirm</label>
                        <em class="icon-arrow-right"></em>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
    <app-company-loading *ngIf="showWarning"></app-company-loading>
    <div class="footer-container" *ngIf="!showWarning">
        <div class="session-container">
            <label class="session-data">User ID: {{currentUserInformation?.email}}</label>
            <label class="session-data">Date & Session ID: {{currentUserInformation?.date | date:'hh:mm - dd/MM/yyyy'}} - {{currentUserInformation?.session}}</label>
        </div>
        <div class="actions-container">
            <button class="back" (click)="goToNextStep('back')">Back</button>
            <button class="compare" (click)="calculateResult()">Calculate</button>
        </div>
    </div>

    <app-dc-process-modal *ngIf="showProcessModal==true" [compAssId]="companyAssessmentId" [selectedCategory]="selectedCategory" [selectedProcess]="selectedProcess" (toggleModal)="toggleModal($event)"></app-dc-process-modal>
    <app-warning-modal *ngIf="showWarningModal==true" (newItemEvent)="getsentedElement($event)"  (toggleWarningModal)="toggleWarningModal($event)" (proceedButtonClicked)="executeFunctionOnProceed()"></app-warning-modal>
</div>
