<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="showTransferModal">
    <div class="re-modal-content2" style="height:250px;">
      <form>
        <div class="request-header">
          <label class="requestModalTitle">Transfer an Assesment</label>
          <em class="icon-close close" style="margin:5px; color:#ffffff;" (click)="close()"></em>
        </div>
        <div class="content-modal2">
          <div class="request-container">
            <div style="display:block;">
              <input type="text" placeholder="Please type the Id of the Assesment" class="email-input" #screeningId>
            </div>
            <div>
            </div>
            <div class="twoButton" style="display:flex;flex-direction:row;justify-content: center;text-align: center;margin-top: 15px !important;">
              <button class="btn-solid default" (click)="close()">Close</button>
              <button class="btn-solid default" style="color:#000;background-color:#98D6D4 ;" (click)="save(screeningId.value)">Transfer</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  