<div class="ms-container">
    <div class="d-flex justify-content-between">
        <label class="big-title">ReFlow Master Sheet </label>
        <div class="goback d-flex align-items-center" *ngIf="stack.length!=0" (click)="goback()">
            <em class="icon-back_arrow mr-2"></em>
            <label class="lbl-goback m-0 ">Back to {{statics[statics.indexOf(stack[stack.length-1].type)]+" overview"}}</label>
        </div>
    </div>
    <div class="locations">
        <label *ngIf="stack.length==0" class="location-item">Database overview<em class="icon-arrow-right"></em></label>
        <ng-container *ngIf="stack.length!=0">
            <label *ngFor="let element of stack" class="location-item">{{element.type+": "+element.title}}<em class="icon-arrow-right"></em></label>
            <label class="location-item">{{statics[statics.indexOf(stack[stack.length-1].type)+1]+" overview"}}<em class="icon-arrow-right"></em></label>

        </ng-container>
    </div>
    <router-outlet></router-outlet>
</div>
