import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Company } from "../models/company.model";
import { Observable } from "rxjs";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  environmentUrl = "Debug api";

  
  constructor(
    private http: HttpClient,
     private helperService:HelperService,
     ) {
    this.environmentUrl = environment.apiUrl;
  }

  public editCustomerCompany(company: Company, id: number) {
   
    return this.http.put<any>(
      `${this.environmentUrl}/api/companies/` + id,
      company,
      this.helperService.headers()
    );
  }

  public addCompany(company: Company): any {
   
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies`,
      company,
      this.helperService.headers()
    );
  }

  public retrieveCustomersCompany(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/companies`, this.helperService.headers());
  }
  
  public getCreditsOverview(companyId:number): any {
    return this.http.get<any>(`${this.environmentUrl}/api/companies/credits/`+companyId, this.helperService.headers());
  }

  public retrieveCustomerCompany(id): any {
   
    return this.http.get<any>(`${this.environmentUrl}/api/companies/` + id, this.helperService.headers());
  }

  public getCompanyUsers(id: number): any {
   
    return this.http.get<any>(
      `${this.environmentUrl}/api/companies/users/` + id,
      this.helperService.headers()
    );
  }

  public getComponentOwners(companyId: number): any {
   
    return this.http.get<any>(
      `${this.environmentUrl}/api/companies/owners/` + companyId,
      this.helperService.headers()
    );
  }

  public getSuppliers(): any {
   
    return this.http.get<any>(
      `${this.environmentUrl}/api/companies/suppliers/`,
      this.helperService.headers()
    );
  }

  public getActiveSuppliers(): any {
   
    return this.http.get<any>(
      `${this.environmentUrl}/api/companies/suppliers/active`,
      this.helperService.headers()
    );
  }

  public retrieveVesselOperators(): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/companies/vesselOperators`,
      this.helperService.headers()
    );
  }

  public refillCredits(refillDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies/refill`,
      refillDto,
      this.helperService.headers()
    );
  }
  public sendResourcesRequest(SendResourceRequestDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies/sendResourceRequest`,
      SendResourceRequestDto,
      this.helperService.headers()
    );
  }

  public updateSubscription(updateSubscriptionDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies/update-subscription`,
      updateSubscriptionDto,
      this.helperService.headers()
    );
  }

  public transferAssessment(transferScreeningDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies/transfer-screening`,
      transferScreeningDto,
      this.helperService.headers()
    );
  }

  public calculateValidityQuality(screeningId): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/ScreeningQuality/`+screeningId,
      this.helperService.headers()
    );
  }

}
