import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyFoldersService } from '../../../services/company-folders.service';

@Component({
  selector: 'app-edit-folder-modal',
  templateUrl: './edit-folder-modal.component.html',
  styleUrls: ['./edit-folder-modal.component.scss']
})
export class EditFolderModalComponent implements OnInit {
  @Output() closeEditFolderModal=new EventEmitter();
  @Input() companyId:any;
  @Input() modalType:any;
  @Input() oldFolder:any;
  public floderName="";
  public showSubfolderBody:boolean=false
  public selectedFolderType="";

  constructor(
    private companyFoldersService:CompanyFoldersService
  ) { }

  ngOnInit(): void {
    if(this.modalType=='rename-folder'){
      this.floderName=this.oldFolder.folderName
    }

  }

  public selectFolder(type){
    if(type==this.selectedFolderType){
      this.selectedFolderType="";
    }else{
      this.selectedFolderType=type;
    }
  }

  public save(){
    if(this.modalType=='sub-folder'){
      this.companyFoldersService.createFolder({
        folderName:this.floderName,
        companyId:this.companyId,
        isCompleted:this.oldFolder.isCompleted,
        subFolderId:this.oldFolder.id
      }).subscribe(ans=>{
        setTimeout(()=>{
          this.showSubfolderBody=true
        },100)
        setTimeout(()=>{
          this.closeEditFolderModal.emit(ans);
        },7000)
        setTimeout(()=>{
          this.showSubfolderBody=false
        },9000)
      })
    }
    else if(this.modalType=='upsert-folder'){
      this.companyFoldersService.createFolder({
        folderName:this.floderName,
        companyId:this.companyId,
        isCompleted:this.selectedFolderType=='Completed'
      }).subscribe(ans=>{
        this.closeEditFolderModal.emit(ans);
      })
    }else{
      this.companyFoldersService.editFolder({
        id: this.oldFolder.id,
        folderName: this.floderName
      }).subscribe(ans=>{
        this.closeEditFolderModal.emit(ans);
      })
    }
  }

  public closeModal(){
    this.closeEditFolderModal.emit(null);
  }

}
