import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParser',
  pure: false,
})
export class JsonParserPipe implements PipeTransform {

  transform(array:any, target:any): any {
    let x=JSON.parse(array);

    return x[target];
  }

}
