<div class="modalDialog">
    <div class="re-modal-content">
        <div class="close-container">
            <div class="close-button" (click)="close()">
                <img src="../../../../../assets/yardscore-assets/ys-i-close-light.svg" alt="">
            </div>
        </div>
        <div class="history-container">
            <div class="title-contaienr">
                <label for="">Version History</label>
            </div>
            <div class="history-list">
                <ng-container *ngFor="let item of [1,2,3,4,5,6,7];let idx=index">
                    <div class="history-item" [ngStyle]="{'margin-top': idx>0?'20px':''}">
                        <div class="item-header">
                            <label for="" class="version">v01.01</label>
                            <label for="" class="date">14/03/2024</label>
                        </div>
                        <div class="notes-list">
                            <p class="note">
                                &bull; &nbsp;Added question X and Y to the steel module in Environment and  Climate Category
                            </p>
                        </div>
                        <div class="item-footer">
                            <label for="" class="normal-text">Changes by <span class="highlighted-text">Karl Fredrick Hiemeyer</span></label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>