<div class="download-button">
    <div class="row">
        <div class="col-4"></div>
        <div class="col-4"><button class="btn btn-info btn-block" (click)="downloadPDF()">Download PDF</button></div>
        <div class="col-4"></div>
    </div>
</div>
<div style="margin-left: 8%; margin-right: 8%;" id="htmlData" #htmlData>
    <br>
    <div>
        <div class="row" style="margin-top: 5%;">
            <div class="col-4">
                <img [src]="component?.componentPicture" alt="" style="height: 235px; width: 240px;">

            </div>
            <div class="col-4">
                <p class="sender">SENDER</p>
                <p class="compname" style="margin-top: -6px;">{{sender?.companyName}}</p>
                <br>
                <p class="cord" style="margin-top: -25px;">{{sender?.address}}</p>
                <p class="cord">Address line 2</p>
                <p class="cord">{{sender?.zipCode}} / {{sender?.city}} / {{sender?.country}}</p>
                <p class="cord">Att:</p>
            </div>
            <div class="col-4">
                <p style="color: white;">.</p>
                <p style="color: white;">.</p>
                <p class="cord" style="margin-top: -28px;">Email:{{sender?.billingEmail}}</p>
                <p class="cord">Tel:{{sender?.companyContact?.phoneNumber}}</p>
                <p class="cord">Web:</p>
            </div>
        </div>
        <div class="row">
            <br>
        </div>
        <hr>
        <div class="row">
            <div class="col-6">
                <h3>Delivery note</h3>
            </div>
            <div class="col-6">
                <p style="float: right; margin-top: 0px;" class="cord">Delivery note no. 000000</p>
            </div>
        </div>
        <hr>
    </div>
    <br>
    <div class="row">
        <div class="col-7">
            <p class="sender">DELIVERY TO</p>
            <p class="cordBtm" style="font-weight: bold; margin-top: 12px;">{{reciever?.companyName}}</p>
            <p class="cordBtm">{{reciever?.address}}</p>
            <p class="cordBtm">Address line 2</p>
            <p class="cordBtm">{{reciever?.zipCode}} / {{reciever?.city}} / {{reciever?.country}}</p>
            <p class="cordBtm">Att:</p>
            <p class="cordBtm" style="font-size: 18px; font-weight: bold;">—</p>
            <p class="cordBtm">{{reciever?.companyContact?.contactName}}</p>
            <p class="cordBtm">{{reciever?.companyContact?.phoneNumber}}</p>
            <p class="cordBtm">{{reciever?.companyContact?.adresseEmail}}</p>
        </div>
        <div class="col-5">
            <p class="sender">DETAILS</p>
            <div class="row" style="margin-top: 32px;">
                <div class="col-6">
                    <p class="cordBtm">Date</p>
                    <p class="cordBtm">Purchase order </p>
                    <p class="cordBtm">Invoice no. </p>
                    <p class="cordBtm">FlowID</p>
                </div>
                <div class="col-6">
                    <p class="cordBtmR" *ngIf="status?.installationDateTime!=null">{{status?.installationDateTime}} </p>
                    <p class="cordBtmR" *ngIf="status?.installationDateTime==null">— </p>
                    <p class="cordBtmR" *ngIf="status?.purchaseOrder!=null">{{status?.purchaseOrder}} </p>
                    <p class="cordBtmR" *ngIf="status?.installationDateTime==null">— </p>
                    <p class="cordBtmR">— </p>
                    <p class="cordBtmR" *ngIf="component?.reflowId!=null">{{component?.reflowId}} </p>
                    <p class="cordBtmR" *ngIf="component?.reflowId==null">— </p>

                </div>
            </div>
        </div>
    </div>
    <br>
    <table style="width:100%">
        <tr>
            <th id="" style="text-align: center; width: 28%;">FlowID</th>
            <th id="" style="text-align: center;width: 55%;">Description</th>
            <th id="" style="text-align: center;width: 17%;">Weight</th>
        </tr>
        <tr>
            <td>
                <p class="cordBtmR" style="text-align: center;">XX-XX-XX-XX-X</p>
                <!--<ngx-qrcode [elementType]="elementType" [value]="value" cssClass="aclass" errorCorrectionLevel="L">
                </ngx-qrcode>-->
                <img style="height: 205px;" width="205px"
                    src="https://fr.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market/generator/dist/generator/assets/images/websiteQRCode_noFrame.png"
                    alt="">
            </td>
            <td>
                <div class="row" style="margin-top: 32px; ">
                    <div class="col-6">
                        <p class="cordBtm" style="font-size: 26px;">Date</p>
                        <p class="cordBtm" style="font-size: 26px;">Purchase order </p>
                        <p class="cordBtm" style="font-size: 26px;">Invoice no. </p>
                        <p class="cordBtm" style="font-size: 26px;">FlowID</p>
                    </div>
                    <div class="col-6">
                        <p class="cordBtmR" *ngIf="status?.installationDateTime!=null">{{status?.installationDateTime}}
                        </p>
                        <p class="cordBtmR" *ngIf="status?.installationDateTime==null">— </p>
                        <p class="cordBtmR" *ngIf="status?.purchaseOrder!=null">{{status?.purchaseOrder}} </p>
                        <p class="cordBtmR" *ngIf="status?.installationDateTime==null">— </p>
                        <p class="cordBtmR">— </p>
                        <p class="cordBtmR" *ngIf="component?.reflowId!=null">{{component?.reflowId}} </p>
                        <p class="cordBtmR" *ngIf="component?.reflowId==null">— </p>
                    </div>
                </div>
            </td>
            <td>
                <p class="cordBtmR" style="text-align: center;font-size: 26px;">9.999 kg</p>
            </td>
        </tr>
    </table>

</div>