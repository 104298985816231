import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ValidationService } from 'src/app/services/validation.service';
@Component({
  selector: 'app-validation-dashboard',
  templateUrl: './validation-dashboard.component.html',
  styleUrls: ['./validation-dashboard.component.scss']
})
export class ValidationDashboardComponent implements OnInit {
  public requestList:any[];
  public initialRequestList:any[];
  public listofHistory:any[];
  public x : number =0;
  public y : number =10;
  public pageNumber : number =1;
  public canShowUploadModal: Boolean=false; 
  public canShowHistoryModal: Boolean=false; 
  public showReqModal: Boolean=false; 
  public fileData: File = null;
  public selectedValidationRequestId:any;
  public fileName:String="Choose a file or drag it here";
  public keyword="";
  public uploadOption:String="";
  constructor(private validationService: ValidationService, 
              private router: Router, 
              private activatedRoute : ActivatedRoute,
              private uploadService: FileUploadService, 
              private toastr: ToastrService) { }
  ngOnInit(): void {
    this.showRequests();
  }
  public showRequests():void{
    this.validationService.getRequests().subscribe(res=>{
      this.requestList=res;     
      this.initialRequestList =this.requestList
    });
  } 
  public changepaginationValue(){
   
    if(this.y<this.requestList.length +10){
      this.x= this.y ;
      this.y= this.y+10;
      this.pageNumber = this.pageNumber +1
    }
  }
  public changePaginationPrev(){
    if(this.pageNumber>1){
      this.x= this.x - 10 ;
      this.y= this.y -10;
      this.pageNumber = this.pageNumber -1
    }
  }
  public updateStatus(requestId:any, newStatus:String):void{
    this.validationService.updateRequestStatus({id: requestId, status: newStatus}).subscribe(res=>{
      this.showRequests();
    });
  }
  public openProject(screeningId):void{
    this.router.navigate([
      "/homeAdmin",
      "project",
      screeningId
    ]);
  }
  public showUploadModal(requestId,uploadOptionSelected:String):void{
    this.uploadOption=uploadOptionSelected;
    this.selectedValidationRequestId=requestId;
    this.canShowUploadModal=true;
  }
  public showHistoryModal(request):void{
    this.canShowHistoryModal=true;
  }
  public closeModal():void{
    this.canShowUploadModal=false;
  }
  public closeHistoryModal():void{
    this.canShowHistoryModal=false;
  }
  public reset():void{
  }

  public saveCorrectionSheet():void{
    this.uploadService.uploadFile(this.fileData, 0).subscribe((res) => {
      const correctionSheet = {
        correctionSheetUrl: res.fileName,
        uploadOption: this.uploadOption,
        validationRequestId: this.selectedValidationRequestId,
      };
      this.validationService.updateFile(correctionSheet).subscribe((response) => {
        this.canShowUploadModal=false;
        this.toastr.success(this.uploadOption+" been uploaded successfully","File upload");
        this.fileName="Choose a file or drag it here";
      });
    });
    
  }
  public uploadFile(event):void{
    this.fileData = <File>event[0];
    this.fileName = event[0].name;
  }
  public sort(sortBy:any){
    if(['status','projectName', 'companyName', 'userEmail'].indexOf(sortBy)!=-1){
      this.requestList=this.requestList.sort((a,b) =>  (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) ? 1 : -1);
    }else{
      this.requestList=this.requestList.sort((a,b) => this.timeleft(a[sortBy]) - this.timeleft(b[sortBy]));
    }
  }
  public timeleft(time){
    var tokens=time.replace("-"," ").replace("d"," ").replace("h"," ").replace("m"," ").replace("-"," ").split(' ');
    return Number(tokens[0])*24*60+Number(tokens[1])*60+Number(tokens[2]);
  }
  public getValidationHistory(request){
    this.canShowHistoryModal=true;
    this.validationService.getHistory(request.screeningId).subscribe(history=>{
      this.listofHistory=history;
    });
  }
  public showRequestModal(validationRequestId:any){
    this.selectedValidationRequestId=validationRequestId;
    this.showReqModal=true;
  }
  public closeRequestModal(){
    this.showReqModal=false;
  }
  public flagRequest(request){
    this.validationService.flagAsUrgent(request['id']).subscribe(ans=>{
      this.requestList[this.requestList.indexOf(request)]['isUrgent']=true;
    },err=>{
      //show alert if operation failed !
    })
  }
  public assignResponsible(responsibleEmail:any):void{
    this.validationService.updateResponsible({validationRequestId:this.selectedValidationRequestId, email:responsibleEmail.value}).subscribe(res=>{
      this.showReqModal=false;
      this.showRequests();
    });
  }
  public modelChanged(e){
    this.requestList=this.initialRequestList.filter(function(item){
      return JSON.stringify(item).toLowerCase().includes(e);
    });
  }
}