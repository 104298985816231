<label class="co2-subtitle">Component group</label>
<div class="form-group select">
  <input
    class="select-input"
    type="text"
    placeholder="Select component group"
    id="dropowners"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [(ngModel)]="txtGroup"
    autocomplete="off"
    (click)="componentGroupClicked($event)"
  />
  <div class="dropdown-menu" aria-labelledby="dropowners">
    <button
      class="dropdown-item"
      *ngIf="showBack == true"
      type="button"
      (click)="backGroup($event)"
    >
      <label class="drop-down-label back">
        <em class="fa fa-angle-left"></em>
        {{ groupLevels[groupLevels.length - 1] }}
      </label>
    </button>
    <div *ngFor="let componentGroup of componentGroups1">
      <button
        class="dropdown-item"
        type="button"
        (click)="selectGroup(componentGroup, $event)"
      >
        <label class="drop-down-label">{{ componentGroup.groupTitle }}</label>
        <em
          class="fa fa-angle-right expend"
          *ngIf="componentGroup.groups != null"
        ></em>
      </button>
      <div class="dropdown-divider"></div>
    </div>
  </div>
  <em class="icon-component icon" *ngIf="!compGroupFocused"></em>
  <em class="icon-search icon" *ngIf="compGroupFocused"></em>
</div>

<label class="co2-subtitle">LIFE SPAN</label>
<label class="co2-label">YEARS</label>
<div class="slidecontainer">
  <input
    type="range"
    min="1"
    max="{{ vessellife }}"
    value="1"
    class="slider-life"
    id="myRange"
    (change)="showLifeSpan($event)"
  />
  <label class="range-indicator">{{ lifeSpan }}</label>
</div>

<label class="co2-subtitle">WEIGHT</label>
<label class="co2-label">KG</label>
<div class="slidecontainer">
  <input
    type="range"
    min="1"
    max="10000"
    value="1"
    class="slider-weight"
    id="myRange"
    (change)="showWeight($event)"
  />
  <label class="range-indicator">{{ weight }}</label>
</div>
