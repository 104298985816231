import { Component, OnInit, Input } from "@angular/core";
import { ComponentLogService } from "src/app/services/component-log.service";
import { ComponentLogOverviewDto } from "src/app/models/dtos.model";

@Component({
  selector: "app-component-log",
  templateUrl: "./component-log.component.html",
  styleUrls: ["./component-log.component.css"],
})
export class ComponentLogComponent implements OnInit {
  public componentLogs: ComponentLogOverviewDto[];
  public orderAsc: boolean = false;
  public fieldName: string;
  public openFile: boolean = false;
  public selectedFile: any;

  @Input() public currentComponentId: number;
  constructor(private componenetLogService: ComponentLogService) {}

  ngOnInit(): void {
    this.getComponentLog();
  }

  public getComponentLog(): void {
    this.componenetLogService
      .getComponentLogs(this.currentComponentId)
      .subscribe((res) => {
        this.componentLogs = res;
        this.orderdesc();
      });
  }

  orderBy(field) {
    this.fieldName = field;
  }

  orderasc(): void {
    this.orderAsc = !this.orderAsc;
    this.componentLogs.sort((a: any, b: any) => {
      if (a["createdAt"] < b["createdAt"]) {
        return -1;
      } else if (a["createdAt"] > b["createdAt"]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  orderdesc(): void {
    this.orderAsc = !this.orderAsc;
    this.componentLogs.sort((a: any, b: any) => {
      if (a["createdAt"] > b["createdAt"]) {
        return -1;
      } else if (a["createdAt"] < b["createdAt"]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public showFile(file: any): void {
    this.openFile = true;
    this.selectedFile = file;
  }

  public closeFileModal(event: any): void {
    this.openFile = false;
  }
}
