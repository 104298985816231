import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { SalesService } from 'src/app/services/sales.service';
declare var $:any;
@Component({
  selector: 'app-videos-overview',
  templateUrl: './videos-overview.component.html',
  styleUrls: ['./videos-overview.component.scss']
})
export class VideosOverviewComponent implements OnInit {

    public playList=[]
    public targetVideo;
    public searchText:string="";
    

    @Output() goToVideo = new EventEmitter<any>();
    constructor(private academyService:AcademyService, private salesService:SalesService) { }

    ngOnInit(): void {
    this.academyService.getAcademyVideos().subscribe(ans=>{
        this.salesService.updateSalesData({companyId:Number(localStorage.getItem("company_id")), module:"academy"}).subscribe(res=>{
          
        });
        this.playList=ans
    })

    setTimeout(() => {
        $(document).ready(function(){
            $('.owl-carousel').owlCarousel({
            loop:false,
            margin:30,
           
            smartSpeed:450,
            navText:[
                "<div class='nav-btn prev-slide'><i class='fa fa-chevron-left' aria-hidden='true'></i></div>",
                "<div class='nav-btn prev-slide'><i class='fa fa-chevron-right' aria-hidden='true'></i></div>",
            ],
            nav:true,
            responsive:{
                    0:{
                        items:1,
                        nav:true
                    },
                    500:{
                        items:1,
                        nav:true,
                        loop:true
                    },
                    750:{
                        items:3,
                        nav:true,
                        loop:false
                    },
                    1050:{
                        items:3,
                        nav:true,
                        loop:false
                    },
                    1350:{
                        items:3,
                        nav:true,
                        loop:false
                    }
                    } 
                })
            });
    }, 3000);
    this.playList.sort(() => Math.random() - 0.5)
    
    }

    public cardClick(video){
        this.goToVideo.emit(video);
    }
    

}
