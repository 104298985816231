import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyAssessmentDataService } from 'src/app/services/company-assessment-data.service';

@Component({
  selector: 'app-company-assessment-end-groups',
  templateUrl: './company-assessment-end-groups.component.html',
  styleUrls: ['./company-assessment-end-groups.component.scss']
})
export class CompanyAssessmentEndGroupsComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentEndGrp:any= null;
  public displayUpsertEndGroup=false;
  public endGroupToEdit: FormGroup;
  public locationArray: FormArray;
  public curentSubGroupId:Number;
  public endGrpsList=[];
  public companies:any[]=[];

  constructor(
    private fb: FormBuilder,
    private companyAssessmentDataService:CompanyAssessmentDataService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      
      this.curentSubGroupId=Number(params['subGroupId']);
      this.getEndGroups(this.curentSubGroupId);
      this.endGroupToEdit = this.fb.group({
        name:[this.currentEndGrp?this.currentEndGrp.name:""],
        unit:[this.currentEndGrp?this.currentEndGrp.unit:""],
        description:[this.currentEndGrp?this.currentEndGrp.description:""],
        hasChildren:[this.currentEndGrp?(this.currentEndGrp.hasChildren==true?"Yes":"No"):"No"],
        companyAssessmentSubGroupId:[this.curentSubGroupId],
        scopeType:[this.currentEndGrp?this.currentEndGrp.scopeType:""],
        emission:[this.currentEndGrp?this.currentEndGrp.emission:0],
        emissionEV:[this.currentEndGrp?this.currentEndGrp.emissionEV:0],

      });
    })
  }
  public getEndGroups(subgroupeid){
    this.companyAssessmentDataService.endGroupsOfSubGroup(subgroupeid).subscribe(ans=>{
      this.endGrpsList=ans;
    })
  }

  public showUpsertEndGroup(currentDb){
    this.currentEndGrp=currentDb;
    this.endGroupToEdit = this.fb.group({
      name:[this.currentEndGrp?this.currentEndGrp.name:""],
      unit:[this.currentEndGrp?this.currentEndGrp.unit:""],
      description:[this.currentEndGrp?this.currentEndGrp.description:""],
      hasChildren:[this.currentEndGrp?(this.currentEndGrp.hasChildren==true?"Yes":"No"):"No"],
      companyAssessmentSubGroupId:[this.curentSubGroupId],
      scopeType:[this.currentEndGrp?this.currentEndGrp.scopeType:""],
      emission:[this.currentEndGrp?this.currentEndGrp.emission:0],
      emissionEV:[this.currentEndGrp?this.currentEndGrp.emissionEV:0],
    });
    this.displayUpsertEndGroup=true;
  }

  public hideUpsertEndGroup(result){
    
  }

  public selectCompany(companyId:any):void{
    this.endGroupToEdit.get('companyId').patchValue(companyId);
  }

  public dismissModal(){
    this.displayUpsertEndGroup=false;
  }

  public upsert(){
    var result=this.endGroupToEdit.value;
    result.hasChildren=result.hasChildren=='No'?false:true
    if(this.currentEndGrp==null){
      this.companyAssessmentDataService.createEndGroup(result).subscribe(ans=>{
        this.displayUpsertEndGroup=false;
        this.getEndGroups(this.curentSubGroupId);
      })
    }else{
      
    }
  }

  public delete(db){
  }

  public navigate(db){
    this.navigation.emit({type:"End-groups",data:db,title:db.endGrpName})
  }

  public toggleProcess(verdict){
    if(verdict==false){
      this.endGroupToEdit.patchValue(
          {endGrpEmission:null,endGrpDescription:null,endGrpUnit:null}
        );
      
    }
  }

  
}
