import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-company-assesstment-overview',
  templateUrl: './company-assesstment-overview.component.html',
  styleUrls: ['./company-assesstment-overview.component.scss']
})
export class CompanyAssesstmentOverviewComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router
  ) { 
    this.userService.getCurrentUser().subscribe(res=>{
      if(res.companyAssessmentAccess==false)
      {
        this.router.navigate(["/homeServiceProvider", localStorage.getItem("company_id")]);
      }
    });
  }

  ngOnInit(): void {
    
  }

}
