import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-locations-overview',
  templateUrl: './locations-overview.component.html',
  styleUrls: ['./locations-overview.component.scss']
})
export class LocationsOverviewComponent implements OnInit {

  @Output() navigation=new EventEmitter<any>();
  public currentLocation= null;
  public displayUpsertLocation=false;
  public locList=[
    {"id":1,"locName":"locess 1","locLocation":"TN"}

  ];
  constructor() { }

  ngOnInit(): void {
  }

  public showUpsertLocation(currentDb){
    this.currentLocation=currentDb;
    this.displayUpsertLocation=true;
  }

  public hideUpsertLocation(result){
    this.displayUpsertLocation=false;
    if(result!=null){
      if(this.currentLocation==null){
        this.locList.push(result);
      }else{
        this.locList[this.locList.indexOf(this.currentLocation)]=result;
        this.currentLocation=null;
      }
      
    }
  }

  public delete(db){
    this.locList.splice(this.locList.indexOf(db),1);
  }

  public navigate(db){
    this.navigation.emit({type:"Locations",data:db,title:db.locName})
  }
}
