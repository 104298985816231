import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { updatePasswordValidator } from "src/app/validators/password-validator.directive";
import { UserService } from "src/app/services/user.service";
import { ResetPassword } from "src/app/models/reset-password.model";

@Component({
  selector: 'app-component-picture',
  templateUrl: './component-picture.component.html',
  styleUrls: ['./component-picture.component.css']
})
export class ComponentPictureComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public showModal: boolean = false;
  @Input() public srcImg: string;

  constructor(private fb: FormBuilder, private userService: UserService) { }

  ngOnInit(): void {

  }

  public close(): void {
    this.showModal = false;
    this.toogleModal.emit(this.showModal);
  }


}
