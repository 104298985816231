<div class="dashboard-container">
    <div class="dash-stat-block">

        <div class="score-block">
            <div class="co2-block">
                <div class="item-details">
                    <img src="../../../assets/yardscore-assets/ys-ship.png" alt="">
                    <div class="item-txt">
                        <label for="" class="item-txt-head-title">CO2*/LDT</label>
                        <label for="" class="item-txt-second-title">*Metric ton carbon dioxide equivalent</label>
                    </div>
                </div>
                <div class="item-score">
                    <label for="" class="co2-score-txt">0.013</label>
                </div>
            </div>
            <div class="score-diag-container">
                <div class="score-diag-block">
                    put the diag here
                </div>
                <div class="score-diag-txt">
                    <label for="" class="percentage-txt">25.00%</label>
                    <label for="" class="descriptive-txt">Improvment</label>
                </div>
                <div class="score-diag-label">
                    <label for="">Overall Score</label>
                </div>
            </div>
            <div class="score-items">
                <div class="score-item-block">
                    <div class="item-details">
                        <img src="../../../assets/yardscore-assets/ys-ship.png" alt="">
                        <div class="item-txt">
                            <label for="" class="item-txt-head-title">Environment & Climate</label>
                            <label for="" class="item-txt-second-title">Material circularity, waste handing and emissions</label>
                        </div>
                    </div>
                    <div class="item-score">
                        <label for="" class="item-score-txt">80%</label>
                    </div>
                </div>
                <div class="score-item-block">
                    <div class="item-details">
                        <img src="../../../assets/yardscore-assets/ys-ship.png" alt="">
                        <div class="item-txt">
                            <label for="" class="item-txt-head-title">Social Responsibility</label>
                            <label for="" class="item-txt-second-title">Human rights, Workers’ rights & community impact</label>
                        </div>
                    </div>
                    <div class="item-score">
                        <label for="" class="item-score-txt">92%</label>
                    </div>
                </div>
                <div class="score-item-block">
                    <div class="item-details">
                        <img src="../../../assets/yardscore-assets/ys-ship.png" alt="">
                        <div class="item-txt">
                            <label for="" class="item-txt-head-title">Technical Efficiency</label>
                            <label for="" class="item-txt-second-title">Capabilities, capacities, infrastructure, workflow</label>
                        </div>
                    </div>
                    <div class="item-score">
                        <label for="" class="item-score-txt">78%</label>
                    </div>
                </div>
                <div class="score-item-block">
                    <div class="item-details">
                        <img src="../../../assets/yardscore-assets/ys-ship.png" alt="">
                        <div class="item-txt">
                            <label for="" class="item-txt-head-title">HSEQ Professionalism</label>
                            <label for="" class="item-txt-second-title">Certificates, training, HSEQ and implementation</label>
                        </div>
                    </div>
                    <div class="item-score">
                        <label for="" class="item-score-txt">70%</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="yardscore-block">
            <div class="yardscore-diag-container">
                <label for="">YardScore</label>
                <div class="yardscore-diag-block"></div>
            </div>
            <div class="yardscore-diag-container">
                <label for="">Yard Comparisons</label>
                <div class="yardscore-diag-block">
                    <div class="yardscore-diag-sousblock">
                        <div class="ys-diag-head">
                            <label for="" class="ys-diag-head-title">Category Comparison</label>
                            <div class="ys-head-keys">
                                <div class="keys-block">
                                    <div class="current-yard blue-key">
                                    </div>
                                    <label for="" class="key-title">Current Yard</label>
                                </div>
                                <div class="keys-block">
                                    <div class="current-yard yellow-key">
                                    </div>
                                    <label for="" class="key-title">All Yards</label>
                                </div>
                                <div class="keys-block">
                                    <div class="current-yard lightblue-key">
                                    </div>
                                    <label for="" class="key-title">Regional Yard</label>
                                </div>
    
                            </div>
                        </div>
                        <div class="ys-diag-body">

                            <div class="ys-diag-item-block">
                                <div class="diag-item-title">
                                    <label for="">Environment & Climate</label>
                                </div>
                                <div class="diag-item-content">
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-success" [ngStyle]="{'width': '60%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-danger" [ngStyle]="{'width': '80%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-light" [ngStyle]="{'width': '85%'}"></div>
                                    </div>
                                </div>

                            </div>
                            
                            <div class="ys-diag-item-block">
                                <div class="diag-item-title">
                                    <label for="">Social Responsibility</label>
                                </div>
                                <div class="diag-item-content">
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-success" [ngStyle]="{'width': '60%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-danger" [ngStyle]="{'width': '80%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-light" [ngStyle]="{'width': '85%'}"></div>
                                    </div>
                                </div>

                            </div>
                            <div class="ys-diag-item-block">
                                <div class="diag-item-title">
                                    <label for="">Technical Efficiency</label>
                                </div>
                                <div class="diag-item-content">
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-success" [ngStyle]="{'width': '60%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-danger" [ngStyle]="{'width': '80%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-light" [ngStyle]="{'width': '85%'}"></div>
                                    </div>
                                </div>

                            </div>

                            <div class="ys-diag-item-block">
                                <div class="diag-item-title">
                                    <label for="">HSEQ Professionalism</label>
                                </div>
                                <div class="diag-item-content">
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-success" [ngStyle]="{'width': '60%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-danger" [ngStyle]="{'width': '80%'}"></div>
                                    </div>
                                    <div class="current-yard-stat">
                                        <div class="indicator-score is-light" [ngStyle]="{'width': '60%'}"></div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                        <div class="ys-score-bar">
                            <ng-container *ngFor="let item of [10,20,30,40,50,60,70,80,90]">
                                <label for="">{{item}}</label>
                            </ng-container>
                            
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="dash-views-block">
        <label for="">Latest Views</label>
        <div class="dash-views-body">
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Shipowner</label>
                        <label for="" class="item-second-title">Southern Europe</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Shipowner</label>
                        <label for="" class="item-second-title">Southern Europe</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Shipowner</label>
                        <label for="" class="item-second-title">Southern Europe</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Insurance Company</label>
                        <label for="" class="item-second-title">Central America</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Shipowner</label>
                        <label for="" class="item-second-title">Southern Europe</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Finance Institution</label>
                        <label for="" class="item-second-title">Denmark</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
            <div class="dash-views-item">
                <div class="details-part">
                    <img src="../../../assets/yardscore-assets/ys-i-project.svg" alt="">
                    <div class="item-title-block">
                        <label for="" class="item-head-title">Shipowner</label>
                        <label for="" class="item-second-title">Southern Europe</label>
                    </div>
                </div>
                <div class="time-part">
                    <label for="">2 min ago</label>
                </div>
            </div>
        </div>

    </div>
</div>
