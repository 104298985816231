import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['../new-login.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  loginWithEmail() {
    this.auth.loginWithRedirect({
      authorizationParams: {
        connection: 'email',
        send: 'link',
      },
    });
  }

}
