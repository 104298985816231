import { ContactPerson } from './contact-person';

export class Warehouse {
    public constructor(init?: Partial<Warehouse>) {
        Object.assign(this, init);
    }
    public id: number;
    public warehouseName: string;
    public address1: string;
    public address2: string;
    public postalCode: number;
    public city: string;
    public country: string;
    public companyId: number;
    public contactPerson: ContactPerson;
}
