import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Company } from "src/app/models/company.model";

@Component({
  selector: "app-company-detail",
  templateUrl: "./company-detail.component.html",
  styleUrls: ["./company-detail.component.css"],
})
export class CompanyDetailComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public companyToShow: Company = new Company();
  constructor() {}

  ngOnInit(): void {}

  public showEditView() {
    this.toogleModal.emit(true);
  }
}
