import { Injectable } from "@angular/core";
import { ComponentFilterDto, ComponentOverviewDto } from "../models/dtos.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public componentFilter(filter: ComponentFilterDto, companyId: number) {

    return this.http.put<any>(
      `${this.environmentUrl}/api/filter/component/` + companyId,
      filter,
      this.helperService.headers()
    );
  }
}
