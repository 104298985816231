import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ShareService } from '../../../services/share.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-request-data',
  templateUrl: './request-data.component.html',
  styleUrls: ['./request-data.component.scss']
})
export class RequestDataComponent implements OnInit {

  @Output() sharedFolderEvent =new EventEmitter<any>();
  @Output() selectProject =new EventEmitter<any>();

  public searchList=[];
  public searchText:string="";
  public searchTriggered:boolean=false; 
  public selectedFolder:string="";
  public canShowFolders:boolean=false;
  public projects=null;
  public showRequestView:boolean=false;
  public companyId="";
  public selectedProject=null;
 
  public inquiries:any=[];
  public requests:any=[];
  public myRequestsCompanies:any=[];
  public requestsToMeCompanies:any=[];
  public toDisplay:any=[];


  constructor(
    private shareService:ShareService,
    private activatedRoute: ActivatedRoute

  ) { 
    this.companyId=localStorage.getItem('company_id')+"";
    
  }

  ngOnInit(): void {
      this.shareService.getCompanyRequests(this.companyId).subscribe(ans=>{
        this.inquiries=ans["inquiries"];
        this.requests=ans["requests"];
        this.myRequestsCompanies=ans["myRequestsCompanies"];
        this.requestsToMeCompanies=ans["requestsToMeCompanies"];
      },err=>{
        console.log(err);
        
      })
  }

  public makeSearch(){

  }
  public selectFolder(x){
    this.selectedFolder=x;
    if(this.selectedFolder=="myrequests"){
      this.toDisplay=this.inquiries
    }else{
      this.toDisplay=this.requests
    }
  }
  public selectSubFolder(subfolder,targetParent){
    this.selectedFolder=targetParent;
    if(this.selectedFolder=="myrequests"){
      this.toDisplay=this.inquiries.filter(x=>x.inquiredCompanyId==subfolder?.companyId)
    }else{
      this.toDisplay=this.requests.filter(x=>x.requestingCompanyId==subfolder?.companyId)
    }
  }

  public selectOneProject(x){

  }
  public addNewItem(x){

  }
  public _selectProject(x){

  }

  
  public showRequestModal(inReturn) {
    this.showRequestView = inReturn;
  }

  public showProjectDetails(project){
    this.selectedProject=project;
    this.selectProject.emit(project)
  }
  
  
}
