import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-edit-platform-update',
  templateUrl: './edit-platform-update.component.html',
  styleUrls: ['./edit-platform-update.component.css']
})
export class EditPlatformUpdateComponent implements OnInit {
  @Input() public showForm:boolean;
  @Input() public puToUpdate:any;
  @Output() public toogleModal= new EventEmitter();

  public updateToCreate:FormGroup;
  public submitted = false;
  
  constructor(private fb:FormBuilder, private notificationService:NotificationServiceService ) { }

  ngOnInit(): void {
    if(this.puToUpdate != null){
      this.updateToCreate = this.fb.group({
        id:[this.puToUpdate.id],
        updateModule: [this.puToUpdate.updateModule, Validators.required],
        updateDescription: [this.puToUpdate.updateDescription, Validators.required],
        updatedAt: [this.puToUpdate.updatedAt, Validators.required],
      });
    }
    else{
      this.updateToCreate = this.fb.group({
        updateModule: ["", Validators.required],
        updateDescription: ["", Validators.required],
        updatedAt: ["", Validators.required],
      });
      
    }
  }

  get frm() {
    return this.updateToCreate.controls;
  }

  public updatePlatform():void{
    this.notificationService.addPlatformUpdate(this.updateToCreate.value).subscribe(res=>{
      this.hideModal();
    });
  }

  public hideModal():void{
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }


}
