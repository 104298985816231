<label class="co2-subtitle">Type</label>
<div class="row custom-row">
  <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
    <div
      class="center"
      (click)="checkVessel('Bulk vessel', r1)"
      (mouseenter)="vesselHover('Bulk vessel')"
      (mouseleave)="vesselLeave('Bulk vessel')"
    >
      <img
        src="../../../../../assets/calculator/vessel-bulk-default.svg"
        class="active-vessel"
        alt=""
        *ngIf="ClickedbulkVessel || HoverbulkVessel || vesselType == ''"
      />
      <img
        src="../../../../../assets/calculator/vessel-bulk-inactive.svg"
        class="active-vessel"
        alt=""
        *ngIf="!ClickedbulkVessel && !HoverbulkVessel && vesselType != ''"
      />
      <label class="vessel-type">Bulk vessel</label>
      <input
        #r1
        class="unchecked"
        type="radio"
        name="r1"
        value="Bulk vessel"
        (change)="checkVessel('Bulk vessel', r1)"
      />
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
    <div
      class="center"
      (click)="checkVessel('Container vessel', r2)"
      (mouseenter)="vesselHover('Container vessel')"
      (mouseleave)="vesselLeave('Container vessel')"
    >
      <img
        src="../../../../../assets/calculator/vessel-container-default.svg"
        class="active-vessel"
        alt=""
        *ngIf="
          ClickedcontainerVessel || HovercontainerVessel || vesselType == ''
        "
      />
      <img
        src="../../../../../assets/calculator/vessel-container-inactive.svg"
        class="active-vessel"
        alt=""
        *ngIf="
          !ClickedcontainerVessel && !HovercontainerVessel && vesselType != ''
        "
      />
      <label class="vessel-type">Container vessel</label>
      <input
        #r2
        class="unchecked"
        type="radio"
        name="r1"
        value="Container vessel"
        (change)="checkVessel('Container vessel', r2)"
      />
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
    <div
      class="center"
      (click)="checkVessel('Passenger vessel', r3)"
      (mouseenter)="vesselHover('Passenger vessel')"
      (mouseleave)="vesselLeave('Passenger vessel')"
    >
      <img
        src="../../../../../assets/calculator/vessel-passenger-default_ver2.svg"
        class="active-vessel"
        alt=""
        *ngIf="
          ClickedpassengerVessel || HoverpassengerVessel || vesselType == ''
        "
      />
      <img
        src="../../../../../assets/calculator/vessel-passenger-inactive_ver2.svg"
        class="active-vessel"
        alt=""
        *ngIf="
          !ClickedpassengerVessel && !HoverpassengerVessel && vesselType != ''
        "
      />
      <label class="vessel-type">Passenger vessel</label>
      <input
        #r3
        class="unchecked"
        type="radio"
        name="r1"
        value="Passenger vessel"
        (change)="checkVessel('Passenger vessel', r3)"
      />
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
    <div
      class="center"
      (click)="checkVessel('Tanker vessel', r4)"
      (mouseenter)="vesselHover('Tanker vessel')"
      (mouseleave)="vesselLeave('Tanker vessel')"
    >
      <img
        src="../../../../../assets/calculator/vessel-tanker_default.svg"
        class="active-vessel"
        alt=""
        *ngIf="ClickedtankerVessel || HovertankerVessel || vesselType == ''"
      />
      <img
        src="../../../../../assets/calculator/vessel-tanker-inactive.svg"
        class="active-vessel"
        alt=""
        *ngIf="!ClickedtankerVessel && !HovertankerVessel && vesselType != ''"
      />
      <label class="vessel-type">Tanker vessel</label>
      <input
        #r4
        class="unchecked"
        type="radio"
        name="r1"
        value="Tanker vessel"
        (change)="checkVessel('Tanker vessel', r4)"
      />
    </div>
  </div>
</div>
<label class="co2-subtitle">Remaining life Time</label>
<label class="co2-label">YEARS</label>
<div class="slidecontainer">
  <input
    type="range"
    min="1"
    max="40"
    value="1"
    class="slider"
    id="myRange"
    (change)="showVesselAge($event)"
  />
  <label class="range-indicator">{{ vesselAge }}</label>
</div>
<label class="co2-subtitle">area</label>
<div class="row area-row">
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-1">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Hull structure')"
    >
      <div [class.cont-shadow]="vesselArea == 'Hull structure'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-hull-structure-default.svg"
            alt=""
            class="area-image"
            *ngIf="hullStructure && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-hull-structure-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!hullStructure"
          />
          <img
            src="../../../../../assets/calculator/area-hull-structure-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Hull structure'"
          />
          <label class="area-label">Hull structure</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Auxiliary systems')"
    >
      <div [class.cont-shadow]="vesselArea == 'Auxiliary systems'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-aux-default.svg"
            alt=""
            class="area-image"
            *ngIf="auxilaritySystem && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-aux-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!auxilaritySystem"
          />
          <img
            src="../../../../../assets/calculator/area-aux-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Auxiliary systems'"
          />
          <label class="area-label">Auxiliary systems</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Electric systems')"
    >
      <div [class.cont-shadow]="vesselArea == 'Electric systems'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-electric-default.svg"
            alt=""
            class="area-image"
            *ngIf="electricSystem && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-electric-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!electricSystem"
          />
          <img
            src="../../../../../assets/calculator/area-electric-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Electric systems'"
          />
          <label class="area-label">Electric systems</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Acommodation')"
    >
      <div [class.cont-shadow]="vesselArea == 'Acommodation'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-acommodation-default.svg"
            alt=""
            class="area-image"
            *ngIf="acommodation && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-acommodation-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!acommodation"
          />
          <img
            src="../../../../../assets/calculator/area-acommodation-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Acommodation'"
          />
          <label class="area-label">Acommodation</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Nav. & comm. system')"
    >
      <div [class.cont-shadow]="vesselArea == 'Nav. & comm. system'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-nav-comm-default.svg"
            alt=""
            class="area-image"
            *ngIf="commSystem && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-nav-comm-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!commSystem"
          />
          <img
            src="../../../../../assets/calculator/area-nav-comm-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Nav. & comm. system'"
          />
          <label class="area-label">Nav. & comm. system</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseArea('Propulsions systems')"
    >
      <div [class.cont-shadow]="vesselArea == 'Propulsions systems'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/area-propulsions-default.svg"
            alt=""
            class="area-image"
            *ngIf="propulsionsSystems && vesselArea == ''"
          />
          <img
            src="../../../../../assets/calculator/area-propulsions-inactive.svg"
            alt=""
            class="area-image"
            *ngIf="!propulsionsSystems"
          />
          <img
            src="../../../../../assets/calculator/area-propulsions-active.svg"
            alt=""
            class="area-image"
            *ngIf="vesselArea == 'Propulsions systems'"
          />
          <label class="area-label">Propulsion system</label>
        </div>
      </div>
    </div>
  </div>
</div>
