import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-questionnaires-overview',
  templateUrl: './review-questionnaires-overview.component.html',
  styleUrls: ['./review-questionnaires-overview.component.scss']
})
export class ReviewQuestionnairesOverviewComponent implements OnInit {

  public currentMessage = 'hello salim';
  public viewType = 'grid';
  public showPassModal:boolean=false;
  public modalTitle = '';

  constructor() { }

  ngOnInit(): void {
  }


  showMessage(message) {
    this.currentMessage = message;
  }

  public random(){
    return Math.floor(Math.random() * 100);
  }

  public closePassModal(event){
    this.showPassModal=false;
  }

  openModal(title: string) {
    this.modalTitle = title;
    this.showPassModal = true;
  }

}
