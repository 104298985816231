import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ysa-overview',
  templateUrl: './ysa-overview.component.html',
  styleUrls: ['./ysa-overview.component.css']
})
export class YSAOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
