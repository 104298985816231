<div class="modalDialog">
    <div class="re-modal-content">
        <div class="extrema">
            <em class="icon-close" (click)="close()"></em>
        </div>
        <div class="main-content">
            <div class="project-details-container p-1">
                <div class="project-detalis">
                    <img src="../../../../assets/reflow-newlogo.png" alt="" class="relogo">
                    <div class="alert-container">
                        <em class="icon-view"></em>
                        <div class="details-container">
                          <p class="bold-detail some-space">Project preview - what you're sharing:</p>
                          <p class="normal-detail">Below you can see what product information is going to be shared.</p>
                      </div>
                    </div>
                    <label class="prodname">{{item?.product?.name}}</label>
                    <p class="prodescription">
                      The product’s function, its manufacturer, and technical specifications are described below providing the basis for this assessment. Please review and select relevant stages before sharing.
                    </p>
                    <div class="row mt10">
                        <div class="col-6 d-flex justify-content-between large-col-6">
                            <label class="reflabel">Project ID:</label>
                            <label class="reflabel id-value">{{item?.reflowId}}</label>
                        </div>
                        <div class="col-6 d-flex justify-content-end small-col-6 no-padding">
                            <label class="reflabel mr-2">Project Date:</label>
                            <label class="reflabel">{{item?.creationDate | date: 'dd/MM/yyyy'}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Manufacturer</label>
                            <label class="attr">Manufacturer Name:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val">{{company?.companyName}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Specifications</label>
                            <label class="attr">Product Name:</label>
                            <label class="attr">Product Type:</label>
                            <label class="attr">Weight:</label>
                            <label class="attr">Lifespan:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy">{{item?.product?.name}}</label>
                            <label class="val">{{item?.product?.type}}</label>
                            <label class="val">{{item?.product?.weight}} kg</label>
                            <div class="d-flex">
                                <label class="val with-label left-align">{{item?.product?.lifeSpan}} years
                                    <span>(Initial)</span>
                                </label>
                                <label class="val ml-3">+</label>
                                <label class="val with-label ml-3 left-align">{{item?.product?.lifeSpan}} years
                                    <span>(Extended)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="attr">Function:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="val">{{item?.product?.quantity}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Project impact assessment</label>
                            <label class="attr">Total carbon footprint</label>
                            <label class="attr attr-description">Manufacturer ID:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy with-label align-items-start">{{screeningResult?.totalCarboonFootprint | number : '1.2-2'}} <span style="margin-top: -7px">kg CO<sub>2</sub> eq</span></label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-12">
                            <label class="title">Assessment results</label>
                        </div>
                        <div class="col-12">
                            <div class="alert-container">
                                <em class="icon-info"></em>
                                <div class="details-container">
                                  <p class="bold-detail some-space">Please select which life cycle stages you will share</p>
                                  <p class="normal-detail">Below you can select which stages you want to share or keep confidential.</p>
                              </div>
                            </div>
                        <span class="required-span lc-error" *ngIf="showLcErrorMessage"> You need to select at least one life cycle stage *</span>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                            <div class="operation mb-2" *ngFor="let lcs of selectedLCS;let i=index" (click)="selectedLCS[i]['selected']=!selectedLCS[i]['selected']">
                                <div class="pi-checkbox" >
                                    <i class="fa fa-check" aria-hidden="true" *ngIf="selectedLCS[i]['selected']"></i>
                                </div>
                                <label for="">{{lcs.name}}</label>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                          <div *ngFor="let lcs of selectedLCS;let i=index" class="units-container">
                            <label class="val with-label flex-row units-name" style="float: left;">{{lcs.value | number : '1.2-2'}}</label>
                            <span class="units-span">kg CO<sub>2</sub> eq</span>
                          </div>
                        </div>

                    </div>
                    <hr>
                        <div class="operation mb-2 project-name-form">
                            <label class="attr bold-attr" style="margin-left:0px;margin-right: -107px;">Change shared assessment title:</label>
                            <label for="">
                              <span class="required-span" *ngIf="showErrorMessage">The project name is required *</span>
                              <input type="text" class="changeTitle" placeholder="New Project Name" [(ngModel)]="sharingName">
                            </label>
                        </div>
                    <hr>
                    <div class="row">
                        <div class="col-6 d-flex justify-content-between">
                            <label class="reflabel">Ecoinvent Database: v3.8</label>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <label class="reflabel mr-2">Date of Inventory Data:</label>
                            <label class="reflabel">{{item?.inventoryVersion | date:'dd/MM/yyyy'}}</label>
                        </div>
                    </div>
                    <button class="share-button" (click)="shareScreening()">
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        Share
                    </button>
                </div>

            </div>
        </div>
        <div class="extrema"></div>
    </div>
</div>
