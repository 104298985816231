import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Company } from 'src/app/models/company.model';
import { Screening } from 'src/app/models/screening.model';
import { CompanyService } from 'src/app/services/company.service';
import { LifeCycleServiceService } from 'src/app/services/life-cycle-service.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-validation-review',
  templateUrl: './validation-review.component.html',
  styleUrls: ['./validation-review.component.scss']
})
export class ValidationReviewComponent implements OnInit {

  public screeningId:number;
  public screeningReview:any;
  public activeCollapsible:number;
  public loading:boolean=true;
  public loadingSave:boolean=false;
  public confirmation:boolean=false;
  public inventoryApproved:boolean=false;
  public company:Company;
  public calculation=0;
  public loadingInventory:boolean=true;
  public screening:Screening;
  public screening_reflow_id;

  constructor(public activatedRoute:ActivatedRoute, private lifeCycleService:LifeCycleServiceService ,private userService:UserService ,public toastrService:ToastrService ,public screeningService:ScreeningService, public companyService:CompanyService) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.screeningId= Number( params["screeningId"]);
      this.screeningService.updateState(this.screeningId,"review").subscribe(res=>{
        this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
          this.screeningReview=res;
          this.loading=false;
          const companyId = Number(localStorage.getItem("company_id"));
          this.companyService.retrieveCustomerCompany(companyId).subscribe(resCompany=>{
            this.company=resCompany;
          });
          this.screeningService.getInventoryById(this.screeningId).subscribe(inventory=>{
            this.screeningReview.lifeCycles=inventory;
            this.loadingInventory=false;
          });
        });
      });
    });
    this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
      this.screening_reflow_id = res.reflowId;
    });
  }

  public next() {
    this.loadingSave=true;
    this.confirmation=false;
    this.screeningService.calculateScreeningResult(this.screeningId).subscribe(res=>{
      this.loadingSave=false;
      this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
        const screeningLog = {date:new Date(),description:"Approve Screening and start calculation", screeningId:this.screeningId};
        this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
        });
      });
    });
  }
  
  public save() {
  }

  public previous() {
  }

  public closeModal(){
    this.confirmation=false;
  }

  public openModal(){
    if(this.inventoryApproved==true && this.loadingInventory==false){
      this.calculation = 1;
      if(this.company.reflowPoints>=1){
        this.confirmation=true;
      }
      else{
        this.toastrService.error("you don't have enough tokens to proceed the calculation","Token balance");
      }
    }
    else{
      this.toastrService.warning("please approve the assessment inventory","Approval needed");
    }
  }

  public approveSetup(systemDefinition:any,screeningSetup:any):void{
    systemDefinition.className+=" show";
    screeningSetup.className="collapse";
  }

  public approveDefinition(screeningInventory:any,systemDefinition:any):void{
    screeningInventory.className+=" show";
    systemDefinition.className="collapse";
  }
  
  public approveInventory(screeningInventory:any):void{
    screeningInventory.className="collapse";
    this.inventoryApproved=true;
  }

  public editSection(sectionName:string):void{
  }

  public setActiveCollapsible(id){
    this.activeCollapsible!==id?this.activeCollapsible=id:this.activeCollapsible=null;
  }

}
