<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content">
        <div class="content-modal" (scroll)="scrolled($event)">
            <div class=" row ">
                <label class="modal-title ">Terms & conditions</label>
            </div>
            <div class="row ">
                <label class="modal-updated ">Updated October 2020</label>
            </div>
            <div class="row ">
                <label class="modal-terms ">Accepting the terms</label>
            </div>
            <div class="row ">
                <label class="terms">
                    By accepting the terms & conditions you accept that we store, access, and use your information for
                    marketing purposes.
                </label>
            </div>
            <div class="row ">
                <label class="terms">
                    This Privacy Policy sets out how we, ReFlow Maritime ApS. obtain, store and use your personal
                    information when you use or interact with our website: www.re-flow.dk (further referred to as the
                    “Website”) or where we otherwise obtain or collect your personal information. This Privacy Policy is
                    effective from 01.10.2020.
                </label>
            </div>
            <div class="row ">
                <label class="terms">
                    Please read this Privacy Policy carefully. We recommend that you print off a copy of this Privacy
                    Policy and any future versions in force from time to time for your records. By using this Website
                    you acknowledge and agree to the terms and conditions of this Policy.
                </label>
            </div>
            <div class="row-section">
                <label style="font-weight: bold;display: block;" class="terms">
                    How we collect or obtain your information?:
                </label>
                <ol style="display: block;">
                    <li class="terms">When you provide it to us by:</li>
                    <ul>
                        <li class="terms">sending us private messages through the contact forms on our platform</li>
                        <li class="terms">making subscriptions for the courses that we offer on our platform</li>
                        <li class="terms">By filling out the profile information</li>
                    </ul>
                    <li class="terms">From your use of our website, using cookies</li>
                    <li class="terms">Occasionally from third parties</li>
                </ol>
                <label style="font-weight: bold;display: block;" class="terms">
                    Personal information we collect:
                </label>
                <ul>
                    <li class="terms">Full name, contact details (email, address, phone number), and company.</li>
                </ul>
                <label style="font-weight: bold;display: block;" class="terms">
                    How we use your personal information?:
                </label>
                <ul>
                    <li class="terms">for administrative and business purposes (particularly to contact you and answer
                        to your
                        questions or finish the registration process for the course/s you have booked on our website)
                    <li class="terms">to improve our business and website</li>
                    <li class="terms">for advertising and analytical purposes</li>
                    <li class="terms">in connection with our legal rights and obligations</li>
                    <li class="terms">For marketing and advertising</li>
                </ul>
                <label style="font-weight: bold;display: block;" class="terms">
                    Disclosure of your personal information to third parties:
                </label>
                <ul>
                    <li class="terms">only to the extent necessary to run our business</li>
                    <li class="terms">fulfil any contracts we enter into with you</li>
                    <li class="terms">where required by law or to enforce our legal rights</li>
                </ul>
                <label class="terms" style="font-weight: bold;display: block;">
                    Do we sell personal information to third parties?:
                </label>
                <ul>
                    <li class="terms">No</li>
                </ul>
                <label class="terms" style="font-weight: bold;display: block;">
                    How long we retain your personal information?
                </label>
                <label class="terms">
                    For no longer than necessary, taking into account any legal obligations we have (e.g. to maintain
                    records for tax purposes) and any other legal basis we have for using your personal information e.g.
                    your consent, performance of a contract with you or our legitimate interests as a business.
                </label>
                <label class="terms" style="font-weight: bold;display: block;">
                    How we secure your personal information?
                </label>
                <ul>
                    <li class="terms">using appropriate technical and organizational measures such as storing your
                        personal
                        information on secure on AWS</li>
                    <li class="terms">encrypting transfers of data to or from our servers</li>
                    <li class="terms">encrypting and securing our website via Secure Sockets Layer (SSL)</li>
                    <li class="terms">only granting access to your personal information where necessary</li>
                    <li class="terms">insert any additional security measures you use to protect personal information
                    </li>
                </ul>
                <label class="terms" style="font-weight: bold;display: block;">
                    Use of cookies
                </label>
                <label class="terms">
                    ReFlow Maritime ApS uses cookies on this Website for the following purposes: to enable certain
                    functions of the Website, to provide analytics, to store your preferences, to enable advertisement
                    delivery, including behavioral advertising. By using this Website you consent to the use of cookies.
                </label>
                <label class="terms">
                    Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is
                    stored in your web browser and allows this Website or a third¬party to recognize you and make your
                    next visit easier and the Service more useful to you.
                </label>
                <label class="terms">
                    In addition to our own cookies, we may also use various third¬parties cookies to report usage
                    statistics of the Website, deliver advertisements on and through the Website, and so on.
                </label>
                <label class="terms" style="font-weight: bold;display: block;">
                    Transfers of your personal information outside the European Economic Area
                </label>
                <label class="terms">
                    We will not transfer your personal information outside of the European Economic Area. Where we do
                    so, we will ensure appropriate safeguards are in place.
                </label>
            </div>
            <div class="row">
                <label class="terms" style="font-weight: bold;display: block;">
                    Your rights in relation to your personal information:
                </label>
                <ul>
                    <li class="terms">to access your personal information and to be informed about its use</li>
                    <li class="terms">to correct your personal information</li>
                    <li class="terms">to have your personal information deleted</li>
                    <li class="terms">to restrict the use of your personal information</li>
                    <li class="terms">to object to the use of your personal information</li>
                    <li class="terms">to complain to a supervisory authority </li>
                    <li class="terms">to withdraw your consent to the use of your personal information </li>
                </ul>
            </div>
            <div class="row">
                <label class="terms" style="font-weight: bold;display: block;">
                    Our details
                </label>
                <label class="terms">The data controller in respect of the platform is Reflow Maritime ApS
                    If you have questions regarding this statement or wish to request that we no longer use your
                    personal information, please contact us at info@re-flow.io</label>
            </div>
        </div>
        <div class="re-modal-footer ">
            <button class="btn-solid decline " (click)="decline()">Decline</button>
            <button [disabled]="!scrolledDown" [class]="scrolledDown ? 'accept-enabled' : 'accept-disabled' "
                (click)="accpet()">Accept</button>
        </div>
    </div>

</div>