import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
 

@Component({
  selector: 'app-life-cycle-modal',
  templateUrl: './life-cycle-modal.component.html',
  styleUrls: ['./life-cycle-modal.component.css']
})
export class LifeCycleModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() stage:any;
  @Input() public maxValue: number;

  public yearValue: number = 0;
    
  constructor() { }

  ngOnInit(): void {
  }

  cancel($event) {
    this.close.emit(null);
  }

  setYears(year)
  {
    this.yearValue = <number>year;
  }

  public confirm(){
    this.close.emit(this.yearValue);
  }

}
