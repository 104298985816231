<div class="steps">
    <label class="step">step<br>{{currentStep}}</label>
</div>
<div class="lfc">
    <p class="login-message">You are one click away from joining the changing of the world!</p>
    <p class="login-sub-message">Discover how to do it - all on your own!</p>
    <div class="vertical-tablet-icon" *ngIf="currentStep==0"></div>
    <div *ngIf="1">
        <form [formGroup]="signupForm" autocomplete="off" class="signup-form mt-4">
            <h3 class="sub-step" [ngClass]="{'disabled': currentStep==2&&startedSteps}" (click)="currentStep=1;">Account Information</h3>
            <h3 *ngIf="startedSteps" class="sub-step" [ngClass]="{'disabled': currentStep==1&&startedSteps}" (click)="currentStep=2;">Company Information</h3>
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <div class="form-row" *ngIf="currentStep==1">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Full name" formControlName="fullName" />
                    <small *ngIf="submitted && frm.fullName.errors?.required">
              *Full name is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Phone number" formControlName="phoneNumber" />
                    <small *ngIf="submitted && frm.phoneNumber.errors?.required">
              *Phone number is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Email" formControlName="workEmail" />
                    <small *ngIf="submitted && frm.workEmail.errors?.required">
              *Email is required
            </small>
                    <small *ngIf="
                submitted &&
                emailExists == true &&
                existingMail == frm.workEmail.value
              ">
              *This email already exists !
            </small>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Set password" formControlName="password" />
                    <small *ngIf="submitted && frm.password.errors?.required">
              *Password is required
            </small>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Confirm Password" formControlName="repeatPassword" />
                    <small *ngIf="
                submitted &&
                frm.password.value != frm.repeatPassword.value &&
                frm.repeatPassword.value != ''">
              *Passwords must be identical
            </small>
                    <small *ngIf="submitted && frm.repeatPassword.value == ''">
              *Repeating password is required
            </small>
                </div>
                <div style="margin: auto" class="d-none">
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <small *ngIf="submitted && frm.recaptcha.errors?.required">
              *Please fill captcha
            </small>
                </div>
                <div class="action-div" style="margin-top: 10px">
                    <button type="submit" class="btn-login" (click)="signUp()">
              NEXT
            </button>
                </div>
            </div>
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <!-------------------------------------------------------------------->
            <div class="form-row" *ngIf="currentStep==2">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Company name" formControlName="companyname" />
                    <small *ngIf="submitted && frm.companyname.errors?.required">
              *Company name is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Industry" formControlName="industry" />
                    <small *ngIf="submitted && frm.industry.errors?.required">
              *Industry is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Address" formControlName="address" />
                    <small *ngIf="submitted && frm.address.errors?.required">
              *Address is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Postal code" formControlName="postalcode" />
                    <small *ngIf="submitted && frm.postalcode.errors?.required">
              *Postal code is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" />
                    <small *ngIf="submitted && frm.city.errors?.required">
              *City is required
            </small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" />
                    <small *ngIf="submitted && frm.country.errors?.required">
              *Country is required
            </small>
                </div>
                <div class="action-div" style="margin-top: 10px">
                    <button type="submit" class="btn-login" (click)="signUp()">
              NEXT
            </button>
                </div>
            </div>
            <!-- <a class="goto-signup">Need help chat with us</a> -->
            <a class="goto-signup" (click)="goToTarget('login')">Already have an account? Log in</a>
            <hr style="width: 80px; margin-left:auto;margin-right:auto;margin-top:24px">
        </form>
        <div class="row" *ngIf="loading">
            <img src="../../../assets/loading.gif" alt="" style="margin: auto;" />
        </div>
    </div>
    <div class="footer">
        <div class="container">
            <a class="f-link mr-3">Privacy policy Terms</a>
            <a href="https://www.facebook.com/reflowmaritime"><i class="fa fa-facebook-square" aria-hidden="true"></i
            ></a>
            <a href="https://www.linkedin.com/company/reflow-maritime"><i class="fa fa-linkedin-square" aria-hidden="true"></i
            ></a>
        </div>
        <div class="container">
            <label class="copyright">V1.18 Copyrights &copy; {{ current_year }} ReFlow ApS - All rights reserved</label>
        </div>
    </div>
</div>
