<label class="h1-heading">Product inventory</label>
<label class="p-body" *ngIf="!loading">Please go through each life cycle stage, enter inventory data, and select corresponding background datasets <br> from the database. <br><br> The reference flow is set to one product and all inventory data shall commonly correspond to one unit of product <br> assessed in this project.</label>
<div class="tip-container upper-tip" *ngIf="!loading">
    <img src="../../../../../assets/tip_icon@4x.png" alt="">
    <div class="tip">
        <p class="tip-content"><strong style="">Tip</strong>: We recommend the use of ReFlow Datasheet for data curation (download link <a href="../../../../../assets/DataCall_Reflow2022.xlsx">here</a>).</p>
        <div class="feedback">
            <p>Did you find the tip useful?</p>
            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
            <i class="fa fa-thumbs-up" aria-hidden="true" style="transform: rotate(180deg);"></i>
        </div>
    </div>
</div>
<div class="loading-container" *ngIf="loading || saveLoading">
   <div class="loading-content">
      <img src="../../../../../assets/Loading-icon-animation.svg" style="height: 125px; width: 125px;" alt="" />
      <label class="h4-heading loading" *ngIf="loading">Please wait while we load necessary data for inventory </label>
      <label class="h4-heading loading" *ngIf="saveLoading">Please wait while we save your draft assestment </label>
   </div>
</div>
<div *ngIf="!loading">
   <div class="stage" *ngFor="let lifeCycle of lifeCycleArray?.controls; let i = index; trackBy:trackByFn" id="accordion">
      <hr style="width: 800px;">
      <div class="row lifecycleTitle">
      <label class="h2-heading">
      {{lifeCycle.value.stage}}
      </label>
      <div class="dropdown-div" >
        <button *ngIf="showScopesActivity" class="dropdownbtn spec {{border_class}}" type="button" id="dropdownMenuButton" data-toggle="dropdown" (click)="border_class = 'grey-border'"
         aria-haspopup="true" aria-expanded="false" aria-placeholder="specify act">
         <label class="internal-act">
            {{lifeCycle.value.activity}} <em class="fa fa-caret-down caret-down"></em>
         </label>
      </button>
      <div class="dropdown-menu" [attr.aria-labelledby]="'dropdownMenuButton'+i">
         <!-- <a class="dropdown-item dropdown-item-first activity-item" *ngIf="lifeCycle.value.activity!='Specify activity'">
         <div class="activity-content" (click)="selectActivity('Specify activity',i)">
               <div style="width: 60px;">
                  <label class="internal-act">
                     Specify activity
                  </label>&nbsp;
                  <em class="icon-info bigger-info"></em>
               </div>
         </div>
         </a> -->
         <a class="dropdown-item dropdown-item-first activity-item" *ngIf="lifeCycle.value.activity!='Internal activity'">
            <div class="activity-content" (click)="selectActivity('Internal activity',i)">
               <div style="width: 60px;">
                     <label class="internal-act">
                        Internal activity
                     </label>&nbsp;
                     <div class="icon-info-position">
                        <em class="icon-info bigger-info"></em>
                     </div>

               </div>
            </div>
         </a>
         <a class="dropdown-item dropdown-item-first activity-item" *ngIf="lifeCycle.value.activity!='External activity'">
         <div class="activity-content" (click)="selectActivity('External activity',i)">
               <div style="width: 60px;">
                  <label class="internal-act">
                     External activity
                  </label>&nbsp;
                  <div class="icon-info-position">
                     <em class="icon-info bigger-info"></em>
                  </div>
               </div>
         </div>
      </a>
   </div>
      <em [ngClass]="{
         'icon-arrow-down':activeCollapsible === i,
         'icon-arrow-right':activeCollapsible !== i
         }" data-toggle="collapse" [attr.data-target]="'#collapseInventory' + i" (click)="setActiveCollapsible(i)"></em></div>
   </div>
   <div [ngClass]="{'show':i==0 , 'collapse':i>-1}" [id]="'collapseInventory' + i">
   <div class="description-contianer">
      <label class="description" >{{getStage(lifeCycle.value.stage).description}}</label>
      <div class="tip-container upper-tip">
         <img src="../../../../../assets/tip_icon@4x.png" alt="" *ngIf="getStage(lifeCycle.value.stage).tips != ''">
         <div class="tip">
            <p class="tip-content" *ngIf="getStage(lifeCycle.value.stage).tips != ''"><strong>Tip</strong>: {{getStage(lifeCycle.value.stage).tips}}</p>
            <div class="feedback" *ngIf="getStage(lifeCycle.value.stage).tips != ''">
               <p>Did you find the tip useful?</p>
               <i class="fa fa-thumbs-up" aria-hidden="true" (click)="react(true,lifeCycle.value.stage)" [ngStyle]="{'color': getStage(lifeCycle.value.stage)?.feedback==true?'#123b63':'#6E6E6E'}"></i>
               <i class="fa fa-thumbs-up" aria-hidden="true" (click)="react(false,lifeCycle.value.stage)" style="transform: rotate(180deg);" [ngStyle]="{'color': getStage(lifeCycle.value.stage)?.feedback==false?'#123b63':'#6E6E6E'}"></i>
            </div>
         </div>
      </div>
   </div>
   <div class="white-space"></div>
   <!---to do if (lifeCycle.value.stage == Refurbishment )lifeCycle.value.lifeSpan -->
   <app-lifespan-modal *ngIf="lifespanModal" (close)="closeLifeSpanModal($event)" [lifeSpanValue]="lifeCycle.value.lifeSpan" [stage]="lifeCycle.value.stage" [maxValue]="screening?.product?.lifeSpan"></app-lifespan-modal>
   <div class="d-flex justify-content-center align-items-center flex-column" *ngIf="lifeCycle.value.stage == 'Manufacturing' || lifeCycle.value.stage == 'Maintenance' || lifeCycle.value.stage == 'Remanufacturing' || lifeCycle.value.stage == 'Refurbishment' || lifeCycle.value.stage == 'Reconditioning' || lifeCycle.value.stage == 'Disposal' || lifeCycle.value.stage == 'Installation' || lifeCycle.value.stage == 'Recycling' ">
      <hr class="input-hr">
      <label class="in-out stage-head" >Input to life cycle stage</label>
   </div>
   <div *ngIf="lifeCycle.value.stage == 'Reconditioning'" style="margin-bottom: 40px;">
      <label class="h3-heading-upper" style="margin-top: 0px;">Prolonged lifespan</label>
      <label class="description">The Reconditioning process prolongs the lifespan with {{lifeCycle.value.lifeSpan}} years.<br>Avoiding the production of new materials within the lifespan </label>
      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null"  (click)="showLifeSpanModal(true,'Reconditioning')">
      <label>{{ lifeCycle.value.lifeSpan }} years</label>
      <em class="icon icon-edit" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.value.stage == 'Refurbishment'" style="margin-bottom: 40px;">
      <label class="h3-heading-upper" style="margin-top: 0px;">Prolonged lifespan</label>
      <label class="description">The Refurbishment process prolongs the lifespan with {{lifeCycle.value.lifeSpan}} years. Avoiding the production of new materials within the lifespan </label>
      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="showLifeSpanModal(true,'Refurbishment')">
      <label>{{ lifeCycle.value.lifeSpan }} years</label>
      <em class="icon icon-edit" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('materials')!=null">
      <label class="h3-heading-upper" style="margin-top: 0px;">materials</label>
      <div class="hint-div" >
        <div data-toggle="collapse" data-target=".to-coll" aria-expanded="false" aria-controls="to-coll" class="bubble" (click)="showMoreMessage()" id="bubblematerial" *ngIf="showMaterialMessage"  (mouseout)="showMaterialMessage=false">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.materials.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" src="../../../assets/{{arrowType}}" alt="" class="sort-icon" id="showMaterialMessage" (mouseover)="showMaterialMessage=true" data-toggle="collapse" data-target=".to-coll" aria-expanded="false" aria-controls="to-coll" (click)="showMoreMessage()" (mouseout)="showMaterialMessage=false">
            <em class="fa fa-trash-o" (click)="deleteMaterial('materials',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let material of lifeCycle.value.materials; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + material.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{material.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{material.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="material.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(material.unit,'material',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{material.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['material'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=material.unit" (click)="selectUnit('materials',material,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{material.id}}' value="{{material.id}}"  (change)="selectAnswer(cid,material, $event,'Materials')"></td>
         </tr>
         <tr class="to-coll collapse show" [id]="'row-' + material?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="material.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="material.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="material.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>
      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Materials')">
      <label>Add material</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('transports')!=null">
      <label class="h3-heading-upper">Transport</label>
      <div class="hint-div">
        <div *ngIf="showTransportMessage" (mouseout)="showTransportMessage=false" data-toggle="collapse" data-target=".to-coll" aria-expanded="false" aria-controls="to-coll-t" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" src="../../../assets/{{arrowType}}" alt="" class="sort-icon" (mouseover)="showTransportMessage=true" (mouseout)="showTransportMessage=false" data-toggle="collapse" data-target=".to-coll" aria-expanded="false" aria-controls="to-coll-t" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('transports',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let transport of lifeCycle.value.transports; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + transport.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{transport.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{transport.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="transport.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(transport.unit,'transport',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{transport.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['transport'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=transport.unit" (click)="selectUnit('transports',transport,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{transport.id}}' value="{{transport.id}}"  (change)="selectAnswer(cid,transport, $event,'transports')"></td>
         </tr>
         <tr class="to-coll-t collapse show" [id]="'row-' + transport?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="transport.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="transport.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="transport.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>



      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Distribution')">
      <label>Add transport</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('energies')!=null">
      <label class="h3-heading-upper">energy usage</label>
      <div class="tip-container upper-tip">
         <img src="../../../../../assets/tip_icon@4x.png" alt="">
         <div class="tip">
            <p class="tip-content"><strong>Tip</strong>: {{getStage('energy usage').tips }}</p>
            <div class="feedback">
               <p>Did you find the tip useful?</p>
               <i class="fa fa-thumbs-up" aria-hidden="true" (click)="react(true,'energy usage')" [ngStyle]="{'color': getStage('energy usage')?.feedback==true?'#123b63':'#6E6E6E'}"></i>
               <i class="fa fa-thumbs-up" aria-hidden="true" (click)="react(false,'energy usage')" [ngStyle]="{'color': getStage('energy usage')?.feedback==false?'#123b63':'#6E6E6E'}" style="transform: rotate(180deg);"></i>
            </div>
         </div>
      </div>
      <div class="hint-div">
        <div *ngIf="showEnegeryUsageMessage"  (mouseout)="showEnegeryUsageMessage=false"  data-toggle="collapse" data-target=".to-coll-eu" aria-expanded="false" aria-controls="to-coll-eu" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.energies.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" (mouseover)="showEnegeryUsageMessage=true" src="../../../assets/{{arrowType}}" alt="" class="sort-icon"  (mouseout)="showEnegeryUsageMessage=false"  data-toggle="collapse" data-target=".to-coll-eu" aria-expanded="false" aria-controls="to-coll-eu" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('energies',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let energy of lifeCycle.value.energies; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + energy.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{energy.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{energy.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="energy.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(energy.unit,'energy',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{energy.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['energy'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=energy.unit" (click)="selectUnit('energies',energy,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{energy.id}}' value="{{energy.id}}"  (change)="selectAnswer(cid,energy, $event,'energies')"></td>
         </tr>
         <tr class="to-coll-eu collapse show" [id]="'row-' + energy?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="energy.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="energy.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="energy.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>


      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Usage')">
      <label>Add energy/fuel use</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('maritimeComponents')!=null">
      <label class="h3-heading-upper">maritime components</label>
      <div class="hint-div">
        <div *ngIf="showMaritimeComponentMessage" (mouseout)="showMaritimeComponentMessage=false" data-toggle="collapse" data-target=".to-coll-mc" aria-expanded="false" aria-controls="to-coll-mc" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.maritimeComponents.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" (mouseover)="showMaritimeComponentMessage=true"  src="../../../assets/{{arrowType}}" alt="" class="sort-icon" (mouseout)="showMaritimeComponentMessage=false" data-toggle="collapse" data-target=".to-coll-mc" aria-expanded="false" aria-controls="to-coll-mc" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('maritimeComponents',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let maritimeComponent of lifeCycle.value.maritimeComponents; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + maritimeComponent.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{maritimeComponent.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{maritimeComponent.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="maritimeComponent.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(maritimeComponent.unit,'maritimeComponent',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{maritimeComponent.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['maritimeComponent'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=maritimeComponent.unit" (click)="selectUnit('maritimeComponents',maritimeComponent,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{maritimeComponent.id}}' value="{{maritimeComponent.id}}"  (change)="selectAnswer(cid,maritimeComponent, $event,'maritimeComponents')"></td>
         </tr>
         <tr class="to-coll-mc collapse show" [id]="'row-' + maritimeComponent?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="maritimeComponent.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="maritimeComponent.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="maritimeComponent.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>

      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'MARITIME SYSTEMS & COMPONENTS')">
      <label>Add maritime component</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('electronicComponents')!=null">
      <label class="h3-heading-upper">electronic components</label>
      <div class="hint-div">
        <div *ngIf="showElectronicComponentMessage"  (mouseout)="showElectronicComponentMessage=false" data-toggle="collapse" data-target=".to-coll-ec" aria-expanded="false" aria-controls="to-coll-ec" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.electronicComponents.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" (mouseover)="showElectronicComponentMessage=true" src="../../../assets/{{arrowType}}" alt="" class="sort-icon"  (mouseout)="showElectronicComponentMessage=false" data-toggle="collapse" data-target=".to-coll-ec" aria-expanded="false" aria-controls="to-coll-ec" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('electronicComponents',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let electronicComponent of lifeCycle.value.electronicComponents; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + electronicComponent.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{electronicComponent.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{electronicComponent.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="electronicComponent.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(electronicComponent.unit,'electronicComponent',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{electronicComponent.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['electronicComponent'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=electronicComponent.unit" (click)="selectUnit('electronicComponents',electronicComponent,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{electronicComponent.id}}' value="{{electronicComponent.id}}"  (change)="selectAnswer(cid,electronicComponent, $event,'electronicComponents')"></td>
         </tr>
         <tr class="to-coll-ec collapse show" [id]="'row-' + electronicComponent?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="electronicComponent.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="electronicComponent.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="electronicComponent.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>


      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Electronic components')">
      <label>Add electronic component</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('sharedComponents')!=null">
      <label class="h3-heading-upper">Shared and imported products</label>
      <div class="hint-div">
        <div *ngIf="showSharedImportedMessage" (mouseout)="showSharedImportedMessage=false" data-toggle="collapse" data-target=".to-coll-si" aria-expanded="false" aria-controls="to-coll-si" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.sharedComponents.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" (mouseover)="showSharedImportedMessage=true"  src="../../../assets/{{arrowType}}" alt="" class="sort-icon"  (mouseout)="showSharedImportedMessage=false" data-toggle="collapse" data-target=".to-coll-si" aria-expanded="false" aria-controls="to-coll-si" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('sharedComponents',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let sharedComponent of lifeCycle.value.sharedComponents; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + sharedComponent.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{sharedComponent.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{sharedComponent.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="true" ngDefaultControl [(ngModel)]="sharedComponent.weight" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(sharedComponent.unit,'sharedComponent',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >Kg</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['sharedComponent'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=sharedComponent.unit" (click)="selectUnit('sharedComponents',sharedComponent,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{sharedComponent.id}}' value="{{sharedComponent.id}}"  (change)="selectAnswer(cid,sharedComponent, $event,'sharedComponents')"></td>
         </tr>
         <tr class="to-coll-si collapse show" [id]="'row-' + sharedComponent?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="sharedComponent.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="sharedComponent.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="sharedComponent.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>

      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Shared Component')">
      <label>Add shared component</label>
      <em class="icon icon-add"></em>
      </button>
   </div>
   <div *ngIf="false">
      <label class="h3-heading-upper">direct emissions</label>
      <button type="button" class="btn btn-select">
      <label>- Upcoming feature -</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div class="d-flex justify-content-center align-items-center flex-column"  *ngIf="lifeCycle.value.stage == 'Manufacturing' || lifeCycle.value.stage == 'Maintenance' || lifeCycle.value.stage == 'Remanufacturing' || lifeCycle.value.stage == 'Refurbishment' || lifeCycle.value.stage == 'Reconditioning' || lifeCycle.value.stage == 'Disposal' || lifeCycle.value.stage == 'Installation' || lifeCycle.value.stage == 'Recycling' ">
      <hr class="input-hr" style="margin-top: 60px;">
      <label class="in-out"  style="margin-bottom: uns;">Output to life cycle stage</label>
   </div>
   <div *ngIf="lifeCycle.get('wastes')!=null">
      <label class="h3-heading-upper" style="margin-top: 0px;">Waste materials</label>
      <div class="hint-div">
        <div data-toggle="collapse" *ngIf="showWasetMessage" (mouseout)="showWasetMessage=false" data-target=".to-coll-w" aria-expanded="false" aria-controls="to-coll-w" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.wastes.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" src="../../../assets/{{arrowType}}" alt="" class="sort-icon" (mouseover)="showWasetMessage=true" (mouseout)="showWasetMessage=false" data-toggle="collapse" data-target=".to-coll-w" aria-expanded="false" aria-controls="to-coll-w" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('wastes',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let waste of lifeCycle.value.wastes; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + waste.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{waste.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{waste.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="waste.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(waste.unit,'waste',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{waste.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['waste'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=waste.unit" (click)="selectUnit('wastes',waste,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{waste.id}}' value="{{waste.id}}"  (change)="selectAnswer(cid,waste, $event,'wastes')"></td>
         </tr>
         <tr class="to-coll-w collapse show" [id]="'row-' + waste?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="waste.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="waste.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="waste.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>

      <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Waste')">
      <label>Add waste</label>
      <em class="icon icon-add" ></em>
      </button>
   </div>
   <div *ngIf="lifeCycle.get('recycledMaterials')!=null">
      <label class="h3-heading-upper">Recycled materials</label>
      <div class="hint-div">
        <div data-toggle="collapse" *ngIf="showRecycledMessage" (mouseout)="showRecycledMessage=false" data-target=".to-coll-rm" aria-expanded="false" aria-controls="to-coll-rm" class="bubble" (click)="showMoreMessage()">{{showMessage}}</div>
      </div>
      <table class="itable" *ngIf="lifeCycle.value.recycledMaterials.length !=0">
         <th id="" class="small-td header-text">Details</th>
         <th id="" colspan="4">
            <img _ngcontent-lml-c183="" src="../../../assets/{{arrowType}}" alt="" class="sort-icon" (mouseover)="showRecycledMessage=true" (mouseout)="showRecycledMessage=false" data-toggle="collapse" data-target=".to-coll-rm" aria-expanded="false" aria-controls="to-coll-rm" (click)="showMoreMessage()">
            <em class="fa fa-trash-o" (click)="deleteMaterial('recycledMaterials',lifeCycle.value.id)"></em>
         </th>
         <th id="" class="small-td header-text">Select</th>
         <ng-container *ngFor="let recycledMaterial of lifeCycle.value.recycledMaterials; let cid=index">
         <tr>
            <td data-toggle="collapse" [attr.data-target]="'#row-' + recycledMaterial.id" aria-expanded="false" aria-controls="row-1" class="small-td arrow-td"><i class="icon-arrow-right"></i></td>
            <td class="m-name">{{recycledMaterial.name}}</td>
            <td class="m-name"><i class="fa fa-map-marker" aria-hidden="true"></i>{{recycledMaterial.locationName}}</td>
            <td class="m-name"><input type="text" [attr.disabled]="disable?'':null" ngDefaultControl [(ngModel)]="recycledMaterial.quantity" placeholder="0" class="form-control multiple-control no-br">
               {{findConcept(recycledMaterial.unit,'recycledMaterial',cid)}}</td>
            <td class="m-name unit-td">
               <div class="input-group-prepend" style="width: 100%;" ngbDropdown #myDrop="ngbDropdown" >
               <span class="input-group-text d-flex justify-content-between no-br full-width" ngbDropdownToggle >{{recycledMaterial.unit}}</span>
               <div ngbDropdownMenu  aria-labelledby="dropdownBasic1"  class="unit-dropdown"  >
                  <ng-container  *ngFor="let item of tempConcept['recycledMaterial'+cid]" ngbDropdownItem>
                     <p *ngIf="item!=recycledMaterial.unit" (click)="selectUnit('recycledMaterials',recycledMaterial,item);myDrop.close();">{{item}}</p>
                  </ng-container>
               </div>
            </div>
            </td>
            <td class="small-td m-name"><input type="checkbox" name='{{recycledMaterial.id}}' value="{{recycledMaterial.id}}"  (change)="selectAnswer(cid,recycledMaterial, $event,'recycledMaterials')"></td>
         </tr>
         <tr class="to-coll-rm collapse show" [id]="'row-' + recycledMaterial?.id">
            <td colspan="2"><input type="text" placeholder="Add Note" [(ngModel)]="recycledMaterial.note" class="itable-input"></td>
            <td><input type="text" placeholder="Add Material nr." [(ngModel)]="recycledMaterial.materialNumber" class="itable-input"></td>
            <td colspan="3"><input type="text" placeholder="Add Product nr." [(ngModel)]="recycledMaterial.productNumber" class="itable-input"></td>
         </tr>
         </ng-container>
      </table>


         <button type="button" class="btn btn-select" [attr.disabled]="disable?'':null" (click)="openMaterialModal(lifeCycle.value.id,'Recycled Materials')">
         <label>Add recycled material</label>
         <em class="icon icon-add" ></em>
         </button>
      </div>
      <div class="next-stage-container">
         <button class="big-turq-outline" (click)="moveToNext(lifeCycle.value, i)" data-toggle="collapse" [attr.data-target]="'#collapseInventory' + lifeCycleArray?.controls[i+1]?.value.id" aria-expanded="true">
         <label style="color: black;">Next stage</label>
         </button>
      </div>
   </div>
   </div>
   <hr>
</div>
<div class="footerContainer"  *ngIf="!loading">
   <div class="buttons" style="position:unset !important">
      <button class="simple-button prev" (click)="previous()">
      <label>
      Previous step
      </label>
      </button>
      <div class="right-buttons">
         <button class="simple-button save" (click)="save()" [ngClass]="{'disabled': disable==true}">
         <label>
         Save and close
         </label>
         </button>
         <button class="big-turq next" (click)="next()">
         <label>
         Next
         </label>
         </button>
      </div>
   </div>
   <div class="turq">
   </div>
</div>
<p class="description" *ngIf="!loading">Project ID: {{screening_reflow_id}}</p>
