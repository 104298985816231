<div class="section introduction">
  <div class="overlay"></div>
  <video
    playsinline="playsinline"
    autoplay="autoplay"
    muted="muted"
    loop="loop"
  >
    <source src="../../../../assets/calculator/co2bg.mp4" type="video/mp4" />
  </video>
  <div class="introduction-center">
    <img
      class="index-image"
      src="../../../../assets/calculator/index-illustration.svg"
      alt=""
    />
    <label class="title"
      >Calculate your <br class="br-hide" />
      carbon footprint</label
    >
    <label class="subtitle">
      Explore the potential of carbon footprint savings<br class="br-hide" />
      on maritime components and systems
    </label>
    <button class="btn-tutorial" (click)="openTutorial()">
      <img src="../../../../assets/calculator/calculator_play.svg" alt=""/>
      <label>Watch tutorial</label>
    </button>
  </div>
  <div class="introduction-footer">
    <div class="row">
      <div class="col">
        <div class="fond">
          <label class="support">Supported by</label>
          <img
            class="fond-image"
            src="../../../../assets/calculator/logo-danske-maritime.svg"
            alt=""
          />
        </div>
      </div>
      <div class="col">
        <div class="icon-scroll" (click)="scrollDown(vesselSection)"></div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>

<div class="section vessel" #vesselSection>
  <img
    class="section-count"
    src="../../../../assets/calculator/circle-no1.svg"
    alt=""
  />
  <label class="co2-section-title">Vessel</label>
  <app-vessel-section
    (submitAge)="getAge($event)"
    (submitArea)="getArea($event)"
    (submitType)="getType($event)"
  ></app-vessel-section>
</div>
<img
  src="../../../../assets/calculator/section-comp-top.svg"
  alt=""
  style="background: white"
/>
<div class="section component">
  <img
    class="section-count"
    src="../../../../assets/calculator/circle-no2.svg"
    alt=""
  />
  <label class="co2-section-title">Component</label>
  <app-component-section
    [vesselArea]="vesselArea"
    [vessellife]="vesselAge"
    (submitGroup)="getGroup($event)"
    (submitSpan)="getSpan($event)"
    (submitWeight)="getWeight($event)"
  ></app-component-section>
</div>
<img
  src="../../../../assets/calculator/section-fade-comp-bottom.svg"
  alt=""
  style="background: white"
/>
<div class="section scenario-section">
  <img
    class="section-count"
    src="../../../../assets/calculator/circle-no3.svg"
    alt=""
  />
  <label class="co2-section-title">Scenario</label>
  <app-scenario-section
    (submitScenario)="getScenario($event)"
  ></app-scenario-section>
  <div class="section scenario">
    <button class="calculate" (click)="calculate()">
      <img
        class="btn-icon"
        src="../../../../assets/calculator/button-calculate-leaf.svg"
        alt=""
      />
      <label class="btn-text">Calculate footprint</label>
    </button>
  </div>
</div>
<div id="openModal-about" class="modalDialog" *ngIf="showTutorial">
  <em class="icon-close" id="close-tutorial" (click)="closeTutorial()"></em>
  <div class="re-modal-content">
    <iframe src="https://player.vimeo.com/video/484388224?autoplay=1" 
    width="100%" 
    height="100%" 
    allow="autoplay; fullscreen" 
    title="Tutorial" 
    ></iframe>
  </div>
</div>
