import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { FileUploadService } from "src/app/services/file-upload.service";
import { StatusService } from "src/app/services/status.service";
import { Status } from "src/app/models/status.model";
import { ComponentLog } from "src/app/models/component-log";
import { ComponentLogService } from "src/app/services/component-log.service";
import { Warehouse } from "src/app/models/warehouse";
import { WarehouseService } from "src/app/services/warehouse.service";
import { ActivatedRoute } from "@angular/router";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { Vessel } from "src/app/models/vesset";
import { VesselService } from "src/app/services/vessel.service";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { TransitVO } from "src/app/models/transit-vo";
import { LocationService } from "src/app/services/location.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-update-status",
  templateUrl: "./update-status.component.html",
  styleUrls: ["./update-status.component.css"],
})
export class UpdateStatusComponent implements OnInit {
  @Input() public showStatusModal: boolean;
  @Input() public componentId: number;
  @Input() public currentComponent: any;
  @Output() public closeStatusModal = new EventEmitter();

  public typed: string;
  public newStatus: any;
  public submitted: boolean;
  public files: any = [];
  public imgSrc: any = null;
  public fileData: File = null;
  public selected: string;
  public filesToUpload: any[];
  public componentFiles: FormArray;
  public lastStatus: any;
  public warehouses: Warehouse[];
  public locations: Location[];
  public vesselOperators: Company[];
  public vessels: Vessel[];
  public txtwarehouse: string;
  public txtvesselOperator: string;
  public txtvessel: string;
  public txtexchangeComponent: string;
  public txtlocation: string;
  public exchangeUnits: any[];

  constructor(
    private fb: FormBuilder,
    private statusService: StatusService,
    private componentLogService: ComponentLogService,
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private vesselsService: VesselService,
    private CCIService: ComponentCoreInformationService,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {

    this.warehouseService
      .retrieveWarehouses(Number(this.route.snapshot.paramMap.get("companyId")))
      .subscribe((res) => {
        this.warehouses = res;
      });
    this.companyService.retrieveVesselOperators().subscribe((res) => {
      this.vesselOperators = res;
    });

    this.newStatus = this.fb.group({
      componentId: this.componentId,
      currentStatus: [this.currentComponent?.status, Validators.required],
      reference: [this.currentComponent?.reference, Validators.required],
      scenarioSelector: [
        this.currentComponent?.scenarioSelector == null
          ? "N/A"
          : this.currentComponent?.scenarioSelector,
        Validators.required,
      ],
      warehouse: ["", Validators.required],
      warehouseId: [0],
      locationId: [0],
      vessel: ["", Validators.required],
      vesselId: [0],
      exchangeComponentId: [""],
      exchangeComponent: [""],
      vesselOperatorId: [0],
      updatedAt: [new Date()],
      location: [this.currentComponent?.location, Validators.required],
      recommendedHours: [
        this.currentComponent?.recommendedHours,
        Validators.required,
      ],
      statusFiles: this.fb.array([]),
    });
    this.selected = this.currentComponent?.status;
  }

  public selectWarehouse(warehouse: any): void {
    this.newStatus.patchValue({
      warehouseId: warehouse.id,
      warehouse: warehouse.warehouseName,
    });
  }

  public selectVesselOperator(vop: any): void {
    this.newStatus.patchValue({
      vesselOperatorId: vop.id,
      vesselId: 0,
      vessel: "",
    });
    this.vessels = null;
    this.txtvesselOperator = vop.companyName;
    this.vesselsService.getVessels(vop.id).subscribe((res) => {
      this.vessels = res;
    });
  }

  public selectVessel(vessel: any): void {
    this.newStatus.patchValue({
      vesselId: vessel.id,
      vessel: vessel.vesselName,
    });
    this.txtlocation = "";
    this.locationService.getLocationsByVesselId(vessel.id).subscribe((res) => {
      this.locations = res;
    });
  }

  public selectLocation(location: any): void {
    this.newStatus.patchValue({
      locationId: location.id,
      location: location.name,
    });
    this.CCIService.getExchangeComponents(
      this.newStatus.controls.locationId.value,
      this.componentId
    ).subscribe((res) => {
      this.exchangeUnits = res;
    });
  }

  get frm() {
    return this.newStatus.controls;
  }

  public makeSelection(): void {
    this.selected = this.newStatus.controls.currentStatus.value;
  }

  public hideModal(): void {
    this.showStatusModal = false;
    this.closeStatusModal.emit(this.showStatusModal);
  }

  public updateStatus(): void {
    let status = new Status(this.newStatus.value);
    status.currentStatus = this.selected;
    this.statusService
      .updateComponentStatus(new Status(status))
      .subscribe((res) => {
        let componentLog: ComponentLog = {
          id: 0,
          componentId: Number(res),
          userId: Number(localStorage.getItem("user_id")),
          createdAt: new Date(),
          action:
            "changed status from " +
            this.currentComponent.status +
            " to " +
            this.newStatus.controls.currentStatus.value,
          componentLogFiles:
            this.componentFiles != undefined ? this.componentFiles.value : [],
        };
        componentLog.componentId = this.componentId;
        this.componentLogService
          .addComponentLog(componentLog)
          .subscribe((log) => { });
        if (
          this.newStatus.controls.currentStatus.value ==
          "Transit to vessel operator"
        ) {
          let tvo = new TransitVO();
          tvo.componentId = this.currentComponent.id;
          tvo.voId = this.frm.vesselOperatorId.value;
          tvo.vesselId = this.frm.vesselId.value;
          this.CCIService.transitToVesselOperator(tvo).subscribe((log) => { });
        }
        this.hideModal();
      });
  }

  public selectUnit(unit: any): void {
    this.newStatus.patchValue({
      exchangeComponentId: unit.id,
      exchangeComponent: unit.specification,
    });
  }

  public updateFiles(file) {
    this.componentFiles = this.newStatus.get("statusFiles") as FormArray;
    this.filesToUpload = this.componentFiles.value;
    this.componentFiles.push(file);
  }

  public reset() {
    this.ngOnInit();
  }
}
