import { Component, OnInit } from "@angular/core";
import { ComponentOverviewDto } from "src/app/models/dtos.model";
import { ActivatedRoute } from "@angular/router";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";

@Component({
  selector: "app-unassigned-components-overview",
  templateUrl: "./unassigned-components-overview.component.html",
  styleUrls: ["./unassigned-components-overview.component.scss"],
})
export class UnassignedComponentsOverviewComponent implements OnInit {
  public showEditComponent: boolean = false;
  public showDetailComponent: boolean = false;
  public showComponent: boolean = false;
  public companyId: number;
  public componentId: number;
  public componentList: ComponentOverviewDto[];
  public currentComponent: any;
  public searchTerm: string = "";
  public fieldName: string;
  public attr: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private componentCoreInfomationService: ComponentCoreInformationService
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.showComponentList();
    });
  }

  public showModal(isShow: boolean, component: ComponentOverviewDto) {
    this.componentId = component?.id;
    this.showComponent = isShow;
    this.showComponentList();
    this.currentComponent = component;
  }

  public selectComponent(isShpow: boolean, component: ComponentOverviewDto) {
    this.componentId = component?.id;

    this.showComponentList();
  }

  public showComponentList(): void {
    this.componentCoreInfomationService
      .getUnassinedComponents(this.companyId)
      .subscribe((res) => {
        this.componentList = res;
      });
  }

  public greater(x, y) {
    return x > y;
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
