<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Warehouses</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
            name="searchTerm" />
        </div>
        <div class="col-md-4">
          <button class="btn-solid primary" id="addBtn" (click)="selectedWarehouse = null; showEditModal(true)">
            <em class="fa fa-plus"></em>Add Warehouse
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('warehouseName','')"><label style="margin-left: 10px;">Name</label></th>
        <th id="" (click)="orderBy('address1','')">Address</th>
        <th id="" (click)="orderBy('city','')">City</th>
        <th id="" (click)="orderBy('country','')">Country</th>
        <th id="" (click)="orderBy('fullName','')">Contact person</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let warehouse of warehouses | filterPipe:searchTerm | sort:fieldName:attr">
        <td style="font-weight: bold;">
          {{ warehouse.warehouseName }}
        </td>
        <td>{{ warehouse.address1 }}</td>
        <td>{{ warehouse.city }}</td>
        <td>{{ warehouse.country }}</td>
        <td>{{ warehouse.contactPerson.fullName }}</td>
        <td>
          <em class="fa fa-bars" (click)="selectWarehouse(warehouse)"></em>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-edit-warehouse (toogleModal)="showEditModal($event)" [showForm]="showEditView"
  [warehouseToEdit]="selectedWarehouse" *ngIf="showEditView">
</app-edit-warehouse>
