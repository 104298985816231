<div class="loading" *ngIf="loadingSave" style="position: unset;">
    <img src="../../../../../assets/Loading-icon-animation.svg" class="img-loading" alt="" />
    <label class="h4-heading label-loading">calculating your environmental impacts</label>
</div>
<label class="h1-heading">Review validation request data</label>
<div class="loading-container" *ngIf="loading">
    <div class="loading-content">
        <img src="../../../../../assets/Loading-icon-animation.svg" style="height: 125px; width: 125px;" alt="" />
        <label class="h4-heading loading">Please wait while we load complete screening framework </label>
    </div>
</div>
<div *ngIf="!loading" class="review-content">
    <hr>
    <div class="row" data-toggle="collapse" data-target="#screening-setup" aria-expanded="false" aria-controls="screening-setup" (click)="setActiveCollapsible(1)">
        <h1 class="title">Project setup</h1>
        <em [ngClass]="{
            'icon-arrow-down':activeCollapsible === 1,
            'icon-arrow-right':activeCollapsible !== 1
        }"></em>
    </div>
    <div class="collapse show" id="screening-setup" #screeningSetup>
        <div class="section1">
            <div class="form-group">
                <p class="p-heading">Name of Project</p>
                <input type="text" disabled="disabled" class="form-control" id="name" [ngModel]="screeningReview?.name">
            </div>
        </div>
        <label class="h3-heading-upper">product specification</label>
        <p class="p-heading">Name</p>
        <input disabled="disabled" type="text" class="form-control" id="name" [ngModel]="screeningReview?.product.name">
        <p class="p-heading">Type</p>
        <input disabled="disabled" type="text" class="form-control" id="type" [ngModel]="screeningReview?.product.type">
        <div class="row mt">
            <div class="col-6">
                <div class="form-group">
                    <p class="p-heading">Weight</p>
                    <input disabled="disabled" type="text" class="form-control" id="weight" name="weight" [ngModel]="screeningReview?.product.weight">
                    <span class="unit">kg</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <p class="p-heading">Lifespan</p>
                    <input disabled="disabled" type="text" class="form-control" id="lifespan" name="lifespan" [ngModel]="screeningReview?.product.lifeSpan">
                    <span class="unit">years</span>
                </div>
            </div>
        </div>
        <label class="h3-heading-upper">product function</label>
        <div class="section2">
            <div class="form-group">
                <p class="p-heading">Briefly describe your product function(s)</p>
                <input disabled="disabled" type="text" class="form-control" id="quantity" name="quantity" [ngModel]="screeningReview?.product.quantity">
            </div>
            <div class="form-group mt">
                <p class="p-heading">Briefly describe your product application(s)</p>
                <input disabled="disabled" type="text" class="form-control" id="durability" name="durability" [ngModel]="screeningReview?.product.durability">
            </div>
            <div class="form-group mt">
                <p class="p-heading">Briefly list industries related to the product application</p>
                <input disabled="disabled" type="text" class="form-control" id="centralProperties" name="centralProperties" [ngModel]="screeningReview?.product.centralProperties">
            </div>
        </div>
        
    <hr>
    <div class="row" data-toggle="collapse" data-target="#system-definition" aria-expanded="false" aria-controls="system-definition" (click)="setActiveCollapsible(2)">
        <h1 class="title">System definition</h1>
        <em [ngClass]="{
            'icon-arrow-down':activeCollapsible === 2,
            'icon-arrow-right':activeCollapsible !== 2
          }"></em>
    </div>
    <div class="collapse" id="system-definition" #systemDefinition>
        <label class="h3-heading-upper">product life cycle</label>
        <div class="divlayout" *ngFor="let lifeCycle of screeningReview?.lifeCycles">
            <em class="fa fa-bars order"></em>
            <label>{{lifeCycle.stage}} </label>
        </div>
    </div>
    <hr>
    <div class="row" data-toggle="collapse" data-target="#screening-inventory" aria-expanded="false" aria-controls="screening-inventory" (click)="setActiveCollapsible(3)">
        <h1 class="title">Product inventory</h1>
        <em [ngClass]="{
            'icon-arrow-down':activeCollapsible === 3,
            'icon-arrow-right':activeCollapsible !== 3
          }"></em>
    </div>
    <div class="collapse" id="screening-inventory" #screeningInventory>
        <div *ngIf="loadingInventory">
            <div class="loading-content">
                <img src="../../../../../assets/Loading-icon-animation.svg" class="loading-image" alt="" />
            </div>
        </div>
        <div *ngIf="!loadingInventory">
            <div *ngFor="let lc of screeningReview?.lifeCycles">
                <label class="h3-heading">{{lc.stage}}</label>
                <div *ngIf="lc?.materials?.length!=0">
                    <label class="h3-heading-upper" style="margin-bottom: 30px;">materials</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let material of lc.materials">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{material.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{material.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{material.quantity}} {{material.unit}}</span>
                        </div>
                        <input disabled="disabled" type="text" [(ngModel)]="material.note" placeholder="Add note" class="form-control multiple-control">
                       
                    </div>
                </div>
                <div *ngIf="lc?.transports?.length > 0">
                    <label class="h3-heading-upper" style="margin-bottom: 30px;">Transport</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let transport of lc.transports">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{transport.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{transport.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{transport.quantity}} {{transport.unit}}</span>
                        </div>
                        <input disabled="disabled" type="text" [(ngModel)]="transport.note" placeholder="Add note" class="form-control multiple-control">
                       
                    </div>

                </div>
                <div *ngIf="lc?.energies?.length > 0">
                    <label class="h3-heading-upper" style="margin-bottom: 30px;">energy usage</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let energy of lc.energies">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{energy.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{energy.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{energy.quantity}} {{energy.unit}}</span>
                        </div>
                        <input disabled="disabled" type="text" [(ngModel)]="energy.note" placeholder="Add note" class="form-control multiple-control">
                       
                    </div>
                </div>
                <div *ngIf="lc?.maritimeComponents?.length > 0">
                    <label class="h3-heading-upper">maritime components</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let maritimeComponent of lc.maritimeComponents">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{maritimeComponent.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{maritimeComponent.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{maritimeComponent.quantity}} {{maritimeComponent.unit}}</span>
                        </div>
                        <input disabled="disabled" type="text" [(ngModel)]="maritimeComponent.note" placeholder="Add note" class="form-control multiple-control">
                        
                    </div>
                </div>
                <div *ngIf="lc?.electronicComponents?.length > 0">
                    <label class="h3-heading-upper">electronic components</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let electronicComponent of lc.electronicComponents">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{electronicComponent.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{electronicComponent.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{electronicComponent.quantity}} {{electronicComponent.unit}}</span>
                        </div>
                        <input type="text" [(ngModel)]="electronicComponent.note" placeholder="Add note" class="form-control multiple-control">
                        
                    </div>
                </div>
                <div *ngIf="lc?.sharedComponents?.length > 0">
                    <label class="h3-heading-upper">Shared and imported products</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let sharedComponent of lc.sharedComponents">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{sharedComponent.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{sharedComponent.weight}} Kg</span>
                        </div>
                        <input type="text" [(ngModel)]="sharedComponent.note" placeholder="Add note" class="form-control multiple-control">
                       
                    </div>
                </div>
                <div *ngIf="lc?.wastes?.length > 0">
                    <label class="h3-heading-upper" style="margin-bottom: 30px;">waste process</label>
                    <div class="input-group mb-3 multiple-input" *ngFor="let waste of lc.wastes">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{waste.name}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text"><em class="icon-location"></em> {{waste.locationName}}</span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text with-unit">{{waste.quantity}} {{waste.unit}}</span>
                        </div>
                        
                        <input disabled="disabled" type="text" [(ngModel)]="waste.note" placeholder="Add note" class="form-control multiple-control">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div>
<p class="description">Project ID: {{screening_reflow_id}}</p>