import { Component, OnInit, Output, EventEmitter, Input, VERSION, SimpleChanges } from '@angular/core'; 
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-shared-with-companies',
  templateUrl: './shared-with-companies.component.html',
  styleUrls: ['./shared-with-companies.component.scss']
})
export class SharedWithCompaniesComponent implements OnInit {
  @Input() public screeningId: boolean;
  @Output() companiesModalEvent =new EventEmitter<any>();
  name = 'Angular ' + VERSION.major;
  constructor(private shareService:ShareService) { }
  invitations: any; /*= [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
  ];*/

  openCoverages = false;
  indexSelectedCoverage = 1

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.shareService.getinvitedCompanies(this.screeningId).subscribe(res=>{
      this.invitations=res;
      this.invitations.forEach((_companies) => {
        _companies.isExpanded = false;
      });
    });
  }

  public close():void{
    this.companiesModalEvent.emit("close-companies-modal");
  }

  selectItemCoverages(index: number) {
  }
}
