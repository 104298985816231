import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-adjust-resources',
  templateUrl: './adjust-resources.component.html',
  styleUrls: ['./adjust-resources.component.scss']
})
export class AdjustResourcesComponent implements OnInit {
  @Output() creditModalEvent = new EventEmitter<any>();
  @Input() company: any;
  public showAdjustModal = true
  public enngHours: number
  public validationHours: number
  public calculationpoints: number
  private toastr: ToastrService
  public refillData : any ;
  constructor(private companyService: CompanyService) { }

  ngOnInit(): void {
    this.enngHours = this.company.engineeringHours;
    this.validationHours = this.company.validations
    this.calculationpoints = this.company.reflowPoints
  }
  public close(): void {
    this.creditModalEvent.emit("close-credit-modal");
  }
  public incrementCalculationPoints() {
    this.calculationpoints = this.calculationpoints + 1;
  }
  public incrementEngineerHours() {
    this.enngHours = this.enngHours + 1;
  }
  public incrementValidationHours() {
    this.validationHours = this.validationHours + 1;
  }
  public decrementEngineerHours() {
    if (this.enngHours > 0) {
      this.enngHours = this.enngHours - 1
    }
  }
  public decrementValidationHours() {
    if (this.validationHours > 0) {
      this.validationHours = this.validationHours - 1
    }
  }
  public saveChanges(enngHours, validationHours, calculationpoints) {
    this.company.engineeringHours = enngHours;
    this.company.validations = validationHours;
    this.company.reflowPoints = calculationpoints;
    this.refillData= {
      companyId : this.company.id,
      credit_to_refill : this.company.reflowPoints,
      engineeringHours_to_refill : this.company.engineeringHours,
      validations_to_refill : this.company.validations

    }
    this.companyService
      .refillCredits(this.refillData).subscribe((res) => {
      });
      this.creditModalEvent.emit("close-credit-modal");
  }
  public decrementCalculationPoints() {
    if (this.calculationpoints > 0) {
      this.calculationpoints = this.calculationpoints - 1
    }
  }
}
