import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { ComponentFile } from "src/app/models/component-file.model";

@Component({
  selector: "app-add-file",
  templateUrl: "./add-file.component.html",
  styleUrls: ["./add-file.component.css"],
})
export class AddFileComponent implements OnInit {
  public fileToEdit: FormGroup;
  public showFileProperties: boolean = false;
  public formValueChanged: boolean = false;
  public fileToShow: string = null;
  @Output() closeFileForm = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private uploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.fileToEdit = this.initForm();
    this.fileToEdit.valueChanges.subscribe((val) => {
      let f1: ComponentFile = new ComponentFile(this.fileToEdit.value);
      let f2: ComponentFile = new ComponentFile(this.initForm().value);
      if (JSON.stringify(f1) === JSON.stringify(f2)) {
        this.formValueChanged = false;
      } else {
        this.formValueChanged = true;
      }
    });
  }

  onChanges(): void {}

  public initForm(): FormGroup {
    return this.fb.group({
      comment: "",
      fileType: "",
      filename: "",
      fileUrl: "",
      isGeneral: false,
      isShareable: false,
    });
  }

  public addFile(): void {
    this.closeFileForm.emit(this.fileToEdit);
    this.fileToEdit = this.initForm();
    this.showFileProperties = false;
    this.fileToShow = null;
  }

  public fileChangeEvent(fileIpnut: any): void {
    this.showFileProperties = true;
    this.uploadService.uploadFile(fileIpnut[0], 0).subscribe((res) => {
      let uploadfile = new UploadPhotoModel(res);
      this.fileToEdit.patchValue({
        fileUrl: uploadfile.fileName,
      });
      if (this.fileToEdit.get("filename").value == "") {
        this.fileToEdit.patchValue({
          filename: fileIpnut[0].name,
        });
      }
      this.fileToShow = this.fileToEdit.get("filename").value;
    });
  }

  public cancel(): void {
    this.showFileProperties = false;
    this.fileToEdit = this.initForm();
  }
}
