<form [formGroup]="videoGroup" autocomplete="off">
    <div class="form-group">
        <label>Video Title</label>
        <input type="text" class="form-control" formControlName="videoTitle">
    </div>
    <div class="form-group">
        <label>Category</label>
        <select class="form-control" formControlName="videoCategory">
          <option>ReFlow Platform</option>
          <option>Sustanability</option>
          <option>Maritime</option>
        </select>
    </div>
    <div class="form-group">
        <label>Description</label>
        <textarea class="form-control" rows="3" formControlName="videoDescription"></textarea>
    </div>
    <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Video source</span>
        </div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" #file (change)="uploadVideo(file.files)">
          <label class="custom-file-label">Choose file</label>
        </div>
    </div>
    <label *ngIf="videoUploaded != ''">Video uploaded !</label>
    <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Cover photo</span>
        </div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" #coverFile (change)="uploadCover(coverFile.files)">
          <label class="custom-file-label">Choose file</label>
        </div>
    </div>
    <label *ngIf="coverUploaded != ''">Cover uploaded !</label>
</form>
<div class="video-section" *ngFor="let item of sectionArray.value">
    <div class="row">
        <label>Starts at</label>
        <input type="time" [(ngModel)]="item.sectionTime"/>
    </div>
    <div class="form-group">
        <label>Section Title</label>
        <input type="text" class="form-control" [(ngModel)]="item.sectionTitle">
    </div>
    <div class="form-group">
        <label>Section Summary</label>
        <textarea class="form-control" rows="3" [(ngModel)]="item.sectionSummary"></textarea>
    </div>
</div>
<button type="button" class="btn btn-primary" (click)="addSection()">Add Section</button>
<button type="button" class="btn btn-success" (click)="saveVideo()">Save Video</button>
