import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyAssessmentDataService } from 'src/app/services/company-assessment-data.service';
import { MastersheetService } from 'src/app/services/mastersheet.service';

@Component({
  selector: 'app-databases',
  templateUrl: './databases.component.html',
  styleUrls: ['./databases.component.scss']
})
export class DatabasesComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentDb= null;
  public displayUpsertDatabase=false;
  public databaseToEdit: FormGroup;

  public dbList=[
    
  ];
  constructor(
    private fb: FormBuilder,
    private companyAssessmentDataService:CompanyAssessmentDataService,
  ) { }

  ngOnInit(): void {
    this.databaseToEdit = this.fb.group({
      name:[this.currentDb?this.currentDb.name:""],
      version:[this.currentDb?this.currentDb.version:""],
      date:[this.currentDb?this.currentDb.date:""],
    });
    this.getDataBases()
  }

  public getDataBases(){
    this.companyAssessmentDataService.getDataBases().subscribe(ans=>{
      this.dbList=ans;
    })
  }

  public showUpsertDatabase(currentDb){
    this.currentDb=currentDb;
    this.displayUpsertDatabase=true;
  }

  public hideUpsertDatabase(result){
    this.displayUpsertDatabase=false;
    if(result!=null){
      if(this.currentDb==null){
        this.dbList.push(result);
      }else{
        this.dbList[this.dbList.indexOf(this.currentDb)]=result;
        this.currentDb=null;
      }
      
    }
  }
  public dismissModal(){
    this.displayUpsertDatabase=false;
  }

  public delete(db){
    this.dbList.splice(this.dbList.indexOf(db),1);
  }

  public navigate(db){
    this.navigation.emit({type:"Database",data:db,title:db.name})
  }

  public upsert(){


    if(!this.currentDb){
      /*var newdate=new Date( this.databaseToEdit.get('date').value);
      this.databaseToEdit.get('date').patchValue(newdate);*/
      this.companyAssessmentDataService.createDataBase(this.databaseToEdit.value).subscribe(ans=>{
        this.getDataBases();
        this.displayUpsertDatabase=false;
      })
    }else{
      this.dbList[this.dbList.indexOf(this.currentDb)]=this.databaseToEdit.value;
        this.currentDb=null;
    }
  }
}
