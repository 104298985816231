<div class="request-container">
    <div class="request-banner">
        <div class="title-container"  (click)="showRequestModal($event)">
            <img src="../../../../assets/add-request.png"  alt="">
            <label class="title">Request data from supplier</label>
        </div>
        <div class="description-container">
            <img src="../../../../assets/icon_info.svg" alt="">
            <p class="description">
              Unveil the synergy of your supply chain with our Request Data from Supplier feature. Experience streamlined data collaboration - request, track, and utilize real-life emission calculations, while staying in the loop with incoming requests. Enjoy our automated process and transform supplier data into valuable building blocks in your climate assessment.
            </p>
        </div>
    </div>
    <div class="search-container">
        <div class="search-box" ngbDropdown >
            <em class="icon-search icon-search-left"></em>
            <input
                type="text"
                placeholder="Search folder or project"
                autocomplete="off"
                class="input-search"
                [(ngModel)]="searchText"
                (ngModelChange)="makeSearch()"
                (focus)="searchTriggered=true"
                (blur)="searchTriggered=false"
                ngbDropdownToggle
            >
            <em class="icon-close close-search" style="top: 0px; right:0px;font-size: 23px;" *ngIf="searchText!=''" (click)="searchText='';searchList=[]"></em>
            <div class="icon-search-right-container"  *ngIf="searchTriggered||searchText!=''">
                <em class="icon-search icon-search-right"></em>
            </div>
            <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu" *ngIf="searchText!=''">
                <ng-container *ngFor="let item of searchList" >
                    <button class="dd-item" ngbDropdownItem (click)="addNewItem(item)">
                        <img src="../../../../assets/pi_draft_projects.svg" alt="">
                        {{item?.name}}
                    </button>
                </ng-container>
            </div> -->
        </div>
    </div>

    <div class="dynamic-content-container">

        <div class="folders-container">
            <div class="folders-tree-container">
                <div class="folders-tree">
                    <hr class="root-vr">
                    <div class="folder" (click)="selectFolder('myrequests')"
                        [ngClass]="{'engaged-folder':selectedFolder=='completed'}" data-toggle="collapse"
                        data-target="#collapseYourRequests" aria-expanded="false" aria-controls="collapseYourRequests">
                        <img
                        [src]="selectedFolder=='completed'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                        alt="" class="folder-state">
                        <label>Your requests</label>
                        <img *ngIf="inquiries==[]" class="folder-loading"
                        src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>
                    <div class="collapse" id="collapseYourRequests">
                        <hr class="root-vr sub-vr">
                        <ng-container *ngFor="let item of myRequestsCompanies">
                            <div class="subfolders" (click)="_selectProject(null)">
                                <div class="folder ml-37" (click)="selectSubFolder(item,'myrequests')"
                                [ngClass]="{'engaged-folder':selectedFolder=='item.folderName'}">
                                <hr class="subfolder-treelink">
                                <img
                                    [src]="selectedFolder=='item.folderName'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                                    alt="" class="folder-state">
                                <label>{{item.companyName}}</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="folder" (click)="selectFolder('requestedToMe')"
                        [ngClass]="{'engaged-folder':selectedFolder=='completed'}" data-toggle="collapse"
                        data-target="#collapseRequestsToYou" aria-expanded="false" aria-controls="collapseRequestsToYou">
                        <img
                        [src]="selectedFolder=='completed'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                        alt="" class="folder-state">
                        <label>Requests to you</label>
                        <img *ngIf="requests==[]" class="folder-loading"
                        src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>
                    <div class="collapse" id="collapseRequestsToYou">
                        <hr class="root-vr sub-vr">
                        <ng-container *ngFor="let item of requestsToMeCompanies">
                            <div class="subfolders" (click)="_selectProject(null)">
                                <div class="folder ml-37" (click)="selectSubFolder(item,'requestedToMe')"
                                [ngClass]="{'engaged-folder':selectedFolder=='item.folderName'}">
                                <hr class="subfolder-treelink">
                                <img
                                    [src]="selectedFolder=='item.folderName'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                                    alt="" class="folder-state">
                                <label>{{item.companyName}}</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="buffer"></div>
            </div>
            </div>
            <div class="projects-container">
                <div class="projects-list-container" *ngIf="selectedFolder=='myrequests'">
                    <div class="projects-list" >
                        <ng-container *ngFor="let item of toDisplay">
                            <div class="project-item" (click)="showProjectDetails(item)" [ngClass]="{'selected-project': selectedProject==item}">
                                <div class="project">
                                    <img src="../../../../assets/pm_completed_projects@2x.png" alt="">
                                    <label (click)="selectOneProject(null)">{{item?.productName}}</label>
                                </div>
                                <label class="request_status">{{item?.shared!=false?"Request Accepted":"Request Sent"}}</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="buffer"></div>
                </div>
                <div class="projects-list-container" *ngIf="selectedFolder=='requestedToMe'">
                    <div class="projects-list" >
                        <ng-container *ngFor="let item of toDisplay">
                            <div class="project-item" (click)="showProjectDetails(item)"  [ngClass]="{'selected-project': selectedProject==item}">
                                <div class="project">
                                    <img src="../../../../assets/pm_completed_projects@2x.png" alt="">
                                    <label (click)="selectOneProject(null)">{{item?.productName}}</label>
                                </div>
                                <label class="request_status">{{item?.shared!=false?"Request Accepted":"Request Sent"}}</label>
                            </div>
                        </ng-container>

                    </div>
                    <div class="buffer"></div>
                </div>
            </div>
        </div>
</div>
<app-request-modal (toogleRequestModal)="showRequestModal($event)" [requestedProject]="null" [showRequestView]="showRequestView"
    *ngIf="showRequestView"></app-request-modal>
