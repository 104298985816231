import { Component, Input, OnInit,  Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-play-list',
  templateUrl: './play-list.component.html',
  styleUrls: ['./play-list.component.scss']
})
export class PlayListComponent implements OnInit {

  @Input() playlist:any;
  @Input() listName:any;
  @Output() targetVideo:any=new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.playlist.sort( () => .5 - Math.random() );
    if(this.playlist.length>3){
      this.playlist=this.playlist.slice(0, 3)
    }
  }

  public playVideo(video){
    this.targetVideo.emit(video);
  }

}
