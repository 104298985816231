import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from '../../../services/inventory.service';
import { MastersheetService } from '../../../services/mastersheet.service';

@Component({
  selector: 'app-end-groups-overview',
  templateUrl: './end-groups-overview.component.html',
  styleUrls: ['./end-groups-overview.component.scss']
})
export class EndGroupsOverviewComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentEndGrp:any= null;
  public displayUpsertEndGroup=false;
  public endGroupToEdit: FormGroup;
  public locationArray: FormArray;
  public curentSubGroupId:Number;
  public endGrpsList=[];
  public companies:any[]=[];

  constructor(
    private fb: FormBuilder,
    private masterSheetService:MastersheetService,
    private route: ActivatedRoute,
    private companyService:CompanyService,
    private inventoryService:InventoryService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyService.getSuppliers().subscribe(res=>{
        this.companies = res;
      });
      this.curentSubGroupId=Number(params['subGroupId']);
      this.getEndGroups(this.curentSubGroupId);
      this.endGroupToEdit = this.fb.group({
        endGroupName:[this.currentEndGrp?this.currentEndGrp.name:""],
        unit:[this.currentEndGrp?this.currentEndGrp.unit:""],
        description:[this.currentEndGrp?this.currentEndGrp.description:""],
        hasChildren:[this.currentEndGrp?(this.currentEndGrp.hasChildren==true?"Yes":"No"):"No"],
        subGroupId:[this.curentSubGroupId],
        companyId:[0],
        locations:this.fb.array([])
      });
      this.locationArray=this.fb.array([]);
    })
  }
  public getEndGroups(subgroupeid){
    this.masterSheetService.endGroupsOfSubGroup(subgroupeid).subscribe(ans=>{
      this.endGrpsList=ans;
    })
  }

  public showUpsertEndGroup(currentDb){
    this.currentEndGrp=currentDb;
    if(this.currentEndGrp!=null){
      this.inventoryService.getEndGroupLocations(this.currentEndGrp?.id).subscribe(ans=>{
        ans.forEach(element => {
          this.locationArray.value.push({
            id:element?.id,
            locationName:element?.location,
            emission:element?.emission,
            equivalent:element?.equivalent,
            directEmission:element?.directEmission,
            indirectEmission:element?.indirectEmission
          });
        });
      })
    }
    
    this.endGroupToEdit = this.fb.group({
      endGroupName:[this.currentEndGrp?this.currentEndGrp.name:""],
      unit:[this.currentEndGrp?this.currentEndGrp.unit:""],
      description:[this.currentEndGrp?this.currentEndGrp.description:""],
      hasChildren:[this.currentEndGrp?(this.currentEndGrp.hasChildren==true?"Yes":"No"):"No"],
      subGroupId:[this.curentSubGroupId],
      companyId:[this.currentEndGrp?.companyId],
      locations:this.fb.array([])
    });
    this.displayUpsertEndGroup=true;
  }

  public hideUpsertEndGroup(result){
    
  }

  public selectCompany(companyId:any):void{
    this.endGroupToEdit.get('companyId').patchValue(companyId);
  }

  public dismissModal(){
    this.displayUpsertEndGroup=false;
  }

  public upsert(){
    this.endGroupToEdit.value.locations = this.locationArray.value;
    var result=this.endGroupToEdit.value;
    result.hasChildren=result.hasChildren=='No'?false:true
    if(this.currentEndGrp==null){
      this.masterSheetService.createEndGroup(result).subscribe(ans=>{
        this.displayUpsertEndGroup=false;
        this.getEndGroups(this.curentSubGroupId);
      })
    }else{
      this.masterSheetService.editEndGroup(result,this.currentEndGrp?.id).subscribe(ans=>{
        this.displayUpsertEndGroup=false;
        this.ngOnInit();
      })
    }
  }

  public delete(db){
    this.endGrpsList.splice(this.endGrpsList.indexOf(db),1);
  }

  public navigate(db){
    this.navigation.emit({type:"End-groups",data:db,title:db.endGrpName})
  }

  public toggleProcess(verdict){
    if(verdict==false){
      this.endGroupToEdit.patchValue(
          {endGrpEmission:null,endGrpDescription:null,endGrpUnit:null}
        );
      
    }
  }

  public addLocation():void{
    this.locationArray.value.push({id:0,locationName:"",emission:"",equivalent:"",directEmission:"",indirectEmission:""});
  }

}
