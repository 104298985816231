import { Injectable } from '@angular/core';
import { HelperService } from "./helper.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
     private helperService:HelperService,
  ) { 
    this.environmentUrl = environment.apiUrl;
  }
  public getDashboardResults(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/dashboard/getDashboardData`, this.helperService.headers());
  }
}
