<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content">
        <div>
            <em class="icon-close close-responsive" (click)="closeModal()"></em>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center;">
            <img src="../../../../../../assets/Warning.png" class="warning-image-style">
            <p class="Warning-title"> Warning: Missing Information </p></div>
        <div>
            <button class="item" *ngFor="let item of uncompletedCategoryList" (click)="sentedElementFromWarning(item)">{{item}}</button>
        </div>
        <div style="display: flex;justify-content: center;">
            <p class="above-text">The above-shown categories have not been filled out. Proceeding may result in inaccurate or incomplete calculations. Would you like to continue anyway?</p>
        </div>
        <div>
            <button class="go-back-button" (click)="closeModal()">Go Back and Complete</button>
            <button class="proceed-button" (click)="proceed()">Proceed</button>
        </div>
    </div>
</div>