import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { CompanyService } from "src/app/services/company.service";
import { LocationService } from "src/app/services/location.service";
import { ActivatedRoute } from "@angular/router";
import { LocationOverviewDto } from "src/app/models/dtos.model";
import { Company } from "src/app/models/company.model";
@Component({
  selector: "app-location-details",
  templateUrl: "./location-details.component.html",
  styleUrls: ["./location-details.component.css"],
})
export class LocationDetailsComponent implements OnInit {
  public locationForm: FormGroup;
  public submitted = false;
  public isEngine = false;
  public currentLocation: LocationOverviewDto;
  public suppliers: Company[];
  public txtmanufacturter: string;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService
  ) {}

  public ngOnInit(): void {
    this.locationForm = this.fb.group({
      id: 0,
      vesselId: 0,
      type: ["", Validators.required],
      name: ["", Validators.required],
      manufacturer: [""],
      description: [""],
      specification: [""],
      endPoint: [""],
      serialNumber: [""],
      runningHours: [""],
    });
    this.companyService.getSuppliers().subscribe((res) => {
      this.suppliers = res;
    });
    this.activatedRoute.params.subscribe((params) => {
      this.locationService
        .getLocationOverviewById(Number(params["locationId"]))
        .subscribe((res) => {
          this.currentLocation = res;
          this.locationForm = this.fb.group({
            id: this.currentLocation.id,
            vesselId: this.currentLocation.vesselId,
            type: [this.currentLocation.type, Validators.required],
            name: [this.currentLocation.name, Validators.required],
            manufacturer: [this.currentLocation.manufacturer],
            description: [this.currentLocation.description],
            specification: [this.currentLocation.specification],
            endPoint: [this.currentLocation.endPoint],
            serialNumber: [this.currentLocation.serialNumber],
            runningHours: [this.currentLocation.runningHours],
          });
        });
    });
  }

  public selectManufacturer(supplier: Company) {
    this.locationForm.patchValue({
      manufacturer: supplier.companyName,
    });
  }

  public get frm() {
    return this.locationForm.controls;
  }
}
