<div class="compare-container" [ngStyle]="{'width': !modal_screenings?'100%':''}">
    <div class="page-title-container">
        <img src="../../../assets/compare-icon.png" alt="">
        <h2>Choose projects to compare</h2>
    </div>
    <div class="loading-container" *ngIf="!modal_screenings"
        style="display: flex;justify-content: center;align-items: center;height: 100vh;">
        <div class="loading-content">
            <img src="../../../../../assets/Loading-icon-animation.svg"
                style="height: 125px; width: 125px;margin-left: 85px;" alt="" />

            <label class="h4-heading loading" style="display: block; width: 318px;">Please wait while we load your data
                ... </label>
        </div>
    </div>
    <ng-container *ngIf="modal_screenings">

        <p class="intro">The comparison feature enables you to compare a baseline project with up to two additional
            projects, facilitating in-depth scenario and parameter testing. This comparison feature allows you to
            evaluate the carbon footprint of each project relative to the baseline, enhancing your understanding of
            their environmental impact.

            To ensure meaningful comparisons, it is important that all projects being compared serve the same function,
            aligning with the same 'functional unit'.

            Designed for user-friendly navigation and understanding, the comparison feature allows you to make
            environmentally informed decisions without the need for deep technical knowledge. It's an essential tool for
            anyone seeking to understand and reduce their project's carbon footprint.</p>
        <div class="tip-container">
            <img src="../../../assets/tip_icon@4x.png" alt="">
            <p>Tip: The selection of compared alternatives should be carefully made to ensure valid and fair
                comparisons. The chosen products shall be functionally comparable.</p>
        </div>
        <div class="search-bar-container">
            <div class="search-icon-container">
                <em class="icon-search"></em>
            </div>
            <input type="text" placeholder="Search for a project" [(ngModel)]="user_input"
                (ngModelChange)="searchScreening()" (focus)="searchFocus=true">
            <div class="drop-down-trigger" (click)="displaySearch=!displaySearch;searchFocus=!searchFocus">
                <em class="icon-arrow-down" *ngIf="displaySearch"></em>
                <em class="icon-arrow-up" *ngIf="!displaySearch"></em>
            </div>
        </div>
        <div class="custom-dropdown-container" *ngIf="(user_input!=''&&displaySearch)||searchFocus==true">
            <div class="search-list">
                <ng-container *ngFor="let item of modal_screenings;let i=index">
                    <div class="search-item" [ngClass]="{'border-top': i!=0}" (click)="highlightedSearchItem=item?.id">
                        <div class="item-with-actions">
                            <div class="item-title">
                                <label for="" class="name">{{item.name}}</label>
                                <em class="icon-arrow-down"
                                    *ngIf="highlightedSearchItem==item?.id&&searchCollapse==null" data-toggle="collapse"
                                    [attr.data-target]="'#search'+item?.id" (click)="searchCollapse=item?.id"></em>
                                <em class="icon-arrow-up"
                                    *ngIf="highlightedSearchItem==item?.id&&searchCollapse==item?.id"
                                    data-toggle="collapse" [attr.data-target]="'#search'+item?.id"
                                    (click)="searchCollapse=null"></em>
                            </div>
                            <div class="actions-container" *ngIf="highlightedSearchItem==item?.id">
                                <button class="baseline" [ngClass]="{'disabled': selected_projects[0]!=null}"
                                    (click)="canBeSelected(item,0)">
                                    <label for="">Add to baseline</label>
                                </button>
                                <button class="compared"
                                    [ngClass]="{'disabled': (selected_projects[1]!=null&&selected_projects[2]!=null)||selected_projects[0]==null}"
                                    (click)="canBeSelected(item,1)">
                                    <label for="">To be compared</label>
                                </button>
                            </div>
                        </div>
                        <div id="search{{item?.id}}" class="collapse collapse-left">
                            <strong>Weight :</strong> {{item.product?.weight}}<br><strong>Type :</strong> {{item.product?.type}}<br> <strong>Lifespan :</strong>
                            {{item.product?.lifeSpan}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="scroll"></div>
        </div>
        <div class="comparision-projects-container">
            <ng-template #tipContent>
                <div class="tooltip-template">
                    <p>You can see preview of the project</p>
                </div>
            </ng-template>
            <div class="project-to-compare">
                <div class="project-title-container">
                    <div class="title-container baseline-title">
                        <label>Baseline</label>
                    </div>
                    <div class="actions-container" *ngIf="selected_projects.length>0&&selected_projects[0]!=null">
                        <div class="icon-view-container" placement="bottom" [ngbTooltip]="tipContent" (click)="selectedProject=selected_projects[0];showMoreView=true;">
                            <img src="../../../assets/icon_view.svg" alt="">
                        </div>
                        <em class="icon-close close" (click)="selected_projects[0]=null"></em>
                    </div>
                </div>
                <div class="add-container" *ngIf="selected_projects[0]==null">
                    <div class="add-button " (click)="searchFocus=true">
                        <em class="icon-add"></em>
                    </div>
                </div>
                <div class="project-details-container" *ngIf="selected_projects[0]!=null">
                    <img src="../../../assets/icon_folder.svg" alt="">
                    <label class="project-name">{{selected_projects[0]?.name}}</label>
                    <div class="show-more-container">
                        <div class="header" data-toggle="collapse" data-target="#baseline"
                            (click)="showMoreCollapse[0]=!showMoreCollapse[0]">
                            <label class="title">show less details</label>
                            <em class="icon-arrow-down" data-toggle="collapse" data-target="#baseline"
                                *ngIf="showMoreCollapse[0]==false"></em>
                            <em class="icon-arrow-up" data-toggle="collapse" data-target="#baseline"
                                *ngIf="showMoreCollapse[0]==true"></em>
                        </div>
                        <div class="collapse" id="baseline">
                            <div class="body">
                                <div class="one-more-element">
                                    <label class="title">Name</label>
                                    <label class="value">{{selected_projects[0]?.name}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Type</label>
                                    <label class="value">{{selected_projects[0].product?.type}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Weight</label>
                                    <label class="value">{{selected_projects[0].product?.weight}} <span
                                            *ngIf="selected_projects[0].product?.weight!=null">Kg</span></label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Lifespan</label>
                                    <label class="value">{{selected_projects[0].product?.lifeSpan}} <span
                                            *ngIf="selected_projects[0].product?.lifeSpan!=null">Years</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-to-compare">
                <div class="project-title-container">
                    <div class="title-container" [ngClass]="{'enabled': selected_projects[0]!=null}">
                        <label>Project 1</label>
                    </div>
                    <div class="actions-container" *ngIf="selected_projects.length>1&&selected_projects[1]!=null">
                        <div class="icon-view-container" placement="bottom" [ngbTooltip]="tipContent"  (click)="selectedProject=selected_projects[1];showMoreView=true;">
                            <img src="../../../assets/icon_view.svg" alt="">
                        </div>
                        <em class="icon-close close" (click)="selected_projects[1]=null"></em>
                    </div>
                </div>
                <div class="add-container" *ngIf="selected_projects[1]==null">
                    <div class="add-button inactive" [ngClass]="{'disabled-button': selected_projects[0]==null}"
                        (click)="searchFocus=true">
                        <em class="icon-add"></em>
                    </div>
                </div>
                <div class="project-details-container" *ngIf="selected_projects[1]!=null">
                    <img src="../../../assets/icon_folder.svg" alt="">
                    <label class="project-name">{{selected_projects[1]?.name}}</label>
                    <div class="show-more-container">
                        <div class="header" data-toggle="collapse" data-target="#project1"
                            (click)="showMoreCollapse[1]=!showMoreCollapse[1]">
                            <label class="title">show less details</label>
                            <em class="icon-arrow-down" data-toggle="collapse" data-target="#project1"
                                *ngIf="showMoreCollapse[1]==false"></em>
                            <em class="icon-arrow-up" data-toggle="collapse" data-target="#project1"
                                *ngIf="showMoreCollapse[1]==true"></em>
                        </div>
                        <div class="collapse" id="project1">
                            <div class="body">
                                <div class="one-more-element">
                                    <label class="title">Name</label>
                                    <label class="value">{{selected_projects[1]?.name}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Type</label>
                                    <label class="value">{{selected_projects[1].product?.type}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Weight</label>
                                    <label class="value">{{selected_projects[1].product?.weight}} <span
                                            *ngIf="selected_projects[1].product?.weight!=null">Kg</span></label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Lifespan</label>
                                    <label class="value">{{selected_projects[1].product?.lifeSpan}} <span
                                            *ngIf="selected_projects[1].product?.lifeSpan!=null">Years</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-to-compare">
                <div class="project-title-container">
                    <div class="title-container" [ngClass]="{'enabled': selected_projects[0]!=null}">
                        <label>Project 2</label>
                    </div>
                    <div class="actions-container" *ngIf="selected_projects.length>2&&selected_projects[2]!=null">
                        <div class="icon-view-container" placement="bottom" [ngbTooltip]="tipContent" (click)="selectedProject=selected_projects[2];showMoreView=true;">
                            <img src="../../../assets/icon_view.svg" alt="">
                        </div>
                        <em class="icon-close close" (click)="selected_projects[2]=null"></em>
                    </div>
                </div>
                <div class="add-container" *ngIf="selected_projects[2]==null">
                    <div class="add-button inactive" [ngClass]="{'disabled-button': selected_projects[0]==null}"
                        (click)="searchFocus=true">
                        <em class="icon-add"></em>
                    </div>
                </div>
                <div class="project-details-container" *ngIf="selected_projects[2]!=null">
                    <img src="../../../assets/icon_folder.svg" alt="">
                    <label class="project-name">{{selected_projects[2]?.name}}</label>
                    <div class="show-more-container">
                        <div class="header" data-toggle="collapse" data-target="#project2"
                            (click)="showMoreCollapse[2]=!showMoreCollapse[2]">
                            <label class="title">show less details</label>
                            <em class="icon-arrow-down" data-toggle="collapse" data-target="#project2"
                                *ngIf="showMoreCollapse[2]==false"></em>
                            <em class="icon-arrow-up" data-toggle="collapse" data-target="#project2"
                                *ngIf="showMoreCollapse[2]==true"></em>
                        </div>
                        <div class="collapse" id="project2">
                            <div class="body">
                                <div class="one-more-element">
                                    <label class="title">Name</label>
                                    <label class="value">{{selected_projects[2]?.name}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Type</label>
                                    <label class="value">{{selected_projects[2].product?.type}}</label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Weight</label>
                                    <label class="value">{{selected_projects[2].product?.weight}} <span
                                            *ngIf="selected_projects[2].product?.weight!=null">Kg</span></label>
                                </div>
                                <div class="one-more-element">
                                    <label class="title">Lifespan</label>
                                    <label class="value">{{selected_projects[2].product?.lifeSpan}} <span
                                            *ngIf="selected_projects[2].product?.lifeSpan!=null">Years</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alerts-container" *ngIf="showNoteInfo">
            <div class="img-container">
                <img src="../../../assets/icon_info.svg" alt="">
            </div>
            <div class="alert-content">
                <label for="">For valid comparison one more project needs to be added to the grid.</label>
            </div>
        </div>
        <div class="alerts-container danger-light danger" *ngIf="showDanger">
            <div class="img-container danger">
                <img src="../../../assets/icon_info copy.svg" alt="">
            </div>
            <div class="alert-content">
                <label for="">You need to add baseline for the comparison to be valid.</label>
            </div>
        </div>
        <div class="alerts-container danger-light danger" *ngIf="selectionErrorAlert">
            <div class="img-container danger">
                <img src="../../../assets/icon_info copy.svg" alt="">
            </div>
            <div class="alert-content">
                <label for="">This project is already selected!</label>
            </div>
        </div>
        <div class="compare-button-container">
            <button (click)="compareScreenings()"
                [ngClass]="{'active': selected_projects[0]!=null&&(selected_projects[1]!=null||selected_projects[2]!=null)}">
                <label>Compare</label>
            </button>
        </div>
        <div class="history-container">
            <div class="history-header" ngbDropdown>
                <label class="title">Previous history</label>
                <div class="filter" id="filter-dd" ngbDropdownToggle (click)="filterdown=!filterdown">
                    <label class="filter-name">Order by</label>
                    <em class="icon-arrow-down" *ngIf="filterdown"></em>
                    <em class="icon-arrow-up" *ngIf="!filterdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="filter-dd" class="filter-dd-container">
                    <button ngbDropdownItem (click)="reverseArrayOrder()">Newest first</button>
                    <button ngbDropdownItem (click)="reverseArrayOrder()">Oldest first</button>
                </div>
            </div>
            <div class="history-item-container" *ngFor="let comparedProject of comparedProjects">
                <div class="header">
                    <label class="name">NAME OF THE PROJECT</label>
                    <label class="cf">CARBON FOOTPRINT</label>
                    <em class="icon-close"></em>
                </div>
                <div class="items-container">
                    <div class="history-item">
                        <div class="history-project">
                            <img src="../../../assets/icon_folder.svg" alt="">
                            <label>{{comparedProject.firstProjectName}}</label>
                        </div>
                        <label class="value">{{comparedProject.firstProjectEmission | number: '1.0-2'}} kg CO2
                            eq</label>
                    </div>
                    <div class="history-item">
                        <div class="history-project">
                            <img src="../../../assets/icon_folder.svg" alt="">
                            <label>{{comparedProject.secondProjectName}}</label>
                        </div>
                        <label class="value">{{comparedProject.secondProjectEmission | number: '1.0-2'}} kg CO2
                            eq</label>
                    </div>
                    <div class="history-item" *ngIf="comparedProject.thirdProjectId!=0">
                        <div class="history-project">
                            <img src="../../../assets/icon_folder.svg" alt="">
                            <label>{{comparedProject.thirdProjectName}}</label>
                        </div>
                        <label class="value">{{comparedProject.thirdProjectEmission | number: '1.0-2'}} kg CO2
                            eq</label>
                    </div>
                    <div class="buttons-container">
                        <button class="download" (click)="downloadComparisonSummary(comparedProject)">Download
                            summary</button>
                        <button class="see" (click)="openComparison(comparedProject)">See comparison</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</div>

<div id="notAllowedModal" class="modal" *ngIf="showModal">
    <div class="modal-content">
        <div class="header-container">
            <p class="error-message">Error</p>
            <em class="icon-close" (click)="closeErrorModal()"></em>
        </div>
        <div class="box-content">
            <i class="fa fa-exclamation-triangle tiangle-icon" aria-hidden="true"></i><br>
            <label class="share-description">{{message}}</label>
        </div>
        <div class="modal-footer">
            <button class="small-turq" (click)="closeErrorModal()">
                <label class="close-btn-text">
                    Close
                </label>
            </button>
        </div>
    </div>
</div>

<app-project-preview (toogleDetailsModal)="showViewMoreModal($event)" [item]="selectedProject" [showDetailsView]="showMoreView"
    *ngIf="showMoreView"></app-project-preview>