import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CompanyService } from "src/app/services/company.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ComponentFilterDto } from "src/app/models/dtos.model";
import { LocationService } from "src/app/services/location.service";

@Component({
  selector: "app-sp-component-filter",
  templateUrl: "./sp-component-filter.component.html",
  styleUrls: ["./sp-component-filter.component.css"],
})
export class SpComponentFilterComponent implements OnInit {
  public companyId: number;
  public index: Array<Number> = [0, 0, 0, 0, 0, 0];
  public filter: ComponentFilterDto = {
    owners: new Array(),
    locations: new Array(),
    manufacturers: new Array(),
    status: new Array(),
    suppliers: new Array(),
    types: new Array(),
  };
  public owners: any[];
  public manufacturers: any[];
  public locations: any[];
  public suppliers: any[];
  public statusList: string[] = [
    "Servicing",
    "In supplier warehouse",
    "Transit to vessel operator",
    "In Vessel operator warehouse",
    "Received on vessel",
    "Stocked on vessel",
    "In use",
    "Returned for service",
  ];
  public typeList: string[] = [
    "Hull structure",
    "Propulsion systems",
    "Auxiliary systems",
    "Electric systems",
    "Navigation and communication systems",
    "Accommodation",
  ];

  @Output() toogleModal = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private locationService: LocationService
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.getOwners();
      this.getManufacturers();
      this.getLocations();
      this.getSuppliers();
    });
  }

  public getOwners(): void {
    this.companyService.getComponentOwners(this.companyId).subscribe((res) => {
      this.owners = res;
    });
  }

  public getManufacturers(): void {
    this.companyService.getSuppliers().subscribe((res) => {
      this.manufacturers = res;
    });
  }

  public getLocations(): void {
    this.locationService.getLocations().subscribe((res) => {
      this.locations = res;
    });
  }

  public getSuppliers(): void {
    this.companyService.getActiveSuppliers().subscribe((res) => {
      this.suppliers = res;
    });
  }

  public checkOwner(event: any): void {
    if (event.target.checked == true) {
      this.filter.owners.push(Number(event.target.value));
    } else {
      let index = this.filter.owners.indexOf(Number(event.target.value));
      this.filter.owners.splice(index, 1);
    }
  }

  public checkManufacturer(event: any): void {
    if (event.target.checked == true) {
      this.filter.manufacturers.push(Number(event.target.value));
    } else {
      let index = this.filter.manufacturers.indexOf(Number(event.target.value));
      this.filter.manufacturers.splice(index, 1);
    }
  }

  public checkType(event: any): void {
    if (event.target.checked == true) {
      this.filter.types.push(event.target.value);
    } else {
      let index = this.filter.types.indexOf(event.target.value);
      this.filter.types.splice(index, 1);
    }
  }

  public checkStatus(event: any): void {
    if (event.target.checked == true) {
      this.filter.status.push(event.target.value);
    } else {
      let index = this.filter.status.indexOf(event.target.value);
      this.filter.status.splice(index, 1);
    }
  }

  public checkSupplier(event: any): void {
    if (event.target.checked == true) {
      this.filter.suppliers.push(Number(event.target.value));
    } else {
      let index = this.filter.suppliers.indexOf(Number(event.target.value));
      this.filter.suppliers.splice(index, 1);
    }
  }

  public checkLocation(event: any): void {
    if (event.target.checked == true) {
      this.filter.locations.push(Number(event.target.value));
    } else {
      let index = this.filter.locations.indexOf(Number(event.target.value));
      this.filter.locations.splice(index, 1);
    }
  }

  public applyFilter(): void {
    this.toogleModal.emit({ show: false, filter: this.filter });
  }

  public closeModal(event: any): void {
    this.toogleModal.emit(false);
  }

  public reset(): void { }

  public changeIndex(x) {
    this.index[x] == 0 ? this.index[x] = 1 : this.index[x] = 0;
  }
}
