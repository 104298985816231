<div class="reflow-detail">
  <div class="fixed-detail-container">
    <div class="row">
      <div class="col col-header">
        <em class="fa fa-chevron-left back-icon" *ngIf="false"></em>
      </div>
      <div
        class="col"
        style="text-align: center; padding-top: 40px; padding-bottom: 0px;"
      >
        <img src="{{ companyToShow?.companyLogo }}" alt="" />
        <div class="row justify-content-center">
          <label class="company-name">{{ companyToShow?.companyName }}</label>
        </div>
      </div>
      <div class="col col-header">
        <em class="fa fa-edit edit-icon" (click)="showEditView()"></em>
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align: center;">
        <hr />
        <div class="row">
          <div class="col">
            <div class="row">
              <label class="details-section-header">Address</label>
            </div>
            <div class="row">
              <label class="details-section-content">{{
                companyToShow?.address
              }}</label>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <hr />
        <div class="row">
          <div class="col" style="border-right: 0.5px solid #cccccc;">
            <div class="row">
              <label class="details-section-header">VAT number</label>
            </div>
            <div class="row">
              <label class="details-section-content">{{
                companyToShow?.vatNumber
              }}</label>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <label class="details-section-header rightcontent">Type</label>
            </div>
            <div class="row">
              <label class="details-section-content rightcontent">{{
                companyToShow?.companyType
              }}</label>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <div class="row">
              <label class="details-section-header">Mail for invoices</label>
            </div>
            <div class="row">
              <label class="details-section-content">{{
                companyToShow?.billingEmail
              }}</label>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <hr />
        <div
          class="row"
          *ngIf="companyToShow?.billingContact.adresseEmail != ''"
        >
          <div class="col">
            <div class="row">
              <label class="details-section-header">Company contact</label>
            </div>
            <div class="row">
              <label
                class="details-section-content"
                style="font-weight: bold;"
                >{{ companyToShow?.billingContact?.contactName }}</label
              >
              <label class="details-section-content">{{
                companyToShow?.billingContact?.adresseEmail
              }}</label>
              <label class="details-section-content">{{
                companyToShow?.billingContact?.phoneNumber
              }}</label>
            </div>
          </div>
          <div class="col" style="text-align: center;">
            <img class="img-contact" src="../../../assets/loginbg.jpg" alt="" />
          </div>
        </div>
        <hr />
        <div
          class="row"
          *ngIf="companyToShow?.companyContact.adresseEmail != ''"
        >
          <div class="col">
            <div class="row">
              <label class="details-section-header">Billing contact</label>
            </div>
            <div class="row">
              <label
                class="details-section-content"
                style="font-weight: bold;"
                >{{ companyToShow?.companyContact?.contactName }}</label
              >
              <label class="details-section-content">{{
                companyToShow?.companyContact?.adresseEmail
              }}</label>
              <label class="details-section-content">{{
                companyToShow?.companyContact?.phoneNumber
              }}</label>
            </div>
          </div>
          <div class="col">
            <img class="img-contact" src="../../../assets/loginbg.jpg" alt="" />
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>
