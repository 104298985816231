import { Component, OnInit, Output,EventEmitter  } from '@angular/core';


@Component({
  selector: 'app-screening-modal',
  templateUrl: './screening-modal.component.html',
  styleUrls: ['./screening-modal.component.css']
})
export class ScreeningModalComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancel($event) {
    this.close.emit(null);
  }

}
