import { Component, OnInit,Output, EventEmitter, Input, SimpleChanges  } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { CompanyFoldersService } from 'src/app/services/company-folders.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-my-project',
  templateUrl: './my-project.component.html',
  styleUrls: ['./my-project.component.scss']
})
export class MyProjectComponent implements OnInit {
  @Input() public shareAction: any;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() projectEvent = new EventEmitter<any>();
  public searchList: any[] = [];
  public porjectsFilter = {
    private: false,
    public: false,
    notShared: false
  };
  public projects = [];
  public projectsOfFolder = [];
  public selectedFolder = '';
  public selectedSubFolder = '';
  public selectedProject = null;
  public canShowFolders = false;
  public showTerms = false;
  public companyId: number;
  public screenings=[];
  public completed : any[] = [];
  public drafts=[];
  public todisplay = [];
  public recentScreenings: any[] = [];
  public draftFolders: any[] = [];
  public completedFolders: any[] = [];
  public currentUser: User;
  public hasAccess: boolean = false;
  public selectedShared: any;
  public infoShareModal = false;
  public expandables = [];
  public currentExpandFolder = "";

  public searchText="";
  public filterParameter="";
  public searchTriggered=false;

  constructor(
    private companyFoldersService: CompanyFoldersService,
    private userService: UserService,
    private screeningService: ScreeningService,
  ) { }

  ngOnInit(): void {
    this.companyId = Number(localStorage.getItem("company_id"));
    this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(res => {
      this.currentUser = res;
      if (this.currentUser.access.filter(x => x.access == "Gaia Project").length > 0) {
        this.hasAccess = true;
        this.showScreenings();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.shareAction=="share"){
      this.showScreenings();
    }
  }
  
  public showScreenings(): void {
    this.canShowFolders = false;
    this.screeningService.getScreeningByCompanyId(this.companyId).subscribe(res => {
      this.screenings = res;
      this.completed = this.screenings.filter(screening => screening.finished == true);
      this.drafts = this.screenings.filter(screening => screening.finished == false);
      this.todisplay = this.completed;
      
      this.recentScreenings = res.sort((a, b) => (a.id < b.id) ? 1 : -1);
      this.recentScreenings = this.recentScreenings.slice(0, 6);
      this.companyFoldersService.getFoldersOfCompany(this.companyId).subscribe(ans => {
        this.draftFolders = ans.filter(x => x.isCompleted == false);
        this.completedFolders = ans.filter(x => x.isCompleted == true);
        this.canShowFolders = true;
      });
    });
  }
    

  public selectProject(item) {
    this.todisplay = this.completed.filter(x=>x.companyFolderId == item.id);
    this.projects = this.todisplay;
    this.selectedProject = item;
    if(this.filterParameter=='shared'){
      this.projects=this.todisplay.filter(x=>x['shared']==true);
    }else if(this.filterParameter=='notShared'){
      this.projects=this.todisplay.filter(x=>x['shared']==false);
    }else{
      this.projects=this.todisplay
    }
  }
  public selectOneProject(project) {
    this.selectedProject = project;
    this.newItemEvent.emit(this.selectedProject.reflowId);
  }

  public selectCompletedSubFolder(folder):void{
    this.selectedFolder='completed';
    this.todisplay = this.completed.filter(x=>x.companyFolderId == folder.id);
  }
  public selectFolder(name) {
    this.selectedFolder == name ? this.selectedFolder = "" : this.selectedFolder = name;
    this.todisplay=this.completed;
    this.projects=this.todisplay;
    
  }
  public selectSubFolder(name) {
    this.selectedFolder == name ? this.selectedFolder = "" : this.selectedFolder = name;
  }
  addNewItem(value: any) {
    this.projectEvent.emit(value);
  }

  public filter(type){
    this.filterParameter==type?this.filterParameter='':this.filterParameter=type;
    if(this.filterParameter=='shared'){
      this.projects=this.todisplay.filter(x=>x['shared']==true);
    }else if(this.filterParameter=='notShared'){
      this.projects=this.todisplay.filter(x=>x['shared']==false);
    }else{
      this.projects=this.todisplay
    }
  }

  public makeSearch(){
    var searchKeywords=this.searchText.split(" ")
    var occured:any[]=[];
    for(var i=0;i<this.completed.length;i++){
      for(var j=0;j<searchKeywords.length;j++){
        if((this.completed[i]["name"]+'').toLocaleLowerCase().includes(searchKeywords[j])){
          var x=this.completed[i];
          if(occured.indexOf(this.completed[i])==-1){
            x.occ=1;
            occured.push(x);
          }else{
            x.occ+=1;
          }
        }
      }
    }
    occured.sort(({occ:a}, {occ:b}) => b-a);
    this.searchList=occured
  }

}
