import { Component, OnInit,Input } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
@Component({
  selector: 'app-request-project-overview',
  templateUrl: './request-project-overview.component.html',
  styleUrls: ['./request-project-overview.component.scss']
})
export class RequestProjectOverviewComponent implements OnInit {
  
  @Input() public item: any;
  public requestInformation=true;
  public productSpecification=true;
  public showmore=null;

  constructor(
    private companyService:CompanyService,
  ) { }

  ngOnInit(): void {
    
    
  }

}
