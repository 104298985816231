<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="showSubmscriptionModal">
    <div class="re-modal-content2" style="height:275px;">
        <form>
            <div class="request-header">
                <label class="requestModalTitle">Subscription Management</label>
                <em class="icon-close close" style="margin:5px; color:#ffffff;" (click)="close()"></em>
            </div>
            <div class="content-modal2">
                <div class="monthlyCredits">
                    Monthly Credits : <input type="text" placeholder="" class="email-input" #monthlyCredit>
                </div>
                <div class="twoButton"
                    style="display:flex;flex-direction:row;justify-content: center;text-align: center;">
                    <button class="btn-solid default" (click)="close()">Close</button>
                    <button class="btn-solid default" style="color:#000;background-color:#98D6D4 ;" (click)="confirm(monthlyCredit.value)">Confirm</button>
                </div>
            </div>
        </form>
    </div>
</div>