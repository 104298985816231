import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "../helper.service";
import { HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SubcategoriesService {

  environmentUrl = "Debug api";

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getSubcategories(module_id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/subcategory/${module_id}`);
  }

  public createSubcategory(subcategory): any {
    return this.http.post<any>(`${this.environmentUrl}/api/subcategory`,subcategory);
  }

  public updateSubcategory(subcategory): any {
    return this.http.post<any>(`${this.environmentUrl}/api/subcategory`,subcategory);
  }

  public deleteSubcategory(id): any {
    return this.http.delete<any>(`${this.environmentUrl}/api/subcategory/${id}`);
  }
}