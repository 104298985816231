import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vessel-operator-overview',
  templateUrl: './vessel-operator-overview.component.html',
  styleUrls: ['./vessel-operator-overview.component.css']
})
export class VesselOperatorOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
