import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-engineers-side-menu',
  templateUrl: './engineers-side-menu.component.html',
  styleUrls: ['./engineers-side-menu.component.css']
})
export class EngineersSideMenuComponent implements OnInit {
  public currentUser: User = new User();
  public showResetView: boolean = false;
  public showImageView: boolean = false;
  constructor(
    private userService: UserService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUserData();
  }
  
  public currentUserData(): void {
    this.userService.getCurrentUser().subscribe(user=>{
      this.userService
      .getUserById(Number(localStorage.getItem("user_id")))
      .subscribe((res) => {
        this.currentUser = res;
        if (this.currentUser.profilePicture == "") {
          this.currentUser.profilePicture = "../../../assets/loginbg.jpg";
        }
      },
      error => {
      }
      );
    });
  }

  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
    this.showImageView = false;
  }
  public showImageModal(isShow: boolean) {
    this.showImageView = isShow;
    this.showResetView = false;
  }

  public logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.auth.logout({ returnTo: `${window.location.origin}/login` });
    localStorage.clear();
  }

}
