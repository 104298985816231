<div class="element-header">
  <em class="fa fa-share-alt dark-blue"></em>
  <label class="share-title">SHARE</label>
</div>
<div class="body">
  <label for="" class="title">
    Multiple sharing
  </label>
<img src="../../../assets/r_p_completed_projects@2x.png" alt="" class="logo">
<div class="info-item">
  <label for="" class="label big-lable">8 collected projects for sharing:</label>
</div>
  <label for="" class="belongs-to">
      <div class="move-folder-container">
          <button class="move-folder-dropdown">
              <label class="item-title">Product name 1
              </label>
              <span class="v-more-span"> View more</span>
          </button>
      </div>
      <div class="move-folder-container">
        <button class="move-folder-dropdown">
            <label class="item-title">Product name 2
            </label>
            <span class="v-more-span"> View more</span>
        </button>
    </div>
    <div class="move-folder-container">
      <button class="move-folder-dropdown">
          <label class="item-title">Product name 3
          </label>
          <span class="v-more-span"> View more</span>
      </button>
  </div>
  <div class="move-folder-container">
    <button class="move-folder-dropdown">
        <label class="item-title">Product name 4
        </label>
        <span class="v-more-span"> View more</span>
    </button>
</div>
<div class="move-folder-container">
  <button class="move-folder-dropdown">
      <label class="item-title">Product name 5
      </label>
      <span class="v-more-span"> View more</span>
  </button>
</div>
<div class="move-folder-container">
  <button class="move-folder-dropdown">
      <label class="item-title">Product name 6
      </label>
      <span class="v-more-span"> View more</span>
  </button>
</div>
<div class="info-item">
  <label for="" class="label">Ecoinvent Database:</label>
  <div class="status-container">
      <label for="" class="status stat-date">v3.8</label>
  </div>
</div>
<div class="info-item">
  <label for="" class="label">Date of Inventory Data:</label>
  <div class="status-container">
      <label for="" class="status stat-date">xx/xx/xx</label>
  </div>
</div>
  </label>
</div>
