import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoriesService } from '../../../services/yardscore-services/categories.service';
import { ModulesService } from '../../../services/yardscore-services/modules.service';
import { SubcategoriesService } from '../../../services/yardscore-services/subcategories.service';

@Component({
  selector: 'app-questionnaire-child-upsert',
  templateUrl: './questionnaire-child-upsert.component.html',
  styleUrls: ['./questionnaire-child-upsert.component.scss']
})
export class QuestionnaireChildUpsertComponent implements OnInit {

  @Input() actionType=null;
  @Input() actionOn:any=null;
  @Input() parentData:any=null;
  @Input() currentObject:any=null;

  @Output() closeUpsertForm=new EventEmitter();

  public questChildName="";

  constructor(
    private categoriesService:CategoriesService,
    private modulesService:ModulesService,
    private subcategoriesService:SubcategoriesService
  ) { }

  ngOnInit(): void {
    this.questChildName=this.currentObject==null?"":this.currentObject.title;
  }

  public cancel(){
    this.closeUpsertForm.emit(false);
  }

  public upsert(){
    if(this.actionType=='create'){
      switch(this.actionOn) {
        case "category":
          this.createCategory();
          break;
        case "module":
          this.createModule();
          break;
        default:
            this.createSubcategory();
      }
    }else{
      switch(this.actionOn) {
        case "category":
          this.updateCategory();
          break;
        case "module":
          this.updateModule();
          break;
        default:
            this.updateSubcategory();
      }
    }
    
  }
//////////////////////////////////////////////////////////////////
  public createCategory(){
    const category={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      type:this.parentData
    }
    this.categoriesService.createCategory(category).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }

  public updateCategory(){
    const category={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      type:this.parentData
    }
    this.categoriesService.updateCategory(category).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }
//////////////////////////////////////////////////////////////////

  public createModule(){
    const module={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      questionnaireCategoryId:this.parentData
    }
    this.modulesService.createModule(module).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }

  public updateModule(){
    const module={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      questionnaireCategoryId:this.parentData
    }
    this.modulesService.updateModule(module).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }

//////////////////////////////////////////////////////////////////

  public createSubcategory(){
    const category={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      questionnaireModuleId:this.parentData
    }
    this.subcategoriesService.createSubcategory(category).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }

  public updateSubcategory(){
    const category={
      id:this.currentObject==null?0:this.currentObject?.id,
      title:this.questChildName,
      questionnaireModuleId:this.parentData
    }
    this.subcategoriesService.updateSubcategory(category).subscribe(ans=>{
      this.closeUpsertForm.emit(true);
    },err=>{
      console.log(err);
      this.closeUpsertForm.emit(false);
    })
  }

}
