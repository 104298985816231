<div class="sections-container" *ngIf="copmonentToShow!='final-step'">
    <div class="login-form-container">
        <app-login-form (goToComponent)='showComponent($event)' *ngIf="copmonentToShow=='login'"></app-login-form>
        <app-signup-form (goToComponent)='showComponent($event)' *ngIf="copmonentToShow=='signup'"></app-signup-form>
    </div>
    <div class="section-1">
        <div class="reflow-logo">
            <img src="../../assets/landing-page/Reflow logo W.svg" alt="" class="logo">
        </div>
        <div class="contents">
            <h1 class="big-title-white">Environmental quantification made easy</h1>
            <label class="green-description">Document environmental sustainability via our easy-to-use 
                self-service digital platform</label>
            <img src="../../assets/landing-page/box-to-landingpage.png" alt="" class="mockup">
        </div>
    </div>
    <section class="section-2">
        <div class="contents">
            <h3 class="big-title">The change is happening</h3>
            <div class="row mt-5">
                <div class="col-3">
                    <img src="../../assets/landing-page/total-footprint-leafs.png" alt="" class="im-extra">
                    <p class="footprint-text">501 000</p><br>
                    <p class="footprint-sub-text ">Tonnes of CO<sub>2</sub> savings<br>already documented with ReFlow</p>
                </div>
                <div class="col-2">
                    <p class="equality-text">=</p>
                </div>
                <div class="col-3">
                    <img src="../../assets/landing-page/house.svg" alt="">
                    <p class="footprint-text">90 000</p><br>
                    <p class="footprint-sub-text ">501 000 CO<sub>2</sub> eq can power</p>
                    <p class="footprint-sub-text delete-margin">90 000 houses for one year</p>
                </div>
                <div class="col-1">
                </div>
                <div class="col-3">
                    <img src="../../assets/landing-page/newcar.svg" alt="">
                    <p class="footprint-text">108 000</p><br>
                    <p class="footprint-sub-text ">501 000 CO<sub>2</sub> eq can produce</p>
                    <p class="footprint-sub-text delete-margin">108 000 new cars</p>
                </div>
            </div>
        </div>
    </section>
    <div class="section-3">
        <div class="contents">
            <h3 class="big-title">Values</h3>
            <div class="row values">
                <div class="value">
                    <img src="../../assets/landing-page/tabel.svg" alt="" id="tabel">
                    <label class="title">
          Put numbers on your sustainability claims
        </label>
                    <p class="description">
                        Our lifecycle-based environmental quantification system makes it easy to calculate, document, and share environmental performance data of products.
                    </p>
                </div>
                <div class="value">
                    <img src="../../assets/landing-page/Asset-511x-8c97868d.png" alt="" id="asset">
                    <label class="title">
          The power of the environmental engineering in your hands
        </label>
                    <p class="description">
                        We remove the complexity around LCA-based calculations and make it possible for users with just basic product knowledge to make advanced calculations.
                    </p>
                </div>
                <div class="value">
                    <img src="../../assets/landing-page/value.png" alt="" id="val">
                    <label class="title">
          Comply with the environmental regulations and gain a competitive advantage
        </label>
                    <p class="description">
                        By obtaining science-based CO<sub>2</sub> numbers on your sustainability claims, you can do both and become one of the most sustainable players in your industry.
                    </p>
                </div>
                <div class="value">
                    <img src="../../assets/landing-page/lifespan-default.png" alt="" id="lifespan">
                    <label class="title">
          Save CO2, money and time with us
        </label>
                    <p class="description">
                        Our product has an average cost savings of 75% and an increase in speed by 90% compared to the traditional LCA methods.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="section-3">
        <div class="contents">
            <h3 class="big-title">Buy extra FlowCredits</h3>
            <div class="row values">
                <img src="../../assets/landing-page/servicesimage.png" alt="" class="servicesimage">
            </div>
        </div>
    </div>
    <!--plans here-->
</div>
<app-final-step (goToComponent)='showComponent($event)' *ngIf="copmonentToShow=='final-step'"></app-final-step>