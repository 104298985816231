import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reflow-academy',
  templateUrl: './reflow-academy.component.html',
  styleUrls: ['./reflow-academy.component.css']
})
export class ReflowAcademyComponent implements OnInit {

  public currentComponent="over-view";
  public currentVideo=null;

  constructor() { }

  ngOnInit(): void {
  }

  public cmpToDisplay(event){
    this.currentVideo=event;
  }

}
