<div class="reflow-menu">
    <ul class="nav navbar-nav">
        <li class="active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <img src="" alt="" />
            <label class="sidebar-username" id="user">Hi Sales Team</label
      >
      <em class="fa fa-angle-down expend-icon" id="expend"></em>
    </li>
    </ul>

    <ul class="nav navbar-nav">
        <li routerLink="sales-space-overview">
            <em class="fa fa-envelope-open-o"></em>
            <label>Companies</label>
        </li>
    </ul>
</div>