import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AcademyService } from 'src/app/services/academy.service';

@Component({
  selector: 'app-academy-admin',
  templateUrl: './academy-admin.component.html',
  styleUrls: ['./academy-admin.component.css']
})
export class AcademyAdminComponent implements OnInit {
  public videoGroup: FormGroup;
  public sectionArray: FormArray;
  public videoUploaded:string="";
  public coverUploaded:string="";
  constructor(private fb: FormBuilder, private academyService: AcademyService, private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.videoGroup = this.fb.group({
      videoTitle:[""],
      videoDescription:[""],
      videoCategory:[""],
      videoPath:[""],
      coverPath:[""],
      sections:this.fb.array([])
    });
    this.sectionArray=this.fb.array([]);
  }

  public addSection():void{
    this.sectionArray.value.push({sectionTitle:"ffff", sectionSummary:"aaaa", sectionTime:""});
  }

  public uploadVideo(files):void{
    this.academyService.uploadFile(files[0]).subscribe(res=>{
      this.videoGroup.get("videoPath").patchValue(res.fileName);
      this.videoUploaded=files[0].name;
    });
  }

  public uploadCover(files):void{
    this.academyService.uploadFile(files[0]).subscribe(res=>{
      this.videoGroup.get("coverPath").patchValue(res.fileName);
      this.coverUploaded=files[0].name;
    });
  }

  public saveVideo():void{
    this.videoGroup.value.sections=this.sectionArray.value;
    this.academyService.addVideo(this.videoGroup.value).subscribe(res=>{
      this.toastrService.success("you can go check it in reflow","Video saved !")
    });
  }
}
