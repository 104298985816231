import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { WarehouseService } from "src/app/services/warehouse.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Warehouse } from "src/app/models/warehouse";
import { LocationService } from "src/app/services/location.service";
import { Vessel } from "src/app/models/vesset";
import { VesselService } from "src/app/services/vessel.service";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { StatusService } from "src/app/services/status.service";
import { DatePipe } from "@angular/common";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { ComponentCoreInformation } from "src/app/models/component-core-information.model";
import { ComponentLogService } from "src/app/services/component-log.service";
import { ComponentLog } from "src/app/models/component-log";
import { ComponentEditDto, ExchangeOverview } from "src/app/models/dtos.model";
import { ExchangeService } from "src/app/services/exchange.service";
@Component({
  selector: "app-vo-update-status",
  templateUrl: "./vo-update-status.component.html",
  styleUrls: ["./vo-update-status.component.scss"],
})
export class VoUpdateStatusComponent implements OnInit {
  public componentFiles: FormArray;
  public filesToUpload: any[];
  public warehouses: Warehouse[];
  public newStatus: FormGroup;
  public txtwarehouse: string;
  public txtlocation: string;
  public txtsupplier: string;
  public locations: Location[];
  public vessels: Vessel[];
  public suppliers: Company[];
  public companyId: number;
  public isInVoWharehouse = false;
  public isReceivedInVessel = false;
  public isStockedInVessel = false;
  public isInUse = false;
  public isReturnToService = false;
  public txtvessel: string = "";
  public currentDate = new Date();
  public currentComponent: ComponentEditDto;
  public exchangeComponent: ExchangeOverview;
  public currentVessel: any;
  public currentLocation: boolean = false;
  public hasExchangeUnit: boolean = false;
  public showChangeExchange: boolean = false;
  public exchangeUnits: any[];
  public txtexchangeComponent: string;

  @Input() componentId: number;
  @Output() closeModal = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private vesselsService: VesselService,
    private companyService: CompanyService,
    private statusService: StatusService,
    private componentService: ComponentCoreInformationService,
    private datePipe: DatePipe,
    private componentLogService: ComponentLogService,
    private exchangeService: ExchangeService,
    private CCIService: ComponentCoreInformationService
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.companyId = params["companyId"];
      this.warehouseService
        .retrieveWarehouses(this.companyId)
        .subscribe((res) => {
          this.warehouses = res;
        });
      this.vesselsService.getVessels(this.companyId).subscribe((res) => {
        this.vessels = res;
      });
      this.companyService.getActiveSuppliers().subscribe((res) => {
        this.suppliers = res;
      });
      this.componentService
        .getComponentById(this.componentId)
        .subscribe((res) => {
          this.currentComponent = res;
          this.txtlocation = this.currentComponent.location;
          this.txtvessel = this.currentComponent.vessel;
        });
    });
    this.exchangeService
      .getExhangeComponent(this.componentId)
      .subscribe((res) => {
        this.exchangeComponent = res;
        if (res != null) {
          this.hasExchangeUnit = true;
        }
      });
    this.newStatus = this.fb.group({
      componentId: this.componentId,
      currentStatus: ["", Validators.required],
      statusFiles: this.fb.array([]),
      exchangeComponent: [""],
      exchangeComponentId: [""],
    });
  }

  public updateFiles(file) {
    this.componentFiles = this.newStatus.get("statusFiles") as FormArray;
    this.filesToUpload = this.componentFiles.value;
    this.componentFiles.push(file);
  }

  public selectWarehouse(warehouse: any): void {
    this.newStatus.patchValue({
      warehouseId: warehouse.id,
      warehouse: warehouse.warehouseName,
    });
  }

  public selectLocation(location: any): void {
    this.newStatus.patchValue({
      locationId: location.id,
      location: location.name,
    });
  }

  public selectVessel(vessel: any): void {
    this.newStatus.patchValue({
      vesselId: vessel.id,
      vessel: vessel.vesselName,
    });
    this.txtlocation = "";
    this.locationService.getLocationsByVesselId(vessel.id).subscribe((res) => {
      this.locations = res;
    });
  }

  public selectSupplier(supplier: any) {
    this.newStatus.patchValue({
      supplierId: supplier.id,
      supplier: supplier.companyName,
    });
  }

  public hideModal(): void {
    this.closeModal.emit(false);
  }

  public makeSelection(selection: string): void {
    this.initStatus();
    if (selection == "In Vo warehouse") {
      this.newStatus.addControl(
        "warehouse",
        this.fb.control("", Validators.required)
      );
      this.newStatus.addControl(
        "warehouseId",
        this.fb.control("", Validators.required)
      );
      this.newStatus.addControl(
        "warehouseLocation",
        this.fb.control("", Validators.required)
      );
      this.newStatus.addControl("stockReason", this.fb.control(""));
      this.newStatus.addControl("voReference", this.fb.control(""));
      this.isInVoWharehouse = true;
    } else if (selection == "Received on vessel") {
      this.newStatus.addControl(
        "vessel",
        this.fb.control(
          this.currentComponent.vessel != null
            ? this.currentComponent.vessel
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "vesselId",
        this.fb.control(
          this.currentComponent.vesselId != null
            ? this.currentComponent.vesselId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "location",
        this.fb.control(
          this.currentComponent.location != null
            ? this.currentComponent.location
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "locationId",
        this.fb.control(
          this.currentComponent.locationId != null
            ? this.currentComponent.locationId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl("stockReason", this.fb.control(""));
      this.newStatus.addControl("voReference", this.fb.control(""));

      this.isReceivedInVessel = true;
    } else if (selection == "Stocked on vessel") {
      this.newStatus.addControl(
        "vessel",
        this.fb.control(
          this.currentComponent.vessel != null
            ? this.currentComponent.vessel
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "vesselId",
        this.fb.control(
          this.currentComponent.vesselId != null
            ? this.currentComponent.vesselId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "location",
        this.fb.control(
          this.currentComponent.location != null
            ? this.currentComponent.location
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "locationId",
        this.fb.control(
          this.currentComponent.locationId != null
            ? this.currentComponent.locationId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl("stockReason", this.fb.control(""));
      this.newStatus.addControl("voReference", this.fb.control(""));
      this.isStockedInVessel = true;
    } else if (selection == "In use") {
      this.newStatus.addControl(
        "vessel",
        this.fb.control(
          this.currentComponent.vessel != null
            ? this.currentComponent.vessel
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "vesselId",
        this.fb.control(
          this.currentComponent.vesselId != null
            ? this.currentComponent.vesselId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "location",
        this.fb.control(
          this.currentComponent.location != null
            ? this.currentComponent.location
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "locationId",
        this.fb.control(
          this.currentComponent.locationId != null
            ? this.currentComponent.locationId
            : "",
          Validators.required
        )
      );
      this.newStatus.addControl(
        "recommendHours",
        this.fb.control(this.currentComponent.recommendedHours)
      );
      this.newStatus.addControl(
        "utilization",
        this.fb.control(this.currentComponent.utilization, Validators.required)
      );
      this.newStatus.addControl(
        "installationDate",
        this.fb.control(
          this.datePipe.transform(this.currentDate, "yyyy-MM-dd"),
          Validators.required
        )
      );
      this.newStatus.addControl(
        "installationTime",
        this.fb.control(
          this.datePipe.transform(this.currentDate.getTime(), "HH:mm"),
          Validators.required
        )
      );
      this.isInUse = true;
    } else if (selection == "Return for service") {
      this.newStatus.addControl(
        "supplier",
        this.fb.control("", Validators.required)
      );
      this.newStatus.addControl(
        "supplierId",
        this.fb.control("", Validators.required)
      );
      this.newStatus.addControl("voReference", this.fb.control(""));
      this.newStatus.addControl("purchaseOrder", this.fb.control(""));
      this.isReturnToService = true;
    }
  }

  public initStatus(): void {
    this.isInVoWharehouse = false;
    this.isReceivedInVessel = false;
    this.isStockedInVessel = false;
    this.isInUse = false;
    this.isReturnToService = false;
  }

  public reset(): void {}

  public updateStatus(): void {
    this.statusService.updateVoStatus(this.newStatus.value).subscribe((res) => {
      let componentLog: ComponentLog = {
        id: 0,
        componentId: Number(res),
        userId: Number(localStorage.getItem("user_id")),
        createdAt: new Date(),
        action:
          "changed status from " +
          this.currentComponent.status +
          " to " +
          this.newStatus.controls.currentStatus.value,
        componentLogFiles:
          this.componentFiles != undefined ? this.componentFiles.value : [],
      };
      componentLog.componentId = this.componentId;
      this.componentLogService
        .addComponentLog(componentLog)
        .subscribe((log) => {
          this.hideModal();
        });
    });
  }

  public changeExchangeUnit(): void {
    this.showChangeExchange = true;
    this.CCIService.getExchangeComponents(
      this.currentComponent.locationId,
      this.componentId
    ).subscribe((res) => {
      this.exchangeUnits = res;
    });
  }

  public selectUnit(unit: any): void {
    this.newStatus.patchValue({
      exchangeComponentId: unit.id,
      exchangeComponent: unit.specification,
    });
  }

  public confirmExchange(): void {
    this.CCIService.changeExchangeComponents(
      this.componentId,
      this.newStatus.get("exchangeComponentId").value
    ).subscribe((res) => {});
  }
}
