import { Component, OnInit } from '@angular/core';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-platform-updates',
  templateUrl: './platform-updates.component.html',
  styleUrls: ['./platform-updates.component.css']
})
export class PlatformUpdatesComponent implements OnInit {

  public showEditView:boolean=false;
  public platformUpdates : any[];
  public platformUpdate : any;
  constructor(private notificationService:NotificationServiceService ) { }

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe(res=>{
      this.platformUpdates=res;
    })
  }

  public showAddModal(isShow:boolean):void{
    this.showEditView = isShow;
  }

  public showEditModal(isShow:boolean, platformUpdate:any):void{
    this.showEditView = isShow;
    this.platformUpdate = platformUpdate;
  }

}
