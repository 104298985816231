import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from '../../../services/inventory.service';
import { MastersheetService } from '../../../services/mastersheet.service';

@Component({
  selector: 'app-processes-overview',
  templateUrl: './processes-overview.component.html',
  styleUrls: ['./processes-overview.component.scss']
})
export class ProcessesOverviewComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentProcess:any= null;
  public displayUpsertProcess=false;
  public processToEdit: FormGroup;
  public procList:any[]=[];
  public locationArray: FormArray;
  public curentEndGroupId:Number;
  public companies:any[]=[];

  constructor(
    private fb: FormBuilder,
    private masterSheetService:MastersheetService,
    private route: ActivatedRoute,
    private companyService:CompanyService,
    private inventoryService:InventoryService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyService.getSuppliers().subscribe(res=>{
        this.companies = res;
      });
      this.curentEndGroupId=Number(params['endGroupId']);
      this.getProcesses(this.curentEndGroupId);
      this.processToEdit = this.fb.group({
        endGroupId:[this.curentEndGroupId],
        companyId:[0],
        processName:[this.currentProcess?this.currentProcess.name:""],
        unit:[this.currentProcess?this.currentProcess.unit:""],
        description:[this.currentProcess?this.currentProcess.description:""],
      });
      this.locationArray=this.fb.array([]);
    })
  }

  public getProcesses(id){
    this.masterSheetService.processesOfEndGroups(id).subscribe(ans=>{
      this.procList=ans;
    })
  }

  public selectCompany(companyId:any):void{
    this.processToEdit.get('companyId').patchValue(companyId);
  }

  public showUpsertProcess(currentDb){
    this.currentProcess=currentDb;
    if(this.currentProcess!=null){
      this.inventoryService.getProcessLocations(this.currentProcess?.id).subscribe(ans=>{
        ans.forEach(element => {
          this.locationArray.value.push({
            id:element?.id,
            locationName:element?.location,
            emission:element?.emission,
            equivalent:element?.equivalent,
            directEmission:element?.directEmission,
            indirectEmission:element?.indirectEmission
          });
        });
      })
    }
    this.processToEdit = this.fb.group({
      endGroupId:[this.curentEndGroupId],
      companyId:[0],
      processName:[this.currentProcess?this.currentProcess.name:""],
      unit:[this.currentProcess?this.currentProcess.unit:""],
      description:[this.currentProcess?this.currentProcess.description:""],
    });
    this.displayUpsertProcess=true;
  }

  public hideUpsertProcess(result){
    this.displayUpsertProcess=false;
    if(result!=null){
      if(this.currentProcess==null){
        this.procList.push(result);
      }else{
        this.procList[this.procList.indexOf(this.currentProcess)]=result;
        this.currentProcess=null;
      }
      
    }
  }
  public upsert(){
    
    var result=this.processToEdit.value;
    result.hasChildren=this.processToEdit.value=='No'?false:true
    this.processToEdit.value.locations = this.locationArray.value;
 
    if(this.currentProcess==null){
      this.masterSheetService.createProcess(result).subscribe(ans=>{
        this.displayUpsertProcess=false;
        this.getProcesses(this.curentEndGroupId);
      })
    }else{
      this.masterSheetService.editProcess(result,this.currentProcess?.id).subscribe(ans=>{
        this.displayUpsertProcess=false;
        this.ngOnInit();
      })
    }
  }
  public delete(db){
    this.procList.splice(this.procList.indexOf(db),1);
  }

  public navigate(db){
    this.navigation.emit({type:"Process",data:db,title:db.procName})
  }

  public dismissModal(){
    this.displayUpsertProcess=false
  }

  public addLocation():void{
    this.locationArray.value.push({id:0,locationName:"",emission:"",equivalent:"",directEmission:"",indirectEmission:""});
  }
}
