import { Component, OnInit, Input } from "@angular/core";
import { ComponentLogService } from "src/app/services/component-log.service";
import { ComponentLogOverviewDto } from "src/app/models/dtos.model";

@Component({
  selector: "app-component-file-log",
  templateUrl: "./component-file-log.component.html",
  styleUrls: ["./component-file-log.component.css"],
})
export class ComponentFileLogComponent implements OnInit {
  public componentLogs: ComponentLogOverviewDto[];
  public orderAsc: boolean = false;
  public fieldName: string;
  public openFile: boolean = false;
  public selectedFile: any;

  @Input() public currentComponentId: number;
  constructor(private componenetLogService: ComponentLogService) {}

  ngOnInit(): void {
    this.getComponentLog();
  }

  public getComponentLog(): void {
    this.componenetLogService
      .getComponentLogs(this.currentComponentId)
      .subscribe((res) => {
        this.componentLogs = res;
        this.componentLogs = this.componentLogs.filter((x) =>
          x.action.includes("status")
        );
      });
  }
  public showFile(file: any): void {
    this.openFile = true;
    this.selectedFile = file;
  }

  public closeFileModal(event: any): void {
    this.openFile = false;
  }
}
