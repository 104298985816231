import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserService } from "../services/user.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-request-demo",
  templateUrl: "./request-demo.component.html",
  styleUrls: ["./request-demo.component.css"],
})
export class RequestDemoComponent implements OnInit {
  public permission: string;
  public selected: string = "assetTracking";

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getUser();
  }

  public getUser() {
    this.userService.getCurrentUser().subscribe(user=>{
      this.userService
      .getUserById(Number(localStorage.getItem("user_id")))
      .subscribe((res) => {
        this.permission = res.permission;
      });
    });
  }

  log() {}
}
