import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UsersService } from '../../../services/yardscore-services/users.service';

@Component({
  selector: 'app-ysa-upsert-user',
  templateUrl: './ysa-upsert-user.component.html',
  styleUrls: ['./ysa-upsert-user.component.scss']
})
export class YsaUpsertUserComponent implements OnInit {

  @Input() actionType:any=null;
  @Input() currentUser:any=null;
  @Input() companyId:any=null;
  @Output() closeUpsertForm:any= new EventEmitter();

  public userToUpsert: FormGroup;
  public permissions={"Edit dashboard":false,"Delete dashboard":false,"Export reports":false};
  public userTypes:any=[]=["Admin","Employee"];
  public userType="Select user Type";
  public userAccesses:any=null;

  constructor(
    private fb: FormBuilder,
    private usersService:UsersService
  ) { }

  ngOnInit(): void {
    this.resetForm();
  }

  public resetForm(){
    this.userToUpsert = this.fb.group({
      id: [this.currentUser?.id!=null?this.currentUser?.id:""],
      companyId: [parseInt(this.currentUser?.companyId!=null?this.currentUser?.companyId:this.companyId)],
      title: [this.currentUser?.title!=null?this.currentUser?.title:""],
      name: [this.currentUser?.name!=null?this.currentUser?.name:""],
      email: [this.currentUser?.email!=null?this.currentUser?.email:""],
      phone: [this.currentUser?.phone!=null?this.currentUser?.phone:""],
      accesses: [this.currentUser?.userAccesses!=null?this.currentUser?.userAccesses:[]],
      userType: [this.currentUser?.userType!=null?this.currentUser?.userType:"Select user Type"],
    });
    this.userType=this.currentUser?.userType!=null?this.currentUser?.userType:"Select user Type";
    this.userAccesses=this.currentUser?.userAccesses;
   if(this.userAccesses!=null){
    for (let i = 0; i < this.userAccesses.length; i++) {
      this.permissions[this.userAccesses[i].moduleName]=true;
    }
   }
   console.log(this.userAccesses);
   
  }


  public upsert(){
    this.userAccesses=[];
    for (let key in this.permissions) {
      if(this.permissions[key]==true){
        this.userAccesses.push(key);
      }
    }
    
    this.userToUpsert.patchValue({accesses: this.userAccesses});
    this.userToUpsert.patchValue({userType: this.userType});


    if(this.actionType=='create'){
      this.userToUpsert.patchValue({id: 0});

      this.usersService.createUser(this.userToUpsert.value).subscribe(ans=>{
        this.closeUpsertForm.emit(true);
      },err=>{
        console.log(err);
        this.closeUpsertForm.emit(false);
      })
    }else{
      this.usersService.updateUser(this.userToUpsert.value).subscribe(ans=>{
        this.closeUpsertForm.emit(true);
      },err=>{
        console.log(err);
        this.closeUpsertForm.emit(false);
      })
    }

  }
  
  public cancel(){
    this.closeUpsertForm.emit(false);
  }

  public selectUserType(type){
    if(this.userType==type){
      this.userType="Select user Type";
    }else{
      this.userType=type;
    }
  }
}
