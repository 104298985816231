import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CompanyAssessmentService } from '../../../../services/company-assessment.service';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.scss']
})
export class DataCollectionComponent implements OnInit {
  @Output() nextStep=new EventEmitter();
  @ViewChild(WarningModalComponent) warningModalComponent: WarningModalComponent;
  public showWarning = false;
  public categories:any=[];
  public currentUserInformation:any;
  public companyAssessmentId:any=null;
  public selectedCategory : any=null;
  public selectedProcess=null;
  public showProcessModal:any=false;
  public showWarningModal:any=false;
  public sampleProcessStatus=["done","pending","in-progress","new"]
  public showTutorial=false;
  public th_toDiplay=0;
  public addLeftSideScroll = false;

  public addedProcesData=[]
  public processes=[]

  public qualityMap={
    "Monitoring - Personal report":"High",
    "Local Measurement":"High",
    "Sensory Calculation":"High",
    "Quantification of qualitatives entities":"High",
    "Projection data":"Medium",
    "Reported":"Medium",
    "Estimated":"Medium",
    "External Source":"Low",
    "Documented  by Supplier":"Low",
    "Based on Bibliography":"Low"
  }

  constructor(
    private companyAssessmentService:CompanyAssessmentService,
    private route: ActivatedRoute,
    private toastrService:ToastrService,
    private userService:UserService,
  ) { }

  ngOnInit(): void {
    this.loadCategories();
    this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(res=>{
      this.currentUserInformation= {date:new Date(), email:res.email,session:localStorage.getItem("access_token").substring(localStorage.getItem("access_token").length - 12)};      
    });
  }



  public loadCategories(){
    this.companyAssessmentId = this.route.snapshot.paramMap.get('companyAssesssmentId');
    this.companyAssessmentService.getcategoriesByCompanyAssessmentId(this.companyAssessmentId).subscribe(ans=>{
      this.categories=ans
    })
  }

  public selectCategory(categoryToSelect){
    this.selectedCategory=categoryToSelect;
    this.showTutorial=true;
    this.toggleTutorial();
    this.th_toDiplay=(this.selectedCategory.name=="Flight and Accomodation"?1:(this.selectedCategory.name=="Home office"?2:0))
    this.companyAssessmentService.getDataCollectionByType(this.companyAssessmentId,categoryToSelect['id']).subscribe(ans=>{
      this.processes=ans;

    },err=>{

    })
    this.addLeftSideScroll = true;
  }

  public deleteProcess(processToDelete){
    this.companyAssessmentService.removeDataCollectionProcess(this.companyAssessmentId,this.selectedCategory!['id'],processToDelete.id).subscribe(ans=>{
      this.processes=this.processes.filter(x=>x!=processToDelete);
    })
  }
  public getsentedElement(item){
    let x = this.categories.filter(a=>a.name==item)[0]
    this.selectedCategory = x
  }

  public toggleModal(event){
    this.showProcessModal=this.toggleModal;
    if(event==true){
      this.updateCategoryStatus("in-progress");
      //this.selectedCategory=null;
    }
  }

  public confirmCategory(){
    this.updateCategoryStatus("confirmed");
  }

  public updateCategoryStatus(newStatus){
    this.companyAssessmentService.updateCategoryStatus(this.companyAssessmentId,{
      categoryId:this.selectedCategory?this.selectedCategory['id']:null,
      newStatus:newStatus
    }).subscribe(ans=>{
      this.loadCategories();
      //this.selectedCategory=null;
      this.selectCategory(this.selectedCategory);
      if(newStatus=="confirmed"){
        this.toastrService.success("Your input data saved","Success")
      }else{
        this.toastrService.warning("Your data is saved but not confirmed!","Confirmation required")
      }
    })
  }

  // show warning modal
  public toggleWarningModal(event){
    this.showWarningModal=this.toggleWarningModal
  }
  public goToNextStep(next){
    this.nextStep.emit({step:next,data:null});
  }

  public toggleTutorial(){
    this.showTutorial=!this.showTutorial;
  }
  public executeFunctionOnProceed() {
    this.showWarningModal=false;
    this.showWarning = true;
    this.companyAssessmentService.calculateCompanyAssessment(this.route.snapshot.paramMap.get('companyAssesssmentId')).subscribe(res=>{
      this.showWarning=false
      this.nextStep.emit({step:'next',data:this.companyAssessmentId});
    });
  }



  public editProcess(processToEdit){
    this.selectedProcess=processToEdit;
    this.showProcessModal=true;
  }

  public openFile(fileUrl){
    window.open(fileUrl, '_blank').focus();
  }

  public calculateResult(){
    this.companyAssessmentService.getUncompletedCategories(this.route.snapshot.paramMap.get('companyAssesssmentId')).subscribe(res=>{
      if(res.length>0){
        this.showWarningModal=true;
      }
      else{
        this.companyAssessmentService.calculateCompanyAssessment(this.route.snapshot.paramMap.get('companyAssesssmentId')).subscribe(res=>{
          this.showWarning=false
          this.nextStep.emit({step:'next',data:this.companyAssessmentId});
        });
      }
    });
  }

  public tutorialGuidlines={
    "1.1 - Fuel Stationary Source":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Fuels.mp4",
      "guidelines":`<p data-renderer-start-pos="217"><em data-renderer-mark="true"><strong data-renderer-mark="true">Equipment Types: </strong></em>List the specific types of stationary equipment involved in fuel combustion, such as boilers, furnaces, burners, turbines, heaters, incinerators, engines, flares, and other relevant devices.</p>
      <p data-renderer-start-pos="458"><em data-renderer-mark="true"><strong data-renderer-mark="true"><u data-renderer-mark="true">Exclusions:</u></strong></em> Please note that this data entry pertains solely to stationary equipment. Exclude any information regarding the combustion of fuels in transportation devices, including automobiles, trucks, buses, trains, airplanes, boats, ships, barges, vessels, and similar modes of transportation.</p>`
    },
    "1.2 - Refrigerant Leaks":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Refrigerants.mp4",
      "guidelines":`<p data-renderer-start-pos="217"><span>From leakage from air-conditioning and refrigeration units or the release of other gases that have a global warming potential to the atmosphere.</span></p>`
    },
    "1.3 - Company Vehicles":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Passenger+Vehicles.mp4",
      "guidelines":`<p data-renderer-start-pos="1226"><em data-renderer-mark="true"><strong data-renderer-mark="true">Country-Specific Factors:</strong></em> Fill in the appropriate country information on the "Your Organization" tab, as certain emission factors are specific to each country. This will help provide more accurate calculations and analysis.</p>
      <p data-renderer-start-pos="1451"><em data-renderer-mark="true"><strong data-renderer-mark="true">Double Counting Prevention:</strong></em> Exercise caution to avoid double counting when reporting travel emissions alongside an organization's general electricity consumption. Specifically, for Plug-in Hybrid Electric and Battery Electric vehicles, ensure that the emissions associated with electricity usage are not already accounted for separately.</p>`
    },
    "2 - Purchased Electricity, Heat, Steam & Cooling":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Heat+%26+Steam.mp4",
      "guidelines":`<p data-renderer-start-pos="1825"><strong data-renderer-mark="true">Electricity: </strong>Scope of Reporting: Include electricity consumption specifically at sites owned or controlled by the organization. Exclude any electricity usage from sites or facilities not directly owned or controlled by the organization.</p>
      <p data-renderer-start-pos="2063">Accurate Measurement: Ensure accurate measurement of electricity consumption by using reliable metering systems and collecting data from the appropriate sources. This will contribute to precise reporting and analysis.</p>
      <p data-renderer-start-pos="2282">Exclusion of Duplicated Data: Avoid double counting electricity consumption that may have been previously accounted for in other reporting categories. It is important to report only the electricity consumed directly at the organization's sites without duplicating any usage that may have already been included elsewhere.</p>
      <p data-renderer-start-pos="2608"><strong data-renderer-mark="true">Heat and steam:</strong>Purchased Heat/Steam Energy: Include emissions associated with the purchased heat/steam energy used by the organization. This may involve energy obtained from external sources, such as district heating systems or steam suppliers.</p>
      <p data-renderer-start-pos="2854">Specific Industrial Processes: Take into account the emissions generated by the use of purchased heat/steam energy in specific industrial processes. This may include heating applications, such as space heating or process heating, as well as any other industrial processes that utilize heat or steam energy.</p>
      <p data-renderer-start-pos="3166"><strong data-renderer-mark="true">District cooling: </strong>Centralized Energy Plant: Include emissions related to the operation of the centralized energy plant responsible for generating chilled water for air conditioning purposes. This may involve processes such as electricity generation, cooling, and distribution of chilled water.</p>
      <p data-renderer-start-pos="3461">Underground Pipe Distribution: Take into account any emissions associated with the underground pipe distribution system used to transport the chilled water from the centralized energy plant to various buildings or facilities for air conditioning purposes.</p>`
    },
    "3.3.1 - Fuel- and Energy-Related Activities - WTT":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/All+Other+Fuel+&+Energy.mp4",
      "guidelines":`<p data-renderer-start-pos="217"><em data-renderer-mark="true"><strong data-renderer-mark="true">Extraction and Refining:</strong></em><span> Take into account the emissions generated during the extraction and refining processes of the raw fuel sources. This includes activities such as mining, drilling, extraction, processing, and refining of fossil fuels or other raw materials.</span></p>`
    },
    "T&D - transmission and distribution":{
      "videpUrl":"s3://reflowplatformfiles/company-assessment/Fuels.mp4",
      "guidelines":`<p data-renderer-start-pos="1825"><span>Emissions associated with grid losses (the energy loss that occurs in getting the electricity from the power plant your organization.) same value as electricity</span></p>`
    },
    "3.5.2 - Water Generated in Operations":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Water+supplied.mp4",
      "guidelines":`<p data-renderer-start-pos="3904"><em data-renderer-mark="true"><strong data-renderer-mark="true">Mains Supply Network:</strong></em> Include emissions related to the provision of water through the mains supply network. This includes the transportation and distribution of water from the source to your organization's premises.</p>
      <p data-renderer-start-pos="4121"><em data-renderer-mark="true"><strong data-renderer-mark="true">Water Treatment:</strong></em> Take into account any emissions associated with water treatment processes, such as filtration, disinfection, and purification, that are necessary for maintaining water quality before it enters the mains supply network.</p>`
    },
    "3.1 - Purchased Goods and Services - Material Use":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Purchased+Materials.mp4",
      "guidelines":`<p data-renderer-start-pos="4375"><em data-renderer-mark="true"><strong data-renderer-mark="true">Material Lifecycle: </strong></em>Include all materials, starting from their extraction or sourcing, primary processing, manufacturing, and transportation to the point of sale. This encompasses emissions associated with activities such as mining, refining, manufacturing processes, and transportation logistics.</p>
      <p data-renderer-start-pos="4674"><em data-renderer-mark="true"><strong data-renderer-mark="true">Comprehensive Scope:</strong></em> Ensure that all relevant materials consumed within the reporting period are accounted for. This includes raw materials, intermediate products, and finished goods utilized or sold by the organization.</p>`
    },
    "3.5.1 - Waste Generated in Operations":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/waste+disposal.mp4",
      "guidelines":`<p data-renderer-start-pos="4918"><em data-renderer-mark="true"><strong data-renderer-mark="true">Material Types:</strong> </em>Specify the types of materials disposed of, including solid waste, liquid waste, hazardous waste, and any other relevant waste streams generated by the organization.</p>
      <p data-renderer-start-pos="5102"><em data-renderer-mark="true"><strong data-renderer-mark="true">Quantities:</strong> </em>Provide the quantities or volumes of each waste material disposed of during the reporting period. This information helps in accurately assessing the environmental impact.</p>
      <p data-renderer-start-pos="5287"><em data-renderer-mark="true"><strong data-renderer-mark="true">Waste Treatment Methodology:</strong></em> Outline the methodologies employed for waste treatment, including waste collection, transportation, treatment, and final disposal processes. Specify any recycling, composting, or other sustainable waste management practices implemented.</p>
      <p data-renderer-start-pos="5555"><em data-renderer-mark="true"><u data-renderer-mark="true">Verification / Data quality:</u></em> Mention that the reported values align with the corresponding data available in the policy entities systems specific to your country. This ensures transparency and verification of the reported waste disposal figures.</p>`
    },
    "3.6.1 - Flight and Accommodation":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Business+Travel+and+Accommodation.mp4",
      "guidelines":``
    },
    "3.6 - Business Travel":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Business+Travel+and+Accommodation.mp4",
      "guidelines":`<p data-renderer-start-pos="5825"><em data-renderer-mark="true"><strong data-renderer-mark="true">Individuals Flying for Work Purposes:</strong></em></p>
      <p data-renderer-start-pos="5864">Report the total km of the travel, you can check on the ticket or checking this website:<span data-inline-card="true" data-card-url="https://www.distance.to/"><span class="loader-wrapper"><span aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a href="https://www.distance.to/" role="button" data-testid="inline-card-resolved-view" class="css-1qo9lmc" tabindex="0"><span data-testid="inline-card-icon-and-title" class="css-1awfwlv"><span class="css-1yitqmp"><span class="css-w2w4jx"></span><img class="smart-link-icon css-fy7h5c" src="https://www.distance.to/favicon-16x16.png?v=2bwaGzj3JR" data-testid="inline-card-icon-and-title-image" /></span><span class="smart-link-title-wrapper css-0">Distance calculator - Calculate the distance online!</span></span></a></span></span></span></p>
      <p data-renderer-start-pos="5956">&nbsp;</p>
      <p data-renderer-start-pos="5958"><em data-renderer-mark="true"><strong data-renderer-mark="true">Emissions Associated with Overnight Hotel Stays for Work Purposes:</strong></em></p>
      <p data-renderer-start-pos="6026">Report the Country where the hotel stay occurred.</p>
      <p data-renderer-start-pos="6077">Provide the Number of Occupied Rooms during the stay.</p>
      <p data-renderer-start-pos="6132">Specify the Number of Nights per Room.</p>
      <p data-renderer-start-pos="6172"><em data-renderer-mark="true"><strong data-renderer-mark="true">Travel for Business Purposes in Assets Not Owned or Directly Operated by the Business:</strong></em></p>
      <p data-renderer-start-pos="6260">Report the Vehicle used for business purposes (e.g., employee-owned car, public transport, hire car).</p>
      <p data-renderer-start-pos="6363">Specify the Type of vehicle (e.g., sedan, SUV, train, bus).</p>
      <p data-renderer-start-pos="6424">Indicate the Fuel type used by the vehicle.</p>
      <p data-renderer-start-pos="6469">Provide the Total Distance traveled for business purposes using the vehicle.</p>
      <p data-renderer-start-pos="6547">By including these details in your reporting, you ensure accuracy and transparency in assessing emissions related to work-related travel and accommodation.</p>`
    },
    "3.4 - Upstream Transportation and Distribution - Freighting Goods":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Freighting+of+goods.mp4",
      "guidelines":`<p data-renderer-start-pos="6728"><em data-renderer-mark="true"><strong data-renderer-mark="true">Shipment Method:</strong></em> Specify the mode of shipment, such as land, sea, or air. If available, provide the <u data-renderer-mark="true">tonne per kilometer (</u><a data-testid="link-with-safety" href="http://t.km/" title="http://t.km/" data-renderer-mark="true" class="css-tgpl01"><u data-renderer-mark="true">t.km</u></a><u data-renderer-mark="true">)</u> emissions factor specific to each mode of transportation used.</p>
      <p data-renderer-start-pos="6919"><em data-renderer-mark="true"><strong data-renderer-mark="true">Number of Trips per Location:</strong></em> Specify the number of trips made for each location where goods are shipped from or delivered to.</p>
      <p data-renderer-start-pos="7047">This information helps in accurately calculating the overall emissions associated with goods shipment.</p>`
    },
    "3.7 - Employee Commuting":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Employee+commuting.mp4",
      "guidelines":`<p data-renderer-start-pos="7178"><em data-renderer-mark="true"><strong data-renderer-mark="true">Commuting Method:</strong></em> Specify the transportation methods used by employees for commuting between their homes and worksites, such as car, public transport, walking, or cycling.</p>
      <p data-renderer-start-pos="7351"><em data-renderer-mark="true"><strong data-renderer-mark="true">Distance:</strong></em> Estimate or collect data on the average distance traveled by employees for their daily commute.</p>`
    },
    "Food":{
      "videpUrl":"s3://reflowplatformfiles/company-assessment/Fuels.mp4",
      "guidelines":``
    },
    "3.16 - Other Non-official Categories - Home Office":{
      "videpUrl":"https://reflowplatformfiles.s3.eu-west-1.amazonaws.com/company-assessment/Working+from+home.mp4",
      "guidelines":`<p data-renderer-start-pos="7477"><em data-renderer-mark="true"><strong data-renderer-mark="true">Country: </strong></em>Provide the country where the home office is located. Emission factors may vary based on the energy mix and efficiency of the local electricity grid.<br />Number of Employees: Report the total number of employees working from home offices.</p>
      <p data-renderer-start-pos="7722"><em data-renderer-mark="true"><strong data-renderer-mark="true">Working Regime: </strong></em>Indicate the working regime for full-time or part-time employees . This information helps calculate emissions based on work hours.</p>
      <p data-renderer-start-pos="7870"><em data-renderer-mark="true"><strong data-renderer-mark="true">Percentage Working from Home:</strong></em> Specify the percentage of time employees work from their home offices (e.g., 50%). This accounts for partial remote work arrangements.</p>
      <p data-renderer-start-pos="8036"><em data-renderer-mark="true"><strong data-renderer-mark="true">Number of Months: </strong></em>Report the number of months during which employees worked from home offices.</p>`
    }
  }
}
