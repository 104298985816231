import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoriesService } from '../../../services/yardscore-services/categories.service';
import { ModulesService } from '../../../services/yardscore-services/modules.service';
import { SubcategoriesService } from '../../../services/yardscore-services/subcategories.service';

@Component({
  selector: 'app-questionnaire-child-delete',
  templateUrl: './questionnaire-child-delete.component.html',
  styleUrls: ['./questionnaire-child-delete.component.scss']
})
export class QuestionnaireChildDeleteComponent implements OnInit {

  @Input() currentChild:any=null;
  @Input() actionOn=null;
  @Output() closeDeleteModal=new EventEmitter()

  constructor(
    private categoriesService:CategoriesService,
    private modulesService:ModulesService,
    private subcategoriesService:SubcategoriesService
  ) { }

  ngOnInit(): void {
    
  }

  public delete(){
    if (this.actionOn=='category') {
      this.categoriesService.deleteCategory(this.currentChild?.id).subscribe(ans=>{
        this.closeDeleteModal.emit(true);
      },err=>{
        console.log(err);
        this.closeDeleteModal.emit(false);
      })
    }else if(this.actionOn=='module'){
      this.modulesService.deleteModule(this.currentChild?.id).subscribe(ans=>{
        this.closeDeleteModal.emit(true);
      },err=>{
        console.log(err);
        this.closeDeleteModal.emit(false);
      })
    }else{
      this.subcategoriesService.deleteSubcategory(this.currentChild?.id).subscribe(ans=>{
        this.closeDeleteModal.emit(true);
      },err=>{
        console.log(err);
        this.closeDeleteModal.emit(false);
      })
    }
  }

  public cancel(){
    this.closeDeleteModal.emit(false);
  }

}
