import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiIntegrationService {
  environmentUrl = "Debug api";

  constructor(private http: HttpClient,
    private helperService: HelperService) { 
    this.environmentUrl = environment.apiUrl;
    }
    public getMaterialList(): any {
      return this.http.get<any>(
        `${this.environmentUrl}/api/integration/usage`, // Utilisation de l'URL de l'environnement pour construire l'URL de l'API
        this.helperService.headers()
      );
    }
}
