import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ShareService } from '../../services/share.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share-overview',
  templateUrl: './share-overview.component.html',
  styleUrls: ['./share-overview.component.scss']
})
export class ShareOverviewComponent implements OnInit {

  public showNewFolderModal: boolean = false;
  public showSharedView: boolean = false;
  public showDetailsView: boolean = false;
  public showMoreView: boolean = false;
  public showRequestView: boolean = false;
  public reflowidstring:string="";
  public currentRefId :string="";
  public active = 1;
  public selectedProject:any=null;
  public companyId;
  public selectedSharingCode="";
  public currentUserInformation:any;
  public sharingAction="";

  constructor(
    private userService:UserService,
    private shareService:ShareService,
    private toastrService:ToastrService,
    ) {
  }

  ngOnInit(): void {
    if(localStorage.getItem('targetTab')=='3'){
      this.active=3;
    }
    this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(res=>{
      this.currentUserInformation= {date:new Date(), email:res.email,session:localStorage.getItem("access_token").substring(localStorage.getItem("access_token").length - 12)};
    });
  }

  public sharedModalActions(event:any):void{
    if(event=="open-folder-modal"){
      this.showNewFolderModal=true;
    }
    else if(event=="close-folder-modal"){
      this.showNewFolderModal=false;
    }
  }

  public showSharedModal(isShow: boolean) {
    this.showSharedView = isShow;
  }

  public showDetailsModal(inReturn) {
    this.showDetailsView = inReturn.toggle;
    this.showSharedView = inReturn.target;
    if(inReturn.target==true){
      this.selectedSharingCode=inReturn.sharingCode;
    }
    if(inReturn.toggle == false){
      this.sharingAction = "share";
    }
  }

  public showViewMoreModal(inReturn) {
    this.showMoreView = inReturn;
  }


  getReflowidFromMyProject(newItem: string) {
    this.reflowidstring = newItem;
    this.currentRefId = this.reflowidstring
  }
  addItem(sProject: any) {
    this.selectedProject = sProject;
  }
  public changeProjectStatus()
  {
    this.selectedProject = null;
  }

  public confirmShared(){
    this.shareService.confirmShared(this.selectedProject.id).subscribe(ans=>{
      this.toastrService.success("Share success");
      localStorage.setItem('targetTab','3');
      this.ngOnInit();
    },err=>{
      this.toastrService.error("Share error")

    })
  }
}
