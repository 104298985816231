<div class="modals rename-modal">
    <div class="main-modal-content rename">
        <div class="header">
            <em class="icon-close" (click)="closeModal()"></em>
        </div>
        <div class="body">
            <div class="title-container">
                <img src="../../../../assets/create_new_folder.png" alt="">
                <label for="" class="title">Create a new folder</label>
            </div>
            <div class="form-group">
                <label class="input-title">Enter new folder name:</label>
                <input type="text" class="form-control" placeholder="Folder name" [(ngModel)]="folderName">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="rename rename-btn" (click)="save()">Create</button>
            </div>
        </div>
    </div>
</div>
<div class="overlay"></div>
