import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-sales-overview',
  templateUrl: './sales-overview.component.html',
  styleUrls: ['./sales-overview.component.css']
})
export class SalesOverviewComponent implements OnInit {
  public companies : Array<any>;
  public companyDetail : Company;
  constructor(private salesService :SalesService) { }

  ngOnInit(): void {
    this.getSalesOver();
  }
  public getSalesOver() {
    this.salesService.getSalesOverview().subscribe((res) => {
      this.companies = res;
    });
  }
  public showCompanyDetails(companyId :number){
   this.companyDetail = this.companies.find(x => x.company.id === companyId);
  }


}
