<div class="filter-form">
  <div class="filter-header">
    <label class="filter-title">
      Filter
    </label>
    <em class="icon-close close" (click)="closeModal(false)"></em>
  </div>
  <div class="filter-content">
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseOwner" aria-expanded="false" aria-controls="collapseOwner"
        (click)="changeIndex(0)">
        <label class="filter-section-title">Owner</label>
        <i class="fa fa-chevron-down right" *ngIf="index[0]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[0]!=1"></i>
      </div>

      <div class="collapse" id="collapseOwner">
        <div class="row" *ngFor="let owner of owners">
          <input class="checkbox" type="checkbox" value="{{ owner.id }}" (change)="checkOwner($event)" />
          <label class="checkbox-text">{{ owner.companyName }}</label>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseManufacturer" aria-expanded="false"
        aria-controls="collapseManufacturer" (click)="changeIndex(1)">
        <label class="filter-section-title">
          Manufacturer
        </label>
        <i class="fa fa-chevron-down right" *ngIf="index[1]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[1]!=1"></i>
      </div>
      <div class="collapse" id="collapseManufacturer">
        <div class="row" *ngFor="let manufacturer of manufacturers">
          <input class="checkbox" type="checkbox" value="{{ manufacturer.id }}" (change)="checkManufacturer($event)" />
          <label class="checkbox-text">{{ manufacturer.companyName }}</label>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseType" aria-expanded="false" aria-controls="collapseType"
        (click)="changeIndex(2)">
        <label class="filter-section-title">Type</label>
        <i class="fa fa-chevron-down right" *ngIf="index[2]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[2]!=1"></i>
      </div>
      <div class="collapse" id="collapseType">
        <div class="row" *ngFor="let type of typeList">
          <input class="checkbox" type="checkbox" value="{{ type }}" (change)="checkType($event)" />
          <label class="checkbox-text">{{ type }}</label>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseSupplier" aria-expanded="false" aria-controls="collapseSupplier"
        (click)="changeIndex(3)">
        <label class="filter-section-title">Supplier</label>
        <i class="fa fa-chevron-down right" *ngIf="index[3]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[3]!=1"></i>
      </div>
      <div class="collapse" id="collapseSupplier">
        <div class="row" *ngFor="let supplier of suppliers">
          <input class="checkbox" type="checkbox" value="{{ supplier.id }}" (change)="checkSupplier($event)" />
          <label class="checkbox-text">{{ supplier.companyName }}</label>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseLocation" aria-expanded="false" aria-controls="collapseLocation"
        (click)="changeIndex(4)">
        <label class="filter-section-title">Location</label>
        <i class="fa fa-chevron-down right" *ngIf="index[4]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[4]!=1"></i>
      </div>
      <div class="collapse" id="collapseLocation">
        <div class="row" *ngFor="let location of locations">
          <input class="checkbox" type="checkbox" value="{{ location.id }}" (change)="checkLocation($event)" />
          <label class="checkbox-text">{{ location.name }}</label>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div data-toggle="collapse" data-target="#collapseStatus" aria-expanded="false" aria-controls="collapseStatus"
        (click)="changeIndex(5)">
        <label class="filter-section-title">Status</label>
        <i class="fa fa-chevron-down right" *ngIf="index[5]==1"></i>
        <i class="fa fa-chevron-right right" *ngIf="index[5]!=1"></i>
      </div>
      <div class="collapse" id="collapseStatus">
        <div class="row" *ngFor="let status of statusList">
          <input class="checkbox" type="checkbox" value="{{ status }}" (change)="checkStatus($event)" />
          <label class="checkbox-text">{{ status }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-footer">
    <button class="btn-solid default" (click)="reset()">Reset</button>
    <button class="btn-solid secondary" (click)="applyFilter()">
      Apply
    </button>
  </div>
</div>