<div class="quantify-container">
    <div class="quantify-header d-flex justify-content-between">
        <div class="quantify-title-container d-flex justify-content-between">
            <div class="qunatify-title">
                <em class="icon-new_quantify"></em>
                <label class="title">Quantify</label>
            </div>

            <div class="stats-container">
                <div class="stat-item si-border-bottm">
                    <div class="stat-label">
                        <img src="./../../../assets/caculation_icon.PNG.png" alt="">
                        <label  (click)="showValidationModal()">Calculations:</label>
                        <em class="icon-info"  [ngbPopover]="popupContent1" triggers="hover" placement="bottom"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">{{flowCredits | number:'1.0-0'}}</label>
                        <button class="operation" (click)="showCalculationModal()">REQUEST</button>
                    </div>
                    <ng-template #popupContent1>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Calculations
                                </label>
                            </div>
                            <p style="text-align: left;
                            font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                            letter-spacing: 0px;
                            color: #123B68;
                            opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many calculations that are available for the ongoing month. Every
                                following month, the number of calculations will be replenished in relation to the
                                purchased subscription plan.
                            </p>
                            <p class="info-description" style="width:372px; margin-top: -30px;">
                                Need more calculations?
                            </p>
                            <p class="info-description" style="width:372px; margin-top: -30px;">
                                Calculations may be requested for either single use purposes or for monthly calculation
                                subscription plan upgrade to suit your needs in either stand-alone projects or monthly
                                calculations upgrade.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div class="stat-item si-border-bottm">
                    <div class="stat-label">
                        <img src="./../../../assets//Engineering_consultations.PNG.png" alt="">
                        <label (click)="showValidationModal()">Engineering Assistance:</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="bottom-right"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">{{company?.engineeringHours}}</label>
                        <button class="operation" (click)="showEngineeringAssistanceModal()">REQUEST</button>
                    </div>
                    <ng-template #popupContent2>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Engineering Assistance
                                </label>
                            </div>
                            <p style="text-align: left;
                                font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                                letter-spacing: 0px;
                                color: #123B68;
                                opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many engineering assistance hours that are available in total.
                                Engineering assistance hours are based on engineering consultancy or advisory from our
                                talented Environmental Engineering team related to technical platform assistance,
                                project & data support, supplier & sub-supplier assistance, life cycle assessment
                                support, circular economy advisory, and more.
                                In need of engineering assistance?
                                Engineering assistance may be requested for any type of project or problem that may
                                arise using the platform. If in doubt, feel free to use our live hotline in the
                                platform.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                In need of engineering assistance?
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                Engineering assistance may be requested for any type of project or problem that may
                                arise using the platform. If in doubt, feel free to use our live hotline in the
                                platform.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div class="stat-item">
                    <div class="stat-label">
                        <img src="./../../../assets/Validation.SVG.png" alt="">
                        <label (click)="showValidationModal()">Validation:</label>
                        <em [ngbPopover]="popupContent" triggers="hover" placement="bottom" class="icon-info"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">{{company?.validations}}</label>
                        <button class="operation" (click)="showRequestValidationModal()">REQUEST</button>
                    </div>
                    <ng-template #popupContent>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Validations
                                </label>
                            </div>
                            <p style="text-align: left;
                            font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                            letter-spacing: 0px;
                            color: #123B68;
                            opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many pre-paid validations that are available in total. Validation
                                is a critical review of the assessment by our engineering team to check if the
                                assessment aligns with the scientific practises of life cycle assessment and if it
                                correlates with the life cycle thinking. By validating your assessments, you ensure the
                                credibility of your calculations.
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                In need of assessment validation?
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                Validation may be requested for any type of completed projects in the platform.
                                Assessment shared with you may only be validated by the project owner.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
    <div class="projects-shortcut">
        <div class="new-folder" (click)="showEditFolderModal=true;modalType='upsert-folder'">
            <label class="title">New Folder</label>
            <img src="../../../assets/new_folder@2x.png" alt="" class="icon">
        </div>
        <hr class="vr">
        <div class="new-folder centered" (click)="createScreening()">
            <label class="title">New project</label>
            <img src="../../../assets/new_project@2x.png" alt="" class="icon icon2">
        </div>
        <hr class="vr">
        <div class="new-folder centered" (click)="showImportModal()">
            <label class="title" style="width: 110px;">Import Project</label>
            <img src="../../../assets/import_project.png" alt="" class="icon icon2" style="width: 83px !important;">
        </div>
        <hr class="vr">
        <div class="recent-projects-container">
            <label class="title">Recent Projects</label>
            <div class="recent-projects">
                <div class="recent-project" *ngFor="let item of recentScreenings" (click)="editSceening(item)">
                    <img src="../../../assets/rp_default@2x.png" alt="" class="main-project">
                    <img src="../../../assets/rp_hover@2x.png" alt="" class="over-project d-none">
                    <p class="project-name">
                        {{item?.name?.length>22?item?.name.substr(0,22)+'...':item?.name}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="quantify-projects">
        <div class="projects-tree">
            <div class="search-container">
                <div class="search-box" ngbDropdown>
                    <em class="icon-search icon-search-left"
                        [ngClass]="{'icon-search-left-focus': focusSearchKeyWord==true||searchKeyWord!=''}"></em>
                    <input type="text" placeholder="Search project" autocomplete="off" class="input-search"
                        (focus)="focusSearchKeyWord=true" (blur)="processBlur()" id="dropdownBasic1" ngbDropdownToggle
                        [(ngModel)]="searchKeyWord" (ngModelChange)="makeSearch()">
                    <em class="icon-close close-search" style="top: 0px; right:0px;font-size: 23px;"
                        [ngClass]="{'d-none': focusSearchKeyWord==false&&searchKeyWord==''}"
                        (click)="searchKeyWord=''"></em>
                    <div class="icon-search-right-container"
                        [ngClass]="{'d-none': focusSearchKeyWord==false&&searchKeyWord==''}">
                        <em class="icon-search icon-search-right"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="search-dropdown"
                        [ngClass]="{'d-none': searchList.length==0}">
                        <button *ngFor="let item of searchList" ngbDropdownItem (click)="editSceening(item)">
                            <img src="../../../assets/pi_draft_projects.svg" alt="">{{item.name}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="move-project-container" *ngIf="showMoveMenu!=false">
                <div class="description-container">
                    <img src="../../../assets/move.png" alt="">
                    <label for="" class="desc-text">To move the selected project(s) - please select the desired folder
                        or subfolder.</label>
                </div>
                <button class="move-button" *ngIf="showMoveButton==true" (click)="moveProjet(null)">
                    <img src="../../../assets/check.svg" alt="">
                    <label>Move to folder</label>
                </button>
                <div class="close-move" (click)="dismissMoveFolder()">
                    <img src="../../../assets/clear.svg" alt="">
                </div>
            </div>
            <div class="tree-container d-flex"
                [ngStyle]="{'border-radius': showMoveMenu==false?'4px':'','margin-top': showMoveMenu==false?'10px':''}"
                [ngClass]="{'tc1': selectedProject!=null && selectedProject.finished==false,'tc2': selectedProject!=null && selectedProject.finished==true}">
                <div class="folders-container">
                    <hr class="vr-tree">
                    <div class="folder-item" [ngClass]="{'folder-item-selected': selectedFolder=='draft'}"
                        (click)="selectedFolder='draft'; todisplay=drafts" data-toggle="collapse"
                        data-target="#drafting">
                        <img src="../../../assets/fm_closed_folder@2x.png" alt="" *ngIf=" selectedFolder!='draft'">
                        <img src="../../../assets/fm_open_folder@2x.png" alt="" *ngIf=" selectedFolder=='draft'">
                        <label for="" class="folder-name">Draft</label>
                        <img *ngIf="!canShowFolders" class="folder-loading"
                            src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>
                    <div id="drafting" class="collapse">
                        <hr class="vr-tree-2">
                        <div *ngFor="let item of draftFolders">
                            <ng-container *ngIf="item?.parentId==0">
                                <div class="folder-item-embeeded" data-toggle="collapse"
                                    [attr.data-target]="'#draft'+item?.id"
                                    [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                    (click)="selectDraftSubFolder(item); selectedSubFolder=item;">
                                    <hr class="hr-tree">
                                    <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                        *ngIf=" selectedSubFolder!=item.folderName">
                                    <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                        *ngIf=" selectedSubFolder==item.folderName">
                                    <div class="folder-label-container">
                                        <label for="" class="folder-name">{{item.folderName}}</label>
                                        <div class="operations-container" *ngIf="selectedSubFolder==item">
                                            <div class="operation  mr-2"
                                                (click)="folderToArchive=item;showArchiveModal('archive','folder')">
                                                <img src="../../../assets/books.svg" alt="">
                                                <label>Archive</label>
                                            </div>
                                            <label> | </label>
                                            <div class="operation  mr-2 ml-2">
                                                <img src="../../../assets/new_folder.svg" alt=""
                                                    (click)="showEditFolderModal=true;targetFolder=item;modalType='sub-folder'">
                                                <label>Add folder</label>
                                            </div>
                                            <label> | </label>
                                            <div class="operation ml-2" style="margin-top: 3px;">
                                                <em class="fa fa-pencil"
                                                    (click)="showEditFolderModal=true;targetFolder=item;modalType='rename-folder'"></em>
                                                <label>Edit folder</label>
                                            </div>

                                            <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasChildrensDraft[item.id]&& visitedDraft[item.parentId]">
                                <ng-container
                                    *ngTemplateOutlet="template; context{items:hasChildrensDraft[item.id],level:1,parent:item}">
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-template #template let-items="items" let-level="level" let-parent="parent">
                            <div id="draft{{parent?.id}}" class="collapse" [style]="'margin-left:'+27+'px'">
                                <hr class="vr-tree-2">
                                <div *ngFor="let item of items">
                                    <div class="folder-item-embeeded" data-toggle="collapse"
                                        [attr.data-target]="'#draft'+item?.id"
                                        [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                        (click)="selectDraftSubFolder(item); selectedSubFolder=item;">
                                        <hr class="hr-tree">
                                        <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder!=item.folderName">
                                        <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder==item.folderName">
                                        <div class="folder-label-container">
                                            <label for="" class="folder-name">{{item.folderName}}</label>
                                            <div class="operations-container" *ngIf="selectedSubFolder==item">
                                                <div class="operation  mr-2"
                                                    (click)="folderToArchive=item;showArchiveModal('archive','folder')">
                                                    <img src="../../../assets/books.svg" alt="">
                                                    <label>Archive</label>
                                                </div>
                                                <label> | </label>
                                                <div class="operation  mr-2 ml-2">
                                                    <img src="../../../assets/new_folder.svg" alt=""
                                                        (click)="showEditFolderModal=true;targetFolder=item;modalType='sub-folder'">
                                                    <label>Add folder</label>
                                                </div>
                                                <label> | </label>
                                                <div class="operation ml-2" style="margin-top: 3px;">
                                                    <em class="fa fa-pencil"
                                                        (click)="showEditFolderModal=true;targetFolder=item;modalType='rename-folder'"></em>
                                                    <label>Edit folder</label>
                                                </div>

                                                <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="hasChildrensDraft[item.id]">
                                        <ng-container
                                            *ngTemplateOutlet="template; context{items:hasChildrensDraft[item.id],level:(level+1),parent:item}">
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div data-toggle="collapse" data-target="#completing" class="folder-item"
                        [ngClass]="{'folder-item-selected': selectedFolder=='completed'}"
                        (click)="selectedFolder='completed'; todisplay=completed">
                        <img src="../../../assets/fm_closed_folder@2x.png" alt="" *ngIf=" selectedFolder!='completed'">
                        <img src="../../../assets/fm_open_folder@2x.png" alt="" *ngIf=" selectedFolder=='completed'">
                        <label for="" class="folder-name">Completed</label>
                        <img *ngIf="!canShowFolders" class="folder-loading"
                            src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>

                    <div id="completing" class="collapse">
                        <hr class="vr-tree-2">
                        <div *ngFor="let item of completedFolders">
                            <ng-container *ngIf="item?.parentId==0">
                                <div class="folder-item-embeeded" data-toggle="collapse"
                                    [attr.data-target]="'#completed'+item?.id"
                                    [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                    (click)="selectCompletedSubFolder(item); selectedSubFolder=item; ">
                                    <hr class="hr-tree">
                                    <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                        *ngIf=" selectedSubFolder!=item.folderName">
                                    <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                        *ngIf=" selectedSubFolder==item.folderName">
                                    <div class="folder-label-container">
                                        <label for="" class="folder-name">{{item.folderName}}</label>
                                        <div class="operations-container" *ngIf="selectedSubFolder==item">
                                            <div class="operation  mr-2"
                                                (click)="folderToArchive=item;showArchiveModal('archive','folder')">
                                                <img src="../../../assets/books.svg" alt="">
                                                <label>Archive</label>
                                            </div>
                                            <label> | </label>
                                            <div class="operation  mr-2 ml-2">
                                                <img src="../../../assets/new_folder.svg" alt=""
                                                    (click)="showEditFolderModal=true;targetFolder=item;modalType='sub-folder'">
                                                <label>Add folder</label>
                                            </div>
                                            <label> | </label>
                                            <div class="operation ml-2" style="margin-top: 3px;">
                                                <em class="fa fa-pencil"
                                                    (click)="showEditFolderModal=true;targetFolder=item;modalType='rename-folder'"></em>
                                                <label>Edit folder</label>
                                            </div>

                                            <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="hasChildrensCompleted[item.id]&& visitedCompleted[item.parentId]">
                                <ng-container
                                    *ngTemplateOutlet="templateCompleted; context{items:hasChildrensCompleted[item.id],level:(draftLevel+1),cparent:item}">
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-template #templateCompleted let-items="items" let-level="level" let-cparent="cparent">
                            <div id="completed{{cparent?.id}}" class="collapse" [style]="'margin-left:'+27+'px'">
                                <hr class="vr-tree-2">
                                <div *ngFor="let item of items">
                                    <div class="folder-item-embeeded" data-toggle="collapse"
                                        [attr.data-target]="'#completed'+item?.id"
                                        [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                        (click)="selectCompletedSubFolder(item); selectedSubFolder=item;">
                                        <hr class="hr-tree">
                                        <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder!=item.folderName">
                                        <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder==item.folderName">
                                        <div class="folder-label-container">
                                            <label for="" class="folder-name">{{item.folderName}}</label>
                                            <div class="operations-container" *ngIf="selectedSubFolder==item">
                                                <div class="operation  mr-2"
                                                    (click)="folderToArchive=item;showArchiveModal('archive','folder')">
                                                    <img src="../../../assets/books.svg" alt="">
                                                    <label>Archive</label>
                                                </div>
                                                <label> | </label>
                                                <div class="operation  mr-2 ml-2">
                                                    <img src="../../../assets/new_folder.svg" alt=""
                                                        (click)="showEditFolderModal=true;targetFolder=item;modalType='sub-folder'">
                                                    <label>Add folder</label>
                                                </div>
                                                <label> | </label>
                                                <div class="operation ml-2" style="margin-top: 3px;">
                                                    <em class="fa fa-pencil"
                                                        (click)="showEditFolderModal=true;targetFolder=item;modalType='rename-folder'"></em>
                                                    <label>Edit folder</label>
                                                </div>

                                                <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="hasChildrensCompleted[item.id]">
                                        <ng-container
                                            *ngTemplateOutlet="templateCompleted; context{items:hasChildrensCompleted[item.id],level:(level+1),cparent:item}">
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div data-toggle="collapse" data-target="#shared" class="folder-item"
                        [ngClass]="{'folder-item-selected': selectedFolder=='sharedFolder'}"
                        (click)="selectedFolder='sharedFolder'; todisplay=sharedScreenings">
                        <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                            *ngIf=" selectedFolder!='sharedFolder'">
                        <img src="../../../assets/fm_open_folder@2x.png" alt="" *ngIf=" selectedFolder=='sharedFolder'">
                        <label for="" class="folder-name">Shared with you</label>
                        <img *ngIf="!canShowFolders" class="folder-loading"
                            src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>
                    <div data-toggle="collapse" data-target="#archiving" class="folder-item"
                        [ngClass]="{'folder-item-selected': selectedFolder=='archive'}"
                        (click)="selectedFolder='archive'; todisplay=archives;showArchiveBuffer()">
                        <img src="../../../assets/archived-closed.png" alt="" *ngIf="true">
                        <label for="" class="folder-name">Archived</label>
                        <img *ngIf="!canShowFolders" class="folder-loading"
                            src="../../../assets/Loading-icon-animation.svg" alt="" />
                    </div>
                    <div id="archiving" class="collapse">
                        <hr class="vr-tree-2">
                        <div *ngFor="let item of archiveBuffer">
                            <div class="folder-item-embeeded" data-toggle="collapse"
                                [attr.data-target]="'#archiving'+item?.id"
                                [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                (click)="selectArchivedSubFolder(item); selectedSubFolder=item; ">
                                <hr class="hr-tree">
                                <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                    *ngIf=" selectedSubFolder!=item">
                                <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                    *ngIf=" selectedSubFolder==item">
                                <div class="folder-label-container">
                                    <label for="" class="folder-name">{{item.folderName}}</label>
                                    <div class="operations-container">
                                        <div class="options" ngbDropdown>
                                            <i class="fa fa-ellipsis-v" aria-hidden="true" id="dropdownBasic1"
                                                ngbDropdownToggle></i>
                                            <div class="dropdown-menu ddr restore-arc-tree" ngbDropdownMenu
                                                aria-labelledby="dropdownBasic1" style="width: 300px;"
                                                placement="bottom-left" display="dynamic">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    (click)="folderToArchive=item;showArchiveModal('restore','folder')"><em
                                                        class="fa fa-archive sub-icon"></em><label>Restore</label></a>
                                            </div>
                                        </div>
                                        <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                    </div>
                                </div>
                                <div id="shared" class="collapse">
                                    <hr class="vr-tree-2">
                                </div>
                            </div>
                            <ng-container *ngIf="hasChildrensArchived[item.id]">
                                <ng-container
                                    *ngTemplateOutlet="templateArchived; context{items:hasChildrensArchived[item.id],level:(draftLevel+1),cparent:item}">
                                </ng-container>
                            </ng-container>
                        </div>

                        <ng-template #templateArchived let-items="items" let-level="level" let-cparent="cparent">
                            <div id="archiving{{cparent?.id}}" class="collapse" [style]="'margin-left:'+27+'px'">
                                <hr class="vr-tree-2">
                                <div *ngFor="let item of items">
                                    <div class="folder-item-embeeded" data-toggle="collapse"
                                        [attr.data-target]="'#archiving'+item?.id"
                                        [ngClass]="{'folder-item-selected': selectedSubFolder==item,'move-item-target': selectedTargetFolder==item}"
                                        (click)="selectArchivedSubFolder(item); selectedSubFolder=item; ">
                                        <hr class="hr-tree">
                                        <img src="../../../assets/fm_closed_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder!=item">
                                        <img src="../../../assets/fm_open_folder@2x.png" alt=""
                                            *ngIf=" selectedSubFolder==item">
                                        <div class="folder-label-container">
                                            <label for=""
                                                class="folder-name">{{item.folderName}}{{item[0]?.folderName}}</label>
                                            <div class="operations-container">
                                                <div class="options" ngbDropdown>
                                                    <i class="fa fa-ellipsis-v" aria-hidden="true" id="dropdownBasic1"
                                                        ngbDropdownToggle></i>
                                                    <div class="dropdown-menu ddr restore-arc-tree" ngbDropdownMenu
                                                        aria-labelledby="dropdownBasic1" style="width: 300px;"
                                                        placement="bottom-left" display="dynamic">
                                                        <a class="dropdown-item"
                                                            (click)="folderToArchive=item;showArchiveModal('restore','folder')"><em
                                                                class="fa fa-archive sub-icon"></em><label>Restore</label></a>
                                                    </div>
                                                </div>
                                                <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
                                            </div>
                                        </div>
                                        <div id="shared" class="collapse">
                                            <hr class="vr-tree-2">
                                        </div>
                                    </div>
                                    <ng-container
                                        *ngTemplateOutlet="templateArchived; context{items:hasChildrensArchived[item.id],level:(draftLevel+1),cparent:item}">
                                    </ng-container>
                                </div>
                            </div>
                        </ng-template>

                    </div>
                    <div id="shared" class="collapse">
                        <hr class="vr-tree-2">

                    </div>
                </div>
                <div class="empty-container"></div>
                <div class="projects-container" *ngIf="selectedFolder!=''">
                    <ng-container *ngFor="let item of todisplay">
                        <div class="project-item" [ngClass]="{'project-item-selected': selectedProject?.id==item?.id}"
                            (click)="selectProject(item)">
                            <img src="../../../assets/pi_draft_projects.svg" alt="" *ngIf="selectedFolder=='draft'">
                            <img src="../../../assets/pm_completed_projects@2x.png" alt=""
                                *ngIf="selectedFolder=='completed'">
                            <img src="../../../assets/pm_completed_projects@2x.png" alt=""
                                *ngIf="selectedFolder=='sharedFolder'">
                            <label for="" class="project-name">{{item.name}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>




        <div class="project-details" *ngIf="selectedProject!=null && selectedProject.finished==false">
            <div class="header-p">
                <div class="project-det-actions" *ngIf="selectedProject?.archived==false">
                    <img src="../../../assets/books.svg" style="width:28px !important ;margin-left: 5px" alt=""
                        (click)="showArchiveModal('archive','project')" placement="top" ngbTooltip="Archive"
                        *ngIf="selectedFolder!='archive'">
                    <img src="../../../assets/move.png" style="width: 30px !important;margin-top: 2px;" alt=""
                        (click)="showMoveMenu=true" placement="top" ngbTooltip="Move to folder"
                        *ngIf="selectedFolder!='archive'">
                    <img src="../../../assets/box.svg" style="width:25px !important" alt=""
                        (click)="showArchiveModal('restore','project')" placement="top" ngbTooltip="Restore"
                        *ngIf="selectedFolder=='archive'">
                    <img src="../../../assets/delete.svg" alt="" (click)="showDeleteModal(selectedProject)"
                        placement="top" ngbTooltip="Delete">
                </div>
                <img *ngIf="selectedProject?.archived==false" src="../../../assets/open.svg" alt="" style="float:right;"
                    class="open" (click)="editSceening(selectedProject)">

                <label class="" *ngIf="selectedProject?.archived==true">&nbsp;</label>
                <label *ngIf="selectedProject?.archived==true" class="restore-link"
                    (click)="showArchiveModal('restore','project')">Restore</label>
            </div>
            <div class="body">
                <label for="" class="title">
                    {{selectedProject?.name}}
                </label>

                <label for="" class="belongs-to" *ngIf="false">
                    <div class="move-folder-container" (click)="moveFolderExpanded=!moveFolderExpanded">
                        <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-folder"
                            aria-expanded="false" aria-controls="collapse-move-folder">
                            <em
                                [ngClass]="{'icon-arrow-down': moveFolderExpanded==false,'icon-close': moveFolderExpanded==true}"></em>
                        </button>
                        <div id="collapse-move-folder" class="collapse mvf-dropdown no-bb">
                            <button class="dd-item" *ngFor="let folder of expandables" data-toggle="collapse"
                                data-target="#collapse-move-folder">
                                <img src="../../../assets/outline-folder.png" alt="">
                                <label for="">{{folder?.folderName}}</label>
                            </button>
                        </div>
                    </div>
                </label>
                <img src="../../../assets/pi_draft_projects.svg" alt="" class="logo">
                <label for="" class="info">
                    Information
                </label>
            </div>
            <ng-container *ngFor="let item of projectInfo">
                <div class="info-item">
                    <label for="" class="label">{{item.name}}</label>
                    <div class="status-container" *ngIf="item.stateValue==true">
                        <img src="../../../assets/completed_stage@2x.png" alt="">
                        <label for="" class="status stat-complete">Completed</label>
                    </div>
                    <div class="status-container" *ngIf="item.stateValue==false">
                        <img src="../../../assets/in_progress@2x.png" alt="">
                        <label for="" class="status stat-progress">In progress</label>
                    </div>
                </div>
            </ng-container>

            <div class="info-item">
                <label for="" class="label">Establish date:</label>
                <div class="status-container">
                    <label for="" class="status stat-date">{{selectedProject?.creationDate|date:'MMM d, y, h:mm:ss
                        a'}}</label>
                </div>
            </div>
        </div>
        <div class="project-details" *ngIf="selectedProject!=null && selectedProject.finished==true">
            <div class="header-p">
                <span class="header-p-left" *ngIf="selectedProject?.archived==false">
                    <img src="../../../assets/mail-forward.svg" style="width:28px !important" alt=""
                        (click)="showShareModal(selectedProject)" placement="top" ngbTooltip="Share">
                    <img src="../../../assets/duplicate.svg" alt="" *ngIf="hideDuplicateicon" style="margin-left: 10px;"
                        (click)="copyScreening(selectedProject.id)" placement="top" ngbTooltip="Duplicate">
                    <img src="../../../assets/move.png"
                        style="width: 30px !important;margin-top: 2px;margin-left: 10px;" alt=""
                        (click)="showMoveMenu=true" placement="top" ngbTooltip="Move to folder">
                    <img src="../../../../../assets/Loading-icon-animation.svg" *ngIf="showLoad"
                        style="height: 35px; width: 42px; margin-left: 20px;" alt="" />
                    <img src="../../../assets/books.svg" style="width:28px !important" alt="" style="margin-left: 10px;"
                        (click)="showArchiveModal('archive','project')" placement="top" ngbTooltip="Archive"
                        *ngIf="selectedFolder!='archive'">
                    <img src="../../../assets/box.svg" style="width:28px !important" alt="" style="margin-left: 10px;"
                        (click)="showArchiveModal('restore','project')" placement="top" ngbTooltip="Restore"
                        *ngIf="selectedFolder=='archive'">
                    <span class="dd-with-dIcon" style="margin-left: 15px;" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="showDropdown"
                        placement="top" ngbTooltip="Download">
                        <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 10px;"></i>
                        <img src="../../../assets/file_download.svg" class="dropleft ml-1" alt="">
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="showDropdown"
                            style="position: absolute; left:auto !important; right: 0 !important; margin-left: -40px;"
                            #dropdownMenu placement="bottom-left" display="dynamic">
                            <div class="dropdown-item"
                                (click)="downloadSheet(selectedProject.id,selectedProject.name,$event)">
                                <div class="label green">
                                    <em class="icon-file-earmark-spreadsheet"></em>
                                    <label>Data Sheet</label>
                                </div>
                                <ng-template #popupContent>
                                    <div class="pop-container">
                                        <div class="pop-header d-flex">
                                            <em class="icon-info bigger-info"></em>
                                            <label class="pop-title">
                                                Data Sheet
                                            </label>
                                        </div>
                                        <p class="info-description">
                                            Provides the main results in an excel format including the total carbon
                                            footprint,
                                            the contribution of each of the life cycle stages and a breakdown of the
                                            carbon
                                            footprint of all parameters included in the project.
                                        </p>
                                    </div>
                                </ng-template>
                                <em class="icon-info hovered select-item-icon" animation='false' container="body"
                                    [ngbPopover]="popupContent" triggers="hover" placement="left"></em>
                            </div>
                            <div class="dropdown-item"
                                (click)="downloadPdf(selectedProject.id,selectedProject.name,$event)">
                                <div class="label dark-blue">
                                    <em class="icon-award-fill"></em>
                                    <label>Climate Assessment Report</label>
                                </div>
                                <ng-template #popupContent1>
                                    <div class="pop-container">
                                        <div class="pop-header d-flex">
                                            <em class="icon-info bigger-info"></em>
                                            <label class="pop-title">
                                                Climate Assessment Report
                                            </label>
                                        </div>
                                        <p class="info-description">
                                            Provides a project outline that proves that the organization has carried out
                                            an
                                            assessment of the CO<sub>2</sub> emissions of a specific product or system.
                                            This document includes all key project information and the assessment
                                            results, such
                                            as total carbon footprint,
                                            most impacting life cycle stages and most impactful parameters.
                                            The assessment summary does not entitle any validation but is proof of
                                            having
                                            conducted an LCA-based assessment.
                                        </p>
                                    </div>
                                </ng-template>
                                <em class="icon-info hovered select-item-icon" animation='false' container="body"
                                    [ngbPopover]="popupContent1" triggers="hover" placement="left"></em>
                            </div>
                            <div class="dropdown-item no-bb" (click)="downloadCertificate()">
                                <div class="label light-blue">
                                    <em class="icon-accomplished"></em>
                                    <label>Certificate of Validation</label>
                                </div>
                                <ng-template #popupContent2>
                                    <div class="pop-container">
                                        <div class="pop-header d-flex">
                                            <em class="icon-info bigger-info"></em>
                                            <label class="pop-title">
                                                Project Validation
                                            </label>
                                        </div>
                                        <p class="info-description">
                                            By requesting project validation an external review of your project will be
                                            conducted by the Environmental Engineering Department at ReFlow.
                                            All project stages are reviewed including the life cycle stages, the
                                            inventory and
                                            provided data,
                                            and the project assessment outcomes to correlate and comply with the
                                            international
                                            life cycle assessment ISO standards.
                                            The users will receive a validation report from ReFlow as the documentation
                                            for the
                                            validity of their calculations.
                                        </p>
                                    </div>
                                </ng-template>
                                <em class="icon-info hovered select-item-icon" animation='false' container="body"
                                    [ngbPopover]="popupContent2" triggers="hover" placement="left"></em>
                            </div>
                        </div>
                    </span>
                    <span class="dd-container" ngbDropdown>
                        <span class="dd-with-dIcon" style="margin-left: 15px;" ngbDropdownToggle style="width: 100%; height: 100%; display:flex; align-items: center; justify-content: center; -webkit-appearance: none;
                    -moz-appearance: none;" *ngIf="projectHaveNotifications">
                            <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 10px;"
                                *ngIf="projectHaveNotifications"></i>
                            <img src="../../../assets/notification.png" class="dropleft ml-1 notification" alt=""
                                *ngIf="projectHaveNotifications">
                        </span>
                        <div class="dropdown-menu ddr-notification" ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            style="width: 300px;" placement="bottom-left" display="dynamic">
                            <div class="dropdown-item" *ngFor="let notification of projectNotifications">
                                <div class="head">
                                    <em *ngIf="notification.status=='received'" class="icon-validation validation"></em>
                                    <em *ngIf="notification.status=='in progress'" class="icon-in-progress orange"></em>
                                    <em *ngIf="notification.status=='corrected'" class="icon-edit purple"></em>
                                    <em *ngIf="notification.status=='completed'"
                                        class="icon-validation-completed blue"></em>
                                    <label>{{notification.updatedAt | date:'EEEE, MMMM d, y'}} </label>
                                </div>
                                <p>{{notification.updateDescription}}</p>
                                <p *ngIf="notification.status=='corrected'">click <a
                                        href="{{projectRequest.correctionFile}}" download>here</a> to download your
                                    correction scheme</p>

                            </div>
                        </div>
                    </span>
                    <img src="../../../../../assets/Loading-icon-animation.svg" *ngIf="loading"
                        style="height: 35px; width: 42px; margin-left: 32px;" alt="" />
                </span>
                <img src="../../../assets/open.svg" alt="" style="float:right;" class="open"
                    (click)="editSceening(selectedProject)" *ngIf="selectedProject?.archived==false">

                <label class="" *ngIf="selectedProject?.archived==true">&nbsp;</label>

                <img src="../../../assets/restore.svg" alt="" style="float:right;height: 30px;width: 75px;" class="open"
                    (click)="showArchiveModal('restore','project')" *ngIf="selectedProject?.archived==true">
            </div>
            <div class="body">
                <label for="" class="title">
                    {{selectedProject?.name}}
                </label>
                <label for="" class="belongs-to" *ngIf="false">
                    <div class="move-folder-container" (click)="moveFolderExpanded=!moveFolderExpanded">
                        <button class="move-folder-dropdown" data-toggle="collapse" data-target="#collapse-move-folder"
                            aria-expanded="false" aria-controls="collapse-move-folder">

                            <em
                                [ngClass]="{'icon-arrow-down': moveFolderExpanded==false,'icon-close': moveFolderExpanded==true}"></em>
                        </button>
                        <div id="collapse-move-folder" class="collapse mvf-dropdown no-bb">
                            <button class="dd-item" *ngFor="let folder of expandables" data-toggle="collapse"
                                data-target="#collapse-move-folder">
                                <img src="../../../assets/outline-folder.png" alt="">
                                <label for="">{{folder?.folderName}}</label>
                            </button>
                        </div>
                    </div>
                </label>
                <img src="../../../assets/r_p_completed_projects@2x.png" alt="" class="logo">
                <label for="" class="info">
                    Information
                </label>
            </div>
            <div class="info-item">
                <label for="" class="label">Product Name:</label>
                <div class="status-container">
                    <label for="" class="status stat-date">{{selectedProject?.product?.name}}</label>
                </div>
            </div>
            <div class="info-item">
                <label for="" class="label">Product Type:</label>
                <div class="status-container">
                    <label for="" class="status stat-date">{{selectedProject?.product?.type}}</label>
                </div>
            </div>
            <div class="info-item">
                <label for="" class="label">Assessment Date:</label>
                <div class="status-container">
                    <label for="" class="status stat-date">{{selectedProject?.creationDate|date:'MMM d, y'}}</label>
                </div>
            </div>
            <div class="info-item no-bb">
                <label for="" class="label">Validation status:</label>
                <ng-template #popupContent5>
                    <div class="pop-container1">
                        <div class="pop-header d-flex">
                            <label class="pop-title1">
                                Project Validation
                            </label>
                        </div>
                        <p class="info-description">
                            By requesting project validation an external review of your project will be conducted by the
                            Environmental Engineering Department at ReFlow. All project stages are reviewed including
                            the life cycle stages, the inventory and provided data, and the project assessment outcomes
                            to correlate with the international life cycle assessment ISO standards. The
                            users will receive a validation report from ReFlow as the documentation for the validity of
                            their calculations.
                        </p>
                    </div>
                </ng-template>
                <em class="icon-info validation-info" style="margin-left: 106px; position: absolute;" animation='false'
                    container="body" [ngbPopover]="popupContent5" triggers="hover" placement="top"></em>
                <div class="status-container">
                    <button class="validation-button"
                        *ngIf="projectHaveNotifications==false && selectedProject?.archived==false"> <label
                            class="validation-button-text" (click)="showResetModal($event)">$ Validate
                            Project</label></button>
                    <label *ngIf="projectHaveNotifications==true" class="validation-status"><em
                            class="{{statusIcon}}"></em> {{requestStatus}}</label>
                    <app-validation-request-modal (toogleModal)="showResetModal($event)"
                        [screeningId]="selectedProject.id" [showModal]="showResetView" *ngIf="showResetView">
                    </app-validation-request-modal>
                </div>
            </div>

        </div>


    </div>


    <div id="myModal" class="modal" *ngIf="shareModal">
        <div class="modal-content">
            <div class="header-modal">
                <em class="icon-close" style="color: #ffffff;right: 10px;top: 10px;" (click)="hideShareModal()"></em>
            </div>

            <label class="share-title">Do you want to share this assessment?</label>
            <label class="share-description">Please copy this code and share it with companies of your choosing</label>
            <div class="screenings link spacing">
                <label class="screening-name">{{sharingCode}}</label>
                <em class="fa fa-copy screening-copy" [cdkCopyToClipboard]="sharingCode" (click)="copyLink()"></em>
            </div>
            <label class="left-description">*The company will only see your result</label>
        </div>
    </div>
    <div id="myModal" class="modal" *ngIf="deleteShareModal">
        <div class="modal-content delete-modal">
            <em class="icon-close" (click)="hideDeleteShareModal()"></em>
            <label class="share-title">Do you want to delete this project?</label>
            <label class="share-description">Once deleted the shared project cannot be recovered.</label>
            <div class="row buttons">
                <button class="delete" (click)="deleteShared()">
                    <em class="fa fa-trash"></em>
                    <label>Delete</label>
                </button>
                <button class="cancel" (click)="hideDeleteShareModal()">
                    <em class="fa fa-times"></em>
                    <label>Cancel</label>
                </button>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal" *ngIf="deleteModal">
        <div class="modal-content delete-modal">
            <em class="icon-close" (click)="hideDeleteModal()"></em>
            <label class="share-title delete-title">Do you want to delete this shared project?</label>
            <div class="delete-information-container">
                <p><strong><i class="icon-info"></i>Information</strong></p>
                <p class="delete-description">This will permanently delete your draft assessment and all data associated
                    with it. Are you sure you want to proceed?</p>
            </div>
            <div class="row buttons">
                <button class="cancel back-delete-btn" (click)="hideDeleteModal()">
                    <label class="back-delete-text">Back</label>
                </button>
                <button class="delete  delete-btn" (click)="deleteCompleted()">
                    <em class="fa fa-trash"></em>
                    <label class="back-delete-text">Delete</label>
                </button>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal software-updates-modal" *ngIf="updatesModal == 'openmodal'">
        <div class="modal-content updates-modal">
            <label><img src="../../../assets/Asset 19.png" class="reflog"></label>
            <hr class="line-separation">
            <em class="icon-close" (click)="hideUpdatesModal()"></em>
            <div class="row">
                <div class="col-md-6 updates-first-column align-items-around">
                    <label class="updates-date">{{latestUpdate?.updatedAt | date:'mediumDate'}}</label>
                    <label class="latest">Latest</label>
                    <label class="software-updates">software-update</label>
                    <hr class="software-underline">
                    <label class="updates-description">{{latestUpdate?.updateDescription}}</label>
                    <div class="checkbox-container" (click)="toogleMessage()">
                        <div class="checkbox"> <em class="icon-close" [ngClass]="{'d-none': showAgain==false}"></em>
                        </div>
                        <p style="font-size: 1vw; margin-top: -10px;"> Don't show this message again</p>
                    </div>
                    <button class="su-close" (click)="hideUpdatesModal()">Close</button>
                </div>
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <img src="../../../assets/asset 2.0.png" class="computer-image">
                </div>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal" *ngIf="stopShareModal">
        <div class="modal-content stop-modal">
            <em class="icon-close" (click)="hideStopShareModal()"></em>
            <label class="share-title">Do you want to stop sharing this project?</label>
            <label class="share-description">Once you stop sharing, the users you shared the product with will not be
                able
                to use it in their product inventory</label>
            <div class="row buttons">
                <button class="delete" (click)="stopSharing()">
                    <em class="fa fa-trash"></em>
                    <label>Stop Sharing</label>
                </button>
                <button class="cancel" (click)="hideStopShareModal()">
                    <em class="fa fa-times"></em>
                    <label>Cancel</label>
                </button>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal" *ngIf="infoShareModal">
        <div class="modal-content details-modal">
            <em class="icon-close" (click)="hideInfoShareModal()"></em>
            <label class="details-title">Project Information</label>
            <label class="details-description">Information and data on the shared product system available for further
                project use</label>
            <label class="details-id">Project ID: {{selectedShared?.reflowId}}</label>
            <hr>
            <div class="row detail-row">
                <label class="detail-item">Company name:</label>
                <label class="detail-content">{{selectedShared?.company.companyName}}</label>
            </div>
            <div class="row detail-row">
                <label class="detail-item">Product name:</label>
                <label class="detail-content">{{selectedShared?.name}}</label>
            </div>
            <div class="row detail-row">
                <label class="detail-item">Type:</label>
                <label class="detail-content">{{selectedShared?.note}}</label>
            </div>
            <div class="row detail-row">
                <label class="detail-item">Weight:</label>
                <label class="detail-content">{{selectedShared?.weight}}</label>
            </div>
            <div class="row detail-row">
                <label class="detail-item">Total carbon footprint:</label>
                <label class="detail-content">{{selectedShared?.emission | number: '1.0-2' }}</label>
            </div>
            <hr>
            <img src="../../../assets/reflow-logo-svg.svg" alt="" class="reflow-logo" />
        </div>
    </div>
    <div id="myModal" class="modal" *ngIf="importModal">
        <div class="modal-content import-modal">
            <div class="modal-header">
                <em class="icon-close close-import" (click)="hideImportModal()"></em>
            </div>
            <label class="share-title import-title">Do you want to import this assessment?</label>
            <label class="share-description import-description">Please past the received calculation code below. When
                importing you will be able to see the calculation result and use the product in you own
                calculation</label>
            <input class="form-control import-input" [(ngModel)]="importLink" type="text"
                placeholder=" Paste your reflow code here">
            <div class="btn-div"><button class="big-blue-outline import-btn" (click)="importScreening()"><em
                        class="icon-download icon-import-btn"></em>&nbsp;Import</button></div>
        </div>
    </div>
    <div id="notAllowedModal" class="modal" *ngIf="notAllowedModal">
        <div class="modal-content">
            <div class="header"><em class="icon-close" style="color: #ffffff; margin-right: -4px; margin-top: -4px;"
                    (click)="hideNotAllowedModal()"></em></div>
            <label class="contact-label">Need additional calculations ?</label>
            <label class="share-description">Your calculation credits automatically replenish at the beginning of every
                month.</label>
            <label class="share-description">
                Should you need additional calculations, please contact us in the chat on this page or contact us at <a
                    href="https://www.re-flow.io/contact-us/">sales@re-flow.io</a>
            </label>
        </div>

    </div>
</div>
<div id="myModal" class="modal" *ngIf="shareModal">
    <div class="modal-content">
        <div class="header-modal">
            <em class="icon-close" style="color: #ffffff;right: 10px;top: 10px;" (click)="hideShareModal()"></em>
        </div>

        <label class="share-title">Do you want to share this assessment?</label>
        <label class="share-description">Please copy this code and share it with companies of your choosing</label>
        <div class="screenings link spacing">
            <label class="screening-name">{{sharingCode}}</label>
            <em class="fa fa-copy screening-copy" [cdkCopyToClipboard]="sharingCode" (click)="copyLink()"></em>
        </div>
        <label class="left-description">*The company will only see your result</label>
    </div>
</div>
<div id="myModal" class="modal" *ngIf="deleteShareModal">
    <div class="modal-content delete-modal">
        <em class="icon-close" (click)="hideDeleteShareModal()"></em>
        <label class="share-title">Do you want to delete this project?</label>
        <label class="share-description">Once deleted the shared project cannot be recovered.</label>
        <div class="row buttons">
            <button class="delete" (click)="deleteShared()">
                <em class="fa fa-trash"></em>
                <label>Delete</label>
            </button>
            <button class="cancel" (click)="hideDeleteShareModal()">
                <em class="fa fa-times"></em>
                <label>Cancel</label>
            </button>
        </div>
    </div>
</div>
<div id="myModal" class="modal" *ngIf="deleteModal">
    <div class="modal-content delete-modal">
        <em class="icon-close" (click)="hideDeleteModal()"></em>
        <label class="share-title delete-title">Do you want to delete this shared project?</label>
        <div class="delete-information-container">
            <p><strong><i class="icon-info"></i>Information</strong></p>
            <p class="delete-description">This will permanently delete your draft assessment and all data associated
                with it. Are you sure you want to proceed?</p>
        </div>
        <div class="row buttons">
            <button class="cancel back-delete-btn" (click)="hideDeleteModal()">
                <label class="back-delete-text">Back</label>
            </button>
            <button class="delete  delete-btn" (click)="deleteCompleted()">
                <em class="fa fa-trash"></em>
                <label class="back-delete-text">Delete</label>
            </button>
        </div>
    </div>
</div>
<div id="myModal" class="modal software-updates-modal" *ngIf="updatesModal == 'openmodal'">
    <div class="modal-content updates-modal">
        <label><img src="../../../assets/Asset 19.png" class="reflog"></label>
        <hr class="line-separation">
        <em class="icon-close" (click)="hideUpdatesModal()"></em>
        <div class="row">
            <div class="col-md-6 updates-first-column align-items-around">
                <label class="updates-date">{{latestUpdate?.updatedAt | date:'mediumDate'}}</label>
                <label class="latest">Latest</label>
                <label class="software-updates">software-update</label>
                <hr class="software-underline">
                <label class="updates-description">{{latestUpdate?.updateDescription}}</label>
                <div class="checkbox-container" (click)="toogleMessage()">
                    <div class="checkbox"> <em class="icon-close" [ngClass]="{'d-none': showAgain==false}"></em></div>
                    <p style="font-size: 1vw; margin-top: -10px;"> Don't show this message again</p>
                </div>
                <button class="su-close" (click)="hideUpdatesModal()">Close</button>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center">
                <img src="../../../assets/asset 2.0.png" class="computer-image">
            </div>
        </div>
    </div>
</div>
<div id="myModal" class="modal" *ngIf="stopShareModal">
    <div class="modal-content stop-modal">
        <em class="icon-close" (click)="hideStopShareModal()"></em>
        <label class="share-title">Do you want to stop sharing this project?</label>
        <label class="share-description">Once you stop sharing, the users you shared the product with will not be able
            to use it in their product inventory</label>
        <div class="row buttons">
            <button class="delete" (click)="stopSharing()">
                <em class="fa fa-trash"></em>
                <label>Stop Sharing</label>
            </button>
            <button class="cancel" (click)="hideStopShareModal()">
                <em class="fa fa-times"></em>
                <label>Cancel</label>
            </button>
        </div>
    </div>
</div>
<div id="myModal" class="modal" *ngIf="infoShareModal">
    <div class="modal-content details-modal">
        <em class="icon-close" (click)="hideInfoShareModal()"></em>
        <label class="details-title">Project Information</label>
        <label class="details-description">Information and data on the shared product system available for further
            project use</label>
        <label class="details-id">Project ID: {{selectedShared?.reflowId}}</label>
        <hr>
        <div class="row detail-row">
            <label class="detail-item">Company name:</label>
            <label class="detail-content">{{selectedShared?.company.companyName}}</label>
        </div>
        <div class="row detail-row">
            <label class="detail-item">Product name:</label>
            <label class="detail-content">{{selectedShared?.name}}</label>
        </div>
        <div class="row detail-row">
            <label class="detail-item">Type:</label>
            <label class="detail-content">{{selectedShared?.note}}</label>
        </div>
        <div class="row detail-row">
            <label class="detail-item">Weight:</label>
            <label class="detail-content">{{selectedShared?.weight}}</label>
        </div>
        <div class="row detail-row">
            <label class="detail-item">Total carbon footprint:</label>
            <label class="detail-content">{{selectedShared?.emission | number: '1.0-2' }}</label>
        </div>
        <hr>
        <img src="../../../assets/reflow-logo-svg.svg" alt="" class="reflow-logo" />
    </div>
</div>
<div id="myModal" class="modal" *ngIf="importModal">
    <div class="modal-content import-modal">
        <div class="modal-header">
            <em class="icon-close close-import" (click)="hideImportModal()"></em>
        </div>
        <label class="share-title import-title">Do you want to import this assessment?</label>
        <label class="share-description import-description">Please past the received calculation code below. When
            importing you will be able to see the calculation result and use the product in you own calculation</label>
        <input class="form-control import-input" [(ngModel)]="importLink" type="text"
            placeholder=" Paste your reflow code here">
        <div class="btn-div"><button class="big-blue-outline import-btn" (click)="importScreening()"><em
                    class="icon-download icon-import-btn"></em>&nbsp;Import</button></div>
    </div>
</div>
<div id="notAllowedModal" class="modal" *ngIf="notAllowedModal">
    <div class="modal-content">
        <div class="header"><em class="icon-close" style="color: #ffffff; margin-right: -4px; margin-top: -4px;"
                (click)="hideNotAllowedModal()"></em></div>
        <label class="contact-label">Need additional calculations ?</label>
        <label class="share-description">Your calculation credits automatically replenish at the beginning of every
            month.</label>
        <label class="share-description">
            Should you need additional calculations, please contact us in the chat on this page or contact us at <a
                href="https://www.re-flow.io/contact-us/">sales@re-flow.io</a>
        </label>
    </div>
</div>

<div id="archiveModal" class="modal" *ngIf="archiveModal">
    <div class="modal-content import-modal">
        <div class="modal-header" style="margin-top: -1px;
        width: 515px;
        margin-left: -1px;">
            <em class="icon-close close-import" (click)="hideArchiveModal()" style="margin-left: 460px;"></em>
        </div>
        <div class="image-container">
            <img src="../../../assets/archive-solid.png" class="archive-image" style="margin-top: 25px;"
                *ngIf="archiveOperation=='restore'">
            <img src="../../../assets/archive-solid1.png" class="archive-image" style="margin-top: 10px;"
                *ngIf="archiveOperation=='archive'">
        </div>
        <label class="share-title import-title archive-title" *ngIf="archiveOperation=='archive'">ARCHIVING</label>
        <label class="share-title import-title archive-title" *ngIf="archiveOperation=='restore'">RESTORING</label>
        <p class="sub-heading-text">Are you sure you want to {{archiveOperation}} this {{archivingTarget}}?</p>
        <label class="share-description import-description" *ngIf="archiveOperation=='archive'" style="margin-top: -12px;
        margin-bottom: 25px;">The content will be
            transferred to the archived folder. You can always restore it later.</label>
        <label class="share-description import-description" *ngIf="archiveOperation=='restore'" style="margin-top: -12px;
        margin-bottom: 25px;">The content will be
            restored to the original folder.</label>
        <div class="flex-container">
            <div class="btn-div"><button class="big-blue-outline  cancel no-margin"
                    (click)="hideArchiveModal()">Cancel</button></div>
            <div class="btn-div"><button class="big-blue-outline import-btn no-margin"
                    (click)="archiveModalAction()">{{archiveOperation[0].toUpperCase() +
                    archiveOperation.slice(1)}}</button></div>
        </div>
    </div>
</div>

<app-edit-folder-modal *ngIf="showEditFolderModal" [modalType]="modalType" [oldFolder]="targetFolder"
    [companyId]="companyId" (closeEditFolderModal)="closeEditFolderModal($event)"></app-edit-folder-modal>
<app-screening-modal *ngIf="false" (close)="closeModal($event)"></app-screening-modal>
<app-customer-contact (toggleChat)='toggleChat($event)' [chatStarted]='chatStarted'></app-customer-contact>
<app-compare-modal *ngIf="false" (close)="closeCompareModal($event)"></app-compare-modal>
<app-refill-modal *ngIf="canShowRefillModal" [isAdmin]="targetRefillAdmin" (close)="closeRefillModal($event)">
</app-refill-modal>
<app-terms-condition *ngIf="showTerms"></app-terms-condition>
<app-move-project *ngIf="showMoveProjectModal" [project]="selectedProject" [targetFolder]="selectedTargetFolder"
    (moveProjet)="moveProjet($event)"></app-move-project>