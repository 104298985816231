<div class="modals" [ngClass]="{'rename-modal': modalType=='rename-folder'}">
    <div class="main-modal-content upsert" *ngIf="modalType=='upsert-folder'">
        <div class="header">
            <em class="icon-close" (click)="closeModal()"></em>
            <div class="title-container">
                <img src="../../../../assets/new_folder@2x.png" alt="">
                <label for="" class="title">Create new folder</label>
            </div>
            <div class="form-container">
                <div class="form-group">
                    <label class="input-title">Enter folder name:</label>
                    <input type="text" class="form-control-c" placeholder="Folder name"
                    [(ngModel)]="floderName"
                    [ngClass]="{'dirty': floderName!=''}"
                    >
                </div>
            </div>
            <div class="form-container">
                <div class="form-group">
                    <label class="input-title">Place the folder in:</label>
                    <div class="input-container" [ngClass]="{'dirty-check': selectedFolderType=='Completed'}" (click)='selectFolder("Completed")'>
                        <div class="form-check-container">
                            <div class="form-control-check">
                                <img src="../../../../assets/check@2x.png" alt="" *ngIf="selectedFolderType=='Completed'">
                            </div>
                        </div>
                        <div class="input-label">
                            <img src="../../../../assets/create_new_folder.png" alt="">
                            <label for="" class="title">Completed</label>
                        </div>
                    </div>
                    <div class="input-container mt-2" [ngClass]="{'dirty-check': selectedFolderType=='Draft'}" (click)='selectFolder("Draft")'>
                        <div class="form-check-container">
                            <div class="form-control-check">
                                <img src="../../../../assets/check@2x.png" alt="" *ngIf="selectedFolderType=='Draft'">
                            </div>
                        </div>
                        <div class="input-label">
                            <img src="../../../../assets/create_new_folder.png" alt="">
                            <label for="" class="title">Draft</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="create" (click)="save()" [ngClass]="{'disabled': floderName==''||selectedFolderType==''}">Create</button>
            </div>
        </div>
    </div>
    <div class="main-modal-content rename" *ngIf="modalType=='rename-folder'">
        <div class="header">
            <em class="icon-close" (click)="closeModal()"></em>
        </div>
        <div class="body">
            <div class="title-container">
                <img src="../../../../assets/create_new_folder.png" alt="">
                <label for="" class="title">Rename folder</label>
            </div>
            <div class="form-group">
                <label class="input-title">Enter new folder name:</label>
                <input type="text" class="form-control" placeholder="Folder name" [(ngModel)]="floderName">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="rename rename-btn" (click)="save()">Rename</button>
            </div>
        </div>
    </div>
    <div class="main-modal-content rename" *ngIf="modalType=='sub-folder'">
        <div class="header">
            <em class="icon-close" (click)="closeModal()"></em>
        </div>
        <div class="body" *ngIf = "showSubfolderBody==false">
            <div class="title-container">
                <img src="../../../../assets/new_folder@2x.png" alt="">
                <label for="" class="title">Create Sub-folder</label>
            </div>
            <div class="form-group">
                <label class="input-title">Enter new sub-folder name:</label>
                <input type="text" class="form-control" placeholder="Sub-folder name" [(ngModel)]="floderName">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="rename rename-btn" (click)="save()">Create</button>
            </div>
        </div>
        <div class="body" *ngIf = "showSubfolderBody==true">
            <div class="title-container">
                <img src="../../../../assets/reflower_loading.gif" style="width: 363px;">
            </div>
        </div>
    </div>
</div>
<div class="overlay"></div>
