import { ContactType } from "./billing-contact.model";
import { User } from "./user.model";

export class Company {
  public constructor(init?: Partial<Company>) {
    Object.assign(this, init);
  }
  public id: number;
  public companyName: string;
  public vatNumber: string;
  public address: string;
  public zipCode: number;
  public city: string;
  public country: string;
  public billingEmail: string;
  public external: boolean;
  public companyType: string;
  public companyLogo: string;
  public reflowPoints: number;
  public validations: number;
  public engineeringHours: number;
  public calculationDone: number;
  public calculationProcessing: number;
  public sharedNumber: number;
  public importedNumber: number;
  public academyAccess: number;
  public screeningAccess: number;
  public compareAccess: number;
  public shareAccess: number;
  public lastCalculation: Date;
  public lastLogin: Date;
  public billingContact: ContactType;
  public companyContact: ContactType;
  public users: User[];
}
