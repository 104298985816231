import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getSalesOverview(): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/sales/companies`,
      this.helperService.headers()
    );
  }

  public updateSalesData(updateSalesDto:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/sales/updateData`,
      updateSalesDto,
      this.helperService.headers()
    );
  }

}
