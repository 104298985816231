<main>
	<section>
		<div class="rad-body-wrapper rad-nav-min">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-3 col-xs-6">
						<div class="rad-info-box rad-txt-success">
							<i class="fa fa-leaf"></i>
							<span class="heading">Saved CO2</span>
							<span class="value"><span>~{{calculated_co2_value | number: '1.0-2' }}</span></span>
						</div>
					</div>
					<div class="col-lg-3 col-xs-6">
						<div class="rad-info-box rad-txt-primary">
							<i class="fa fa-calculator"></i>
							<span class="heading">Number of calculations</span>
							<span class="value"><span>{{amoutOfCalculationDone}}</span></span>
						</div>
					</div>
					<div class="col-lg-3 col-xs-6">
						<div class="rad-info-box rad-txt-danger">
							<i class="fa fa-user"></i>
							<span class="heading">Number of users</span>
							<span class="value"><span>{{registred_user_value}}</span></span>
						</div>
					</div>
					<div class="col-lg-3 col-xs-6">
						<div class="rad-info-box">
							<i class="fa fa-building"></i>
							<span class="heading">Number of Companies</span>
							<span class="value"><span>{{companyNumber}}</span></span>
						</div>
					</div>
				</div>
                <div class="row">
                    <div class="col">
                        <p>Calculations in Last 10 days</p>
                        <canvas baseChart [datasets]="refillChartData" [labels]="amountChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas>
                    </div>
                    <div class="col">
                        <table class="re-table">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th id="">User Name</th>
                                    <th id="">Company</th>
                                    <th id="">Active since</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor= "let user of activeUsers">
                                    <td>{{user.fullName}}</td>
                                    <td>{{user.company}}</td>
                                    <td>{{user.lastLogin| date: 'dd/MM/yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
			</div>
		</div>
	</section>
</main>