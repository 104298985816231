<div class="row vid-play-container d-flex">
    <div class="video-main">
        <div class="back-container" (click)="goBackToOverview()">
            <em class="icon-back_arrow"></em>
            <label class="go-back-btn">back</label>
            
        </div>
        <div class="vid-content-container">
            <label class="big-title">Course: {{courseVideo.title}}</label><br>
            <label class="section-name">Section {{currentSectionId}}: {{currentSection}}</label>
            <video #target controls id="video">
                <source [src]="courseVideo.videoPath" type="video/mp4">
            </video>
            <label class="dec-lbl">Description:</label>
            <p class="description"> {{courseVideo.description}} </p>
        </div>
    </div>
    <div class="sections-container">
        <div class="title">Course Content</div>
        <div class="section-part">
            <div class="panel-group" id="accordion">
                <div class="panel panel-default" *ngFor="let section of courseVideo.sections; let i=index">
                    <div class="panel-heading" (click)="revertSelection(i);goToSection(section.time)">
                        <h4 class="panel-title" >
                            <a data-toggle="collapse" data-parent="#accordion" [href]="'#collaps'+i"  class="second-title"> 
                                <div class="left">
                                    <p>{{i+1}}. {{section?.title}}</p>
                                    <div class="duration">
                                        <i class="fa fa-play-circle"></i>
                                        <label class="time-label">{{section?.time}}</label>
                                    </div>
                                </div>
                                <div class="right">
                                    <i [ngClass]="{'icon-arrow-down': visibleDescriptions[i]==true,'icon-arrow-right': visibleDescriptions[i]!=true}" class="icon-arrow-right icon-arrow"></i>
                                </div>
                            </a>
                        </h4>
                    </div>
                    <div [id]="'collaps'+i" class="panel-collapse collapse in">
                        <div class="panel-body ">
                            <p class="desc-title">
                                Description:
                            </p>
                            <p class="desc-content">
                                {{section?.summary}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>