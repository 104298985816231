<div class="validation-container">
  <div class="header-container">
    <label class="title">Validation Dashboard</label>
    <div class="row filters d-flex p-relative">
      <div class="filter-btn d-flex" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <em class="icon-filter"></em>
        <label>Filter</label>
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </div>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
        style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
        <a class="dropdown-item" (click)="sort('status')">Status</a>
        <a class="dropdown-item" (click)="sort('projectName')">Project</a>
        <a class="dropdown-item" (click)="sort('userEmail')">Email</a>
        <a class="dropdown-item no-bb" (click)="sort('timeLeft')">Time left</a>
      </div>
      <div class="input-group search-bar">
        <span class="input-group-append">
          <button class="btn border-right-0 border left-bounded p-0" type="button">
            <em class="icon-search"></em>
          </button>
        </span>
        <input class="form-control py-2 border-left-0 border right-bounded" type="search" placeholder="Search"
          [(ngModel)]="keyword" (ngModelChange)="modelChanged($event)">
      </div>
    </div>
  </div>
  <div class="table-container">
    <div class="row table-data-item">
      <div style="width: 64px;padding:0px !important" class="head-item d-flex align-items-center">
        Status
      </div>
      <div class="data-row">
        <div class="head-item w27">Project</div>
        <div class="head-item w27">Company</div>
        <div class="head-item w27">Email</div>
        <div class="head-item w27">Responsible</div>
        <div class="head-item w19 self-align-end">Time left</div>
      </div>
      <div style="width: 45px;">
      </div>
    </div>
    <ng-container *ngFor="let request of requestList?.slice(x, y) | validationSearchFilter:keyword;let i = index">
      <div class="row table-data-item" [ngClass]="{'mt-1': i>0}">
        <div class="status" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <em *ngIf="request.status.toLowerCase()=='received'" class="icon-validation validation"
            style="font-size: 15px;"></em>
          <em *ngIf="request.status=='in progress'" class="icon-in-progress orange"></em>
          <em *ngIf="request.status=='corrected'" class="icon-edit purple"></em>
          <em *ngIf="request.status=='completed'" class="icon-validation-completed blue"></em>
          <em class="icon-arrow-down down"></em>
          <div class="dropdown-menu dd1" aria-labelledby="dropdownMenuButton1"
            style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform; width: 300px;">
            <a class="dropdown-item" (click)="updateStatus(request.id,'received')"><em
                class="icon-validation"></em><label>Validation Requested Recived</label></a>
            <a class="dropdown-item orange" (click)="updateStatus(request.id,'in progress')"><em
                class="icon-in-progress"></em><label>Validation in Progress</label></a>
            <a class="dropdown-item purple" (click)="updateStatus(request.id,'corrected')"><em
                class="icon-edit"></em><label>Validation Corrected</label></a>
            <a class="dropdown-item blue" (click)="updateStatus(request.id,'completed')"><em
                class="icon-validation-completed"></em><label>Validation Completed</label></a>
          </div>
        </div>
        <div class="data-row borders">
          <div class="row-item w27">{{request.projectName}}</div>
          <div class="row-item w27">{{request.companyName}}</div>
          <div class="row-item email w27">{{request.userEmail}}</div>
          <div class="row-item w27">{{request.responsible}}</div>
          <div class="row-item w19 self-align-end d-flex justify-content-between">{{request.timeLeft}}
            <em class="icon-flag red" *ngIf="request?.isUrgent" style="margin-right: 25px;"></em>
          </div>
        </div>
        <div class="options" ngbDropdown>
          <i class="fa fa-ellipsis-v" aria-hidden="true" id="dropdownBasic1" ngbDropdownToggle style="width: 100%; height: 100%; display:flex; align-items: center; justify-content: center; -webkit-appearance: none;
                -moz-appearance: none;"></i>
          <div class="dropdown-menu ddr" ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 300px;"
            placement="bottom-left" display="dynamic">
            <a class="dropdown-item" (click)="openProject(request.screeningId)"><em class="icon-project"></em><label>Go
                to Project</label></a>
            <a class="dropdown-item" (click)="showUploadModal(request.id,'correction sheet')"><em
                class="icon-edit"></em><label>Upload
                Correction Scheme</label></a>
            <a class="dropdown-item" (click)="showUploadModal(request.id,'validation certificate')"><em
                class="icon-upload"></em><label>Upload Certificate</label></a>
            <a class="dropdown-item red" (click)="flagRequest(request)"><em class="icon-flag"></em><label
                class="red">Flag as urgent</label></a>
            <a class="dropdown-item" (click)="getValidationHistory(request)"><em class="fa fa-history"></em><label>Show
                History</label></a>
            <a class="dropdown-item" (click)="showRequestModal(request.id)"><em class="icon-user" style="color:#123b63;"></em><label>Assign
                Request</label></a>
          </div>
        </div>

      </div>
    </ng-container>
    <div class="pagination" style="text-align:center;justify-content:center;">
      <a>&laquo;</a>
      <a (click)="changePaginationPrev()">Prev</a>
      <a>{{pageNumber}}</a>
      <a (click)="changepaginationValue()">next</a>
      <a>&raquo;</a>
    </div>
  </div>
</div>
<div id="openModal-about" class="modalDialog" *ngIf="canShowUploadModal">
  <div class="re-modal-content">
    <form>
      <div class="re-modal-header">
        <em class="icon-close close" (click)="closeModal()"></em>
      </div>
      <div class="content-modal">
        <div class="text-center">
          <label class="change-label"> Upload {{uploadOption}} </label>
        </div>
        <div class="text-center">
          <div class="uploadfilecontainer" (click)="fileInput.click()" (onFileDropped)="uploadFile($event)">
            <label class="drag-label">{{fileName}}</label>
            <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" />
          </div>
        </div>
        <div class="text-center">
          <button class="btn-solid default" style="float: left" (click)="reset()">
            Cancel
          </button>
          <button class="btn-solid secondary" style="float: right" (click)="saveCorrectionSheet()">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="canShowHistoryModal">
  <div class="re-modal-content1">
    <form>
      <div class="re-modal-header1">
        <em class="icon-close close" (click)="closeHistoryModal()"></em>
      </div>
      <div class="content-modal1">

        <div class="text-center">
          <div *ngFor="let notification of listofHistory">
            <div class="ddr-notification">
              <div class="dropdown-item">
                <div class="head">
                  <em *ngIf="notification.status=='received'" class="icon-validation validation"></em>
                  <em *ngIf="notification.status=='in progress'" class="icon-in-progress orange"></em>
                  <em *ngIf="notification.status=='corrected'" class="icon-edit purple"></em>
                  <em *ngIf="notification.status=='completed'" class="icon-validation-completed blue"></em>
                  <label>{{notification.updatedAt | date:'EEEE, MMMM d, y'}} </label>
                </div>
                <p>{{notification.updateDescription}}</p>
              </div>
            </div>


          </div>
        </div>
        <div class="text-center">
          <button *ngIf="listofHistory?.length>1" class="btn-solid default" (click)="closeHistoryModal()">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>


<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="showReqModal">
  <div class="re-modal-content2" style="height:250px;">
    <form>

      <div class="request-header">
        <label class="requestModalTitle">Change the responsible of the Validation Request</label>
        <em class="icon-close close" (click)="closeRequestModal()" style="margin:5px; color:#ffffff;"></em>
      </div>

      <div class="content-modal2">
        <div class="request-container">
          <div style="display:flex;flex-direction:row;justify-content:center;">
            <label class="email-label">Responsible's Email : </label>
            <input #responsibleEmail type="text" placeholder="Email" class="email-input">
          </div>
          <div>

          </div>
          <div class="twoButton" style="display:flex;flex-direction:row;justify-content: center;text-align: center;margin-top: 35px;">
            <button class="btn-solid default" (click)="closeRequestModal()">Close</button>
            <button class="btn-solid default" style="color:#000;background-color:#98D6D4 ;" (click)="assignResponsible(responsibleEmail)">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>