import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyFoldersService } from '../../../services/company-folders.service';

@Component({
  selector: 'app-move-project',
  templateUrl: './move-project.component.html',
  styleUrls: ['./move-project.component.scss']
})
export class MoveProjectComponent implements OnInit {

  @Output() moveProjet:any=new EventEmitter();
  @Input() targetFolder:any;
  @Input() project:any;

  public isMovingProject=false;

  constructor(
    private companyFoldersService:CompanyFoldersService,
    private toastrService:ToastrService,
  ) { }

  ngOnInit(): void {
  }

  public close(status){
    this.moveProjet.emit(status);
  }

  public moveToFolder(){
    this.isMovingProject=true;
    this.companyFoldersService.moveProjectToFolder({projectId:this.project.id,folderId:this.targetFolder.id}).subscribe(ans=>{
      this.toastrService.success("Project moved!")
      this.close(true);
      this.isMovingProject=false;
    },err=>{
      this.toastrService.error("Error occured!")
      this.close(false);
      this.isMovingProject=false;
    })
  }
}
