<div class="assessment-result-container">
    <div class="logo-container">
        <div class="logo"></div>
    </div>
    <div class="project-data-container">
        
        <div class="meta-data-container">
            <div class="md-item">
                <label class="mt-item-name">Project Date:</label>
                <label class="mt-item-value">{{currentAssessment?.sharingDate | date:'MMMM d, y'}}</label>
            </div>
            <div class="md-item">
                <label class="mt-item-name">Project ID:</label>
                <label class="mt-item-value">{{currentAssessment?.sharingCode}}</label>
            </div>
        </div>

        <div class="alert-container">
            <div class="title-container">
                <i class="fa fa-eye" aria-hidden="true"></i>
                <label class="title">Project preview - What has been shared with you:</label>
            </div>
            <p class="description">
                Below you can see a summary of the assessment project that has been shared with you. 
                The assessment results obtained in the shared project will be available for you to use in your own assessments.
            </p>
        </div>

        <div class="bloc">
            <label for="" class="big-title">{{currentAssessment?.productName}}</label>
            <p class="description">
                The assessment results in the shared project relate to this specific product. The product’s function, its manufacturer and technical specifications are described below and provide the basis for this assessment.
            </p>
        </div>
        <div class="bloc">
            <div class="bloc-item">
                <label class="item-name">Manufacturer Name:</label>
                <label class="item-value">{{currentAssessment?.manufacturerName}}</label>
            </div>
        </div>
        <div class="bloc">
            <label for="" class="big-title">Specifications</label>
            <div class="bloc-item mt-2">
                <label class="item-name">Product Name:</label>
                <label class="item-value blue">{{currentAssessment?.productName}}</label>
            </div>
            <div class="bloc-item mt-2">
                <label class="item-name">Product Type:</label>
                <label class="item-value">{{currentAssessment?.productType}}</label>
            </div>
            <div class="bloc-item bi-row mt-2">
                <label class="item-name">Weight:</label>
                <label class="item-value" style="margin: 0px 0px 0px 15px">{{currentAssessment?.weight}} kg</label>
            </div>
            <div class="bloc-item bi-row mt-2">
                <label class="item-name">Lifespan:</label>
                <div class="composed ml-3">
                    <label class="item-value" style="margin: 0px">{{currentAssessment?.lifeSpan}} years</label>
                    <label class="alt">(Initial)</label>
                </div>
                <label class="item-value" style="margin: 0px 10px 0px 10px">+</label>
                <div class="composed">
                    <label class="item-value" style="margin: 0px">{{currentAssessment?.extendedLifeSpan}} years</label>
                    <label class="alt">(Extended)</label>
                </div>
            </div>
        </div>
        <div class="bloc">
            <div class="bloc-item mt-1">
                <label class="item-name">Function:</label>
                <label class="item-value">{{currentAssessment?.function}}</label>
            </div>
        </div>
        <div class="bloc">
            <label for="" class="big-title">{{currentAssessment?.productName}}</label>
            <div class="bloc-item bi-row mt-2">
                <label class="item-name">Total carbon footprint</label>
                <p class="description" style="margin: 0px 0px 0px 10px">of the modeled scenario.</p>
            </div>
            <div class="bloc-item bi-row mt-2">
                <label class="item-name blue">{{currentAssessment?.totalCarbonfootprint | number : '1.2-2'}}</label>
                <p class="description" style="margin: 0px 0px 0px 10px">kg CO<sub>2</sub> eq</p>
            </div>
        </div>
        <label for="" class="big-title mt-4">Assessment results</label>
        <div class="alert-container" style="margin-top: 10px">
            <div class="title-container">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <label class="title">The following life cycle stages are included in the shared assessment results.</label>
            </div>
            <p class="description">
                Below you can see a summary of the assessment project that has been shared with you. 
                The assessment results obtained in the shared project will be available for you to use in your own assessments.The life cycle stages to be shared are chosen by the manufacturer and may not represent the whole life cycle of the product.
            </p>
        </div>
        <div class="bloc">
            <div class="bloc-item mt-3" *ngFor="let lc of currentAssessment?.sharedLifeCycles">
                <label class="item-name" style="font-weight:normal ;">{{lc.cycleName}}:</label>
                <div class="inline mt-1">
                    <label class="item-name blue" style="font-weight:normal">{{lc.impact  | number : '1.2-2'}}</label>
                    <p class="description" style="margin: 0px 0px 0px 10px">kg CO<sub>2</sub> eq</p>
                </div>
            </div>
        </div>
        <div class="meta-data-container mt-2">
            <div class="md-item">
                <label class="mt-item-name" style="font-weight: bold;">Ecoinvent Database:</label>
                <label class="mt-item-value">v{{currentAssessment?.inventoryVersion}}</label>
            </div>
            <div class="md-item">
                <label class="mt-item-name" style="font-weight: bold;">Date of Inventory Data:</label>
                <label class="mt-item-value">{{currentAssessment?.inventoryDate | date:'MMMM d, y'}}</label>
            </div>
        </div>
    </div>

    <div class="footer mt-3">
        <p>ReFlow ApS, Bryggervangen 55, 2100 Copenhagen , Denmark · CVR: 39843870 </p>
        <p>info@re-flow.io · +45 3274 5300 · www.re-flow.io</p>
    </div>
</div>