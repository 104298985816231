import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class AcademyService {
  environmentUrl = "Debug api";

  constructor(private http: HttpClient,private helperService:HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public uploadFile(file: File): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.environmentUrl}/api/academy/upload`, formData);
  }

  public addVideo(createVideoDto: any): any {
   
    return this.http.post<any>(
      `${this.environmentUrl}/api/academy/newVideo`,
      createVideoDto,
      this.helperService.headers()
    );
  }

  public getAcademyVideos(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/academy/videos`, this.helperService.headers());
  }
}
