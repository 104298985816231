<div class="search-container" id="dd-dismiss">
    <div class="left-icon">
        <em class="icon-search"></em>
    </div>
    <div class="search-text">
        <input type="text" class="input-search-text" placeholder="Choose year of calculation" [(ngModel)]="searchText" (click)="ddTriggered=true">
    </div>
    <div class="right-icon" (click)="ddTriggered=!ddTriggered">
        <em class="icon-arrow-down" *ngIf="ddTriggered==false"></em>
        <em class="icon-arrow-up" *ngIf="ddTriggered==true"></em>
    </div>
</div>

<div class="drop-down-container" *ngIf="ddTriggered==true">
    <div class="years-list-contianer">
        <ng-container *ngFor="let item of years; let i=index">
            <div class="y-dropdown-item"  [ngClass]="{'border-top': i>0}" (click)="selectYear(item)">
                <label class="y-item-label" for="">{{item}}</label>
                <div class="select-mark" *ngIf="selectedYear==item">
                    <img class="img" src="../../../../../assets/checkmark-white.svg" alt="">
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="monthly-calculation" *ngIf="false" (click)="includesMonthlyCalculation=!includesMonthlyCalculation">
    <p>You can include monthly calculation</p>
    <div class="check-box">
        <img src="../../../../../assets/checkmark-black.svg" alt="" *ngIf="includesMonthlyCalculation==true">
    </div>
</div>

<div class="actions-container">
    <button class="back" routerLink="..">Back</button>
    <button class="continue" (click)="goToNextStep()">Continue</button>
</div>