import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Company } from "../models/company.model";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class ExternalCompanyService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getExternalCompany(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/companies/external`, this.helperService.headers());
  }

  public deleteCustomerCompany(id: number) {
    return this.http.delete(`${this.environmentUrl}/api/companies/` + id, this.helperService.headers());
  }

  public addCompany(company: Company): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/companies`,
      company,
      this.helperService.headers()
    );
  }

  public editExternalCompany(company) {
    return this.http.put<any>(
      `${this.environmentUrl}/api/companies/` + company.id,
      company,
      this.helperService.headers()
    );
  }
}
