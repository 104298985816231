<div class="split right">
  <div class="reflow-form-tabs">
    <div class="form-tabs-header">
      <div class="row">
        <div class="col-sm-4">
          <img src="../../../assets/status_white.png" alt="" class="update-status-icon"
            (click)="hideDetailsShowStatus()" />
          <img src="../../../assets/printer_white.png" alt="" class="printer-icon" (click)="previewPdf()" />
        </div>
        <div class="col-sm-4" style="text-align: center; margin-top: 10px;">
          <img [src]="currentComponent?.componentPicture " alt="" class="component-image" *ngIf="currentComponent!=null"
            (click)="showPictureModal(true)" />
          <label class="user-name">{{
            currentComponent?.supplierReference
          }}</label>
        </div>
        <div class="col-sm-4">
          <em class="fa fa-times" style="margin-top: 10px; margin-right: 25px; float: right;"
            (click)="hideModal()"></em>
        </div>
      </div>
      <div class="row tabs-menu">
        <div [class]="
            showDetails ? 'col tabs-menu-item-selected' : 'col tabs-menu-item'
          " (click)="resetTabs(); showDetails = true">
          <label class="item-content">Details</label>
        </div>
        <div [class]="
            showLog ? 'col tabs-menu-item-selected' : 'col tabs-menu-item'
          " (click)="resetTabs(); showLog = true">
          <label class="item-content">Log</label>
        </div>
        <div [class]="
            showFiles ? 'col tabs-menu-item-selected' : 'col tabs-menu-item'
          " (click)="resetTabs(); showFiles = true">
          <label class="item-content">Flow Files</label>
        </div>
      </div>
    </div>
    <div class="form-tabs-content">
      <app-component-edit-core (changeSupplierRef)="changeSuppRef($event)" (closeModalEdit)="closeModalEdit($event)"
        [componentId]="componentId" *ngIf="showDetails"></app-component-edit-core>
      <app-component-log [currentComponentId]="componentId" *ngIf="showLog"></app-component-log>
      <app-component-file-log *ngIf="showFiles" [currentComponentId]="componentId">
      </app-component-file-log>
    </div>
  </div>
</div>
<app-component-picture (toogleModal)="showPictureModal($event)" [srcImg]="srcImg" [showModal]="showPictureView"
  *ngIf="showPictureView">
</app-component-picture>