<div id="openModal-about" class="modalDialog">
  <div class="re-modal-content" *ngIf="isImage">
    <div class="left">
      <img src="{{ file.fileUrl }}" alt="" />
    </div>
    <div class="right">
      <em class="fa fa-times close-button" (click)="closeModal()"></em>
      <div class="centered-right">
        <em class="fa fa-picture-o"></em>
        <label class="note-title-image">Image with comment</label>
        <label class="note-comment-image">
          {{ file.comment }}
        </label>
      </div>
    </div>
  </div>
  <div class="re-modal-file" *ngIf="!isImage">
    <div class="container">
      <em class="fa fa-times close-button" (click)="closeModal()"></em>
      <em class="fa fa-file-text-o"></em>
      <label class="note-title">{{ file.fileName }}</label>
      <label class="note-comment">
        {{ file.comment }}
      </label>
    </div>
    <hr />
    <label class="section-title">attatchements</label>
    <button
      *ngIf="file.fileUrl != ''"
      class="uploaded-file"
      (click)="showFile(file.fileUrl)"
    >
      <em class="fa fa-cog"></em>{{ file.fileName }}
    </button>
  </div>
</div>
