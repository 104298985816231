<div class="modalDialog">
    <div class="re-modal-content">
        <div class="title-container">
            <label for="">{{actionType}} {{actionOn}}</label>
        </div>
        <div class="form-item">
            <label for="Company name" class="form-item-label">{{actionOn}} name</label>
            <div class="form-control-container">
                <input type="text" placeholder="Environment and Climate" [(ngModel)]="questChildName">
            </div>
        </div>
        <div class="actions-container">
            <button class="cancel" (click)="cancel()">CANCEL</button>
            <button class="add-company" (click)="upsert()">{{actionType}}</button>
        </div>
    </div>
</div>