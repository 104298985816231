import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from "src/app/services/company.service";
@Component({
  selector: 'app-update-credit',
  templateUrl: './update-credit.component.html',
  styleUrls: ['./update-credit.component.scss']
})
export class UpdateCreditComponent implements OnInit {
  @Output() creditModalEvent =new EventEmitter<any>();
  @Input()  company_id: string;
  @Input()  initialCredit: string;
  public data :any;
  newCredit: any;
  public showCreditModal = true;
  constructor(private companyService: CompanyService, private toastrService:ToastrService) { }

  ngOnInit(): void {
  }

  public close():void{
    this.creditModalEvent.emit("close-credit-modal");
  }

  public updateCredit(credit){
    this.data = {'companyId' : Number(this.company_id) , 'credit_to_refill' : Number(credit)};
    this.companyService.refillCredits(this.data).subscribe((res) => {
      this.toastrService.success(credit+" added to the company's current credits","Operation accomplished");
      this.close();
    });
  }

}
