import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { LoginForm } from "../models/login-form";
import { error } from "@angular/compiler/src/util";
import { User } from "../models/user.model";
import { ResetPassword } from "../models/reset-password.model";
import { AuthService } from '@auth0/auth0-angular';
import { switchMap } from 'rxjs/operators';
import {
  SignUpDto,
  ProfilPictureDto,
  verificationCodeDto,
} from "../models/dtos.model";
import { HelperService } from "./helper.service";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  environmentUrl = "Debug api";
  private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private  handler: HttpBackend,
    private helperService:HelperService,
    private auth: AuthService
    ) {
    this.environmentUrl = environment.apiUrl;
    this.httpClient = new HttpClient(handler);
  }

  public loginUser(loginForm: LoginForm) {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/login`,
      loginForm
    );
  }

  public logoutUser(userId:any) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/user/logout/`+userId,
      this.helperService.headers()
    );
  }

  public addCompanyUser(user: User): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/createUser`,
      user,
      this.helperService.headers()
    );
  }

  public signupUser(signupDto: SignUpDto): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/signupUser`,
      signupDto
    );
  }

  public getUserById(userId: number): any {
    return this.http.get<any>(`${this.environmentUrl}/api/user/` + userId, this.helperService.currentHeaders());
  }

  public _getCurrentUser(): any {
    return this.httpClient.get<any>(`${this.environmentUrl}/api/user/currentUser`, this.helperService.currentHeaders());
  }
  getCurrentUser(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    return this.http.get(`${this.environmentUrl}/api/user/currentUser`, { headers });
  
  }

  public acceptTerms(userId: number): any {
    return this.http.get<any>(`${this.environmentUrl}/api/user/acceptTerms/` + userId, this.helperService.headers());
  }

  public checkAcceptTerms(userId: number): any {
    return this.http.get<any>(`${this.environmentUrl}/api/user/checkAcceptTerms/` + userId, this.helperService.headers());
  }

  public verifyCode(verificationCodeDto: verificationCodeDto, loginResult): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/verifyCode`,
      verificationCodeDto,
      { 
        headers:  new HttpHeaders({
            Authorization: "Bearer " + loginResult.token,
            "Content-Type": "application/json",
          })
      }
    );
  }

  public updateUserPassword(userId: number, password: ResetPassword): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/updatePassword/`,
      password,
      { 
        headers:  new HttpHeaders({
            Authorization: "Bearer " + localStorage.getItem("resetToken"),
            "Content-Type": "application/json",
          })
      }
    );
  }

  public changePassword(userId: number, password: ResetPassword): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/changePassword`,
      password,
      this.helperService.headers()
    );
  }

  public deleteUser(userId: number) {
    return this.http.delete(`${this.environmentUrl}/api/user/` + userId, this.helperService.headers());
  }

  public editUser(user: User) {
    return this.http.put<any>(
      `${this.environmentUrl}/api/user/` + user.id,
      user,this.helperService.headers()
    );
  }

  public emailExists(email: string): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/user/emailExists/` + email
    );
  }

  public updatePicture(profilPictureDto: ProfilPictureDto): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/updateProfilPhoto`,
      profilPictureDto,this.helperService.headers()
    );
  }

  public chargebeeSignupUser(user): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/signupChargebeeUser`,
      user,{headers: headers}
    );
  }

  public reactOnTips(reaction:any){
    return this.http.post<any>(
      `${this.environmentUrl}/api/tipsReactions/react_on_tip` , reaction, this.helperService.headers()
    );
  }

  public userReactions(userId: number, porjectId: number) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/tipsReactions/user_tips/` + userId+'/'+porjectId, this.helperService.headers()
    );
  }

}
