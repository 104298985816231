<div class="split left">
  <nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="margin-left: 20px;" (click)="goBack()">
        <em class="glyphicon fa fa-angle-left" style="color: #97d6d4; margin-top: 12px; margin-right: 11px;"></em>
        <label class="clicked-content-title">Components</label>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <div class="row float-right">
          <div class="inner-addon right-addon">
            <em class="glyphicon fa fa-search"></em>
            <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
              name="searchTerm" />
          </div>
          <div class="col-md-4">
            <button class="btn-solid primary" id="addBtn">
              <em class="fa fa-plus"></em>Add Component
            </button>
          </div>
        </div>
      </form>
    </div>
  </nav>
  <div class="row">
    <table class="re-table">
      <caption></caption>
      <thead>
        <tr>
          <th id="" (click)="orderBy('reference', '')">
            <label style="margin-left: 10px;">VO reference</label>
          </th>
          <th id="" (click)="orderBy('owner', '')">Location</th>
          <th id="" (click)="orderBy('type', '')">Type</th>
          <th id="" (click)="orderBy('description', '')">Description</th>
          <th id="" (click)="orderBy('status', '')">Status</th>
          <th id="" (click)="orderBy('tte', '')">TtE</th>
          <th id="" (click)="orderBy('coSaving', '')">Total kg CO2e savings</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="
            let component of componentList
              | paginate: { itemsPerPage: 10, currentPage: p }
              | filterPipe: searchTerm
              | sort: fieldName:attr
          " (click)="selectComponent($event, true, component)">
          <td style="font-weight: bold;">
            <img class="left-image" src="{{ component.componentPicture }}" alt="component Picture" />
            {{ component.reference }}
          </td>
          <td>
            <img class="owner-image" src="{{ component.ownerLogoUrl }}" alt="component Picture" />
            {{ component.owner }}
          </td>
          <td>{{ component.type }}</td>
          <td>{{ component.description }}</td>
          <td>
            <em class="icon-servicing status" *ngIf="component.status == 'Servicing'"></em>
            <em class="icon-in-warehouse status" *ngIf="component.status == 'In supplier warehouse'"></em>
            <em class="icon-transit status" *ngIf="component.status == 'Transit to vessel operator'"></em>
            <em class="icon-in-warehouse status" *ngIf="component.status == 'In Vessel operator warehouse'"></em>
            <em class="icon-received-on-vessel status" *ngIf="component.status == 'Received on vessel'"></em>
            <em class="icon-stocked-on-vessel status" *ngIf="component.status == 'Stocked on vessel'"></em>
            <em class="icon-in-use status" *ngIf="component.status == 'In use'"></em>
            <em class="icon-servicing status" *ngIf="component.status == 'Returned for service'"></em>
            <label class="label-status">{{ component.status }}</label>
          </td>
          <td>
            <label class="tte-label" *ngIf="component.status != 'In use'">
              <span> N/A </span>
            </label>
            <label class="tte-label" *ngIf="component.status == 'In use'">
              <span class="dot-red" *ngIf="25 > component.tte"></span>
              <span class="dot-yellow" *ngIf="component.tte > 25 && 75 > component.tte"></span>
              <span class="dot-green" *ngIf="component.tte > 75"></span>
              {{ component.tte }} days
            </label>
          </td>
          <td class="co-label">{{ component.coSaving }}</td>
          <td>
            <em class="icon-status" (click)="toogleModal(true, component)"></em>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div>
<div class="split right">
  <app-vo-update-status *ngIf="showStatus" (closeModal)="toogleModal($event, null)" [componentId]="componentId">
  </app-vo-update-status>
  <app-component-details *ngIf="showDetailComponent" [componentId]="componentId"
    (closeModal)="selectComponent(null, $event, null)"></app-component-details>
</div>
<div class="split right" *ngIf="!showStatus && !showDetailComponent">
  <app-location-details></app-location-details>
</div>
