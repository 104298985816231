<app-light-calculator
  (submitForm)="getCO2Form($event)"
  (submitAttemp)="getCO2Error($event)"
  *ngIf="co2Form == null && co2Error == false"
></app-light-calculator>
<app-light-result
  [co2Form]="co2Form"
  *ngIf="co2Form != null && co2Error == false"
></app-light-result>
<app-light-error *ngIf="co2Error == true"></app-light-error>
<app-terms-condition *ngIf="!showTerms"></app-terms-condition>