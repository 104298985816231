import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss']
})
export class TransferModalComponent implements OnInit {
  @Output() transferModalEvent = new EventEmitter<any>();
  @Input()  companyId: string;
  public showTransferModal = true;
  constructor(private companyService: CompanyService, private toastrService:ToastrService) { }

  ngOnInit(): void {
  }

  public close():void{
    this.transferModalEvent.emit("close-credit-modal");
  }

  public save(screeningToTransfer:any):void{
    this.companyService.transferAssessment({companyId:this.companyId,screeningId:Number(screeningToTransfer)}).subscribe(res=>{
      this.toastrService.success("Assessment has been transfered succefully","Operation accomplished");
      this.transferModalEvent.emit("close-credit-modal");
    });
  }

}
