import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyAssessmentService } from '../../../../services/company-assessment.service';
import { ActivatedRoute } from '@angular/router';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  @Output() nextStep=new EventEmitter();
  public companyAssessmentId:any=null;
  public locationName="";
  public countryName="";
  public companyName="";
  public currentCompanyProfile=null;
  public locationTags:any=[];
  public companyTypes:any=["Services","Retail","Wholesale","Agriculture","Livestock","Construction","Mining","Energy","Financial","Technology","Tourism & Hospitality","Transportation & Logistics","Education","Healthcare","Entertainment & Media","Real Estate","Telecommunications","Aerospace & Defense","Automotive","Chemicals","Food & Beverages","Pharmaceuticals","Biotechnology","Textiles","Publishing","Environmental Services","Non-Profit","Research & Development","Consulting","Other"];
  public companyIndustries:any=["Energy and Utilities","Manufacturing","Transportation and Logistics","Agriculture and Agribusiness","Oil and Gas","Chemicals and Petrochemicals","Construction and Building","Retail and Consumer Goods","Technology and IT Services","Financial Services","Healthcare and Pharmaceuticals","Hospitality and Tourism","Telecommunications","Mining and Extraction","Waste Management and Recycling","Forestry and Timber","Food and Beverage","Textiles and Apparel","Real Estate and Property Management","Automotive","Aerospace and Defense"];
  public companyActivity:any=["Production","Services","Consulting","Trade - Wholesale / Retail","Agriculture","Construction","Technology","Healthcare","Education","Entertainment","Transportation","Finance","Real Estate","Tourism & Hospitality","Research & Development","Others"];
  public companyProfile:FormGroup;
  public selectedCountry="";
  public selectedCompanyType="";
  public selectedIndustry="";
  public selectedActivity="";
  public toggleCountryselection=false;
  public toggleCopmanyTypeselection=false;
  public toggleIndustrySelection=false;
  public toggleActivitySelection=false;
  public countryList:[];
  public companyNameLength: string = '';
  public showCompanyNameError = false;

  public file;
  public filename="";
  public fileUrl:any ="";

  constructor(
    private fb:FormBuilder,
    private companyAssessmentService:CompanyAssessmentService,
    private route: ActivatedRoute,
    private uploadService: FileUploadService
    ) {
      this.companyProfile= this.fb.group({
        companyAssessmentId:this.companyAssessmentId,
        companyAssessmentProfilId:0,
        companyName:"",
        companyCountry:"",
        companyLogo:"",
        companyLogoName:"",
        employeesNumber:"",
        companyType:"abc",
        industry:"xyz",
        activity:"def",
        siteLocationName:"",
        siteCountryName:"",
      });
    }

  ngOnInit(): void {
    this.companyAssessmentId = this.route.snapshot.paramMap.get('companyAssesssmentId');
    this.companyAssessmentService.getCompanyPofilByAssessmentId(this.companyAssessmentId).subscribe(ans=>{
      this.currentCompanyProfile=ans;

      this.companyProfile= this.fb.group({
        companyAssessmentId:[this.route.snapshot.paramMap.get('companyAssesssmentId')],
        companyAssessmentProfilId:[this.currentCompanyProfile!=null?this.currentCompanyProfile['id']:0],
        companyName:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyName']:""],
        companyCountry:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyCountry']:""],
        employeesNumber:[this.currentCompanyProfile!=null?this.currentCompanyProfile['employeesNumber']:""],
        companyType:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyType']:""],
        industry:[this.currentCompanyProfile!=null?this.currentCompanyProfile['industry']:""],
        activity:[this.currentCompanyProfile!=null?this.currentCompanyProfile['activity']:""],
        companyLogo:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogo']:""],
        companyLogoName:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogoName']:""],
        siteLocationName:"",
        siteCountryName:"",
      });
      this.fileUrl=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogo']:"";
      this.filename=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogoName']:"";
      this.selectedCountry=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyCountry']:"";
      this.selectedCompanyType=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyType']:"";
      this.selectedIndustry=this.currentCompanyProfile!=null?this.currentCompanyProfile['industry']:"";
      this.selectedActivity=this.currentCompanyProfile!=null?this.currentCompanyProfile['activity']:"";
    },err=>{
      this.companyProfile= this.fb.group({
        companyAssessmentId:[this.route.snapshot.paramMap.get('companyAssesssmentId')],
        companyAssessmentProfilId:[this.currentCompanyProfile!=null?this.currentCompanyProfile['id']:0],
        companyName:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyName']:""],
        companyCountry:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyCountry']:""],
        employeesNumber:[this.currentCompanyProfile!=null?this.currentCompanyProfile['employeesNumber']:""],
        companyType:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyType']:""],
        industry:[this.currentCompanyProfile!=null?this.currentCompanyProfile['industry']:""],
        activity:[this.currentCompanyProfile!=null?this.currentCompanyProfile['activity']:""],
        companyLogo:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogo']:""],
        companyLogoName:[this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogoName']:""],
        siteLocationName:"",
        siteCountryName:"",
      });
      
      this.fileUrl=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogo']:"";
      this.filename=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyLogoName']:"";
      this.selectedCountry=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyCountry']:"";
      this.selectedCompanyType=this.currentCompanyProfile!=null?this.currentCompanyProfile['companyType']:"";
      this.selectedIndustry=this.currentCompanyProfile!=null?this.currentCompanyProfile['industry']:"";
      this.selectedActivity=this.currentCompanyProfile!=null?this.currentCompanyProfile['activity']:"";
    });
    this.companyAssessmentService.getCountries().subscribe(countries=>{
      this.countryList=countries;
    });

  }

  public onFilechange(event: any) {
    this.file = event.target.files[0];
    this.filename=this.file.name;
    this.companyProfile.controls['companyLogoName'].setValue(this.file.name);
    this.uploadService.uploadFile(event.target.files[0], 0).subscribe((res) => {
      this.fileUrl = res.fileName;
      this.companyProfile.controls['companyLogo'].setValue(this.fileUrl);
    });
  }

  public getcurrentCompanyProfilePtofile(){

  }

  public removeLocation(location){
    this.locationTags=this.locationTags.filter(x=>x!=location);
  }

  public addLocation(){
    if(this.companyProfile.controls['siteLocationName'].value==""||this.companyProfile.controls['siteCountryName'].value=="")return;
    else{
      this.locationTags.unshift({location:this.companyProfile.controls['siteCountryName'].value,country:this.companyProfile.controls['siteLocationName'].value})
      this.companyProfile.get("siteLocationName")?.setValue("");
      this.companyProfile.get("siteCountryName")?.setValue("");
    }
  }

  public goToNextStep(next){
    if(next=="next"){
      let updateCompanyProfileDto=this.companyProfile.value;
      delete updateCompanyProfileDto.siteLocationName;
      delete updateCompanyProfileDto.siteCountryName;
      updateCompanyProfileDto.companySiteDtos=this.locationTags;

      this.companyAssessmentService.updateCompanyProfile(updateCompanyProfileDto).subscribe(ans=>{
        this.nextStep.emit({step:next,data:this.companyAssessmentId});
      },err=>{
        this.nextStep.emit({step:next,data:this.companyAssessmentId});
      })
    }else{
      this.nextStep.emit({step:next,data:this.companyAssessmentId});
    }
  }

  public selectCountry(item){
    this.selectedCountry=item;
    this.toggleCountryselection=false;
    this.companyProfile.controls['companyCountry'].setValue(item);
  }

  public selectCompanyType(item){
    this.selectedCompanyType=item;
    this.toggleCopmanyTypeselection=false;
    this.companyProfile.controls['companyType'].setValue(item);
  }
  public selectIndustry(item){
    this.selectedIndustry=item;
    this.toggleIndustrySelection=false;
    this.companyProfile.controls['industry'].setValue(item);
  }
  public selectActivity(item){
    this.selectedActivity=item;
    this.toggleActivitySelection=false;
    this.companyProfile.controls['activity'].setValue(item);
  }

  onInputChange(value: string){   
      this.showCompanyNameError = value.length >= 30; 
  }
}
