import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "./helper.service";
import { HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CompanyAssessmentDashboardService {

  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getCompanyAssessmentsstats(companyId,dashboardDataDto): any {
    return this.http.post<any>(`${this.environmentUrl}/api/company-assessment/dashboard/charts/${companyId}`,dashboardDataDto, this.helperService.headers());
  }

  public getActiveCompanyAssessmentsByCompanyId(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment/dashboard/assessments/${id}/active`, this.helperService.headers());
  }

  public getArchivedCompanyAssessmentsByCompanyId(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment/dashboard/assessments/${id}/archived`, this.helperService.headers());
  }

  public archiveCompanyAssessment(id): any {
    return this.http.put<any>(`${this.environmentUrl}/api/company-assessment/dashboard/archive/${id}`,{}, this.helperService.headers());
  }

  public activateCompanyAssessment(id): any {
    return this.http.put<any>(`${this.environmentUrl}/api/company-assessment/dashboard/activate/${id}`,{}, this.helperService.headers());
  }

}
