import { Component, OnInit } from '@angular/core';
import { Warehouse } from 'src/app/models/warehouse';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-warehouse-overview',
  templateUrl: './warehouse-overview.component.html',
  styleUrls: ['./warehouse-overview.component.css']
})
export class WarehouseOverviewComponent implements OnInit {

  public warehouses: Array<Warehouse>;
  public showEditView: boolean = false;
  public showWarehouseDetails: boolean = false;
  public selectedWarehouse: Warehouse;
  private routeSub: Subscription;
  public companyId: number;
  public searchTerm: string = "";
  public fieldName: string;
  public attr: string
  constructor(
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.parent.params.subscribe(params => {
      this.companyId = Number(params['companyId']);
      this.retrieveWarehouses(this.companyId);
    });

  }

  public retrieveWarehouses(id) {
    this.warehouseService.retrieveWarehouses(id).subscribe((res) => {
      this.warehouses = res;
    });
  }

  public showEditModal(isShow: boolean) {
    this.showEditView = isShow;
    this.showWarehouseDetails = false;
    this.retrieveWarehouses(this.companyId);
  }

  public showDetailsModal() {
    this.showWarehouseDetails = true;
    this.showEditView = false;
  }

  public selectWarehouse(warehouse: Warehouse): void {
    this.selectedWarehouse = warehouse;
    this.showEditModal(true);
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
