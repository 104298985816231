<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <label class="form-title">Add Component</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="componentToEdit" autocomplete="off">
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="owner" [(ngModel)]="txtowner" placeholder="Owner"
            id="dropowners" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="dropowners">
            <button *ngFor="let owner of owners | filterPipe: txtowner" class="dropdown-item" type="button"
              (click)="selectOwner(owner)">
              <img class="drop-down-image" src="{{ owner.companyLogo }}" alt="" />
              <label class="drop-down-label">{{ owner.companyName }}</label>
            </button>
          </div>
          <label class="float-label">Owner<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.owner?.errors?.required">
          Owner is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="manufacturer" [(ngModel)]="txtmanufacturter"
            placeholder="Manufacturer" id="dropmanufacturer" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="dropowners">
            <button *ngFor="let supplier of suppliers | filterPipe: txtmanufacturter" class="dropdown-item"
              type="button" (click)="selectManufacturer(supplier)">
              <img class="drop-down-image" src="{{ supplier.companyLogo }}" alt="" />
              <label class="drop-down-label">{{ supplier.companyName }}</label>
            </button>
          </div>
          <label class="float-label">Manufacturer<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.manufacturer.errors?.required">
          Manufacturer is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" [(ngModel)]="txttype" formControlName="type" placeholder="Type"
            id="droptypes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="droptypes">
            <button *ngFor="let type of typeList | filterPipe: txttype" (click)="selectType(type)"
              class="dropdown-item">
              {{ type }}
            </button>
          </div>
          <label class="float-label">Type<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.type.errors?.required">
          Type is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="number" formControlName="weight" placeholder="weight" />
          <label class="float-label">weight in KG</label>
        </div>
        <small *ngIf="submitted && frm.specification.errors?.required">
          Specification is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="specification" placeholder="Specification" />
          <label class="float-label">Specification<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.specification.errors?.required">
          Specification is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="serialNumber" placeholder="Serial number" />
          <label class="float-label">Serial number<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.serialNumber.errors?.required">
          Serial number is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="supplierReference" placeholder="Supplier reference" />
          <label class="float-label">Supplier reference<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.supplierReference.errors?.required">
          Supplier reference number is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <select class="re-input" formControlName="condition" placeholder="Condition">
            <option>New</option>
            <option>Used</option>
          </select>
          <label class="float-label">Condition<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.condition.errors?.required">
          Condition is required
        </small>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="status" [(ngModel)]="txtstatus" placeholder="Status"
            id="droptypes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="droptypes">
            <button (click)="selectStatus(status)" *ngFor="let status of statusList | filterPipe: txtstatus"
              class="dropdown-item">
              {{ status }}
            </button>
          </div>
          <label class="float-label">Status<span class="star">*</span></label>
        </div>
        <small *ngIf="submitted && frm.status.errors?.required">
          Status is required
        </small>
      </div>
      <div class="row" *ngIf="showWarehouse">
        <div class="field">
          <input class="re-input" type="text" formControlName="warehouse" [(ngModel)]="txtwarehouse"
            placeholder="Warehouse" id="dropwarehouses" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="dropwarehouses">
            <button (click)="selectWarehouse(warehouse)" *ngFor="let warehouse of warehouses | filterPipe: txtwarehouse"
              class="dropdown-item">
              {{ warehouse.warehouseName }}
            </button>
          </div>
          <label class="float-label">Warehouse<span class="star">*</span></label>
        </div>
      </div>
      <label class="section-title-blue">Additional info</label>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="description" placeholder="Description" />
          <label class="float-label">Description</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="number" formControlName="recommendedHours" placeholder="Recommended hours" />
          <label class="float-label">Recommended hours</label>
        </div>
      </div>
      <div class="row" *ngIf="false">
        <input type="checkbox" formControlName="noX" class="checkbox" />
        <label class="checkbox-label">NOx compliant</label>
      </div>
      <div class="row" *ngIf="false">
        <input type="checkbox" formControlName="classCertificate" class="checkbox" />
        <label class="checkbox-label">Class certificate</label>
      </div>
      <label class="section-title">ADD PROFILE PICTURE</label>
      <div class="container upload-image">
        <input #file type="file" (change)="uploadFile(file.files)" class="file-button" />
        <div class="upload-image-content">
          <em class="fa fa-file-image-o fa-2x"></em>
        </div>
        <label class="upload-image-label">{{ profilImage }}</label>
      </div>
      <label class="section-title-blue">Documentation</label>
      <div class="row" *ngFor="let file of componentFiles?.controls; let i = index">
        <button class="uploaded-file">
          <em class="fa fa-cog"></em>{{ file.controls.filename.value }}
        </button>
      </div>

      <app-add-file (closeFileForm)="updateFiles($event)"></app-add-file>
      <label class="section-title">GENERAL DOCUMENTS</label>
    </form>
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default" (click)="reset()">Cancel</button>
      <button class="btn-solid secondary" (click)="updateComponent()">
        Done
      </button>
    </div>
  </div>
</div>