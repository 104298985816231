<div class="reflow-form">
    <div class="fixed-form-header">
      <div class="header-content">
        <label class="form-title">Add platform update</label>
        <em class="fa fa-times form-close" (click)="hideModal()"></em>
      </div>
    </div>
    <div class="form-container">
      <form [formGroup]="updateToCreate">
        <div class="row" style="margin-top: 30px;">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="updateModule"
              required
            />
            <label class="float-label">Update Module</label>
            <small *ngIf="submitted && frm.updateModule?.errors?.required">
              *Update Module is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <textarea
              class="re-input"
              type="text"
              cols="40"
               rows="5"
              formControlName="updateDescription"
              required
            ></textarea>
            <label class="float-label">Update Description</label>
            <small *ngIf="submitted && frm.updateDescription?.errors?.required">
              *Update Description is required
            </small>
          </div>
        </div>
        <div class="row" style="margin-top: 100px;">
          <div class="field">
            <input
              class="re-input"
              type="date"
              formControlName="updatedAt"
              value="{{puToUpdate?.updatedAt | date:'dd/mm/yyyy'}}"
              required
            />
            <label class="float-label">Update date</label>
            <small *ngIf="submitted && frm.updatedAt?.errors?.required">
              *update date is required
            </small>
          </div>
        </div>
      </form>
    </div>
    <div class="fixed-form-footer">
      <div class="footer-content">
        <button class="btn-solid default" (click)="hideModal()"> Close</button>
        <button class="btn-solid secondary" (click)="updatePlatform()">
          Done
        </button>
      </div>
    </div>
  </div>
  