<div class="module-management-container">
    <div class="filters-container">
        <div class="qm-title-container">
            <label class="qm-title">All modules (5)</label>
            <div class="qm-search-container">
                <img src="../../../assets/yardscore-assets/ys-i-search.svg" alt="" class="qm-search-icon">
                <input type="text" class="qm-search-text" placeholder="Search module">
            </div>
        </div>
        <span ngbDropdown >
            <div class="list-filter" ngbDropdownToggle>
                <label class="filter-label">Sort by</label>
                <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="filter-icon">
            </div>
            <div ngbDropdownMenu>
				
                <button ngbDropdownItem (click)="toSortBy='title'">Name</button>
                <button ngbDropdownItem (click)="toSortBy=''">Date</button>
                <button ngbDropdownItem (click)="toSortBy=''">Creator</button>
			</div>
        </span>
        <div class="qm-add-module" (click)="toggleUpsertForm('create',null)">Add module</div>
    </div>
    <div class="modules-list-container">

        <div class="modules-list">
            <table class="table">
                <thead>
                    <tr>
                        <th>Module name</th>
                        <th class="sortable">
                            <label>Created on</label>
                            <img src="../../../../assets/yardscore-assets/ys-i-sort-order.svg" alt="">
                        </th>
                        <th>Created by</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngIf="true">
                        <ng-container *ngFor="let item of modules | sortby: modules : toSortBy; let idx=index">
                            <tr>
                                <td>{{item?.title}}</td>
                                <td>14-02-2024</td>
                                <td>Patrik</td>
                                <td class="actions-container" [ngStyle]="{'border-top': idx==0?'0 !important':''}" >
                                    <button class="manage-button" [routerLink]="[item?.id+'/sub-categories']" routerLinkActive="router-link-active" >
                                        <i class="fa fa-cog"></i>
                                        <label>Manage</label>
                                    </button>
                                    <img src="../../../assets/yardscore-assets/ys-i-edit.svg" alt="" class="action-icon" (click)="$event.stopPropagation();toggleUpsertForm('update',item)">
                                    <img src="../../../assets/yardscore-assets/ys-i-delete.svg" alt="" class="action-icon action-margin" (click)="$event.stopPropagation();toggleDeleteForm(item)">
                                    <img src="../../../assets/yardscore-assets/ys-i-more-actions.svg" alt="" class="action-icon">
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <div class="no-data-container" *ngIf="false">
                <img src="../../../../assets/yardscore-assets/no-data.svg" alt="" class="no-data-img">
                <label class="no-data-label">No data Found !!</label>
            </div>
        </div>
    </div>
</div>
<app-questionnaire-child-upsert *ngIf="showUpsertForm==true" [currentObject]="currentModule" [actionType]="actionType" [actionOn]="'module'" [parentData]="category_id" (closeUpsertForm)="closeUpsertForm($event)"></app-questionnaire-child-upsert>
<app-questionnaire-child-delete *ngIf="showDeleteForm==true" [actionOn]="'module'" [currentChild]="currentModule" (closeDeleteModal)="closeDeleteModal($event)"></app-questionnaire-child-delete>
