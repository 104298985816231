import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yardscore-shipyard',
  templateUrl: './yardscore-shipyard.component.html',
  styleUrls: ['./yardscore-shipyard.component.scss']
})
export class YardscoreShipyardComponent implements OnInit {

  public menuItems:any=[
    {name:'Dashboard',icon:'overview',route:'overview'},
    {name:'Questionnaire',icon:'questionnaire',route:'questionnaire'},
    {name:'Questionnaire archive',icon:'help',route:'questionnaire-archive'},
    {name:'Help',icon:'help',route:'help'},
    {name:'Documentation',icon:'documentation',route:'documentation'},
  ];
  public mouseOn='';
  public selectedTab:any=this.menuItems[0];

  constructor() {
    if (localStorage.getItem("ys_st") !== null) {
      this.selectedTab=this.menuItems[parseInt(localStorage.getItem('ys_st')+"")];
    }
  }

  ngOnInit(): void {
    document.body.style.background = '#F7FAFC';
  }

  public mouseEnter(item){
    this.mouseOn=item;
  }

  public mouseleave(item){
    this.mouseOn="";
  }

  public selectTab(tab,idx){
    this.selectedTab=tab;
    localStorage.setItem('ys_st',idx)
  }

}
