import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit {
  @Output() subscriptionModalEvent =new EventEmitter<any>();
  @Input()  companyId: string;
  public showSubmscriptionModal = true;
  constructor(private companyService: CompanyService, private toastrService:ToastrService) { }

  ngOnInit(): void {
  }

  public close():void{
    this.subscriptionModalEvent.emit("close-credit-modal");
  }

  public confirm(refillCredits:any):void{
    this.companyService.updateSubscription({companyId:this.companyId,monthlyCredit:Number(refillCredits)}).subscribe(res=>{
      this.toastrService.success("Subscription updated with "+refillCredits+" per month","Operation accomplished");
      this.subscriptionModalEvent.emit("close-credit-modal");
    });
  }

}
