<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Companies</label>
    </ul>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="">Company Name</th>
        <th id="">reflow points</th>
        <th id="">number of useers</th>
        <th id="">More informations </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let company of companies">
        <td>{{company.company.companyName}}</td>
        <td>{{company.company.reflowPoints}}</td>
        <td>{{company.userNumber}}</td>
        <td>
          <button class="btn-solid secondary" data-toggle="modal" data-target="#myModal"
            (click)="showCompanyDetails(company.company.id)">show more</button>
        </td>
      </tr>
    </tbody>
  </table>

</div>
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">More informations about the Company</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="col" style="text-align: center;">
          <div class="row">
            <div class="col">
              <div class="row">
                <label class="details-section-header">Calculations Done :</label>
              </div>
              <div class="row">
                <label class="details-section-content">
                  {{companyDetail?.calculationDone}}
                </label>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <div class="row">
                <label class="details-section-header">Calculation processing :</label>
              </div>
              <div class="row">
                <label class="details-section-content">
                  {{companyDetail?.calculationProcessing}}
                </label>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <label class="details-section-header rightcontent">last calculation :</label>
              </div>
              <div class="row">
                <label class="details-section-content rightcontent">
                  {{companyDetail?.lastCalculation | date : 'dd/MM/yyyy'}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <div class="row">
                <label class="details-section-header">Last login :</label>
              </div>
              <div class="row">
                <label class="details-section-content">
                  {{companyDetail?.lastLogin | date : 'dd/MM/yyyy'}}
                </label>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <label class="details-section-header rightcontent">Number of shared projects :</label>
              </div>
              <div class="row">
                <label class="details-section-content rightcontent">
                  {{companyDetail?.sharedNumber}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <div class="row">
                <label class="details-section-header">Number of imported projects :</label>
              </div>
              <div class="row">
                <label class="details-section-content">
                  {{companyDetail?.importedNumber}}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <label class="details-section-header" style="font-weight: bold;">Number of access to :</label>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <label class="details-section-header">Reflow academy :</label>
                  </div>
                  <div class="row">
                    <label class="details-section-content">
                      {{companyDetail?.academyAccess}}
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <label class="details-section-header rightcontent">Quantify :</label>
                  </div>
                  <div class="row">
                    <label class="details-section-content rightcontent">
                      {{companyDetail?.screeningAccess}}
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <label class="details-section-header rightcontent">Compare :</label>
                  </div>
                  <div class="row">
                    <label class="details-section-content rightcontent">
                      {{companyDetail?.compareAccess}}
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <label class="details-section-header rightcontent">Share :</label>
                  </div>
                  <div class="row">
                    <label class="details-section-content rightcontent">
                      {{companyDetail?.shareAccess}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>