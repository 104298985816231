<div id="openModal-about" class="modalDialog" *ngIf ="showModal">
    <div class="re-modal-content">
            <div class="ShareModalHearder">
                <label class="shareModalTitle">{{headingTitle}}</label>
                <em class="icon-close close" (click)="close()"
                    style="color: #ffffff;margin: 2px;"></em>
            </div>
            <div class="dynamic-share-container">
            <div class="tabs-container">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                    <li [ngbNavItem]="1" [destroyOnHide]="true" (click)="changeHeadingTitleUrl()">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=1,'nav-button-active': active==1}">
                            <label [ngClass]="{'nav-text': active!=1,'nav-text-active': active==1}">URL Code</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="urlContainer">
                                <div class="linkContainer">
                                <p class="urlText">Please copy this code and send it to the company you would like to share the calculation result with.</p>
                                <div class="screenings link spacing">
                                    <label class="screening-name">{{refId}}</label>
                                    <em class="fa fa-copy screening-copy" [cdkCopyToClipboard]="refId" (click)="copyUrl()"></em>
                                </div>
                                <button class="close-button" (click)="closeModal()">Confirm & share</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" (click)="changeHeadingTitleQr()">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=2,'nav-button-active': active==2}">
                            <label [ngClass]="{'nav-text': active!=2,'nav-text-active': active==2}">QR Code</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="qrcode-container">
                                <div class="linkContainer forqrcode" >
                                    <div class="qr-content" #screen>
                                        <div class="qr-grid">
                                            <ngx-qrcode
                                                [elementType]="elementType"
                                                errorCorrectionLevel="Q"
                                                [value]="refId"
                                                colorDark="#123B63">
                                            </ngx-qrcode>
                                        </div>
                                        <img src="../../../../assets/logo-icon.png" alt="">
                                    </div>
                                    <div class="media-types">
                                        <div class="media-element">
                                            <button (click)="downloadPdf()">
                                                <div class="spinner-grow spinner-grow-sm" style="color: #98d6d4;" *ngIf="showSpinner"></div>
                                                PDF
                                            </button>
                                            <label>Assessment PDF with QR-code</label>
                                        </div>
                                        <div class="media-element mt-3">
                                            <button (click)="downloadImage()">PNG</button>
                                            <label>PNG of QR-code</label>
                                        </div>
                                    </div>
                                </div>
                                <button class="close-button"  (click)="close()">Close</button>
                                <div id="download" class=" d-none">
                                    <img #canvas>
                                    <a #downloadLink></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" (click)="changeHeadingTitleCompanies()">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=3,'nav-button-active': active==3}" style="width:182px !important ;">
                            <label [ngClass]="{'nav-text': active!=3,'nav-text-active': active==3}">Share with companies</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="company-container" *ngIf="invitationSent==false">
                                <p *ngIf="shouldMakeSearch==false" class="urlText">Please type the name of the company you would like to share your assessment with. If the company is already part of the ReFlow platform, they will be able to see it on their shared with me folder. Otherwise, you can invite them for joining the ReFlow Platform by filling out contact information. You can share it with multiple companies at the same time</p>
                                <div class="row"  *ngIf="shouldMakeSearch==false">
                                    <img class="company-icon" src="../../../../assets/private_share.svg" alt=""/>
                                    <div class="form-group select" ngbDropdown [autoClose]="'outside'">
                                        <input
                                            class="form-control companies-list"
                                            type="text"
                                            id="dropowners"
                                            [(ngModel)]="searchText"
                                            (ngModelChange)="toggleCustom()"
                                            ngbDropdownToggle
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            autocomplete="off"
                                            placeholder="Type to search for companies" 
                                        />
                                        <div ngbDropdownMenu  aria-labelledby="dropowners" closeOnSelect="false"  (click)="$event.stopPropagation()">
                                                <button  *ngIf="canMakeSearch"
                                                ngbDropdownItem
                                                type="button"
                                                >
                                                    <label class="drop-down-label">
                                                        <img src="../../../../assets/private_share.svg" alt="" style='border:none;border-radius:0px;background-color:transparent'>
                                                        {{searchText}}
                                                    </label>
                                                    <button class="rename rename-btn" (click)="shouldMakeSearch=true;customCompany.controls['companyName'].setValue(searchText)"> Invite to platform</button>
                                                </button>
                                            
                                                <button  *ngFor="let company of companies | filterPipe:searchText"
                                                ngbDropdownItem
                                                type="button"
                                                >
                                                    <label class="drop-down-label">
                                                        <img [src]="company.companyLogo" alt="" onerror="this.src='../../../../assets/private_share.svg';this.style='border:none;border-radius:0px;background-color:transparent'">
                                                        {{company.companyName}}
                                                    </label>
                                                    <button class="rename rename-btn" (click)="selectCompany(company)"> Share</button>
                                                </button>
                                                
                                            
                                        </div>
                                        <div class="selection-container mt-3">
                                            <div class="selected-companies">
                                                <div *ngFor="let company of selectedCompanies;let i=index" class="selected-company mr-2 mt-1">
                                                    <img [src]="company.companyLogo" alt="" onerror="this.src='../../../../assets/private_share.svg';">
                                                    <label for="">{{company.companyName}}</label>
                                                    <em class="icon-close" (click)="discardCompany(i)"></em>
                                                </div>
                                            </div>
                                            <button class="send-mails" [ngClass]="{'enabled': selectedCompanies.length!=0}" (click)="invite()">{{companyShareButton}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-company-container" *ngIf="shouldMakeSearch==true">
                                    <div class="intro">
                                        <img class="company-icon" src="../../../../assets/private_share.svg" alt=""/>
                                        <p>The company you search for does not have a ReFlow account. You can send an invite by filling out the text fields below and clicking invite. An email with the invitation will be sent.</p>
                                    </div>
                                    <div class="form-custom-company" [formGroup]="customCompany">
                                        <div class="inputs-container">
                                            <div class="form-group">
                                                <input type="text" formControlName="companyName" class="custom-form-control"  placeholder="Company name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" formControlName="companyContact" class="custom-form-control" placeholder="Contact person">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" formControlName="email" class="custom-form-control" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="actions-container">
                                            <button class="continue" (click)="addCustomCompany()">Continue</button>
                                            <button class="cancel" (click)="shouldMakeSearch=false;searchText='';">Cancel</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="project-shared" *ngIf="invitationSent==true">
                                <em class="icon-validation-completed"></em>
                                <label> The project is now shared </label>
                                <button class="close-btn" (click)="close()">Close</button>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" (click)="changeHeadingTitleData()">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=4,'nav-button-active': active==4}">
                            <label [ngClass]="{'nav-text': active!=4,'nav-text-active': active==4}">Data subscribers</label>
                            <div class="navlink"></div>
                        </a>
                        </li>
                    </ul>
                    
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
        
    </div>
</div>