<div class="overview-container">
  <div class="infomation-header">
    <div class="row">
      <div class="block-icon"><em class="icon-info bigger-info"></em></div>
      <div class="block-text">
        <p class="information-text text-margin">
          The share function allows you to share all of your calculations with other stakeholders - either privately or
          publicly. Privately means that the calculation will be shared internally, within the platform, and only the
          company or user you select has access. You can also choose to share your calculation with a public link or QR
          code, which means that everyone who has access to one of the before-mentioned can enter the calculation.</p>
      </div>
    </div>
  </div>
  <hr>

  <div class="search-container">
    <div class="search-box" ngbDropdown >
      <em class="icon-search icon-search-left"></em>
      <input
        type="text"
        placeholder="Search project"
        autocomplete="off"
        class="input-search"
        [(ngModel)]="searchText"
        (ngModelChange)="makeSearch()"
        (focus)="searchTriggered=true"
        (blur)="searchTriggered=false"
        ngbDropdownToggle
      >
      <em class="icon-close close-search" style="top: 0px; right:0px;font-size: 23px;" *ngIf="searchText!=''" (click)="searchText='';searchList=[]"></em>
      <div class="icon-search-right-container"  *ngIf="searchTriggered||searchText!=''">
        <em class="icon-search icon-search-right"></em>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu" *ngIf="searchText!=''">
        <ng-container *ngFor="let item of searchList" >
          <button class="dd-item" ngbDropdownItem (click)="addNewItem(item)">
            <img src="../../../../assets/pi_draft_projects.svg" alt="">
            {{item?.name}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="dynamic-content-container">

    <div class="folders-container">
      <div class="top-container" style="border-right: none">

      </div>
      <div class="folders-tree-container">
        <div class="folders-tree">
          <hr class="root-vr">
          <div class="folder" (click)="selectFolder('completed')"
            [ngClass]="{'engaged-folder':selectedFolder=='completed'}" data-toggle="collapse"
            data-target="#collapseMyProjects" aria-expanded="false" aria-controls="collapseMyProjects">
            <img
              [src]="selectedFolder=='completed'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
              alt="" class="folder-state">
            <label>My projects</label>
            <img *ngIf="!canShowFolders" class="folder-loading"
            src="../../../assets/Loading-icon-animation.svg" alt="" />
          </div>
          <div class="collapse" id="collapseMyProjects">
            <hr class="root-vr sub-vr">
            <ng-container *ngFor="let item of completedFolders">
              <div class="subfolders" (click)="selectProject(item)">
                <div class="folder ml-37" (click)="selectSubFolder('item')"
                  [ngClass]="{'engaged-folder':selectedFolder=='item.folderName'}">
                  <hr class="subfolder-treelink">
                  <img
                    [src]="selectedFolder=='item.folderName'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                    alt="" class="folder-state">
                  <label>{{item.folderName}}</label>
                </div>

              </div>
            </ng-container>
          </div>
        </div>
        <div class="buffer"></div>
      </div>
    </div>
    <div class="projects-container">
      <div class="top-container br-tr" style="border-left: solid 1px #ddd;">
        <div class="operation" (click)="filter('shared')">
          <div class="checkbox">
            <i class="fa fa-check" aria-hidden="true" *ngIf="filterParameter=='shared'"></i>
          </div>
          <label for="">Shared</label>
        </div>
        <div class="operation ml-15" (click)="filter('notShared')">
          <div class="checkbox">
            <i class="fa fa-check" aria-hidden="true" *ngIf="filterParameter=='notShared'"></i>
          </div>
          <label for="">Not shared</label>
        </div>
      </div>
      <div class="projects-list-container">
        <div class="projects-list" *ngIf="projects!=null">
          <div class="project-item" *ngFor="let project of projects;let i=index"
            [ngClass]="{'engaged-project': selectedProject==project}" (click)="addNewItem(project)">
            <div class="project">
              <div class="pi-checkbox" (click)="projects[i]['checked']=!projects[i]['checked'];">
                <i class="fa fa-check" aria-hidden="true" *ngIf="projects[i]['checked']"></i>
              </div>
              <img src="../../../../assets/pm_completed_projects@2x.png" alt="">
              <label (click)="selectOneProject(project)">{{project.name}}</label>
            </div>
            <img src="../../../../assets/private_share.svg" class="project-icon">
          </div>
        </div>
        <div class="buffer"></div>
      </div>
    </div>
  </div>


</div>
