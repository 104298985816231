import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { ExternalCompanyService } from 'src/app/services/external-company.service';

@Component({
  selector: "app-edit-external",
  templateUrl: "./edit-external.component.html",
  styleUrls: ["./edit-external.component.css"],
})
export class EditExternalComponent implements OnInit, OnChanges {
  @Output() toogleModal = new EventEmitter();

  @Input() public showForm: boolean;
  @Input() public companyToEdit: Company;
  submitted = false;
  public action: string;
  public companyToCreate: FormGroup;
  public redDotType: boolean = true;

  constructor(
    private fb: FormBuilder,
    private externalCompanyService: ExternalCompanyService,
    private uploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    if (this.companyToEdit != null) {
      this.action = "Edit";
    } else {
      this.action = "Add";
    }
    this.companyToCreate = this.fb.group({
      companyName: [this.companyToEdit?.companyName, Validators.required],
      vatNumber: [this.companyToEdit?.vatNumber],
      address: [this.companyToEdit?.address],
      zipCode: [this.companyToEdit?.zipCode, [
        Validators.required,
      ]],
      city: [this.companyToEdit?.city],
      country: [this.companyToEdit?.country, Validators.required],
      billingEmail: [this.companyToEdit?.billingEmail],
      external: true,
      companyType: [this.companyToEdit?.companyType, Validators.required],
      companyLogo: [this.companyToEdit?.companyLogo],
      billingContact: this.fb.group({
        contactName: [this.companyToEdit?.billingContact.contactName],
        contactTitle: [this.companyToEdit?.billingContact.contactTitle],
        adresseEmail: [this.companyToEdit?.billingContact.adresseEmail],
        phoneNumber: [this.companyToEdit?.billingContact.phoneNumber],
      }),
      companyContact: this.fb.group({
        contactName: [this.companyToEdit?.companyContact.contactName],
        contactTitle: [this.companyToEdit?.companyContact.contactTitle],
        adresseEmail: [this.companyToEdit?.companyContact.adresseEmail],
        phoneNumber: [this.companyToEdit?.companyContact.phoneNumber],
      }),
    });
  }
  
  get frm() {
    return this.companyToCreate.controls;
  }

  public uploadFile(files) {
    this.uploadService.uploadFile(files[0], 0).subscribe((res) => {
      const uploadfile = new UploadPhotoModel(res);
      this.companyToCreate.patchValue({ companyLogo: uploadfile.fileName });
    });
  }

  public updateCompany() {
    this.submitted = true;
    if (this.companyToCreate.invalid) {
      return;
    }

    const company: Company = new Company(this.companyToCreate.value);

    if (this.companyToEdit != null) {
      this.submitted = true;
      company.external = true;
      company.id = this.companyToEdit.id;
      this.externalCompanyService.editExternalCompany(company).subscribe((res) => {
        this.ngOnInit();
        this.hideModal();
      });
    } else {
      this.submitted = true;
      company.external = true;
      this.externalCompanyService.addCompany(company).subscribe((res) => {
        this.ngOnInit();
        this.hideModal();
      });
    }
  }

  public hideModal() {
    this.showForm = false;
    this.companyToEdit = null;
    this.toogleModal.emit(this.showForm);
  }

  public deleteCompany() {
    this.externalCompanyService.deleteCustomerCompany(this.companyToEdit.id).subscribe((res) => {
      this.hideModal();
      this.ngOnInit();
    });
  }

  public reset() {
    this.ngOnInit();
  }

  ngOnChanges(changes: any) {
  }
}
