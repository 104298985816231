<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="showAdjustModal">
  <div class="re-modal-content2" style="height:250px;">
    <form>
      <div class="request-header">
        <label class="requestModalTitle">Adjust available resources</label>
        <em class="icon-close close" style="margin:5px; color:#ffffff;" (click)="close()"></em>
      </div>
      <div class="content-modal2">
        <div class="all-content">
          <div class="first-element">
            <div style="display: flex;flex-direction: row;">
            <img src="../../../../assets/caculation_icon.PNG.png" style="width: 25px;height: 25px;margin-top: 17px;margin-left: 10px;">
              <p class="calculation-text">
                Calculations:
              </p>
              <button class="left-arrow-button" (click)="decrementCalculationPoints()">
                <em class="fa fa-caret-left"></em>
              </button>
              <p class="p-no">{{calculationpoints}} pcs</p>
              <button class="right-arrow-button" (click)="incrementCalculationPoints()">
                <em class="fa fa-caret-right"></em>
              </button>
            </div>
            <div style="display: flex;flex-direction: row;margin-top: -22px;">
            <img src="../../../../assets/Engineering_consultations.PNG.png" style="width: 25px;height: 25px;margin-top: 17px;margin-left: 10px;">
              <p class="calculation-text">
                Engineer Support:
              </p>
              <button class="left-arrow-button" (click)="decrementEngineerHours()">
                <em class="fa fa-caret-left"></em>
              </button>
              <p class="p-no">{{enngHours}} hrs</p>
              <button class="right-arrow-button" (click)="incrementEngineerHours()">
                <em class="fa fa-caret-right"></em>
              </button>
            </div>
            <div style="display: flex;flex-direction: row;margin-top: -22px;">
            <img src="../../../../assets/Validation.SVG.png" style="width: 25px;height: 25px;margin-top: 17px;margin-left: 10px;">
              <p class="calculation-text">
                Validations:
              </p>
              <button class="left-arrow-button" (click)="decrementValidationHours()">
                <em class="fa fa-caret-left"></em>
              </button>
              <p class="p-no">{{validationHours}} pcs</p>
              <button class="right-arrow-button" (click)="incrementValidationHours()">
                <em class="fa fa-caret-right"></em>
              </button>
            </div>
          </div>
          <div>
            <button class="save-button" style="color:#000;background-color:#98D6D4 ;" (click)="saveChanges(enngHours,validationHours,calculationpoints)">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>