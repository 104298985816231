<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Components</label>
      <label class="content-title" (click)="showExchange()">Exchange</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
            name="searchTerm" />
        </div>
        <button class="filter-button" (click)="toogleFilter(true)">
          <em class="icon-filter"></em>
          <label class="btn-label">Filter</label>
        </button>
        <div class="col-md-4">
          <button class="btn-solid primary" (click)="showEditModal(true)">
            <em class="icon-add"></em>Add component
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('reference', '')">
          <label style="margin-left: 10px;">Reference</label>
        </th>
        <th id="" (click)="orderBy('description', '')">Description</th>
        <th id="" (click)="orderBy('', '')">Owner</th>
        <th id="" (click)="orderBy('type', '')">Type</th>
        <th id="" (click)="orderBy('status', '')">Status</th>
        <th id="" (click)="orderBy('daysInStatus', '')">Days in status</th>
        <th id="" (click)="orderBy('tte', '')">TtE</th>
        <th id="" (click)="orderBy('coSaving', '')">Total kg CO2e savings</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="
          let component of componentList
            | paginate: { itemsPerPage: 10, currentPage: p }
            | filterPipe: searchTerm
            | sort: fieldName:attr
        " (click)="clickComponent(component, $event)" style="cursor: pointer;">
        <td style="font-weight: bold;">
          <img class="left-image" src="{{ component.componentPicture }}" alt="component Picture" />
          {{ component.reference }}
        </td>
        <td>{{ component.description }}</td>
        <td>
          <img src="{{ component.ownerLogoUrl }}" alt="component Picture" />
        </td>
        <td>{{ component.type }}</td>
        <td>
          <em class="icon-servicing status" *ngIf="component.status == 'Servicing'"></em>
          <em class="icon-in-warehouse status" *ngIf="component.status == 'In supplier warehouse'"></em>
          <em class="icon-transit status" *ngIf="component.status == 'Transit to vessel operator'"></em>
          <em class="icon-in-warehouse status" *ngIf="component.status == 'In Vessel operator warehouse'"></em>
          <em class="icon-received-on-vessel status" *ngIf="component.status == 'Received on vessel'"></em>
          <em class="icon-stocked-on-vessel status" *ngIf="component.status == 'Stocked on vessel'"></em>
          <em class="icon-in-use status" *ngIf="component.status == 'In use'"></em>
          <em class="icon-servicing status" *ngIf="component.status == 'Returned for service'"></em>
          <label class="label-status">{{ component.status }}</label>
        </td>
        <td>{{ component.daysInStatus }}</td>
        <td (click)="clickComponent(component, $event)">
          <label *ngIf="component.status != 'In use'">
            <span> N/A </span>
          </label>
          <label *ngIf="component.status == 'In use'">
            <span class="dot-red" *ngIf="component.tte > 0 && 25 > component.tte"></span>
            <span class="dot-yellow" *ngIf="component.tte > 25 && 75 > component.tte"></span>
            <span class="dot-green" *ngIf="component.tte > 75"></span>
            {{ component.tte }} days
          </label>
        </td>
        <td>
          <label class="left-image" *ngIf="component.coSaving != 0">
            {{ component.coSaving }}
          </label>
          <label class="left-image" *ngIf="component.coSaving == 0">
            <span class="shine">New</span>
          </label>
        </td>
        <td>
          <em class="icon-edit" (click)="selectComponent(true, component)">
          </em>
          <em class="icon-status" (click)="showStatusModal(true, component)"></em>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="pagination">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
<app-sp-component-filter *ngIf="showFilter" (toogleModal)="toogleFilter($event)">
</app-sp-component-filter>
<app-component-edit-view *ngIf="showEditComponent" [showEditModal]="showEditComponent"
  (closeModal)="showEditModal($event)">
</app-component-edit-view>
<app-update-status *ngIf="showStatusComponent" [componentId]="componentId" [currentComponent]="currentComponent"
  [showStatusModal]="showStatusComponent" (closeStatusModal)="showStatusModal($event, null)"></app-update-status>

<app-component-details *ngIf="showDetailComponent" [componentId]="componentId"
  (closeModal)="selectComponent($event, null)" (openStatus)="showEmmiterStatusModal($event)">
</app-component-details>
<app-request-demo *ngIf="!hasAccess">
</app-request-demo>