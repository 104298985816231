<div class="reflow-form-tabs">
  <div class="form-tabs-header">
    <div class="row">
      <div class="col" style="text-align: center; margin-top: 10px;">
        <img
          src="{{ currentLocation?.vesselImage }}"
          alt=""
          class="user-image"
        />
        <label class="user-name">Location name</label>
        <label class="user-login">{{ currentLocation?.name }}</label>
      </div>
    </div>
    <div class="row tabs-menu">
      <div
        class="col tabs-menu-item"
        style="
          border-top: 0.5px solid #e8e8e8;
          border-right: 0.5px solid #e8e8e8;
        "
      >
        <div class="row">
          <div class="col-sm-4">
            <span class="cit">{{ currentLocation?.totalComponenent }}</span>
          </div>
          <div class="col-sm-8">
            <label class="item-content" style="text-align: left;"
              >Components in total</label
            >
          </div>
        </div>
      </div>
      <div class="col tabs-menu-item" style="border-top: 0.5px solid #e8e8e8;">
        <div class="row">
          <div class="col-sm-4">
            <img
              class="status-image"
              src="../../../assets/circle-arrow.png"
              alt=""
            />
          </div>
          <div class="col-sm-8">
            <label class="item-content" style="text-align: left;"
              >Maintenance status</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-container" style="margin-top: 200px;">
    <form [formGroup]="locationForm">
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="type"
            placeholder="Type"
          />
          <label class="float-label">Type</label>
          <small *ngIf="submitted && frm.type?.errors?.required">
            *Type is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="name"
            placeholder="Name"
          />
          <label class="float-label">Name</label>
          <small *ngIf="submitted && frm.name?.errors?.required">
            *Name is required
          </small>
        </div>
      </div>
      <div class="row" *ngIf="currentLocation?.type == 'Engine'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="manufacturer"
            [(ngModel)]="txtmanufacturter"
            placeholder="Manufacturer"
            id="dropmanufacturer"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropowners">
            <button
              *ngFor="let supplier of suppliers | filterPipe: txtmanufacturter"
              class="dropdown-item"
              type="button"
              (click)="selectManufacturer(supplier)"
            >
              <img
                class="drop-down-image"
                src="{{ supplier.companyLogo }}"
                alt=""
              />
              <label class="drop-down-label">{{ supplier.companyName }}</label>
            </button>
          </div>
          <label class="float-label"
            >Manufacturer<span class="star">*</span></label
          >
        </div>
        <small *ngIf="submitted && frm.manufacturer.errors?.required">
          Manufacturer is required
        </small>
      </div>
      <div class="row" *ngIf="currentLocation?.type == 'Engine'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="serialNumber"
            placeholder="Serial Number"
          />
          <label class="float-label">Serial Number</label>
        </div>
      </div>
      <div class="row" *ngIf="currentLocation?.type == 'Engine'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="runningHours"
            placeholder="Running hours"
          />
          <label class="float-label">Running hours</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="description"
            placeholder="Description"
          />
          <label class="float-label">Description</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="specification"
            placeholder="specification"
          />
          <label class="float-label">Specification</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="endPoint"
            placeholder="End point"
          />
          <label class="float-label">End point</label>
        </div>
      </div>
      <label class="section-title">FILEs</label>
      <div class="row">
        <button class="uploaded-file">
          <em class="fa fa-cog"></em>filename
        </button>
      </div>
    </form>
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default">Back</button>
      <button class="btn-solid secondary">
        Save
      </button>
    </div>
  </div>
</div>
