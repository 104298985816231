<div id="openModal-about" class="modalDialog" style="overflow-x: auto;" *ngIf="showCreditModal">
  <div class="re-modal-content2" style="height:250px;">
    <form>
      <div class="request-header">
        <label class="requestModalTitle">Update Your Reflow Credit</label>
        <em class="icon-close close" style="margin:5px; color:#ffffff;" (click)="close()"></em>
      </div>
      <div class="content-modal2">
        <div class="request-container">
          <div style="display:block;">
            <label class="email-label">Current Credits : {{initialCredit}} </label><br>
            <input type="text" placeholder="Credits to add" class="email-input" #newCredit>
          </div>
          <div>
          </div>
          <div class="twoButton" style="display:flex;flex-direction:row;justify-content: center;text-align: center;margin-top: 15px !important;">
            <button class="btn-solid default" (click)="close()">Close</button>
            <button class="btn-solid default" style="color:#000;background-color:#98D6D4 ;" (click)="updateCredit(newCredit.value)">Update</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
