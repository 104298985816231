<div id="openModal-about" class="modalDialog" >
    <div class="re-modal-content">
        <div class="requestModalHearder">
            <label class="requestModalTitle">Request climate data from your supplier</label>
            <em class="icon-close close" (click)="close()" style="color: #ffffff;margin: 2px;"></em>
        </div>
        <div class="modal-body" *ngIf="canCreateInvitation==false&&!done">
            <div class="description-container">
                <img src="../../../../assets/request-icon.png" alt="">
                <div class="description-content">
                    <label class="title">Invite and request climate data from the supplier</label>
                    <p class="content">Make it easier to calculate climate emissions of all your products life cycle stages by asking your suppliers about climate data.</p>
                </div>
            </div>
            <ng-container *ngIf="canSelect">
                <label class="search-title">Search for supplier:</label>
                <input type="text" class="search-box" placeholder="Type company name here" [(ngModel)]="searchText" (ngModelChange)="filterCompanies()" id="companies-dd"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="dropdown-menu"  aria-labelledby="companies-dd" >
                    <div class="dropdown-item" ngbDropdownItem *ngIf="searchText!=''">
                        <div class="company">
                            <img src="../../../../assets/logo-icon.png" alt="">
                            <label>{{searchText}}</label>
                        </div>
                        <button class="create-invitation" (click)="canSelect=false;fillRequestData=false;canCreateInvitation=true;">Invite to platform</button>
                    </div>
                    <ng-container *ngFor="let item of existingCompanies">
                        <div class="dropdown-item" ngbDropdownItem  (click)="selectCompany(item)">
                            <div class="company">
                                <img src="../../../../assets/logo-icon.png" alt="">
                                <label>{{item.companyName}}</label>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="to-be-requested-container" *ngIf="toBeRequested.length!=0">
                    <div class="tbr-item" *ngFor="let item of toBeRequested">
                        <img src="../../../../assets/logo-icon.png" alt="">
                        <label>{{item.companyName}}</label>
                        <em class="icon-close" (click)="discardCompany(item)"></em>
                    </div>
                </div>
                <div class="buttons-container">
                    <button (click)="fillRequestData=true;canSelect=false">
                        <label >Next</label>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="fillRequestData">
                <span [formGroup]="inquired">
                    <label class="search-title">Specify to your supplier which product(s) you need climate data on:</label>
                    <input type="text" class="product-box cmt-25" placeholder="Product name" formControlName="productName">
                    <input type="text" class="product-box cmt-10" placeholder="Product no" formControlName="productNumber">
                    <textarea class="product-box cmt-10" style="height: unset !important;" id="" cols="30" rows="2" placeholder="Message" formControlName="message"></textarea>
                </span>
                <div class="buttons-container">
                    <button (click)="submit()">
                        <label >Next</label>
                    </button>
                </div>
            </ng-container>
        </div>
        <div class="modal-body" *ngIf="canCreateInvitation==true&&!done">
            <div class="description-container desc-invite">
                <img src="../../../../assets/request-icon.png" alt="">
                <div class="description-content">
                    <p class="content">The company you search for does not have a ReFlow account. You can send an invite by filling out the text fields below and clicking invite. An email with the invitation will be sent.</p>
                </div>
            </div>
            <form [formGroup]="customData">
                <input type="text" class="product-box cmt-10" placeholder="Company name" formControlName="companyName">
                <input type="text" class="product-box cmt-10" placeholder="Contact person" formControlName="contactPersonEmail">
                <input type="text" class="product-box cmt-10" placeholder="Email"  formControlName="contactPersonEmail">
                <div class="buttons-container">
                    <button (click)="canCreateInvitation=false;canSelect=true;done=false;addCustomCompany()">
                        <label >Next</label>
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-body-done" *ngIf="done">
            <div class="icon">
                <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <label class="status">Your request has been sent</label>
            <button class="close-done" (click)="close()">
                <label>Close</label>
            </button>
        </div>
    </div>
</div>