<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <label class="form-title">Update status</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="newStatus" autocomplete="off">
      <div class="row">
        <div class="field">
          <select
            class="re-input"
            formControlName="currentStatus"
            (change)="makeSelection()"
            required
          >
            <option disabled selected value></option>
            <option value="Servicing">Servicing</option>
            <option value="In supplier warehouse">
              In supplier warehouse
            </option>
            <option value="Transit to vessel owner">
              Transit to vessel owner
            </option>
          </select>
          <label class="float-label">New status</label>
        </div>
      </div>

      <div class="row" *ngIf="selected == 'Servicing'">
        <div class="field">
          <select class="re-input" formControlName="scenarioSelector" required>
            <option>N/A</option>
            <option>Remanufacturing</option>
            <option>Repair</option>
            <option>Break down</option>
          </select>
          <label class="float-label">Scenario<small>*</small></label>
        </div>
      </div>

      <div class="row" *ngIf="selected == 'In supplier warehouse'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="warehouse"
            [(ngModel)]="txtwarehouse"
            placeholder="Warehouse"
            id="dropwarehouses"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropwarehouses">
            <button
              (click)="selectWarehouse(warehouse)"
              *ngFor="let warehouse of warehouses | filterPipe: txtwarehouse"
              class="dropdown-item"
            >
              {{ warehouse.warehouseName }}
            </button>
          </div>
          <label class="float-label">Warehouse</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          frm.warehouse?.value != '' &&
          frm.warehouse?.value != null &&
          selected == 'In supplier warehouse'
        "
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="location"
            required
          />
          <label class="float-label">Location</label>
        </div>
      </div>

      <div class="row" *ngIf="selected == 'Transit to vessel owner'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="warehouse"
            [(ngModel)]="txtvesselOperator"
            placeholder="Vessel operator"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVesselOperarors">
            <button
              (click)="selectVesselOperator(vesselOperator)"
              *ngFor="
                let vesselOperator of vesselOperators
                  | filterPipe: txtvesselOperator
              "
              class="dropdown-item"
            >
              {{ vesselOperator.companyName }}
            </button>
          </div>
          <label class="float-label">Vessel operator</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          txtvesselOperator != null &&
          txtvesselOperator != '' &&
          selected == 'Transit to vessel owner'
        "
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="vessel"
            [(ngModel)]="txtvessel"
            placeholder="Vessel operator"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectVessel(vessel)"
              *ngFor="let vessel of vessels | filterPipe: txtvessel"
              class="dropdown-item"
            >
              {{ vessel.vesselName }}
            </button>
          </div>
          <label class="float-label">Vessel</label>
        </div>
      </div>
      <div class="row" *ngIf="txtlocation != null">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="location"
            [(ngModel)]="txtlocation"
            placeholder="Location"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectLocation(location)"
              *ngFor="let location of locations | filterPipe: txtlocation"
              class="dropdown-item"
            >
              {{ location.name }}
            </button>
          </div>
          <label class="float-label">Location</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="reference"
            required
          />
          <label class="float-label">Supplier reference</label>
        </div>
      </div>
      <div class="row" *ngIf="selected == 'Transit to vessel owner'">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="recommendedHours"
            required
          />
          <label class="float-label">Recommended hours</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          txtlocation != null &&
          txtlocation != '' &&
          selected == 'Transit to vessel owner'
        "
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="exchangeComponent"
            [(ngModel)]="txtexchangeComponent"
            placeholder="Exchange "
            id="dropexchangeComponent"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropexchangeComponent">
            <button
              (click)="selectUnit(unit)"
              *ngFor="
                let unit of exchangeUnits | filterPipe: txtexchangeComponent
              "
              class="dropdown-item"
            >
              {{ unit.specification }}
            </button>
          </div>
          <label class="float-label">Exchange unit</label>
        </div>
      </div>
      <div
        class="row"
        *ngFor="let file of componentFiles?.controls; let i = index"
      >
        <button class="uploaded-file">
          <em class="fa fa-cog"></em>{{ file.controls.filename.value }}
        </button>
      </div>
      <label class="section-title">ADD DOCUMENTAION</label>
      <app-add-file (closeFileForm)="updateFiles($event)"></app-add-file>
    </form>
  </div>
  <div class="flow">
    <hr />
    <div class="row">
      <span id="flow-left">Flow files</span>
      <span id="flow-right"
        ><em class="fa fa-angle-right" id="angle"></em
      ></span>
    </div>
    <hr />
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default" (click)="reset()">Cancel</button>
      <button class="btn-solid secondary" (click)="updateStatus()">
        Update
      </button>
    </div>
  </div>
</div>
