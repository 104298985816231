import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MastersheetService } from '../../../../services/mastersheet.service';

@Component({
  selector: 'app-edit-database',
  templateUrl: './edit-database.component.html',
  styleUrls: ['./edit-database.component.scss']
})
export class EditDatabaseComponent implements OnInit {
  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentDb:any;
  @Output() closeModal=new EventEmitter<any>();
  public databaseToEdit: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mastersheetService:MastersheetService,
  ) { }

  ngOnInit(): void {
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.databaseToEdit = this.fb.group({
     // id:[this.currentDb?this.currentDb.id:""],
      name:[this.currentDb?this.currentDb.name:""],
      version:[this.currentDb?this.currentDb.version:""],
      date:[this.currentDb?this.currentDb.date:""],
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public upsert(){


    if(!this.currentDb){
      /*var newdate=new Date( this.databaseToEdit.get('date').value);
      this.databaseToEdit.get('date').patchValue(newdate);*/
      this.mastersheetService.createDataBase(this.databaseToEdit.value).subscribe(ans=>{
        
      })
    }else{
      //update database
    }
    this.closeModal.emit(this.databaseToEdit.value);
  }
}
