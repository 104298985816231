import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Co2LightDto, Co2LightResult } from "src/app/models/dtos.model";
import { Co2LightService } from "src/app/services/co2-light.service";

@Component({
  selector: "app-light-result",
  templateUrl: "./light-result.component.html",
  styleUrls: ["./light-result.component.scss"],
})
export class LightResultComponent implements OnInit {
  @Input() co2Form: Co2LightDto;
  public co2LightResult: Co2LightResult;
  public imgModal: string = "";
  public titleModal: string = "";
  public descriptionModal: string = "";
  public questionModal: boolean = false;
  public detailsModal: boolean = false;
  public modal: boolean = false;
  public readMore: boolean = false;
  public qstNumber = 0;

  constructor(private co2LightService: Co2LightService) {}

  ngOnInit(): void {
    if (this.co2Form != null) {
      this.co2LightService.lightCalculate(this.co2Form).subscribe((res) => {
        this.co2LightResult = res;
      });
    }
  }

  public showReadMore(): void {
    this.readMore = true;
  }

  public retry(): void {
    location.reload();
  }

  public closeModal() {
    this.questionModal = false;
    this.detailsModal = false;
    this.modal = false;
  }

  public showDetails(): void {
    this.modal = true;
    this.questionModal = false;
    this.detailsModal = true;
  }

  public showModal(questionNumber: number): void {
    this.modal = true;
    this.questionModal = true;
    this.detailsModal = false;
    this.qstNumber = questionNumber;
    if (questionNumber == 1) {
      this.imgModal =
        "../../../../assets/calculator/result-carbon-footprint-savings.svg";
      this.titleModal = "Estimated saved carbon footprint";
    } else if (questionNumber == 2) {
      this.imgModal =
        "../../../../assets/calculator/result-economic-savings.svg";
      this.titleModal = "Potential economic impact";
    } else if (questionNumber == 3) {
      this.imgModal =
        "../../../../assets/calculator/result-carbon-footprint.svg";
      this.titleModal = "Business-as-usual";
    }
  }
}
