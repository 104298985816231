<div class="component-details">
  <form [formGroup]="componentToEdit" autocomplete="off">
    <div class="row">
      <div class="field">
        <input
          class="re-input"
          type="text"
          (input)="supplierReferenceChange($event)"
          formControlName="supplierReference"
          required
        />
        <label class="float-label">Supplier reference</label>
      </div>
    </div>
    <div class="row" *ngIf="currentComponent?.voReference">
      <div class="field">
        <input
          class="re-input"
          type="text"
          placeholder="Vo reference"
          value="{{ currentComponent?.voReference }}"
          required
        />
        <label class="float-label">Vo reference </label>
      </div>
    </div>
    <div class="row inline">
      <div class="field" style="width: 32%">
        <input
          class="re-input"
          type="number"
          formControlName="utilization"
          required
        />
        <i class="fa fa-percent field-icon" aria-hidden="true"></i>
        <label class="float-label">Utilization</label>
      </div>
      <div class="field" style="width: 50%">
        <input
          class="re-input"
          type="text"
          formControlName="recommendedHours"
          required
        />

        <label class="float-label">Recommended hours</label>
      </div>
    </div>
    <div
      class="row"
      *ngFor="let file of componentFiles?.controls; let i = index"
    >
      <button class="uploaded-file">
        <em class="fa fa-cog"></em>{{ file.controls.filename.value }}
      </button>
    </div>
    <app-add-file (closeFileForm)="updateFiles($event)"></app-add-file>
    <hr />
    <div
      class="row"
      id="core"
      (click)="showCoreInfo = !showCoreInfo"
      data-toggle="collapse"
      data-target="#demo"
    >
      <span id="core-left">Core informations</span>
      <span id="core-right">
        <em class="fa fa-angle-right" id="angle" *ngIf="!showCoreInfo"></em>
        <em class="fa fa-angle-down" id="angle" *ngIf="showCoreInfo"></em>
      </span>
    </div>
    <div id="demo" class="collapse show">
      <div class="core-item">
        <p class="top-label">Supplier reference</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.owner }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Manufacturer</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.manufacturer }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Type</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.type }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Specification</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.specification }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Serial number</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.serialNumber }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Supplier reference</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.supplierReference }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Condition</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.condition }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Warehouse</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.warehouse }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Description</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.description }}
        </p>
      </div>
      <hr />
      <div class="core-item">
        <p class="top-label">Total running hours</p>
        <p class="btm-label" *ngIf="currentComponent != null">
          {{ currentComponent.utilization }}
        </p>
      </div>

      <br /><br /><br /><br />
    </div>
    <hr />
  </form>

  <div></div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default" (click)="reset()">Cancel</button>
      <button class="btn-solid secondary" (click)="saveComponent()">
        Save
      </button>
    </div>
  </div>
</div>
