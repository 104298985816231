<div class="master-sheet-container">
    <div class="new-db-btn" (click)="showUpsertDatabase(null)">
        <label > New Database</label>
    </div>
    <div class="records-list mt-4">
        <ng-container  *ngFor="let db of dbList; let i=index">
            <div class="record-item" >
                <div class="item-output">
                    <div class="item-info">
                        <label class="version">{{db.version}}</label>
                        <label class="date">{{db.date | date:'shortDate'}}</label>
                    </div>
                    <div class="item-content-o">{{db.name}}</div>
                </div>
                <div class="item-operations" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <em class="icon-burger"></em>
                </div>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
                    <a class="dropdown-item" [routerLink]="[db.id]">Groups</a>
                </div>
            </div>
        </ng-container>
    </div>

</div>
<div #modal class="sidebar " id="sidebar" *ngIf="displayUpsertDatabase">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create Database</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="databaseToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="name"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="version"
                        required
                    />
                    <label class="float-label">Version</label>
                    <small *ngIf="false">
                    *Version is required
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="date"
                        formControlName="date"
                        required
                    />
                    <label class="float-label">Date</label> 
                    <small *ngIf="false">
                    *Version is required
                    </small>
                </div>
            </div>
            <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
        </form>
    </div>

</div>
