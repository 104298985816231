<div class="modalDialog">
  <div class="re-modal-content">
      <div class="extrema">
        <p class="modal-header">Companies that have access</p>
          <em class="icon-close" (click)="close()"></em>
      </div>

            <p class="title-text">
              An overview of the shared assessment - it is possible to see which companies have access, who has accepted the invitation, and when it is shared. To see which life cycle stages have been shared, to stop sharing and resend the invitation, please click on the arrow.
            </p>
            <hr>
            <div class="main-content">
              <div class="project-details-container p-1">
            <table class="table">
              <tr class="header-row">
                <th id="" class="header-tr">Shared with</th>
                <th id="" class="header-tr">Status</th>
                <th id="" class="header-tr">Shared date</th>
                <th id="" class="header-tr">&nbsp;</th>
              </tr>
              <tr class="table-row-not-expanded" *ngFor="let invitation of invitations; let i = index">
                <td colspan="3">
                <div class="move-folder-container">
                  <table class="table-inside">
                    <tr>
                      <td class="company-name company-name-text">{{invitation?.companyName}}</td>
                      <td class="status-td status-td-text"> <span class="green-dot"></span>&nbsp; {{invitation?.status}}</td>
                      <td class="company-name-text">{{invitation?.invitationDate | date:'MMMM dd, yyyy'}}</td>
                      <td class="move-folder-dropdown arrow-td" data-toggle="collapse" [attr.data-target]="'#collapse-move-company-' + invitation.id" aria-expanded="false" aria-controls="collapse-move-company"><em class="icon-arrow-down"></em></td>
                   </tr>
                  </table>

                  <div [id]="'collapse-move-company-' + invitation?.id" class="collapse mvf-dropdown no-bb">
                    <hr>
                    <table class="table-inside">
                      <tr>
                        <td class="company-name header-tr">Shared life cycle stages</td>
                        <td class="status-td header-tr">Assessment results</td>
                        <td></td>
                      </tr>
                      <tr *ngFor="let lifeCycle of invitation?.showInvtationLifeCycleDtos">
                        <td class="company-name blue-td">{{lifeCycle?.cycleName}}</td>
                        <td class="status-td blue-td">{{lifeCycle?.impact | number: '1.0-2'}} kg CO<sub>2</sub> eq</td>
                        <td></td>
                      </tr>

                    </table>
                    <hr>
                    <table class="table-inside">
                      <tr>
                        <td class="company-name blue-td-big">RESEND INVITATION</td>
                        <td class="status-td header-tr"></td>
                        <td></td>
                    </table>
                    <hr>
                    <table class="table-inside" *ngIf="false">
                      <tr>
                        <td class="company-name red-big-text">STOP SHARING</td>
                        <td class="status-td header-tr"></td>
                        <td></td>
                    </table>
                  </div>
              </div>
            </td>
              </tr>
            </table>
          </div>
      </div>
      <div class="extrema"></div>
  </div>
</div>
