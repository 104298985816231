import { Component, OnInit } from "@angular/core";
import { ExchangeService } from "src/app/services/exchange.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ExchangeComponentDto,
  UpdateStatusVoDto,
} from "src/app/models/dtos.model";
import { ComponentLogService } from "src/app/services/component-log.service";
import { ComponentLog } from "src/app/models/component-log";
import { StatusService } from "src/app/services/status.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-exchange-panel",
  templateUrl: "./exchange-panel.component.html",
  styleUrls: ["./exchange-panel.component.css"],
})
export class ExchangePanelComponent implements OnInit {
  public searchTerm: string = "";
  public companyId: number;
  public exchanges: ExchangeComponentDto[];
  public voStatus = [
    "In Vo warehouse",
    "Received on vessel",
    "Stocked on vessel",
    "In use",
    "Return for service",
  ];
  constructor(
    private exchangeService: ExchangeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private componentLogService: ComponentLogService,
    private statusService: StatusService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.fetchExchangeList();
  }

  public fetchExchangeList(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.companyId = params["companyId"];
      this.exchangeService.getExhangeList(this.companyId).subscribe((res) => {
        this.exchanges = res;
      });
    });
  }

  public showComponents(): void {
    this.router.navigate(["/homeServiceProvider", this.companyId]);
  }

  public recieve(componentId, status): void {
    if (this.voStatus.indexOf(status) != -1) {
      let componentLog: ComponentLog = {
        id: 0,
        componentId: Number(componentId),
        userId: Number(localStorage.getItem("user_id")),
        createdAt: new Date(),
        action:
          "An unregistered return component has been marked as received by the supplier",
        componentLogFiles: [],
      };
      this.componentLogService
        .addComponentLog(componentLog)
        .subscribe((log) => {
          let status = new UpdateStatusVoDto();
          status.componentId = componentId;
          status.currentStatus = "Received by supplier";

          this.statusService.receiveComponent(status).subscribe((res) => {
            this.ngOnInit();
          });
        });
    } else {
      this.toastr.error(
        "Component must be in Vessel Owner possesion",
        "Status"
      );
    }
  }
}
