import { Company } from "./company.model";

export class Vessel {
  public constructor(init?: Partial<Vessel>) {
    Object.assign(this, init);
  }
  public id: number;
  public imo: number;
  public mmsi: number;
  public vesselName: string;
  public vesselType: string;
  public description: string;
  public vesselPicture: string;
  public companyId: number;
  public company: Company;
  public locations: Location[];
}
