import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Warehouse } from "src/app/models/warehouse";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { ActivatedRoute } from "@angular/router";
import { Vessel } from "src/app/models/vesset";
import { VesselService } from "src/app/services/vessel.service";

@Component({
  selector: "app-edit-vessel",
  templateUrl: "./edit-vessel.component.html",
  styleUrls: ["./edit-vessel.component.css"],
})
export class EditVesselComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();

  @Input() public showForm: boolean;
  @Input() public vesselToEdit: Vessel;
  @Input() public companyId: number;

  public submitted = false;
  public vesselToCreate: FormGroup;
  public action: string = "Add";
  public selected: string = "";
  public vesselImage: string = "Choose an image or drag it here";
  constructor(
    private fb: FormBuilder,
    private uploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private vesselService: VesselService
  ) {}

  ngOnInit(): void {
    if (this.vesselToEdit != null) {
      this.action = "Edit";
    } else {
      this.action = "Add";
    }
    this.activatedRoute.parent.params.subscribe((params) => {
      this.vesselToCreate = this.fb.group({
        imo: [this.vesselToEdit?.imo, Validators.required],
        mmsi: [this.vesselToEdit?.mmsi, Validators.required],
        vesselName: [this.vesselToEdit?.vesselName, Validators.required],
        vesselType: [this.vesselToEdit?.vesselType, Validators.required],
        description: [this.vesselToEdit?.description],
        vesselPicture: [this.vesselToEdit?.vesselPicture],
        companyId: [this.companyId],
        type: [""],
        locations: this.fb.array([]),
      });
    });
  }

  public makeSelection(): void {
    this.selected = this.vesselToCreate.controls.type.value;
  }

  public reset() {
    this.ngOnInit();
  }

  public updateVessel() {
    this.submitted = true;
    if (this.vesselToCreate.invalid) {
      return;
    }

    if (this.vesselToEdit != null) {
      let vessel = new Vessel(this.vesselToCreate.value);
      vessel.id = this.vesselToEdit.id;
      this.vesselService.updateVessel(vessel).subscribe((res) => {
        this.hideModal();
      });
    } else {
      let vessel = new Vessel(this.vesselToCreate.value);
      this.vesselService.addVessel(vessel).subscribe((res) => {
        this.hideModal();
      });
    }
  }

  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }

  public deleteVessel() {
    this.vesselService.deleteVessel(this.vesselToEdit.id).subscribe((res) => {
      this.hideModal();
    });
  }

  get frm() {
    return this.vesselToCreate.controls;
  }

  public uploadFile(files) {
    this.vesselImage = files[0].name;
    this.uploadService.uploadFile(files[0], 0).subscribe((res) => {
      const uploadfile = new UploadPhotoModel(res);
      this.vesselToCreate.patchValue({
        vesselPicture: uploadfile.fileName,
      });
    });
  }

  public onIMOChange() {
    //load vessel data from external API
  }
}
