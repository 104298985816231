import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ComponentCoreInformationService } from "../services/component-core-information.service";
import { ComponentOverviewDto } from "../models/dtos.model";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-vessel-operator-space",
  templateUrl: "./vessel-operator-space.component.html",
  styleUrls: ["./vessel-operator-space.component.css"],
})
export class VesselOperatorSpaceComponent implements OnInit {
  public componentList: ComponentOverviewDto[];
  public companyId: number;
  public showNotification: boolean = false;


  constructor(
    private toastr: ToastrService,
    private componentCoreInfomationService: ComponentCoreInformationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private router: Router, 
    private userService:UserService
  ) { }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(res=>{
      this.activatedRoute.params.subscribe((params) => {
        if (Number(params["companyId"]) != res.companyId || res.type == "Vessel Owner") {
          this.userService.logoutUser(res.userId).subscribe(user=>{
            localStorage.clear();
            this.router.navigate(["/login"]);
          });
        }
        else{
          localStorage.setItem("user_id", res.userId);
          localStorage.setItem("user_role", res.role);
          localStorage.setItem("company_type", res.type);
          localStorage.setItem("company_id", res.companyId);
          localStorage.setItem("permission", res.permission);
          localStorage.setItem("checkedUpdates", res.acceptUpdates);
        }
      });
    },err=>{
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    /*this.activatedRoute.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.componentCoreInfomationService
        .getUnassinedComponents(this.companyId)
        .subscribe((res) => {
          this.componentList = res;
          if (this.componentList.length > 0) {
            this.showNotification = true;
            
          }
        });
      setTimeout(() => {
        this.showNotification = false;
      },
        8000);
    });*/
  }
  public dismiss(dismissed) {
    if (dismissed) {
      this.showNotification = false;
    }
  }

}
