<div class="modals">
    <div class="main-modal-content">
        <div class="row">
            <div class="col-12  centered">
                <label class="form-title ">GETTING STARTED</label>
            </div>
        </div>
        <div class="row">
            <p>
                Welcome to our testing environment of our upcoming life cycle screening tool of products and systems. We sincerely thank you for testing our prototype 
                and value any feedback on the screening tool. The screening tool is a prototype, and graphical elements are not yet added hence you will be testing core 
                functionalities.</p>    
            
                <div class="custom-br"> </div>  
                <p>
                The life cycle screening consists of 4 steps: screening setup, system definition, screening inventory, and screening summary. The screening setup is where 
                your preferred product or system is identified and detailed through its specifications, its related information, and its core functionalities. The system definition 
                is where you choose all relevant life cycle stages of your product or system to be included in the life cycle screening. The screening inventory is where you 
                choose all quantitively relevant materials and processes for your product or system related to each individual life cycle stage that were chosen in the system 
                definition. In this section, technical data on your product or system may be needed for further approach. The screening summary is where you approve the
                provided information on your product in the screening setup, the screening system definition, and the screening inventory, and now you will be ready to 
                calculate your life cycle carbon footprint including total carbon footprint, most impacting life cycle stage, and most impacting life cycle parameter. If circular 
                strategies are applied the reduction potential for the carbon footprint is also calculated and provided. 
            </p>
        </div>
        <div class="custom-br"> </div>  
        <div class="row">
            <div class="col-12  centered">
                <label class="form-title ">The concept behind ReQUIT</label>
            </div>
        </div>
        <div class="row">
            <p>
                The concept behind this life cycle screening tool is to provide a fast and simple solution for industries to assess their carbon footprint and potential reductions from 
                circular activities through quantification and utilization of potential climate impact reductions hence the name ReFlow Quantification, Utilization, and Impact-reduction 
                Tool (ReQUIT). To accelerate industries in the green transition towards a cleaner future, ReQUIT enables industry stakeholders to quickly perform initial investigations 
                of their products or systems to identify hotspots and to target climate mitigation needs. The tool is developed from several years of engineering consultancy identifying 
                the need for easy and fast carbon footprint assessments. ReQUIT is build upon the ISO 14040 and ISO 14044, and the ILCD Framework to tie it into good practice assessments
                supporting science-based evaluation. ReQUIT relies on the largest and most advanced life cycle databases for background data modelling, and the software is updated regularly 
                to meet the highest standards in life cycle modelling.
            </p>
        </div>
        <div class="custom-br"> </div> 
    </div> 
   <div class="main-modal-footer">
    <button class="small-turq" (click)="cancel($event)">
        <label class="form-title" >
            START
        </label>
      </button>
   </div>
</div>
<div class="overlay"></div>
