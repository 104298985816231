import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CompanyAssessmentService } from '../../../../services/company-assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import { ScreeningService } from 'src/app/services/screening.service';
@Component({
  selector: 'app-result-company-asssessment',
  templateUrl: './result-company-asssessment.component.html',
  styleUrls: ['./result-company-asssessment.component.scss']
})
export class ResultCompanyAsssessmentComponent implements OnInit {

  public barchartData={
      "1.1 - Fuel Stationary Source":"1",
      "1.2 - Refrigerant Leaks":"1",
      "1.3 - Company Vehicles":"1",
      "2 - Purchased Electricity, Heat, Steam & Cooling":"2",
      "3.3.1 - Fuel- and Energy-Related Activities - WTT":"3",
      "3.5.2 - Water Generated in Operations":"3",
      "3.1 - Purchased Goods and Services - Material Use":"3",
      "3.5.1 - Waste Generated in Operations":"3",
      "3.6.1 - Flight and Accommodation":"3",
      "3.6 - Business Travel":"3",
      "3.4 - Upstream Transportation and Distribution - Freighting Goods":"3",
      "3.7 - Employee Commuting":"3",
      "3.16 - Other Non-official Categories - Home Office":"3",
  }
  public chart: any;
  public companyAssessmentId:any=null;
  public companyAssessmentResult:any;
  public dataLoaded:Boolean=false;
  isHovered : boolean = false;
  constructor(
    private companyAssessmentService:CompanyAssessmentService,
    private screeningService: ScreeningService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.companyAssessmentId = this.route.snapshot.paramMap.get('companyAssesssmentId');
    this.companyAssessmentService.getCompanyAssessmentResult(this.companyAssessmentId).subscribe(res=>{
      this.companyAssessmentResult=res;
      let ox:any[]=[];
      this.companyAssessmentResult['showCategoryImpactDtos'].forEach(element => {
        let x=element;
        x["scope"]=this.barchartData[element['category']]
        ox.push(x);
      });
      
      this.companyAssessmentResult['showCategoryImpactDtos']=ox.sort((a, b) => a.scope - b.scope);
      this.chart = new Chart('canvas', {
        type: 'doughnut',
        data: {
          labels: ["fzef","fz","ggr"],
          datasets: [
            { 
              data: [res.showScopeImpactDtos[0].impact,res.showScopeImpactDtos[1].impact,res.showScopeImpactDtos[2].impact],
              backgroundColor: ['#153C62','#77DDD9','#8CBBDF'],
              fill: false
            },
          ]
        },
        options: {
          aspectRatio: 1,
          legend: {
            display: false
          },
          tooltips:{
            enabled:false
          },
          plugins: {
            datalabels: {
              formatter: (value) => {
                return value + '%';
              },
            },
          },
        }
      });
      if(res.showScopeImpactDtos[0].impact+res.showScopeImpactDtos[1].impact+res.showScopeImpactDtos[2].impact==0.0){
        this.chart = new Chart('canvas', {
          type: 'doughnut',
          data: {
            labels: [""],
            datasets: [
              { 
                data: [1],
                backgroundColor: ['#cccdcf'],
                fill: false
              },
            ]
          },
          options: {
            aspectRatio: 1,
            legend: {
              display: false
            },
            tooltips:{
              enabled:false
            },
            plugins: {
              datalabels: {
                formatter: (value) => {
                  return value + '%';
                },
              },
            },
          }
        });
      }
  

    });
    
  }
  @ViewChild("divToCapture") divToCapture: ElementRef;
  public downloading=false;
  public downloadSummary(){
    this.downloading=true;
    html2canvas(this.divToCapture.nativeElement).then((canvas) => {
      const screenshotImageBase64 = canvas.toDataURL('image/png');
      this.companyAssessmentService.downloadAssessmentResults(this.companyAssessmentId,{exportedImage:screenshotImageBase64}).subscribe(ans=>{
        this.screeningService.DownloadFile(ans, "result summary.pdf", 'application/pdf');
        this.downloading=false;
      });
    });
  }
  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }
  public navigateHome(): void {
    let companyId: number = Number(localStorage.getItem("company_id"));
    this.router.navigate(["/homeServiceProvider", companyId, "company-assessment"]);
  }
}
