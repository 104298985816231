import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortby'
})
export class SortbyPipe implements PipeTransform {

  transform(array: any, property: string, args: string): any[] {
    if (!Array.isArray(array) || args == null) {
      return array;
    }
    if(args=='users'){
      array.sort((a: any, b: any) => {
        if (a[args].length > b[args].length) {
          return -1;
        } else if (a[args].length < b[args].length) {
          return 1;
        } else {
          return 0;
        }
      });
  
      return array;
    }
    array.sort((a: any, b: any) => {
      
      if (a[args] < b[args]) {
        return -1;
      } else if (a[args] > b[args]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}

