<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">External Companies</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
            name="searchTerm" />
        </div>
        <div class="col-md-4">
          <button class="btn-solid primary" (click)="selectedCompany=null;showEditModal(true)">
            <em class="fa fa-plus"></em>Add Company
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('companyName','')"><label style="margin-left: 10px;">Company</label></th>
        <th id="" (click)="orderBy('companyType','')">Role</th>
        <th id="" (click)="orderBy('country','')">Country</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let company of companies | filterPipe:searchTerm | sort:fieldName:attr"
        (click)="selectCompany(company)">
        <td style="font-weight: bold;">
          <img src="{{ company.companyLogo }}" alt="Avatar" />
          {{ company.companyName }}
        </td>
        <td>{{ company.companyType }}</td>
        <td>{{ company.country }}</td>
        <td>
          <em class="fa fa-bars" (click)="showDetailsModal()"></em>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-edit-external (toogleModal)="showEditModal($event)" [showForm]="showEditView" [companyToEdit]="selectedCompany"
  *ngIf="showEditView">
</app-edit-external>