<div class="modalDialog">
    <div class="rq-modal-content">
        <!-- <label class="pass-title">Are you sure you want to Pass the questionnaire</label> -->
        <label class="pass-title">{{ modalTitle }}</label>
        <p class="pass-description">This action cannot be undone.</p>
        <div class="pass-actions">
            <button class="confirm" >yes, sure</button>
            <button class="cancel" (click)="close()">no</button>
        </div>
    </div>
</div>