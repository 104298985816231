<div class="yardscore-shipyard-container">

    <div class="yss-sidemenu">
        <div class="yss-logo-container">
            <img class="yss-logo" src="../../assets/yardscore-assets/ys-full-logo.svg" alt="">
        </div>
        <div class="yss-menu-items-container">
            <div class="menu-list">
                <ng-container *ngFor="let item of menuItems;let idx=index">
                    <div 
                    class="menu-item" 
                    [routerLink]="[item?.route]" 
                    routerLinkActive="router-link-active"  
                    (mouseenter)="mouseEnter(item?.route)" 
                    (mouseleave)="mouseleave(item?.route)" 
                    (click)="selectTab(item,idx)"
                    [ngClass]="{'selected-tab': selectedTab==item}"
                    >
                        <img *ngIf="selectedTab!=item" src="../../assets/yardscore-assets/ys-i-{{item?.icon}}{{mouseOn==item?.route?'-dark':''}}.svg" alt="" class="yss-mi-icon">
                        <img *ngIf="selectedTab==item" src="../../assets/yardscore-assets/ys-i-{{item?.icon}}-dark.svg" alt="" class="yss-mi-icon">
                        <label class="yss-mi-label">{{item?.name}}</label>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="menu-list logout">
            <div class="menu-item" (mouseenter)="mouseEnter('logout')" (mouseleave)="mouseleave('logout')">
                <img src="../../assets/yardscore-assets/ys-i-logout{{mouseOn=='logout'?'-dark':''}}.svg" alt="" class="yss-mi-icon">
                <label class="yss-mi-label">Logout</label>
            </div>
        </div>
    </div>
    <div class="yss-page-content">
        <div class="yss-content-header">
            <div class="title-container">
                <label class="page-title">{{selectedTab?.name}}</label>
                <label class="page-subtitle">Home</label>
            </div>
            <div class="tools-container">
                <img src="../../assets/yardscore-assets/ys-i-mail.svg" alt="" class="tool-icon">
                <img src="../../assets/yardscore-assets/ys-i-notification.svg" alt="" class="tool-icon">
                <img src="../../assets/yardscore-assets/ys-profile-picture.svg" alt="" class="profile-image">
            </div>
        </div>
        <div class="yss-main-content">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>