import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompareService {
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getProofOfCompare(screeningsToCompare: number [],fileName:any){
    return this.http.post(`${this.environmentUrl}/api/compare/downloadProof`,screeningsToCompare,
     {
       responseType: 'blob',
       headers: new HttpHeaders({
         Authorization: "Bearer " + localStorage.getItem("access_token"),
         "Content-Type": "application/json",
       })
     })
  }

  public getCompareResult(screeningsToCompare: number []): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/compare/result`,
      screeningsToCompare,
      this.helperService.headers()
    );
  }

  public getComparisonHistory(companyId: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/compare/history/`+companyId,
      this.helperService.headers()
    );
  }
}
