import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-end-group',
  templateUrl: './edit-end-group.component.html',
  styleUrls: ['./edit-end-group.component.scss']
})
export class EditEndGroupComponent implements OnInit {

  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentEndGrp:any;
  @Output() closeModal=new EventEmitter<any>();
  public endGroupToEdit: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.endGroupToEdit = this.fb.group({
      id:[this.currentEndGrp?this.currentEndGrp.id:""],
      endGrpName:[this.currentEndGrp?this.currentEndGrp.endGrpName:""],
      endGrpUnit:[this.currentEndGrp?this.currentEndGrp.endGrpUnit:""],
      endGrpEmission:[this.currentEndGrp?this.currentEndGrp.endGrpEmission:""],
      endGrpDescription:[this.currentEndGrp?this.currentEndGrp.endGrpDescription:""],
      endGrphasProcess:[this.currentEndGrp?this.currentEndGrp.endGrphasProcess:"Yes"],
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public upsert(){
    
    var eg=this.endGroupToEdit.value;
    if(!this.currentEndGrp){
      eg.id=Date.now();
      //store database
    }else{
      //update database
    }
    this.closeModal.emit(eg);
  }

  public toggleProcess(verdict){
      if(verdict==false){
        this.endGroupToEdit.patchValue(
            {endGrpEmission:null,endGrpDescription:null,endGrpUnit:null}
          );
        
      }
  }
}
