import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.scss']
})
export class VersionHistoryComponent implements OnInit {

  @Output() toggleHistoryModal:any= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public close(){
    this.toggleHistoryModal.emit(false);
  }

}
