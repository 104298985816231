<div class="acad-container">
    <div class="banner" >
        <div class="ban-flower">
        </div>
        <img class="ban-kia" src="../../../../assets/academy/Hue_Saturation 1.png" alt="">
        <img class="logo" src="../../../../assets/academy/Asset 13.svg" alt="">
    </div>
    <div class="row filters d-flex p-relative">
        <div *ngIf="false" class="filter-btn d-flex" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="icon-filter"></em>
            <label>Filter</label>
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
            <a class="dropdown-item" href="#">Most recent</a>
            <a class="dropdown-item" href="#">Rating</a>
            <a class="dropdown-item" href="#">Under an hour</a>
            <a class="dropdown-item" href="#">Something else?</a>
        </div>
        <div class="input-group search-bar">
            <span class="input-group-append">
                <button class="btn border-right-0 border left-bounded p-0" type="button">
                    <em class="icon-search"></em>
                </button>
            </span>
            <input class="form-control py-2 border-left-0 border right-bounded" type="search" placeholder="Search" [(ngModel)]="searchText">
        </div>
    </div>
    <div class="play-all-container" *ngIf="false">
        <label class="play-all">play all</label>
        <em class="icon-arrow-right"></em>
    </div>
    <div class="list" *ngFor="let categoryVideos of playList">
        <label class="title">{{categoryVideos.category}}</label>
        <div class="owl-carousel owl-theme">
            <ng-container *ngFor="let video of categoryVideos.videos | filterPipe:searchText">
                <div class="card-container"  (click)="cardClick(video)">
                    <div class="cover-container">
                        <img [src]="video?.coverPath" alt="" class="cover"
                        onerror="this.onerror=null;this.src='../../../../../assets/academy/Asset 13.svg';"
                        >
                        <label class="play">
                            Play
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </label>
                    </div>
                    <label class="video-title" >{{video?.title}}</label>
                </div>
        </ng-container>
        </div>
    </div>
</div>
