<div class="qm-container">
    <div class="qm-overview-container">
        <div class="header-container">
            <label class="header-item w-30">Questionnaire</label>
            <label class="header-item w-20">Date Submitted</label>
            <label class="header-item w-15">Status</label>
            <label class="header-item w-15">Progress</label>
            <label class="header-item w-20">&nbsp;</label>
        </div>
        <div class="details-container">
            <div class="questionnaire-list">
                <div class="questionnaire-item">
                    <label class="data-label w-30">Questionnaire 1.0</label>
                    <label class="data-label w-20">20 / 01 / 2024</label>
                    <div class="tag-container w-15">
                        <div class="tag blue-tag">
                            <label for="">In Progress</label>
                        </div>
                    </div>
                    <div class="progress-container w-15">
                        <div role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="50" style="--value:75;float: left;"></div>
                    </div>
                    <div class="tag-container tag-right w-20">
                        <div class="tag grey-tag pointer" (click)="canShowHistory=true">
                            <img src="../../../../assets/yardscore-assets/ys-i-history.svg" alt="">
                            <label for="">Version History</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="categories-stats-container">
                <ng-container *ngFor="let item of categories; let idx=index">
                    <div class="category-item" [ngStyle]="{'margin-top':idx>1?'20px':''}">
                        <div class="info-container">
                            <label class="info">{{item.title}}</label>
                            <label class="info">({{item.size}})</label>
                        </div>
                        <div class="progress">
                            <div class="progress-indocator" [ngStyle]="{'background-color':item.color,'width':item.size}"></div>
                        </div>
                        <div class="end-container">
                            <button class="choose-button">Choose</button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="submit-container">
                <button class="submit-questionnaire" (click)="canShowSubmit=true">submit questionnaire</button>
            </div>
        </div>
    </div>
    
    <div class="faq-container">
        <div class="faq-title">
            <label>Frequently asked questions</label>
        </div>
        <div class="faq-content">
            <div *ngFor="let question of questions; let i = index" class="question-block">
                <div class="question-title" (click)="toggleCollapse(i)">
                    <label>{{ question }}</label>
                    <img src="../../../../assets/yardscore-assets/ys-i-plus.svg" *ngIf="isCollapsed[i]" class="region-icon" alt="Expand">
                    <img src="../../../../assets/yardscore-assets/ys-i-close.svg" *ngIf="!isCollapsed[i]" class="region-icon" alt="Collapse">
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[i]" class="question-answer">
                    <p>Scores can refer to various measurements or assessments related to the performance, quality, or capabilities of a shipyard.</p>
                </div>
            </div>
        </div>
    </div>
    
     
    
</div>

<app-submit-questionnaire *ngIf="canShowSubmit" (toggleSubmitModal)="toggleSubmitModal($event)"></app-submit-questionnaire>
<app-version-history *ngIf="canShowHistory" (toggleHistoryModal)="toggleHistoryModal($event)"></app-version-history>