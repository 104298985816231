import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateScreeningDto } from '../models/dtos.model';
import { LifeCycle } from '../models/life-cycle.model';
import { Screening, ScreeningLog } from '../models/screening.model';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ScreeningService {
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public createEmptyScreening(createScreeningDto: CreateScreeningDto): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/screenings/newScreening`,
      createScreeningDto,
      this.helperService.headers()
    );
  }

  public addSharedScreening(importDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/screenings/import`,
      importDto,
      this.helperService.headers()
    );
  }

  public updateScopeState(updateScopeDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/screenings/updateScope`,
      updateScopeDto,
      this.helperService.headers()
    );
  }

  public activateShare(reflowId: String): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/share/` + reflowId,
      this.helperService.headers()
    );
  }

  public getScreeningInvetory(screeningId: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/getinventory/` + screeningId,
      this.helperService.headers()
    );
  }

  public copyScreening(screeningId: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/duplicate/` + screeningId,
      this.helperService.headers()
    );
  }

  public getScreeningResult(screeningId: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/screeningResult/` + screeningId,
      this.helperService.headers()
    );
  }

  public createScreeningLog(screeningLog): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/screenings/addScreeningLog`,
      screeningLog,
      this.helperService.headers()
    );
  }

  public deleteScreening(screeningId: number): any {
    return this.http.delete(`${this.environmentUrl}/api/screenings/deleteScreening/` + screeningId, this.helperService.headers());
  }

  public stopSharingScreening(screeningId: number): any {
    return this.http.delete(`${this.environmentUrl}/api/screenings/stopSharing/` + screeningId, this.helperService.headers());
  }

  public deleteSharedScreening(screeningId: number): any {
    return this.http.delete(`${this.environmentUrl}/api/screenings/deleteSharedScreening/` + screeningId, this.helperService.headers());
  }

  public getScreeningByCompanyId(companyId: number) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/company/` + companyId,
      this.helperService.headers()
    );
  }

  public getCompletedScreeningByCompanyId(companyId: number) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/company/` + companyId,
      this.helperService.headers()
    );
  }

  public getSharedScreeningByCompanyId(companyId: number) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/shared/` + companyId,
      this.helperService.headers()
    );
  }

  public updateState(screeningId: number, newState: string) {
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/` + screeningId + `/` + newState,
      this.helperService.headers()
    );
  }

  public getScreeningById(screeningId: number) {

    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/` + screeningId,
      this.helperService.headers()
    );
  }

  public getScreeningLogById(screeningId: number) {

    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/history/` + screeningId,
      this.helperService.headers()
    );
  }

  public getReviewById(screeningId: number) {

    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/review/` + screeningId,
      this.helperService.headers()
    );
  }

  public getInventoryById(screeningId: number) {

    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/inventory/` + screeningId,
      this.helperService.headers()
    );
  }

  public calculateScreeningResult(screeningId: number) {
    let tokenHeader = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    });
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/calculateScreen/` + screeningId,
      { headers: tokenHeader }
    );
  }

  public getScreeningSheet(group: string) {

    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/mastersheet/` + group,
      this.helperService.headers()
    );
  }

  public updateScreeningProduct(screening: Screening) {

    return this.http.put<any>(
      `${this.environmentUrl}/api/screenings/product/` + screening.id,
      screening,
      this.helperService.headers()
    );
  }

  public updateScreeningLifeCycle(screeningId: number, lifeCycles: LifeCycle[]) {

    return this.http.put<any>(
      `${this.environmentUrl}/api/screenings/lifecycle/` + screeningId,
      lifeCycles,
      this.helperService.headers()
    );
  }

  public updateLifeCycle(lifeCycleId: number, lifeCycle: LifeCycle) {

    return this.http.put<any>(
      `${this.environmentUrl}/api/screenings/updateLifeCycle/` + lifeCycleId,
      lifeCycle,
      this.helperService.headers()
    );
  }

  public getExcelResult(screeningId: number,fileName:any) {
    return this.http.get(`${this.environmentUrl}/api/screenings/downloadExcel/` + screeningId,
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        })
      })
  }

  public getProofOfAssessment(screeningId: number,fileName:any){
     return this.http.get(`${this.environmentUrl}/api/screenings/downloadAssessment/` + screeningId,
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        })
      })
  }

  public getScopesResult(screeningId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/screenings/screeningScopes/` + screeningId,
      this.helperService.headers()
    );
  }

  public DownloadFile(data: any, filename: string, mime: string): void {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were 
      // revoked by closing the blob for which they were created. 
      // These URLs will no longer resolve as the data backing 
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      tempLink.setAttribute('target', '_blank');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }

  public archiveScreening(sid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/screenings/archive/${sid}`,
      this.helperService.headers()
      )
  }
  public restoreScreening(sid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/screenings/restore/${sid}`,
      this.helperService.headers()
      )
  }
}
