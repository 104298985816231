import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-show-company",
  templateUrl: "./show-company.component.html",
  styleUrls: ["./show-company.component.css"],
})
export class ShowCompanyComponent implements OnInit {
  public contextUser: boolean = true;
  public currentCompany: Company;
  public showAddForm: boolean = false;
  public showEditCompanyForm: boolean = false;
  public showUserDetails: boolean = false;
  public companyId: number;
  public companyUsers: User[];
  public selectedUser: User = new User();
  public searchTerm: string;
  public fieldName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.companyId = params["id"];
      this.getCompanyInfo();
    });
  }

  private getCompanyInfo(): void {
    this.companyService
      .retrieveCustomerCompany(this.companyId)
      .subscribe((res) => {
        this.currentCompany = res;
        this.retriveUsers(this.companyId);
      });
  }

  private retriveUsers(companyId: number) {
    this.companyService.getCompanyUsers(companyId).subscribe((res) => {
      this.companyUsers = res;
    });
  }

  showUsers() {
    this.contextUser = true;
  }

  showComponent() {
    this.contextUser = false;
  }

  showAddUserForm(isShow: boolean) {
    this.showAddForm = isShow;
    this.retriveUsers(this.companyId);
  }

  showDetails(isShow: boolean, user: User) {
    this.showUserDetails = isShow;
    this.selectedUser = user;
    this.retriveUsers(this.companyId);
  }

  showEditCompForm(isShow: boolean) {
    this.showEditCompanyForm = isShow;
  }

  orderBy(field) {
    this.fieldName = field;
  }
}
