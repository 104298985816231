<div class="r-q-view-container">
    
    <div class="r-q-navbar">
            <div class="navbar-item "
            [ngClass]="{'active-nav-item': activeItem === 'Environment and climate'}"
            (click)="setActiveItem('Environment and climate')"
            >
                <label for="" class="">Environment and climate</label> 
            </div>
            <div  class="navbar-item" 
            [ngClass]="{'active-nav-item': activeItem === 'Social Responsibility'}"
            (click)="setActiveItem('Social Responsibility')"
            
            >
                <label for="">Social Responsibility</label>
            </div>
            <div
            class="navbar-item" 
            [ngClass]="{'active-nav-item': activeItem === 'Technical efficiency'}"
            (click)="setActiveItem('Technical efficiency')"
            
            >
               <label for="">Technical efficiency</label> 
            </div>
            <div class="navbar-item"
            [ngClass]="{'active-nav-item': activeItem === 'HSEQ professionality'}"
            (click)="setActiveItem('HSEQ professionality')"
            >
              <label for="">HSEQ professionality</label>  
            </div>
    </div>

    <div class="r-q-body-content">
        <div class="body-side-bar">
            <div class="side-bar-item"
            [ngClass]="{'side-bar-active-item': activeSidebarItem === 'Steel'}"
            (click)="setActiveSidebarItem('Steel')"
            >
            <img [src]="activeSidebarItem === 'Steel' ? '../../../assets/yardscore-assets/ys-i-checkbox-light.svg' : '../../../assets/yardscore-assets/ys-i-checkbox.svg'" alt="" class="check-box-icon">
                <label for="">Steel</label>
               
            </div>
            <div 
            class="side-bar-item" 
            [ngClass]="{'side-bar-active-item': activeSidebarItem === 'Fate of Materials'}"
            (click)="setActiveSidebarItem('Fate of Materials')"
            >
            <img [src]="activeSidebarItem === 'Fate of Materials' ? '../../../assets/yardscore-assets/ys-i-checkbox-light.svg' : '../../../assets/yardscore-assets/ys-i-checkbox.svg'" alt="" class="check-box-icon">
                <label for="">Fate of Materials</label>
                
            </div>
            <div 
            class="side-bar-item" 
            [ngClass]="{'side-bar-active-item': activeSidebarItem === 'Environmental protection'}"
            (click)="setActiveSidebarItem('Environmental protection')"
            >
            <img [src]="activeSidebarItem === 'Environmental protection' ? '../../../assets/yardscore-assets/ys-i-checkbox-light.svg' : '../../../assets/yardscore-assets/ys-i-checkbox.svg'" alt="" class="check-box-icon">
                <label for="">Environmental protection</label>
                
            </div>
        </div>
        <div class="body-content-block">
            <label for="" class="small-title">All Questions</label>
            <label for="" class="big-title">Steel recycling process</label>
            <div class="question-block-container">
                <div class="question-block">
                    <label for="" class="question-title">Q1.  How you calculate the ferrous carbon emissions?</label>

                    <div class="ans-block">
                        <label for="" class="ans-title">Ans.</label>
                        <div class="ans-input">
                            <label for="" class="ans-input-text">50%</label>
                        </div>   
                    </div>
                    <div class="distribution-block">
                        <label for="" class="distribution-title">Distribution</label>
                        <div class="distribution-details">
                            <div class="d-inputs-block">
                                <div class="d-input-items">
                                    <div class="d-input-text">
                                        <label for="" class="ans-input-text">Open hearth furnace</label>
                                    </div>
                                    <div class="d-input-percentage">
                                        <label for="" class="ans-input-text">10%</label>
                                    </div>  
                                </div>
                                <div class="d-input-score">
                                    <label for="" class="score-text">Score : X</label>
                                </div>
                            
                            </div>
                            <div class="d-inputs-block">
                                <div class="d-input-items">
                                    <div class="d-input-text">
                                        <label for="" class="ans-input-text">Basic oxygen furnace</label>
                                    </div>
                                    <div class="d-input-percentage">
                                        <label for="" class="ans-input-text">40%</label>
                                    </div>  
                                </div>
                                <div class="d-input-score">
                                    <label for="" class="score-text">Score : X</label>
                                </div>
                            
                            </div>
                        </div>   
                    </div>

                    <div class="total-score-block">

                        <div class="total-score-text">
                            <div class="total-score-result-box">
                                <label for="" class="total-score-result-text">Total score : 3.4</label>
                            </div>

                        </div>
                        <div class="total-score-buttons">
                            <div class="valid-button" (click)="openModal('Are you sure you want to validate this answer?')">
                                <label for="" class="btn-text">Valid</label>
                            </div>
                            <div class="invalid-button" (click)="openModal('Are you sure you want to fail this answer?')">
                                <label for="" class="btn-text">Not Valid</label>
                            </div>
                        </div>

                    </div>
                </div>

                

            </div>
            <div class="question-block-container">
                <div class="question-block">
                    <label for="" class="question-title">Q2.  Lorem islem the next question</label>

                    <div class="ans-block">
                        <label for="" class="ans-title">Ans.</label>
                        <div class="ans-input">
                            <label for="" class="ans-input-text">10%</label>
                        </div>   
                    </div>
                    <div class="distribution-block">
                        <label for="" class="distribution-title">Distribution</label>
                        <div class="distribution-details">
                            <div class="d-inputs-block">
                                <div class="d-input-items">
                                    <div class="d-input-text">
                                        <label for="" class="ans-input-text">Open hearth furnace</label>
                                    </div>
                                    <div class="d-input-percentage">
                                        <label for="" class="ans-input-text">10%</label>
                                    </div>  
                                </div>
                                <div class="d-input-score">
                                    <label for="" class="score-text">Score : X</label>
                                </div>
                            
                            </div>
                            <div class="d-inputs-block">
                                <div class="d-input-items">
                                    <div class="d-input-text">
                                        <label for="" class="ans-input-text">Basic oxygen furnace</label>
                                    </div>
                                    <div class="d-input-percentage">
                                        <label for="" class="ans-input-text">40%</label>
                                    </div>  
                                </div>
                                <div class="d-input-score">
                                    <label for="" class="score-text">Score : X</label>
                                </div>
                            
                            </div>
                        </div>   
                    </div>

                    <div class="files-block">

                        <label for="" class="file-label">Files</label>
                        <div class="files-content">
                            <div class="file-items-block">
                                <div class="img-icon">
                                    <img src="../../../assets/yardscore-assets/ys-i-image.svg" alt="">
                                </div>
                                <div class="file-name">
                                    <label for="" class="file-name-title">File name</label>
                                    <div class="file-name-date-block">
                                        <label for="" class="file-name-date-txt">Added today</label>
                                        <img src="../../../assets/yardscore-assets/ys-i-info.svg" alt="" class="file-name-date-icon">
                                    </div>
                                </div>
                                <div class="resubmit-btn">

                                    <img src="../../../assets/yardscore-assets/ys-i-resubmit.svg" alt="" class="resubmit-icon">
                                    <label for="" class="resubmit-btn-txt">Resubmit</label>
                                    
                                </div>
                            </div>
                            <div class="file-items-block">
                                <div class="file-icon">
                                    <img src="../../../assets/yardscore-assets/ys-i-file.svg" alt="">
                                </div>
                                <div class="file-name">
                                    <label for="" class="file-name-title">File name</label>
                                    <div class="file-name-date-block">
                                        <label for="" class="file-name-date-txt">20 Feb 2024</label>
                                        <img src="../../../assets/yardscore-assets/ys-i-info.svg" alt="" class="file-name-date-icon">
                                    </div>
                                </div>
                                <div class="resubmit-btn">

                                    <img src="../../../assets/yardscore-assets/ys-i-resubmit.svg" alt="" class="resubmit-icon">
                                    <label for="" class="resubmit-btn-txt">Resubmit</label>
                                    
                                </div>
                            </div>
                
                        </div>

                    </div>

                    <div class="total-score-block">

                        <div class="total-score-text">
                            <div class="total-score-result-box">
                                <label for="" class="total-score-result-text">Total score : 3.4</label>
                            </div>

                        </div>
                        <div class="total-score-buttons">
                            <div class="valid-button" (click)="openModal('Are you sure you want to validate this answer?')">
                                <label for="" class="btn-text" >Valid</label>
                            </div>
                            <div class="invalid-button" (click)="openModal('Are you sure you want to fail this answer?')">
                                <label for="" class="btn-text">Not Valid</label>
                            </div>
                        </div>

                    </div>
                </div>

                

            </div>
        </div>
    </div>
 </div>


 <app-rq-pass-questionnaire *ngIf="showPassModal==true"   [modalTitle]="modalTitle"  (closePassModal)="closePassModal($event)"></app-rq-pass-questionnaire>