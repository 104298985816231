<div class="master-sheet-container">
    <div class="new-db-btn" (click)="showUpsertGroup(null)">
        <label > New Group</label>
    </div>
    <div class="records-list mt-4">
        <ng-container  *ngFor="let grp of groupsList; let i=index">
            <div class="record-item" >
                <div class="item-output">
                    <div class="item-content-o">{{grp.name}}</div>
                </div>
                <div class="item-operations" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <em class="icon-burger"></em>
                </div>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
                    <a class="dropdown-item "  [routerLink]="['../../sub-groups',grp.id]">Sub-groups</a>
                </div>
            </div>
        </ng-container>
    </div>
    
</div>

<div #modal class="sidebar move-to-left" id="sidebar" *ngIf="displayUpsertGroup">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create Database</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="groupToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="groupName"
                        required
                    />
                    <label class="float-label">Group name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            <br>
            <div class="submit-container">
                <button class="big-blue" (click)="save()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
        </form>
    </div>

</div>

