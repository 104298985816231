import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/yardscore-services/users.service';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { delay, distinctUntilChanged, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {

  public currentUser: any;
  public token: any;
  public userObtained:boolean=false;

  constructor(
    private userService: UserService,
    private auth: AuthService, 
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public navigateFromLogin(): void {
    //localStorage.setItem("access_token",this.currentUser.token);
    localStorage.setItem("companyId",this.currentUser.companyId);
    localStorage.removeItem("isRedirecting");
    this.router.navigate([this.currentUser.redirectTo]);
  }
  isLoading = true;
  isRedirecting = false;


  ngOnInit(): void {
    let attempts=0
    if(localStorage.hasOwnProperty("ulattempt")==false){
      localStorage.setItem("ulattempt","1");
    }
    attempts=parseInt(localStorage.getItem("ulattempt")+"");
    if(attempts>2){
      localStorage.removeItem("ulattempt");
      this.router.navigate(['/login']);
      return
    }
    attempts+=1;
    localStorage.setItem("ulattempt",attempts+"");
    try {
      this.route.fragment.subscribe(fragment => {
        
        if (fragment) {
          const fragmentObject = this.parseFragment(fragment);
          if(fragmentObject['error']!== undefined){
            if(localStorage.getItem('access_token')==null||localStorage.getItem('access_token')==undefined){
              this.router.navigate(['/error']);
            }else{
              this.router.navigate(['/redirection']);
            }
          }else{
            this.doRedirection();
          }
        }else{
          this.doRedirection();
        }
      }); 
    } catch (error) {
      location.reload();
    }
    
  }

  public doRedirection(){
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.auth.getAccessTokenSilently().subscribe((claims) => {
          if (claims) {
            this.token = claims;
            localStorage.setItem('access_token',this.token);
            this.isLoading = false;
            this.userService.getCurrentUser().subscribe(user => {
              this.currentUser=user;
              this.navigateFromLogin();
            },err=>{
              location.reload();
            });
          }
        });
        
      } else {
        this.auth.getAccessTokenSilently().subscribe(
          (token) => {
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            if (error.error === 'login_required' || error.error === 'consent_required') {
              if (localStorage.getItem("isRedirecting")=="false") {
                localStorage.setItem("isRedirecting","true");
                
                this.auth.loginWithRedirect({
                  authorizationParams: {
                    connection: 'email', 
                    send: 'link',        
                  },
                  appState: { target: '/redirection' }
                });
              }
            }
          }
        );
      }
    });
  }

  public parseFragment(fragment) {  
    const fragmentString = fragment.startsWith('#') ? fragment.substring(1) : fragment;
    const pairs = fragmentString.split('&');
  
    const fragmentObject = {};
    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      fragmentObject[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });
  
    return fragmentObject;
  }

}


