export class ComponentFile {
  public constructor(init?: Partial<ComponentFile>) {
    Object.assign(this, init);
  }
  public id: number;
  public componentId: number;
  public comment: string;
  public fileType: string;
  public filename: string;
  public fileUrl: string;
  public isGeneral: boolean;
  public isShareable: boolean;
}
