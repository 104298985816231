import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-lifespan-modal',
  templateUrl: './lifespan-modal.component.html',
  styleUrls: ['./lifespan-modal.component.css']
})
export class LifespanModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() stage:any;
  @Input() public maxValue: number;
  @Input() public lifeSpanValue: number;
  public currentValue :Number =0;

  public yearValue: number = 0;
    
  constructor() { }

  ngOnInit(): void {
    this.currentValue=this.lifeSpanValue;
    this.yearValue=this.lifeSpanValue;
  }

  cancel($event) {
    this.close.emit(this.currentValue );
  }

  setYears(year)
  {
    this.yearValue = <number>year;
    //this.currentValue =Number(year);
  }

  public confirm(){
    this.close.emit(this.yearValue);
  }

}

