import { Component, OnInit } from "@angular/core";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ComponentOverviewDto,
  ComponentFilterDto,
} from "src/app/models/dtos.model";
import { FilterService } from "src/app/services/filter.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-component-overview",
  templateUrl: "./component-overview.component.html",
  styleUrls: ["./component-overview.component.scss"],
})
export class ComponentOverviewComponent implements OnInit {
  public showEditComponent: boolean = false;
  public showDetailComponent: boolean = false;
  public showStatusComponent: boolean = false;
  public hasAccess: boolean = false;
  public companyId: number;
  public componentId: number;
  public componentList: ComponentOverviewDto[];
  public allComponents: ComponentOverviewDto[];
  public currentComponent: any;
  public currentUser: User;
  public searchTerm: string = "";
  public fieldName: string;
  public attr: string;
  p: number = 1;
  public showFilter: boolean = false;
  public filters: ComponentFilterDto;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private componentCoreInfomationService: ComponentCoreInformationService,
    private filterService: FilterService,
    private userService:UserService
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(res=>{
        this.currentUser=res;
        if(this.currentUser.access.filter(x=>x.access=="Components management").length > 0 ){
          this.hasAccess=true;
          this.showComponentList();
        }
      });
    });
  }

  public showEditModal(isShow: boolean) {


    this.showEditComponent = isShow;
    this.showComponentList();

  }

  public showStatusModal(isShow: boolean, component: ComponentOverviewDto) {
    this.componentId = component?.id;
    this.showStatusComponent = isShow;
    this.showComponentList();
    this.currentComponent = component;
  }

  public showEmmiterStatusModal(event: any) {
    this.componentId = event.component.id;
    this.showStatusComponent = event.show;
    this.showComponentList();
    this.currentComponent = event.component;
  }

  public selectComponent(isShpow: boolean, component: ComponentOverviewDto) {
    this.componentId = component?.id;
    this.showDetailComponent = isShpow;
    this.showEditComponent = false;
    this.showComponentList();
    this.ngOnInit();
  }

  public showComponentList(): void {
    this.componentCoreInfomationService
      .getCompanyComponents(this.companyId)
      .subscribe((res) => {
        this.componentList = res;
        this.allComponents = res;
      });
  }

  public filterAllComponents(): void {
    this.filterService
      .componentFilter(this.filters, this.companyId)
      .subscribe((res) => {
        this.componentList = res;
      });
  }

  public clickComponent(component: any, event: any): void {

    if (event.target.tagName != "EM") {
      this.selectComponent(true, component);
    }
  }

  public greater(x, y) {
    return x > y;
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }

  public showExchange(): void {
    this.router.navigate([
      "/homeServiceProvider",
      this.companyId,
      "exchange-panel",
    ]);
  }

  public toogleFilter(event: any): void {
    if (event.hasOwnProperty("show") == false) {
      this.showFilter = event;
    } else {
      this.showFilter = event.show;
      this.filters = event.filter;
      this.filterAllComponents();
    }
  }
}
