import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Warehouse } from "src/app/models/warehouse";
import { WarehouseService } from "src/app/services/warehouse.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-edit-warehouse",
  templateUrl: "./edit-warehouse.component.html",
  styleUrls: ["./edit-warehouse.component.css"],
})
export class EditWarehouseComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();

  @Input() public showForm: boolean;
  @Input() public warehouseToEdit: Warehouse;

  public submitted = false;
  public warehouseToCreate: FormGroup;
  public action: string;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private warehouseService: WarehouseService
  ) {}

  ngOnInit(): void {
    if (this.warehouseToEdit != null) {
      this.action = "Edit";
    } else {
      this.action = "Add";
    }
    this.initForm();
  }

  public initForm() {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.warehouseToCreate = this.fb.group({
        warehouseName: [
          this.warehouseToEdit?.warehouseName,
          Validators.required,
        ],
        address1: [this.warehouseToEdit?.address1, Validators.required],
        address2: [this.warehouseToEdit?.address2],
        postalCode: [this.warehouseToEdit?.postalCode, Validators.required],
        city: [this.warehouseToEdit?.city, Validators.required],
        country: [
          this.warehouseToEdit?.country == null
            ? ""
            : this.warehouseToEdit?.country,
          Validators.required,
        ],
        companyId: Number(params["companyId"]),
        contactPerson: this.fb.group({
          fullName: [this.warehouseToEdit?.contactPerson.fullName],
          email: [this.warehouseToEdit?.contactPerson.email],
          phone: [this.warehouseToEdit?.contactPerson.phone],
        }),
      });
    });
  }
  get frm() {
    return this.warehouseToCreate.controls;
  }

  public hideModal() {
    this.showForm = false;
    this.warehouseToEdit = null;
    this.toogleModal.emit(this.showForm);
  }

  public updateWarehouse() {
    this.submitted = true;
    let warehouse: Warehouse = new Warehouse(this.warehouseToCreate.value);
    if (this.warehouseToEdit) {
      this.submitted = true;
      warehouse.id = this.warehouseToEdit.id;
      warehouse.contactPerson.id = this.warehouseToEdit.contactPerson.id;
      this.warehouseService.editWarehouse(warehouse).subscribe((res) => {
        this.ngOnInit();
        this.hideModal();
      });
    } else {
      if (this.warehouseToCreate.valid) {
        this.submitted = true;
        this.warehouseService.addWarehouse(warehouse).subscribe((res) => {
          this.ngOnInit();
          this.hideModal();
        });
      }
    }
  }

  public deleteWarehouse() {
    this.warehouseService
      .deleteWarehouse(this.warehouseToEdit.id)
      .subscribe((res) => {
        this.hideModal();
        this.ngOnInit();
      });
  }

  public reset() {
    this.initForm();
  }
}
