import { Component, OnInit, Input } from "@angular/core";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { ExternalCompanyService } from "src/app/services/external-company.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-external-companies-overview",
  templateUrl: "./external-companies-overview.component.html",
  styleUrls: ["./external-companies-overview.component.css"],
})
export class ExternalCompaniesOverviewComponent implements OnInit {
  public showEditView: boolean = false;
  public showCompanyDetails: boolean = false;
  public companies: Array<Company>;
  public selectedCompany: Company;
  public searchTerm: string = "";
  public fieldName: string;
  public attr: string

  constructor(
    private externalCompanyService: ExternalCompanyService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.retriveExternalCompanies();
  }

  public retriveExternalCompanies() {
    this.externalCompanyService.getExternalCompany().subscribe((res) => {
      this.companies = res;
    });
  }

  public showEditModal(isShow: boolean) {
    this.showEditView = isShow;
    this.showCompanyDetails = false;
    this.retriveExternalCompanies();
  }

  public showDetailsModal() {
    this.showCompanyDetails = true;
    this.showEditView = false;
  }

  public selectCompany(company: Company): void {
    this.selectedCompany = company;
    this.showEditModal(true);
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
