import { Component, OnInit,Input, SimpleChanges } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.model';
import { Screening } from 'src/app/models/screening.model';
import { ScreeningService } from 'src/app/services/screening.service';
import { ScreeningResult } from 'src/app/models/dtos.model';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { ShareService } from 'src/app/services/share.service';
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @Input() public item: any;
  @Input() public companiesModalEvent: boolean;
  public company: Company;
  public inventory: any;
  public showCompaniesModal: boolean = false;
  public screeningResult:ScreeningResult;
  public sharedScreeningOverview:any;
  constructor(private companyService: CompanyService,private screeningService: ScreeningService, private shareService: ShareService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.item!= undefined){
      this.getScreeningProduct();
      this.getCompanyInfo();
      this.getScreeningInfo();
      this.getInventory();
      if(this.item.shared==true)
      {
        this.shareService.getsharedScreeningOverview(this.item.id).subscribe(res=>{
          this.sharedScreeningOverview = res;
        });
      }
    }
    
  }

  public getScreeningProduct()
  {
    this.screeningService.getScreeningById(this.item.id).subscribe(res=>{
      this.item = res;
    });
  }

  public getCompanyInfo()
  {
    let companyId = Number(localStorage.getItem("company_id"));
    this.companyService.retrieveCustomerCompany(companyId).subscribe(resCompany => {
      this.company = resCompany;
  });
  }

  public getScreeningInfo()
  {
    this.screeningService.getScreeningResult(this.item.id).subscribe(res=>{
      this.screeningResult=res;
      
    });
  }

  public getInventory()
  {
    this.screeningService.getScreeningInvetory(this.item.id).subscribe(res=>{
      this.inventory=res;
    });
  }

  public companiesModalActions(event:any):void{
    if(event=="open-companies-modal"){
      this.showCompaniesModal=true;
    }
    else if(event=="close-companies-modal"){
      this.showCompaniesModal=false;
    }
  }

  ngOndestroy(){
    this.company = null;
    this.screeningResult = null;
  }


}
