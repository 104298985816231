<div class="modalDialog">
    <div class="re-modal-content" *ngIf="deletePhase==1">
        <label class="delete-title">Are you sure to Delete?</label>
        <p class="delete-description">Are you sure you want to delete the customer? <br>This action cannot be undone.</p>
        <div class="delete-actions">
            <button class="confirm" (click)="delete()">yes, sure</button>
            <button class="cancel" (click)="cancel()">no</button>
        </div>
    </div>
    <div class="re-modal-content" *ngIf="deletePhase==2">
        <label class="delete-title">Company deleted successfully</label>
        <p class="delete-description">Are you sure you want to delete the Company? <br>This action cannot be undone.</p>
        <div class="delete-actions">
            <button class="info-goback" (click)="backToCompaniesList()">go to updated Company list</button>
        </div>
    </div>
</div>