<div class="modals" >
    <div class="main-modal-content" *ngIf="isMovingProject==false">
        <div class="header">
            <em class="icon-close" (click)="close(false)"></em>
        </div>
        <div class="body">
            <img src="../../../../assets/move_filled.png" alt="">
            <label for="" class="title">MOVE PROJECT</label>
            <p>Are you sure you want to move this project to the selected folder?</p>
            <div class="actions-container">
                <button class="cancel" (click)="close(false)">Cancel</button>
                <button class="move rename-btn move-btn" (click)="moveToFolder()">Move</button>
            </div>
        </div>
    </div>
    <div class="main-modal-content loading" *ngIf="isMovingProject==true">
        <img src="../../../../assets/favicon.png" alt="">
    </div>
</div>
<div class="overlay"></div>
