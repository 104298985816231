import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-vessel-section",
  templateUrl: "./vessel-section.component.html",
  styleUrls: ["./vessel-section.component.scss"],
})
export class VesselSectionComponent implements OnInit {
  @Output() submitAge = new EventEmitter();
  @Output() submitArea = new EventEmitter();
  @Output() submitType = new EventEmitter();
  public vesselAge: number = 1;
  public ClickedbulkVessel: boolean = false;
  public ClickedcontainerVessel: boolean = false;
  public ClickedpassengerVessel: boolean = false;
  public ClickedtankerVessel: boolean = false;
  public HoverbulkVessel: boolean = false;
  public HovercontainerVessel: boolean = false;
  public HoverpassengerVessel: boolean = false;
  public HovertankerVessel: boolean = false;
  public vesselType: string = "";
  public vesselArea: string = "";
  public hullStructure: boolean = true;
  public auxilaritySystem: boolean = true;
  public electricSystem: boolean = true;
  public acommodation: boolean = true;
  public commSystem: boolean = true;
  public propulsionsSystems: boolean = true;
  constructor() {}

  ngOnInit(): void {}

  public showVesselAge(event): void {
    this.vesselAge = event.target.value;
    let result = (this.vesselAge / 40) * 100;
    event.target.style.background =
      "linear-gradient(to right, #123b63, #123b63 " +
      result +
      "%, #E8E8E8 15%)";
    this.submitAge.emit(this.vesselAge);
  }

  public vesselLeave(event): void {
    this.HoverbulkVessel = false;
    this.HovercontainerVessel = false;
    this.HoverpassengerVessel = false;
    this.HovertankerVessel = false;
    if (
      this.ClickedbulkVessel == true ||
      this.ClickedcontainerVessel == true ||
      this.ClickedpassengerVessel == true ||
      this.ClickedtankerVessel == true
    ) {
    }
  }

  public vesselHover(event): void {
    this.vesselType = event;
    if (this.vesselType == "Bulk vessel") {
      this.HoverbulkVessel = true;
      this.HovercontainerVessel = false;
      this.HoverpassengerVessel = false;
      this.HovertankerVessel = false;
    } else if (this.vesselType == "Container vessel") {
      this.HoverbulkVessel = false;
      this.HovercontainerVessel = true;
      this.HoverpassengerVessel = false;
      this.HovertankerVessel = false;
    } else if (this.vesselType == "Passenger vessel") {
      this.HoverbulkVessel = false;
      this.HovercontainerVessel = false;
      this.HoverpassengerVessel = true;
      this.HovertankerVessel = false;
    } else if (this.vesselType == "Tanker vessel") {
      this.HoverbulkVessel = false;
      this.HovercontainerVessel = false;
      this.HoverpassengerVessel = false;
      this.HovertankerVessel = true;
    }
  }

  public checkVessel(event, radio): void {
    radio.checked = true;
    this.vesselType = event;
    if (this.vesselType == "Bulk vessel") {
      this.ClickedbulkVessel = true;
      this.ClickedcontainerVessel = false;
      this.ClickedpassengerVessel = false;
      this.ClickedtankerVessel = false;
    } else if (this.vesselType == "Container vessel") {
      this.ClickedbulkVessel = false;
      this.ClickedcontainerVessel = true;
      this.ClickedpassengerVessel = false;
      this.ClickedtankerVessel = false;
    } else if (this.vesselType == "Passenger vessel") {
      this.ClickedbulkVessel = false;
      this.ClickedcontainerVessel = false;
      this.ClickedpassengerVessel = true;
      this.ClickedtankerVessel = false;
    } else if (this.vesselType == "Tanker vessel") {
      this.ClickedbulkVessel = false;
      this.ClickedcontainerVessel = false;
      this.ClickedpassengerVessel = false;
      this.ClickedtankerVessel = true;
    }
    this.submitType.emit(this.vesselType);
  }

  public chooseArea(area): void {
    this.vesselArea = area;
    if (area == "Hull structure") {
      this.hullStructure = true;
      this.auxilaritySystem = false;
      this.electricSystem = false;
      this.acommodation = false;
      this.commSystem = false;
      this.propulsionsSystems = false;
    } else if (area == "Auxiliary systems") {
      this.hullStructure = false;
      this.auxilaritySystem = true;
      this.electricSystem = false;
      this.acommodation = false;
      this.commSystem = false;
      this.propulsionsSystems = false;
    } else if (area == "Electric systems") {
      this.hullStructure = false;
      this.auxilaritySystem = false;
      this.electricSystem = true;
      this.acommodation = false;
      this.commSystem = false;
      this.propulsionsSystems = false;
    } else if (area == "Acommodation") {
      this.hullStructure = false;
      this.auxilaritySystem = false;
      this.electricSystem = false;
      this.acommodation = true;
      this.commSystem = false;
      this.propulsionsSystems = false;
    } else if (area == "Nav. & comm. system") {
      this.hullStructure = false;
      this.auxilaritySystem = false;
      this.electricSystem = false;
      this.acommodation = false;
      this.commSystem = true;
      this.propulsionsSystems = false;
    } else if (area == "Propulsions systems") {
      this.hullStructure = false;
      this.auxilaritySystem = false;
      this.electricSystem = false;
      this.acommodation = false;
      this.commSystem = false;
      this.propulsionsSystems = true;
    }
    this.submitArea.emit(this.vesselArea);
  }
}
