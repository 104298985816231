import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { CompanyAssessmentDataService } from 'src/app/services/company-assessment-data.service';
import { CompanyAssessmentService } from 'src/app/services/company-assessment.service';

@Component({
  selector: 'app-company-assessment-groups',
  templateUrl: './company-assessment-groups.component.html',
  styleUrls: ['./company-assessment-groups.component.scss']
})
export class CompanyAssessmentGroupsComponent implements OnInit {

  public currentGroup= null;
  public displayUpsertGroup=false;
  public groupToEdit: FormGroup;
  public groupsList:any[]=[];
  public curentGroupId:Number;
  constructor(
    private fb: FormBuilder,
    private companyAssessmentDataService:CompanyAssessmentDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.curentGroupId=Number(params['inventoryId']);
      this.getGroups(this.curentGroupId);
      this.groupToEdit = this.fb.group({
        groupName:[this.currentGroup?this.currentGroup.groupName:""],
        dataEmissionId:[params['inventoryId']]
      });
    });
  }

  public getGroups(groupId:Number):void{
    this.companyAssessmentDataService.groupsOfEmissionData(groupId).subscribe(ans=>{
      this.groupsList=ans;
    });
  }


  public showUpsertGroup(currentGrp){
    
    this.currentGroup=currentGrp;
    this.displayUpsertGroup=true;
  }

  public hideUpsertGroup(result){
    this.displayUpsertGroup=false;
    if(result!=null){
      if(this.currentGroup==null){
        this.groupsList.push(result);
      }else{
        this.groupsList[this.groupsList.indexOf(this.currentGroup)]=result;
        this.currentGroup=null;
      }
      
    }
  }

  public delete(subgr){
    this.groupsList.splice(this.groupsList.indexOf(subgr),1);

  }

  public navigate(subgr){
  }

  public dismissModal(){
    
  }

  public save(){

    if(!this.currentGroup){
      this.companyAssessmentDataService.createGroup(this.groupToEdit.value).subscribe(res=>{
        this.getGroups(this.curentGroupId);
        this.displayUpsertGroup=false;
      });
    }else{
      this.groupsList[this.groupsList.indexOf(this.currentGroup)]=this.groupToEdit.value;
      this.currentGroup=null;
    }
    
  }
}
