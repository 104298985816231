<div class="modalDialog">
    <div class="re-modal-content">
        <div class="title-container">
            <label for="">{{actionType}} company</label>
        </div>
        <form [formGroup]="companyToUpsert">
            <div class="form-row">
                <div class="form-item">
                    <label for="Company name" class="form-item-label">Company name</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Re-flow" formControlName="companyName">
                    </div>
                </div>
                <div class="form-item pos-relative" ngbDropdown [autoClose]="'outside'">
                    <label for="Company type" class="form-item-label" >Company type</label>
                    <div class="form-control-container-custom" ngbDropdownToggle>
                        <label for="">{{companyType}}</label>
                        <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="">
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <ng-container *ngFor="let item of companyTypes; let idx=index">
                            <button ngbDropdownItem class="dropdown-item" (click)="toggleCheck(idx)" data-toggle="collapse" [attr.data-target]="'#type'+idx">
                                <div class="checkbox" *ngIf="item?.typeName!=companyType"></div>
                                <div class="checkbox box-checked" *ngIf="item?.typeName==companyType">
                                    <img src="../../../../assets/yardscore-assets/ys-i-checkmark.svg" alt="">
                                </div>
                                <div class="dd-item-label">{{item?.typeName}}</div>
                                <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="sub-dropdown-icon" *ngIf="item?.subTypes.length!=0">
                            </button>
                            <div id="type{{idx}}" class="collapse" *ngIf="item?.subTypes.length!=0">
                                <ng-container *ngFor="let subItem of item?.subTypes;let subIdx=index">
                                    <button class="dropdown-item" (click)="toggleSubCheck(idx,subIdx)">
                                        <div style="width: 30px;"></div>
                                        <div class="checkbox" *ngIf="subItem?.typeName!=companyType"></div>
                                        <div class="checkbox box-checked" *ngIf="subItem?.typeName==companyType">
                                            <img src="../../../../assets/yardscore-assets/ys-i-checkmark.svg" alt="">
                                        </div>
                                        <div class="dd-item-label">{{subItem?.typeName}}</div>
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!---->
            <div class="form-row mt-20">
                <div class="form-item">
                    <label for="Address" class="form-item-label">Address</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Address line 1" formControlName="firstAddress">
                    </div>
                </div>
                <div class="form-item">
                    <label for="Address line 2" class="form-item-label">Address line 2</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Address line 2" formControlName="secondAddress">
                    </div>
                </div>
            </div>
            <!---->
            <div class="form-row mt-20">
                <div class="form-item">
                    <label for="Country" class="form-item-label">Country</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Country" formControlName="country">
                    </div>
                </div>
                <div class="form-item">
                    
                </div>
            </div>
        </form>
        <div class="actions-container">
            <button class="cancel" (click)="dismiss()">CANCEL</button>
            <button class="add-company" (click)="upsert()">{{actionType=='Add'?'ADD COMPANY':'UPDATE'}}</button>
        </div>
    </div>
</div>