<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <em class="fa fa-bars form-edit" data-toggle="collapse" data-target="#target" *ngIf="vesselToEdit != null"></em>
      <em class="fa fa-trash-o delete collapse" id="target" *ngIf="vesselToEdit != null" (click)="deleteVessel()"></em>
      <label class="form-title">{{ action }} Vessel</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="vesselToCreate">
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="imo" placeholder="IMO" (input)="onIMOChange()" />
          <label class="float-label">IMO<span class="star">*</span></label>
          <small *ngIf="submitted && frm.imo?.errors?.required">
            *IMO is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="mmsi" placeholder="MMSI" />
          <label class="float-label">MMSI<span class="star">*</span></label>
          <small *ngIf="submitted && frm.mmsi?.errors?.required">
            *MMSI is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="vesselName" placeholder="Name" />
          <label class="float-label">Name<span class="star">*</span></label>
          <small *ngIf="submitted && frm.vesselName?.errors?.required">
            *Name is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="vesselType" placeholder="Type" />
          <label class="float-label">Type<span class="star">*</span></label>
          <small *ngIf="submitted && frm.vesselType?.errors?.required">
            *Type is required
          </small>
        </div>
      </div>

      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="description" placeholder="Description" />
          <label class="float-label">Description</label>
        </div>
      </div>
      <label class="section-title">ADD PROFILE PICTURE</label>
      <div class="container upload-image">
        <input #file type="file" (change)="uploadFile(file.files)" class="file-button" />
        <div class="upload-image-content">
          <em class="fa fa-file-image-o fa-2x"></em>
        </div>
        <label class="upload-image-label">{{ vesselImage }}</label>
      </div>

      <div class="fixed-form-footer">
        <div class="footer-content">
          <button class="btn-solid default" (click)="reset()">Cancel</button>
          <button class="btn-solid secondary" (click)="updateVessel()">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>