import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreventLoggedinUsersGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() :boolean{
    if(localStorage.getItem('access_token')==null||localStorage.getItem('access_token')==undefined){
      return true;
    }
    this.router.navigate(['/redirection']);
    return false;
  }
}
