import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { ShareService } from '../../../services/share.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import html2canvas  from "html2canvas"
import { ScreeningService } from 'src/app/services/screening.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';


@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  @Output() toogleModal = new EventEmitter();
  @Input() public showShareModal: boolean = false;
  @Input() projectToShare=null; 
  @Input() sharingCode:String; 
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  public showSpinner=false;
  public searchText:string="";
  public headingTitle:string="Publicly share with URL code";
  public companies:any[]=["Test Company","Test company 2"];
  public selectedCompanies:any[]=[];
  public customCompanies:any[]=[];
  public active = 1;
  public invitationSent:boolean=false;
  public shouldMakeSearch:boolean = false;
  public canMakeSearch:boolean = false;
  public showModal:boolean = true;
  public customCompany: FormGroup;
  public refId = ''; 
  public companyShareButton = 'Share'; 
  public config: any/*Options*/ = {
    width: 200,
    height: 200,
    data: "",
    image: "../../../../assets/svglogoqr.svg",
    margin: 0,
    dotsOptions: {
      color: "#185088",
      type: "square"
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 1,
      imageSize:0.4,
    }
  };

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;
  value = 'https://www.techiediaries.com/';

  constructor(
    private companyService:CompanyService,
    private shareService:ShareService,
    private toastrService: ToastrService,
    private screeningService: ScreeningService
  ) { }

  ngOnInit(): void {
    this.customCompany = new FormGroup({
      id: new FormControl(0),
      companyName: new FormControl(this.searchText),
      companyContact: new FormControl(''),
      email: new FormControl(''),
      custom: new FormControl(true),
    });
    this.companyService.getActiveSuppliers().subscribe(ans=>{
      this.companies=ans;
    },err=>{
    })
    this.config.data ="https://"+window.location.host+"/public-assessment/"+this.sharingCode;
    this.refId = "https://"+window.location.host+"/public-assessment/"+this.sharingCode;
  }

  public close(): void {
    this.showShareModal = false;
    this.toogleModal.emit(this.showShareModal);
  }
  public changeHeadingTitleUrl(): void {
    this.headingTitle="Publicly share with URL code";
  }
  public changeHeadingTitleQr(): void {
    this.headingTitle="Publicly share with QR code";
  }
  public changeHeadingTitleCompanies(): void {
    this.headingTitle="Privately share with companies";
  }
  public changeHeadingTitleData(): void {
    this.headingTitle="Privately share with data subscribers";
  }

  public selectCompany(company:any):void{
    this.selectedCompanies.push(company);
    this.companies=this.companies.filter(x=>x!=company);
    this.changeButtonName();
  }

  public discardCompany(index){
    var x=this.selectedCompanies[index];
    this.selectedCompanies.splice(index,1);
    
    if(x.hasOwnProperty('custom')==false){
      this.companies.push(x);
    }
    this.changeButtonName();
  }

  public invite(){
    this.shareService.shareWithCompanies({
      user_id:localStorage.getItem("user_id"),
      projectShared:this.projectToShare,
      ShareRecievers:this.selectedCompanies.map(({id,email,companyName,companyContact}) => ({id,email,companyName,companyContact})),
      sharingCode:this.sharingCode
    }).subscribe(ans=>{
      this.invitationSent=true;
    },err=>{
      
    })
  }

  public toggleCustom(){
    var list = this.companies.filter(x=>x.companyName.toLowerCase().includes(this.searchText.toLocaleLowerCase()));
    if(this.searchText!=''&&list.length==0){
      this.canMakeSearch=true;
    }
  }

  public addCustomCompany(){
    this.selectedCompanies.push(this.customCompany.value);
    this.customCompany.controls['companyName'].setValue('');
    this.customCompany.controls['companyContact'].setValue('');
    this.customCompany.controls['email'].setValue('');
    this.shouldMakeSearch=false;
    this.searchText='';
    this.changeButtonName();
  }

  public copyUrl():void{
    this.toastrService.success(this.refId, "Copied");
  }
  public closeModal():void{
    this.showModal = false
  }

  downloadImage(){
    
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
    });
  }

  downloadPdf(){
    this.showSpinner=true;
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.shareService.downloadShareCode({
        projectId:this.sharingCode,
        qr_code:this.canvas.nativeElement.src
      }).subscribe(ans=>{
        this.showSpinner=false;
        this.screeningService.DownloadFile(ans, this.projectToShare['name']+".pdf", 'application/pdf');
      },err=>{
        this.showSpinner=false;
      })
    });
    
  }

  changeButtonName(){
    if(this.selectedCompanies.filter(x=>x.id!=0).length>0 && this.selectedCompanies.filter(x=>x.id == 0).length>0){
      this.companyShareButton="Share & Invite";
    }
    else if(this.selectedCompanies.filter(x=>x.id!=0).length>0 && this.selectedCompanies.filter(x=>x.id == 0).length==0){
      this.companyShareButton="Share";
    }
    else if(this.selectedCompanies.filter(x=>x.id!=0).length==0 && this.selectedCompanies.filter(x=>x.id == 0).length>0){
      this.companyShareButton="Invite";
    }
  }
}
