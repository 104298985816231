import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class CompanyFoldersService {

  environmentUrl = "Debug api";

  
  constructor(
      private http: HttpClient,
      private helperService:HelperService,
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public createFolder(folder){
    return this.http.post<any>(
      `${this.environmentUrl}/api/folders/addFolder`,
      folder,
      this.helperService.headers()
    );
  }

  public getFoldersOfCompany(id){
    return this.http.get<any>(
      `${this.environmentUrl}/api/folders/getFolders/`+id,
      this.helperService.headers()
    );
  }

  public editFolder(folder){
    return this.http.put<any>(
      `${this.environmentUrl}/api/folders/editFolder/${folder.id}`,
      folder,
      this.helperService.headers()
    );
  }

  public moveProjectToFolder(data){
    return this.http.post<any>(
      `${this.environmentUrl}/api/folders/assignProject`,
      data,
      this.helperService.headers()
    );
  }

  public archiveFolder(fid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/Folders/archive/${fid}`,
      this.helperService.headers()
      )
  }

  public restoreFolder(fid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/Folders/restore/${fid}`,
      this.helperService.headers()
      )
  }
}
