import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "../helper.service";
import { HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getQuestionnaires(questionnaireSubCategoryId): any {
    return this.http.get<any>(`${this.environmentUrl}/api/question/${questionnaireSubCategoryId}`);
  }

  public createQuestionnaire(questionnaire): any {
    return this.http.post<any>(`${this.environmentUrl}/api/question`,questionnaire);
  }

  public updateQuestionnaire(questionnaire): any {
    return this.http.post<any>(`${this.environmentUrl}/api/question`,questionnaire);
  }

  public deleteQuestionnaire(id): any {
    return this.http.delete<any>(`${this.environmentUrl}/api/question/${id}`);
  }
}
