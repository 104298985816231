import { Component, OnInit } from "@angular/core";
import { Co2LightDto } from "src/app/models/dtos.model";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: "app-co2-light",
  templateUrl: "./co2-light.component.html",
  styleUrls: ["./co2-light.component.css"],
})
export class Co2LightComponent implements OnInit {
  public co2Form: Co2LightDto;
  public co2Error: boolean = false;
  public showTerms: boolean = false;
  constructor(private userService:UserService) {}

  ngOnInit(): void {
  }

  public getCO2Form(event): void {
    this.co2Form = new Co2LightDto(event);
  }

  public getCO2Error(event): void {
    this.co2Error = event;
  }

  public checkTerms():void{
    this.userService.checkAcceptTerms(Number(localStorage.getItem("user_id"))).subscribe(res=>{
      this.showTerms =res.result;
    })
  }
}
