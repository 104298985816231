<div class="success-container  d-flex h-50">
    <div class="row">
        <label class="big-title">Welcome to Reflow</label>
        <label class="big-title-2">The Environmental Performance System</label>
        <i class="fa fa-check-square-o" aria-hidden="true"></i>
        <label class="small-title">Sign-up is completed. Please check your e-mail.</label>
        <div class="row-timer">
            <label class="small-title-2">
                <div class="spinner-grow spinner-grow-sm"></div>
                <p>Redirection to landing page&nbsp;...</p>
            </label>
        </div>
    </div>
</div>