import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public selected='plan-card-start';
  public showTablets=true;
  public currentStep=0;
  public copmonentToShow='login'

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("access_token") != null) {
      let companyId: number = Number(localStorage.getItem("company_id"));
      if (localStorage.getItem("company_type") == "Vessel Owner") {
        this.router.navigate(["/homeVesselOperator", companyId]);
      } else if (localStorage.getItem("company_type") == "Supplier") {
        this.router.navigate(["/homeServiceProvider", companyId]);
      } else if (localStorage.getItem("company_type") == "reflow admin") {
        this.router.navigateByUrl("/homeAdmin");
      }
    }
  }
  public selectPlan(element){
    if(this.selected == element){
      this.selected = '';
    }else{
      this.selected = element;
    }
  }

  public showComponent(event){
    this.copmonentToShow=event; 
  }
  
}
