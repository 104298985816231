<div class="review-questionnaires-container">
    
    <div class="r-q-filter">
       <label for="" class="mb-2">Sorting</label>
       <div class="r-q-filter-element">
          <div class="sort-type-bloc" ngbDropdown >
             <div class="sort-element" ngbDropdownToggle>
                <span class="sort-label">Sort by : Name</span>
                <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="sort-icon">
             </div>
             <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem>Email</button>
                <button ngbDropdownItem>Phone</button>
                <button ngbDropdownItem>ID</button>
             </div>
          </div>
          <div class="bloc-appearence-type">
             <div class="grid-type" (click)="showMessage('hello salim'); viewType='grid'; " [ngClass]="{'selected': viewType=='grid'}" >
             <img src="../../../assets/yardscore-assets/ys-i-grid-light.svg" alt="" class="grid-img-border" *ngIf="viewType == 'grid'">
             <img src="../../../assets/yardscore-assets/ys-i-grid-dark.svg" alt="" class="grid-img-border" *ngIf="viewType == 'list'">
          </div>
          <div class="list-type" (click)="showMessage('hello ZA5'); viewType='list';" [ngClass]="{'selected': viewType=='list'}" >
          <img src="../../../assets/yardscore-assets/ys-i-list-dark.svg" alt="" *ngIf="viewType == 'grid'">
          <img src="../../../assets/yardscore-assets/ys-i-list-light.svg" alt="" *ngIf="viewType == 'list'">
       </div>
    </div>
 </div>
 </div>
 <div class="r-q-body-container">
    <div class="body-scroll-container">
        <!------------------------------------------------------------------------------------->
        <!--------------------------------------List View-------------------------------------->
        <!------------------------------------------------------------------------------------->
        <div class="r-q-list-bloc" *ngIf="viewType=='list'">
            <label for="" class="bloc-title">Recent submitted</label>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn" [routerLink]="['view/1']" routerLinkActive="router-link-active">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <label for="" class="bloc-title mt-30">Jan 2024</label>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <label for="" class="bloc-title mt-30">Dec 2023</label>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="r-q-list-elements">
                <div class="r-q-list-element-content">
                    <div class="q-name">
                        <label for="" class="element-label">Questionnaire name</label>
                    </div>
                    <div class="q-details">
                        <div class="q-details-labels-container">
                        <label for="" class="element-label">SOBY VAERFT LTD</label>
                        <label for="" class="element-label">20-01-24</label>
                        <label for="" class="element-label">Thameur</label>
                        </div>
                        <div class="q-details-btn-container">
                        <div class="view-btn">
                            <img src="../../../assets/yardscore-assets/ys-i-eye.svg" alt="" class="view-btn-icon">
                            <label for="" class="view-btn-label">View</label>
                        </div>
                        <div class="pass-btn" (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                            <img src="../../../assets/yardscore-assets/ys-i-circle-check.svg" alt="" class="pass-btn-icon">
                            <label for="" class="pass-btn-label">Pass</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!------------------------------------------------------------------------------------->
        <!--------------------------------------Grid View-------------------------------------->
        <!------------------------------------------------------------------------------------->
        <div class="r-q-grid-bloc-container" *ngIf="viewType=='grid'">
            <label for="" class="bloc-title">Recent submitted</label>
            <div class="r-q-grid-bloc">
                <ng-container *ngFor="let item of [31,82,70,90,62]">
                    <div class="rqb-card" >
                        <span class="more-options-container" ngbDropdown placement="bottom-right">
                            <img src="../../../../assets/yardscore-assets/ys-i-more-actions-light.svg" class="more-options" alt="" ngbDropdownToggle>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                                    <label for="" class="action-label">Pass</label>
                                    <img src="../../../../assets/yardscore-assets/ys-i-pass.svg" alt="" class="action-icon">
                                </button>
                                <button ngbDropdownItem  [routerLink]="['view/1']" routerLinkActive="router-link-active">
                                    <label for="" class="action-label">View</label>
                                    <img src="../../../../assets/yardscore-assets/ys-i-view.svg" alt="" class="action-icon">
                                </button>
                            </div>
                        </span>
                        <div class="region-container">
                            <img src="../../../../assets/yardscore-assets/ys-i-region.svg" class="region-icon" alt="">
                            <label for="" class="region-label">Northern European</label>
                        </div>
                        <label for="" class="big-title">Questionnaire name</label>
                        <label for="" class="subtitle">Client name / Company</label>
                        <div class="score-indicator-container" [ngClass]="{'success-score': item>=50,'danger-score': item<50}">
                            <label for="" class="score-title">Minim dolor in amet nulla laboris</label>
                            <div class="indicator-container">
                                <div class="indicator">
                                    <div class="indicator-score" [ngStyle]="{'width': item+'%'}" [ngClass]="{'is-success': item>=50,'is-danger': item<50}"></div>
                                </div>
                                <label for="" class="score-label">{{item}}% Reviewed</label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="r-q-grid-bloc-container" *ngIf="viewType=='grid'">
            <label for="" class="bloc-title mb-2 mt-1">Jan 2024</label>
            <div class="r-q-grid-bloc">
                <ng-container *ngFor="let item of [31,82,70]">
                    <div class="rqb-card" >
                        <span class="more-options-container" ngbDropdown placement="bottom-right">
                            <img src="../../../../assets/yardscore-assets/ys-i-more-actions-light.svg" class="more-options" alt="" ngbDropdownToggle>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="openModal('Are you sure you want to Pass the questionnaire?')">
                                    <label for="" class="action-label">Pass</label>
                                    <img src="../../../../assets/yardscore-assets/ys-i-pass.svg" alt="" class="action-icon">
                                </button>
                                <button ngbDropdownItem>
                                    <label for="" class="action-label">View</label>
                                    <img src="../../../../assets/yardscore-assets/ys-i-view.svg" alt="" class="action-icon">
                                </button>
                            </div>
                        </span>
                        <div class="region-container">
                            <img src="../../../../assets/yardscore-assets/ys-i-region.svg" class="region-icon" alt="">
                            <label for="" class="region-label">Northern European</label>
                        </div>
                        <label for="" class="big-title">Questionnaire name</label>
                        <label for="" class="subtitle">Client name / Company</label>
                        <div class="score-indicator-container" [ngClass]="{'success-score': item>=50,'danger-score': item<50}">
                            <label for="" class="score-title">Minim dolor in amet nulla laboris</label>
                            <div class="indicator-container">
                                <div class="indicator">
                                    <div class="indicator-score" [ngStyle]="{'width': item+'%'}" [ngClass]="{'is-success': item>=50,'is-danger': item<50}"></div>
                                </div>
                                <label for="" class="score-label">{{item}}% Reviewed</label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
</div>

<app-rq-pass-questionnaire *ngIf="showPassModal==true" [modalTitle]="modalTitle" (closePassModal)="closePassModal($event)"></app-rq-pass-questionnaire>
