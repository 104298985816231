import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Guid } from "guid-typescript";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  environmentUrl = "Debug api";

  constructor(private http: HttpClient) {
    this.environmentUrl = environment.apiUrl;
  }
  public uploadFile(file: File, companyId: number): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.environmentUrl}/api/uploads`, formData);
  }
}

export class UploadPhotoModel {
  public constructor(init?: Partial<UploadPhotoModel>) {
    Object.assign(this, init);
  }
  public Success: boolean;
  public fileName: string;
}
