<div class="modalDialog">
    <div class="re-modal-content">
        <div class="extrema">
            <em class="icon-close" (click)="close()"></em>
        </div>
        <div class="main-content">
            <div class="project-details-container p-1">
                <div class="project-detalis">
                    <img src="../../../../assets/reflow-newlogo.png" alt="" class="relogo">
                    <div class="alert-container">
                        <em class="icon-view"></em>
                        <div class="details-container">
                          <p class="bold-detail some-space">Project preview - What has been shared with you:</p>
                          <p class="normal-detail">Below you can see a summary of the assessment project that has been shared with you. The assessment results obtained in the shared project will be available for you to use in your own assessments.</p>
                      </div>
                    </div>
                    <label class="prodname">Product preview</label>
                    <p class="prodescription">
                      The assessment results in the shared project relate to this specific product. The product’s function, its manufacturer and technical specifications are described below and provide the basis for this assessment.
                    </p>
                    <div class="row mt10">
                        <div class="col-6 d-flex justify-content-between large-col-6">
                            <label class="reflabel">Poroject ID:</label>
                            <label class="reflabel id-value">{{item.sharingCode}}</label>
                        </div>
                        <div class="col-6 d-flex justify-content-end small-col-6 no-padding">
                            <label class="reflabel mr-2">Project Date:</label>
                            <label class="reflabel">{{item?.importDate | date: 'dd/MM/yyyy'}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Manufacturer</label>
                            <label class="attr">Manufacturer Name:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val">{{item?.manufacturerName}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Specifications</label>
                            <label class="attr">Product Name:</label>
                            <label class="attr">Product Type:</label>
                            <label class="attr">Weight:</label>
                            <label class="attr">Lifespan:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy">{{item?.name}}</label>
                            <label class="val">{{item?.type}}</label>
                            <label class="val">{{item?.weight}} kg</label>
                            <div class="d-flex">
                                <label class="val with-label left-align">{{item?.lifeSpan}} years
                                    <span>(Initial)</span>
                                </label>
                                <label class="val ml-3">+</label>
                                <label class="val with-label ml-3 left-align">{{screeningResult?.extendLifeSpan}} years
                                    <span>(Extended)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="attr">Function:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="val">{{item?.function}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Project impact assessment</label>
                            <label class="attr">Total carbon footprint</label>
                            <label class="attr attr-description">Manufacturer ID:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy with-label align-items-start">{{item?.emission | number : '1.2-2'}} <span style="margin-top: -7px">kg CO<sub>2</sub> eq</span></label>                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-12">
                            <label class="title">Assessment results</label>
                        </div>
                        <div class="col-12">
                            <div class="alert-container">
                                <em class="icon-info"></em>
                                <div class="details-container">
                                    <p class="bold-detail some-space">The following life cycle stages are included in the shared assessment results.</p>
                                    <p class="normal-detail">The life cycle stages to be shared are chosen by the manufacturer and may not represent the whole life cycle of the product.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                            <div class="operation mb-2 grow-container" *ngFor="let lc of item?.sharedLifeCycles;let i=index">
                                <label for="">{{lc.lifeCycle}}</label>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                          <div *ngFor="let lc of item?.sharedLifeCycles;let i=index" class="units-container grow-container">
                            <label class="val with-label flex-row units-name" style="float: left;">{{lc.lifeCycleImpact.impact | number : '1.2-2'}}</label>
                            <span class="units-span">kg CO<sub>2</sub> eq</span>
                          </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-3">
                        <div class="col-6 d-flex justify-content-between">
                            <label class="reflabel">Ecoinvent Database: v{{item?.inventoryVersion}}</label>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <label class="reflabel mr-2">Date of Inventory Data:</label>
                            <label class="reflabel">{{item?.inventoryVersion | date:'dd/MM/yyyy'}}</label>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="extrema"></div>
    </div>
</div>
