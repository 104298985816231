import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ComponentCoreInformationService } from "../services/component-core-information.service";
import { ComponentCoreInformation } from "../models/component-core-information.model";
import { Company } from "../models/company.model";
import { CompanyService } from "../services/company.service";
import { StatusService } from "../services/status.service";
import { Status } from "../models/status.model";

@Component({
  selector: "app-print-pdf",
  templateUrl: "./print-pdf.component.html",
  styleUrls: ["./print-pdf.component.css"],
})
export class PrintPdfComponent implements OnInit {
  public component: ComponentCoreInformation = null;
  public sender: Company = null;
  public reciever: Company = null;
  public status: any = null;
  public id: number;

  constructor(
    private route: ActivatedRoute,
    private componentService: ComponentCoreInformationService,
    private compnyService: CompanyService,
    private statusService: StatusService
  ) {}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get("id"));
    this.loadComponent();
  }

  public loadComponent() {
    this.componentService.getCci(this.id).subscribe((res) => {
      this.component = res;
      this.loadCompany();
      this.loadVesselOperator();
      this.loadStatus();
    });
  }

  public loadCompany() {
    this.compnyService
      .retrieveCustomerCompany(this.component.companyId)
      .subscribe((res) => {
        this.sender = res;
      });
  }

  public loadVesselOperator() {
    this.compnyService
      .retrieveCustomerCompany(this.component.vesselOperatorId)
      .subscribe((res) => {
        this.reciever = res;
      });
  }

  public loadStatus() {
    this.statusService.getStatusOfComponent(this.id).subscribe((res) => {
      this.status = res;
    });
  }

  public downloadPDF() {
    window.print();
  }
}
