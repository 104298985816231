import { AbstractControl, ValidatorFn, FormGroup } from "@angular/forms";

export function updatePasswordValidator(
  formGroup: FormGroup
): { [key: string]: boolean } | null {
  if (formGroup) {
    let regexp = new RegExp("[0-9]");
    let regUpp = new RegExp("[A-Z]");
    let password = formGroup.get("newPassword").value;
    let verifyPassword = formGroup.get("confirmPassword").value;
    if (
      password == verifyPassword &&
      regexp.test(password) &&
      password.length > 8 &&
      regUpp.test(password)
    ) {
      return null;
    }
  }
  return { namenotAllowed: true };
}
