<div id="openModal-about" class="modalDialog" *ngIf="modal">
  <div class="re-modal-content">
    <em class="fa fa-times close" (click)="closeModal()"></em>
    <div *ngIf="questionModal">
      <img class="modal-img" src="{{ imgModal }}" alt="" />
      <label class="question-title">{{ titleModal }}</label>
      <label class="question-description" *ngIf="qstNumber == 1">
        The estimated saved carbon footprint of
        {{ co2LightResult?.reduction }} kg CO2e indicates the potential carbon
        footprint reduction from the business-as-usual scenario implementing
        circular economy. The estimated saved carbon footprint is based on a
        theoretical {{ co2Form.componentScenario }} of the component, estimated
        from industry-representative case study averages. Want to assess the
        specific saved carbon footprint for your component ?
        <a href="http://www.re-flow.io/">Click here</a>
        to learn more about life cycle assessment tailored to your specific
        needs.
      </label>
      <label class="question-description" *ngIf="qstNumber == 2">
        "This is an example of how your potential environmental impact reduction
        translates into an economic incentive. Our calculation is based on EUR
        75 per ton of saved CO2e to simulate the impact using the European
        Emissions Trading Scheme (ETS).";
      </label>
      <label class="question-description" *ngIf="qstNumber == 3">
        The business-as-usual carbon footprint of
        {{ co2LightResult?.footprint }} kg CO2e indicates the regular carbon
        footprint if no circular actions are chosen thus following a linear
        economy. The business-as-usual carbon footprint of the component is
        estimated from industry-representative case study averages. Want to
        assess potential life cycle impact hotspots for your component
        <a href="http://www.re-flow.io/">Click here</a>
        to learn more about life cycle assessment tailored to your specific
        needs.;
      </label>
    </div>
    <div *ngIf="detailsModal">
      <img
        class="modal-img"
        src="../../../../assets/calculator/result-details.svg"
        alt=""
      />
      <label class="question-title">Details</label>
      <label class="detail-blue">
        The results and output of the Maritime CO2 Calculator must not be used
        commercially, published for marketing purposes, or otherwise disclosed
        without written consent from ReFlow Maritime ApS.
      </label>
      <label class="detail-description">
        The Maritime CO2 Calculator estimates the CO2 footprint based on real
        data but is designed as inspiration for maritime stakeholders to pursue
        more sustainable maintenance practices of the global merchant fleet. The
        result is not meant to be used as a stand-alone tool. We always
        recommend that any number used commercially or otherwise disclosed are
        backed up by an ISO-compliant life cycle assessment.
      </label>
      <label class="detail-description">
        For an offer on comprehensive lifecycle assessment, contact us at
        <a href="mailto:info@re-flow.io">info@re-flow.io</a>
      </label>
    </div>
  </div>
</div>

<div class="split left">
  <div class="side-content">
    <div class="right-fade"></div>
    <div class="overlay"></div>
    <video
      playsinline="playsinline"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
    >
      <source src="../../../../assets/calculator/co2bg.mp4" type="video/mp4" />
    </video>
    <div class="container-spec">
      <div class="Co2-menu-section">
        <label class="specification">Specifications</label>
        <hr />
      </div>
      <div class="Co2-menu-section">
        <label class="specification-title">Vessel</label>
        <label class="specification-label">{{ co2Form.vesselType }}</label>
        <label class="specification-label">
          {{ co2Form.vesselAge }} years remaining
        </label>
        <label class="specification-label">{{ co2Form.vesselArea }}</label>
        <hr />
      </div>
      <div class="Co2-menu-section">
        <label class="specification-title">COMPONENT</label>
        <label class="specification-label">{{ co2Form.componentGroup }}</label>
        <label class="specification-label">
          {{ co2Form.componentWeight }} kg
        </label>
        <label class="specification-label">
          {{ co2Form.lifeSpan }} years old
        </label>
        <hr />
      </div>
      <div class="Co2-menu-section">
        <label class="specification-title">SCENARIO</label>
        <label class="specification-label">
          {{ co2Form.componentScenario }}
        </label>
        <hr />
      </div>
    </div>
    <button class="retry-btn" (click)="retry()">
      <em class="fa fa-angle-left"></em>
      <label>Retry</label>
    </button>
  </div>
</div>
<div class="split right">
  <div class="container">
    <label class="result">Here's your results</label>
    <label class="result-description">
      Sustainable maintenance of maritime equipment and systems unlocks large<br
        class="br-hide"
      />
      CO2 savings compared to installing new equipment.
      <span class="read-more" *ngIf="readMore == false" (click)="showReadMore()"
        >Read more</span
      >
    </label>
    <div *ngIf="readMore == true">
      <label class="detail-description">
        The Maritime CO2 Calculator estimates the CO2 footprint based on real
        data but is designed as inspiration for maritime stakeholders to pursue
        more sustainable maintenance practices of the global merchant fleet. The
        result is not meant to be used as a stand-alone tool. We always
        recommend that any number used commercially or otherwise disclosed are
        backed up by an ISO-compliant life cycle assessment.
      </label>
      <label class="detail-description" style="margin-bottom: 30px">
        For an offer on comprehensive lifecycle assessment, please
        <a class="read-more" href="mailto:lca@re-flow.io">contact us</a>
      </label>
    </div>
    <button class="certified">
      <em class="icon-logout"></em>
      <label>
        <a href="mailto:info@re-flow.io"> Get your estimate certified</a>
      </label>
    </button>

    <div class="row result-diagram">
      <div class="col left-result">
        <div class="center-result">
          <label class="result-description value-description">
            Estimated saved carbon footprint
            <img
              (click)="showModal(1)"
              class="question"
              src="../../../../assets/calculator/result-question-active.svg"
              alt=""
            />
          </label>
          <img
            src="../../../../assets/calculator/result-carbon-footprint-savings.svg"
            alt=""
          />
          <label class="value">{{ co2LightResult?.reduction }}</label>
          <label class="unit">kg CO2e</label>
        </div>
      </div>
      <div class="col right-result">
        <div class="row right-top">
          <img
            src="../../../../assets/calculator/result-economic-savings.svg"
            class="result-image"
            alt=""
          />
          <div class="result-info">
            <label class="result-description value-description">
              Potential economic impact
              <img
                (click)="showModal(2)"
                class="question"
                src="../../../../assets/calculator/result-question-active.svg"
                alt=""
              />
            </label>
            <label class="value">{{ co2LightResult?.saving }}</label>
            <label class="unit">EUR €</label>
          </div>
        </div>
        <div class="row right-bottom">
          <img
            src="../../../../assets/calculator/result-carbon-footprint.svg"
            class="result-image"
            alt=""
          />
          <div class="result-info">
            <label class="result-description value-description">
              Business-as-usual
              <img
                class="question"
                (click)="showModal(3)"
                src="../../../../assets/calculator/result-question-active.svg"
                alt=""
              />
            </label>
            <label class="value">{{ co2LightResult?.footprint }}</label>
            <label class="unit">kg CO2e</label>
          </div>
        </div>
      </div>
    </div>

    <label class="detail-blue">
      The results and output of the Maritime CO2 Calculator must not be used
      commercially, published for marketing purposes, or otherwise disclosed
      without written consent from ReFlow Maritime ApS.
    </label>
    <button *ngIf="false" class="btn-details" (click)="showDetails()">
      Details
    </button>
  </div>
</div>
