<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <em
        class="fa fa-bars form-edit"
        data-toggle="collapse"
        data-target="#target"
        *ngIf="locationToEdit != null"
      ></em>
      <em
        class="fa fa-trash-o delete collapse"
        id="target"
        *ngIf="locationToEdit != null"
        (click)="deleteLocation()"
      ></em>
      <label class="form-title">{{ action }} Location</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="locationForm">
      <div class="row">
        <div class="field">
          <select
            class="re-input"
            formControlName="type"
            (change)="makeSelection($event)"
            placeholder=""
          >
            <option disabled selected value></option>
            <option value="Area">Area</option>
            <option value="Engine">Engine </option>
          </select>
          <label class="float-label">Type<span class="star">*</span></label>
          <small *ngIf="submitted && frm.type?.errors?.required">
            *Type is required
          </small>
        </div>
      </div>
      <div class="row" *ngIf="selected">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="name"
            placeholder="Name"
          />
          <label class="float-label">Name<span class="star">*</span></label>
          <small *ngIf="submitted && frm.name?.errors?.required">
            *Name is required
          </small>
        </div>
      </div>
      <div class="row" *ngIf="isEngine">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="manufacturer"
            [(ngModel)]="txtmanufacturter"
            placeholder="Manufacturer"
            id="dropmanufacturer"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropowners">
            <button
              *ngFor="let supplier of suppliers | filterPipe: txtmanufacturter"
              class="dropdown-item"
              type="button"
              (click)="selectManufacturer(supplier)"
            >
              <img
                class="drop-down-image"
                src="{{ supplier.companyLogo }}"
                alt=""
              />
              <label class="drop-down-label">{{ supplier.companyName }}</label>
            </button>
          </div>
          <label class="float-label"
            >Manufacturer<span class="star">*</span></label
          >
        </div>
        <small *ngIf="submitted && frm.manufacturer.errors?.required">
          Manufacturer is required
        </small>
      </div>
      <div class="row" *ngIf="isEngine">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="serialNumber"
            placeholder="Serial Number"
          />
          <label class="float-label">Serial Number</label>
        </div>
      </div>
      <div class="row" *ngIf="isEngine">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="runningHours"
            placeholder="Running hours"
          />
          <label class="float-label">Running hours</label>
        </div>
      </div>
      <div class="row" *ngIf="selected">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="description"
            placeholder="Description"
          />
          <label class="float-label">Description</label>
        </div>
      </div>
      <div class="row" *ngIf="selected">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="specification"
            placeholder="specification"
          />
          <label class="float-label">Specification</label>
        </div>
      </div>
      <div class="row" *ngIf="selected">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="endPoint"
            placeholder="End point"
          />
          <label class="float-label">End point</label>
        </div>
      </div>
      <label class="section-title">FILES</label>
      
      <div class="fixed-form-footer">
        <div class="footer-content">
          <button class="btn-solid default" (click)="reset()">Cancel</button>
          <button class="btn-solid secondary" (click)="updateLocation()">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
