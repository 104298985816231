<div class="user-management-container">
    <div class="filters-container">
        <div class="cm-title-container">
            <label class="cm-title">All users (5)</label>
            <div class="cm-search-container">
                <img src="../../../assets/yardscore-assets/ys-i-search.svg" alt="" class="cm-search-icon">
                <input type="text" class="cm-search-text" placeholder="Search user">
            </div>
        </div>
        <div class="cm-add-user" (click)="toggleUpsert('create',null)">Add User</div>
    </div>
    <div class="users-list-container">
        <label class="list-title">Users list</label>
        <span ngbDropdown >
            <div class="list-filter" ngbDropdownToggle>
                <label class="filter-label">Sort by</label>
                <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="filter-icon">
            </div>
            <div ngbDropdownMenu>
				<button ngbDropdownItem (click)="toSortBy='name'">Name</button>
				<button ngbDropdownItem (click)="toSortBy='userType'">Type of user</button>
				<button ngbDropdownItem (click)="toSortBy='email'">Email</button>
			</div>
        </span>

        <div class="users-list">
            <table class="table">
                <thead>
                    <tr>
                        <th>User name</th>
                        <th>Type of User</th>
                        <th>Email</th>
                        <th>Mobile number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of users | sortby: users : toSortBy; let idx=index">
                        <tr>
                            <td>{{item?.name}}</td>
                            <td>{{item?.userType}}</td>
                            <td>{{item?.email}}</td>
                            <td>{{item?.phone}}</td>
                            <td class="actions-container" [ngStyle]="{'border-top': idx==0?'0 !impocalcrtant':''}" >
                                <img src="../../../assets/yardscore-assets/ys-i-edit.svg" alt="" class="action-icon" (click)="toggleUpsert('update',item)">
                                <img src="../../../assets/yardscore-assets/ys-i-delete.svg" alt="" class="action-icon action-margin" (click)="toggleDelete('delete',item)">
                              

                                <span class="more-options-container" ngbDropdown placement="bottom-left">
                                  
                                    <img src="../../../assets/yardscore-assets/ys-i-more-actions.svg" alt="" class="action-icon" alt="" ngbDropdownToggle>
                                     
                                    <div ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="toggleDelete('flipstatus',item)">
                                            <label for="" class="action-label">Active</label>
                                            <img src="../../../../assets/yardscore-assets/ys-i-pass.svg" alt="" class="action-icon margin-icon" *ngIf="item?.isActive==true">
                                        </button>
                                        <div class="dropItemBorder"></div>
                                        <button ngbDropdownItem (click)="toggleDelete('flipstatus',item)">
                                            <label for="" class="action-label">Deactive</label>
                                            <img src="../../../../assets/yardscore-assets/ys-i-pass.svg" alt="" class="action-icon margin-icon" *ngIf="item?.isActive==false">
                                        </button>
                                    </div>
                                </span>

                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-ysa-upsert-user *ngIf="showUpsertForm==true" [currentUser]="currentUser" [companyId]="companyId" [actionType]="actionType" (closeUpsertForm)="closeUpsertForm($event)"></app-ysa-upsert-user>
<app-ysa-delete-user *ngIf="showDeleteUserForm==true" [actionType]="actionType" [currentUser]="currentUser" (closeDeleteModal)="closeDeleteModal($event)"></app-ysa-delete-user>
