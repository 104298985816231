import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { CompanyService } from "src/app/services/company.service";
import { Company } from "src/app/models/company.model";
import { ComponentCoreInformation } from "src/app/models/component-core-information.model";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { Vessel } from "src/app/models/vesset";
import { VesselService } from "src/app/services/vessel.service";
import { LocationService } from "src/app/services/location.service";
import { ActivatedRoute } from "@angular/router";
import { VesselOverviewDto } from "src/app/models/dtos.model";
import { Status } from "src/app/models/status.model";
import { StatusService } from "src/app/services/status.service";

@Component({
  selector: "app-edit-unassigned-component",
  templateUrl: "./edit-unassigned-component.component.html",
  styleUrls: ["./edit-unassigned-component.component.css"],
})
export class EditUnassignedComponentComponent implements OnInit {
  public componentToEdit: FormGroup;
  public submitted: boolean = false;
  public txtvesselOperator: string = "";
  public txtvessel: string = "";
  public txtlocation: string = "";
  public vesselOperators: Company[];
  public vessels: Vessel[];
  public locations: Location[];
  public currentComponent: ComponentCoreInformation;
  public currentVessel: VesselOverviewDto;

  @Input() public componentId: number;
  @Input() public showModal: boolean;

  @Output() public closeModal = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private vesselsService: VesselService,
    private activatedRoute: ActivatedRoute,
    private componentCoreInfomationService: ComponentCoreInformationService,
    private locationService: LocationService,
    private statusService: StatusService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.componentCoreInfomationService
        .getfullComponent(this.componentId)
        .subscribe((res) => {
          this.currentComponent = res;
          this.vesselsService
            .getvesselOverviewById(this.currentComponent.vesselId)
            .subscribe((vessel) => {
              this.componentToEdit.patchValue({
                vesselId: vessel.id,
                vessel: vessel.vesselName,
              });
              this.locationService
                .getLocationsByVesselId(vessel.id)
                .subscribe((locationList) => {
                  this.locations = locationList;
                });
            });
        });
      this.vesselsService
        .getVessels(Number(params["companyId"]))
        .subscribe((res) => {
          this.vessels = res;
        });
    });

    this.componentToEdit = this.fb.group({
      vesselOperator: ["", Validators.required],
      vessel: ["", Validators.required],
      location: ["", Validators.required],
      vesselId: [0],
      vesselOperatorId: [0],
      locationId: [0],
    });
  }

  public hideModal() {
    this.showModal = false;
    this.closeModal.emit(this.showModal);
  }

  public selectVessel(vessel: any): void {
    this.componentToEdit.patchValue({
      vesselId: vessel.id,
      vessel: vessel.vesselName,
    });
    this.locationService.getLocationsByVesselId(vessel.id).subscribe((res) => {
      this.locations = res;
    });
  }

  public selectLocation(location: any): void {
    this.componentToEdit.patchValue({
      locationId: location.id,
      location: location.name,
    });
  }

  get frm() {
    return this.componentToEdit.controls;
  }

  public updateComponent() {
    if (this.frm.location.value == "" || this.frm.vessel.value == "") return;
    let status: Status = {
      id: 0,
      componentId: this.currentComponent.id,
      updatedAt: new Date(),
      wareHouseId: 0,
      location: "",
      locationId: this.currentComponent?.locationId,
      exchangeComponentId: "",
      vesselId: this.frm.vesselId.value,
      currentStatus: "Received on vessel",
      scenarioSelector: "",
      statusFiles: null,
    };
    this.currentComponent.vesselId = this.frm.vesselId.value;
    this.currentComponent.locationId = this.frm.locationId.value;
    this.currentComponent.vesselOperatorId = this.frm.vesselOperatorId.value;
    this.componentCoreInfomationService
      .updateCci(this.currentComponent)
      .subscribe((res) => {
        this.hideModal();
      });
    this.statusService.updateComponentStatus(status).subscribe((res) => {});
  }

  public reset() {
    this.ngOnInit();
  }
}
