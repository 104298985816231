import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Status } from "../models/status.model";
import { UpdateStatusVoDto } from "../models/dtos.model";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class StatusService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public updateComponentStatus(status: Status): any {
    this.helperService.headers()
    return this.http.post<any>(`${this.environmentUrl}/api/status`, status, this.helperService.headers());
  }

  public updateVoStatus(status: UpdateStatusVoDto): any {
    this.helperService.headers()
    return this.http.post<any>(`${this.environmentUrl}/api/status/vo`, status, this.helperService.headers());
  }

  public receiveComponent(status: UpdateStatusVoDto): any {
    this.helperService.headers()
    return this.http.post<any>(
      `${this.environmentUrl}/api/status/sp/receive`,
      status,this.helperService.headers()
    );
  }

  public getStatus(id): any {
    this.helperService.headers()
    return this.http.get<any>(`${this.environmentUrl}/api/status/` + id, this.helperService.headers());
  }

  public getStatusOfComponent(id): any {
    this.helperService.headers()
    return this.http.get<any>(
      `${this.environmentUrl}/api/status/component/` + id,this.helperService.headers()
    );
  }
}
