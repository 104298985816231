import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-overview',
  templateUrl: './mobile-overview.component.html',
  styleUrls: ['./mobile-overview.component.scss']
})
export class MobileOverviewComponent implements OnInit {
  public current_year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  }

}
