<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <label class="form-title">Update status</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="newStatus" autocomplete="off">
      <div class="row">
        <div class="field">
          <select
            class="re-input"
            formControlName="currentStatus"
            (change)="makeSelection($event.target.value)"
            required
          >
            <option disabled selected value></option>
            <option value="In Vo warehouse">In Vo warehouse</option>
            <option value="Received on vessel">Received on vessel</option>
            <option value="Stocked on vessel">Stocked on vessel</option>
            <option value="In use">In use</option>
            <option value="Return for service">Return for service</option>
          </select>
          <label class="float-label">New status</label>
        </div>
      </div>
      <div
        class="shadow p-3 mb-5 bg-white rounded exchange-unit"
        *ngIf="hasExchangeUnit && !showChangeExchange"
      >
        <em class="fa fa-link fa-lg" style="color: #123b63"></em>
        <label class="exchange-title">Exchange unit</label>
        <label class="exchange-confirm">
          Please confirm that the following component will be returned for
          service
        </label>
        <div class="exchange-comp">
          <img
            class="exchange-image"
            src="{{ exchangeComponent?.componentPicture }}"
            alt=""
          />
          <label class="exchange-specification">Specification</label>
          <label class="property-title">Description</label>
          <label class="property-content">
            {{ exchangeComponent?.description }}
          </label>
          <label class="property-title">Installation date</label>
          <label
            class="property-content"
            *ngIf="exchangeComponent?.installationDate != ''"
          >
            {{ exchangeComponent?.installationDate }}
          </label>
          <label
            class="property-content"
            *ngIf="exchangeComponent?.installationDate === ''"
          >
            N/A
          </label>
          <label class="property-title">Days in status</label>
          <label class="property-content">
            {{ exchangeComponent?.daysInStatus }}
          </label>
          <button class="download-button">
            <em class="fa fa-download"></em>Download return label
          </button>
        </div>
        <label class="exchange-specification" (click)="changeExchangeUnit()"
          >Not the right component?</label
        >
        <button class="btn-solid primary">confirm</button>
      </div>
      <div
        class="shadow p-3 mb-5 bg-white rounded change-unit"
        *ngIf="hasExchangeUnit && showChangeExchange"
      >
        <em class="fa fa-link fa-lg" style="color: #123b63"></em>
        <label class="exchange-title">Exchange unit</label>
        <label class="exchange-confirm">
          Please confirm that the following component will be returned for
          service
        </label>
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="exchangeComponent"
            [(ngModel)]="txtexchangeComponent"
            placeholder="Exchange "
            id="dropexchangeComponent"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropexchangeComponent">
            <button
              (click)="selectUnit(unit)"
              *ngFor="
                let unit of exchangeUnits | filterPipe: txtexchangeComponent
              "
              class="dropdown-item"
            >
              {{ unit.specification }}
            </button>
          </div>
          <label class="float-label">Exchange unit (Specification)</label>
        </div>
        <button class="btn-solid primary" (click)="confirmExchange()">
          confirm
        </button>
      </div>
      <div class="row" *ngIf="isInVoWharehouse">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="warehouse"
            [(ngModel)]="txtwarehouse"
            placeholder="Warehouse"
            id="dropwarehouses"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropwarehouses">
            <button
              (click)="selectWarehouse(warehouse)"
              *ngFor="let warehouse of warehouses | filterPipe: txtwarehouse"
              class="dropdown-item"
            >
              {{ warehouse.warehouseName }}
            </button>
          </div>
          <label class="float-label">Warehouse</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="isReceivedInVessel || isStockedInVessel || isInUse"
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="vessel"
            [(ngModel)]="txtvessel"
            placeholder="Vessel operator"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectVessel(vessel)"
              *ngFor="let vessel of vessels | filterPipe: txtvessel"
              class="dropdown-item"
            >
              {{ vessel.vesselName }}
            </button>
          </div>
          <label class="float-label">Vessel</label>
        </div>
      </div>
      <div class="row" *ngIf="isInVoWharehouse">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="warehouseLocation"
            placeholder="Location"
            required
          />
          <label class="float-label">Location in Warehouse</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="isReceivedInVessel || isStockedInVessel || isInUse"
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="location"
            [(ngModel)]="txtlocation"
            placeholder="Location"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectLocation(location)"
              *ngFor="let location of locations | filterPipe: txtlocation"
              class="dropdown-item"
            >
              {{ location.name }}
            </button>
          </div>
          <label class="float-label">Location</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="isReceivedInVessel || isInVoWharehouse || isStockedInVessel"
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="stockReason"
            placeholder="Reason for being stocked"
            required
          />
          <label class="float-label">Reason for being stocked</label>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          isReceivedInVessel ||
          isInVoWharehouse ||
          isStockedInVessel ||
          isReturnToService
        "
      >
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="voReference"
            placeholder="Vo reference"
            required
          />
          <label class="float-label">Vo reference</label>
        </div>
      </div>
      <div class="row" *ngIf="isInUse">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="utilization"
            placeholder="Utilization"
            required
          />
          <label class="float-label">Utilization</label>
        </div>
      </div>
      <div class="row" *ngIf="isInUse">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="recommendHours"
            placeholder="recommended Hours"
            required
          />
          <label class="float-label">recommended Hours</label>
        </div>
      </div>
      <div class="row" *ngIf="isInUse">
        <div class="field">
          <input
            class="re-input"
            type="date"
            formControlName="installationDate"
            placeholder="Installation Date"
            required
          />
          <label class="float-label">Installation Date</label>
        </div>
      </div>
      <div class="row" *ngIf="isInUse">
        <div class="field">
          <input
            class="re-input"
            type="time"
            formControlName="installationTime"
            placeholder="Installation Time"
            required
          />
          <label class="float-label">Installation Time</label>
        </div>
      </div>
      <div class="row" *ngIf="isReturnToService">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="supplier"
            [(ngModel)]="txtsupplier"
            placeholder="Supplier"
            id="dropsupplier"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropowners">
            <button
              *ngFor="let supplier of suppliers | filterPipe: txtsupplier"
              class="dropdown-item"
              type="button"
              (click)="selectSupplier(supplier)"
            >
              <img
                class="drop-down-image"
                src="{{ supplier.companyLogo }}"
                alt=""
              />
              <label class="drop-down-label">{{ supplier.companyName }}</label>
            </button>
          </div>
          <label class="float-label">
            Supplier <span class="star">*</span></label
          >
        </div>
      </div>
      <div class="row" *ngIf="isReturnToService">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="purchaseOrder"
            placeholder="Purchase Order"
            required
          />
          <label class="float-label">Purchase Order</label>
        </div>
      </div>
      <div
        class="row"
        *ngFor="let file of componentFiles?.controls; let i = index"
      >
        <button class="uploaded-file">
          <em class="fa fa-cog"></em>{{ file.controls.filename.value }}
        </button>
      </div>
      <label class="section-title">ADD DOCUMENTAION</label>
      <app-add-file (closeFileForm)="updateFiles($event)"></app-add-file>
    </form>
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default" (click)="reset()">Cancel</button>
      <button class="btn-solid secondary" (click)="updateStatus()">
        Update
      </button>
    </div>
  </div>
</div>
