import { Component, Output,Input, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Screening } from 'src/app/models/screening.model';
import { ScreeningService } from 'src/app/services/screening.service';
@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrls: ['./compare-modal.component.css']
})
export class CompareModalComponent implements OnInit { 
  public companyId: number;
  search_array: Screening[];
  selected_projects: number[] = [];
  un_selected_projects: number[] = [];
  projects_to_add: any[] = [];
  projects_to_remove: any[] = [];
  final_projects_to_add: any[] = [];
  final_projects_to_remove: any[] = [];
  public modal_screenings: Screening[];
  public initial_screening: Screening[];
  public user_input = '';
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(
    private screeningService: ScreeningService,
    private router: Router,
    ) { 
    this.getScreenings();      
  }   
  
  ngOnInit(): void {    
    this.companyId = Number(localStorage.getItem("company_id"));
    this.getScreenings();     
  }  
  cancel($event) {
    this.close.emit(null);
  }

  public getScreenings(): void {
      this.screeningService.getScreeningByCompanyId(Number(localStorage.getItem("company_id"))).subscribe(res => {
      this.modal_screenings = res;
      this.modal_screenings = this.modal_screenings.filter(screening => screening.finished == true);
      this.initial_screening=this.modal_screenings;
    });
  }  
  public selectScreening(screening_id:number)
  {
    if(this.selected_projects.indexOf(screening_id) == -1)
      {
       this.selected_projects.push(screening_id);       
      }else
      {
        const index = this.selected_projects.indexOf(screening_id, 0);
        if (index > -1) {
          this.selected_projects.splice(index, 1);
        }
      } 
     
  }
  public unselectScreening(screening_id:number)
  {     
    if(this.un_selected_projects.indexOf(screening_id) == -1)
      {
       this.un_selected_projects.push(screening_id);       
      }else
      {
        const index = this.un_selected_projects.indexOf(screening_id, 0);
        if (index > -1) {
          this.un_selected_projects.splice(index, 1);
        }
      }            
  }

  public addScreening()
  {     
    this.selected_projects?.forEach(value => { 
    this.projects_to_add = this.modal_screenings.filter((screening: Screening) => screening.id === value);
    this.final_projects_to_add.push(this.projects_to_add[0]);  
    var index = this.modal_screenings.map(x => {return x.id;}).indexOf(this.projects_to_add[0].id);    
    this.modal_screenings.splice(index, 1);
    this.initial_screening.splice(this.initial_screening.map(x => {return x.id;}).indexOf(this.projects_to_add[0].id), 0);
  });
    this.selected_projects = [];
  }
  public removeScreening()
  {      
    this.un_selected_projects.forEach(value=> { 
    this.projects_to_remove = this.final_projects_to_add.filter((screening: Screening) => screening.id === value);      
    this.final_projects_to_remove.push(this.projects_to_remove[0]);      
    });  
    this.un_selected_projects = [];    
    this.modal_screenings = this.modal_screenings.concat(this.final_projects_to_remove);
    this.initial_screening = this.initial_screening.concat(this.final_projects_to_remove);    
    this.final_projects_to_remove.forEach(element => { 
    var index = this.final_projects_to_add.map(x => {return x.id;}).indexOf(element.id);    
    this.final_projects_to_add.splice(index, 1); 
    this.final_projects_to_remove = [];
    }); 
     
  }

    public searchScreening()
    {      
      this.modal_screenings = this.initial_screening.filter((screening: Screening) => screening.name.toLowerCase().includes(this.user_input.toLowerCase()));      
    }
    public compareScreenings()
    {
      this.close.emit(this.final_projects_to_add);
      return true;
    }
  
}
