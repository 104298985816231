import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MastersheetService } from '../../../../services/mastersheet.service';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentGroup:any;
  @Input() inventoryDatabaseId:any;
  @Output() closeModal=new EventEmitter<any>();
  public groupToEdit: FormGroup;

  constructor(
    private fb: FormBuilder,
    private masterSheetService:MastersheetService
  ) { }

  ngOnInit(): void {
    
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.groupToEdit = this.fb.group({
      //id:[this.currentGrp?this.currentGrp.id:""],
      groupName:[this.currentGroup?this.currentGroup.groupName:""],
      databaseId:[this.inventoryDatabaseId]
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public save(){
    var db=this.groupToEdit.value;
    if(!this.currentGroup){
      this.masterSheetService.createGroup(this.groupToEdit.value).subscribe(res=>{
      });
      //store database
    }else{
      //update database
    }
    this.closeModal.emit(this.groupToEdit.value);
  }
}
