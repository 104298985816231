import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Location } from "../models/location";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public addLocation(location: Location): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/locations`,
      location,
      this.helperService.headers()
    );
  }

  public getLocationsByVesselId(vesselId: number) {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/locations/getLocationsByVesselId/` + vesselId,
      this.helperService.headers()
    );
  }

  public getLocations(): any {
    
    return this.http.get<any>(`${this.environmentUrl}/api/locations`, this.helperService.headers());
  }

  public getLocationOverviewById(locationId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/locations/locationOverview/` + locationId,
      this.helperService.headers()
    );
  }
}
