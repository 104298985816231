import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {
  environmentUrl = "Debug api";

  constructor(private http: HttpClient,
    private router: Router,
    private helperService:HelperService,) { 
      this.environmentUrl = environment.apiUrl;
    }

  public getNotifications(): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/notifications`,
      this.helperService.headers()
    );
  }

  public addPlatformUpdate(platformUpdate:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/notifications`,
      platformUpdate,
      this.helperService.headers()
    );
  }

  public checkUpdate(checkUpdateDto:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/notifications/checkUpdates`,
      checkUpdateDto,
      this.helperService.headers()
    );
  }
}
