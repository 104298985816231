<div class="order-container">
  <div class="row">
    <ul class="timeline">
      <li *ngFor="let log of componentLogs">
        <label class="timeline-title">{{ log.action }}</label>
        <label class="timeline-date">
          {{ log.createdAt | date: "LLL dd, yyyy 'at' hh.mm" }} by
          {{ log.userName }}
        </label>
        <div class="row">
          <button
            *ngFor="let file of log.files"
            class="uploaded-file"
            (click)="showFile(file)"
          >
            <em class="fa fa-cog"></em>{{ file.fileName }}
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
<app-show-file
  *ngIf="openFile"
  [file]="selectedFile"
  (closeFile)="closeFileModal($event)"
></app-show-file>
