<div class="customer-management-container">
    <div class="filters-container">
        <div class="cm-title-container">
            <label class="cm-title">All Companies (5)</label>
            <div class="cm-search-container">
                <img src="../../../assets/yardscore-assets/ys-i-search.svg" alt="" class="cm-search-icon">
                <input type="text" class="cm-search-text" placeholder="Search customer">
            </div>
        </div>
        <div class="cm-add-company" (click)="toggleUpsert('create',null)">Add Company</div>
    </div>
    <div class="companies-list-container">
        <label class="list-title">Company list</label>
        <span ngbDropdown >
            <div class="list-filter" ngbDropdownToggle>
                <label class="filter-label">Sort by</label>
                <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="filter-icon">
            </div>
            <div ngbDropdownMenu>
				<button ngbDropdownItem (click)="toSortBy='companyName'">Alphabetical order</button>
				<button ngbDropdownItem (click)="toSortBy='country'">Region</button>
				<button ngbDropdownItem (click)="toSortBy='users'">Number of users</button>
			</div>
        </span>
        <div class="companies-list">
            <table class="table">
                <thead>
                    <tr>
                        <th>Company name</th>
                        <th>Type of company</th>
                        <th>Region</th>
                        <th>Number of users</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of companiesList | sortby: companiesList : toSortBy; let idx=index">
                        <tr [routerLink]="['./'+item?.id+'/users-management']" routerLinkActive="router-link-active">
                            <td>{{item?.companyName}}</td>
                            <td>{{item?.companyType}}</td>
                            <td>{{item?.country}}</td>
                            <td>{{item?.users?.length>0?item?.users?.length:0}}</td>
                            <td class="actions-container" [ngStyle]="{'border-top': idx==0?'none !important':''}" ngbDropdown >
                                <img src="../../../assets/yardscore-assets/ys-i-edit.svg" alt="" class="action-icon" (click)="$event.stopPropagation();toggleUpsert('update',item)">
                                <img src="../../../assets/yardscore-assets/ys-i-delete.svg" alt="" class="action-icon action-margin" (click)="$event.stopPropagation();toggleDelete(item)">
                                <img src="../../../assets/yardscore-assets/ys-i-more-actions.svg" alt="" class="action-icon" ngbDropdownToggle (click)="$event.stopPropagation()">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-ysa-upsert-company *ngIf="showUpsertForm" [currentCompany]="currentCompany" [actionType]="actionType" (closeUpsertForm)="closeUpsertForm($event)"></app-ysa-upsert-company>
<app-ysa-delete-company *ngIf="showDeleteCompanyForm" [currentCompany]="currentCompany" (closeDeleteModal)="closeDeleteModal($event)"></app-ysa-delete-company>