import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { UserService } from "src/app/services/user.service";
import { ProfilPictureDto } from "src/app/models/dtos.model";

@Component({
  selector: "app-profile-image",
  templateUrl: "./profile-image.component.html",
  styleUrls: ["./profile-image.component.css"],
})
export class ProfileImageComponent implements OnInit {
  public files: any = [];
  public imgSrc: any = null;
  public fileData: File = null;

  @Output() toogleModal = new EventEmitter();
  @Input() public userId: number;
  constructor(
    private uploadService: FileUploadService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  uploadFile(event) {
    this.fileData = <File>event[0];
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.imgSrc = reader.result;
    };
  }

  public reset() {
    this.imgSrc = null;
    this.files = null;
  }

  public closeModal(): void {
    this.toogleModal.emit(false);
  }

  public saveProfilPicture(): void {
    this.uploadService.uploadFile(this.fileData, 0).subscribe((res) => {
      const profilPicture = {
        pictureUrl: res.fileName,
        userId: Number(localStorage.getItem("user_id")),
      };
      this.userService.updatePicture(profilPicture).subscribe((response) => {
        this.toogleModal.emit(false);
      });
    });
  }
}
