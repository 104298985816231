<div class="questionnaire-container">
   <div class="questionnaire-header-container">
      <label for="">Steel Recycling Process</label>
   </div>
   <div class="questionnaire-body-container">
      <div class="questionnaire-card" *ngFor="let item of questionsList;let idx=index">
         <div class="title-bloc">
            <label for="">Question {{idx+1}}</label>
            <div class="action-buttons">
               <div class="space-swipe-label">
                  <span class="swip-txt">Documentation required</span>
                  <label class="switch">
                     <input type="checkbox" checked>
                     <span class="slider"></span>
                  </label>
               </div>
               <div class="delete-btn">
                  <img src="../../../../assets/yardscore-assets/ys-i-delete-light.svg" alt="">
               </div>
            </div>
         </div>
         
         <div class="content-bloc">
   
            <div class="question-title-container">
               <div class="quest-type-bloc" ngbDropdown >
                  <label for="" class="input-title">Question type</label>
                  <div class="percentage-bloc" ngbDropdownToggle>
                     <span class="percentage-label">{{questionsList[idx].questionType==''?'Select Question Type' : questionsList[idx].questionType}}</span>
                     <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="percentage-icon">
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                     <button ngbDropdownItem (click)="selectType('Percentage',idx)">Percentage</button>
                     <button ngbDropdownItem (click)="selectType('YES / NO',idx)">YES / NO</button>
                     <button ngbDropdownItem (click)="selectType('Dropdown',idx)">Dropdown</button>
                  </div>
               </div>
               <div class="quest-title-bloc">
                  <label for="" class="input-title">Question title</label>
                  <input type="text" class="form-control-container" placeholder="Steel Recycling Process" [(ngModel)]="questionsList[idx].questionTitle">
               </div>
            </div>

            <div class="question-answers-container">
               <label for="" class="input-title">Answers</label>
               <label *ngIf="false" for="" class="error">{{error}}</label>

               <ng-container *ngFor="let answer of questionsList[idx].questionnaireQuestionOptions;let answerIdx=index">
                  <div class="answer-item">
                     <input type="text" class="answer-element answer-element-40 form-control-container" placeholder="Answer" [(ngModel)]="questionsList[idx].questionnaireQuestionOptions[answerIdx].answerTitle" [disabled]="questionsList[idx].questionType=='YES / NO'">
                     <input type="number" class="answer-element answer-element-30 form-control-container ml-16" placeholder="Score impact" [(ngModel)]="questionsList[idx].questionnaireQuestionOptions[answerIdx].answerScore">
                     <div class="answer-element answer-element-30 form-control-container add-more ml-16" *ngIf="questionsList[idx].questionType!='YES / NO'" (click)="addAnswer(idx)">
                        <img src="../../../../assets/yardscore-assets/ys-i-add-more.svg" alt="">
                        <label for="">Add more</label>
                     </div>
                     <div class="answer-element answer-element-30 ml-16" *ngIf="questionsList[idx].questionType=='YES / NO'">
                        &nbsp;
                     </div>
                  </div>
               </ng-container>
            </div>
   
            <div class="extrem-left-btn" (click)="saveQuestion(idx,questionsList[idx].questionType)">
               <button class="btn-save">SAVE</button>
            </div>
   
         </div>
      </div>
      <div class="add-quest-btn-container">

         <div class="add-quest-btn" (click)="addEmptyQuestion()">
            <img src="../../../../assets/yardscore-assets/ys-i-add-more.svg" alt="">
            <label for="">Add question</label>
         </div>
      </div>
      <div class="save-container mt-100">
         &nbsp;
         <button class="save" (click)="saveAll()">SUBMIT QUESTIONNAIRE</button>
     </div>
   </div>
</div>