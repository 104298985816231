<div class="loading-container" *ngIf="!showResult">
  <div class="loading-content">
    <img src="../../../../../assets/Loading-icon-animation.svg" style="height: 125px; width: 125px;" alt="" />
    <label class="h3-heading" style="display: block;">Calculating impact contributions</label>
    <label class="h4-heading loading">Please wait while we process your data ... </label>
  </div>
</div>

<div class="results-container rc-rs">
  <div class="files-container">

  </div>

  <h1 class="big-title">Results summary</h1>
  <p class="title-description">The outcome of the project’s assessment is presented in this section along with the
    interpretation of the results. The total carbon footprint of the product is calculated according to the project’s
    scope, primary data inputs, and chosen datasets.</p>
  <p class="title-description">In addition, the avoided carbon footprint is estimated due to the adoption of circular
    life cycle stages (if any). This is achieved by benchmarking the calculated total carbon footprint against a
    hypothetical “take-make-use-dispose” linear economy
    scenario (i.e. no circular life cycle stages). </p>
  <p class="title-description">The linear economy scenario assumes the substitution of circular life cycle stages with
    corresponding linear ones to ensure a fair comparison in terms of time horizon. Any usage or distribution of the
    product is assumed to remain the same.</p>
  <div class="changes-info" *ngIf="showCorrectionInfo">
    <label class="changes-description">Please press the button when you are ready to submit the correction scheme
      changes.</label>
    <button class="big-turq changes-btn" (click)="openChangesModal()">
      <label>
        Submit
      </label>
    </button>
  </div>
  <div class="dropdown">
    <button class="dropdownbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <label class="label-download">
        <em class="fa fa-download download-icon"></em>
        Download
        <em class="fa fa-caret-down caret-down"></em>
      </label>

    </button>

    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item dropdown-item-first" (click)="downloadSheet($event)">
        <div class="drop-elem-content">
          <div>
            <em class="icon-file-earmark-spreadsheet icons-margin" style="color: #579557;"></em>
            <label class="label-excel">
              Excel Sheet
            </label>
          </div>
          <div class="free-div">
            <label class="label-free-exel">
              free
              <ng-template #popupContent>
                <div class="pop-container1">
                  <div class="pop-header d-flex">
                    <em class="icon-info bigger-info1"></em>
                    <label class="pop-title1">
                      Data Sheet
                    </label>
                  </div>
                  <p class="info-description" style="color: #123B68 !important;">
                    Provides the main results in an excel format including the total carbon footprint,
                    the contribution of each of the life cycle stages and a breakdown of the carbon
                    footprint of all parameters included in the project.
                  </p>
                </div>
              </ng-template>

            </label>
          </div>
          <div class="info-div">
            <em class="icon-info hovered select-item-icon" animation='false' container="body"
              [ngbPopover]="popupContent" triggers="hover" placement="right"></em>
          </div>
        </div>
      </a>
      <a class="dropdown-item" (click)="downloadProofOfAssessment()">
        <div class="drop-elem-content">
          <div>
            <em class="icon-award-fill icons-margin" style="color: #123B68;"></em>
            <label class="label-proof">
              Climate Assessment Report
            </label>
          </div>
          <div class="free-div">
            <label class="label-free">
              free
              <ng-template #popupContent1>
                <div class="pop-container1">
                  <div class="pop-header d-flex">
                    <em class="icon-info bigger-info1"></em>
                    <label class="pop-title1">
                      Climate Assessment Report
                    </label>
                  </div>
                  <p class="info-description" style="color: #123B68 !important;">
                    Provides a project outline that proves that the organization has carried out an
                    assessment of the CO<sub>2</sub> emissions of a specific product or system.
                    This document includes all key project information and the assessment results, such
                    as total carbon footprint,
                    most impacting life cycle stages and most impactful parameters.
                    The assessment summary does not entitle any validation but is proof of having
                    conducted an LCA-based assessment.
                  </p>
                </div>
              </ng-template>
            </label>
          </div>
          <div class="info-div">
            <em class="icon-info hovered select-item-icon" animation='false' container="body"
              [ngbPopover]="popupContent1" triggers="hover" placement="right"></em>
          </div>
        </div>
      </a>
      <a class="dropdown-item dropdown-item-last" (click)="showValidationModal($event)" *ngIf="hasRequest==false">
        <div class="drop-elem-content">
          <div>
            <em class="icon-validation icons-margin" style="color: #123B68;"></em>
            <label class="label-validation">
              Validate Project
            </label>
          </div>
          <div class="free-div">
            <label class="label-dollar" style="margin-left: 115px">
              $
              <ng-template #popupContent2>
                <div class="pop-container1">
                  <div class="pop-header d-flex">
                    <em class="icon-info bigger-info1"></em>
                    <label class="pop-title1">
                      Project Validation
                    </label>
                  </div>
                  <p class="info-description" style="color: #123B68 !important;">
                    By requesting project validation an external review of your project will be
                    conducted by the Environmental Engineering Department at ReFlow.
                    All project stages are reviewed including the life cycle stages, the inventory and
                    provided data,
                    and the project assessment outcomes to correlate with the international
                    life cycle assessment ISO standards.
                    The users will receive a validation report from ReFlow as the documentation for the
                    validity of their calculations.
                  </p>
                </div>
              </ng-template>
            </label>
          </div>
          <div class="info-div">
            <em class="icon-info hovered select-item-icon" style="margin-left: 16px;" animation='false' container="body"
              [ngbPopover]="popupContent2" triggers="hover" placement="right"></em>
          </div>
        </div>
      </a>
      <div class="dropdown-item dropdown-item-last" *ngIf="screening?.validated==true" (click)="downloadCertificate()">
        <div class="light-blue" style="display: inline-flex;" *ngIf="screening?.validated==true">
          <em class="icon-accomplished icons-margin"></em>
          <label class="label-validation light-blue">
            Certificate of Validation
          </label>
        </div>
        <label class="label-dollar">
          $
          <ng-template #popupContent2>
            <div class="pop-container1">
              <div class="pop-header d-flex">
                <em class="icon-info bigger-info1"></em>
                <label class="pop-title1">
                  Project Validation
                </label>
              </div>
              <p class="info-description" style="color: #123B68 !important;">
                By requesting project validation an external review of your project will be
                conducted by the Environmental Engineering Department at ReFlow.
                All project stages are reviewed including the life cycle stages, the inventory and
                provided data,
                and the project assessment outcomes to correlate and comply with the international
                life cycle assessment ISO standards.
                The users will receive a validation report from ReFlow as the documentation for the
                validity of their calculations.
              </p>
            </div>
          </ng-template>
          <em class="icon-info hovered select-item-icon" style="margin-left: 16px;" animation='false' container="body"
            [ngbPopover]="popupContent2" triggers="hover" placement="right"></em>
        </label>
      </div>
    </div>
    <p class="title-description small-description">Click here to download your assessment summary as a PDF or Excel
      datasheet</p>
    <img src="../../../../../assets/Loading-icon-animation.svg" class="download-loadExel" alt="" *ngIf="showLoading" />
  </div>
  <hr class="first-for-title" style="margin-top: 90px;">
  <h3 class="sub-header mt60" style="margin-top: 40px !important;">Specifications</h3>
  <hr class="hr-top-2">
  <div class="d-flex high-d-flex mt24">
    <span class="left-container large-left-container">
      <h3 class="text-highlight">Name:</h3>
    </span>
    <span class="right-small-container">
      <h3 class="text-highlight">{{screening?.product.name}}</h3>
    </span>
  </div>
  <div class="d-flex high-d-flex">
    <span class="left-container large-left-container">
      <h3 class="text-highlight">Type:</h3>
    </span>
    <span class="right-small-container">
      <p class="black-text">{{screening?.product.type}}</p>
    </span>
  </div>
  <div class="d-flex high-d-flex">
    <span class="left-container large-left-container">
      <h3 class="text-highlight">Weight:</h3>
    </span>
    <span class="right-small-container">
      <p class="black-text">{{screening?.product.weight}} kg</p>
    </span>
  </div>
  <div class="d-flex high-d-flex">
    <span class="left-container large-left-container">
      <h3 class="text-highlight">Lifespan:</h3>
    </span>
    <span class="right-small-container clear-padding">
      <p class="black-text clear-margin ">{{screening?.product.lifeSpan}} years</p><small
        class="btn-description">(Initial)</small>
    </span>
    <span class="right-small-container clear-padding">
      <p class="black-text">+</p>
    </span>
    <span class="right-small-container clear-padding">
      <p class="black-text clear-margin">{{screeningResult?.extendLifeSpan}} years</p><small
        class="btn-description">(Extended)</small>
    </span>
  </div>
  <div class="important-msg" *ngIf="screening?.timeBased==true">
    <div style="width: 86px;height: 82px;background: #AAD7D4;text-align: center;">
      <img src="../../../../../assets/Time full Blu.png" class="time-full-blu">
    </div>
    <div style="width:620px;height: 82px;background: #DFF1F1;">
      <p class="important-description"><strong>IMPORTANT:</strong> This calculation was made for a specificTime-based calculation of {{screening?.timeSpan}} {{screening?.timeSpanUnit}} only. 
        The emissions in the results is calculated using the total lifetime of the product and any consumed materials.</p>
    </div>
  </div>
  <hr class="hr-top-2">
  <div class="d-flex row-margin">
    <span class="left-container large-left-container">
      <h3 class="text-highlight large-left-container">Function:</h3>
    </span>
    <span class="right-small-container">
      <p class="black-text">{{screening?.product?.quantity}}</p>
    </span>
  </div>
  <div class="d-flex row-margin">
    <span class="left-container large-left-container">
      <h3 class="text-highlight large-left-container">Application:</h3>
    </span>
    <span class="right-small-container">
      <p class="black-text">{{screening?.product?.durability}}</p>
    </span>
  </div>
  <hr class="first-for-title" style="margin-top: 90px !important;">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4 class="project-impact-title" style="margin-top: 0px; text-transform: none !important;">Project Impact Assessment</h4>
        </div>
      </div>
    </span>
  </div>
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Total carbon footprint</h4>
          <small class="btn-description">of the modeled scenario.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
      <h1 class="value">{{screeningResult?.totalCarboonFootprint | number: '1.0-2'}}</h1>
      <p class="value-description">kg CO<sub>2</sub>eq</p>
    </span>
  </div>
  <hr class="hr-other">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Carbon footprint Reduction
            <em class="icon-info hovered select-item-icon" animation='false' container="body"
              [ngbPopover]="popupReduction" triggers="hover" placement="right"></em>
          </h4>
          <ng-template #popupReduction>

            <div class="pop-container">
              <div class="pop-header d-flex">
                <em class="icon-info bigger-info"></em>
                <label class="pop-title">Carbon footprint Reduction
                </label>

              </div>
              <div class="sections-container">
                <div class="basic-section">
                  <label for="" class="section-title">Description:</label>
                  <p class="section-description">This indicator estimates the percentage difference in the total carbon
                    footprint between the project and the hypothetical linear economy scenario.</p>
                </div>

                <div class="tips-section mt24">
                  <img src="../../../../../assets/tip_icon@4x.png" alt="">
                  <div class="tips-container">
                    <p class="tip-text">
                      <strong>Tip</strong>: This indicator should be used with caution since it is assumed that the
                      usage and distribution of the product remain the same. This is not always the case since the mass
                      or performance of a product may change after remanufacturing, refurbishment, or reconditioning.
                    </p>

                  </div>
                </div>
              </div>

            </div>
          </ng-template>
          <small class="btn-description">Compared to the linear economy scenario when any circular strategy is
            included.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
      <h1 class="value" *ngIf="screeningResult?.reductionAmount>=0">{{screeningResult?.totalReduction | number:
        '1.0-2'}}%</h1>
      <h1 class="value" *ngIf="!(screeningResult?.reductionAmount>=0)">{{0 | number: '1.0-2'}}%</h1>
    </span>
  </div>
  <hr class="hr-other">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Carbon Footprint Difference
            <em class="icon-info hovered select-item-icon" animation='false' container="body"
              [ngbPopover]="popupCarbonFootDiff" triggers="hover" placement="right"></em>
          </h4>
          <ng-template #popupCarbonFootDiff>

            <div class="pop-container">
              <div class="pop-header d-flex">
                <em class="icon-info bigger-info"></em>
                <label class="pop-title">Carbon Footprint Difference
                </label>

              </div>
              <div class="sections-container">
                <div class="basic-section">
                  <label for="" class="section-title">Description:</label>
                  <p class="section-description">This indicator estimates the carbon footprint difference between the
                    project and the hypothetical linear economy scenario.</p>
                </div>

                <div class="tips-section mt24">
                  <img src="../../../../../assets/tip_icon@4x.png" alt="">
                  <div class="tips-container">
                    <p class="tip-text">
                      <strong>Tip</strong>: This indicator should be used with caution since it is assumed that the
                      usage and distribution of the product remain the same. This is not always the case since the mass
                      or performance of a product may change after remanufacturing, refurbishment, or reconditioning.
                    </p>

                  </div>
                </div>
              </div>

            </div>
          </ng-template>
          <small class="btn-description">A negative value indicates that the circular scenario has a higher carbon
            footprint than the linear scenario.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
      <h1 class="value">{{screeningResult?.reductionAmount | number: '1.0-2'}}</h1>
      <p class="value-description">kg CO<sub>2</sub>eq</p>
    </span>
  </div>
  <hr class="hr-other  hr-margin">
  <div class="d-flex">
    <span class="left-container">
      <h4>Most impacting life cycle stage</h4>
    </span>
    <span class="right-container">
      <p class="value-description-highlighted">{{maxCycle?.cycleName}}</p>
      <h1 class="value">{{maxCycle?.impact | number: '1.0-2'}}</h1>
      <p class="value-description">kg CO<sub>2</sub>eq</p>
    </span>
  </div>
  <hr class="hr-other">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Life cycle stages impact contribution</h4>
          <small class="btn-description">The graph bellow illustrates the carbon footprint (kg CO<sub>2</sub> eq) of
            each life cycle stage. Move your cursor over to see more details.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
    </span>
  </div>
  <div style="display: block" *ngIf="lifeCycleImpacts" class="chart-block">
    <canvas style="height: 500px;width: 800px;" baseChart [datasets]="lifeCycleImpacts" [labels]="lifeCycleLabels"
      [options]="barChartOptions" [colors]="chartColorsCycle" [legend]="barChartLegend" [chartType]="barChartType">
    </canvas>
  </div>
  <hr class="hr-other" style="margin-top: 50px;">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Most impacting parameter</h4>
          <small class="btn-description">The parameter with the highest carbon footprint.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
      <p class="value-description-highlighted">{{screeningResult?.materialImpacts[0].materialName}}</p>
      <h1 class="value end-text">{{screeningResult?.materialImpacts[0].impact | number: '1.0-2'}}</h1>
      <p class="value-description">kg CO<sub>2</sub>eq</p>
    </span>
  </div>
  <hr class="hr-other">
  <div class="d-flex">
    <span class="left-container">
      <div class="wrapper">
        <div class="missing-wrapper">
          <h4>Most impactful life cycle parameters</h4>
          <small class="btn-description">The graph bellow illustrates the five most impacting parameters and their
            geographical location. Move your cursor over to see the carbon footprint of each parameter and the
            corresponding life cycle stage.</small>
        </div>
      </div>
    </span>
    <span class="right-container">
    </span>
  </div>
  <div style="display: block" *ngIf="materialImpacts" class="chart-block">
    <canvas style="height: 500px;width: 800px;" baseChart [datasets]="materialImpacts" [labels]="MaterialLabels"
      [options]="barChartOptions" [colors]="chartColors" [legend]="barChartLegend" [chartType]="barChartType">
    </canvas>
  </div>
  
  <hr class="hr-other"  style="margin-top: 90px;
    margin-bottom: 0px;
    border: 2px solid #123B68;
    background-color: #123B68;" *ngIf="showScopesCharts">
  <div class="wrapper q-wrapper" *ngIf="showScopesCharts">
    <div class="missing-wrapper" *ngIf="showScopesCharts">
      <div style="display: flex; flex-direction: row;">
      <h3 class="sub-header mt60 q-margin">Emission scopes</h3>
      </div>
      <small class="btn-description">Life cycle emissions can be divided into three emission definitions, or emission
        scopes: Scope 1, Scope 2, and Scope 3 emissions.
        Scope 1 indicates direct greenhouse gas (GHG) emissions that are from sources owned or controlled by the
        assessing entity. Scope 2 indicates indirect GHG emissions associated with the production of electricity, heat,
        or steam purchased by the assessing entity. Scope 3 indicates all other indirect emissions known as supply chain
        emissions hence emissions associated with the extraction and production of purchased materials, fuels, and
        services, including transport in vehicles not owned or controlled by the assessing entity, outsourced
        activities, waste disposal, etc. (IPCC, 2014).</small>
        
    </div>
  </div>
  <div class="d-flex" *ngIf="showScopesCharts">
    <span class="left-container">
      <h4>Most impacting scope</h4>
    </span>
    <span class="right-container">
      <p class="value-description-highlighted">{{highiestScope?.name}}</p>
      <h1 class="value">{{highiestScope?.impact | number: '1.0-2'}}</h1>
      <p class="value-description">kg CO<sub>2</sub>eq</p>
    </span>
  </div>
  <hr class="hr-other  hr-margin" *ngIf="showScopesCharts">
  <div class="wrapper" *ngIf="showScopesCharts">
    <div class="missing-wrapper">
      <h4>SCOPE IMPACT CONTRIBUTION</h4>
      <small class="btn-description">The graph below illustrates the contribution of each scope to the total carbon
        footprint. Move your cursor over to see the percentage of each scope.</small>
    </div>
  </div>
  <div class="chart-container" *ngIf="showScopesCharts">
    <div style="display: block" class="chart-content">
      <canvas baseChart [data]="doughnutScopesData" [labels]="doughnutScopesLabels" [chartType]="doughnutScopesType"
        [colors]="scopesChartColors" [legend]="false"></canvas>
    </div>
    <div class="chart-text-container" *ngIf="showScopesCharts">
      <p class="scope-text"><span class="scope-square one"></span> <label class="donut-legend">Scope 1</label> <label
          class="scope-value">{{screeningResult?.scopeOne | number: '1.0-2'}} kg CO<sub>2</sub> eq</label></p>
      <hr>
      <p class="scope-text"><span class="scope-square two"></span> <label class="donut-legend">Scope 2</label><label
          class="scope-value">{{screeningResult?.scopeTwo | number: '1.0-2'}} kg CO<sub>2</sub> eq</label></p>
      <hr>
      <p class="scope-text"><span class="scope-square three"></span> <label class="donut-legend">Scope 3</label><label
          class="scope-value">{{screeningResult?.scopeThree | number: '1.0-2'}} kg CO<sub>2</sub> eq</label></p>
    </div>
  </div>
  <hr class="hr-other" style="margin-top: 50px;margin-bottom: 0px;" *ngIf="showScopesCharts">
  <div class="wrapper" *ngIf="showScopesCharts">
    <div class="missing-wrapper">
      <h4>SCOPE DISTRIBUTION IN EACH LIFE CYCLE STAGE</h4>
      <small class="btn-description">The graph below illustrates the distribution of the life cycle stages for each
        scopes distribution of each CO<sub>2</sub> impact. Move your cursor over to see the carbon footprint of each
        parameter .</small>
    </div>
  </div>
  <div style="display: block" style="height:250px;width: 630px;" *ngIf="showScopesCharts">
    <canvas baseChart *ngIf="showScopesCharts" [datasets]="lifeCycleChartData" [labels]="barChartLabels"
      [options]="barChartOptionss" [legend]="barChartLegends" [options]="bchartoptions" [chartType]="barChartTypes"
      [legend]="false">
    </canvas>
    <p style="text-align:center;margin-top: 15px;">
      <span class="scope-square one"></span>
      <label class="bar-legend">Scope 1</label>
      <span class="scope-square two"></span>
      <label class="bar-legend">Scope 2</label>
      <span class="scope-square three"></span>
      <label class="bar-legend">Scope 3</label>
    <p>
  </div>
  <hr class="hr-other" style="margin-top: 65px;margin-bottom: 0px;" *ngIf="showScopesCharts">
  <div class="wrapper" *ngIf="showScopesCharts">
    <div class="missing-wrapper">
      <h4>THE SCOPES FIVE MOST IMPACTFUL PARAMETERS</h4>
      <small class="btn-description">The graph below illustrates the distribution of each scopes distribution three most
        impactful parameters. Move your cursor over to see the carbon footprint of each parameter.</small>
    </div>
  </div>
  <div style="display: block" style="height:350px;width: 600px;" *ngIf="showScopesCharts">
    <div class="row" *ngIf="showScopesCharts">
      <div class="scope-col">
        <label class="scope-parameter-title"> Scope 1</label>
        <canvas baseChart
          style="position: relative !important; height: 200px !important; width: 200px !important; margin-bottom: 30px;"
          *ngIf="showScopesCharts" [data]="pieChartData1" [labels]="pieChartLabels1" [chartType]="pieChartType"
          [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [colors]="pieChartColors">
        </canvas>
        <p style="padding-left: 20px;" *ngFor="let materialName of pieChartLabels1; let parameterIndex=index">
          <span class="scope-square"
            [style.background-color]="pieChartColors[0].backgroundColor[parameterIndex]"></span>
          <label class="lg-label">{{parameterIndex+1}} . {{materialName}}</label>
        </p>
      </div>
      <div class="scope-col">
        <label class="scope-parameter-title"> Scope 2</label>
        <canvas baseChart
          style="position: relative !important; height: 200px !important; width: 200px !important;margin-bottom: 30px;"
          *ngIf="showScopesCharts" [data]="pieChartData2" [labels]="pieChartLabels2" [chartType]="pieChartType"
          [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [colors]="pieChartColors">
        </canvas>
        <p *ngFor="let materialName of pieChartLabels2; let parameterIndex=index">
          <span class="scope-square"
            [style.background-color]="pieChartColors[0].backgroundColor[parameterIndex]"></span>
          <label class="lg-label">{{parameterIndex+1}} . {{materialName}}</label>
        </p>
      </div>
      <div class="scope-col">
        <label class="scope-parameter-title"> Scope 3</label>
        <canvas baseChart
          style="position: relative !important; height: 200px !important; width: 200px !important;margin-bottom: 30px;"
          *ngIf="showScopesCharts" [data]="pieChartData3" [labels]="pieChartLabels3" [chartType]="pieChartType"
          [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend" [colors]="pieChartColors">
        </canvas>
        <p *ngFor="let materialName of pieChartLabels3; let parameterIndex=index">
          <span class="scope-square"
            [style.background-color]="pieChartColors[0].backgroundColor[parameterIndex]"></span>
          <label class="lg-label">{{parameterIndex+1}} . {{materialName}}</label>
        </p>
      </div>
    </div>
  </div>
  <hr class="hr-other"
    style="margin-top: 90px;margin-bottom: 0px; border: 2px solid #123B68; background-color: #123B68;">
  <div class="wrapper q-wrapper">
    <div class="missing-wrapper">
      <h3 class="sub-header mt60 q-margin">Validity, Quality and Transparency</h3>
      <small class="btn-description">This section provides essential insights into the assessed project's validity,
        quality, and transparency. The framework used is described at the end of this page.</small>
    </div>
  </div>
  <hr class="hr-other scopes-hr" style="margin-top: 50px;margin-bottom: 50px;">
  <div class="flex-container">
    <div class="container-element left-element">
      <div class="section-title-container">
        <label class="section-title">PROJECT VALIDITY SCORE</label>
        <em *ngIf="false" class="icon-info hovered select-item-icon" animation='false' container="body" [ngbPopover]="validityscore"
          triggers="hover" placement="right"></em>
        <ng-template #validityscore>
          <div class="pop-container">
            <div class="pop-header d-flex">
              <em class="icon-info bigger-info"></em>
              <label class="pop-title">PROJECT VALIDITY SCORE
              </label>
            </div>
            <div class="sections-container">
              <div class="basic-section">
                <label for="" class="section-title">Description:</label>
                <p class="section-description"></p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <small class="btn-description">The Validity score of the project relates to the widespread use of data by the
        assessor. A higher score indicated that the project had been validated by our engineering team in accordance
        with the set guidelines.</small>
    </div>
    <div class="container-element right-element">

      <h4 class="gray-h4 mt16" [ngStyle]="{'color': ['#CCCCCC','#98D6D4','#6487A3','#123B68'][dataSet?.validityScore-1]}">{{['None','Low','Basic','High'][Math.floor(dataSet?.validityScore-1)]}}</h4>
      <h4 class="dark-gray-h4 mt16" [ngStyle]="{'color': ['#CCCCCC','#98D6D4','#6487A3','#123B68'][dataSet?.validityScore-1]}">{{dataSet?.validityScore}}</h4>
      <div class="chart-div">
        <div class="chart-element gray-box" [ngbTooltip]="scaleTipContent" triggers="hover focus" #tn1="ngbTooltip"
          (mouseenter)='toggleTooltip(tn1, "The project is not validated neither created by a supplier")'>None</div>
        <div class="chart-element green-box" [ngbTooltip]="scaleTipContent" triggers="hover focus" #tn2="ngbTooltip"
          (mouseenter)='toggleTooltip(tn2, "The project was created by a supplier but was not validated")'>Low</div>
        <div class="chart-element light-blue-box" [ngbTooltip]="scaleTipContent" triggers="hover focus"
          #tn3="ngbTooltip"
          (mouseenter)='toggleTooltip(tn3, "The project was not created by a supplier but was validated by ReFlow engineers")'>
          Basic</div>
        <div class="chart-element blue-box" [ngbTooltip]="scaleTipContent" triggers="hover focus" #tn4="ngbTooltip"
          (mouseenter)='toggleTooltip(tn4, "The project was both created by a supplier and was validated by ReFlow engineers")'>
          High</div>
      </div>
      <div class="scale-bounds">
        <label id="l1">1</label>
        <label id="l2">4</label>
      </div>
    </div>

  </div>

  <div class="predifined-div longuer-div">
    <div class="info-div-icon longuer-div-icon">
      <img src="../../../../../assets/shieldsvg.svg" style="width: 28px; height: 28px;">
    </div>
    <div class="description-text-predef">
      <p class="pre-def">The Validity of the project relates to the combination of data compared to the documentation
        used by the assessor. Documentation can be a Bill of Materials, logistical documentation, energy bill, or
        recycling documentation. To obtain a high score the assessor has to have an independent validation done by our
        environmental engineering team in accordance with the set framework. For a description of the framework, please
        see the last page of this report.</p>
    </div>
  </div>

  <hr class="hr-other scopes-hr" style="margin-top: 50px;margin-bottom: 50px;">
  <div class="flex-container">
    <div class="container-element left-element">
      <div class="section-title-container">
        <label class="section-title">DATA QUALITY SCORE</label>
        <em *ngIf="false" class="icon-info hovered select-item-icon" animation='false' container="body" [ngbPopover]="qualityscore"
          triggers="hover" placement="right"></em>
      </div>
      <ng-template #qualityscore>
        <div class="pop-container">
          <div class="pop-header d-flex">
            <em class="icon-info bigger-info"></em>
            <label class="pop-title">DATA QUALITY SCORE
            </label>
          </div>
          <div class="sections-container">
            <div class="basic-section">
              <label for="" class="section-title">Description:</label>
              <p class="section-description"></p>
            </div>
          </div>
        </div>
      </ng-template>
      <small class="btn-description">The data quality score evaluates the origin of the data as primary data from a
        supplier ranks higher than standard data from the Assessor. A higher score can also be obtained using custom
        data modeled by the ReFlow engineering team following the actual specifications.</small>
    </div>
    <div class="container-element right-element">
      <h4 class="light-blue-h4 mt16" style="color:#98D6D4 ;" *ngIf="dataSet?.qualityScore<2.0">Low</h4>
      <h4 class="light-blue-h4 mt16" style="color:#6487A3 ;" *ngIf="dataSet?.qualityScore>=2.0&&dataSet?.qualityScore<3.0">Basic</h4>
      <h4 class="light-blue-h4 mt16" style="color:#123B68 ;" *ngIf="dataSet?.qualityScore>=3.0&&dataSet?.qualityScore<=4.0">Hight</h4>
      <h4 class="light-blue-h4 light-blue-h4-bigger mt16" [ngStyle]="{'color': (dataSet?.qualityScore<2.0?'#98D6D4':(dataSet?.qualityScore>=2.0&&dataSet?.qualityScore<=3.0?'#6487A3':(dataSet?.qualityScore>=3.0&&dataSet?.qualityScore<=4.0?'#123B68':'#DDDDDD')))}">
        {{dataSet?.qualityScore | number: '1.0-2'}}
      </h4>
      <div class="chart-div">
        <div class="chart-element green-box" style="width: 33%" [ngbTooltip]="scaleTipContent" triggers="hover focus"
          #tb1="ngbTooltip" (mouseenter)='toggleTooltip(tb1, "Low is classed after the Quality score range 1-2")'>Low
        </div>
        <div class="chart-element light-blue-box" style="width: 33%" [ngbTooltip]="scaleTipContent"
          triggers="hover focus" #tb2="ngbTooltip"
          (mouseenter)='toggleTooltip(tb2, "Basic is classed after the Quality score range 2-3")'>Basic</div>
        <div class="chart-element blue-box" style="width: 33%" [ngbTooltip]="scaleTipContent" triggers="hover focus"
          #tb3="ngbTooltip" (mouseenter)='toggleTooltip(tb3, "High is classed after the Quality score range 3-4")'>High
        </div>
      </div>
      <ng-template #scaleTipContent let-tipcontent="tipcontent">
        <div class="scale-tip-content">
          <label>{{tipcontent}}</label>
        </div>
      </ng-template>
      <div class="scale-bounds">
        <label id="l1">1</label>
        <label id="l2">4</label>
      </div>
    </div>
  </div>

  <div class="predifined-div" style="height: unset;">
    <div class="info-div-icon">
      <img src="../../../../../assets/datasvgicon.svg" style="width: 28px; height: 28px;">
    </div>
    <div class="description-text-predef">
      <p class="pre-def">The quality of the data used in a project is essential to the overall reliability of the
        result. The lower the data quality, the higher the result's uncertainty. There are several ways you can increase
        the quality of your data. <br>- Shared projects or data from suppliers or other companies <br>- Custom-modeled
        data by our engineering team <br>- Verified data from an external party</p>
    </div>
  </div>

  <hr class="hr-other scopes-hr" style="margin-top: 50px;margin-bottom: 50px;">
  <div class="flex-container">
    <div class="container-element left-element full-width" style="width: 100%">
      <div class="section-title-container">
        <label class="section-title">MOST RELEVANT DATASET SCORE (RELEVANCE)</label>
        <em *ngIf="false" class="icon-info hovered select-item-icon" animation='false' container="body" [ngbPopover]="datasetscore"
          triggers="hover" placement="right"></em>
        <ng-template #datasetscore>
          <div class="pop-container">
            <div class="pop-header d-flex">
              <em class="icon-info bigger-info"></em>
              <label class="pop-title">Most relevant dataset score (Relevance)
              </label>
            </div>
            <div class="sections-container">
              <div class="basic-section">
                <label for="" class="section-title">Description:</label>
                <p class="section-description"></p>
              </div>
            </div>
          </div>
        </ng-template>

      </div>
      <small class="btn-description">
        Not all data have the same importance, so we have ranked the data based on the percentage contribution to the
        total emissions for you to easier evaluate the most important datasets.
        Move your cursor hover to see the datasets relevance values.
      </small>
    </div>
  </div>

  <div class="predifined-div">
    <div class="info-div-icon">
      <img src="../../../../../assets/weightscalesvg.svg" style="width: 28px; height: 28px;">
    </div>
    <div class="description-text-predef">
      <p class="pre-def">
        We have arranged the data based on its relevance to the total emissions.
        The higher percentage of the total emissions, the higher the relevance.
        You must pay attention to the higher relevance data to ensure they have a high quality, as that will affect the
        entire project score.
      </p>
    </div>
  </div>


  <ng-template #tipContent let-tipcontent="tipcontent">
    <div class="tootlip-template">
      <label class="title">{{tipcontent['dataSetName']}}</label>
      <div class="value-container">
        <label class="value">{{((tipcontent['dataSetRelevance'])) | number: '1.0-2'}}%</label>
        <label class="label">Impact of the Total carbon footprint</label>
      </div>
      <div class="labels-container">
        <label class="label">Quality score: {{['None','Low','Basic','High'][Math.floor(tipcontent?.dataSetQuality)]}}</label>
        <label class="label">Validity score: N/A</label>
      </div>
    </div>
  </ng-template>

  <div class="bar-chart-container">
    <div class="bar-vr" style="left:25%;"></div>
    <div class="bar-vr" style="left: 50%;"></div>
    <div class="bar-vr" style="left: 75%;"></div>
    <div class="main-bar-container">
      <ng-container *ngFor="let item of datasetColors; let i=index">
        <div 
        class="bar-element"  
        [ngStyle]="{'background-color': item, 'width':((top5[i]?.dataSetRelevance)*100)+'%'}"
        [ngbTooltip]="tipContent"
        triggers="hover focus" #tb2="ngbTooltip"
        (mouseenter)='toggleTooltip(tb2, top5[i])'
        >
        </div>
      </ng-container>
      <div 
        class="bar-element"  
        [ngStyle]="{'background-color': '#F2F2F2', 'width':(totalRelevance-top5relevance)+'%'}"
        placement="top" ngbTooltip="Others ..."
        >
      </div>
    </div>
  </div>

  <div class="values-container">
    <span class="percentage-text">0%</span>
    <span class="percentage-text">25%</span>
    <span class="percentage-text">50%</span>
    <span class="percentage-text">75%</span>
    <span class="percentage-text">100%</span>
  </div>
  <div class="bar-labels-container">
    <div class="element" *ngFor="let item of datasetColors; let i=index">
      <div class="lab-color" [ngStyle]="{'background-color': item}"></div>
      <label>{{i+1}}. {{top5[i]?.dataSetName}}</label>
    </div>
  </div>

  <hr class="hr-other scopes-hr" style="margin-top: 50px;margin-bottom: 50px;">
  <div class="flex-container">
    <div class="container-element left-element full-width" style="width: 100%;">
      <div class="section-title-container">
        <label class="section-title">DATA VALIDITY, QUALITY AND TRANSPARENCY</label>
        <em *ngIf="false" class="icon-info hovered select-item-icon" animation='false' container="body"
          [ngbPopover]="validityQualityTransparencyscore" triggers="hover" placement="right"></em>
        <ng-template #validityQualityTransparencyscore>
          <div class="pop-container">
            <div class="pop-header d-flex">
              <em class="icon-info bigger-info"></em>
              <label class="pop-title">DATA VALIDITY, QUALITY AND TRANSPARENCY
              </label>
            </div>
            <div class="sections-container">
              <div class="basic-section">
                <label for="" class="section-title">Description:</label>
                <p class="section-description"></p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <small class="btn-description">This is a detailed overview of the data used in the project assessed following our
        quality and validity framework. The data is listed under relevance, quality score, and validity (for projects
        only).</small>
    </div>
  </div>

  <div class="tree-with-scroll">
    <div class="valid-container">
      <div class="tree-container">
        <div class="project-container" data-toggle="collapse" [attr.data-target]="'#collapseExample'+dataSet?.id" aria-expanded="true"
          aria-controls="collapseExample1">
          <div class="icon-container gray-box">
            <img src="../../../../../assets/r_p_completed_projects@2x.png">
            <label>Project</label>
          </div>
          <div class="project-data-container">
  
            <div class="less-container">
              <div class="title-container">
                <span class="assessed-name">{{dataSet?.projectName}}</span>
                <span class="owner-name">Owner: {{dataSet?.owner}}</span>
                <span class="sh-more" *ngIf="!showMore[1]" (click)="showMore[1]=!showMore[1];" data-toggle="collapse" 
                aria-expanded="false"
                data-target="#showmore1">Show more</span>
              </div>
              <div class="data-container" style="height: 90px;">
  
                <div class="d-element">
                  <div class="d-element-prefix">
                    <img src="../../../../../assets/shieldsvg.svg" style="height: 14px;">
                    <span class="project-text">Data Validity:</span>
                  </div>
                  <div class="label-val-container">
                    <div class="progress-div" [ngClass]="{'green-box': dataSet?.validityScore==2,'gray-box': dataSet?.validityScore==1}"></div>
                    <div class="progress-div" [ngClass]="{'light-blue-box': dataSet?.validityScore==3,'gray-box': dataSet?.validityScore<3}"></div>
                    <div class="progress-div" [ngClass]="{'blue-box': dataSet?.validityScore==4,'gray-box': dataSet?.validityScore<4}"></div>
                    <span class="project-text">{{['None','Low','Basic','High'][dataSet?.validityScore-1]}}</span>
                  </div>
                </div>
  
                <div class="d-element">
                  <div class="d-element-prefix">
                    <img src="../../../../../assets/datasvgicon.svg" style="height: 14px;">
                    <span class="project-text">Data Quality:</span>
                  </div>
                  <div class="label-val-container">
                    <div class="progress-div green-box" ></div>
                    <div class="progress-div" [ngClass]="{'light-blue-box': Math.floor(dataSet?.qualityScore)>=2,'gray-box': Math.floor(dataSet?.qualityScore)<2}"></div>
                    <div class="progress-div" [ngClass]="{'blue-box': Math.floor(dataSet?.qualityScore)>=3,'gray-box': Math.floor(dataSet?.qualityScore)<3}"></div>
                    <span class="project-text">{{dataSet?.qualityScore<2?'Low':(dataSet?.qualityScore<3&&dataSet?.qualityScore>=2.0?'Basic':(dataSet?.qualityScore>=3?'hight':''))}}</span>
                  </div>
                </div>
  
                <div class="d-element">
                  <div class="d-element-prefix">
                    <img src="../../../../../assets/weightscalesvg.svg" style="height: 14px;">
                    <span class="project-text">Relevance:</span>
                  </div>
                  <div class="label-val-container">
                    <div class="progress-div gray-box" style="width: 60px;">
                      <div class="progress-div green-box" style="width: 100%;">
                      </div>
                    </div>
                    <span class="project-text">100%</span>
                  </div>
                </div>
  
              </div>
            </div>
            <div id="showmore1" class="collapse">
              <div class="more-container mt-2">
                <div class="more-data-row">
                  <label class="data-label m-0 p-0" style="width: 50%;">Issued: {{dataSet?.issuedAt | date:'dd/MM/yyyy'}}</label>
                  <label class="data-label  m-0 p-0">Updated: {{dataSet?.updatedAt | date:'dd/MM/yyyy'}}</label>
                </div>
                <div class="more-data-row">
                  <label class="data-label m-0 p-0">Database: {{dataSet?.dataBase}}</label>
                </div>
                <div class="more-data-row">
                  <label class="data-label m-0 p-0">Data source: {{dataSet?.dataSource}}</label>
                </div>
                <div class="more-data-row">
                  <label class="data-label m-0 p-0">Scope: {{dataSet?.scopes}}</label>
                </div>
                <div class="more-data-row no-bb">
                  <label class="data-label m-0 p-0">Validated: {{dataSet?.validated}}</label>
                </div>
              </div>
            </div>
            <div class="more-data-row d-flex mdr-space no-bb pr-2 justify-content-end" *ngIf="showMore[1]">
              <label class="data-label m-0 p-0">&nbsp;</label>
              <label class="data-label  m-0 p-0"><span class="sh-more" (click)="showMore[1]=!showMore[1]"
                  data-toggle="collapse" data-target="#showmore1">Show less</span></label>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="tel; context{items:dataSet?.dataSetTransperancies,level:1,parent:dataSet?.id}">
        </ng-container>
      </div>
  
  
  
      <ng-template #tel let-items="items" let-level="level" let-parent="parent">
        <div class="collapse" [ngClass]="{'show': level==1}" id="collapseExample{{parent}}"
          [ngStyle]="{'padding-left': (5+(10*(level-1)))+'px'}">
          <div class="tree-container" style="border-left: dashed 1px #123b63;">
            <label class="tier" *ngIf="items!=[]&&items!=null">Tier {{level+1}}</label>
            <ng-container *ngFor="let item of items">
              <div class="element-container">
                <div class="element-tail"></div>
                <div class="project-container" data-toggle="collapse" [attr.data-target]="'#collapseExample'+item?.id">
                  <div class="icon-container gray-box">
                    <img src="../../../../../assets/r_p_completed_projects@2x.png">
                    <label>{{item?.dataSetType}}</label>
                  </div>
                  <div class="project-data-container">
  
                    <div class="less-container">
                      <div class="title-container">
                        <span class="assessed-name">{{item?.dataSetName}}</span>
                        <span class="owner-name">Owner: {{item?.dataSetOwner}}</span>
                        <span class="sh-more" *ngIf="item?.dataSetType=='Shared'" (click)="showMore[item?.id]=!showMore[item?.id]"
                          data-toggle="collapse" aria-expanded="false" [attr.data-target]="'#showmore'+item?.id"
                          [attr.aria-controls]="'#showmore'+item?.id">Show more</span>
                      </div>
                      <div class="data-container" style="height: 90px;">
  
                        <div class="d-element" *ngIf="item?.dataSetType=='Shared'">
                          <div class="d-element-prefix">
                            <img src="../../../../../assets/shieldsvg.svg" style="height: 14px;">
                            <span class="project-text">Data Validity:</span>
                          </div>
                          <div class="label-val-container">
                            <div class="progress-div" [ngClass]="{'green-box': item?.dataSetValidity==2,'gray-box': item?.dataSetValidity==1}"></div>
                            <div class="progress-div" [ngClass]="{'light-blue-box': item?.dataSetValidity==3,'gray-box': item?.dataSetValidity<3}"></div>
                            <div class="progress-div" [ngClass]="{'blue-box': item?.dataSetValidity==4,'gray-box': item?.dataSetValidity<4}"></div>
                            <span class="project-text">{{['','None','Low','Basic','High'][item?.dataSetValidity]}}</span>
                          </div>
                        </div>
  
                        <div class="d-element">
                          <div class="d-element-prefix">
                            <img src="../../../../../assets/datasvgicon.svg" style="height: 14px;">
                            <span class="project-text">Data Quality:</span>
                          </div>
                          <div class="label-val-container">
                            <div class="progress-div" [ngClass]="{'green-box': Math.floor(item?.dataSetQuality)>=1,'gray-box': Math.floor(item?.dataSetQuality)<1}"></div>
                            <div class="progress-div" [ngClass]="{'light-blue-box': Math.floor(item?.dataSetQuality)>=2,'gray-box': Math.floor(item?.dataSetQuality)<2}"></div>
                            <div class="progress-div" [ngClass]="{'blue-box': Math.floor(item?.dataSetQuality)>=3,'gray-box': Math.floor(item?.dataSetQuality)<3}"></div>
                            <span class="project-text">{{['None','Low','Basic','High'][Math.floor(item?.dataSetQuality)]}}</span>
                          </div>
                        </div>
  
                        <div class="d-element">
                          <div class="d-element-prefix">
                            <img src="../../../../../assets/weightscalesvg.svg" style="height: 14px;">
                            <span class="project-text">Relevance:</span>
                          </div>
                          <div class="label-val-container">
                            <div class="progress-div gray-box" style="width: 60px;">
                              <div class="progress-div green-box" [ngStyle]="{'width': Math.ceil(item?.dataSetRelevance).toPrecision(4)+'%'}">
                              </div>
                            </div>
                            <span class="project-text">{{item?.dataSetRelevance | number: '1.0-2'}}%</span>
                          </div>
                        </div>
  
                      </div>
                    </div>
                    <div id="showmore{{item?.id}}" class="collapse">
                      <div class="more-container mt-2">
                        <div class="more-data-row">
                          <label class="data-label m-0 p-0" style="width: 50%;">Issued: {{item?.issuedAt | date:'dd/MM/yyyy' }}</label>
                          <label class="data-label  m-0 p-0">Updated: {{item?.updatedAt | date:'dd/MM/yyyy' }}</label>
                        </div>
                        <div class="more-data-row">
                          <label class="data-label m-0 p-0">Database: {{item?.dataBase}}</label>
                        </div>
                        <div class="more-data-row">
                          <label class="data-label m-0 p-0">Data source: {{item?.dataSource}}</label>
                        </div>
                        <div class="more-data-row">
                          <label class="data-label m-0 p-0">Scope: {{item?.scopes}}</label>
                        </div>
                        <div class="more-data-row no-bb">
                          <label class="data-label m-0 p-0">Validated: {{item?.validated}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="more-data-row d-flex mdr-space no-bb pr-2 justify-content-end" *ngIf="showMore[item?.id]">
                      <label class="data-label m-0 p-0">&nbsp;</label>
                      <label class="data-label  m-0 p-0"><span class="data-label  m-0 p-0 sh-more"
                          (click)="showMore[item?.id]=!showMore[item?.id]" data-toggle="collapse"
                          [attr.data-target]="'#showmore'+item?.id"
                          [attr.aria-controls]="'#showmore'+item?.id"
                          >Show less</span></label>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="tel; context{items:item['dataSetTransperancies'],level:(level+1),parent:item['id']}">
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-template>
  
    </div>
  </div>



  <hr class="hr-other" style="margin-top: 50px;margin-bottom: 50px;" *ngIf="showScopesCharts">
  <span class="left-container assessment-title">
    <h4>DOWNLOAD YOUR ASSESMENT SUMMARY HERE:</h4>
  </span>
  <label style="text-align: center;" class="title-description">The Assessment Summary provides a project outline
    including all key project information and assessment outputs. The Assessment summary does not entitle any
    validation but is proof for having conducted an LCA-based assessment.</label>
  <button class="big-turq assessment" (click)="downloadProofOfAssessment()">
    <em class="fa fa-download"></em>
    <label>
      Download
    </label>
  </button>
  <img src="../../../../../assets/Loading-icon-animation.svg" class="download-load" alt="" *ngIf="downloadPdf" />
  <app-validation-request-modal (toogleModal)="showValidationModal($event)" [screeningId]="screeningId"
    [showModal]="showValidationView" *ngIf="showValidationView"></app-validation-request-modal>
</div>
<app-submit-correction-modal *ngIf="showCorrectionDialog"
  (submitModal)="showCorrectionModal($event)"></app-submit-correction-modal>