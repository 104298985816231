import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: "app-sp-side-menu",
  templateUrl: "./sp-side-menu.component.html",
  styleUrls: ["./sp-side-menu.component.scss"],
})
export class SpSideMenuComponent implements OnInit {
  public currentUser: any;
  public currentUserAccess: any;
  public showResetView: boolean = false;
  public showImageView: boolean = false;
  public userRole:any="";
  constructor(
    private userService: UserService,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.currentUserData();
    this.userRole = localStorage.getItem("user_role");
  }

  public logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.auth.logout({ returnTo: `${window.location.origin}/login` });
    localStorage.clear();
  }

  public currentUserData(): void {
    this.userService.getCurrentUser().subscribe(user=>{
      this.currentUserAccess = user;
      this.userService
      .getUserById(Number(user.userId))
      .subscribe((res) => {
        this.currentUser = res;
        if (this.currentUser.profilePicture == "") {
          this.currentUser.profilePicture = "../../../assets/loginbg.jpg";
        }
      },
      error => {
        //this.authService.logout();
      });
    });
    
  }
  public navigateHome(): void {
    let companyId: number = Number(localStorage.getItem("company_id"));
    this.router.navigate(["/homeServiceProvider", companyId]);
  }

  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
    this.showImageView = false;
  }

  public showImageModal(isShow: boolean) {
    this.showImageView = isShow;
    this.showResetView = false;
  }
}
