import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Company } from "../models/company.model";
import { Co2LightDto } from "../models/dtos.model";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class Co2LightService {
  environmentUrl = "Debug api";

  
  constructor(
    private http: HttpClient,
     private helperService:HelperService,
     ) {
    this.environmentUrl = environment.apiUrl;
  }

  public lightCalculate(lightForm: Co2LightDto): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/co2/lightCalculator`,
      lightForm,
      this.helperService.headers()
    );
  }

  public getAttemps(userId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/co2/getAttemps/` + userId,
      this.helperService.headers()
    );
  }
}
