import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rq-view-questionnaire',
  templateUrl: './rq-view-questionnaire.component.html',
  styleUrls: ['./rq-view-questionnaire.component.scss']
})
export class RqViewQuestionnaireComponent implements OnInit {

  activeItem: string = 'Environment and climate';
  activeSidebarItem: string = 'Steel';
  public showPassModal:boolean=false;
  public modalTitle = '';
  constructor() { }

  ngOnInit(): void {
  }


  setActiveItem(item) {
    this.activeItem = item;
  }

  setActiveSidebarItem(item: string) {
    this.activeSidebarItem = item;
  }
  public closePassModal(event){
    this.showPassModal=false;
  }

  openModal(title: string) {
    this.modalTitle = title;
    this.showPassModal = true;
  }
}
