import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public requestValidation(addValidationRequestDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/validations/new`,
      addValidationRequestDto,
      this.helperService.headers()
    );
  }

  public getRequests(): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/validations`,
      this.helperService.headers()
    );
  }

  public updateRequestStatus(updateValidationStatusDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/validations/updateStatus`,
      updateValidationStatusDto,
      this.helperService.headers()
    );
  }

  public getHistory(screeningId:any): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/validations/history/`+screeningId,
      this.helperService.headers()
    );
  }

  public hasValidationRequest(screeningId:any): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/validations/hasValidationRequest/`+screeningId,
      this.helperService.headers()
    );
  }

  public uploadCorrectionFile(file: File): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.environmentUrl}/api/validations/upload`, 
      formData,
      this.helperService.headers()
    );
  }

  public updateFile(updateCorrectionSheetDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/validations/updateFile`,
      updateCorrectionSheetDto,
      this.helperService.headers()
    );
  }

  public flagAsUrgent(id: number): any {
    return this.http.put<any>(
      `${this.environmentUrl}/api/validations/flagurgent/`+id,null,
      this.helperService.headers()
    );
  }

  public notifyAdmin(screeningId): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/validations/notifyAdmin/`+screeningId,
      this.helperService.headers()
    );
  }

  public updateResponsible(updateResponsibleDto: any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/validations/updateResponsible`,
      updateResponsibleDto,
      this.helperService.headers()
    );
  }

}
