import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class LifeCycleServiceService {

  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getTransports(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/transport/`+lifeCycleId, this.helperService.headers());
  }

  public getEnergies(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/energies/`+lifeCycleId, this.helperService.headers());
  }

  public getManufacturing(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/manufacturing/`+lifeCycleId, this.helperService.headers());
  }

  public getRecycling(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/recycling/`+lifeCycleId, this.helperService.headers());
  }

  public getDisposal(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/disposal/`+lifeCycleId, this.helperService.headers());
  }
  
  public getRestCycles(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/restCycles/`+lifeCycleId, this.helperService.headers());
  }

  public getAllCycles(lifeCycleId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/allCycles/`+lifeCycleId, this.helperService.headers());
  }

  public getMaterials(lifeCycleId:any): any {
    this.http.get<any>(`${this.environmentUrl}/api/lifeCycle/material/`+lifeCycleId, this.helperService.headers()).subscribe(res=>{
      return res;
    });
  }
}
