import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { ComponentEditDto } from "src/app/models/dtos.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-component-details",
  templateUrl: "./component-details.component.html",
  styleUrls: ["./component-details.component.css"],
})
export class ComponentDetailsComponent implements OnInit, OnChanges {
  public showLog: boolean = false;
  public showDetails: boolean = true;
  public showFiles: boolean = false;
  public showCore: boolean = false;
  public currentComponent: ComponentEditDto;
  public showPictureView: boolean = false;
  public srcImg: string;

  @Input() public componentId: number;
  @Output() public closeModal = new EventEmitter();
  @Output() public openStatus = new EventEmitter();

  constructor(private componentService: ComponentCoreInformationService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    this.showComponent();
  }

  public showComponent(): void {
    this.componentService
      .getComponentById(this.componentId)
      .subscribe((res) => {
        this.currentComponent = res;
      });
  }

  public resetTabs() {
    this.showLog = false;
    this.showDetails = false;
    this.showFiles = false;
  }

  public hideModal(): void {
    this.closeModal.emit(false);
  }
  public closeModalEdit(event: boolean) {
    this.hideModal();
  }

  public hideDetailsShowStatus(): void {
    this.hideModal();
    this.openStatus.emit({ show: true, component: this.currentComponent });
  }

  public changeSuppRef(event: any): void {
    this.currentComponent.supplierReference = event;
  }

  public previewPdf() {
    window.open(location.origin + `/print/` + this.componentId, "_blank");
  }

  public showPictureModal(isShow: boolean) {
    this.srcImg = this.currentComponent.componentPicture;
    this.showPictureView = isShow;
  }
}
