<div class="card-container">
    <div class="cover-container">
        <img [src]="videoToPlay?.coverPath" alt="" class="cover"
        onerror="this.onerror=null;this.src='../../../../../assets/academy/Asset 13.svg';"
        >
        <label class="play">
            Play
            <i class="fa fa-play" aria-hidden="true"></i>
        </label>
    </div>
    <label class="video-title" >{{videoToPlay?.title}}</label>
    <div class="level-container d-flex" *ngIf="false">
        <label class="lv-label">{{videoToPlay?.level?videoToPlay?.level:'Beginner'}}</label>
        <em class="icon-remove"></em>
        <label class="lv-label">{{countDuration(videoToPlay)}}</label>
    </div>
    <div *ngIf="false" class="rating-container d-flex">
        <div class="rt-container">
            <ng-container *ngFor="let item of[].constructor(Math.floor( videoToPlay?.rating )); let i = index">
                <i class="fa fa-star" aria-hidden="true"></i>
            </ng-container>
            <i *ngIf="videoToPlay?.rating>Math.floor( videoToPlay?.rating )" class="fa fa-star-half-o" aria-hidden="true"></i>
            <ng-container *ngFor="let item of[].constructor(5-(Math.round( videoToPlay.rating ))); let i = index">
                <i class="fa fa-star-o" aria-hidden="true"></i>
            </ng-container>
        </div>
        <em class="icon-remove"></em>
        <label class="rt-label">{{videoToPlay?.rating}}</label>
    </div>
    <div *ngIf="false" class="rating-container d-flex">
        <div class="rt-container">
            <ng-container *ngFor="let item of[].constructor(5); let i = index">
                <i class="fa fa-star-o" aria-hidden="true"></i>
            </ng-container>
        </div>
        <em class="icon-remove"></em>
        <label class="rt-label">0</label>
    </div>
</div>