import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { CompanyService } from "src/app/services/company.service";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { Company } from "src/app/models/company.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-edit-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.css"],
})
export class EditCompanyComponent implements OnInit {
  public companyToCreate: FormGroup;
  public submitted = false;
  public currentCompany: Company = new Company();
  @Output() toogleModal = new EventEmitter();

  @Input() public showForm: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private uploadService: FileUploadService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params["id"];
      this.companyService.retrieveCustomerCompany(id).subscribe((res) => {
        this.currentCompany = res;
        this.companyToCreate = this.fb.group({
          id: this.currentCompany.id,
          companyName: [this.currentCompany.companyName, Validators.required],
          vatNumber: [this.currentCompany.vatNumber, Validators.required],
          address: [this.currentCompany.address, Validators.required],
          zipCode: [this.currentCompany.zipCode, Validators.required],
          city: [this.currentCompany.city, Validators.required],
          country: [this.currentCompany.country, Validators.required],
          billingEmail: [this.currentCompany.billingEmail, Validators.required],
          reflowPoints: [this.currentCompany.reflowPoints, Validators.required],
          external: this.currentCompany.external,
          companyType: [this.currentCompany.companyType, Validators.required],
          companyLogo: this.currentCompany.companyLogo,
          billingContact: this.fb.group({
            id: [this.currentCompany.billingContact?.id],
            contactName: [
              this.currentCompany.billingContact?.contactName,
              Validators.required,
            ],
            contactTitle: [
              this.currentCompany.billingContact?.contactTitle,
              Validators.required,
            ],
            adresseEmail: [
              this.currentCompany.billingContact?.adresseEmail,
              Validators.required,
            ],
            phoneNumber: [
              this.currentCompany.billingContact?.phoneNumber,
              Validators.required,
            ],
          }),
          companyContact: this.fb.group({
            id: [this.currentCompany.companyContact?.id],
            contactName: [
              this.currentCompany.companyContact?.contactName,
              Validators.required,
            ],
            contactTitle: [
              this.currentCompany.companyContact?.contactTitle,
              Validators.required,
            ],
            adresseEmail: [
              this.currentCompany.companyContact?.adresseEmail,
              Validators.required,
            ],
            phoneNumber: [
              this.currentCompany.companyContact?.phoneNumber,
              Validators.required,
            ],
          }),
        });
      });
    });
    this.companyToCreate = this.fb.group({
      companyName: ["", Validators.required],
      vatNumber: ["", Validators.required],
      address: ["", Validators.required],
      zipCode: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", Validators.required],
      billingEmail: ["", Validators.required],
      reflowPoints: ["", Validators.required],
      external: false,
      companyType: ["", Validators.required],
      companyLogo: ["test.png"],
      billingContact: this.fb.group({
        id: [0],
        contactName: ["", Validators.required],
        contactTitle: ["", Validators.required],
        adresseEmail: ["", Validators.required],
        phoneNumber: ["", Validators.required],
      }),
      companyContact: this.fb.group({
        id: [0],
        contactName: ["", Validators.required],
        contactTitle: ["", Validators.required],
        adresseEmail: ["", Validators.required],
        phoneNumber: ["", Validators.required],
      }),
    });
  }

  get frm() {
    return this.companyToCreate.controls;
  }

  public uploadFile(files) {
    this.uploadService.uploadFile(files[0], 0).subscribe((res) => {
      const uploadfile = new UploadPhotoModel(res);
      this.companyToCreate.patchValue({ companyLogo: uploadfile.fileName });
    });
  }

  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }

  public deleteCompany() {
    
  }

  public editCompany() {
    const company: Company = new Company(this.companyToCreate.value);
    this.companyService
      .editCustomerCompany(company, company.id)
      .subscribe((res) => {
        this.toastr.warning("Company", "Company infomations updated!");
        this.hideModal();
      });
  }
}
