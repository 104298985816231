import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { ReCaptcha2Component } from "ngx-captcha";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { verificationCodeDto } from "src/app/models/dtos.model";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit,OnChanges {
  public aFormGroup: FormGroup;
  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;
  @ViewChild("langInput") langInput: ElementRef;

  @Output() goToComponent= new EventEmitter();
  @Input() currentStep;
  public  x : string = 'openmodal'

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public isVerified = true;
  public loading = false;
  public verificationFailed = false;
  public loginResult: any;
  public captchaResponse?: string;
  public startedSteps=false;
  public signupValues=null;
  public current_year = new Date().getFullYear();
  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "en";
  public type: "image" | "audio";

  public siteKey = "6Lez6NMZAAAAAMD7ZBqIH-0Zj4fHvqNkwFy7th83";

  public submitted: boolean = false;
  public emailExists: boolean = false;
  public existingMail: string = "";

  loginForm = new FormGroup({
    EmailAddress: new FormControl(""),
    Password: new FormControl(""),
  });

  signupForm = this.fb.group({
    //companyName: ["", Validators.required],
    fullName: ["", Validators.required],
    password: ["", Validators.required],
    repeatPassword: ["", Validators.required],
    workEmail: ["", Validators.required],
    phoneNumber: ["", Validators.required],
    recaptcha: ["", Validators.required],
    companyname: ["", Validators.required],
    industry: ["", Validators.required],
    address: ["", Validators.required],
    postalcode: ["", Validators.required],
    city: ["", Validators.required],
    country: ["", Validators.required],
  });

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private toastr:ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  public hidePassword: boolean = true;
  public loginFailed: boolean = false;
  public loginShow: boolean = true;
  ngOnInit(): void {
    localStorage.setItem('monObjet', this.x);
    localStorage.removeItem('signupValues');
    this.signupForm.valueChanges.subscribe( (ans) => {
      this.cdr.detectChanges()
      this.signupValues=ans
    })
  }
  ngOnChanges(changes) {
  }

  public tooglePassword(): void {
    this.hidePassword = !this.hidePassword;
  }
  get frm() {
    return this.signupForm.controls;
  }
  public login(): void {
    this.userService.loginUser(this.loginForm.value).subscribe(
      (res: any) => {
        this.loginResult = res;
        if (res.verified == true) {
          this.navigateFromLogin();
        } else {
          this.isVerified = res.verified;
          this.loginShow = false;
        }
      },
      (error) => {
        this.loginFailed = true;
      }
    );
  }

  public verifyCode(code): void {
    let codeDto: verificationCodeDto = {
      userId: this.loginResult.userId,
      verificationCode: Number(code.value),
    };
    this.userService.verifyCode(codeDto, this.loginResult).subscribe((res) => {
      if (res.result == "success") {
        this.navigateFromLogin();
      }
    });
  }

  public navigateFromLogin(): void {
    //localStorage.setItem("access_token", this.loginResult.token);
    localStorage.setItem("user_id", this.loginResult.userId);
    localStorage.setItem("user_role", this.loginResult.role);
    localStorage.setItem("company_type", this.loginResult.type);
    localStorage.setItem("company_id", this.loginResult.companyId);
    localStorage.setItem("permission", this.loginResult.permission);
    localStorage.setItem("thread", "");
    if (this.loginResult.role == "Super Admin") {
      this.router.navigate(["/homeAdmin"]);
    } else if (this.loginResult.type == "Supplier") {
      if (this.loginResult.permission == "Free") {
        this.router.navigate([
          "/homeServiceProvider",
          this.loginResult.companyId,
          "co2",
        ]);
      } else {
        this.router.navigate([
          "/homeServiceProvider",
          this.loginResult.companyId,
          "decarbonizer"
        ]);
      }
    } else if (this.loginResult.type == "Vessel Owner") {
      if (this.loginResult.permission == "Free") {
        this.router.navigate([
          "/homeServiceProvider",
          this.loginResult.companyId,
          "co2",
        ]);
      } else {
        this.router.navigate([
          "/homeVesselOperator",
          this.loginResult.companyId,
        ]);
      }
    }
  }



  gotoContact(){
    location.replace("https://www.re-flow.io/contact/")
  }

  goToTarget(targetComponent){
    this.goToComponent.emit(targetComponent);
  }


}
