import { Component, OnInit } from '@angular/core';
import { ModulesService } from '../../../services/yardscore-services/modules.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-questionnaire-modules',
  templateUrl: './questionnaire-modules.component.html',
  styleUrls: ['./questionnaire-modules.component.scss']
})
export class QuestionnaireModulesComponent implements OnInit {

  public showUpsertForm:boolean=false;
  public showDeleteForm:boolean=false;
  public actionType="";
  public currentModule:any=null;
  public category_id:any=null;
  public modules:any=null;
  public toSortBy="Date";

  constructor(
    private route: ActivatedRoute,
    private modulesService:ModulesService
  ) { }

  ngOnInit(): void {
    this.category_id = this.route.snapshot.paramMap.get('category_id');
    console.log(this.route.snapshot.paramMap);
    
    this.getModules();
  }

  public closeUpsertForm(event){
    this.showUpsertForm=false;
    this.getModules();
  }

  public toggleUpsertForm(action,module){
    this.showUpsertForm=true;
    this.currentModule=module;
    this.actionType=action;
  }

  public closeDeleteModal(event){
    this.showDeleteForm=false;
    this.getModules();
  }

  public toggleDeleteForm(item){
    this.currentModule=item;
    this.showDeleteForm=true;
  }

  public getModules(){
    this.modulesService.getModules(this.category_id).subscribe(ans=>{
      this.modules=ans;
    },err=>{
      console.log(err);
    })
  }
  
}
