import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public shareWithCompanies(companies): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/share/shareWith`,
      companies,
      this.helperService.headers()
    );
  }

  public shareScreening(shareScreeningDto:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/share/Screening`,
      shareScreeningDto,
      this.helperService.headers()
    );
  }

  public importScreening(importScreeningDto:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/share/import`,
      importScreeningDto,
      this.helperService.headers()
    );
  }

  public getComanyShared(companyId): any {
    this.helperService.headers()
    return this.http.get<any>(`${this.environmentUrl}/api/share/sharedwithMe/` + companyId, this.helperService.headers());
  }

  public getPublicAssessment(sharingCode:any): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/share/public-assessment/`+sharingCode
    );
  }

  public getinvitedCompanies(screeningId:any): any {
    this.helperService.headers()
    return this.http.get<any>(
      `${this.environmentUrl}/api/share/invitedCompanies/`+screeningId, this.helperService.headers()
    );
  }

  public getsharedScreeningOverview(screeningId:any): any {
    this.helperService.headers()
    return this.http.get<any>(
      `${this.environmentUrl}/api/share/sharedScreeningOverview/`+screeningId, this.helperService.headers()
    );
  }

  public downloadShareCode(data): any {
    return this.http.post(
      `${this.environmentUrl}/api/share/downloadShareCode`,
      data,
      {
        responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        })
      }
    );
  }

  public getCompanyRequests(companyId:any): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/share/requests/`+companyId, this.helperService.headers()
    );
  }

  public requestData(data:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/share/request-data`,
      data,
      this.helperService.headers()
    );
  }

  public confirmShared(requestId:any): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/share/confirm-request/`+requestId, this.helperService.headers()
    );
  }
}
