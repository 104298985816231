import { Component, OnInit, Input } from "@angular/core";
import { CompanyService } from "src/app/services/company.service";
import { Company } from "src/app/models/company.model";
import { CompanyAssessmentService } from "src/app/services/company-assessment.service";

@Component({
  selector: "app-companies-overview",
  templateUrl: "./companies-overview.component.html",
  styleUrls: ["./companies-overview.component.scss"],
})
export class CompaniesOverviewComponent implements OnInit {
  @Input() public creditModalEvent: boolean;
  @Input() public transferModalEvent: boolean;
  public showEditView: boolean = false;
  public showCompanyDetails: boolean = false;
  public companies: Array<Company>;
  public searchTerm: string;
  public fieldName: string;
  public attr: string;
  public showCreditModal: boolean = false;
  public showAdjustModal: boolean = false;
  public showSubmscriptionModal: boolean = false;
  public showTransferModal: boolean = false;
  public initialCredit;
  public company_id;
  public company ; 

  public openCreditModal:string="open-credit-modal";
  public closeCreditModal:string="close-credit-modal";

  constructor(
    private companyService: CompanyService,
    private companyAssessmentService: CompanyAssessmentService,
  ) { }

  ngOnInit(): void {
    this.retrieveCustomers();
  }

  public retrieveCustomers() {
    this.companyService.retrieveCustomersCompany().subscribe((res) => {
      this.companies = res; 
    });
  }

  public showEditModal(isShow: boolean) {
    this.showEditView = isShow;
    this.showCompanyDetails = false;
    this.retrieveCustomers();
  }

  public showDetailsModal() {
    this.showCompanyDetails = true;
    this.showEditView = false;
  }

  orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }

  public creditModalActions(event:any):void{
    if(event==this.openCreditModal){
      this.showCreditModal=true;
    }
    else if(event==this.closeCreditModal){
      this.showCreditModal=false;
      this.retrieveCustomers();
    }
  }
  public adjustModalActions(event:any):void{
    if(event==this.openCreditModal){
      this.showAdjustModal=true;
    }
    else if(event==this.closeCreditModal){
      this.showAdjustModal=false;
    }
  }
  
  public SubscriptionModalActions(event:any):void{
    if(event==this.openCreditModal){
      this.showSubmscriptionModal=true;
    }
    else if(event==this.closeCreditModal){
      this.showSubmscriptionModal=false;
      this.retrieveCustomers();
    }
  }

  public TransferModalActions(event:any):void{
    if(event==this.openCreditModal){
      this.showTransferModal=true;
    }
    else if(event==this.closeCreditModal){
      this.showTransferModal=false;
    }
  }
  
  public setCompanyData(company_id,initialCredit):void{
    this.company_id = company_id;
    this.initialCredit = initialCredit
    this.showCreditModal = true;
  }
  public adjustRessources(company,initialCredit):void{
    this.company = company;
    this.initialCredit = initialCredit
    this.showAdjustModal = true;
  }

  public openSubscriptionModal(company_id):void{
    this.company_id = company_id;
    this.showSubmscriptionModal = true;
  }

  public openTransferModal(company_id):void{
    this.company_id = company_id;
    this.showTransferModal = true;
  }

  public changeClimateHubAccess(event, companyId):void{
      this.companyAssessmentService.UpdateCompanyAssessmentAccess({companyId:companyId, haveAccess:event.target.checked}).subscribe(res=>{
        this.retrieveCustomers();
      });
    
  }

}
