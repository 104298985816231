<div class="row area-row">
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-1">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
    >
      <div>
        <div class="cont-center">
          <img
            style="margin-top: 13px;"
            src="../../../../../assets/calculator/scenario-new-inactive.svg"
            alt=""
            class="area-image other"
          />
          <label class="area-label-desactive">Manufacture</label>
          <label class="sub-label-desactive">coming soon</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-1">
    <div
      class="shadow p-3 mb-5 bg-white rounded box col-scenario"
      (click)="chooseScenario('Repair')"
    >
      <div [class.cont-shadow]="scenario == 'Repair'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/scenario-repair-default.svg"
            alt=""
            class="area-image repair"
            *ngIf="repair && scenario == ''"
          />
          <img
            src="../../../../../assets/calculator/scenario-repair-inactive.svg"
            alt=""
            class="area-image repair"
            *ngIf="!repair"
          />
          <img
            src="../../../../../assets/calculator/scenario-repair-active.svg"
            alt=""
            class="area-image repair"
            *ngIf="scenario == 'Repair'"
          />
          <label class="area-label">Repair</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-1">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
    >
      <div >
        <div class="cont-center">
          <img
            style="margin-top: 13px;"
            src="../../../../../assets/calculator/scenario-refurb-inactive.svg"
            alt=""
            class="area-image other"
          />
          
          <label class="area-label-desactive">Refurbish</label>
          <label class="sub-label-desactive">coming soon</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-1">
    <div
      class="shadow p-3 mb-5 bg-white rounded box"
      (click)="chooseScenario('Remanufacturing')"
    >
      <div [class.cont-shadow]="scenario == 'Remanufacturing'">
        <div class="cont-center">
          <img
            src="../../../../../assets/calculator/scenario-reman-default.svg"
            alt=""
            class="area-image reman"
            *ngIf="remanufacturing && scenario == ''"
          />
          <img
            src="../../../../../assets/calculator/scenario-reman-inactive.svg"
            alt=""
            class="area-image reman"
            *ngIf="!remanufacturing"
          />
          <img
            src="../../../../../assets/calculator/scenario-reman-active.svg"
            alt=""
            class="area-image reman"
            *ngIf="scenario == 'Remanufacturing'"
          />
          <label class="area-label">Remanufacture</label>
        </div>
      </div>
    </div>
  </div>
  
  
</div>
