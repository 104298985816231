import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { CompanyService } from "src/app/services/company.service";
import { ActivatedRoute } from "@angular/router";
import { Company } from "src/app/models/company.model";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";
import { ComponentCoreInformation } from "src/app/models/component-core-information.model";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { WarehouseService } from "src/app/services/warehouse.service";
import { Warehouse } from "src/app/models/warehouse";
import { ComponentLogService } from "src/app/services/component-log.service";
import { ComponentLog } from "src/app/models/component-log";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-component-edit-view",
  templateUrl: "./component-edit-view.component.html",
  styleUrls: ["./component-edit-view.component.css"],
})
export class ComponentEditViewComponent implements OnInit {
  public componentToEdit: FormGroup;
  public filesToUpload: any[];
  public componentFiles: FormArray;
  public submitted: boolean = false;
  public profilImage: string = "Choose an image or drag it here";
  public statusList: string[] = [
    "Servicing",
    "In supplier warehouse",
    "Transit to vessel operator",
    "In Vessel operator warehouse",
    "Received on vessel",
    "Stocked on vessel",
    "In use",
    "Returned for service",
  ];
  public typeList: string[] = [
    "Hull structure",
    "Propulsion systems",
    "Auxiliary systems",
    "Electric systems",
    "Navigation and communication systems",
    "Accommodation",
  ];
  public owners: Company[];
  public warehouses: Warehouse[];
  public suppliers: Company[];
  public showAddFile: boolean = false;
  public showWarehouse: boolean = false;
  public txttype: string;
  public txtstatus: string;
  public txtmanufacturter: string;
  public txtwarehouse: string;
  public companyId: number;
  public txtowner: string;

  @Input() public showEditModal: boolean;
  @Output() public closeModal = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private componentCoreInfomationService: ComponentCoreInformationService,
    private uploadService: FileUploadService,
    private warehouseService: WarehouseService,
    private componentLogService: ComponentLogService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.companyService
        .getComponentOwners(this.companyId)
        .subscribe((res) => {
          this.owners = res;
        });
      this.companyService.getSuppliers().subscribe((res) => {
        this.suppliers = res;
      });
      this.warehouseService
        .retrieveWarehouses(this.companyId)
        .subscribe((res) => (this.warehouses = res));
      this.initForm();
    });
  }

  public initForm(): void {
    this.componentToEdit = this.fb.group({
      reflowId: [null],
      ownerId: [null],
      owner: [null, Validators.required],
      manufacturerId: [0],
      manufacturer: ["", Validators.required],
      type: ["", Validators.required],
      weight: [0],
      companyId: this.companyId,
      specification: ["", Validators.required],
      condition: ["", Validators.required],
      status: ["", Validators.required],
      warehouseId: [0],
      warehouse: [""],
      description: [""],
      serialNumber: ["", Validators.required],
      supplierReference: ["", Validators.required],
      componentPicture: [""],
      recommendedHours: [0],
      noX: [false],
      classCertificate: [false],
      componentFiles: this.fb.array([]),
      statusList: this.fb.array([]),
    });
  }

  public hideModal() {
    this.showEditModal = false;
    this.closeModal.emit(this.showEditModal);
  }

  public selectOwner(owner: Company) {
    this.componentToEdit.patchValue({
      ownerId: owner.id,
      owner: owner.companyName,
    });
  }

  public selectManufacturer(supplier: Company) {
    this.componentToEdit.patchValue({
      manufacturerId: supplier.id,
      manufacturer: supplier.companyName,
    });
  }

  get frm() {
    return this.componentToEdit.controls;
  }

  public selectType(type: string) {
    this.componentToEdit.patchValue({ type: type });
  }

  public selectStatus(status: string) {
    this.componentToEdit.patchValue({ status: status });
    if (status == "In supplier warehouse") {
      this.showWarehouse = true;
    } else {
      this.showWarehouse = false;
    }
  }

  selectWarehouse(warehouse: any): void {
    this.componentToEdit.patchValue({
      warehouseId: warehouse.id,
      warehouse: warehouse.warehouseName,
    });
  }

  public updateComponent() {
    this.submitted = true;
    if (this.componentToEdit.invalid) {
      return;
    }
    let cci = new ComponentCoreInformation(this.componentToEdit.value);
    this.componentCoreInfomationService
      .addCompanyComponent(cci)
      .subscribe((res) => {
        let componentLog: ComponentLog = {
          id: 0,
          componentId: Number(res),
          userId: Number(localStorage.getItem("user_id")),
          createdAt: new Date(),
          action: "create new componenet",
          componentLogFiles:
            this.componentFiles != undefined ? this.componentFiles.value : [],
        };
        this.componentLogService
          .addComponentLog(componentLog)
          .subscribe((log) => {
            this.toastr.success(
              "Component",
              "componenet core informations saved succefuly!"
            );
          });
        this.hideModal();
      });
  }

  public updateFiles(file: any): void {
    this.componentFiles = this.componentToEdit.get(
      "componentFiles"
    ) as FormArray;
    this.filesToUpload = this.componentFiles.value;
    this.componentFiles.push(file);
    this.showAddFile = false;
  }

  public uploadFile(files) {
    this.uploadService.uploadFile(files[0], 0).subscribe((res) => {
      const uploadfile = new UploadPhotoModel(res);
      this.componentToEdit.patchValue({
        componentPicture: uploadfile.fileName,
      });
      this.profilImage = files[0].name;
    });
  }

  public reset() {
    this.ngOnInit();
  }
}
