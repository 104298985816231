import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MastersheetService } from '../../../services/mastersheet.service';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-groups-overview',
  templateUrl: './groups-overview.component.html',
  styleUrls: ['./groups-overview.component.scss']
})
export class GroupsOverviewComponent implements OnInit {
  
  public currentGroup= null;
  public displayUpsertGroup=false;
  public groupToEdit: FormGroup;
  public groupsList:any[]=[];
  public curentGroupId:Number;
  constructor(
    private fb: FormBuilder,
    private masterSheetService:MastersheetService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.curentGroupId=Number(params['inventoryId']);
      this.getGroups(this.curentGroupId);
      this.groupToEdit = this.fb.group({
        //id:[this.currentGrp?this.currentGrp.id:""],
        groupName:[this.currentGroup?this.currentGroup.groupName:""],
        databaseId:[params['inventoryId']]
      });
    });
    //this.masterSheetService.
  }

  public getGroups(groupId:Number):void{
    this.masterSheetService.groupsOfInventory(groupId).subscribe(ans=>{
      this.groupsList=ans;
    });
  }


  public showUpsertGroup(currentGrp){
    
    this.currentGroup=currentGrp;
    this.displayUpsertGroup=true;
  }

  public hideUpsertGroup(result){
    this.displayUpsertGroup=false;
    if(result!=null){
      if(this.currentGroup==null){
        this.groupsList.push(result);
      }else{
        this.groupsList[this.groupsList.indexOf(this.currentGroup)]=result;
        this.currentGroup=null;
      }
      
    }
  }

  public delete(subgr){
    this.groupsList.splice(this.groupsList.indexOf(subgr),1);

  }

  public navigate(subgr){
    //this.navigation.emit({type:"Groups",data:subgr,title:subgr.groupeName})
  }

  public dismissModal(){
    
  }

  public save(){

    if(!this.currentGroup){
      this.masterSheetService.createGroup(this.groupToEdit.value).subscribe(res=>{
        this.getGroups(this.curentGroupId);
        this.displayUpsertGroup=false;
      });
      //store database
    }else{
      this.groupsList[this.groupsList.indexOf(this.currentGroup)]=this.groupToEdit.value;
      this.currentGroup=null;
    }
    
  }
}
