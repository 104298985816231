
<div class="row row-section">
    <label class="h2-heading">Credit Overview</label>
    <canvas class="chart-image" id="lineChart"></canvas>
</div>
<div class="row row-section">
    <label class="h2-heading">Company Users</label>
    <button class="small-turq add" (click)="showUserForm()">
        <label>
            Add User
        </label>
    </button>
    <table class="re-table">
      <caption></caption>
      <thead>
        <tr>
          <th id=""><label style="margin-left: 10px;">User</label></th>
          <th id="">Role</th>
          <th id="">Mail</th>
          <th id="">Last Login</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of companyUsers">
          <td style=" font-weight: bold;">
            <img src="../../../assets/favicon.png" alt="Avatar" />
            {{ user.fullName }}
          </td>
          <td>{{ user.role }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.lastLogin | date:'longDate' }}</td>
          <td>
            <em class="fa fa-trash" (click)="showDeleteModal(user)"></em>
          </td>
        </tr>
      </tbody>
    </table>
</div>
<div class="reflow-form" *ngIf="showForm">
    <form [formGroup]="userToCreate" autocomplete="off">
      <div class="fixed-form-header">
        <div class="header-content">
          <label class="form-title">Add user</label>
          <em class="fa fa-times form-close" (click)="hideForm()"></em>
        </div>
      </div>
      <div class="form-container">
        <div class="row" style="padding-top: 20px;">
          <div class="field">
            <input class="re-input" type="email" formControlName="email" placeholder="Email" autocomplete="off" />
            <label class="float-label">Email</label>
            <small *ngIf="submitted && frm.email.errors?.required">*email is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="password" formControlName="password" placeholder="Password" autocomplete="new-password" />
            <label class="float-label">Password</label>
            <small *ngIf="submitted && frm.password.errors?.required">*password is required</small>
          </div>
        </div>
        
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="fullName" required />
            <label class="float-label">Full name</label>
            <small *ngIf="submitted && frm.fullName.errors?.required">*name is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="title" required />
            <label class="float-label">Title</label>
            <small *ngIf="submitted && frm.title.errors?.required">*title is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="department" required />
            <label class="float-label">Department </label>
            <small *ngIf="submitted && frm.department.errors?.required">*department is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="phoneNumber" required />
            <label class="float-label">Mobile number</label>
            <small *ngIf="submitted && frm.phoneNumber.errors?.required">*mobile is required</small>
          </div>
        </div>
      </div>
  
      <div class="fixed-form-footer">
        <div class="footer-content">
          <button class="btn-solid default" (click)="hideForm()">Back</button>
          <button class="btn-solid secondary" (click)="addUser()">Add</button>
        </div>
      </div>
    </form>
</div>
<div id="myModal" class="modal" *ngIf="showDelete">
    <div class="modal-content">
      <p>Please confirm deleting this user  </p>
      <div class="row">
        <label class="data-title">Full Name : </label>
        <label class="data-number">{{userToDelete?.fullName}} </label>
      </div>
      <div class="row">
        <label class="data-title">Email : </label>
        <label class="data-number">{{userToDelete?.email}} </label>
      </div>
      <div class="row" style="margin-top: 15px;">
        <button class="small-inactive" style="margin-left: 10px;" (click)="closeModal()" >
            <label>
                Cancel
            </label>
        </button>
        <button class="small-turq confirm" (click)="confirmDelete()">
            <label>
                Delete
            </label>
        </button>
        </div>
    </div>
        
</div>