<div id="clouds">
  <div class="cloud x1"></div>
  <div class="cloud x1_5"></div>
  <div class="cloud x2"></div>
  <div class="cloud x3"></div>
  <div class="cloud x4"></div>
  <div class="cloud x5"></div>
</div>
<div class="c">
  <div class="_404">Oops!</div>
  <hr />
  <div class="_1">Many attemps for today</div>
  <div class="_2">Contact us to get the premium version</div>
  <a class="btn" href="#">Back To Home</a>
</div>
