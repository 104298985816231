import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-success-signup',
  templateUrl: './success-signup.component.html',
  styleUrls: ['./success-signup.component.scss']
})
export class SuccessSignupComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    let id=null;
    let state=null;
    let subscribedUser=JSON.parse(localStorage.getItem('signupValues'));
    //localStorage.removeItem('signupValues');
    this.activatedRoute.queryParams.subscribe(params => {
      state=params.state;
      id=params.id;
      subscribedUser.subscriptionId=params.id;
      if(!id||!state){
        this.router.navigate(['/login']);
      }
      
      // api call goes here
      /**
       * 
       */
      
      this.userService.chargebeeSignupUser(subscribedUser).subscribe(ans=>{
        this.router.navigate(['/login']);
      },err=>{
      })
  });
  }

}
