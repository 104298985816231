import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public totalCredit:number;
  public amoutOfCalculationDone;
  public total_refill = 850;
  public companyNumber :Number;
  public registred_user_value ;
  public calculated_co2_value;
  public activeUsers:any=[];
  public consumptionDone:any[];
  public flowcreditDone : any[];
  calculated_co2_start: number =0;
  registred_user_start: number =0;
  amount_start:number;
  refill_start: number =0;

  calculated_co2_count_stop: any = setInterval(()=>{
    if(this.calculated_co2_start == this.calculated_co2_value)
    {
      clearInterval(this.calculated_co2_count_stop)
    }else{
      this.calculated_co2_start++;
    }
  },10)

  amount_count_stop: any = setInterval(()=>{
    if(this.amount_start == this.totalCredit)
    {
      clearInterval(this.amount_count_stop)
    }else{
      this.amount_start++;
    }
  },10)
  refill_count_stop: any = setInterval(()=>{
    this.refill_start++;
    if(this.refill_start == this.total_refill)
    {
      clearInterval(this.refill_count_stop)
    }
  },10)

  registred_user_stop: any = setInterval(()=>{
    
    if(this.registred_user_start == this.registred_user_value)
    {
      clearInterval(this.registred_user_stop)
    }else{
      this.registred_user_start++;
    }
  },100)
  //amount chart
  amountChartData: ChartDataSets[] = [
    { data: [],label: '' },
  ];
  amountChartLabels: Label[] = [];  
  //refill chart
  refillChartData: ChartDataSets[] = [
    { data: [], label: ''},
  ];
  refillChartLabels: Label[] = [];
  refillChartOptions = {
    responsive: true,
  }; 
  //chart options
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: '#123b63',
      backgroundColor: '#ffffff00',
    },
  ];
 
  constructor(private dashboardservice : DashboardService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getDashboardResults();
  }
  public getDashboardResults() {
    this.dashboardservice.getDashboardResults().subscribe((res) => {
      this.totalCredit=res.totalCredit ;
      this.amoutOfCalculationDone = res.calculationDone
      this.registred_user_value = res.numberOfUser
      this.activeUsers = res.activeUsers;
      this.calculated_co2_value = res.coCalculated;
      this.consumptionDone = res.consumptionDone;
      this.companyNumber = res.companyNumber;
      this.flowcreditDone =res.creditDone;
      this.amountChartData.forEach(element => {
        this.consumptionDone.forEach(element1 => {
          element.data.push(element1.amount)
        });
      });
        this.consumptionDone.forEach(element1 => {
          this.amountChartLabels.push(new DatePipe('en-US').transform(element1.date, 'dd-MM'))
      });
      this.refillChartData.forEach(element => {
        this.flowcreditDone.forEach(element1 => {
          element.data.push(element1.amount)
        });
      });

      this.flowcreditDone.forEach(element1 => {
        this.refillChartLabels.push(new DatePipe('en-US').transform(element1.date, 'dd-MM'))
    });
    });
  }

}
