import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyAssessmentDataService } from 'src/app/services/company-assessment-data.service';

@Component({
  selector: 'app-company-assessment-process',
  templateUrl: './company-assessment-process.component.html',
  styleUrls: ['./company-assessment-process.component.scss']
})
export class CompanyAssessmentProcessComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentProcess:any= null;
  public displayUpsertProcess=false;
  public processToEdit: FormGroup;
  public locationArray: FormArray;
  public curentEndGroupId:Number;
  public processList=[];
  public companies:any[]=[];

  constructor(
    private fb: FormBuilder,
    private companyAssessmentDataService:CompanyAssessmentDataService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.curentEndGroupId=Number(params['endGroupId']);
      this.getProcesses(this.curentEndGroupId);
      this.processToEdit = this.fb.group({
        name:[this.currentProcess?this.currentProcess.name:""],
        unit:[this.currentProcess?this.currentProcess.unit:""],
        description:[this.currentProcess?this.currentProcess.description:""],
        hasChildren:[this.currentProcess?(this.currentProcess.hasChildren==true?"Yes":"No"):"No"],
        companyAssessmentEndGroupId:[this.curentEndGroupId],
        scopeType:[this.currentProcess?this.currentProcess.scopeType:""],
        emission:[this.currentProcess?this.currentProcess.emission:0],
        emissionEV:[this.currentProcess?this.currentProcess.emissionEV:0],

      });
    })
  }
  public getProcesses(endgroupeid){
    this.companyAssessmentDataService.processesOfEndGroup(endgroupeid).subscribe(ans=>{
      this.processList=ans;
    })
  }

  public showUpsertProcess(currentDb){
    this.currentProcess=currentDb;
    this.processToEdit = this.fb.group({
      name:[this.currentProcess?this.currentProcess.name:""],
      unit:[this.currentProcess?this.currentProcess.unit:""],
      description:[this.currentProcess?this.currentProcess.description:""],
      companyAssessmentEndGroupId:[this.curentEndGroupId],
      scopeType:[this.currentProcess?this.currentProcess.scopeType:""],
      emission:[this.currentProcess?this.currentProcess.emission:0],
      emissionEV:[this.currentProcess?this.currentProcess.emissionEV:0],
    });
    this.displayUpsertProcess=true;
  }

  public hideUpsertEndGroup(result){
    
  }

  public selectCompany(companyId:any):void{
    this.processToEdit.get('companyId').patchValue(companyId);
  }

  public dismissModal(){
    this.displayUpsertProcess=false;
  }

  public upsert(){
    var result=this.processToEdit.value;
    result.hasChildren=result.hasChildren=='No'?false:true
    if(this.currentProcess==null){
      this.companyAssessmentDataService.createProcess(result).subscribe(ans=>{
        this.displayUpsertProcess=false;
        this.getProcesses(this.curentEndGroupId);
      })
    }else{
      
    }
  }

  public delete(db){
  }

  public navigate(db){
  }

  public toggleProcess(verdict){
    
  }

}
