<div #modal class="sidebar " id="sidebar">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create Process</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="processToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="procName"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            
            <div class="row">
                <div class="field">
                    <select class="re-input" formControlName="procUnit">
                        <option selected value>Km </option>
                        <option>kWh</option>
                    </select>
                    <label class="float-label">Unit</label>
                    <small *ngIf="false">*role is required</small>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="procEmission"
                        required
                    />
                    <label class="float-label">Emission</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <textarea class="re-input mt-2"
                        type="text"
                        formControlName="procDescription"
                        required rows="3" cols=""></textarea>
                    <label class="float-label">Description</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            
        </form>
        <br>
        <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
    </div>

</div>