<div class="container" style="margin-top: 10%;">
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <div>
            <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">#</th>
                    <td><strong>total usage</strong></td>
                    <td>{{totalUsage}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div>
            <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">#</th>
                    <td><strong>current month usage</strong></td>
                    <td>{{currentMonthUsage}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
            
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <div class="table-container">
    <div class="row table-data-item">
      <div class="data-row">
        <div class="head-item ">company Contact</div>
        <div class="head-item ">company Email</div>
        <div class="head-item ">company Name</div>
        <div class="head-item ">estimated Carbon Footprint</div>
        <div class="head-item ">product</div>
        <div class="head-item ">unit</div>
      </div>
      <div style="width: 45px;">
      </div>
    </div>
    <ng-container>
      <div class="row table-data-item" *ngFor="let request of usageCalculations?.slice(x, y) | validationSearchFilter:keyword;let i = index">
        <div class="data-row borders">
          <div class="row-item " *ngIf="request.companyContact; else noData">{{request.companyContact}}</div>
          <ng-template class="row-item" #noData> <div class="row-item">No data</div> </ng-template>
          <div class="row-item ">{{request.companyEmail}}</div>
          <div class="row-item ">{{request.companyName}}</div>
          <div class="row-item ">{{request.estimatedCarbonFootprint | number:'1.3-3'}}</div>
          <div class="row-item ">{{request.product}}</div>
          <div class="row-item " *ngIf="request.unit; else noData1">{{request.unit}}</div>
          <ng-template class="row-item " #noData1><div class="row-item">#</div></ng-template>
        </div>

      </div>
    </ng-container>
    <div class="pagination" style="text-align:center;justify-content:center;">
      <a>&laquo;</a>
      <a (click)="changePaginationPrev()">Prev</a>
      <a>{{pageNumber}}</a>
      <a (click)="changepaginationValue()">next</a>
      <a>&raquo;</a>
    </div>
  </div>
    </div>
</div>