<div class="questionnaire-management-container">
    <div class="questionnaire-card" [routerLink]="['./repairyard/categories']" routerLinkActive="router-link-active" >
        <div class="layer"></div>
        <div class="edit-questionnaire">
            <img src="../../../../assets/yardscore-assets/ys-i-edit-wraped.svg" alt="">
        </div>
        <img src="../../../../assets/yardscore-assets/ys-i-repair-yard.svg" alt="" class="yard-icon">
        <label class="yard-title">Questionnaire Ship Repair Yard</label>
    </div>
    <div class="questionnaire-card ml-3" [routerLink]="['./recyclingyard/categories']" routerLinkActive="router-link-active" >
        <div class="layer"></div>
        <div class="edit-questionnaire">
            <img src="../../../../assets/yardscore-assets/ys-i-edit-wraped.svg" alt="">
        </div>
        <img src="../../../../assets/yardscore-assets/ys-i-recycling-yard.svg" alt="" class="yard-icon">
        <label class="yard-title">Questionnaire Ship Recycling Yard</label>
    </div>
</div>
