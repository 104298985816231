import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { VesselService } from "src/app/services/vessel.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Vessel } from "src/app/models/vesset";
import { VesselOverviewDto } from "src/app/models/dtos.model";

@Component({
  selector: "app-vessels-overview",
  templateUrl: "./vessels-overview.component.html",
  styleUrls: ["./vessels-overview.component.css"],
})
export class VesselsOverviewComponent implements OnInit {
  public vessels: Array<VesselOverviewDto>;
  public showEditView: boolean = false;
  public showVesselDetails: boolean = false;
  public searchTerm: string;
  public fieldName: string;
  public attr: string
  public selectedVessel: Vessel;
  private routeSub: Subscription;
  public companyId: number;

  constructor(
    private vesselService: VesselService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this.companyId = Number(params["companyId"]);
      this.showVesselsList();
    });
  }

  public showEditModal(isShow: boolean) {
    this.showEditView = isShow;
    this.showVesselDetails = false;
    if (!isShow) {
      this.ngOnInit();
    }
  }
  public selectVessel(event: any, vessel: any) {
    if (event.target.tagName == "TD") {
      this.router.navigate([
        "/homeVesselOperator/",
        this.companyId,
        "vessels",
        vessel.id,
      ]);
    }
  }

  public showDetailsModal() {
    this.showVesselDetails = true;
    this.showEditView = false;
  }

  public btnEditClicked(vessel: any): void {
    this.selectedVessel = vessel;
    this.showEditModal(true);
  }

  public showVesselsList(): void {
    this.vesselService.getVesselsoverview(this.companyId).subscribe((res) => {
      this.vessels = res;
    });
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
