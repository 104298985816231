import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {
  Math: any;
  @Input() videoToPlay:any;
  constructor() { }

  ngOnInit(): void {
    this.Math = Math;
  }

  public countDuration(video){
    let sections=video['sections'].map(x=>x.time);
    let totalDuration=0;
    sections.forEach(section => {
      let mmss=section.split(":");
      totalDuration+=parseInt(mmss[0])*60+parseInt(mmss[1])
    });
    
    let mm:number=totalDuration/60;
    let ss=totalDuration%60;
    let hh=0;
    
    if(mm>=60){
      hh=mm/60;
      mm=mm%60;
    }
    
    return (hh>0?this.addLeadingZeros(hh)+':':'')+this.addLeadingZeros(mm)+':'+this.addLeadingZeros(ss);;
  }

  public addLeadingZeros(timestamp){
    timestamp=parseInt(timestamp);
    if(timestamp>=0&&timestamp<10){
      return "0"+timestamp;
    }
    return timestamp;
  }


}
