<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <em
        class="fa fa-bars form-edit"
        data-toggle="collapse"
        data-target="#target"
        *ngIf="warehouseToEdit != null"
      ></em>
      <em
        class="fa fa-trash-o delete collapse"
        id="target"
        *ngIf="warehouseToEdit != null"
        (click)="deleteWarehouse()"
      ></em>
      <label class="form-title">{{ action }} Warehouse</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="warehouseToCreate">
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="warehouseName"
            placeholder="Warehouse name"
          />
          <label class="float-label"
            >Warehouse name<span class="star">*</span></label
          >
          <small *ngIf="submitted && frm.warehouseName?.errors?.required">
            *Warehouse name is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="address1"
            placeholder="Address 1"
          />
          <label class="float-label"
            >Address 1<span class="star">*</span></label
          >
          <small *ngIf="submitted && frm.address1?.errors?.required">
            *Address 1 is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="address2"
            placeholder="Adresse 2"
          />
          <label class="float-label">Address 2</label>
        </div>
      </div>
      <div class="row inline">
        <div class="field" style="width: 32%;">
          <input
            class="re-input"
            type="number"
            formControlName="postalCode"
            placeholder="Postal code"
          />
          <label class="float-label"
            >Postal code<span class="star">*</span></label
          >
          <small *ngIf="submitted && frm.postalCode?.errors?.required">
            *Postal code is required
          </small>
        </div>
        <div class="field" style="width: 56%;">
          <input
            class="re-input"
            type="text"
            formControlName="city"
            placeholder="City"
          />
          <label class="float-label">City<span class="star">*</span></label>
          <small *ngIf="submitted && frm.city?.errors?.required">
            *City is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="country"
            placeholder="Country"
          />
          <label class="float-label">Country<span class="star">*</span></label>
          <small *ngIf="submitted && frm.country?.errors?.required">
            *Country is required
          </small>
        </div>
      </div>
      <div formGroupName="contactPerson">
        <label class="section-title">CONTACT PERSON</label>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="fullName"
              placeholder="Full name"
            />
            <label class="float-label"> Full name</label>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="email"
              formControlName="email"
              placeholder="Email"
            />
            <label class="float-label"> Email</label>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="phone"
              placeholder="Phone"
            />
            <label class="float-label"> Phone</label>
          </div>
        </div>
      </div>
      <div class="fixed-form-footer">
        <div class="footer-content">
          <button class="btn-solid default" (click)="reset()">Cancel</button>
          <button class="btn-solid secondary" (click)="updateWarehouse()">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
