<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Fleet </label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
            name="searchTerm" />
        </div>
        <div class="col-md-4">
          <button class="btn-solid primary" id="addBtn" (click)="selectedVessel = null; showEditModal(true)">
            <em class="fa fa-plus"></em>Add Vessel
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('vesselName', '')">
          <label style="margin-left: 10px;">Name</label>
        </th>
        <th id="" (click)="orderBy('imo', '')">IMO</th>
        <th id="" (click)="orderBy('mmsi', '')">MMSI</th>
        <th id="" (click)="orderBy('vesselType', '')">Type</th>
        <th id="" (click)="orderBy('coSaving', '')">Saved Co2</th>
        <th id="" (click)="orderBy('componentNumber', '')">Components</th>
        <th id="" (click)="orderBy('tte', '')">Condition</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="
          let vessel of vessels | filterPipe: searchTerm | sort: fieldName:attr
        " id="select" (click)="selectVessel($event, vessel)">
        <td style="font-weight: bold;">
          <img class="left-image" src="{{ vessel.vesselPicture }}" alt="vessel Picture" />
          {{ vessel.vesselName }}
        </td>

        <td>{{ vessel.imo }}</td>
        <td>{{ vessel.mmsi }}</td>
        <td>{{ vessel.vesselType }}</td>
        <td>{{ vessel.coSaving }}</td>
        <td>{{ vessel.componentNumber }}</td>
        <td>
          <label class="tte-label">
            <span class="dot-red" *ngIf="25 > vessel.tte"></span>
            <span class="dot-yellow" *ngIf="vessel.tte > 25 && 75 > vessel.tte"></span>
            <span class="dot-green" *ngIf="vessel.tte > 75"></span>
          </label>
        </td>
        <td>
          <em class="icon-edit" (click)="btnEditClicked(vessel)"> </em>
          <em class="fa fa-bars" [routerLink]="['vessels/', vessel.id]"></em>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-edit-vessel (toogleModal)="showEditModal($event)" [showForm]="showEditView" [vesselToEdit]="selectedVessel"
  [companyId]="companyId" *ngIf="showEditView"></app-edit-vessel>
  