<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content">
        <div class="rightside">
            <div class="rightside-container">
                <img src="../../assets/reqdemo.jpg" alt="">

                <span *ngIf="selected=='assetTracking'">
                    <div class=" title">
                        Asset tracking
                    </div>
                    <p class="description">
                        Keep track of your components and systems after they have left your workshop or vessel. Our
                        unique
                        ID ensues traceability as the foundation of a circular economy.
                    </p>
                </span>
                <span *ngIf="selected=='serviceHistory'">
                    <div class=" title">
                        Service history
                    </div>
                    <p class=" description">
                        We keep a block-chain based log of the entire service and usage history of maritime components
                        or systems. A service history retaining a components value for resale, end-of-life or retrofit
                        scenarios.
                    </p>
                </span>
                <span *ngIf="selected=='certificates'">
                    <div class="title">
                        Certificates
                    </div>
                    <p class="description">
                        No need to keep paper certificates, repair reports, service letters and manuals in paper format.
                        We gather all the relevant information and make sure you are notified when new information is
                        available.
                    </p>
                </span>
                <span *ngIf="selected=='usage'">
                    <div class="title">
                        Usage
                    </div>
                    <p class="description">
                        We make it possible for ship owners and suppliers to share usage of a component or system making
                        it easy to plan maintenance and exchange of units.
                    </p>
                </span>
                <span *ngIf="selected=='carbonFootprint'">
                    <div class="title">
                        Carbon footprint
                    </div>
                    <p class="description">
                        We document and estimate the carbon footprint for a system or components using AI. Buying a
                        remanufactured components can lower the carbon footprint of the vessel - we document this.
                    </p>
                </span>

                <ul id="menu">
                    <li>
                        <div class="bullet" (click)="selected='assetTracking'"
                            [ngStyle]="{'background-color':selected === 'assetTracking' ? '#123B63' : '#EFEFEF' }">
                        </div>
                    </li>
                    <li>
                        <div class="bullet" (click)="selected='serviceHistory'"
                            [ngStyle]="{'background-color':selected === 'serviceHistory' ? '#123B63' : '#EFEFEF' }">
                        </div>
                    </li>
                    <li>
                        <div class="bullet" (click)="selected='certificates'"
                            [ngStyle]="{'background-color':selected === 'certificates' ? '#123B63' : '#EFEFEF' }"></div>
                    </li>
                    <li>
                        <div class="bullet" (click)="selected='usage'"
                            [ngStyle]="{'background-color':selected === 'usage' ? '#123B63' : '#EFEFEF' }">
                        </div>
                    </li>
                    <li>
                        <div class="bullet" (click)="selected='carbonFootprint'"
                            [ngStyle]="{'background-color':selected === 'carbonFootprint' ? '#123B63' : '#EFEFEF' }">
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="title-left">
            Manage your assets and vessels
        </div>
        <div class="row" style="margin-top: 32px;  margin-left: auto;margin-right: auto;">
            <div class="col-4">
                <div class="row" style="margin-right: auto;margin-left: auto;">
                    <div class="circle" (click)="selected='assetTracking';"
                        [ngStyle]="{'background-color':selected==='assetTracking'?'#97D6D4':'#274D72'}">
                        <span class="icon-asset-tracking-active">
                            <span
                                [ngClass]="{'path11':selected==='assetTracking','path1':selected!='assetTracking'}"></span>
                            <span
                                [ngClass]="{'path12':selected==='assetTracking','path2':selected!='assetTracking'}"></span>
                            <span
                                [ngClass]="{'path13':selected==='assetTracking','path3':selected!='assetTracking'}"></span>
                            <span
                                [ngClass]="{'path14':selected==='assetTracking','path4':selected!='assetTracking'}"></span>
                        </span>
                    </div>
                    <p>Asset tracking</p>
                </div>
            </div>
            <div class="col-4">
                <div class="row" style="margin-right: auto;margin-left: auto;">
                    <div class="circle" (click)="selected='serviceHistory';"
                        [ngStyle]="{'background-color':selected==='serviceHistory'?'#97D6D4':'#274D72'}">
                        <span class="icon-service-history-active">
                            <span
                                [ngClass]="{'path21':selected==='serviceHistory','path1':selected!='serviceHistory'}"></span>
                            <span
                                [ngClass]="{'path22':selected==='serviceHistory','path2':selected!='serviceHistory'}"></span>
                            <span
                                [ngClass]="{'path23':selected==='serviceHistory','path3':selected!='serviceHistory'}"></span>
                        </span>
                    </div>
                    <p>Service history</p>
                </div>
            </div>
            <div class="col-4">
                <div class="row" style="margin-right: auto;margin-left: auto;">
                    <div class="circle" (click)="selected='certificates';"
                        [ngStyle]="{'background-color':selected==='certificates'?'#97D6D4':'#274D72'}">
                        <span class="icon-certificate-active">
                            <span
                                [ngClass]="{'path31':selected==='certificates','path1':selected!='certificates'}"></span>
                            <span
                                [ngClass]="{'path32':selected==='certificates','path2':selected!='certificates'}"></span>
                        </span>
                    </div>
                    <p>Certificates</p>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 24px;  margin-left: auto;margin-right: auto;">
            <div class="col-4">
                <div class="row" style="margin-right: auto;margin-left: auto;">
                    <div class="circle" (click)="selected='usage';"
                        [ngStyle]="{'background-color':selected==='usage'?'#97D6D4':'#274D72'}">
                        <span class="icon-usage-active">
                            <span [ngClass]="{'path41':selected==='usage','path1':selected!='usage'}"></span>
                            <span [ngClass]="{'path42':selected==='usage','path2':selected!='usage'}"></span>
                        </span>
                    </div>
                    <p>Usage</p>
                </div>
            </div>
            <div class="col-4">
                <div class="row" style="margin-right: auto;margin-left: auto;">
                    <div class="circle" (click)="selected='carbonFootprint';"
                        [ngStyle]="{'background-color':selected==='carbonFootprint'?'#97D6D4':'#274D72'}">
                        <span class="icon-carbon-footprint-active">
                            <span
                                [ngClass]="{'path51':selected==='carbonFootprint','path1':selected!='carbonFootprint'}"></span>
                            <span
                                [ngClass]="{'path52':selected==='carbonFootprint','path2':selected!='carbonFootprint'}"></span>
                        </span>
                    </div>
                    <p>Carbon footprint</p>
                </div>
            </div>
            <div class="col-4">
            </div>
        </div>
        <a rel="noopener noreferrer" href="http://www.re-flow.io/contact/" target="_blank">
            <button class="btn-solid secondary">
                Request demo
            </button>
        </a>
    </div>
</div>