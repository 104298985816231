<div #modal class="sidebar " id="sidebar">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create End-group</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="endGroupToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="endGrpName"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            
            <span *ngIf="endGroupToEdit.value['endGrphasProcess']=='Yes'">
                <div class="row">
                    <div class="field">
                        <select class="re-input" formControlName="endGrpUnit">
                            <option selected value>Km </option>
                            <option>kWh</option>
                        </select>
                        <label class="float-label">Unit</label>
                        <small *ngIf="false">*role is required</small>
                    </div>
                </div>
                <div class="row">
                    <div class="field">
                        <input
                            class="re-input"
                            type="text"
                            formControlName="endGrpEmission"
                            required
                        />
                        <label class="float-label">Emission</label>
                        <small *ngIf="false">
                        *Name is required
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="field">
                        <textarea class="re-input mt-2"
                            type="text"
                            formControlName="endGrpDescription"
                            required rows="3" cols=""></textarea>
                        <label class="float-label">Description</label>
                        <small *ngIf="false">
                        *Name is required
                        </small>
                    </div>
                </div>
            </span>
            
            
            <div class="row" [ngClass]="{'mt-5': endGroupToEdit.value['endGrphasProcess']=='Yes'}">
                <div class="field">
                    <div class="re-input re-styled d-flex mt-2 ">
                        <div class="rb d-flex align-items-center">
                            <input type="radio" value="Yes" formControlName="endGrphasProcess" (change)="toggleProcess(true)" [checked]="endGroupToEdit.value.endGroupToEdit=='Yes'">
                            <span class="ml-1">Yes</span>
                        </div>
                        <div class="rb d-flex align-items-center ml-2">
                            <input type="radio" value="No" formControlName="endGrphasProcess" (change)="toggleProcess(false)" [checked]="endGroupToEdit.value.endGroupToEdit=='No'">
                            <span  class="ml-1">No</span>
                        </div>
                    </div>
                    <label class="float-label">Has process ?</label>
                    <small *ngIf="false">
                    *Version is required
                    </small>
                </div>
            </div>
            
        </form>
        <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
    </div>

</div>