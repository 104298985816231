<div id="openModal-about" class="modalDialog">
  <div class="re-modal-content">
    <form [formGroup]="passwordToReset">
      <div class="rightside">
        <div class="rightside-container">
          <label class="big-text">Password must contain</label>
          <label class="small-text">At least 1 upper case letter</label>
          <label class="small-text">At least 1 number (0-9)</label>
          <label class="small-text">At least 8 characters</label>
        </div>
      </div>
      <div class="re-modal-header">
        <label class="modal-title"
          ><em class="icon-lock" aria-hidden="true" id="modal-title-icon"></em
          >Change password</label
        >
        <em class="icon-close close" (click)="close()"></em>
      </div>
      <div class="content-modal">
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="password"
              formControlName="newPassword"
            />
            <label class="float-label">New password</label>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="password"
              formControlName="confirmPassword"
            />
            <label class="float-label">Verify password</label>
          </div>
        </div>
      </div>
      <div class="re-modal-footer">
        <button class="btn-solid decline" (click)="close()">Cancel</button>
        <button
          class="btn-solid save"
          [disabled]="!passwordToReset.valid"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
