<div class="project-container">
    <label class="big-title">{{item?.productName}}</label>
    <div class="image-container">
        <img src="../../../assets/r_p_completed_projects@2x.png" alt="">
    </div>
    <label class="med-title">Request status</label>
    <div class="request-project-item mt-1">
        <label class="item-title">Request Sent</label>
        <label class="item-value"><i class="fa fa-check-circle" aria-hidden="true" style="color: #98D6D4;margin-right:3px"></i>{{item?.sentAt| date: 'dd/MM/yyyy'}}</label>
    </div>
    <div class="request-project-item">
        <label class="item-title">Request Accepted</label>
        <label class="item-value">{{item?.shared==false?'-':(item?.acceptedAt| date: 'dd/MM/yyyy')}}</label>
    </div>
    <div class="collapse-group">
        <div class="collapse-title" (click)="requestInformation=!requestInformation" data-toggle="collapse" data-target="#requestInfo">
            <label class="title">Request information</label>
            <i class="fa fa-angle-down" aria-hidden="true" *ngIf="requestInformation"></i>
            <i class="fa fa-angle-right" aria-hidden="true" *ngIf="!requestInformation"></i>
        </div>
        <div id="requestInfo" class="collapse show">
            <div class="request-project-item">
                <label class="item-title">Inquired Company</label>
                <label class="item-value">{{item?.inquiredCompanyName}}</label>
            </div>
            <div class="request-project-item">
                <label class="item-title">Contact Person</label>
                <label class="item-value">{{item?.inquiredContactPerson}}</label>
            </div>
            <div class="request-project-item">
                <label class="item-title">Company Requester</label>
                <label class="item-value">{{item?.requestingCompanyName}}</label>
            </div>
        </div>
    </div>
    <div class="collapse-group">
        <div class="collapse-title" (click)="productSpecification=!productSpecification" data-toggle="collapse" data-target="#productSpecification">
            <label class="title">Product Specification</label>
            <i class="fa fa-angle-down" aria-hidden="true" *ngIf="productSpecification"></i>
            <i class="fa fa-angle-right" aria-hidden="true" *ngIf="!productSpecification"></i>
        </div>
        <div id="productSpecification" class="collapse show">
            <div class="request-project-item">
                <label class="item-title">Product Name</label>
                <label class="item-value">{{item?.productName}}</label>
            </div>
            <div class="request-project-item">
                <label class="item-title">Product No.</label>
                <label class="item-value">{{item?.productNumber}}</label>
            </div>
            <div class="request-project-item">
                <label class="item-title">Message</label>
                <label class="item-value underlined" data-toggle="collapse" data-target="#showmore" aria-expanded="false" *ngIf="showmore!=item?.id" (click)="showmore=item?.id">See more</label>
                <label class="item-value underlined" data-toggle="collapse" data-target="#showmore" aria-expanded="false" *ngIf="showmore==item?.id" (click)="showmore=null">See less</label>
            </div>
            <div id="showmore" class="collapse">
                <div class="request-project-item">
                     {{item?.message}}
                </div>
            </div>
        </div>
    </div>
</div>