<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Unassigned components</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
            name="searchTerm" />
        </div>
        <div class="col-md-4"></div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('vesselName','')"><label style="margin-left: 10px;">Vessel</label></th>
        <th id="" (click)="orderBy('reference','')"><label>Reference</label></th>
        <th id="" (click)="orderBy('type','')">Type</th>
        <th id="" (click)="orderBy('description','')">Description</th>
        <th id="" (click)="orderBy('status','')">Status</th>
        <th id="" (click)="orderBy('status','')">Days in status</th>
        <th id="" (click)="orderBy('daysInStatus','')">TtE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let component of componentList | filterPipe:searchTerm | sort:fieldName:attr">
        <td>
          <label class=" left-image" *ngIf="component.vesselName != ''">
            {{ component.vesselName }}
          </label>
          <label class="left-image" *ngIf="component.vesselName == ''">
            N/A
          </label>
        </td>
        <td style="font-weight: bold;">
          <img src="{{ component.componentPicture }}" alt="component Picture" style="cursor: default;" />
          {{ component.reference }}
        </td>
        <td>{{ component.type }}</td>
        <td>{{ component.description }}</td>
        <td>{{ component.status }}</td>
        <td>{{ component.daysInStatus }}</td>
        <td>
          <label *ngIf="component.status != 'In use'">
            N/A
          </label>
          <label *ngIf="component.status == 'In use'">
            <span class="dot" *ngIf="greater(component.ttePercent, 25) == false" style="
                background-color: white;
                border-width: 1.5px;
                border-color: CECECE;
                border-style: solid;
              "></span>
            <span class="dot" *ngIf="
                greater(component.ttePercent, 25) &&
                greater(component.ttePercent, 50) == false
              " style="background-color: #58c497;"></span>
            <span class="dot" *ngIf="
                greater(component.ttePercent, 50) &&
                greater(component.ttePercent, 75) == false
              " style="background-color: #f7e064;"></span>
            <span class="dot" *ngIf="
                greater(component.ttePercent, 75) &&
                greater(component.ttePercent, 100) == false
              " style="background-color: #de7575;"></span>
            {{ component.tte }} days
          </label>
        </td>
        <td>
          <em class="fa fa-bars fa-lg" (click)="showModal(true, component)">
          </em>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-edit-unassigned-component *ngIf="showComponent" [componentId]="componentId" [showModal]="showComponent"
  (closeModal)="showModal($event, null)">
</app-edit-unassigned-component>
