<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
     <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
        <label class="clicked-content-title">Companies</label>
     </ul>
     <form class="form-inline my-2 my-lg-0">
        <div class="row float-right">
           <div class="inner-addon right-addon">
              <em class="glyphicon fa fa-search"></em>
              <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
              name="searchTerm" />
           </div>
           <div class="col-md-4">
              <button class="btn-solid primary" (click)="showEditModal(true)">
              <em class="fa fa-plus"></em>Add Company
              </button>
           </div>
        </div>
     </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
     <caption></caption>
     <thead>
        <tr>
           <th id="" (click)="orderBy('companyName','')"><label style="margin-left: 10px;">Company</label></th>
           <th id="" (click)="orderBy('companyType','')">Role</th>
           <th id="" (click)="orderBy('companyContact','companyName')">Primary admin</th>
           <th id="" (click)="orderBy('billingContact','billingEmail')">Billing Info</th>
           <th id="" (click)="orderBy('total','')">Monthly Credit</th>
           <th id="" (click)="orderBy('total','')">Current Credits</th>
           <th id="" >Climate hub</th>
           <th id="" >Actions</th>
        </tr>
     </thead>
     <tbody>
        <tr  *ngFor=" let company of companies | filterPipe:searchTerm | sort:fieldName:attr">
           <td style="font-weight: bold;" [routerLink]="['/homeAdmin/company', company.id]">
              <img src="{{ company.companyLogo }}" alt="Avatar" />
              {{ company.companyName }}
           </td>
           <td [routerLink]="['/homeAdmin/company', company.id]">{{ company.companyType }}</td>
           <td [routerLink]="['/homeAdmin/company', company.id]">{{ company.companyContact?.contactName }}</td>
           <td [routerLink]="['/homeAdmin/company', company.id]">{{ company.billingEmail }}</td>
           <td>{{company.monthlyPoints}}</td>
           <td style="display: none;">
              <em class="fa fa-bars" *ngIf="false" (click)="showDetailsModal()"></em>
           </td>
           <td [routerLink]="['/homeAdmin/company', company.id]">{{ company.reflowPoints | number: '1.0-2'}}</td>
           <td>
               <input type="checkbox" [checked]="company.companyAssessmentAccess" (change)="changeClimateHubAccess($event, company.id)"/>
           </td>
           <td>
            <div class="options" ngbDropdown>
               <i class="fa fa-ellipsis-v" aria-hidden="true" id="dropdownBasic1" ngbDropdownToggle></i>
               <div class="dropdown-menu ddr" ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 353px;margin-left: -52px;" placement="bottom-left" display="dynamic">
                  <a class="dropdown-item" (click)="adjustRessources(company,company.reflowPoints)"><em
                   class="icon-settings" style="margin-right: 30px;"></em><label>Adjustment the available resources</label></a>
                  <a class="dropdown-item" (click)="openSubscriptionModal(company.id)"><em
                   class="icon-edit" style="margin-right:30px;"></em><label>Subscription Management </label></a>
                  <a class="dropdown-item" (click)="openTransferModal(company.id)"><em class="icon-flag" style="margin-right:33px;"></em><label>Transfer Assessment</label></a>
               </div>
            </div>
         </td>
        </tr>
     </tbody>
  </table>
</div>
<app-add-company (toogleModal)="showEditModal($event)" [showForm]="showEditView" *ngIf="showEditView"></app-add-company>
<app-update-credit *ngIf="showCreditModal" (creditModalEvent)="creditModalActions($event)" [company_id]="company_id" [initialCredit]="initialCredit"></app-update-credit>
<app-adjust-resources *ngIf="showAdjustModal" (creditModalEvent)="adjustModalActions($event)" [company]="company"></app-adjust-resources>
<app-subscription-modal *ngIf="showSubmscriptionModal" (subscriptionModalEvent)="SubscriptionModalActions($event)" [companyId]="company_id"></app-subscription-modal>
<app-transfer-modal *ngIf="showTransferModal" (transferModalEvent)="TransferModalActions($event)" [companyId]="company_id"></app-transfer-modal>
