import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-scenario-section",
  templateUrl: "./scenario-section.component.html",
  styleUrls: ["./scenario-section.component.scss"],
})
export class ScenarioSectionComponent implements OnInit {
  @Output() submitScenario = new EventEmitter();
  public scenario: string = "";
  public standardMaintenance: boolean = true;
  public repair: boolean = true;
  public remanufacturing: boolean = true;
  public breakDown: boolean = true;
  constructor() {}

  ngOnInit(): void {}

  public chooseScenario(selectedSenario): void {
    this.scenario = selectedSenario;
    if (this.scenario == "Standard maintenance") {
      this.standardMaintenance = true;
      this.repair = false;
      this.remanufacturing = false;
      this.breakDown = false;
    } else if (this.scenario == "Repair") {
      this.standardMaintenance = false;
      this.repair = true;
      this.remanufacturing = false;
      this.breakDown = false;
    } else if (this.scenario == "Remanufacturing") {
      this.standardMaintenance = false;
      this.repair = false;
      this.remanufacturing = true;
      this.breakDown = false;
    } else if (this.scenario == "Break-down") {
      this.standardMaintenance = false;
      this.repair = false;
      this.remanufacturing = false;
      this.breakDown = true;
    }
    this.submitScenario.emit(this.scenario);
  }
}
