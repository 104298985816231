import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { ComponentCoreInformation } from "src/app/models/component-core-information.model";
import {
  ComponentOverviewDto,
  ComponentEditDto,
  UpdateComponentDto,
} from "src/app/models/dtos.model";
import { ComponentLog } from "src/app/models/component-log";
import { ComponentLogService } from "src/app/services/component-log.service";

@Component({
  selector: "app-component-edit-core",
  templateUrl: "./component-edit-core.component.html",
  styleUrls: ["./component-edit-core.component.css"],
})
export class ComponentEditCoreComponent implements OnInit, OnChanges {
  public componentToEdit: FormGroup;
  public currentComponent: ComponentEditDto;
  public componentFiles: FormArray;
  public filesToUpload: any[];
  public showCoreInfo: boolean = true;

  @Input() public componentId: number;
  @Output() public changeSupplierRef = new EventEmitter();
  @Output() public closeModalEdit = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private componentService: ComponentCoreInformationService,
    private componentLogService: ComponentLogService
  ) {}

  ngOnInit(): void {
    this.componentToEdit = this.fb.group({
      id: [0],
      supplierReference: ["", Validators.required],
      componentPicture: ["", Validators.required],
      utilization: [""],
      recommendedHours: ["", Validators.required],
      componentFiles: this.fb.array([]),
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.componentService
      .getComponentById(this.componentId)
      .subscribe((res) => {
        this.currentComponent = res;
        this.initForm();
      });
  }

  public supplierReferenceChange(event: any): void {
    this.changeSupplierRef.emit(event.target.value);
  }

  public closeModal() {
    this.closeModalEdit.emit(false);
  }

  public initForm(): void {
    this.componentToEdit = this.fb.group({
      id: [this.currentComponent?.id],
      supplierReference: [
        this.currentComponent?.supplierReference,
        Validators.required,
      ],
      componentPicture: [
        this.currentComponent?.componentPicture,
        Validators.required,
      ],
      utilization: [this.currentComponent?.utilization],
      recommendedHours: [
        this.currentComponent?.recommendedHours,
        Validators.required,
      ],
      componentFiles: this.fb.array([]),
    });
  }

  public updateFiles(file: any): void {
    this.componentFiles = this.componentToEdit.get(
      "componentFiles"
    ) as FormArray;
    this.filesToUpload = this.componentFiles.value;
    this.componentFiles.push(file);
  }

  get frm() {
    return this.componentToEdit.controls;
  }

  public saveComponent(): void {
    this.componentService
      .updateComponent(new UpdateComponentDto(this.componentToEdit.value))
      .subscribe((res) => {
        let componentLog: ComponentLog = {
          id: 0,
          componentId: Number(res),
          userId: Number(localStorage.getItem("user_id")),
          createdAt: new Date(),
          action: "update componenet",
          componentLogFiles:
            this.componentFiles != undefined ? this.componentFiles.value : [],
        };
        this.componentLogService
          .addComponentLog(componentLog)
          .subscribe((log) => {
            this.closeModal();
          });
      });
  }

  public reset() {
    this.ngOnInit();
  }
}
