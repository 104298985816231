import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "../helper.service";
import { HttpClient, HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getCompanies(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company`);
  }


  public createCompany(company): any {
    return this.http.post<any>(`${this.environmentUrl}/api/company`,company);
  }

  public updateCompany(company): any {
    return this.http.post<any>(`${this.environmentUrl}/api/company`,company);
  }

  public deleteCompany(id): any {
    return this.http.delete<any>(`${this.environmentUrl}/api/company/${id}`);
  }
}
