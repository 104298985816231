<div class="modalDialog">
    <div class="re-modal-content">
        <div class="title-container">
            <label for="">{{actionType}} user</label>
        </div>
        <form [formGroup]="userToUpsert" class="upsert-form">
            <div class="form-row">
                <div class="form-item">
                    <label for="Company name" class="form-item-label">Title</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Mr.  |" formControlName="title">
                    </div>
                </div>
                <div class="form-item">
                    <label for="Company name" class="form-item-label">Name of User</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="Thameur Saadi" formControlName="name">
                    </div>
                </div>
            </div>
            <!---->
            <div class="form-row mt-20">
                <div class="form-item">
                    <label for="Address" class="form-item-label">Email</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="user@gmail.com" formControlName="email">
                    </div>
                </div>
                <div class="form-item">
                    <label for="Address line 2" class="form-item-label">Telephone</label>
                    <div class="form-control-container">
                        <input type="text" placeholder="+12 2344 34553" formControlName="phone">
                    </div>
                </div>
            </div>
            <!---->
            <div class="form-row mt-20">
                <div class="form-item pos-relative" ngbDropdown>
                    <label for="Company type" class="form-item-label" >User type</label>
                    <div class="form-control-container-custom" ngbDropdownToggle>
                        <label for="">{{userType}}</label>
                        <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="">
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <ng-container *ngFor="let item of userTypes; let idx=index">
                            <button ngbDropdownItem class="dropdown-item" data-toggle="collapse" (click)="selectUserType(item)">
                                <div class="checkbox" *ngIf="userType!=item"></div>
                                <div class="checkbox box-checked" *ngIf="userType==item">
                                    <img src="../../../../assets/yardscore-assets/ys-i-checkmark.svg" alt="">
                                </div>
                                <div class="dd-item-label">{{item}}</div>
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="form-item">
                    
                </div>
            </div>
        </form>
        <div class="title-container mt-20">
            <label for="">Permission / Role</label>
        </div>
        <div class="upsert-form">
            <div class="form-row">
                <div class="form-item item-row">
                    <label for="Company name" class="form-item-label">Edit dashboard</label>
                    <div class="checkbox-custom" [ngClass]="{'checked': permissions['Edit dashboard']==true}" (click)="permissions['Edit dashboard']=!permissions['Edit dashboard']">
                        <div class="box-bullet"></div>
                    </div>
                </div>
                <div class="form-item item-row">
                    <label for="Company name" class="form-item-label">Delete dashboard</label>
                    <div class="checkbox-custom" [ngClass]="{'checked': permissions['Delete dashboard']==true}" (click)="permissions['Delete dashboard']=!permissions['Delete dashboard']">
                        <div class="box-bullet"></div>
                    </div>
                </div>
            </div>
            <div class="form-row mt-20">
                <div class="form-item item-row">
                    <label for="Company name" class="form-item-label">Export reports</label>
                    <div class="checkbox-custom" [ngClass]="{'checked': permissions['Export reports']==true}" (click)="permissions['Export reports']=!permissions['Export reports']">
                        <div class="box-bullet"></div>
                    </div>
                </div>
                <div class="form-item item-row">
                    
                </div>
            </div>
        </div>
        <div class="actions-container">
            <button class="cancel" (click)="cancel()">CANCEL</button>
            <button class="add-company" (click)="upsert()">{{actionType=='create'?'ADD USER':'UPDATE'}}</button>
        </div>
    </div>
</div>