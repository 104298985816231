import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-upsert-location',
  templateUrl: './upsert-location.component.html',
  styleUrls: ['./upsert-location.component.scss']
})
export class UpsertLocationComponent implements OnInit {

  
  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentLocation:any;
  @Output() closeModal=new EventEmitter<any>();
  public locessToEdit: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.locessToEdit = this.fb.group({
      id:[this.currentLocation?this.currentLocation.id:""],
      locName:[this.currentLocation?this.currentLocation.locName:""],
      locLocation:[this.currentLocation?this.currentLocation.locLocation:""],
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public upsert(){
    
    var eg=this.locessToEdit.value;
    if(!this.currentLocation){
      eg.id=Date.now();
      //store database
    }else{
      //update database
    }
    this.closeModal.emit(eg);
  }

}
