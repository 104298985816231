import { ComponentFile } from "./component-file.model";
import { Status } from "./status.model";

export class ComponentCoreInformation {
  public constructor(init?: Partial<ComponentCoreInformation>) {
    Object.assign(this, init);
  }
  public id: number;
  public reflowId: string;
  public ownerId: number;
  public manufacturerId: number;
  public type: string;
  public specification: string;
  public condition: string;
  public status: string;
  public warehouseId: number;
  public description: string;
  public serialNumber: string;
  public supplierReference: string;
  public recommendedHours: number;
  public noX: boolean;
  public utilization: number;
  public classCertificate: boolean;
  public componentPicture: string;
  public companyId: number;
  public vesselOperatorId: number;
  public vesselId: number;
  public locationId: number;
  public componentFiles: ComponentFile[];
  public statusList: Status[];
}
