<div class="notification-container fade-in">
  <div style="text-align: center">
    <span class="icon-accomplished"></span>
    <p class="desc">
      Please confirm that you have received an unregistered component
    </p>
    <button class="btn-solid default" (click)="dismissed()">
      Remind me later
    </button>
    <button class="btn-solid secondary" (click)="navigate()">
      OK, proceed
    </button>
  </div>
</div>
