<div class="split left">
  <nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
        <label [class]="contextUser ? 'clicked-content-title' : 'content-title'" (click)="showUsers()">Users</label>
        <label [class]="!contextUser ? 'clicked-content-title' : 'content-title'"
          (click)="showComponent()">Components</label>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <div class="row float-right" style="width: 100%;">
          <div class="inner-addon right-addon">
            <em class="glyphicon fa fa-search"></em>
            <input type="text" class="form-control re-search" placeholder="Search" [(ngModel)]="searchTerm"
              name="searchTerm" />
          </div>
          <div class="col-md-4">
            <button class="btn-solid primary" *ngIf="contextUser" (click)="showAddUserForm(true)">
              <em class="fa fa-plus"></em>Add User
            </button>
            <button class="btn-solid primary" *ngIf="!contextUser">
              <em class="fa fa-plus"></em>Add Component
            </button>
          </div>
        </div>
      </form>
    </div>
  </nav>
  <div class="row">
    <table class="re-table" *ngIf="contextUser">
      <caption></caption>
      <thead>
        <tr>
          <th id="" (click)="orderBy('fullName')"><label style="margin-left: 10px;">User</label></th>
          <th id="" (click)="orderBy('role')">Role</th>
          <th id="" (click)="orderBy('email')">Mail</th>
          <th id="" (click)="orderBy('lastLogin')">Last Login</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of companyUsers | filterPipe:searchTerm | sort:fieldName:''">
          <td style=" font-weight: bold;">
            <img src="../../../assets/favicon.png" alt="Avatar" />
            {{ user.fullName }}
          </td>
          <td>{{ user.role }}</td>
          <td>{{ user.email }}</td>
          <td>April 25, 2020</td>
          <td>
            <em class="fa fa-edit" (click)="showDetails(true, user)"></em>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="re-table" *ngIf="!contextUser">
      <caption></caption>
      <thead>
        <tr>
          <th id=""><label style="margin-left: 10px;">Company</label></th>
          <th id="">Role</th>
          <th id="">Country</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="font-weight: bold;">
            <img src="../../../assets/favicon.png" alt="Avatar" /> Pj Diesel
          </td>
          <td>Service provider</td>
          <td>Denmark</td>
          <td><em class="fa fa-edit"></em></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="split right">
  <app-company-detail [companyToShow]="currentCompany" (toogleModal)="showEditCompForm($event)"></app-company-detail>
  <app-add-user [showForm]="showAddForm" (toogleModal)="showAddUserForm($event)" *ngIf="showAddForm"></app-add-user>
  <app-edit-company [showForm]="showEditCompanyForm" (toogleModal)="showEditCompForm($event)"
    *ngIf="showEditCompanyForm">
  </app-edit-company>
  <app-detail-user (toogleModal)="showDetails($event, null)" [showForm]="showUserDetails" [userToShow]="selectedUser"
    *ngIf="showUserDetails">
  </app-detail-user>
</div>