import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { updatePasswordValidator } from "src/app/validators/password-validator.directive";
import { UserService } from "src/app/services/user.service";
import { ResetPassword } from "src/app/models/reset-password.model";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public showModal: boolean = false;
  @Input() public userId: number;
  public passwordToReset: FormGroup;

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.passwordToReset = this.fb.group(
      {
        newPassword: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      {
        validators: updatePasswordValidator,
      }
    );
  }

  public close(): void {
    this.showModal = false;
    this.toogleModal.emit(this.showModal);
  }

  public save(): void {
    const newPass: ResetPassword = new ResetPassword(this.passwordToReset.value);
    this.userService
      .changePassword(this.userId, newPass)
      .subscribe((res) => {
        this.close();
      });
  }
}
