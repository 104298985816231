import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-process',
  templateUrl: './edit-process.component.html',
  styleUrls: ['./edit-process.component.scss']
})
export class EditProcessComponent implements OnInit {

  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentProcess:any;
  @Output() closeModal=new EventEmitter<any>();
  public processToEdit: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.processToEdit = this.fb.group({
      id:[this.currentProcess?this.currentProcess.id:""],
      procName:[this.currentProcess?this.currentProcess.procName:""],
      procUnit:[this.currentProcess?this.currentProcess.procUnit:""],
      procEmission:[this.currentProcess?this.currentProcess.procEmission:""],
      procDescription:[this.currentProcess?this.currentProcess.procDescription:""],
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public upsert(){
    
    var eg=this.processToEdit.value;
    if(!this.currentProcess){
      eg.id=Date.now();
      //store database
    }else{
      //update database
    }
    this.closeModal.emit(eg);
  }

}
