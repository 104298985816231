import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Warehouse } from "../models/warehouse";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class WarehouseService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public retrieveWarehouses(id: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/Warehouses/company/` + id,this.helperService.headers()
    );
  }

  public deleteWarehouse(id: number) {
    return this.http.delete(`${this.environmentUrl}/api/Warehouses/` + id,this.helperService.headers());
  }

  public addWarehouse(warehouse: Warehouse) {
    return this.http.post<any>(
      `${this.environmentUrl}/api/Warehouses`,warehouse,this.helperService.headers()
    );
  }

  public editWarehouse(warehouse: Warehouse) {
    return this.http.put<any>(
      `${this.environmentUrl}/api/Warehouses/` + warehouse.id,warehouse,this.helperService.headers()
    );
  }
}
