
    <div class="lfc" >

        <div class="form" *ngIf="isVerified">
            <p class="login-message">START YOU GREEN JOURNEY TODAY !</p>
            <p class="login-sub-message">Discover how to do it - all on your own!</p>
            <div class="vertical-tablet-icon">
                <img src="../../assets/landing-page/ipads.png" alt="" >
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="loginFailed">
                Incorrect email or password. <br /> Please try again
            </div>
            <form [formGroup]="loginForm">
                <div class="form-row">
                    <input type="email" class="form-input" placeholder="Email" formControlName="EmailAddress" />
                    <input type="password" class="form-input" placeholder="Password" formControlName="Password" />

                    <div class="action-div">
                        <button type="submit" class="btn-login" (click)="login()">
                  Login
                </button>
                    </div>
                    <a class="goto-signup" (click)="goToTarget('signup')">Create new account</a>
                </div>
            </form>
        </div>
        <div *ngIf="!isVerified">
            <p class="login-message" style="height: 50px;">Verify your account</p>
            <div class="vertical-tablet-icon">
                <img src="../../assets/landing-page/ipads.png" alt="" height="170">
            </div>
            <p class="login-sub-message mt-3 mb-3" style=" font-size: 18px;">
                We have sent a verification code to your mail.
            </p>

            <div class="alert alert-danger" role="alert" *ngIf="verificationFailed">
                Incorrect verification code. <br /> Please try again
            </div>
            <div class="form-row">
                <div class="form-group">
                    <input #verificationCode class="form-control" placeholder="Verfication code" />
                </div>
                <div class="action-div">
                    <button type="submit" class="continue-button" (click)="verifyCode(verificationCode)">
                        Continue
                    </button>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="container">
                <a class="f-link mr-3">Privacy policy Terms</a>
                <a href="https://www.facebook.com/reflowmaritime"><i class="fa fa-facebook-square" aria-hidden="true"></i
                ></a>
                <a href="https://www.linkedin.com/company/reflow-maritime"><i class="fa fa-linkedin-square" aria-hidden="true"></i
                ></a>
            </div>
            <div class="container">
                <label class="copyright">V1.18 Copyrights &copy; {{ current_year }} ReFlow ApS - All rights reserved</label
              >
            </div>
          </div>

    </div>










