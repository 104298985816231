<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <label class="form-title">Add Company</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="companyToCreate">
      <label class="section-title">GENERAL INFO</label>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="companyName"
            required
          />
          <label class="float-label">Company name</label>
          <small *ngIf="submitted && frm.companyName?.errors?.required">
            *Company name is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="vatNumber"
            required
          />
          <label class="float-label">VAT number</label>
          <small *ngIf="submitted && frm.vatNumber?.errors?.required">
            *VAT number is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <select class="re-input" formControlName="companyType">
            <option disabled selected value> Type </option>
            <option>Supplier</option>
            <option>Vessel Owner</option>
          </select>
          <small *ngIf="submitted && frm.type?.errors?.required">
            *Type is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="email"
            formControlName="billingEmail"
            required
          />
          <label class="float-label">Email for invoices</label>
          <small *ngIf="submitted && frm.emailInvoices?.errors?.required">
            *Email for invoices is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="number"
            formControlName="reflowPoints"
            required
          />
          <label class="float-label">Reflow Points</label>
          <small *ngIf="submitted && frm.reflowPoints?.errors?.required">
            *Reflow Points is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="address"
            required
          />
          <label class="float-label">Address</label>
          <small *ngIf="submitted && frm.address?.errors?.required">
            *Address is required
          </small>
        </div>
      </div>
      <div class="row inline">
        <div class="field" style="width: 32%;">
          <input
            class="re-input"
            type="number"
            formControlName="zipCode"
            required
          />
          <label class="float-label">Postal code</label>
          <small *ngIf="submitted && frm.postalCode?.errors?.required">
            *Postal code is required
          </small>
        </div>
        <div class="field" style="width: 56%;">
          <input class="re-input" type="text" formControlName="city" required />
          <label class="float-label">City</label>
          <small *ngIf="submitted && frm.city?.errors?.required">
            *City is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="country"
            required
          />
          <label class="float-label">Country</label>
          <small *ngIf="submitted && frm.country?.errors?.required">
            *Country is required
          </small>
        </div>
      </div>
      <div class="container" style="text-align: center;">
        <label class="btn-empty" style="padding: 8px 12px;">
          Add profile image
          <input
            #file
            type="file"
            (change)="uploadFile(file.files)"
            style="display: none;"
          />
        </label>
      </div>
      <div formGroupName="companyContact">
        <label class="section-title">COMPANY CONTACT</label>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="contactName"
              required
            />
            <label class="float-label"> Name</label>
            <small *ngIf="submitted && frm.name?.errors?.required">
              *Name is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="contactTitle"
              required
            />
            <label class="float-label"> Title</label>
            <small *ngIf="submitted && frm.title?.errors?.required">
              *Title is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="email"
              formControlName="adresseEmail"
              required
            />
            <label class="float-label"> Mail address</label>
            <small *ngIf="submitted && frm.companyEmail?.errors?.required">
              *Mail address is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="phoneNumber"
              required
            />
            <label class="float-label"> Phone number</label>
            <small *ngIf="submitted && frm.phone?.errors?.required">
              *Phone number is required
            </small>
          </div>
        </div>
      </div>
      <div formGroupName="billingContact">
        <label class="section-title">BILLING CONTACT</label>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="contactName"
              required
            />
            <label class="float-label"> Name</label>
            <small *ngIf="submitted && frm.name?.errors?.required">
              *Name is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="contactTitle"
              required
            />
            <label class="float-label"> Title</label>
            <small *ngIf="submitted && frm.title?.errors?.required">
              *Title is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="email"
              formControlName="adresseEmail"
              required
            />
            <label class="float-label"> Mail address</label>
            <small *ngIf="submitted && frm.companyEmail?.errors?.required">
              *Mail address is required
            </small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="phoneNumber"
              required
            />
            <label class="float-label"> Phone number</label>
            <small *ngIf="submitted && frm.phone?.errors?.required">
              *Phone number is required
            </small>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default">Close</button>
      <button class="btn-solid secondary" (click)="updateCompany()">
        Done
      </button>
    </div>
  </div>
</div>
