import { Component, OnInit,Input, SimpleChanges } from '@angular/core';
import { Company } from '../../../models/company.model';
import { ScreeningResult } from '../../../models/dtos.model';
import { CompanyService } from '../../../services/company.service';
import { ScreeningService } from '../../../services/screening.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {
  
  @Input() public item: any;
  public company: Company;
  public showCompaniesModal: boolean = false;
  public screeningResult:ScreeningResult;
  constructor(
    private companyService: CompanyService,
    private screeningService: ScreeningService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getScreeningInfo();
  }

  public getScreeningInfo()
  {
    this.screeningService.getScreeningResult(this.item?.importedScreeningId).subscribe(res=>{
      this.screeningResult=res;
    });
  }

 
}
