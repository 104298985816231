import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Location } from "src/app/models/location";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { LocationService } from "src/app/services/location.service";

@Component({
  selector: "app-edit-location",
  templateUrl: "./edit-location.component.html",
  styleUrls: ["./edit-location.component.css"],
})
export class EditLocationComponent implements OnInit {
  public action: string = "Add";
  public locationForm: FormGroup;
  public submitted = false;
  public selected = false;
  public isEngine = false;
  public suppliers: Company[];
  public txtmanufacturter: string;

  @Input() public locationToEdit: Location;
  @Input() public vesselId: number;
  @Output() public closeModal = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public selectManufacturer(supplier: Company) {
    this.locationForm.patchValue({
      manufacturer: supplier.companyName,
    });
  }

  public initForm(): void {
    this.companyService.getSuppliers().subscribe((res) => {
      this.suppliers = res;
    });
    if (this.locationToEdit != null) {
      this.action = "Edit";
    }
    this.locationForm = this.fb.group({
      vesselId: this.vesselId,
      type: [this.locationToEdit?.type, Validators.required],
    });
  }

  get frm() {
    return this.locationForm.controls;
  }

  public hideModal(): void {
    this.closeModal.emit(false);
  }

  public makeSelection(select: any): void {
    if (select.target.value != "") {
      this.locationForm.addControl(
        "name",
        this.fb.control("", Validators.required)
      );
      this.locationForm.addControl("description", this.fb.control(""));
      this.locationForm.addControl("specification", this.fb.control(""));
      this.locationForm.addControl("endPoint", this.fb.control(""));
      this.isEngine = false;
      if (select.target.value == "Engine") {
        this.locationForm.addControl(
          "manufacturer",
          this.fb.control("", Validators.required)
        );
        this.locationForm.addControl("serialNumber", this.fb.control(""));
        this.locationForm.addControl("runningHours", this.fb.control(""));
        this.isEngine = true;
      }
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  public reset(): void {
    this.hideModal();
  }

  public updateLocation(): void {
    this.submitted = true;
    if (this.locationForm.invalid) {
      return;
    }
    if (this.vesselId != null) {
      this.locationService
        .addLocation(this.locationForm.value)
        .subscribe((res) => {
          this.hideModal();
        });
    }
  }

  public deleteLocation(): void {}
}
