import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Vessel } from "../models/vesset";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class VesselService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public addVessel(vessel: Vessel): any {
    
    return this.http.post<any>(`${this.environmentUrl}/api/vessels`, vessel, this.helperService.headers());
  }

  public getVesselsoverview(companyId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/company/vesselOverview/` + companyId,
     this.helperService.headers()
    );
  }

  public getVessels(companyId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/company/` + companyId,
     this.helperService.headers()
    );
  }

  public getVesselChart(vesselId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/chart/` + vesselId,
     this.helperService.headers()
    );
  }

  public updateVessel(vessel: Vessel): any {
    
    return this.http.put<any>(
      `${this.environmentUrl}/api/vessels/` + vessel.id,
      vessel,
     this.helperService.headers()
    );
  }

  public deleteVessel(id: number): any {
    
    return this.http.delete(`${this.environmentUrl}/api/vessels/` + id, this.helperService.headers());
  }

  public getVesselById(vesselId: number): any {
    
    return this.http.get(`${this.environmentUrl}/api/vessels/` + vesselId, this.helperService.headers());
  }

  public getvesselOverviewById(vesselId: number): any {
    
    return this.http.get(
      `${this.environmentUrl}/api/vessels/vesselOverview/` + vesselId,
     this.helperService.headers()
    );
  }
}
