import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class ExchangeService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
     private router: Router,
     private helperService:HelperService,
     ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getExhangeList(companyId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/exchanges/sp/` + companyId,
      this.helperService.headers()
    );
  }

  public getExhangeComponent(componentId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/exchanges/component/` +
        componentId +
        `/exchangeUnit`,
      this.helperService.headers()
    );
  }
}
