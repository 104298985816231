<div class="category-management-container">
    <div class="filters-container">
        <div class="cm-title-container">
            <label class="cm-title">All categories (5)</label>
            <div class="cm-search-container">
                <img src="../../../assets/yardscore-assets/ys-i-search.svg" alt="" class="cm-search-icon">
                <input type="text" class="cm-search-text" placeholder="Search category">
            </div>
        </div>
        <span ngbDropdown >
        <div class="list-filter" ngbDropdownToggle>
            <label class="filter-label">Sort by</label>
            <img src="../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="filter-icon">
        </div>
        <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="toSortBy='title'">Name</button>
            <button ngbDropdownItem (click)="toSortBy=''">Date</button>
			<button ngbDropdownItem (click)="toSortBy=''">Creator</button>
            
        </div>
    </span>
        <div class="cm-add-category" (click)="toggleUpsertForm('create',null)">Add Category</div>
    </div>
    <div class="categories-list-container">

        <div class="categories-list">
            <table class="table">
                <thead>
                    <tr>
                        <th>Category name</th>
                        <th class="sortable">
                            <label>Created on</label>
                            <img src="../../../../assets/yardscore-assets/ys-i-sort-order.svg" alt="">
                        </th>
                        <th>Created by</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngIf="true">
                        <ng-container *ngFor="let item of categories | sortby: categories : toSortBy; let idx=index">
                            <tr>
                                <td>{{item?.title}}</td>
                                <td>dd-mm-yyyy</td>
                                <td>user</td>
                                <td class="actions-container" [ngStyle]="{'border-top': idx==0?'0 !important':''}" [routerLink]="['../categories',item?.id,'modules']" routerLinkActive="router-link-active" >
                                    <button class="manage-button">
                                        <i class="fa fa-cog"></i>
                                        <label>Manage</label>
                                    </button>
                                    <img src="../../../assets/yardscore-assets/ys-i-edit.svg" alt="" class="action-icon" (click)="$event.stopPropagation();toggleUpsertForm('update',item)">
                                    <img src="../../../assets/yardscore-assets/ys-i-delete.svg" alt="" class="action-icon action-margin" (click)="$event.stopPropagation();toggleDeleteForm(item)">
                                    <img src="../../../assets/yardscore-assets/ys-i-more-actions.svg" alt="" class="action-icon">
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <div class="no-data-container" *ngIf="false">
                <img src="../../../../assets/yardscore-assets/no-data.svg" alt="" class="no-data-img">
                <label class="no-data-label">No data Found !!</label>
            </div>
        </div>
    </div>
</div>
<app-questionnaire-child-upsert *ngIf="showUpsertForm==true" [currentObject]="currentCategory" [actionType]="actionType" [actionOn]="'category'" [parentData]="questionnaireType" (closeUpsertForm)="closeUpsertForm($event)"></app-questionnaire-child-upsert>
<app-questionnaire-child-delete *ngIf="showDeleteForm==true" [actionOn]="'category'" [currentChild]="currentCategory" (closeDeleteModal)="closeDeleteModal($event)"></app-questionnaire-child-delete>
