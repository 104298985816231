<div class="master-sheet-container">
    <div class="new-db-btn" (click)="showUpsertProcess(null)">
        <label > New Process</label>
    </div>
    <div class="records-list mt-4">
        <table class="re-table" >
        <thead>
            <tr>
                <th id="" class="pl-1"><label class="m-0"  style="margin:0">Process Name</label></th>
                <th id="">Unit</th>
                <th id="">Description</th>
                <th id=""></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let proc of procList; let i=index">
                <td class="pl-1"  style=" font-weight: bold;">
                    {{proc.name}}
                </td>
                <td> {{proc.unit}}</td>
                <td> 
                    <label class="with-description">
                        {{proc.description&&proc.description.length>20?proc.description.substr(0,20)+"...":proc.description}}
                    </label>
                </td>
                <td>
                    <em class="icon-burger" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></em>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
                        <a class="dropdown-item" (click)="navigate(proc)">Locations</a>
                        <a class="dropdown-item" (click)="showUpsertProcess(proc)">Edit</a>
                    </div>
                </td>
            </tr>
        </tbody>
        </table>
        
    </div>

</div>

<div #modal class="sidebar " id="sidebar" *ngIf="displayUpsertProcess">
    <div class="modal-header">
        <span class="dismiss-container">
            <em class="icon-close dismiss" (click)="dismissModal()"></em>
            <div class="title-container d-flex">
                <h3 class="modal-title">Create Process</h3>
            </div>
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="processToEdit">
            <div class="row">
                <div class="field">
                    <input
                        class="re-input"
                        type="text"
                        formControlName="processName"
                        required
                    />
                    <label class="float-label">Name</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <select class="re-input" formControlName="companyId">
                        <option></option>
                        <option *ngFor="let company of companies" value="{{company.id}}" (click)="selectCompany(company.id)">{{company?.companyName}}</option>
                    </select>
                    <label class="float-label">Specific company</label>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <select class="re-input" formControlName="unit">
                        <option selected disabled> </option>
                        <option value="Kg">Kg </option>
                        <option value="Km">Km </option>
                        <option value="kWh">kWh</option>
                    </select>
                    <label class="float-label">Unit</label>
                    <small *ngIf="false">*role is required</small>
                </div>
            </div>
          
            <div class="row">
                <div class="field">
                    <textarea class="re-input mt-2"
                        type="text"
                        formControlName="description"
                        required rows="3" cols=""></textarea>
                    <label class="float-label">Description</label>
                    <small *ngIf="false">
                    *Name is required
                    </small>
                </div>
            </div>
           
            
        </form>
        <div class="row mt-5 p-3 d-flex justify-content-between">
            <label class="locations">Locations</label>
            <button class="restyled" (click)="addLocation()">
                <label>
                    Add location
                </label>
            </button>
        </div>
        
        <ng-container *ngFor="let item of locationArray?.value">
            <div class="row inline" >
                <div class="field" style="width: 30%;">
                    <input class="re-input" type="text" [(ngModel)]="item.locationName" />
                    <label class="float-label">Location name</label>
                </div>
                <div class="field" style="width: 25%;">
                    <input class="re-input" type="text"  [(ngModel)]="item.emission"/>
                    <label class="float-label">Emission</label>
                </div>
                <div class="field" style="width: 25%;">
                    <input class="re-input" type="text"  [(ngModel)]="item.equivalent"/>
                    <label class="float-label">Equivalent</label>
                </div>
            </div>
            <div class="row inline" style="border-bottom: solid 1px #ddd;margin-bottom:15px">
                <div class="field" style="width: 44%;">
                    <input class="re-input" type="text" [(ngModel)]="item.directEmission" />
                    <label class="float-label">Direct Emission</label>
                </div>
                <div class="field" style="width: 44%;">
                    <input class="re-input" type="text"  [(ngModel)]="item.indirectEmission"/>
                    <label class="float-label">Indirect Emission</label>
                </div>
            </div>
        </ng-container>
        <br>
        <br>
            <div class="submit-container">
                <button class="big-blue" (click)="upsert()">
                    <label>
                        Save
                    </label>
                </button>
            </div>
    </div>

</div>