<div class="modals">
    <em class="icon-close" (click)="cancel($event)"></em>
    <div class="main-modal-content">
        <div class="row">
            <div class="col-12  centered">
                <label class="modal-title">Choose project to compare</label>
            </div>
        </div>

        <div class="row">
            <p class="modal-header">
                Click on the project you want to compare and then press the arrow to either include or excluded from the comparison.
            </p>
        </div>
        <div class="row">

            <div class="col col-border vertical-scrollable projects-container">
                <div class="search-bar">
                    <div class="inner-addon left-addon">
                        <em class="glyphicon fa fa-search"></em>
                        <input type="text" class="form-control re-search" placeholder="Search" name="searchTerm" [(ngModel)]="user_input" (ngModelChange)="searchScreening()" />
                    </div>
                </div>
                <div class="project-list">
                    <div class="project" *ngFor="let m_screening of modal_screenings" (click)="selectScreening(m_screening.id)" [ngClass]="{'selected-project':  selected_projects.indexOf(m_screening.id) > -1}">
                        <label class="project-name">{{m_screening.name}}</label>
                    </div>
                </div>
            </div>
            <div class="col-xx-1">
                <div class="arrow-border-right" (click)="addScreening()"><em class="icon-arrow-right"></em></div>
                <div class="arrow-border-left" (click)="removeScreening()"><em class="icon-arrow-left"></em></div>
            </div>
            <div class="col col-border vertical-scrollable projects-container">
                <div class="project" *ngFor="let project_to_add of final_projects_to_add" (click)="unselectScreening(project_to_add?.id)" [ngClass]="{'selected-project':  un_selected_projects.indexOf(project_to_add.id) > -1}">
                    <label class="project-name">{{project_to_add.name}}</label>
                </div>

            </div>
        </div>
    </div>
    <div class="main-modal-footer">
        <button class="compare-button" (click)="compareScreenings()">
            <label class="compare-button-lable" >
                Compare
            </label>
          </button>
    </div>
</div>
<div class="overlay"></div>