import { Company } from "./company.model";
import { UserLog } from "./user-log.model";

export class User {
  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
  public id: number;
  public fullName: string;
  public email: string;
  public phoneNumber: string;
  public password: string;
  public title: string;
  public department: string;
  public role: string;
  public lastLogin: Date;
  public acceptedTerms: boolean;
  public profilePicture: string;
  public timer: number;
  public permission: string;
  public permissionDate: Date;
  public companyId: number;
  public Company: Company;
  public userLogs: UserLog[];
  public access:UserAccess[];
}

export class UserAccess {
  public id: number;
  public access: string;
  public userId: number;
  public user: User;
}
