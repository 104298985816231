<div class="master-sheet-container">
    <div class="new-db-btn" (click)="showUpsertLocation(null)">
        <label > New Location</label>
    </div>
    <div class="records-list mt-4">
        <table class="re-table" >
        <thead>
            <tr>
                <th id="" class="pl-1"><label class="m-0"  style="margin:0">Name</label></th>
                <th id="">Location</th>              
                <th id=""></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let loc of locList; let i=index">
                <td class="pl-1"  style=" font-weight: bold;">
                    {{loc.locName}}
                </td>
                <td> {{loc.locLocation}}</td>    
                <td>
                    <em class="icon-burger" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></em>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="  position: absolute; transform:translate3d(853px, 482px, 0px); left: 0px; will-change: transform;">
                        <a class="dropdown-item" (click)="showUpsertLocation(loc)">Edit</a>
                        <a class="dropdown-item" (click)="delete(loc)">Delete</a>
                    </div>
                </td>
            </tr>
        </tbody>
        </table>
        
    </div>
    <div class="dynamic-view">
        <app-upsert-location
        *ngIf="displayUpsertLocation" 
        [currentLocation]="currentLocation" 
        (closeModal)="hideUpsertLocation($event)"
        ></app-upsert-location>
    </div>
</div>

