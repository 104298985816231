import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/yardscore-services/users.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ysa-users-management',
  templateUrl: './ysa-users-management.component.html',
  styleUrls: ['./ysa-users-management.component.scss']
})
export class YsaUsersManagementComponent implements OnInit {

  public showUpsertForm:boolean=false;
  public showDeleteUserForm:boolean=false;
  public actionType="";
  public users:any=null;
  public companyId:any=null;
  public currentUser:any=null;
  public toSortBy="Email";

  constructor(
    private usersService:UsersService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.getUsers();
  }

  public toggleUpsert(action,user){
    this.actionType=action;
    this.currentUser=user;
    this.showUpsertForm=true;
  }

  public closeUpsertForm(event){
    this.showUpsertForm=false;
    this.getUsers();
  }
  public toggleDelete(type,user){;
    this.actionType=type;
    this.currentUser=user;
    this.showDeleteUserForm=true;
  }

  public closeDeleteModal(event){
    this.showDeleteUserForm=false;
    this.getUsers();
  }

  public getUsers(){
    this.usersService.getUsers(this.companyId).subscribe(ans=>{
      this.users=ans;
    })
  }

}
