import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
import { AuthService } from '@auth0/auth0-angular';
import { Router } from "@angular/router";

@Component({
  selector: "app-vo-side-menu",
  templateUrl: "./vo-side-menu.component.html",
  styleUrls: ["./vo-side-menu.component.css"],
})
export class VoSideMenuComponent implements OnInit {
  public currentUser: User = new User();
  public showResetView: boolean = false;
  public showImageView: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.currentUserData();
  }

  public currentUserData(): void {
    this.userService
      .getUserById(Number(localStorage.getItem("user_id")))
      .subscribe((res) => {
        this.currentUser = res;
        if (this.currentUser.profilePicture == "") {
          this.currentUser.profilePicture = "../../../assets/loginbg.jpg";
        }
      });
  }
  public navigateHome(): void {
    let companyId: number = Number(localStorage.getItem("company_id"));
    this.router.navigate(["/homeVesselOperator", companyId]);
  }

  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
    this.showImageView = false;
  }

  public showImageModal(isShow: boolean) {
    this.showImageView = isShow;
    this.showResetView = false;
  }

  public logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.auth.logout({ returnTo: `${window.location.origin}/login` });
    localStorage.clear();
  }
}
