import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.model';
import { Screening } from 'src/app/models/screening.model';
import { ScreeningService } from 'src/app/services/screening.service';
import { ScreeningResult } from 'src/app/models/dtos.model';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-shared-project-details',
  templateUrl: './shared-project-details.component.html',
  styleUrls: ['./shared-project-details.component.scss']
})
export class SharedProjectDetailsComponent implements OnInit {

  @Output() toogleDetailsModal = new EventEmitter();
  @Input() public showDetailsView: boolean = false;
  @Input() public item;
  public company: Company;
  public screeningResult:ScreeningResult;
  public selectedLCS=[];
  public sharingName:String;
  constructor(private companyService: CompanyService,private screeningService: ScreeningService, private shareService: ShareService) { }

  ngOnInit(): void {
    this.getCompanyInfo();
    this.getScreeningInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getScreeningInfo();
  }

  public close(): void {
    this.showDetailsView = false;
    this.toogleDetailsModal.emit(false);
  }

  public getCompanyInfo()
  {
    let companyId = Number(localStorage.getItem("company_id"));
    this.companyService.retrieveCustomerCompany(companyId).subscribe(resCompany => {
      this.company = resCompany;
    });
  }

  public getScreeningInfo()
  {
    this.screeningService.getScreeningResult(this.item['importedScreeningId']).subscribe(res=>{
      this.screeningResult=res;
      for (let index = 0; index < res.lifeCycleImpacts.length; index++) {
        this.selectedLCS.push({id:res.lifeCycleImpacts[index].id,name:res.lifeCycleImpacts[index].cycleName,selected:false,value:res.lifeCycleImpacts[index].impact})
      }
    });
  }


}