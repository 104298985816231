<div class="results-contianer">
    <p class="title-bolder no-martop">Company Assessment of {{companyAssessmentResult?.companyName}} in
        {{companyAssessmentResult?.assessedYear}}</p>
    <p class="title-bold"></p>
    <p class="decription-normal">In this analysis, we present the results of assessing the Greenhouse Gas (GHG)
        emissions of a {{companyAssessmentResult?.industry}} company located in {{companyAssessmentResult?.country}}.
        Throughout this report, we will examine the data related to the the carbon dioxide equivalent (CO2e) emissions
        of this industry, taking into account the number of employees involved and the analyzed year. These insights
        will help us understand the climate impact of this industry and provide a solid foundation for identifying areas
        where improvements can be made.</p>
    <div class="button-go-back button-dark" style="position: absolute;margin-left: 54.3%;" (click)="navigateHome()">
        <label for="">Go back to dashboard</label>
        <img src="../../../../../assets/svgviewer-png-output go back to dashboard.png" alt="">
    </div>
    <div class="info-box">
        <div class="info-container">
            <div class="title-bold">
                Company Profile
            </div>
            <div class="extra-info mt-4">
                <div class="info-line no-martop mt-2">
                    <label class="title-bold">Activity: &nbsp;</label>
                    <label class="title-normal">{{companyAssessmentResult?.industry}} </label>
                </div>
                <div class="info-line no-martop mt-2">
                    <label class="title-bold">Industry Locations: &nbsp;</label>
                    <label class="title-normal">{{companyAssessmentResult?.country}} </label>
                </div>
                <div class="info-line no-martop mt-2">
                    <label class="title-bold">Number of Employees: &nbsp;</label>
                    <label class="title-normal">{{companyAssessmentResult?.employeesNumber}} </label>
                </div>
                <div class="info-line no-martop mt-2">
                    <label class="title-bold">Year Analyzed: &nbsp;</label>
                    <label class="title-normal">{{companyAssessmentResult?.assessedYear}} </label>
                </div>

            </div>
        </div>
        <div class="attatched-content-container">
            <div class="company-logo">
                <img [src]="companyAssessmentResult?.companyLogoUrl" style="max-height: 100%;max-width: 100%;" alt=""
                    *ngIf="companyAssessmentResult?.companyLogoUrl!=''&&companyAssessmentResult?.companyLogoUrl!=null">
                <label
                    *ngIf="companyAssessmentResult?.companyLogoUrl==''||companyAssessmentResult?.companyLogoUrl==null">Company
                    Logo</label>
            </div>
        </div>
    </div>

    <label class="title-big ">Choose Your Download:</label>
    <div class="info-box no-martop flex-column align-items-start" *ngIf="false">
        <div class="downloads-container">
            <div class="info-container">
                <div class="extra-info">
                    <div class="info-line no-martop mt-2">
                        <label class="title-bold">Looking for Visual Analysis? Choose the "Download Report"
                            Option</label>
                    </div>
                </div>
            </div>
            <div class="button-download button-dark">
                <label>Download Report</label>
                <img src="../../../../../assets/download-white.svg" alt="">
            </div>
        </div>
        <div class="decription-normal mt-4">
            If you're seeking a comprehensive visual analysis of your results, our "Report" document is your go-to
            choice. This document presents an insightful breakdown of your greenhouse gas emissions, offering analyses
            based on scopes and categories. You'll find the corresponding values and percentages, neatly organized to
            provide a clear understanding of your climate impact. The "Report" comes complete with informative graphs
            that visually illustrate the data for a more engaging experience.
        </div>
    </div>
    <div class="info-box no-martop flex-column align-items-start">
        <div class="downloads-container">
            <div class="info-container">
                <div class="extra-info">
                    <div class="info-line no-martop mt-2">
                        <label class="title-bold">This option will soon be available through our platform</label>
                    </div>
                </div>
            </div>
            <div class="button-download button-gary disabled-button">
                <label class="disabled-button">Download Report</label>
                <img src="../../../../../assets/download-white.svg" alt="" class="disabled-button">
            </div>
        </div>
    </div>
    <div class="info-box no-martop flex-column align-items-start">
        <div class="downloads-container">
            <div class="info-container">
                <div class="extra-info">
                    <div class="info-line no-martop mt-2">
                        <label class="title-bold">Looking for just the emission figures? Choose the 'Download Summary'
                            option.</label>
                    </div>
                </div>
            </div>
            <div class="button-download button-light" (click)="downloadSummary()">
                <label>Download Summary</label>
                <img src="../../../../../assets/download-white.svg" alt="" *ngIf="downloading==false">
                <div class="spinner-grow spinner-grow-sm ml-3" style="color:white" *ngIf="downloading==true"></div>
            </div>
        </div>
        <div class="decription-normal mt-4">
            Get a straightforward summary of your emission data, organized by scopes and categories. This option gives
            you the essential numbers, making it easy to see your company's carbon footprint.
        </div>
    </div>

    <label class="title-big mb-3">GHG Analysis</label>
    <div class="info-boxes-container mt-3">
        <div class="info-box-v vb-40">
            <label class="title-bold">Total CO2e Emissions of {{companyAssessmentResult?.companyName}}:</label>
            <label class="big-number">{{companyAssessmentResult?.totalCarbonFootprint/1000 | number : '1.2-2' }}</label>
            <p class="title-bold"><br> tCO2e </p>
        </div>
        <div class="info-box-v vb-55">
            <label class="title-bold">Total CO2e Emissions </label>
            <p class="decription-normal smaller-container">After a comprehensive analysis of the CO2e emissions of
                {{companyAssessmentResult?.companyName}}, we determined that the total emissions reached
                {{companyAssessmentResult?.totalCarbonFootprint/1000 | number:'1.2-2':'en' }}. These emissions result
                from various operational activities such as manufacturing processes, transportation, and other sources
                associated with the industry. The total emissions value provides an initial overview of the climate
                impact of this industry and serves as a basis for the detailed evaluation of GHG categories.</p>
        </div>
    </div>
    <label class="title-big mb-3">Primary Drivers of GHG Emissions</label>
    <div class="info-boxes-container mt-3">
        <div class="info-card"
            *ngFor="let categoryImpact of companyAssessmentResult?.orderedShowCategoryImpactDtos.slice(0,4)">
            <label class="card-text-big" style="line-height: 15px;">{{categoryImpact.category}}</label>
            <div class="image-container">
                <img src="../../../../../assets/res-icons/{{categoryImpact.category}}.png" alt="">
            </div>
            <div class="info-card-footer">
                <div class="val-unit">
                    <label class="card-text-big">{{categoryImpact.impact/1000 | number : '1.2-2' }} &nbsp;</label>
                    <label class="card-text-normal">tCO2e</label>
                </div>
                <label class="card-text-small">Data quality: High - Monitoring - Personal report</label>
            </div>
        </div>
    </div>
    <label class="title-big mb-3">Distribution of CO2e Emissions by Scopes</label>
    <div class="chart-container mt-3">
        <div class="chart-data-container">
            <label for="" class="title-bold">tCO2e emissions by Scope</label>
            <div #divToCapture class="chart-content with-canvas">
                <canvas id="canvas" width="300" height="300"></canvas>
                <div class="text-inside-container">
                    <label for="" class="simple">Total</label>
                    <label for="" class="big">{{companyAssessmentResult?.totalCarbonFootprint/1000 | number : '1.2-2'
                        }}</label>
                    <label for="" class="simple">tCO<sub>2</sub>e</label>
                </div>
            </div>
            <div class="chart-labels-container">
                <div class="chart-label">
                    <div class="label-bullet bullet-dark"></div>
                    <label class="label-text">Scope 1: {{companyAssessmentResult?.showScopeImpactDtos[0].impact/1000 |
                        number : '1.2-2' }} tCO2e</label>
                </div>
                <div class="chart-label">
                    <div class="label-bullet bullet-light2"></div>
                    <label class="label-text">Scope 2: {{companyAssessmentResult?.showScopeImpactDtos[1].impact/1000 |
                        number : '1.2-2' }} tCO2e</label>
                </div>
                <div class="chart-label" style="margin-bottom: 0px;">
                    <div class="label-bullet bullet-light1"></div>
                    <label class="label-text">Scope 3: {{companyAssessmentResult?.showScopeImpactDtos[2].impact/1000 |
                        number : '1.2-2' }} tCO2e</label>
                </div>
            </div>
        </div>
        <div class="chart-desctiption-container">
            <p>The pie chart illustrates the percentage distribution of {{companyAssessmentResult?.companyName}}'s CO2e
                emissions across the three reported scopes: Scope 1, Scope 2, and Scope 3. These scopes represent
                different emission sources and help us understand the industry's climate impact comprehensively.</p>
            <p>According to the analyzed data, we observe that Scope 1 emissions account for
                {{companyAssessmentResult?.showScopeImpactDtos[0].impactPercentage}}% of the total emissions, while
                Scope 2 represents {{companyAssessmentResult?.showScopeImpactDtos[1].impactPercentage}}% and Scope 3
                contributes {{companyAssessmentResult?.showScopeImpactDtos[2].impactPercentage}}%. This distribution
                highlights the importance of addressing not only the direct emissions from the industry's activities but
                also the indirect emissions associated with supply chains and employee-related activities.</p>
            <p>These results emphasize the need to implement comprehensive strategies to reduce CO2e emissions,
                considering all scopes. Apart from addressing internal operational efficiency, it is crucial to
                collaborate with supply chain partners and promote sustainable transportation and commuting practices
                among employees.</p>
        </div>
    </div>

    <label class="title-big mb-3">Distribution of the GHG Emissions by Category</label>
    <p class="decription-normal no-martop">The bar chart below presents the distribution of
        {{companyAssessmentResult?.companyName}}'s GHG emissions by category. Each bar represents a specific GHG
        category and shows its corresponding emissions value. This visualization allows us to identify the key
        categories contributing to the industry's total emissions and understand their respective climate impacts.</p>
    <div class="chart-container mt-4" style="flex-direction: column;padding-bottom: 0px;">
        <div class="chart-data-container" style="width: 100%;" *ngIf="companyAssessmentResult!=null">
            <div class="chart-content">
                <div class="chart-labels-container">
                    <ng-container *ngFor="let item of companyAssessmentResult!['showCategoryImpactDtos']">
                        <label class="bar-label">{{item.category}}</label>
                    </ng-container>
                </div>
                <div class="bar-scales-container">
                    <div class="bar-container">
                        <ng-container *ngFor="let item of companyAssessmentResult!['showCategoryImpactDtos']">
                            <div class="bar" data-toggle="tooltip" placement="right"
                                [ngStyle]="{'width': item.impactPercentage+'%'}"
                                [ngClass]="{'scope-color-1':item.scope=='1','scope-color-2':item.scope=='2','scope-color-3':item.scope=='3'}"
                                [ngbTooltip]="tipContent"></div>
                            <ng-template #tipContent>
                                <div class="tooltip-template">
                                    <p>{{ item.impact / 1000 | number : '1.2-2' }} tCO2e, <small>Hight</small></p>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div class="scale-vertical-bars">
                        <div class="v-bar"></div>
                        <div class="v-bar"></div>
                        <div class="v-bar"></div>
                        <div class="v-bar"></div>
                        <div class="v-bar"></div>
                    </div>
                    <div class="scale-values-container">
                        <label class="scale-value">0%</label>
                        <label class="scale-value">20%</label>
                        <label class="scale-value">40%</label>
                        <label class="scale-value">60%</label>
                        <label class="scale-value">80%</label>
                    </div>
                </div>
                <div class="scope-indicators-container">
                    <div class="scope-indicator" style="height: calc((90% / 13) * 3);">
                        <div class="indicator"></div>
                        <div class="tail"></div>
                        <label>Scope 1</label>
                    </div>
                    <div class="scope-indicator" style="height: calc((90% / 13) * 1);">
                        <div class="indicator"></div>
                        <div class="tail"></div>
                        <label>Scope 2</label>
                    </div>
                    <div class="scope-indicator" style="height: calc((90% / 13) * 9);">
                        <div class="indicator"></div>
                        <div class="tail"></div>
                        <label>Scope 3</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-desctiption-container" style="width: 100%;">
            <p>The distribution of GHG emissions by category, as shown in the bar chart, reveals the relative
                significance of each category in contributing to the company's total emissions. By evaluating the chart,
                we can identify the key categories that have the most impact and prioritize efforts for emission
                reduction accordingly.</p>
            <p>Upon analyzing the chart, it is evident that
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[0]?.category}} has the highest impact on the
                company's emissions, representing
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[0].impactPercentage}}% of the total emissions.
                This category demands immediate attention and presents a significant opportunity for targeted mitigation
                strategies. Following {{companyAssessmentResult?.orderedShowCategoryImpactDtos[0]?.category}}, the top
                five categories with the most substantial contributions to emissions are
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[1].category}},
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[2].category}},
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[3].category}}, and
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[4].category}}, constituting
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[1].impactPercentage}}%,
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[2].impactPercentage}}%,
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[3].impactPercentage}}%, and
                {{companyAssessmentResult?.orderedShowCategoryImpactDtos[4].impactPercentage}}%, respectively.</p>
            <p>The high-impact categories will be instrumental in achieving meaningful emission reductions. By
                implementing measures tailored to each category, such as optimizing processes, improving energy
                efficiency, or exploring alternative technologies, {{companyAssessmentResult?.companyName}} can make
                substantial progress toward its sustainability goals.</p>
            <p>It is worth noting that while these top categories deserve immediate attention, all categories should be
                considered in the broader context of emission reduction efforts. An integrated approach that encompasses
                multiple categories will lead to comprehensive and effective sustainability strategies.</p>
        </div>
    </div>

    <label class="title-big mb-3">Conclusion and Future Steps</label>
    <p class="decription-normal no-martop">Based on the analyzed CO2e emissions data from
        {{companyAssessmentResult?.companyName}}, we conclude that awareness and actions towards sustainability are
        crucial to minimize climate impact. The detailed analysis provided valuable insights into total emissions,
        distribution by GHG categories, and the proportion of emissions across different scopes.</p>
    <p class="decription-normal no-martop">To achieve a significant reduction in emissions, it is essential to implement
        continuous improvement strategies, identify areas with high potential for reduction, and collaborate with supply
        chain partners. Furthermore, adopting industry best practices, exploring renewable energy sources, and investing
        in more efficient technologies are recommended.</p>
    <p class="decription-normal no-martop">By transparently communicating the obtained results, along with emission
        reduction strategies and goals, {{companyAssessmentResult?.companyName}} demonstrates its commitment to
        sustainability and meets the expectations of stakeholders such as investors, regulators, and the community at
        large. Together, we can build a greener future and create a positive impact on the environment.</p>


    <div class="info-box">
        <div class="info-container">
            <div class="extra-info">
                <div class="download-line">
                    <img src="../../../../../assets/download-logo.png" alt="" class="logo">
                    <div class="line-box">
                        <div class="info-line no-martop mt-2">
                            <label class="title-bold">If you would like to download this full report, please</label>
                        </div>
                        <div class="info-line no-martop mt-1">
                            <label class="title-bold"> click on the following button</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="attatched-content-container">
            <div class="button-download button-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                type="button" style="width: 300px;">
                <img src="../../../../../assets/download-white.svg" alt="" *ngIf="downloading==false">
                <div class="spinner-grow spinner-grow-sm mr-3" style="color:white" *ngIf="downloading==true"></div>
                <label>Download Report</label>
                <img class="valid-ok-icon" src="../../../../../assets/valid-icon.png" alt="">
            </div>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton dropdown-edit"
                style="padding: 0px !important;border-radius: 10px;">
                <a class="dropdown-item download-full-report dd-item">
                    <img src="../../../../../assets/svgviewer-png-output download grey.png"
                        style="width: 20px;height: 20px;margin-right: 10px;">
                    <span style="margin-left: 15px;"> Download Full Report</span></a>
                <a class="dropdown-item download-summary dd-item" (click)="downloadSummary()"
                    (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                    <img [src]="isHovered ? '../../../../../assets/svgviewer-png-output download white.png' : '../../../../../assets/svgviewer-png-output download icon blue.png'"
                        style="width: 20px;height: 20px;margin-right: 10px;">
                    Download Summary Report</a>
            </div>
        </div>
    </div>
    <div class="note">
        <p class="note-text">"Note: In this report, certain categories have been excluded, including specific Scope 3
            emissions like Capital Goods and Upstream Leased Assets, along with various downstream Scope 3 emissions.
            These exclusions are due to the current limitations in data accuracy and the complexities of certain
            methodological aspects. For a detailed explanation of these exclusions and their rationale, please refer to
            our <strong> <u>Detailed Technical Document [insert hyperlink]</u></strong> or consult our <strong><u>FAQs [insert hyperlink]</u></strong>. Our commitment to
            transparent and accurate reporting is reflected in our ongoing efforts to refine our methodologies and
            broaden the comprehensiveness of our environmental impact assessments."</p>
    </div>
</div>