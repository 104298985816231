import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit-correction-modal',
  templateUrl: './submit-correction-modal.component.html',
  styleUrls: ['./submit-correction-modal.component.css']
})
export class SubmitCorrectionModalComponent implements OnInit {

  @Output() submitModal = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  public closeModal():void{
    this.submitModal.emit(false);
  }

  public SubmitCorrection():void{
    this.submitModal.emit(true);
  }

}
