<div class="modalDialog">
    <div class="re-modal-content">
        <div class="extrema">
            <em class="icon-close" (click)="close()"></em>
        </div>
        <div class="main-content">
            <div class="project-details-container p-1">
                <div class="project-detalis">
                    <img src="../../../../assets/reflow-newlogo.png" alt="" class="relogo">
                    <label class="prodname">Product preview</label>
                    <p class="prodescription">
                      The assessment results in the project relate to this specific product. The product’s function, its manufacturer and technical specifications are described below and provide the basis for this assessment.
                    </p>
                    <div class="row mt10">
                        <div class="col-6 d-flex justify-content-between large-col-6">
                            <label class="reflabel">&nbsp;</label>
                            <label class="reflabel id-value">&nbsp;</label>
                        </div>
                        <div class="col-6 d-flex justify-content-end small-col-6 no-padding">
                            <label class="reflabel mr-2">Project Date:</label>
                            <label class="reflabel">{{item?.creationDate | date: 'dd/MM/yyyy'}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Manufacturer</label>
                            <label class="attr">Manufacturer Name:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val">{{item?.company?.companyName}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Specifications</label>
                            <label class="attr">Product Name:</label>
                            <label class="attr">Product Type:</label>
                            <label class="attr">Weight:</label>
                            <label class="attr">Lifespan:</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy">{{item?.product?.name}}</label>
                            <label class="val">{{item?.product?.type}}</label>
                            <label class="val">{{item?.product?.weight}} kg</label>
                            <div class="d-flex">
                                <label class="val with-label left-align">{{item?.product?.lifeSpan}} years
                                    <span>(Initial)</span>
                                </label>
                                <label class="val ml-3">+</label>
                                <label class="val with-label ml-3 left-align">{{screeningResult?.extendLifeSpan}} years
                                    <span>(Extended)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-6 d-flex flex-column">
                            <label class="title">Project impact assessment</label>
                            <label class="attr">Total carbon footprint</label>
                            <label class="attr attr-description">&nbsp;</label>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <label class="title">&nbsp;</label>
                            <label class="val fancy with-label align-items-start">{{screeningResult?.totalCarboonFootprint | number : '1.2-2'}} <span style="margin-top: -7px">kg CO<sub>2</sub> eq</span></label>                        </div>
                    </div>
                    <hr>
                    <div class="row new-bloc">
                        <div class="col-12">
                            <label class="title">Assessment results</label>
                        </div>
                        <div class="col-12">
                            <div class="alert-container">
                                <em class="icon-info"></em>
                                <div class="details-container">
                                    <p class="normal-detail">The life cycle stages are chosen by the manufacturer and may not represent the whole life cycle of the product.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                            <div class="operation mb-2 grow-container" *ngFor="let lc of selectedLCS;let i=index">
                                <label for="">{{lc.name}}</label>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column mt-3">
                          <div *ngFor="let lc of selectedLCS;let i=index" class="units-container grow-container">
                            <label class="val with-label flex-row units-name" style="float: left;">{{lc.value | number : '1.2-2'}}</label>
                            <span class="units-span">kg CO<sub>2</sub> eq</span>
                          </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="extrema"></div>
    </div>
</div>
