import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-public-assessment',
  templateUrl: './public-assessment.component.html',
  styleUrls: ['./public-assessment.component.scss']
})
export class PublicAssessmentComponent implements OnInit {
  public currentAssessment:any;
  constructor(private activatedRoute:ActivatedRoute, private shareService:ShareService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.shareService.getPublicAssessment(params['sharingId']).subscribe(res=>{
        this.currentAssessment=res;
      },err=>{
        //show error page
      });
    });
  }

}
