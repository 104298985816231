<div style="width: 100%;" [formGroup]="fileToEdit">
  <div class="row" *ngIf="fileToShow!=null&&fileToShow!=''">
    <button class="uploaded-file">
      <em class="fa fa-cog"></em>{{fileToShow}}
    </button>
  </div>
  <label class="section-title">ADD NEW</label>
  <div class="row">
    <div class="field">
      <input class="re-input" type="text" formControlName="filename" placeholder="Title" />
      <label class="float-label">Title</label>
    </div>
  </div>
  <div class="row">
    <div class="field">
      <input class="re-input" type="text" formControlName="comment" placeholder="Write comment" />
      <label class="float-label">Write comment</label>
    </div>
  </div>
  <div class="container upload-image" *ngIf="!showFileProperties">
    <input #file type="file" (change)="fileChangeEvent(file.files)" class="file-button" />
    <div class="upload-image-content">
      <em class="fa fa-file-o fa-2x"></em>
    </div>
    <label class="upload-image-label">Choose a file or drag it here</label>
  </div>
  <div *ngIf="showFileProperties">
    <div class="row">
      <div class="field">
        <select class="re-input" formControlName="fileType" placeholder="File Type">
          <option>Certificate</option>
          <option>Report</option>
          <option>Picture</option>
          <option>Technical file</option>
          <option>Other</option>
        </select>
        <label class="float-label">File Type</label>
      </div>
    </div>

    <div class="row">
      <input type="checkbox" formControlName="isGeneral" class="checkbox" />
      <label class="checkbox-label">Share with others in flow</label>
    </div>
    <div class="row">
      <input type="checkbox" formControlName="isShareable" class="checkbox" />
      <label class="checkbox-label">Add as general document</label>
    </div>
  </div>
  <div *ngIf="formValueChanged">
    <button class="btn-solid default" (click)="cancel()">Cancel</button>
    <button class="btn-solid primary" (click)="addFile()">Add</button>
  </div>
</div>