import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-light-error',
  templateUrl: './light-error.component.html',
  styleUrls: ['./light-error.component.css']
})
export class LightErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
