import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "./helper.service";
import { HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class CompanyAssessmentDataService {

  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getDataBases(): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment-data/databases`, this.helperService.headers());
  }

  public createDataBase(database){
    return this.http.post(`${this.environmentUrl}/api/company-assessment-data/createDatabase`, database,this.helperService.headers());
  }
  
  public createGroup(group){
    return this.http.post(`${this.environmentUrl}/api/company-assessment-data/createGroup`, group,this.helperService.headers());
  }

  public groupsOfEmissionData(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment-data/${id}/groups`, this.helperService.headers());    
  }

  public subGroupsOfGroup(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment-data/${id}/subgroups`, this.helperService.headers());    
  }

  public createSubGroup(subgroup){
    return this.http.post(`${this.environmentUrl}/api/company-assessment-data/createSubGroup`, subgroup,this.helperService.headers());
  }

  public endGroupsOfSubGroup(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment-data/${id}/endGroups`, this.helperService.headers());    
  }

  public createEndGroup(endgroup){
    return this.http.post(`${this.environmentUrl}/api/company-assessment-data/createEndGroup`, endgroup,this.helperService.headers());
  }

  public processesOfEndGroup(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/company-assessment-data/${id}/processes`, this.helperService.headers());    
  }

  public createProcess(endgroup){
    return this.http.post(`${this.environmentUrl}/api/company-assessment-data/createProcess`, endgroup,this.helperService.headers());
  }
}
