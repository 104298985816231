<div class="questionnaire-management-container">
    <div class="header-container">
        <div class="back-button">
            <img src="../../../../assets/yardscore-assets/ys-i-arrow-left.svg" alt="">
            <label for="">Back to Dashboard</label>
        </div>
        <div class="category">Environment and climate</div>
    </div>
    <div class="questionnaire-container">
        <div class="modules-container">
           <ng-container *ngFor="let item of ['Steel','Fate of Materials','Environmental protection'];let idx=index">
                <div class="module" [ngStyle]="{'margin-top': idx>0?'20px':''}" [ngClass]="{'selected': item==selectedModule}">
                    <div class="box" ></div>
                    <label for="">{{item}}</label>
                </div>
           </ng-container>
        </div>
        <div class="questions-container">
            <label for="" class="small-title">All Questions</label>
            <label for="" class="big-title">Steel recycling process</label>

            <!--**********************************************************************************************-->
            <!--*********************************** Template Percentage **************************************-->
            <!--**********************************************************************************************-->
            <div class="question-container">
                <label for="" class="big-info-label">Q1.  How you calculate the ferrous carbon emissions?</label>
                <div class="answer-container">
                    <label for="" class="info-label pr-3">Ans.</label>
                    <input type="text" class="input-text w-95" placeholder="50%">
                </div>
                <label for="" class="info-label pr-3 mt-20">Distribution</label>
                <div class="distribution-bloc">
                    <input type="text" class="input-text w-20" placeholder="Open hearth furnace">
                    <input type="number" class="input-text w-5 ml-10" placeholder="50%">
                </div>
                <div class="distribution-bloc">
                    <input type="text" class="input-text w-20" placeholder="Open hearth furnace">
                    <input type="number" class="input-text w-5 ml-10" placeholder="50%">
                </div>
                <label for="" class="info-label pr-3 mt-20">Files</label>
                <div class="existing-files-container">
                    <div class="new-files-container">
                        <div class="new-file">
                            <div class="dismiss">
                                <img src="../../../../assets/yardscore-assets/ys-i-close-light.svg" alt="">
                            </div>
                            <img src="../../../../assets/yardscore-assets/ys-i-file-dark.svg" alt="" class="file">
                            <div class="info-container">
                                <div class="info">
                                    <div class="file-name">Karl_file.pdf</div>
                                    <div class="file-size">{{file?.size/1204 | number: '4.1'}} MB</div>
                                </div>
                                <div class="progress">
                                    <div class="sub-progress"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <label for="" class="info-label pr-3 mt-20">Attach File *</label>
                <div class="drop-file-container">
                    <img src="../../../../assets/yardscore-assets/ys-i-drop-file.svg" alt="">
                    <p>Drag & drop, JPG/PNG/PDF files here <br>or <span>Click to upload</span></p>
                    <input type="file" id="drop_zone" class="FileUpload" accept=".jpg,.png,.gif" (change)="fileChanged($event)" />
                </div>
                <div class="save-container">
                    <span>&nbsp;</span>
                    <button class="save">save</button>
                </div>
            </div>
            <!--**********************************************************************************************-->
            <!--************************************* Template YES / NO **************************************-->
            <!--**********************************************************************************************-->
            <div class="question-container">
                <label for="" class="big-info-label">Q1.  How you calculate the ferrous carbon emissions?</label>
                <label for="" class="info-label pr-3 mt-20">Ans.</label>
                <div class="distribution-bloc">
                    <input type="text" class="input-text w-20 mr-10" placeholder="Open hearth furnace">
                    <span ngbDropdown class="yes-no-dropdown">
                        <label for="" class="input-text" ngbDropdownToggle> 
                            Select
                            <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="">
                        </label>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem>YES</button>
                            <button ngbDropdownItem>NO</button>
                        </div>
                    </span>
                </div>
                <div class="distribution-bloc">
                    <input type="text" class="input-text w-20 mr-10" placeholder="Open hearth furnace">
                    <span ngbDropdown class="yes-no-dropdown">
                        <label for="" class="input-text" ngbDropdownToggle> 
                            Select
                            <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="">
                        </label>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem>YES</button>
                            <button ngbDropdownItem>NO</button>
                        </div>
                    </span>
                </div>
                <div class="save-container">
                    <span>&nbsp;</span>
                    <button class="save">save</button>
                </div>
            </div>

            <!--**********************************************************************************************-->
            <!--********************************* Template Multiple Select ***********************************-->
            <!--**********************************************************************************************-->
            <div class="question-container">
                <label for="" class="big-info-label">Q1.  How you calculate the ferrous carbon emissions?</label>
                <label for="" class="info-label pr-3 mt-20">Ans.</label>
                <div class="distribution-bloc">
                    <span ngbDropdown class="multi-select-dropdown">
                        <label for="" class="input-text" ngbDropdownToggle> 
                            Select
                            <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="">
                        </label>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem>
                                <div class="dd-box">
                                    <img src="../../../../assets/yardscore-assets/ys-i-check-mark.svg" alt="">
                                </div>
                                <label for="">Licensed subcontractor</label>
                            </button>
                            <button ngbDropdownItem>
                                <div class="dd-box selected-box">
                                    <img src="../../../../assets/yardscore-assets/ys-i-check-mark.svg" alt="">
                                </div>
                                <label for="">Licensed subcontractor</label>
                            </button>
                            <button ngbDropdownItem>
                                <div class="dd-box">
                                    <img src="../../../../assets/yardscore-assets/ys-i-check-mark.svg" alt="">
                                </div>
                                <label for="">Licensed subcontractor</label>
                            </button>
                        </div>
                    </span>
                </div>
                <div class="save-container">
                    <span>&nbsp;</span>
                    <button class="save">save</button>
                </div>
            </div>
            <div class="save-container mt-100">
                <button class="exit">save & exit</button>
                <button class="save">save</button>
            </div>
        </div>
    </div>
</div>