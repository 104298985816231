import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/services/company.service";
import { Guid } from "guid-typescript";
import {
  FileUploadService,
  UploadPhotoModel,
} from "src/app/services/file-upload.service";

@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
})
export class AddCompanyComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();

  @Input() public showForm: boolean;
  public submitted = false;
  public companyImageName: string = "imageurl";
  public companyToCreate: FormGroup;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private uploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.companyToCreate = this.fb.group({
      companyName: ["", Validators.required],
      vatNumber: ["", Validators.required],
      address: ["", Validators.required],
      zipCode: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", Validators.required],
      billingEmail: ["", Validators.required],
      external: false,
      companyType: ["", Validators.required],
      reflowPoints:["", Validators.required],
      companyLogo: ["test.png"],
      billingContact: this.fb.group({
        contactName: ["", Validators.required],
        contactTitle: ["", Validators.required],
        adresseEmail: ["", Validators.required],
        phoneNumber: ["", Validators.required],
      }),
      companyContact: this.fb.group({
        contactName: ["", Validators.required],
        contactTitle: ["", Validators.required],
        adresseEmail: ["", Validators.required],
        phoneNumber: ["", Validators.required],
      }),
    });
  }

  public uploadFile(files) {
    this.uploadService.uploadFile(files[0], 0).subscribe((res) => {
      const uploadfile = new UploadPhotoModel(res);
      this.companyToCreate.patchValue({ companyLogo: uploadfile.fileName });
    });
  }

  get frm() {
    return this.companyToCreate.controls;
  }

  public updateCompany() {
    this.submitted = true;
    const company: Company = new Company(this.companyToCreate.value);
    this.companyService.addCompany(company).subscribe((res) => {
      this.ngOnInit();
      this.hideModal();
    });
  }

  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }
}
