import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { HelperService } from "./helper.service";
import { Mastersheet } from '../models/mastersheet.model';

@Injectable({
  providedIn: 'root'
})
export class MastersheetService {
  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  // public addMasterSheet(proc: Mastersheet): any {
  //   return this.http.post<any>(
  //     `${this.environmentUrl}/api/mastersheet/addProcess`, proc, this.helperService.headers()
  //   );
  // }

  // public getProcesses(): any {
  //   return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/getProcess`, this.helperService.headers());
  // }

  // public filterProcesses(keyword): any {
  //   return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/filterProcesses/` + keyword, this.helperService.headers());
  // }

  // public deleteProcess(id): any {
  //   return this.http.delete<any>(`${this.environmentUrl}/api/mastersheet/` + id, this.helperService.headers());
  // }

  // public editProcess(proc): any {
  //   return this.http.put<any>(`${this.environmentUrl}/api/mastersheet/editProcess/`,proc, this.helperService.headers());
  // }

  public createDataBase(database){
    return this.http.post(`${this.environmentUrl}/api/mastersheet/createDatabase`, database,this.helperService.headers());
  }

  public getDataBases(): any {
      return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/databases`, this.helperService.headers());
  }

  public createGroup(group){
    return this.http.post(`${this.environmentUrl}/api/mastersheet/createGroup`, group,this.helperService.headers());
  }

  public groupsOfInventory(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/${id}/groups`, this.helperService.headers());    
  }

  public subGroupsOfGroup(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/${id}/subgroups`, this.helperService.headers());    
  }

  public createSubGroup(subgroup){
    return this.http.post(`${this.environmentUrl}/api/mastersheet/createSubGroup`, subgroup,this.helperService.headers());
  }

  public endGroupsOfSubGroup(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/${id}/endGroups`, this.helperService.headers());    
  }

  public createEndGroup(endgroup){
    return this.http.post(`${this.environmentUrl}/api/mastersheet/createEndGroup`, endgroup,this.helperService.headers());
  }

  public editEndGroup(endgroup,id){
    return this.http.put(`${this.environmentUrl}/api/mastersheet/editEndGroup/${id}`, endgroup,this.helperService.headers());
  }

  public processesOfEndGroups(id): any {
    return this.http.get<any>(`${this.environmentUrl}/api/mastersheet/${id}/processes`, this.helperService.headers());    
  }

  public editProcess(process,id){
    return this.http.put(`${this.environmentUrl}/api/mastersheet/editProcess/${id}`, process,this.helperService.headers());
  }

  public createProcess(process){
    return this.http.post(`${this.environmentUrl}/api/mastersheet/createProcess`, process,this.helperService.headers());
  }

}
