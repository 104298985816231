import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit-questionnaire',
  templateUrl: './submit-questionnaire.component.html',
  styleUrls: ['./submit-questionnaire.component.scss']
})
export class SubmitQuestionnaireComponent implements OnInit {

  @Output() toggleSubmitModal:any= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public submit(){
    this.toggleSubmitModal.emit(false);
  }
  
  public cancel(){
    this.toggleSubmitModal.emit(false);
  }

}
