import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../../services/yardscore-services/companies.service';

@Component({
  selector: 'app-ysa-customer-management',
  templateUrl: './ysa-customer-management.component.html',
  styleUrls: ['./ysa-customer-management.component.scss']
})
export class YSACustomerManagementComponent implements OnInit {

  public showUpsertForm:boolean=false;
  public showDeleteCompanyForm:boolean=false;
  public actionType="";
  public companiesList:any=null;
  public currentCompany:any=null;
  public toSortBy="Region";

  constructor(
    private companiesService:CompaniesService
  ) { }

  ngOnInit(): void {
    this.getCompanies();
  }

  public toggleUpsert(action,company){
    this.actionType=action;
    this.currentCompany=company;
    this.showUpsertForm=true;
  }

  public closeUpsertForm(event){
    if(event==true){
      this.getCompanies();
    }
    this.showUpsertForm=false
  }
  public toggleDelete(company){
    this.currentCompany=company;
    this.showDeleteCompanyForm=true;
  }

  public closeDeleteModal(event){
    if(event==true){
      this.getCompanies();
    }
    this.showDeleteCompanyForm=false;
  }

  public getCompanies(){
    
    this.companiesService.getCompanies()
    .subscribe(ans => {
        console.log("aaaaaaa");
        this.companiesList=ans;
      },err=>{
        console.log(err);
        
      });
    
  }
  

}
