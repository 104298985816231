import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yardscore-admin',
  templateUrl: './yardscore-admin.component.html',
  styleUrls: ['./yardscore-admin.component.scss']
})
export class YardscoreAdminComponent implements OnInit {

  public menuItems:any=[
    {name:'Overview',icon:'overview',route:'overview'},
    {name:'Questionnaire management',icon:'questionnaire',route:'questionnaire-management'},
    {name:'Customer management',icon:'customer',route:'customer-management'},
    {name:'Review Questionnaires',icon:'review',route:'review-questionnaires'},
    {name:'Help',icon:'help',route:''},
    {name:'Documentation',icon:'documentation',route:''},
  ];
  public mouseOn='';
  public selectedTab:any=this.menuItems[0];

  constructor() {
    if (localStorage.getItem("ys_st") !== null) {
      this.selectedTab=this.menuItems[parseInt(localStorage.getItem('ys_st')+"")];
    }
  }

  ngOnInit(): void {
    document.body.style.background = '#F7FAFC';
  }

  public mouseEnter(item){
    this.mouseOn=item;
  }

  public mouseleave(item){
    this.mouseOn="";
  }

  public selectTab(tab,idx){
    this.selectedTab=tab;
    localStorage.setItem('ys_st',idx)
  }

}
