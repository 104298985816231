import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterPipe",
  pure: false,
})
export class FilterPipePipe implements PipeTransform {
  keys = [];
  transform(items: any, args: string): any {
    if (!items || !args) {
      return items;
    }
    if (items != null && items.length > 0) {
      let ans = [];

      if (this.keys.length == 0) {
        this.keys = Object.keys(items[0]);
      }

      for (let i of items) {
        if (i["id"] != null) {
          for (let k of this.keys) {
            if (i[k] == null) continue;
            if (i[k].toString().toLowerCase().includes(args.toLowerCase())) {
              ans.push(i);
              break;
            }
          }
        } else {
          ans = items.filter((item) =>
            item.toLowerCase().includes(args.toLowerCase())
          );
        }
      }
      return ans;
    }
  }
}
