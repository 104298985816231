import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-import-project',
  templateUrl: './import-project.component.html',
  styleUrls: ['./import-project.component.scss']
})
export class ImportProjectComponent implements OnInit {
  @Output() importPorjectEvent =new EventEmitter<any>();
  public inputCode="";
  constructor(private shareService:ShareService,private toastrService:ToastrService) { }

  ngOnInit(): void {
  }

  public closeModal():void{
    this.importPorjectEvent.emit({close:true,code:null});
  }

  /***
   * refers to importModalActions method of SharedWithMeComponent, line 50
   */
  public save():void{
    this.shareService.importScreening({sharedScreeningReflowId:this.inputCode,companyId:Number(localStorage.getItem("company_id"))}).subscribe(res=>{
      if(res==true){
        this.toastrService.success("Shared assessment imported succefully. You can now use it in your calculations","Imported succefully")
        this.importPorjectEvent.emit({close:true});
      }
      else{
        this.toastrService.error("Failed to import shared Assessment . please check with your provider if the shared url is correct","Error")
        this.importPorjectEvent.emit({close:true});
      }
    });
  }

}
