import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyAssessmentService } from '../../../services/company-assessment.service';

@Component({
  selector: 'app-calculation-stepper',
  templateUrl: './calculation-stepper.component.html',
  styleUrls: ['./calculation-stepper.component.scss']
})
export class CalculationStepperComponent implements OnInit {

  public oxCompanyAssessment=null;
  public companyAssessmentId=14;
  public currentCompanyAssessment:any=null;
  public currentCompanyAssessmentId:any=null;
  public stepsTracker=[
    {
      id:1,
      name:"01 Year selection",
      description:"Before we dive into the details, it's essential to establish the timeframe. By selecting the appropriate year, you're laying the groundwork for accurate tracking and analysis. Whether you're reflecting on past climate actions or setting the stage for future initiatives, defining the right year is your initial step in your climate journey.",
      isDone:false
    },{
      id:2,
      name:"02 Company profile",
      description:"Every company has a unique role to play in the global climate narrative. By sharing details about your organization, you're enabling us to customize our platform to your distinct needs. This ensures that the insights and recommendations you receive are directly aligned with your climate goals.",
      isDone:false
    },{
      id:3,
      name:"03 Data collection",
      description:"",
      isDone:false
    },{
      id:4,
      name:"04 Results",
      description:"",
      isDone:false
    }
  ]


  public currentStep=1;

  constructor(
    private route: ActivatedRoute,
    private companyAssessmentService:CompanyAssessmentService,
    ) { 
    
  }

  ngOnInit(): void {
    this.currentCompanyAssessmentId = this.route.snapshot.paramMap.get('companyAssesssmentId');
    
    if(this.currentCompanyAssessmentId!='new'){
      this.companyAssessmentService.getAssessmentsById(localStorage.getItem('company_id'),this.currentCompanyAssessmentId).subscribe(ans=>{
        this.currentCompanyAssessment=ans;
        if(this.currentCompanyAssessment?.finished==true){
          this.currentStep=4
        }else{
          switch(this.currentCompanyAssessment?.currentTab) {
            case "year-selection":
              this.currentStep=1;
              break;
            case "company-profile":
              this.currentStep=2;
              break;
            case "data-collection":
              this.currentStep=3;
              break;
            case "result-summary":
              this.currentStep=4;
              break;
            default:
              this.currentStep=1;
          }
        }
      })
    }else{
      this.currentCompanyAssessment=null;
    } 
  }

  public goToNextStep(event){
    if(event.step=="next"){
      this.currentStep++;
      this.companyAssessmentId=event.data.id
    }else{
      this.currentStep--;
    }
  }
  public changeCurrentStepTo4(): void {
    setTimeout(() => {
      this.currentStep = 4; 
    }, 6000); 
  }

}
