<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content">
        <form>
            <div class="content-modal">
                <em class="fa fa-times close" (click)="close()"></em>
                <img [src]="srcImg" alt="">
            </div>

        </form>
    </div>
</div>