import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CompanyAssessmentService } from '../../../../services/company-assessment.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-year-selection',
  templateUrl: './year-selection.component.html',
  styleUrls: ['./year-selection.component.scss']
})
export class YearSelectionComponent implements OnInit {

  @Output() nextStep=new EventEmitter();
  public currentCompanyAssessment:any=null;
  public ddTriggered=false;
  public years=[];
  public selectedYear=null;
  public searchText=null;
  public includesMonthlyCalculation:boolean=false;


  constructor(
    private companyAssessmentService:CompanyAssessmentService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.companyAssessmentService.getYears(localStorage.getItem('company_id')).subscribe(res=>{
      this.years=res;
    
        this.companyAssessmentService.getAssessmentsById(localStorage.getItem('company_id'),this.route.snapshot.paramMap.get('companyAssesssmentId')).subscribe(ans=>{
          this.currentCompanyAssessment=ans;
          this.searchText=this.currentCompanyAssessment?.year;
          this.includesMonthlyCalculation=this.currentCompanyAssessment?.includesMonthlyCalculation;
        },err=>{
          console.log(err);
        })
  
    });
    
  }

  public selectYear(year){
    if(year==this.selectYear&&this.selectedYear!=null){
      this.selectedYear=null;
    }else{
      this.selectedYear=year
    }
    this.searchText=this.selectedYear;
  }


  public goToNextStep(){
      let updateYearDto={
        includesMonthlyCalculation:this.includesMonthlyCalculation,
        year:this.searchText,
        companyAssessmentId:this.currentCompanyAssessment?.id
      };
      this.companyAssessmentService.updateYearSelection(this.currentCompanyAssessment?.id,updateYearDto).subscribe(ans=>{
        this.nextStep.emit({step:"next",data:this.currentCompanyAssessment});
      },err=>{
        console.log(err);
      })
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    var idClass = target.attributes.class;
    try {
      if(idClass.nodeValue==undefined||["icon-search","input-search-text","search-text","drop-down-container","years-list-contianer","y-dropdown-item","y-item-label","select-mark","img", "y-dropdown-item border-top"].indexOf(idClass.nodeValue)!=-1){
        this.ddTriggered=false;
      }
    } catch (error) {
      this.ddTriggered=false;
    }
    
  }
}
