
export class TransitVO {
    public constructor(init?: Partial<TransitVO>) {
        Object.assign(this, init);
    }
    public componentId: number;
    public voId: number;
    public vesselId: number;

}
