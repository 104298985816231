<div class="reflow-form">
  <div class="fixed-form-header">
    <div class="header-content">
      <label class="form-title">Assign Component</label>
      <em class="fa fa-times form-close" (click)="hideModal()"></em>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="componentToEdit" autocomplete="off">
      <div class="row" *ngIf="true">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="vessel"
            [(ngModel)]="txtvessel"
            placeholder="Vessel operator"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectVessel(vessel)"
              *ngFor="let vessel of vessels | filterPipe: txtvessel"
              class="dropdown-item"
            >
              {{ vessel.vesselName }}
            </button>
          </div>
          <label class="float-label">Vessel</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input
            class="re-input"
            type="text"
            formControlName="location"
            [(ngModel)]="txtlocation"
            placeholder="Location"
            id="dropVesselOperarors"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropVessels">
            <button
              (click)="selectLocation(location)"
              *ngFor="let location of locations | filterPipe: txtlocation"
              class="dropdown-item"
            >
              {{ location.name }}
            </button>
          </div>
          <label class="float-label">Location</label>
        </div>
      </div>
    </form>
  </div>
  <div class="fixed-form-footer">
    <div class="footer-content">
      <button class="btn-solid default" (click)="reset()">Cancel</button>
      <button class="btn-solid secondary" (click)="updateComponent()">
        Done
      </button>
    </div>
  </div>
</div>
