<div class="header-vessel">
  <div class="header-vessel-content">
    <div class="row">
      <div class="col">
        <img class="vessel-image" src="{{ currentVessel?.vesselPicture }}" alt="" />
        <label class="vessel-name">{{ currentVessel?.vesselName }}</label>
        <label class="vessel-details">
          {{ currentVessel?.vesselType }} <span>|</span> IMO:
          {{ currentVessel?.imo }}
        </label>
      </div>
      <button class="btn-solid default" (click)="navigateHome()">
        <em class="fa fa-ship"></em>See all vessels
      </button>
    </div>
    <div class="row">
      <div class="col-md chart">
        <label class="chart-title left">Total CO2 savings</label>

        <label class="chart-title right">{{ currentVessel?.coSaving }} kg CO2e</label>

        <canvas class="chart-image" id="lineChart"></canvas>
      </div>
      <div class="component">
        <div class="centered-right">
          <img class="component-image" src="../../../assets/component.png" alt="" />
          <label class="component-number">
            {{ currentVessel?.componentNumber }}
          </label>
          <label class="component-title">Component</label>
          <label class="component-total">in use</label>
        </div>
      </div>
      <div class="status">
        <div class="centered-right">
          <img class="status-image" src="../../../assets/circle-arrow.png" alt="" />
          <label class="component-title">Maintenance status</label>
          <label class="component-total">based on TtE</label>
        </div>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
      <label class="clicked-content-title">Locations</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input type="text" class="form-control re-search" placeholder="Search" />
        </div>
        <div class="col-md-4">
          <button class="btn-solid primary" (click)="toogleModal(true)">
            <em class="fa fa-plus"></em>Add location
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="" (click)="orderBy('name','')"><label style="margin-left: 10px;">Name</label></th>
        <th id="" (click)="orderBy('type','')">Type</th>
        <th id="" (click)="orderBy('manufacturer','')">Manufacturer</th>
        <th id="" (click)="orderBy('description','')">Description</th>
        <th id="" (click)="orderBy('specification','')">Specification</th>
        <th id="" (click)="orderBy('totalComponenent','')">Total components</th>
        <th id="" (click)="orderBy('averageUtilization','')">Condition</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let location of locations | filterPipe:searchTerm | sort:fieldName:attr" [routerLink]="
        ['../../location/', location.id]">
        <td style="font-weight: bold;">
          {{ location.name }}
        </td>
        <td>{{ location.type }}</td>
        <td>{{ location.manufacturer }}</td>
        <td>{{ location.description }}</td>
        <td>{{ location.specification }}</td>
        <td>{{ location.totalComponenent }}</td>
        <td>
          <span class="dot-red" *ngIf="
              location.totalComponenent > 0 && 25 > location.averageUtilization
            "></span>
          <span class="dot-yellow" *ngIf="
              location.totalComponenent > 0 &&
              location.averageUtilization > 25 &&
              75 > location.averageUtilization
            "></span>
          <span class="dot-green" *ngIf="
              location.totalComponenent > 0 && location.averageUtilization > 75
            "></span>
          <span class="dot-white" *ngIf="location.totalComponenent == 0"></span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-edit-location [vesselId]="currentVesselId" *ngIf="showLocationForm" (closeModal)="toogleModal($event)">
</app-edit-location>
