import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ComponentCoreInformation } from "../models/component-core-information.model";
import { UpdateComponentDto } from "../models/dtos.model";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class ComponentCoreInformationService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
     private router: Router,
     private helperService:HelperService,
     ) {
    this.environmentUrl = environment.apiUrl;
  }

  public addCompanyComponent(componentCoreInformation: any): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations`,
      componentCoreInformation,
      this.helperService.headers()
    );
  }

  public updateComponent(component: UpdateComponentDto): any {
    
    return this.http.put(
      `${this.environmentUrl}/api/ComponentCoreInformations/` + component.id,
      component,
      this.helperService.headers()
    );
  }

  public updateCci(component: any): any {
    
    return this.http.put(
      `${this.environmentUrl}/api/ComponentCoreInformations/location/` +
        component.id,
      component,
      this.helperService.headers()
    );
  }

  public getCompanyComponents(companyId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/company/` +
        companyId,
      this.helperService.headers()
    );
  }

  public getLocationComponents(locationId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/location/` +
        locationId,
      this.helperService.headers()
    );
  }

  public getComponentById(componentId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/` + componentId,
      this.helperService.headers()
    );
  }

  public getCci(componentId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/cci/` + componentId,
      this.helperService.headers()
    );
  }

  public getUnassinedComponents(voId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/unassigned/` + voId,
      this.helperService.headers()
    );
  }

  public getfullComponent(id: any): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/full/` + id,
      this.helperService.headers()
    );
  }

  public transitToVesselOperator(transitVO: any): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/transitToVesselOperator`,
      transitVO,
      this.helperService.headers()
    );
  }

  public componentNumberByVesselId(vesselId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/componentNumberByVesselId/` +
        vesselId,
      this.helperService.headers()
    );
  }

  public getExchangeComponents(locationId: number, componentId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/exchangeComponents/` +
        locationId +
        `/` +
        componentId,
      this.helperService.headers()
    );
  }

  public changeExchangeComponents(componentId: number, unitId: number): any {
    
    return this.http.get<any>(
      `${this.environmentUrl}/api/ComponentCoreInformations/` +
        componentId +
        `/confirmExchange/` +
        unitId,
      this.helperService.headers()
    );
  }
}
