import { Component, OnInit } from "@angular/core";
import { ComponentOverviewDto } from "src/app/models/dtos.model";
import { ComponentCoreInformationService } from "src/app/services/component-core-information.service";
import { ActivatedRoute } from "@angular/router";
import { Location as loc } from "@angular/common";
@Component({
  selector: "app-show-location",
  templateUrl: "./show-location.component.html",
  styleUrls: ["./show-location.component.css"],
})
export class ShowLocationComponent implements OnInit {
  public componentList: ComponentOverviewDto[];
  public locationId: number;
  public showStatus: boolean;
  public showDetailComponent: boolean;
  public componentId: any;
  public searchTerm: string = "";
  public fieldName: string;
  public attr: string;
  p: number = 1;

  constructor(
    private componentCoreInfomationService: ComponentCoreInformationService,
    private activatedRoute: ActivatedRoute,
    private _location: loc
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.locationId = Number(params["locationId"]);
      this.showComponentList();
    });
  }

  public showComponentList(): void {
    this.componentCoreInfomationService
      .getLocationComponents(this.locationId)
      .subscribe((res) => {
        this.componentList = res;
      });
  }

  public greater(x, y) {
    return x > y;
  }

  public goBack() {
    this._location.back();
  }

  public toogleModal(show: boolean, component: any): void {
    this.componentId = component?.id;
    this.showStatus = show;
    this.showDetailComponent = false;
    if (show == false) {
      this.showComponentList();
    }
  }

  public selectComponent(event: any, show: boolean, component: any) {
    if (event?.target.tagName == "TD" || event?.target.id == "select") {
      this.componentId = component?.id;
      this.showDetailComponent = show;
      this.showStatus = false;
    }
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
