<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content">
        <div class="sections">
            <div class="search-box">
                <div class="icon-container">
                    <em class="icon-search"></em>
                </div>
                <div class="input-container">
                    <input type="text" [(ngModel)]="searchText" (ngModelChange)="makeSearch()">
                </div>
            </div>
            <div class="processes-container" *ngIf="searchText==''">
                <ng-container *ngFor="let item of groupData?.companyAssessmentSubGroups;let idx=index">
                    <div class="subgroup" data-toggle="collapse" [attr.data-target]="'#subgroup'+idx"
                        (click)="handleItemClick(item?.id)">
                        <em
                            [ngClass]="{'icon-folder-closed': !folderStates[item?.id], 'icon-folder-open': folderStates[item?.id]}"></em>
                        <label>{{ capitalizeFirstLetter(item?.name) }}</label>
                    </div>
                    <div id="subgroup{{idx}}" class="collapse embededgroups-container"
                        [ngClass]="{'show':  defaultTree!=null&&defaultTree![1]==item?.id}">
                        <ng-container *ngFor="let endGroupItem of item?.companyAssessmentEndGroups;let egIdx=index">
                            <div class="subgroup" data-toggle="collapse" [attr.data-target]="'#endgroup'+egIdx"
                                (click)="selectEndgroup(endGroupItem,'');selectedSubGroup=endGroupItem.id"
                                [ngClass]="{'selected': selectedTarget==endGroupItem}">
                                <em
                                    [ngClass]="{'icon-folder-closed': !folderSubItemStates[endGroupItem?.id], 'icon-folder-open': folderSubItemStates[endGroupItem?.id]}"></em>
                                <label>{{capitalizeFirstLetter(endGroupItem?.name) }}</label>
                            </div>
                            <div *ngIf="endGroupItem?.companyAssessmentEmissionProcesses.length!=0"
                                id="endgroup{{egIdx}}" class="collapse embededgroups-container"
                                [ngClass]="{'show': defaultTree!=null&&defaultTree![2]==endGroupItem?.id}">
                                <ng-container
                                    *ngFor="let processItem of endGroupItem?.companyAssessmentEmissionProcesses">
                                    <div class="subgroup" (click)="selectProcessEmission(processItem)"
                                        [ngClass]="{'selected': selectedTarget==processItem}">
                                        <em class="icon-folder-closed"></em>
                                        <label>{{processItem?.name.length>35?(processItem?.name.substring(0,32)+"..."):processItem?.name}}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="processes-container" *ngIf="searchText!=''">
                <ng-container *ngFor="let item of searchTrace;let idx=index">
                    <div class="subgroup" data-toggle="collapse" [attr.data-target]="'#subgroup'+idx"
                        (click)="selectedSearch=item;item.length==2? selectEndgroup(item[0],'fromSearch') :selectProcessEmission(item[0])">
                        <em
                            [ngClass]="{'icon-folder-closed': !folderStates[item[0].id], 'icon-folder-open': folderStates[item[0].id]}"></em>
                        <label>{{ capitalizeFirstLetter(item[0].name) }}</label>
                    </div>
                    
                </ng-container>
            </div>
        </div>
        <div class="process-description">
            <div class="data-source-container">
                <div class="source-table">
                    <div class="fake-margin"></div>
                    <p class="ds-title">Data source</p>
                    <!-- <p class="ds-subtitle" *ngIf="selectedTarget!=null">subtitle data source in style</p> -->
                    <div class="ds-description-container" *ngIf="selectedTarget!=null">
                        <p class="ds-description">{{selectedTarget?.description}}</p>
                    </div>
                </div>
                <p class="ds-db-version" *ngIf="selectedTarget!=null">DataBase version : {{dbVersion}}-V1</p>

            </div>

        </div>
        <form class="forms" [formGroup]="processForm">
            <div class="close-button">
                <div class="close-circle">
                    <em class="icon-close" (click)="closeModal(false)"></em>
                </div>
            </div>
            <div class="form-container" *ngIf="selectedTarget!=null">
                <span class="form-holder">
                    <p class="form-title">{{selectedTarget?.name}}</p>
                    <ng-container *ngFor="let item of currentProcess.attibutes">
                        <div class="form-group"
                            *ngIf="(item.type!='dropdown'&&item.name!='workingFromHome')||(item.type!='dropdown'&&item.name=='workingFromHome'&&canWorkFromHome==true)">
                            <label for="usr" class="form-elem-title">{{item.label}}:</label>
                            <div class="input-container" *ngIf="item.type!='file'">
                                <div class="text-holder">
                                    <input [type]="item.type" placeholder="Insert the amount"
                                        [formControlName]="item.name" class="form-control simple-input"
                                        [ngClass]="{'icon-attached': item.type=='file'}">
                                </div>

                                <div *ngIf="selectedCategory.name != 'Home office'">
                                    <label class="attached-label"
                                        *ngIf="item?.showUnit == true">{{selectedTarget?.unit}}</label>
                                </div>
                            </div>
                            <div *ngIf="item.name=='numberMonths'&&validnumberMonths==false" class="error-message">
                                Number of months must be between 1 and 12.
                            </div>
                            <div class="input-container" *ngIf="item.type=='file'">
                                <div class="text-holder">
                                    <input type="file" #fileInput style="display: none;"
                                        (change)="onFilechange($event)">
                                    <div class="file-container" *ngIf="filename!=''&&filename!=null">
                                        <img src="../../../../../../assets/uploadedFile.svg" alt="">
                                        <label for="">{{filename}}</label>
                                        <img (click)="file=null;filename=null" style="cursor: pointer;"
                                            src="../../../../../../assets/deleteUploadedFile.svg" alt="">
                                    </div>
                                </div>
                                <div class="upload-icon-container less-padding" *ngIf="item.type=='file'"
                                    (click)="fileInput.click()">
                                    <label>Upload</label>
                                    <img src="../../../../../../assets/uploadsvgarrow.svg" alt=""
                                        class="upload-svg-arrow">
                                </div>

                            </div>
                        </div>
                        <span class="dd-wrapper" style="margin-bottom: 1rem;" ngbDropdown *ngIf="item.type=='dropdown'">
                            <label for="usr" class="form-elem-title">{{item.label}}:</label>
                            <div class="dropdown-container" id="dropdownBasic1" ngbDropdownToggle>
                                <div class="input-dropdown">{{processForm.controls[item.name].value ||
                                    item.placeholderText }}</div>
                                <div class="icon-container"> <em class="icon-arrow-down"></em></div>
                            </div>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <ng-container *ngFor="let qdItem of item.data">
                                    <button ngbDropdownItem class="dropdown-item"
                                        (click)="selectQuality(qdItem,item)">{{qdItem}}</button>
                                </ng-container>
                            </div>
                        </span>

                    </ng-container>
                    <div class="form-group">
                        <div class="note-container">
                            <label for="usr" class="form-elem-title">Note</label>
                            <textarea [(ngModel)]="note" [ngModelOptions]="{ standalone: true }" class="input-note"
                                rows="4" cols="100" placeholder="Add description"
                                (input)="checkCharacterCount()"maxlength="400"></textarea>
                        </div>
                        <div>
                            Character count: {{ note.length }}/{{ maxCharacters }}
                          </div>
                    </div>
                </span>
                <div class="actions-container">
                    <button class="back" (click)="closeModal(false)">Cancel</button>
                    <button class="compare" (click)="save()">Save</button>
                </div>
            </div>
            <div class="no-content" *ngIf="selectedTarget==null">
                <h3 class="no-content-text">No item selected</h3>
            </div>
        </form>
        <div class="alert-overlay" *ngIf="missingInputs==true">
            <div class="alert-container">
                <img src="../../../../../../assets/Warning--1.png" alt="">
                <label for="">Ensure all mandatory information is provided</label>
                <div class="dismiss-button" (click)="dismissAlert()">Back</div>
            </div>
        </div>
    </div>
</div>