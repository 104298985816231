import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss']
})
export class VideoPlayComponent implements OnInit {
  @Input() courseVideo:any;
  @Output() goToVideo:any=new EventEmitter<any>();
  public showdescription : boolean =false
  public showIcon : boolean =false
  public showiconright : boolean =true
  public visibleDescriptions={};
  public currentSectionId=1;
  public currentSection="";
  public subscription: Subscription;

  @ViewChild('target') target: ElementRef;
  constructor() { }

  ngOnInit(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      let totalDuration=0;
      let targetSection=this.courseVideo['sections'][0];
      this.courseVideo['sections'].forEach(section => {
        let mmss=section.time.split(":");
        totalDuration=parseInt(mmss[0])*60+parseInt(mmss[1]);
        if(parseInt(this.target.nativeElement.currentTime)+1>=totalDuration){
          targetSection=section;
        }
      });
      this.currentSection=targetSection.title;
      this.currentSectionId=this.courseVideo['sections'].indexOf(targetSection)+1;
    });
  }

  public showDescription(){
    this.showdescription = !this.showdescription
    this.showIcon = !this.showIcon
    this.showiconright = !this.showiconright
  }

  public revertSelection(id){
    this.visibleDescriptions[id]!=true?this.visibleDescriptions[id]=true:this.visibleDescriptions[id]=false;
  }

  public goToSection(time){
    const myArray = time.split(":");
    var x = (parseInt (myArray[0])*60)+parseInt (myArray[1]);
    this.target.nativeElement.currentTime = x ;
  }

  public goBackToOverview(){
    this.goToVideo.emit(null);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
