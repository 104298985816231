import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getScreeningGroup(screeningGroupDto:any){
    return this.http.post(`${this.environmentUrl}/api/inventory/ScreeningGroup`, screeningGroupDto,this.helperService.headers());
  }
  
  public getEndGroupLocations(endGroupId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/inventory/endGroupLocations/`+endGroupId, this.helperService.headers());
  }

  public getProcessLocations(processId:any): any {
    return this.http.get<any>(`${this.environmentUrl}/api/inventory/processLocations/`+processId, this.helperService.headers());
  }
}
