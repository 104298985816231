import { StatusFile } from "./status-file.model";

export class Status {
  public constructor(init?: Partial<Status>) {
    Object.assign(this, init);
  }
  public id: number;
  public componentId: number;
  public updatedAt: Date;
  public wareHouseId: number;
  public locationId: number;
  public location: string;
  public vesselId: string;
  public currentStatus: string;
  public scenarioSelector: string;
  public exchangeComponentId: string;
  public statusFiles: StatusFile[];
}
