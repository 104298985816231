import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VesselService } from "src/app/services/vessel.service";
import { LocationService } from "src/app/services/location.service";
import { Chart } from "chart.js";
import {
  LocationOverviewDto,
  VesselOverviewDto,
} from "src/app/models/dtos.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-show-vessel",
  templateUrl: "./show-vessel.component.html",
  styleUrls: ["./show-vessel.component.scss"],
})
export class ShowVesselComponent implements OnInit {
  @ViewChild("lineChart", { static: true }) private chartRef;
  public showLocationForm: boolean = false;
  public currentVessel: VesselOverviewDto;
  public currentVesselId: number;
  public locations: LocationOverviewDto[];
  public currentDate = new Date();
  public searchTerm: string;
  public fieldName: string;
  public attr: string
  LineChart: any;
  Player = [];
  dates = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private vesselService: VesselService,
    private locationService: LocationService,
    private router: Router,
    public datepipe: DatePipe
  ) { }

  public showChart(): void {
    this.currentDate.setDate(this.currentDate.getDate() + 9);
    for (let index = 10; index > 0; index--) {
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.dates.push(this.datepipe.transform(this.currentDate, "dd/M"));
    }
    this.vesselService.getVesselChart(this.currentVesselId).subscribe((res) => {
      this.LineChart = new Chart("lineChart", {
        type: "line",
        data: {
          //have to change soon
          labels: this.dates,
          datasets: [
            {
              data: res,
              fill: false,
              lineTension: 0.2,
              borderColor: "#97D6D4",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            text: "Line Chart",
            display: false,
          },
          legend: {
            display: false,
            labels: {
              fontColor: "#FFFFFF",
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "white",
                  maxTicksLimit: 3,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.currentVesselId = Number(params["vesselId"]);
      this.showChart();

      this.getCurrentVessel();
      this.getLocations();
    });
  }

  public getLocations() {
    this.locationService
      .getLocationsByVesselId(this.currentVesselId)
      .subscribe((res) => {
        this.locations = res;
      });
  }

  public getCurrentVessel(): void {
    this.vesselService
      .getvesselOverviewById(this.currentVesselId)
      .subscribe((res) => {
        this.currentVessel = res;
      });
  }

  public toogleModal(show: boolean): void {
    this.showLocationForm = show;
    this.getLocations();
  }

  public greater(x, y) {
    return x > y;
  }

  public navigateHome(): void {
    let companyId: number = Number(localStorage.getItem("company_id"));
    this.router.navigate(["/homeVesselOperator", companyId]);
  }

  public orderBy(field, attr) {
    this.fieldName = field;
    this.attr = attr;
  }
}
