import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ComponentGroup } from "src/app/models/dtos.model";

@Component({
  selector: "app-component-section",
  templateUrl: "./component-section.component.html",
  styleUrls: ["./component-section.component.scss"],
})
export class ComponentSectionComponent implements OnInit, OnChanges {
  @Input() public vesselArea: string;
  @Input() public vessellife: number;
  @Output() submitGroup = new EventEmitter();
  @Output() submitSpan = new EventEmitter();
  @Output() submitWeight = new EventEmitter();

  public componentGroups1: any[] = [];
  public componentGroups: any[] = [];
  public groupLevels: any[] = [];
  public txtGroup: string = "";
  public lifeSpan: number = 1;
  public weight: number = 1;
  public compGroupFocused: boolean = false;
  public showBack: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.componentGroups.push({
      groupTitle: "Hull structure",
      groups: [
        { groupTitle: "Hull plating and shell" },
        { groupTitle: "Hull corrosion wastage allowance" },
        { groupTitle: "Masts, towers, tetrapods etc" },
        { groupTitle: "Propulsion systems foundation" },
        { groupTitle: "Auxiliary systems foundation" },
        { groupTitle: "Electric systems foundation" },
      ],
    });
    this.componentGroups.push({
      groupTitle: "Propulsions systems",
      groups: [
        {
          groupTitle: "Main engine",
          groups: [
            { groupTitle: "Bedplate" },
            { groupTitle: "Crankshaft" },
            { groupTitle: "Flywheel" },
            { groupTitle: "Monoblock column" },
            { groupTitle: "Tie rods" },
            { groupTitle: "Cylinder block" },
            { groupTitle: "Cylinder line" },
            { groupTitle: "Cylinder cover" },
            { groupTitle: "Connecting rods" },
            { groupTitle: "Crosshead pins/guide shoes" },
            { groupTitle: "Pistons" },
            { groupTitle: "Gland box housing" },
            { groupTitle: "Scavenge air receiver" },
            { groupTitle: "Supply unit and fuel pumps" },
            {
              groupTitle: "Engine piping, platforms and unspecified components",
            },
            { groupTitle: "Turbocharger"},
          ],
        },
        { groupTitle: "Machinery plant control systems" },
        { groupTitle: "Propulsion couplings" },
        { groupTitle: "Propulsion shafting" },
        { groupTitle: "Propulsion shaft bearings" },
        { groupTitle: "Propellers" },
        { groupTitle: "Combustion air system" },
        { groupTitle: "Governor" },
        {
          groupTitle: "Cooling water systems",
          groups: [{ groupTitle:"Pumps"}, { groupTitle:"Cooler and piping"}, { groupTitle:"Piping and fittings"}],
        },
        { groupTitle: "Uptakes" },
        {
          groupTitle: "Fuel systems",
          groups: [
            { groupTitle: "Fuel pumps" },
            { groupTitle: "Fuel purifiers" },
            { groupTitle: "Piping, fittings and filters" },
          ],
        },
        {
          groupTitle: "Lube oil systems",
          groups: [
            { groupTitle: "Lube oil pumps" },
            { groupTitle: "Lube oil purifiers" },
            { groupTitle: "Piping and cooler" },
          ],
        },
      ],
    });
    this.componentGroups.push({
      groupTitle: "Auxiliary systems",
      groups: [
        { groupTitle: "Overflows, air escapes and sounding tubes" },
        { groupTitle: "Thermal insulation" },
        { groupTitle: "Compartment heating system" },
        { groupTitle: "Ventilation system" },
        {
          groupTitle: "Air conditioning system",
          groups: [
            { groupTitle: "Air condition units" },
            { groupTitle: "Air condition plants" },
          ],
        },
        {
          groupTitle: "Refrigeration system",
          groups: [
            { groupTitle: "Refrigeration condenser units" },
            { groupTitle: "Refrigeration piping" },
          ],
        },
        {
          groupTitle: "Fire and flushing system",
          groups: [
            { groupTitle: "Fire pumps" },
            { groupTitle: "Fire piping etc" },
          ],
        },
        {
          groupTitle: "Sprinkler system",
          groups: [
            { groupTitle: "Sprinkler pumps" },
            { groupTitle: "Sprinkler piping" },
          ],
        },
        {
          groupTitle: "Auxiliary seawater system",
          groups: [
            { groupTitle: "Auxiliary pumps" },
            { groupTitle: "Auxiliary piping" },
          ],
        },
        { groupTitle: "Scuppers and deck drains" },
        { groupTitle: "Plumbing drainage" },
        {
          groupTitle: "Ballasting systems",
          groups: [
            { groupTitle: "Ballasting pumps" },
            { groupTitle: "Ballast valves" },
            { groupTitle: "Ballast piping" },
          ],
        },
        {
          groupTitle: "Distilling plant",
          groups: [
            { groupTitle: "Reverse osmosis units" },
            { groupTitle: "Distilling piping" },
          ],
        },
        {
          groupTitle: "Potable water",
          groups: [
            { groupTitle: "Heater pumps" },
            { groupTitle: "Hydrophore pumps" },
            { groupTitle: "Water piping" },
          ],
        },
        {
          groupTitle: "Compressed air and ventilation system",
          groups: [
            { groupTitle: "Compressor and receiver unit" },
            { groupTitle: "Compressed air piping" },
          ],
        },
        {
          groupTitle: "Steering and manoeuvring system",
          groups: [
            { groupTitle: "Steering gear unit" },
            { groupTitle: "Steering gear piping" },
            { groupTitle: "Rudder" },
            { groupTitle: "Thruster and misc" },
          ],
        },
        { groupTitle: "Equipment handling system" },
        { groupTitle: "Anchor handling and stowage systems" },
        {
          groupTitle: "Mooring and towing systems",
          groups: [
            { groupTitle: "Winches" },
            { groupTitle: "Mooring line" },
            { groupTitle: "Bollards" },
          ],
        },
        { groupTitle: "Lifeboat handling systems" },
        {
          groupTitle: "Environmental pollution control systems",
          groups: [
            { groupTitle: "Treatment control units" },
            { groupTitle: "Treatment pumps" },
            { groupTitle: "Treatment tanks" },
          ],
        },
        { groupTitle: "Repair parts and tools" },
      ],
    });
    this.componentGroups.push({
      groupTitle: "Electric systems",
      groups: [
        { groupTitle: "Service power generators" },
        { groupTitle: "Emergency power generators" },
        { groupTitle: "Batteries and service facilities" },
        { groupTitle: "Power conversion equipment" },
        { groupTitle: "Power cables" },
        { groupTitle: "Switchboards and panels" },
        { groupTitle: "Lighting distribution control equipment" },
        { groupTitle: "Lighting fixtures" },
        { groupTitle: "Diesel support systems" },
      ],
    });
    this.componentGroups.push({
      groupTitle: "Nav. & comm. system",
      groups: [
        { groupTitle: "Computers and displays" },
        { groupTitle: "Navigation systems" },
        { groupTitle: "Internal communication systems" },
        { groupTitle: "External communication systems" },
        { groupTitle: "Radar systems" },
      ],
    });
    this.componentGroups.push({
      groupTitle: "Acommodation",
      groups: [
        { groupTitle: "Hull fittings and rails" },
        { groupTitle: "Staff and crew spaces" },
        { groupTitle: "Sanitary spaces" },
        { groupTitle: "Other undefined spaces" },
        { groupTitle: "Floor plates and misc." },
        { groupTitle: "Non-structural bulkheads" },
        { groupTitle: "Ladders" },
        { groupTitle: "Air vents and fixed portlights" },
        { groupTitle: "Painting" },
        { groupTitle: "Deck covering" },
        { groupTitle: "Hull insulation" },
        { groupTitle: "Refrigerated spaces" },
        { groupTitle: "Captain and faculty spaces" },
      ],
    });
    this.componentGroups1 = this.componentGroups;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.vesselArea != "") {
      this.groupLevels = [this.vesselArea];
      this.showBack = false;
      this.componentGroups1 = this.componentGroups.filter(
        (x) => x.groupTitle == this.vesselArea
      )[0].groups;
    }
  }

  public showLifeSpan(event): void {
    this.lifeSpan = event.target.value;
    let result = (this.lifeSpan / this.vessellife) * 100 - 5;
    event.target.style.background =
      "linear-gradient(to right, #123b63, #123b63 " + result + "%, white 15%)";
    this.submitSpan.emit(this.lifeSpan);
  }

  public showWeight(event): void {
    this.weight = event.target.value;
    let result = (this.weight / 10000) * 100;
    event.target.style.background =
      "linear-gradient(to right, #123b63, #123b63 " + result + "%, white 15%)";
    this.submitWeight.emit(this.weight);
  }

  public calculatePercentage(val, range): number {
    return (val / range) * 100 - 5;
  }

  public selectGroup(componentGroup, event): void {
    if (componentGroup.groups != undefined) {
      this.componentGroups1 = componentGroup.groups;
      this.groupLevels.push(componentGroup.groupTitle);
      if (this.groupLevels.length > 1) {
        this.showBack = true;
      } else {
        this.showBack = false;
      }
      event.stopPropagation();
    } else {
      this.txtGroup = componentGroup.groupTitle;
      this.compGroupFocused = false;
      this.submitGroup.emit(this.txtGroup);
    }
  }

  public componentGroupClicked(event): void {
    event.target.placeholder="Search..."
    this.compGroupFocused = true;
  }

  public backGroup(event): void {
    this.groupLevels.length = this.groupLevels.length - 1;
    let selected = this.groupLevels[this.groupLevels.length - 1];
    if (this.groupLevels.length > 1) {
      this.showBack = true;
    } else {
      this.showBack = false;
    }
    this.componentGroups1 = this.componentGroups.filter(
      (x) => x.groupTitle == selected
    )[0].groups;
    event.stopPropagation();
  }
}
