<form [formGroup]="companyProfile" class="location-group-container location-group-data">
    <div class="form-element" style="width: 43%;">
        <div class="icon-side">
            <img src="../../../../../assets/Company-Name.png" alt="">
        </div>
        <div class="input-side">
            <input type="text" placeholder="Company name" formControlName="companyName" #companyNameInput (input)="onInputChange(companyNameInput.value)" maxlength="30">
        </div>
        <span *ngIf="showCompanyNameError" class="danger-text">* Company name cannot be more than 30 characters.</span>
    </div>
    <div class="form-element mt-3" style="width: 43%;" (click)="toggleCountryselection=!toggleCountryselection">
        <div class="icon-side">
            <img src="../../../../../assets/Country.png" alt="">
        </div>
        <div class="input-side fake-input" [ngClass]="{'input-diabled': selectedCompanyType==''}">
            {{(selectedCountry!=""&&selectedCountry!=null)?selectedCountry:"Select country"}} 
        </div>
    </div>
    <div class="form-element mt-3" style="width: 43%; height: unset;" *ngIf="toggleCountryselection==true">
        <div class="drop-down-container" >
            <div class="years-list-contianer">
                <ng-container *ngFor="let item of countryList; let i=index">
                    <div class="y-dropdown-item"  [ngClass]="{'border-top': i>0}" (click)="selectCountry(item)">
                        <label class="y-item-label" for="">{{item}}</label>
                        <div class="select-mark" *ngIf="selectedCountry==item">
                            <img class="img" src="../../../../../assets/checkmark-white.svg" alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-element mt-3" style="width: 43%;">
        <div class="icon-side">
            <img src="../../../../../assets/Add-Workers.png" alt="">
        </div>
        <div class="input-side">
            <input type="number" placeholder="Add number of workers" formControlName="employeesNumber" class="arrow-padding">
        </div>
    </div>
    <div class="form-element mt-3" style="width: 43%;" (click)="toggleCopmanyTypeselection=!toggleCopmanyTypeselection">
        <div class="icon-side">
            <img src="../../../../../assets/Company-Type.png" alt="">
        </div>
        <div class="input-side fake-input" [ngClass]="{'input-diabled': selectedCompanyType==''}">
            {{(selectedCompanyType!=""&&selectedCompanyType!=null)?selectedCompanyType:"Company type"}}
        </div>
    </div>
    <div class="form-element mt-3" style="width: 43%; height: unset;" *ngIf="toggleCopmanyTypeselection==true">
        <div class="drop-down-container" >
            <div class="years-list-contianer">
                <ng-container *ngFor="let item of companyTypes; let i=index">
                    <div class="y-dropdown-item"  [ngClass]="{'border-top': i>0}" (click)="selectCompanyType(item)">
                        <label class="y-item-label" for="">{{item}}</label>
                        <div class="select-mark" *ngIf="selectedCompanyType==item">
                            <img class="img" src="../../../../../assets/checkmark-white.svg" alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    
    <div class="form-element mt-3" style="width: 43%;"  (click)="toggleActivitySelection=!toggleActivitySelection">
        <div class="icon-side">
            <img src="../../../../../assets/Company-activity.png" alt="">
        </div>
        <div class="input-side fake-input" [ngClass]="{'input-diabled': selectedActivity==''}">
            {{selectedActivity!=''?selectedActivity:'Activity'}}
        </div>
    </div>
    <div class="form-element mt-3" style="width: 43%; height: unset;" *ngIf="toggleActivitySelection==true">
        <div class="drop-down-container" >
            <div class="years-list-contianer">
                <ng-container *ngFor="let item of companyActivity; let i=index">
                    <div class="y-dropdown-item"  [ngClass]="{'border-top': i>0}" (click)="selectActivity(item)">
                        <label class="y-item-label" for="">{{item}}</label>
                        <div class="select-mark" *ngIf="selectedActivity==item">
                            <img class="img" src="../../../../../assets/checkmark-white.svg" alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="form-element mt-3" style="width: 43%;">
        <input type="file"  #fileInput style="display: none;" (change)="onFilechange($event)">
        <div class="icon-side">
            <img src="../../../../../assets/svgviewer-png-output.png" alt="">
        </div>
        <div class="input-side fake-input" [ngClass]="{'input-diabled': (fileUrl=='')}"  (click)="fileInput.click()">
            {{fileUrl!=''?filename:'Company Logo'}}
        </div>
    </div>

    <div class="company-site-container" *ngIf="false">
        <div class="form-element  mt-3" style="width: 43%;">
            <div class="icon-side">
                <img src="../../../../../assets/office.svg" alt="">
            </div>
            <div class="input-side">
                <input type="text" placeholder="Country" formControlName="siteCountryName">
            </div>
        </div>
        <div class="form-element  mt-3" style="width: 43%;" *ngIf="false">
            <div class="icon-side">
                <img src="../../../../../assets/location.svg" alt="">
            </div>
            <div class="input-side">
                <input type="text" placeholder="Location" formControlName="siteLocationName">
            </div>
        </div>
        <div class="add-button" (click)="addLocation()" *ngIf="false">
            <em class="icon-add"></em>
        </div>
    </div>


</form>
<div class="location-tags-contaier" *ngIf="locationTags.length!=0">
    <div class="tags-container">
        <ng-container *ngFor="let item of locationTags">
            <div class="tag" >
                <label class="location">{{item.location}}, {{item.country}}</label>
                <em class="icon-close" (click)="removeLocation(item)"></em>
            </div>
        </ng-container>
        <button class="clear-button" (click)="locationTags=[]">Clear All</button>

    </div>
</div>
<div class="actions-container">
    <button class="back" (click)="goToNextStep('back')">Back</button>
    <button class="compare" (click)="goToNextStep('next')">Continue</button>
</div>
