<div id="openModal-about" class="modalDialog">
  <div class="re-modal-content" *ngIf="!imgSrc">
    <form>
      <div class="re-modal-header">
        <em class="icon-close close" (click)="closeModal()"></em>
      </div>
      <div class="content-modal">
        <div class="text-center">
          <i class="icon-image" id="mid-pic" aria-hidden="true"></i>
        </div>
        <div class="text-center">
          <label class="change-label"> Change picture </label>
        </div>
        <div class="text-center">
          <div
            class="uploadfilecontainer"
            (click)="fileInput.click()"
            (onFileDropped)="uploadFile($event)"
          >
            <label class="drag-label">Choose a file or drag it here</label>
            <input
              hidden
              type="file"
              #fileInput
              (change)="uploadFile($event.target.files)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="re-modal-content-next" *ngIf="imgSrc">
    <form>
      <div class="re-modal-header-next" style="float: right">
        <em class="icon-close close" (click)="closeModal()"></em>
      </div>
      <div class="content-modal-next">
        <div class="text-center">
          <img [src]="imgSrc" alt="" class="preview-image" />
        </div>

        <div class="text-center">
          <div class="footer">
            <button
              class="btn-solid default"
              style="float: left"
              (click)="reset()"
            >
              Cancel
            </button>
            <button
              class="btn-solid secondary"
              style="float: right"
              (click)="saveProfilPicture()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
