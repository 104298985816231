import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from "../helper.service";
import { HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ModulesService {


  environmentUrl = "Debug api";
  constructor(private http: HttpClient, private helperService: HelperService) {
    this.environmentUrl = environment.apiUrl;
  }

  public getModules(type): any {
    return this.http.get<any>(`${this.environmentUrl}/api/module/${type}`);
  }

  public createModule(module): any {
    return this.http.post<any>(`${this.environmentUrl}/api/module`,module);
  }

  public updateModule(module): any {
    return this.http.post<any>(`${this.environmentUrl}/api/module`,module);
  }

  public deleteModule(id): any {
    return this.http.delete<any>(`${this.environmentUrl}/api/module/${id}`);
  }
}
