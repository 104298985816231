import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Groups, EndGroups } from 'src/app/models/dtos.model';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.scss']
})
export class MaterialModalComponent implements OnInit {
  public level: boolean = false;
  public descriptionComponent: boolean = false;
  public descriptionText: string = "";
  public level2: EndGroups[];
  public sharedComponents: any[];
  public selectedLevel2 = "";
  public selectedLevel1 = "";
  public companyId : number;
  public selectedShared : any;
  public searchEGroup : string = '';
  public subGroups: any[];
  public privateSubGroup: any[]=[];
  public endGroups:any;
  public initialEndGroups:any;
  public locations:any;
  public canShowEndGroups:Boolean=true;
  public selectedItem:any;
  public selectedLocation:any;
  public canShowDescription:boolean=false;
  public canShowLocations:boolean=false;
  public canShowAdd:boolean=false;
  public canShowSharedDetails:boolean=false;
  public showSub:boolean=true;
  public showPrivate:boolean=false;
  public currentCompany :any;
  public myPorjectsSubprojects:any[]=[];
  public myPorjectsSubfolders=[];
  public fullsharedComponents =[];

  public selectedLocations:any[]=[]
  public searchResults:any=[];
  public highlightedToken=""

  @Input() public material;
  @Input() public title;
  @Input() public screeningId;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    public screeningService: ScreeningService,
    private inventoryService:InventoryService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private shareService:ShareService
    ) { }

  ngOnInit(): void {
    this.companyId = Number(localStorage.getItem("company_id"));
    if(this.title=="Shared Component")
    {
      this.title="Shared and imported products";
      this.screeningService.getSharedScreeningByCompanyId(this.companyId).subscribe(res => {
        this.level = true;
        this.sharedComponents = res;
        
        this.fullsharedComponents = this.sharedComponents;
      });
      this.shareService.getComanyShared(Number(localStorage.getItem("company_id"))).subscribe(ans=>{
        this.myPorjectsSubfolders=ans
        this.myPorjectsSubfolders.forEach(x=>{
          this.myPorjectsSubprojects=[...this.myPorjectsSubprojects,x?.showImportedScreeningDtos]
        })
        this.myPorjectsSubprojects=this.myPorjectsSubprojects[0]
      })
    }
    else
    {
      this.route.paramMap.subscribe(params => {
        this.inventoryService.getScreeningGroup({screeningId:this.screeningId, groupName:this.title}).subscribe((res:any)=>{
          this.companyService.retrieveCustomerCompany(Number(localStorage.getItem("company_id"))).subscribe(companyResult=>{
            this.currentCompany=companyResult;
          });
          this.subGroups=res.subGroups.filter(x=>x.companyId==0);
          
          this.searchEndGroups=[].concat.apply([], this.subGroups.map(x=>x.endGroups));
          
          this.privateSubGroup=res.subGroups.filter(x=>x.companyId!=0);
        });
      });
    }
  }

  public showEndGroups(subGroup):void{
    this.endGroups=subGroup.endGroups;
    this.initialEndGroups=this.endGroups;
    this.canShowEndGroups = true;
    this.searchEGroup='';
  }

  public searchEndGroups:any=[];
  public searchEndGroup():void{
    this.searchResults=[];
    
    for (let i = 0; i < this.searchEndGroups.length; i++) {
      if(this.searchEndGroups[i]['processes']?.length!=0){
        let temp=this.searchEndGroups[i]['processes'].filter(x=>x.name?.toLowerCase().includes(this.searchEGroup.toLowerCase()));
        if(temp!=[]){
          this.searchResults=this.searchResults.concat(temp);
        }
      }else{
        if(this.searchEndGroups[i].name.toLowerCase().includes(this.searchEGroup.toLowerCase())){
          this.searchResults.unshift(this.searchEndGroups[i])
        }
      }
    }
    
  }

  public selectSearchItem(target){
    if(target.hasOwnProperty('endGroupId')){
      this.selectProcess(target);
    }else{
      this.selectEndGroup(target);
    }
  }

  public highlightingToken(target){
    return target.toLocaleLowerCase().replace(this.searchEGroup.toLocaleLowerCase(),"<span class='ofInner'>"+this.searchEGroup.toLocaleLowerCase()+"</span>");
  }

  public selectEndGroup(endGroup):void{
    if(endGroup.hasChildren==false){
      this.canShowDescription=true;
      this.selectedItem=endGroup;
      this.selectedLocation=null;
      this.canShowAdd=false;
      this.inventoryService.getEndGroupLocations(endGroup.id).subscribe(res=>{
        this.locations=res;
        this.selectedLocations=[];
        this.canShowLocations=true;
      });
    }
  }

  public selectProcess(process):void{
    this.canShowDescription=true;
    this.selectedItem=process;
    this.selectedLocation=null;
    this.canShowAdd=false;
    this.inventoryService.getProcessLocations(process.id).subscribe(res=>{
      this.locations=res;
      this.selectedLocations=[];
      this.canShowLocations=true;
    });
  }

  public close(): void {
    this.activeModal.close();
  }

  public selectSharedComponent(component:any):void{
    component.id=0;
    this.canShowSharedDetails=true;
    this.material.name=component.name;
    this.material.unit="kg";
    this.material.weight=component.weight;
    this.canShowAdd=true;
    this.selectedShared = component;
    this.selectedShared.id=0;
  }

  public selectLocation(locationToAdd):void{
    this.selectedLocation=locationToAdd;
    this.material.name=this.selectedItem.name;
    this.material.unit=this.selectedItem.unit;
    this.material.locationEmissionId=locationToAdd.id;
    this.material.locationName=locationToAdd.location;
    this.canShowAdd=true;
  }

  public removeLocation(targetLocation){
    this.selectedLocation=null;
  }

  public addMaterial():void{
    if(this.title != 'Shared and imported products'){
      this.passEntry.emit(this.material);
      this.activeModal.close(this.material);
    }
    else if(this.title == 'Shared and imported products'){
      this.passEntry.emit(this.selectedShared);
      this.activeModal.close(this.selectedShared);
    }
  }

  public tooglePrivate():void{
    this.showPrivate=!this.showPrivate;
    this.showSub=false;
  }

  public toogleSubGroups():void{
    this.showSub=!this.showSub;
    this.showPrivate=false;
  }
  public selectShared(name: string):void{
    this.sharedComponents=this.fullsharedComponents
    this.sharedComponents= this.sharedComponents.filter(x=>x.company.companyName == name);
  }

}
