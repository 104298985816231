import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { MastersheetService } from '../../../services/mastersheet.service';

@Component({
  selector: 'app-sub-groups-overview',
  templateUrl: './sub-groups-overview.component.html',
  styleUrls: ['./sub-groups-overview.component.scss']
})
export class SubGroupsOverviewComponent implements OnInit {
  @Output() navigation=new EventEmitter<any>();
  public currentSubGr= null;
  public displayUpsertSubGroup=false;
  public subGroupToEdit: FormGroup;
  public subGroupList:any[]=[];
  public curentGroupId:Number;
  public companies:any[]=[];

  constructor(
    private fb: FormBuilder,
    private masterSheetService:MastersheetService,
    private route: ActivatedRoute,
    private companyService:CompanyService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyService.getSuppliers().subscribe(res=>{
        this.companies = res;
      });
      this.curentGroupId=Number(params['groupId']);
      this.getSubGroups(this.curentGroupId);
      this.subGroupToEdit = this.fb.group({
        subGroupName:[this.currentSubGr?this.currentSubGr.subGroupName:""],
        groupId:[this.curentGroupId],
        companyId:[0],
      });
    })
  }

  public getSubGroups(groupeId){
    this.masterSheetService.subGroupsOfGroup(groupeId).subscribe(ans=>{
      this.subGroupList=ans
    })
  }

  public showUpsertSubGroup(currentSubGr){
    this.currentSubGr=currentSubGr;
    this.displayUpsertSubGroup=true;
  }

  public dismissModal(){
    this.displayUpsertSubGroup=false;
  }

  public hideUpsertSubGroup(result){
    this.displayUpsertSubGroup=false;
    if(result!=null){
      if(this.currentSubGr==null){
        this.subGroupList.push(result);
      }else{
        this.subGroupList[this.subGroupList.indexOf(this.currentSubGr)]=result;
        this.currentSubGr=null;
      }
      
    }
  }

  public delete(subgr){
    this.subGroupList.splice(this.subGroupList.indexOf(subgr),1);
  }

  public navigate(subgr){
    this.navigation.emit({type:"Sub-groups",data:subgr,title:subgr.subGrName})
  }

  public upsert(){

    if(!this.currentSubGr){
      this.masterSheetService.createSubGroup(this.subGroupToEdit.value).subscribe(ans=>{
        this.displayUpsertSubGroup=false;
        this.getSubGroups(this.curentGroupId);
      })
    }else{
      //update database
    }
  }

  public selectCompany(companyId:any):void{
    this.subGroupToEdit.get('companyId').patchValue(companyId);
  }
}
