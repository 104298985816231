import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-show-file",
  templateUrl: "./show-file.component.html",
  styleUrls: ["./show-file.component.css"],
})
export class ShowFileComponent implements OnInit {
  public isImage: boolean = false;

  @Input() file: any;
  @Output() closeFile = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    if (this.file.fileUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
      this.isImage = true;
    } else {
      this.isImage = false;
    }
  }

  public closeModal(): void {
    this.closeFile.emit(false);
  }

  public showFile(fileUrl: string): void {
    window.open(fileUrl, "_target");
  }
}
