<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content-next">
      <form>
        <div class="re-modal-header-next" style="float: right">
          <em class="icon-close close" (click)="closeModal()"></em>
        </div>
        <div class="content-modal-next">
            <div class="text-center">
                <label class="change-label">Validation Request</label>
            </div>
            <div class="text-center">
                <ul>
                    <li class="information-label">By pressing submit changes you agree to have submitted the provided corrections from the correction scheme provided by the ReFlow Engineering Team.</li>
                    <li class="information-label">After pressing the Submit Changes, the assessment will be reviewed by the ReFlow Engineering Team to assure that the assessment aligns with the engineering recommendations.</li>
                    <li class="information-label">If the assessment aligns, the assessment will received a Validated status and its specific validation certificate to prove the engineering validation. Updates on the process are available in the project navigation panel on the quantify page.</li>
                </ul>
                <label class="information-label-center">To proceed, press "Submit changes" or "Cancel" if submission is to be terminated.</label>
            </div>
          <div class="text-center">
            <div class="footer">
              <button
                class="btn-solid default"
                style="float: left"
                (click)="closeModal()"
              >
                Cancel
              </button>
              <button
                class="btn-solid secondary"
                style="float: right"
                (click)="SubmitCorrection()"
              >
                Submit changes
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  