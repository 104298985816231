<div class="calculation-stepper-container">
    <div class="wizard-container">
        <ng-container *ngFor="let item of stepsTracker; let idx=index">
            <div class="step-container" (click)="currentStep=idx+1">
                <div class="check-mark" [ngClass]="{'cm-success': currentStep>=item.id}">
                    <img src="../../../../assets/checkmark-black.svg" alt="" *ngIf="currentStep>=item.id">
                    <img src="../../../../assets/checkmark-grey.svg" alt="" *ngIf="!(currentStep>=item.id)">
                </div>
                <div class="step">
                    <label [ngClass]="{'cmc-success': currentStep>=item.id}">{{item.name}}</label>
                    <div class="borbot" [ngClass]="{'cm-success': currentStep>=item.id}"></div>
                </div>
            </div>
        </ng-container>
    </div>
    <p class="step-description">{{stepsTracker[currentStep-1].description}}</p>

    <div class="current-step-container">
        <app-year-selection *ngIf="currentStep==1" (nextStep)="goToNextStep($event)" ></app-year-selection>
        <app-company-profile *ngIf="currentStep==2" (nextStep)="goToNextStep($event)"></app-company-profile>
        <app-data-collection *ngIf="currentStep==3"  (nextStep)="goToNextStep($event)"></app-data-collection>
        <app-result-company-asssessment *ngIf="currentStep==4"></app-result-company-asssessment>
    </div>

</div>


