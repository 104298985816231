import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-validation-request-modal',
  templateUrl: './validation-request-modal.component.html',
  styleUrls: ['./validation-request-modal.component.scss']
})
export class ValidationRequestModalComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public showModal: boolean = false;
  @Input() public userId: number;
  @Input() public screeningId: number;
  public showFirst : boolean =true
  public show : boolean = false
  public id : number ;
  public currentUser: User;
  public currentScreening: any;
  showLoading: boolean =false;
  showContact : boolean =false
  constructor(private validationService:ValidationService, private userService: UserService, private screeningService:ScreeningService) { }

  ngOnInit(): void {
    this.id=Number(localStorage.getItem("user_id"))
    this.userService.getUserById(this.id).subscribe(res => {
      this.currentUser = res;
    });
    this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
      this.currentScreening=res;
    });
    
  }

  public close(): void {
    this.showModal = false;
    this.toogleModal.emit(this.showModal);
  }

  public showSecondModal(): void {
    this.showFirst = false;
    this.showLoading = true;
    this.validationService.requestValidation({screeningId:this.screeningId, userId:Number(localStorage.getItem("user_id"))}).subscribe(res=>{
      
    
      setTimeout(() => {
        this.showLoading = false;
      },
        3000);
        setTimeout(() => {
          this.show = true;
        },
          3000);
    });
  }

  public showContactModal() : void {
    this.show = false ;
    this.showContact = true;
  }
  
  public saveContact() : void {
    this.show = true ;
    this.showContact = false;
  }
  public saveEmail(value) : void {
    this.currentUser.email = value.target.value
  }

}
