<nav
  class="navbar navbar-expand-lg navbar-light"
  style="background-color: transparent; padding-top: 30px"
>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="margin-left: 20px">
      <label class="content-title" (click)="showComponents()">Components</label>
      <label class="clicked-content-title">Exchange Panel</label>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="row float-right">
        <div class="inner-addon right-addon">
          <em class="glyphicon fa fa-search"></em>
          <input
            type="text"
            class="form-control re-search"
            placeholder="Search"
            [(ngModel)]="searchTerm"
            name="searchTerm"
          />
        </div>
        <div class="col-md-4"></div>
      </div>
    </form>
  </div>
</nav>
<div class="row">
  <table class="re-double-table">
    <caption></caption>
    <thead>
      <tr>
        <th id="">Vessel Location</th>
        <th id="">Reference</th>
        <th id="">Type</th>
        <th id="">Description</th>
        <th id="">Status</th>
        <th id="">Days in status</th>
        <th id="">Return to</th>
      </tr>
    </thead>
    <tbody>
      <ng-container class="table-container" *ngFor="let exchange of exchanges">
        <tr class="tr-top">
          <td class="td-left" rowspan="2">
            <div class="row">
              <div class="col-3">
                <img alt="" src="{{ exchange.vesselImage }}" />
              </div>
              <div class="col-9">
                <label class="label-top">{{ exchange.vesselName }} </label>
                <label class="label-bottom">{{ exchange.location }} </label>
                <div class="chain"><em class="fa fa-link"></em></div>
              </div>
            </div>
          </td>
          <td class="td-id">
            <img src="{{ exchange.componentImage }}" alt="" />
            {{ exchange.componentReference }}
          </td>
          <td class="td-rest">{{ exchange.componentType }}</td>
          <td class="td-rest">{{ exchange.componentDescription }}</td>
          <td class="td-rest">{{ exchange.componentStatus }}</td>
          <td class="td-rest">
            <em class="icon-clock"></em> {{ exchange.componentDaysStatus }}
          </td>
          <td class="td-rest">
            <img src="{{ exchange.componentReturnCompanyImage }}" alt="" />
            {{ exchange.componentReturnCompany }}
          </td>
        </tr>
        <tr class="tr-bottom" *ngIf="exchange.exchangeId != 0">
          <td class="td-id">
            <img src="{{ exchange.exchangeImage }}" alt="" />
            {{ exchange.exchangeReference }}
          </td>
          <td class="td-rest">{{ exchange.exchangeType }}</td>
          <td class="td-rest">{{ exchange.exchangeDescription }}</td>
          <td class="td-rest">{{ exchange.exchangeStatus }}</td>
          <td class="td-rest">
            <em class="icon-clock"></em> {{ exchange.exchangeDaysStatus }}
          </td>
          <td class="td-rest">
            <img src="{{ exchange.exchangeReturnCompanyImage }}" alt="" />
            {{ exchange.exchangeReturnCompany }}
          </td>
        </tr>
        <tr class="tr-bottom" *ngIf="exchange.exchangeId == 0">
          <td class="td-unregistred" colspan="6">
            <label class="label-unregistred"
              >Unregistered return component</label
            >
            <button
              class="btn-solid primary"
              (click)="recieve(exchange.componentId, exchange.componentStatus)"
            >
              Receive
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<app-request-demo> </app-request-demo>
