<div class="container-mobile">
    <div class="row">
        <a class="logo" href="http://www.re-flow.io/" target="_blank" rel="noopener noreferrer">
            <img src="../../assets/mobile/reflow_logo.svg" alt=""/>
        </a>
    </div>
        <label class="title">Hi there!</label>
        <label class="info">
            Please use one of the following devices to access our platform.
        </label>
        <label class="device">PC / Mac / Tablet</label>
    <div class="footer">
        <div class="social">
            <a href="https://www.facebook.com/reflowmaritime" target="_blank" rel="noopener noreferrer">
                <img class="social-icon" src="../../assets/mobile/reflow_facebook-icon.svg" alt=""/>
            </a>
            <a href="https://www.linkedin.com/company/reflow-maritime" target="_blank" rel="noopener noreferrer">
                <img class="social-icon" src="../../assets/mobile/reflow_linkedin-icon.svg" alt=""/>
            </a>
        </div>
        <label class="copyright">
            V1.0.1 Copyright © {{ current_year }} ReFlow ApS - All rights reserved
        </label>
    </div>
</div>
