<div class="modals rename-modal">
    <div class="main-modal-content rename">
        <div class="header">
            <em class="icon-close" (click)="closeModal()"></em>
        </div>
        <div class="body">
            <div class="title-container">
                <img src="../../../../assets/import_project.svg" alt="">
                <label for="" class="title">Import project</label>
            </div>
            <div class="form-group">
                <p class="description">
                    Please past the received calculation code below. When importing you will be able to see the calculation result and use the product in you own calculation
                </p>
                <input type="text" class="form-control" placeholder="Paste your url code here" [(ngModel)]="inputCode">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="rename rename-btn" (click)="save()">Import</button>
            </div>
        </div>
    </div>
</div>
<div class="overlay"></div>
