<div class="loading-container" *ngIf="noDataToShow&&!showComparison">
    <div class="loading-content">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>

        <label class="h3-heading" style="display: block;">No projects selected</label>
        <label class="h4-heading loading">Please, select projects to compare..</label>
    </div>
</div>
<div class="loading-container" *ngIf="showload==true">
    <div class="loading-content">
        <img src="../../../../../assets/Loading-icon-animation.svg" style="height: 125px; width: 125px;" alt="" />
        <label class="h4-heading loading" style="display: block;">Please wait while we are processing your comparison
            projects</label>
    </div>
</div>
<div class="compare-container" *ngIf="showload==false">
    <label class="big-title">Comparison</label>
    <div class="intro-container">
        <div class="descriptions-container">
            <p class="simple-desc">
                An overview of the compared products is presented in this section, including product specifications,
                calculated carbon
                footprints, and estimated carbon footprints of the hypothetical linear economy scenario. The product in
                the first column
                is defined as the baseline and the following are compared alternative(s) to the baseline. The values in
                the green or red
                boxes show the differences between the parameters across the compared products.
            </p>
            <div class="warning-desc-container">
                <img src="../../../../assets/disclamer.png" alt="" class="warning">
                <p class="description">
                    Disclaimer: The information provided on this page must be interpreted with caution. A non-misleading
                    selection of
                    the compared alternatives is a prerequisite for reusing the information on this page to draw
                    conclusions or
                    recommendations on the superiority or equality of any of the compared products.
                </p>
            </div>
        </div>
        <button class="download" (click)="downloadProof()">
            <em class="icon-download"> </em>
            <label>Download</label>
        </button>
    </div>
    <div class="data-container mt50">
        <div class="data-row">
            <label class="simple-title col16">specifications:</label>
            <label class="under-title col28">Baseline</label>
            <label class="under-title col28">Compared to Baseline</label>
            <label class="under-title col28" *ngIf="screeningResults?.length==3">Compared to Baseline</label>
        </div>
        <div class="data-row mt15">
            <label class="data-item with-bold col16">Project Name:</label>
            <label class="data-item with-bold col28">{{screeningResults[0]?.projectName}}</label>
            <label class="data-item with-bold col28">{{screeningResults[1]?.projectName}}</label>
            <label class="data-item with-bold col28"
                *ngIf="screeningResults?.length==3">{{screeningResults[2]?.projectName}}</label>
        </div>
        <div class="data-row mt10">
            <label class="data-item col16">Product Name:</label>
            <label class="data-item col28">{{screeningResults[0]?.productName}}</label>
            <label class="data-item col28">{{screeningResults[1]?.productName}}</label>
            <label class="data-item col28"
                *ngIf="screeningResults?.length==3">{{screeningResults[2]?.productName}}</label>
        </div>
        <div class="data-row mt10">
            <label class="data-item col16">Product Type:</label>
            <label class="data-item col28">{{screeningResults[0]?.productType}}</label>
            <label class="data-item col28">{{screeningResults[1]?.productType}}</label>
            <label class="data-item col28"
                *ngIf="screeningResults?.length==3">{{screeningResults[2]?.productType}}</label>
        </div>
        <div class="data-row mt10">
            <label class="data-item col16">Weight:</label>
            <label class="data-item col28">{{screeningResults[0]?.weight}}</label>
            <label class="data-item col28">{{screeningResults[0]?.weight}}</label>
            <label class="data-item col28" *ngIf="screeningResults?.length==3">{{screeningResults[0].weight}}</label>
        </div>
        <div class="data-row mt10">
            <label class="data-item col16">Lifespan:</label>
            <label class="data-item col28 d-flex">
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin ">{{screeningResults[0]?.lifeSpan}} years</p><small
                        class="btn-description">(Initial)</small>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text plus">+</p>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin">{{screeningResults[0]?.extendedLifeSpan}} years</p><small
                        class="btn-description">(Extended)</small>
                </span>
            </label>
            <label class="data-item col28 d-flex">
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin ">{{screeningResults[1]?.lifeSpan}} years</p><small
                        class="btn-description">(Initial)</small>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text plus">+</p>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin">{{screeningResults[1]?.extendedLifeSpan}} years</p><small
                        class="btn-description">(Extended)</small>
                </span>
            </label>
            <label class="data-item col28 d-flex" *ngIf="screeningResults?.length==3">
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin ">{{screeningResults[2]?.lifeSpan}} years</p><small
                        class="btn-description">(Initial)</small>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text plus">+</p>
                </span>
                <span class="right-small-container clear-padding">
                    <p class="black-text clear-margin">{{screeningResults[2]?.extendedLifeSpan}} years</p><small
                        class="btn-description">(Extended)</small>
                </span>
            </label>
        </div>
    </div>
    <div class="data-container">
        <div class="data-row">
            <label class="simple-title col16">System definition</label>
        </div>
        <div class="data-row  mt10">
            <label class="data-item as-description col16">Life cycle stages included in the assessment</label>
            <div class="data-item vertical col28">
                <label *ngFor="let item of screeningResults[0]?.lifeCycles">{{item.stage}}</label>
            </div>
            <div class="data-item vertical col28">
                <label *ngFor="let item of screeningResults[1]?.lifeCycles">{{item.stage}}</label>
            </div>
            <div class="data-item vertical col28" *ngIf="screeningResults?.length==3">
                <label *ngFor="let item of screeningResults[2]?.lifeCycles">{{item.stage}}</label>
            </div>
        </div>
    </div>
    <div class="data-container dc-emission">
        <div class="params-data-row">
           <div class="data-item vertical col16">
              <label class="simple-title col16">Parameters:</label>
              <label class="param-name pram-title">Total carbon footprint</label>
              <p class="as-description mt15">The total carbon footprint refers to the complete amount of greenhouse gases,
                in one unit, including all upstream and downstream activities.
                It comprises both direct and indirect emissions hence capturing the full extent of the climate impact.</p>
           </div>
           <div class="data-item vertical col28 v-center">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name center-txt-lables">{{screeningResults[0]?.productName}}</label>
                    <label class="product-label center-txt-lables">Baseline</label>
                </div>
                 
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[0]?.carbonFootPrint | number: '1.0-2'}}</label>
                       <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>

                 </div>
              </div>
           </div>
           <div class="data-item vertical col28 v-center">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name">{{screeningResults[1]?.productName}}</label>
                    <label class="product-label">&nbsp;</label>
                </div>
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[1]?.carbonFootPrint | number: '1.0-2'}}</label>
                       <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <div class="bottle-container">
                       <div class="more-bottles">
                          <div class="big-bottle ml-3">
                             <label class="bot-top-value top-red" *ngIf="screeningResults[1].footPrintDifference>0">+{{((screeningResults[1].footPrintDifference)/screeningResults[0]?.carbonFootPrint)*100 | number: '1.0-2'}}%</label>
                             <label class="bot-top-value top-green" *ngIf="screeningResults[1].footPrintDifference<0">-{{((screeningResults[1].footPrintDifference*-1)/screeningResults[0]?.carbonFootPrint)*100 | number: '1.0-2'}}%</label>
                             <div class="bottle">
                                <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-(((screeningResults[1].footPrintDifference)/screeningResults[0]?.carbonFootPrint)*100))+'%'}" *ngIf="screeningResults[1].footPrintDifference>0"></div>
                                <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-(((screeningResults[1].footPrintDifference*-1)/screeningResults[0]?.carbonFootPrint)*100))+'%'}" *ngIf="screeningResults[1].footPrintDifference<0"></div>
                                <div class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': ((screeningResults[1].footPrintDifference)/screeningResults[0]?.carbonFootPrint)*100+'%'}" *ngIf="screeningResults[1].footPrintDifference>0"></div>
                                <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': ((screeningResults[1].footPrintDifference*-1)/screeningResults[0]?.carbonFootPrint)*100+'%'}"  *ngIf="screeningResults[1].footPrintDifference<0"></div>
                                <div class="overlay-bottle-data">
                                   <label>Compared to baseline</label>
                                   <label>{{screeningResults[1].footPrintDifference | number: '1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name">{{screeningResults[2]?.productName}}</label>
                    <label class="product-label">&nbsp;</label>
                </div>
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[2]?.carbonFootPrint | number: '1.0-2'}}</label>
                       <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <div class="bottle-container">
                       <div class="more-bottles">
                        <div class="big-bottle ml-3">
                            <label class="bot-top-value top-red" *ngIf="screeningResults[2].footPrintDifference>0">+{{((screeningResults[2].footPrintDifference)/screeningResults[0]?.carbonFootPrint)*100 | number: '1.0-2'}}%</label>
                            <label class="bot-top-value top-green" *ngIf="screeningResults[2].footPrintDifference<0">-{{((screeningResults[2].footPrintDifference*-1)/screeningResults[0]?.carbonFootPrint)*100 | number: '1.0-2'}}%</label>
                            <div class="bottle">
                               <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (screeningResults[2].footPrintDifferencePercent>100?0:100-screeningResults[2].footPrintDifferencePercent)+'%'}"></div>
                               <div class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': (screeningResults[2].footPrintDifferencePercent>100?100:screeningResults[2].footPrintDifferencePercent) +'%'}" *ngIf="screeningResults[2].footPrintDifference>0"></div>
                               <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': (screeningResults[2].footPrintDifferencePercent>100?100:screeningResults[2].footPrintDifferencePercent) +'%'}" *ngIf="screeningResults[2].footPrintDifference<0"></div>
                               <div class="overlay-bottle-data">
                                  <label>Compared to baseline</label>
                                  <label>{{screeningResults[2].footPrintDifference | number: '1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                               </div>
                            </div>
                         </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>

    <div class="data-container dc-emission">

        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">Carbon footprint Reduction
                    <em class="icon-info" [ngbPopover]="popupContent" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">When incorporating any circular strategy, a decrease in the carbon footprint is observed compared to the conventional linear economy scenario.</p>
            </div>
            <div class="data-item vertical col28 v-center">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name center-txt-lables">{{screeningResults[0]?.productName}}</label>
                    <label class="product-label center-txt-lables">Baseline</label>
                </div>
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}%</label>
                    </div>
                 </div>
              </div>
           </div>
           <div class="data-item vertical col28 v-center">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name">{{screeningResults[1]?.productName}}</label>
                    <label class="product-label">&nbsp;</label>
                </div>
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[1]?.footPrintReductionPercentage | number:'1.0-2'}}%</label>
                    </div>
                    <div class="bottle-container">
                       <div class="more-bottles">
                          <div class="big-bottle ml-3">
                             <div class="bottle">
                                <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': 30+'%'}"></div>
                                <div *ngIf="screeningResults[1]?.footPrintReductionPercentage>screeningResults[0]?.footPrintReductionPercentage" class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': 70+'%'}"></div>
                                <div *ngIf="screeningResults[1]?.footPrintReductionPercentage<screeningResults[0]?.footPrintReductionPercentage" class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': 70+'%'}"></div>
                                <div class="overlay-bottle-data">
                                   <label>Compared to baseline</label>
                                   <label *ngIf="screeningResults[1]?.footPrintReductionPercentage>screeningResults[0]?.footPrintReductionPercentage">+{{screeningResults[1]?.footPrintReductionPercentage-screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}</label>
                                   <label *ngIf="screeningResults[1]?.footPrintReductionPercentage<screeningResults[0]?.footPrintReductionPercentage">{{screeningResults[1]?.footPrintReductionPercentage-screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}</label>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
              <div class="comparison-product-container">
                <div class="cp-title-container">
                    <label class="product-name">{{screeningResults[2]?.productName}}</label>
                    <label class="product-label">&nbsp;</label>
                </div>
                 <div class="params-data-content">
                    <div class="param-value">
                       <label for="" class="scope">&nbsp;</label>
                       <label for="" class="value">{{screeningResults[2]?.footPrintReductionPercentage | number:'1.0-2'}}%</label>
                    </div>
                    <div class="bottle-container">
                       <div class="more-bottles">
                        <div class="big-bottle ml-3">
                            <div class="bottle">
                               <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': 30+'%'}"></div>
                               <div *ngIf="screeningResults[2]?.footPrintReductionPercentage>screeningResults[0]?.footPrintReductionPercentage" class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': 70+'%'}"></div>
                               <div *ngIf="screeningResults[2]?.footPrintReductionPercentage<screeningResults[0]?.footPrintReductionPercentage" class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': 70+'%'}"></div>
                               <div class="overlay-bottle-data">
                                  <label>Compared to baseline</label>
                                  <label *ngIf="screeningResults[2]?.footPrintReductionPercentage>screeningResults[0]?.footPrintReductionPercentage">+{{screeningResults[2]?.footPrintReductionPercentage-screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}</label>
                                  <label *ngIf="screeningResults[2]?.footPrintReductionPercentage<screeningResults[0]?.footPrintReductionPercentage">{{screeningResults[2]?.footPrintReductionPercentage-screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}</label>
                                  <label *ngIf="screeningResults[2]?.footPrintReductionPercentage==0">{{screeningResults[2]?.footPrintReductionPercentage-screeningResults[0]?.footPrintReductionPercentage | number:'1.0-2'}}</label>
                               </div>
                            </div>
                         </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>

        </div>
    </div>
    <div class="data-container dc-emission">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">Carbon Footprint Difference
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>

                </label>
                <p class="as-description mt15">A negative value indicates that the circular scenario has a higher carbon
                    footprint than the linear scenario.</p>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name center-txt-lables">{{screeningResults[0]?.productName}}</label>
                        <label class="product-label center-txt-lables">Baseline</label>
                    </div>
                  
                   <div class="params-data-content">
                      <div class="param-value">
                         <label for="" class="scope">&nbsp;</label>
                         <label for="" class="value">{{screeningResults[0]?.footPrintReduction | number:'1.0-2'}}</label>
                         <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                      </div>
                   </div>
                </div>
             </div>
             <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{screeningResults[1]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                   <div class="params-data-content">
                      <div class="param-value">
                         <label for="" class="scope">&nbsp;</label>
                         <label for="" class="value">{{screeningResults[1]?.footPrintReduction | number:'1.0-2'}}</label>
                         <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                      </div>
                      <div class="bottle-container">
                         <div class="more-bottles">
                            <div class="big-bottle ml-3">
                               <div class="bottle">
                                  <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': 30+'%'}"></div>
                                  <div class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': 70+'%'}" *ngIf="screeningResults[1]?.footPrintReduction<screeningResults[0]?.footPrintReduction"></div>
                                  <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': 70+'%'}" *ngIf="screeningResults[1]?.footPrintReduction>screeningResults[0]?.footPrintReduction"></div>
                                  <div class="overlay-bottle-data">
                                    <label>Compared to baseline</label>
                                    <label *ngIf="screeningResults[1]?.footPrintReduction>screeningResults[0]?.footPrintReduction">+{{screeningResults[1]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    <label *ngIf="screeningResults[1]?.footPrintReduction<screeningResults[0]?.footPrintReduction">{{screeningResults[1]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    <label *ngIf="screeningResults[1]?.footPrintReduction==0">{{screeningResults[1]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{screeningResults[2]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                   <div class="params-data-content">
                      <div class="param-value">
                         <label for="" class="scope">&nbsp;</label>
                         <label for="" class="value">{{screeningResults[2]?.footPrintReduction | number:'1.0-2'}}</label>
                         <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                      </div>
                      <div class="bottle-container">
                         <div class="more-bottles">
                            <div class="big-bottle ml-3">
                               <div class="bottle">
                                  <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': 30+'%'}"></div>
                                  <div class="bottle-side ci-red bs-bottom" [ngStyle]="{'height': 70+'%'}" *ngIf="screeningResults[2]?.footPrintReduction<screeningResults[0]?.footPrintReduction"></div>
                                  <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': 70+'%'}" *ngIf="screeningResults[2]?.footPrintReduction>screeningResults[0]?.footPrintReduction"></div>
                                  <div class="overlay-bottle-data">
                                    <label>Compared to baseline</label>
                                    <label *ngIf="screeningResults[2]?.footPrintReduction>screeningResults[0]?.footPrintReduction">+{{screeningResults[2]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    <label *ngIf="screeningResults[2]?.footPrintReduction<screeningResults[0]?.footPrintReduction">{{screeningResults[2]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    <label *ngIf="screeningResults[2]?.footPrintReduction==0">{{screeningResults[2]?.footPrintReduction-screeningResults[0]?.footPrintReduction | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
        </div>
    </div>
    <div class="data-container fixed-height">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">Most impacting life cycle stage</label>
                <p class="as-description mt15">The life cycle stage with the highest carbon footprint.</p>
            </div>
            <div class="data-item vertical col28 v-center align-items-center">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[0]?.lifeCycleImpacts[0].cycleName }}</label>
                        <label for="" class="value">{{screeningResults[0]?.lifeCycleImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
            <div class="data-item vertical col28 v-center align-items-center">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[1]?.lifeCycleImpacts[0].cycleName }}</label>
                        <label for="" class="value">{{screeningResults[1]?.lifeCycleImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
            <div class="data-item vertical col28 v-center align-items-center" *ngIf="screeningResults?.length==3">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[2]?.lifeCycleImpacts[0].cycleName }}</label>
                        <label for="" class="value">{{screeningResults[2]?.lifeCycleImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="data-container fixed-height">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">MOST IMPACTING LIFE CYCLE PARAMETER</label>
                <p class="as-description mt15">The parameter with the highest carbon footprint.</p>
            </div>
            <div class="data-item vertical col28 v-center align-items-center">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[0]?.parameterImpacts[0].materialName }}</label>
                        <label for="" class="value">{{screeningResults[0]?.parameterImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
            <div class="data-item vertical col28 v-center align-items-center">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[1]?.parameterImpacts[0].materialName }}</label>
                        <label for="" class="value">{{screeningResults[1]?.parameterImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
            <div class="data-item vertical col28 v-center align-items-center" *ngIf="screeningResults?.length==3">
                <div class="params-data-content w-unset">
                    <div class="param-value">
                        <label for="" class="most">{{screeningResults[2]?.parameterImpacts[0].materialName }}</label>
                        <label for="" class="value">{{screeningResults[2]?.parameterImpacts[0].impact | number:
                            '1.0-2'}}</label>
                        <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                    </div>
                    <span></span>
                </div>
            </div>
        </div>
    </div>


    <div class="data-container">
        <div class="data-row">
            <label class="simple-title col16">Charts:</label>
        </div>
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">Total carbon footprint</label>
                <p class="as-description mt15">The graph illustrates the total carbon footprint of each product.
                    Interact with the bars to see more details.</p>
            </div>
            <div class="data-item ">
                <div class="row table-row" *ngIf="footPrintData?.length > 0">
                    <div style="display: inline; width: 950px;height: 125px;margin-left: 100px;">
                        <canvas baseChart [datasets]="footPrintData" [labels]="footPrintLabels"
                            [options]="barChartOptions" [colors]="chartColorsCycle" [legend]="barChartLegend"
                            [chartType]="barChartType">
                        </canvas>
                        <em class="fa fa-square" style="color: #AEAEAE; margin-right: 5px;margin-left: 10px;"></em>
                        <label class="footPrint-legend">{{screeningResults[0]?.projectName}}</label>
                        <em class="fa fa-square ml-2" style="color: #123B68; margin-right: 5px;"></em>
                        <label class="footPrint-legend">{{screeningResults[1]?.projectName}}</label>
                        <em class="fa fa-square ml-2" style="color: #83B9B7; margin-right: 5px;"
                            *ngIf="screeningResults?.length==3"></em>
                        <label class="footPrint-legend"
                            *ngIf="screeningResults?.length==3">{{screeningResults[2]?.projectName}}</label>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="data-container">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title" style="margin-top: 128px;">LIFE CYCLE STAGE IMPACT
                    CONTRIBUTION</label>
                <p class="as-description mt15">The graph illustrates the contribution of each life cycle stage for each
                    product. Interact with the bars to see more details.</p>
            </div>
            <div class="data-item ">
                <div class="row table-row" *ngIf="footPrintData?.length > 0">
                    <div style="display: inline; width: 950px;height: 300px;margin-left: 100px; margin-top:60px; ">
                        <canvas baseChart [datasets]="lifeCycleData" [labels]="lifeCycleLabels"
                            [options]="lifeCycleChartOptions" [colors]="chartColorsCycle" [legend]="barChartLegend"
                            [chartType]="lifeCycleChartType">
                        </canvas>
                        <em class="fa fa-square"
                            style="color: #AEAEAE; margin-right: 5px;margin-left: 30px;margin-top: 25px !important;"></em>
                        <label class="footPrint-legend">{{screeningResults[0]?.projectName}}</label>
                        <em class="fa fa-square ml-2" style="color: #123B68; margin-right: 5px;"></em>
                        <label class="footPrint-legend">{{screeningResults[1]?.projectName}}</label>
                        <em class="fa fa-square ml-2" style="color: #83B9B7; margin-right: 5px;"
                            *ngIf="screeningResults?.length==3"></em>
                        <label class="footPrint-legend"
                            *ngIf="screeningResults?.length==3">{{screeningResults[2]?.projectName}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="data-container" style="margin-top: 45px; padding-top: 50px; border-bottom: solid 2px #123B68;">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title" style="margin-top: 146px;">TOP 5 IMPACT PARAMETERS</label>
                <p class="as-description mt15" style="width: 151px;">The graph illustrates the contribution of the 5
                    most impacting parameters. Interact with the pie charts to see more details.</p>
            </div>
            <div class="data-item" style="margin-left: 118px;">
                <canvas baseChart class="p-chart" [data]="pieChartData1" [labels]="pieChartLabels1"
                    [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend" [colors]="pieChartColors">
                </canvas>
                <div class="data-item p-title">
                    <label class="pie-title">{{screeningResults[0]?.projectName}}</label>
                </div>
                <div class="legends-container mt-3">
                    <div class="legends d-flex" *ngFor="let label of pieChartLabels1; let i = index">
                        <div class="element">
                            <div class="lg-color" [style.background-color]="pieChartColors[0].backgroundColor[i]"></div>
                            <div class="lg-label">{{label}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item">
                <canvas baseChart class="p-chart" [data]="pieChartData2" [labels]="pieChartLabels2"
                    [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend" [colors]="pieChartColors">
                </canvas>
                <div class="data-item p-title">
                    <label class="pie-title">{{screeningResults[1]?.projectName}}</label>
                </div>
                <div class="legends-container mt-3">
                    <div class="legends d-flex" *ngFor="let label of pieChartLabels2; let i = index">
                        <div class="element">
                            <div class="lg-color" [style.background-color]="pieChartColors[0].backgroundColor[i]"></div>
                            <div class="lg-label">{{label}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item" *ngIf="screeningResults?.length==3">
                <canvas baseChart class="p-chart" [data]="pieChartData3" [labels]="pieChartLabels3"
                    [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend" [colors]="pieChartColors">
                </canvas>
                <div class="data-item p-title">
                    <label class="pie-title">{{screeningResults[2]?.projectName}}</label>
                </div>
                <div class="legends-container mt-3">
                    <div class="legends d-flex" *ngFor="let label of pieChartLabels3; let i = index">
                        <div class="element">
                            <div class="lg-color" [style.background-color]="pieChartColors[0].backgroundColor[i]"></div>
                            <div class="lg-label">{{label}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <label class="big-title emission" *ngIf="comparisonResults.hasScopeResult">Emission scopes</label>

    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">MOST IMPACTING SCOPE
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">Scope with the highest contribution.</p>
                <div class="labels-container">
                    <div class="label-element">
                        <div class="color-indicator ci-grey">&nbsp;</div>
                        <label >BASELINE</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element">
                        <div class="color-indicator ci-red">&nbsp;</div>
                        <label >HIGHER IMPACT</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element">
                        <div class="color-indicator ci-green">&nbsp;</div>
                        <label >LOWER IMPACT</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[0]?.productName}}</label>
                        <label class="product-label">Baseline</label>
                    </div>
                    <div class="params-data-content pdc-h150">
                        <div class="param-value">
                            <label for="" class="scope">{{comparisonResults?.impactingScopes[0].highScopeName}}</label>
                            <label for="" class="value">{{comparisonResults?.impactingScopes[0].highScopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="big-bottle">
                                <label class="bot-top-value top-red">&nbsp;</label>
                                <div class="bottle">
                                    <div class="baseline-bottle-content">
                                        <label>Baseline</label>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact <br>{{comparisonResults?.compareItems[0]?.carbonFootPrint | number:'1.0-2'}} kg CO<sub>2</sub> eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[1]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">{{comparisonResults?.impactingScopes[1].highScopeName}}</label>
                            <label for="" class="value">{{comparisonResults?.impactingScopes[1].highScopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="big-bottle">
                                <label class="bot-top-value top-red" *ngIf="comparisonResults?.impactingScopes[1].impactDifference>0">{{comparisonResults?.impactingScopes[1]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <label class="bot-top-value top-green" *ngIf="comparisonResults?.impactingScopes[1].impactDifference<0">{{comparisonResults?.impactingScopes[1]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <label class="bot-top-value top-grey" *ngIf="comparisonResults?.impactingScopes[1].impactDifference==0">{{comparisonResults?.impactingScopes[1]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <div class="bottle">
                                    <div class="bottle-side ci-red bs-top" [ngStyle]="{'height': (comparisonResults?.impactingScopes[1]?.impactDifferencePercent>100?100:comparisonResults?.impactingScopes[1]?.impactDifferencePercent) +'%'}" *ngIf="comparisonResults?.impactingScopes[1].impactDifference>0"></div>
                                    <div class="bottle-side ci-green bs-top" [ngStyle]="{'height': (comparisonResults?.impactingScopes[1]?.impactDifferencePercent>-100?(comparisonResults?.impactingScopes[1]?.impactDifferencePercent*-1):100) +'%'}" *ngIf="comparisonResults?.impactingScopes[1].impactDifference<0"></div>
                                    <div class="bottle-side ci-grey bs-bottom" [ngStyle]="{'height': (comparisonResults?.impactingScopes[1]?.impactDifferencePercent>100?(100-comparisonResults?.impactingScopes[1]?.impactDifferencePercent):0) +'%'}" *ngIf="comparisonResults?.impactingScopes[1].impactDifference>0"></div>
                                    <div class="bottle-side ci-grey bs-bottom" [ngStyle]="{'height': (comparisonResults?.impactingScopes[1]?.impactDifferencePercent>-100?(100-(comparisonResults?.impactingScopes[1]?.impactDifferencePercent*-1)):0) +'%'}" *ngIf="comparisonResults?.impactingScopes[1].impactDifference<0"></div>
                                    <div class="overlay-bottle-data">
                                        <label>Compared to baseline</label>
                                        <label>{{comparisonResults?.impactingScopes[1].impactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact <br>{{comparisonResults?.compareItems[1]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[2]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">{{comparisonResults?.impactingScopes[2].highScopeName}}</label>
                            <label for="" class="value">{{comparisonResults?.impactingScopes[2].highScopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="big-bottle">
                                <label class="bot-top-value top-red" *ngIf="comparisonResults?.impactingScopes[2].impactDifference>0">{{comparisonResults?.impactingScopes[2]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <label class="bot-top-value top-green" *ngIf="comparisonResults?.impactingScopes[2].impactDifference<0">{{comparisonResults?.impactingScopes[2]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <label class="bot-top-value top-grey" *ngIf="comparisonResults?.impactingScopes[2].impactDifference==0">{{comparisonResults?.impactingScopes[2]?.impactDifferencePercent | number:'1.0-2'}}%</label>
                                <div class="bottle">
                                    <div class="bottle-side ci-red bs-top" [ngStyle]="{'height': (comparisonResults?.impactingScopes[2]?.impactDifferencePercent>100?100:comparisonResults?.impactingScopes[2]?.impactDifferencePercent) +'%'}" *ngIf="comparisonResults?.impactingScopes[2].impactDifference>0"></div>
                                    <div class="bottle-side ci-green bs-top" [ngStyle]="{'height': (comparisonResults?.impactingScopes[2]?.impactDifferencePercent>-100?(comparisonResults?.impactingScopes[2]?.impactDifferencePercent*-1):100) +'%'}" *ngIf="comparisonResults?.impactingScopes[2].impactDifference<0"></div>
                                    <div class="bottle-side ci-grey bs-bottom" [ngStyle]="{'height': (comparisonResults?.impactingScopes[2]?.impactDifferencePercent>100?(100-comparisonResults?.impactingScopes[2]?.impactDifferencePercent):0) +'%'}" *ngIf="comparisonResults?.impactingScopes[2].impactDifference>0"></div>
                                    <div class="bottle-side ci-grey bs-bottom" [ngStyle]="{'height': (comparisonResults?.impactingScopes[2]?.impactDifferencePercent>-100?(100-(comparisonResults?.impactingScopes[2]?.impactDifferencePercent*-1)):0) +'%'}" *ngIf="comparisonResults?.impactingScopes[2].impactDifference<0"></div>
                                    <div class="overlay-bottle-data">
                                        <label>Compared to baseline</label>
                                        <label>{{comparisonResults?.impactingScopes[2].impactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[2]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">SCOPE IMPACT CONTRIBUTION
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">The graph below illustrates the contribution of each scope to the total carbon footprint. Move your cursor over to see the percentage of each scope.</p>
                <div class="labels-container">
                    <div class="label-element">
                        <div class="color-indicator ci-azure">&nbsp;</div>
                        <label >scope 1</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element">
                        <div class="color-indicator ci-lightblue">&nbsp;</div>
                        <label >scope 2</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element">
                        <div class="color-indicator ci-darkblue">&nbsp;</div>
                        <label >scope 3</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                </div>
            </div>
            <div class="data-item" style="width: 950px;" *ngIf="footPrintData?.length > 0">
                <canvas baseChart style="height: 200px !important;"
                    [datasets]="barChartDataScopes"
                    [labels]="barChartLabelScopes"
                    [options]="barChartOptionScopes"
                    [legend]="barChartLegendScopes"
                    [chartType]="barChartTypeScopes"
                    [colors]="Scopescolors">
                </canvas>
            </div>
        </div>
    </div>


    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">MOST IMPACTING SCOPE
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">Scope with the highest contribution.</p>
                <label class="param-name pram-title pt-mt30">Scope 1</label>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[0]?.productName}}</label>
                        <label class="product-label">Baseline</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[0].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[0].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[0].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[0].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data" style="text-align: center; width: 100%;">
                                            <label>Baseline</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact <br>{{comparisonResults?.compareItems[0]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[1]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Scope total impact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-bottle ml-3">
                                    <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Compared to baseline</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[1]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[2]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Scope total impact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-bottle ml-3">
                                    <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Compared to baseline</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[0]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[2]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title pt-mt40">Scope 2</label>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[0]?.productName}}</label>
                        <label class="product-label">Baseline</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[0].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[0].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[0].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[0].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data" style="text-align: center; width: 100%;">
                                            <label>Baseline</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact <br>{{comparisonResults?.compareItems[0]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[1]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Scope total impact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-bottle ml-3">
                                    <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Compared to baseline</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[1]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[2]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="bottle-container">
                                <div class="more-bottles">
                                    <div class="big-bottle">
                                        <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactPercent | number:'1.0-2'}}%</label>
                                        <div class="bottle">
                                            <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactPercent)+'%'}"></div>
                                            <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactPercent+'%'}"></div>
                                            <div class="overlay-bottle-data">
                                                <label>Scope total impact</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="big-bottle ml-3">
                                        <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                        <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                        <div class="bottle">
                                            <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                            <div class="bottle-side ci-green bs-bottom" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                            <div class="overlay-bottle-data">
                                                <label>Compared to baseline</label>
                                                <label *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                                <label *ngIf="comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[1]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[2]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">

                <label class="param-name pram-title pt-mt40">Scope 3</label>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[0]?.productName}}</label>
                        <label class="product-label">Baseline</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[0].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[0].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[0].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[0].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data" style="text-align: center; width: 100%;">
                                            <label>Baseline</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact <br>{{comparisonResults?.compareItems[0]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[1]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1]?.scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Scope total impact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-bottle ml-3">
                                    <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-green bs-bottom" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference<=0" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-red bs-bottom"  *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference>0"  [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Compared to baseline</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[1].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[1]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item vertical col28 v-center" *ngIf="screeningResults?.length==3">
                <div class="comparison-product-container">
                    <div class="cp-title-container">
                        <label class="product-name">{{comparisonResults?.compareItems[2]?.productName}}</label>
                        <label class="product-label">&nbsp;</label>
                    </div>
                    <div class="params-data-content">
                        <div class="param-value">
                            <label for="" class="scope">&nbsp;</label>
                            <label for="" class="value">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpact | number:'1.0-2'}}</label>
                            <label for="" class="unit">kg CO<sub>2</sub> eq</label>
                        </div>
                        <div class="bottle-container">
                            <div class="more-bottles">
                                <div class="big-bottle">
                                    <label class="bot-top-value top-darkblue">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactPercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactPercent)+'%'}"></div>
                                        <div class="bottle-side ci-darkblue bs-bottom" [ngStyle]="{'height': comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactPercent+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Scope total impact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-bottle ml-3">
                                    <label class="bot-top-value top-green" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference<=0"> -{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <label class="bot-top-value top-red" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference>0"> +{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent | number:'1.0-2'}}%</label>
                                    <div class="bottle">
                                        <div class="bottle-side ci-grey bs-top" [ngStyle]="{'height': (100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent<0?0:100-comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-green bs-bottom" *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference<=0" [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="bottle-side ci-red bs-bottom"  *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference>0"  [ngStyle]="{'height': (comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent>100?100:comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifferencePercent)+'%'}"></div>
                                        <div class="overlay-bottle-data">
                                            <label>Compared to baseline</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference<0">{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                            <label *ngIf="comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference>0">+{{comparisonResults?.scopeImpactContributions[2]?.scopeProductImpacts[2].scopeImpactDifference | number:'1.0-2'}}<br>kg CO<sub>2</sub>eq</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="bot-bottom-value">Total impact<br>{{comparisonResults?.compareItems[2]?.carbonFootPrint | number:'1.0-2'}} kg CO2 eq</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>




    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">SCOPE DISTRIBUTION IN EACH LIFE CYCLE STAGE
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">The graph below illustrates the contribution of each scope to the different life cycle stages. Move your cursor over to see the carbon footprint of each life cycle stage.</p>
                <div class="labels-container">
                    <div class="label-element">
                        <div class="color-indicator ci-azure">&nbsp;</div>
                        <label class="no-space">{{comparisonResults?.compareItems[0]?.productName}}</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element">
                        <div class="color-indicator ci-lightblue">&nbsp;</div>
                        <label >{{comparisonResults?.compareItems[1]?.productName}}</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                    <div class="label-element" *ngIf="screeningResults?.length==3">
                        <div class="color-indicator ci-darkblue">&nbsp;</div>
                        <label >{{comparisonResults?.compareItems[2]?.productName}}</label>
                        <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                    </div>
                </div>
            </div>
            <div class="data-item row charts-row">
                <div class="scope-chart-container1">
                    <p class="scope-chart-title1">Scope 1</p>
                <div style="width: 100%;" *ngIf="footPrintData?.length > 0">
                    <canvas baseChart
                        [datasets]="scope1BarChartDataScopeDistribution"
                        [labels]="barChartLabelsScopeDistribution"
                        [options]="barChartOptionsScopeDistribution"
                        [legend]="barChartLegendScopeDistribution"
                        [chartType]="barChartTypeScopeDistribution"
                        [colors]="ScopeDistributioncolors">
                    </canvas>
                </div>
                </div>
                <div class="scope-chart-container1">
                    <div style="width: 100%">
                        <p class="scope-chart-title1">Scope 2</p>
                            <canvas baseChart
                                [datasets]="scope2BarChartDataScopeDistribution"
                                [labels]="barChartLabelsScopeDistribution"
                                [options]="barChartOptionsScopeDistribution"
                                [legend]="barChartLegendScopeDistribution"
                                [chartType]="barChartTypeScopeDistribution"
                                [colors]="ScopeDistributioncolors">
                            </canvas>
                        </div>
                </div>
                <div class="scope-chart-container1">
                    <div style="width: 100%">
                        <p class="scope-chart-title1">Scope 3</p>
                            <canvas baseChart
                                [datasets]="scope3BarChartDataScopeDistribution"
                                [labels]="barChartLabelsScopeDistribution"
                                [options]="barChartOptionsScopeDistribution"
                                [legend]="barChartLegendScopeDistribution"
                                [chartType]="barChartTypeScopeDistribution"
                                [colors]="ScopeDistributioncolors">
                            </canvas>
                        </div>
                </div>
            </div>
        </div>
    </div>


    <div class="data-container dc-emission" *ngIf="comparisonResults.hasScopeResult">
        <div class="params-data-row">
            <div class="data-item vertical col16">
                <label class="param-name pram-title">THE SCOPES FIVE MOST IMPACTFUL PARAMETERS
                    <em class="icon-info" [ngbPopover]="popupContent2" triggers="hover" placement="right" *ngIf=false></em>
                </label>
                <p class="as-description mt15">The graph below illustrates the contribution of each scope to the different life cycle stages. Move your cursor over to see the carbon footprint of each life cycle stage.</p>
            </div>
            <div class="data-items-container-1">
                <div class="data-item " style="width: 100%;" *ngFor="let scopeParameterDistribution of comparisonResults?.scopeParameterDistributions; let i = index" >
                    <div class="custom-chart-container">
                        <label class="scope-name">{{scopeParameterDistribution?.scopeName}}</label>
                        <div class="scope-chart-container">
                            <div class="product-name-list">
                                <label>{{comparisonResults?.compareItems[0]?.productName}}</label>
                                <label>{{comparisonResults?.compareItems[1]?.productName}}</label>
                                <label *ngIf="screeningResults?.length==3">{{comparisonResults?.compareItems[2]?.productName}}</label>
                            </div>
                            <div class="scaled-scopes">
                                <div class="scopes-chart">
                                    <div class="vb" style="margin-left: 25%;"></div>
                                    <div class="vb" style="margin-left: 50%;"></div>
                                    <div class="vb" style="margin-left: 75%;"></div>
                                    <div class="bar-container">
                                        <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[0].scopeParameterImpactContributions;let j=index">
                                            <div class="bar-content" [ngStyle]="{'width': item.parameterScopeImpactPercent+'%','background-color':sampleDataProductColors[j]}">
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="bar-container">
                                        <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[1].scopeParameterImpactContributions;let j=index">
                                            <div class="bar-content" [ngStyle]="{'width': item.parameterScopeImpactPercent+'%','background-color':sampleDataProductColors[j]}">
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="bar-container" *ngIf="screeningResults?.length==3">
                                        <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[2].scopeParameterImpactContributions;let j=index">
                                            <div class="bar-content" [ngStyle]="{'width': item.parameterScopeImpactPercent+'%','background-color':sampleDataProductColors[j]}">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="scale-container">
                                    <label>0%</label>
                                    <label>25%</label>
                                    <label>50%</label>
                                    <label>75%</label>
                                    <label>100%</label>
                                </div>
                            </div>
                            <div class="product-labels-list">
                                <label *ngFor="let productParameterScopesImpact of scopeParameterDistribution.productParameterScopesImpacts">{{productParameterScopesImpact?.scopeImpact}} kg CO<sub>2</sub>eq</label>
                            </div>
                        </div>

                        <label class="params-impact">PARAMETERS IMPACT</label>
                        <div class="impact-labels-container">
                            <div class="impact-labels-group">
                                <label class="title">{{comparisonResults?.compareItems[0]?.productName}}<span class="subtitle">(Baseline)</span></label>
                                <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[0].scopeParameterImpactContributions;let i=index">
                                    <div class="impact-element" [ngStyle]="{'background-color': (i%2==0?'#F2F2F2':'white')}">
                                        <div class="color-indicator" [ngStyle]="{'background-color': sampleDataProductColors[i]}"></div>
                                        <label style="margin-left: 8px;">{{i+1}}.&nbsp;</label>
                                        <div class="data-box">
                                            <label>{{item.parameterName}}</label>
                                            <label>{{item.parameterScopeImpactPercent| number: '1.0-2'}}% ({{item.scopeImpact| number: '1.0-2'}} kg CO2eq)</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="impact-labels-group">
                                <label class="title">{{comparisonResults?.compareItems[1]?.productName}}</label>
                                <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[1].scopeParameterImpactContributions;let i=index">
                                    <div class="impact-element" [ngStyle]="{'background-color': (i%2==0?'#F2F2F2':'white')}">
                                        <div class="color-indicator" [ngStyle]="{'background-color': sampleDataProductColors[i]}"></div>
                                        <label style="margin-left: 8px;">{{i+1}}.&nbsp;</label>
                                        <div class="data-box">
                                            <label>{{item.parameterName}}</label>
                                            <label>{{item.parameterScopeImpactPercent| number: '1.0-2'}}% ({{item.scopeImpact| number: '1.0-2'}} kg CO2eq)</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="impact-labels-group" *ngIf="screeningResults?.length==3">
                                <label class="title">{{comparisonResults?.compareItems[2]?.productName}}</label>
                                <ng-container *ngFor="let item of scopeParameterDistribution.productParameterScopesImpacts[2].scopeParameterImpactContributions;let i=index">
                                    <div class="impact-element" [ngStyle]="{'background-color': (i%2==0?'#F2F2F2':'white')}">
                                        <div class="color-indicator" [ngStyle]="{'background-color': sampleDataProductColors[i]}"></div>
                                        <label style="margin-left: 8px;">{{i+1}}.&nbsp;</label>
                                        <div class="data-box">
                                            <label>{{item?.parameterName}}</label>
                                            <label>{{item?.parameterScopeImpactPercent | number: '1.0-2'}}% ({{item?.scopeImpact | number: '1.0-2'}} kg CO2eq)</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>






















</div>

<ng-template #popupContent>
    <div class="pop-container">
        <div class="pop-header d-flex">
            <em class="icon-info bigger-info"></em>
            <label class="pop-title">Carbon footprint Reduction
                <em class="icon-re_stage circular"></em>
            </label>

        </div>
        <div class="sections-container">
            <div class="basic-section">
                <label for="" class="section-title">Description:</label>
                <p class="section-description">This indicator shows the difference between the baseline and the compared data .
                </p>
            </div>

            <div class="tips-section mt24">
                <img src="../../../../../assets/idea.png" alt="">
                <div class="tips-container">
                    <p class="tip-text ml-2">
                        <span style="font-weight: bold !important;">Tip</span>: This indicator should be used with
                        caution since it is assumed that the usage and
                        distribution of the product remain the same. This is not always the case since the mass
                        or performance of a product may change after remanufacturing, refurbishment, or
                        reconditioning.
                    </p>

                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #popupContent2>
    <div class="pop-container">
        <div class="pop-header d-flex">
            <em class="icon-info bigger-info"></em>
            <label class="pop-title">Carbon Footprint Difference
                <em class="icon-re_stage circular"></em>
            </label>

        </div>
        <div class="sections-container">
            <div class="basic-section">
                <label for="" class="section-title">Description:</label>
                <p class="section-description">This indicator shows the difference between the baseline and the compared data.
                    <a>(Read more)</a>
                </p>
            </div>

            <div class="tips-section mt24">
                <img src="../../../../../assets/idea.png" alt="">
                <div class="tips-container">
                    <p class="tip-text ml-2">
                        <span style="font-weight: bold !important;">Tip</span>: This indicator should be used with
                        caution since it is assumed that the usage and distribution of the product remain the same.
                        This is not always the case since the mass or performance of a product may change after
                        remanufacturing, refurbishment, or reconditioning.
                    </p>

                </div>
            </div>
        </div>
    </div>
</ng-template>
