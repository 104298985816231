<div class="modals">
    <em class="icon-close" (click)="cancel($event)"></em>
    <div class="main-modal-content">
        <div class="row">
            <div class="col-12  centered">
                <label class="modal-title">Prolonged lifespan: {{stage}}</label>
            </div>
        </div>

        <div class="row">
            <p class="modal-header">
                Please choose the prolonged lifespan from the {{stage}}
            </p>
        </div>
        <div class="slidecontainer">
            <input type="range" min="0" max="{{maxValue}}" value="{{yearValue}}" class="slider" id="myRange" #year (input)="setYears(year.value)">
        </div>
        <p class="year-min">&nbsp; 0 Years</p>
        <p class="year-max">&nbsp; {{maxValue}} Years</p>
        <div class="years-container">
            <p class="year-text">{{yearValue}}</p>
            <p class="year-note">&nbsp; Years</p>
        </div>

    </div>
    <div class="main-modal-footer">
        <button class="cancel-button">
            <label class="button-lable" >
                Cancel
            </label>
          </button>
        <button class="confirm-button" (click)="confirm()">
            <label class="button-lable">
                Confirm
            </label>
          </button>
    </div>
</div>
<div class="overlay"></div>