import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScreeningService } from '../../../services/screening.service';
import { ShareService } from '../../../services/share.service';

@Component({
  selector: 'app-shared-with-me',
  templateUrl: './shared-with-me.component.html',
  styleUrls: ['./shared-with-me.component.scss']
})
export class SharedWithMeComponent implements OnInit {
  @Output() sharedFolderEvent =new EventEmitter<any>();
  @Output() selectProject =new EventEmitter<any>();
  public searchList:any[]=[];
  public openImportModal = false;
  public porjectsFilter={
    private:false,
    public:false,
    notShared:false
  };
  public projects=[];
  public projectsOfFolder=[];
  public myPorjectsSubfolders=[];
  public myPorjectsSubprojects:any[]=[];
  public selectedSubFolder=null;
  public selectedFolder='';
  public selectedProject=null;
  public relativeScreening=null;
  public showImportModal:boolean=false;
  public filterParameter='';
  public searchText="";
  public searchTriggered=false;
  public canShowFolders = false;
  constructor(
    private shareService:ShareService,
    private screeningService:ScreeningService
  ) { }

  ngOnInit(): void {
    this.shareService.getComanyShared(Number(localStorage.getItem("company_id"))).subscribe(ans=>{
      this.myPorjectsSubfolders=ans
      this.myPorjectsSubfolders.forEach(x=>{
        this.myPorjectsSubprojects=[...this.myPorjectsSubprojects,x?.showImportedScreeningDtos]
      })
      this.myPorjectsSubprojects=this.myPorjectsSubprojects[0]
      this.canShowFolders = true;
    })

  }

  public selectFolder(name:string){
    this.selectedFolder==name?this.selectedFolder="":this.selectedFolder=name;
    if(this.selectedFolder=="My projects"){
      //this.projects=this.myPorjectsSubprojects;
    }else if(this.selectedFolder=="Data subscribers"){

    }else{
      this.projects=this.myPorjectsSubfolders?.filter(x=>x['folderName']==name)[0]['showImportedScreeningDtos'];
      this.projectsOfFolder=this.projects;
      if(this.filterParameter=='public'){
        this.projects=this.projectsOfFolder.filter(x=>x['publicShared']==true);
      }else if(this.filterParameter=='private'){
        this.projects=this.projectsOfFolder.filter(x=>x['privateShared']==true);
      }
    }
  }

  public openImportModalFunction(){
    this.openImportModal = true;
  }

  public closeImportModalFunction(){
    this.openImportModal = false;
  }

  public openNewFolderModalFunction():void{
    this.sharedFolderEvent.emit("open-folder-modal");
  }

  public importModalActions(event:any){
    if(event==null){
      this.showImportModal=true;
      return;
    }
    else if(event.close==true)
    {
      this.showImportModal=false;
    }
  }

  public selectingProject(project){
    this.selectedProject=project;
    this.selectProject.emit(project);
  }

  public filter(type){
    this.filterParameter==type?this.filterParameter='':this.filterParameter=type;
    if(this.filterParameter=='public'){
      this.projects=this.projectsOfFolder.filter(x=>x['publicShared']==true);
    }else if(this.filterParameter=='private'){
      this.projects=this.projectsOfFolder.filter(x=>x['privateShared']==true);
    }else{
      this.projects=this.projectsOfFolder
    }
  }


  public makeSearch(){
    var searchKeywords=this.searchText.split(" ")
    var occured:any[]=[];
    for(var i=0;i<this.myPorjectsSubprojects.length;i++){
      for(var j=0;j<searchKeywords.length;j++){
        if(isNaN(searchKeywords[j].charCodeAt(0)))continue;

        if((this.myPorjectsSubprojects[i]["sharedProjectName"]+'').toLocaleLowerCase().includes(searchKeywords[j])){
          var x=this.myPorjectsSubprojects[i];
          if(occured.indexOf(this.myPorjectsSubprojects[i])==-1){
            x.occ=1;
            occured.push(x);
          }else{
            x.occ+=1;
          }
        }
      }
    }
    occured.sort(({occ:a}, {occ:b}) => b-a);
    this.searchList=occured
  }
}
