<nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent; padding-top: 30px;">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="margin-left: 20px;">
        <label class="clicked-content-title">Platform updates</label>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <div class="row float-right">
          <div class="col-md-4">
            <button class="btn-solid primary" (click)="showAddModal(true)">
              <em class="fa fa-plus"></em>New Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </nav>
  <div class="row">
    <table class="re-table">
      <caption></caption>
      <thead>
        <tr>
          <th id="" >Updated Module</th>
          <th id="" >Update Description</th>
          <th id="" >Update Date</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          *ngFor=" let pu of platformUpdates ">
          <td>{{ pu.updateModule }}</td>
          <td >{{ pu.updateDescription | slice:0:45 }}...</td>
          <td>{{ pu.updatedAt | date }}</td>
          <td>
            <em *ngIf="false" class="fa fa-bars" (click)="showEditModal(true,pu)"></em>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-edit-platform-update (toogleModal)="showAddModal($event)" [showForm]="showEditView" [puToUpdate]="platformUpdate" *ngIf="showEditView">
  </app-edit-platform-update>