import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rq-pass-questionnaire',
  templateUrl: './rq-pass-questionnaire.component.html',
  styleUrls: ['./rq-pass-questionnaire.component.scss']
})
export class RqPassQuestionnaireComponent implements OnInit {

  @Input() modalTitle: string = '';
  @Output() closePassModal:any= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public close(){
    this.closePassModal.emit(false);
  }

}

