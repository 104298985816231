import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-sub-group',
  templateUrl: './edit-sub-group.component.html',
  styleUrls: ['./edit-sub-group.component.scss']
})
export class EditSubGroupComponent implements OnInit {

  @ViewChild('modal',{static: true}) modal:ElementRef;
  @Input() operation:any;
  @Input() currentSubGr:any;
  @Output() closeModal=new EventEmitter<any>();
  public subGroupToEdit: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.modal.nativeElement.toggleClass="move-to-left";
    
    this.subGroupToEdit = this.fb.group({
      id:[this.currentSubGr?this.currentSubGr.id:""],
      subGrName:[this.currentSubGr?this.currentSubGr.subGrName:""],
     
    });
  }

  public dismissModal(){
    this.closeModal.emit(null);
  }

  public upsert(){

    var db=this.subGroupToEdit.value;
    if(!this.currentSubGr){
      db.id=Date.now();
      //store database
    }else{
      //update database
    }
    this.closeModal.emit(this.subGroupToEdit.value);
  }
}
