import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-new-folder',
  templateUrl: './shared-new-folder.component.html',
  styleUrls: ['./shared-new-folder.component.scss']
})
export class SharedNewFolderComponent implements OnInit {
  @Output() sharedFolderEvent =new EventEmitter<any>();
  public folderName:String="";

  constructor() { }

  ngOnInit(): void {
  }

  public closeNewFolderModal():void{

  }

  public closeModal():void{
    this.sharedFolderEvent.emit("close-folder-modal");
  }

  public save():void{
    this.sharedFolderEvent.emit("close-folder-modal");
  }

}
