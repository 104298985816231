import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Co2LightService } from "src/app/services/co2-light.service";

@Component({
  selector: "app-light-calculator",
  templateUrl: "./light-calculator.component.html",
  styleUrls: ["./light-calculator.component.scss"],
})
export class LightCalculatorComponent implements OnInit {
  public co2Form: any;
  public scrolled: boolean = false;
  public showTutorial: boolean = false;
  public vesselArea: string = "";
  public vesselAge: number = 1;
  @Output() submitForm = new EventEmitter();
  @Output() submitAttemp = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public toastr: ToastrService,
    public co2LightService: Co2LightService
  ) {}

  ngOnInit(): void {
    this.co2Form = this.fb.group({
      vesselType: ["", Validators.required],
      vesselArea: ["", Validators.required],
      vesselAge: [0, Validators.required],
      componentGroup: ["", Validators.required],
      lifeSpan: [0, Validators.required],
      componentWeight: [0, Validators.required],
      componentScenario: ["", Validators.required],
    });
  }
  public onContainerScroll(vessel, event): void {
    if (!this.scrolled) {
      this.scrolled = true;
      event.scrollIntoView({ behavior: "smooth" });
    }
  }

  public getGroup(event): void {
    this.co2Form.patchValue({
      componentGroup: event,
    });
  }
  public getSpan(event): void {
    this.co2Form.patchValue({
      lifeSpan: Number(event),
    });
  }
  public getWeight(event): void {
    this.co2Form.patchValue({
      componentWeight: Number(event),
    });
  }
  public getType(event): void {
    this.co2Form.patchValue({
      vesselType: event,
    });
  }
  public getArea(event): void {
    this.vesselArea = event;
    this.co2Form.patchValue({
      vesselArea: event,
    });
  }
  public getAge(event): void {
    this.vesselAge = Number(event);
    this.co2Form.patchValue({
      vesselAge: Number(event),
    });
  }
  public getScenario(event): void {
    this.co2Form.patchValue({
      componentScenario: event,
    });
  }

  public scrollDown(event): void {
    event.scrollIntoView({ behavior: "smooth" });
  }

  public calculate(): void {
    let userId: number = Number(localStorage.getItem("user_id"));
    if (this.co2Form.valid == true) {
      this.co2LightService.getAttemps(userId).subscribe((res) => {
        if (res.result == "failed") {
          this.submitAttemp.emit(true);
        } else {
          this.submitForm.emit(this.co2Form.value);
          this.submitAttemp.emit(false);
        }
      });
    } else {
      this.toastr.error(
        "We are missing some data above to calculate your result. Please fill it out",
        "Data missing"
      );
    }
  }

  public closeTutorial():void{
    this.showTutorial=false;
  }

  public openTutorial():void{
    this.showTutorial=true;
  }
}
