<div class="logout-container">
    <label class="h1-heading">Are you still there? </label>
    <label class="h3-heading">You have been logged out due to inactivity but don't worry. You're data has been saved.</label>
    <button class="big-turq-outline" (click)="logout()">
        <label>Logout</label>
    </button>
    <button class="big-turq" (click)="close()">
        <label>Continue</label>
    </button>
</div>
 