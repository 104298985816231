import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { ShareService } from '../../../services/share.service';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent implements OnInit {

  @Output() toogleRequestModal = new EventEmitter();
  @Input() showRequestView: boolean = false;
  @Input() requestedProject=null; 
  public customData: FormGroup;
  public inquired: FormGroup;
  public done:boolean=false;
  public phase:{};

  public existingCompanies:any=[];
  public toBeRequested:any=[];
  public baseData:any=[];
  public canSelect=true;
  public canCreateInvitation=false;
  public searchText="";
  public fillRequestData=false;
  constructor(
    private companyService:CompanyService,
    private shareService:ShareService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.customData = this.fb.group({
      companyName:[""],
      contactPerson:[""],
      contactPersonEmail:[""],
      custom:[true]
    });
    
    this.inquired = this.fb.group({
      productName:[""],
      productNumber:[""],
      message:[""],
    });
    this.companyService.getSuppliers().subscribe(ans=>{
      this.existingCompanies=ans;
      this.baseData=this.existingCompanies;
    })
  }

  public close(){
    this.toogleRequestModal.emit(null)
  }

  public filterCompanies(){
    this.existingCompanies=this.baseData.filter(x=>x.name.includes(this.searchText))
  }

  public addCustomCompany(){
    this.toBeRequested.push(this.customData.value)
    this.customData = this.fb.group({
      companyName:[""],
      contactPerson:[""],
      contactPersonEmail:[""],
      custom:[true]
    });
  }

  public selectCompany(company){
    this.baseData=this.baseData.filter(x=>x!=company);
    this.existingCompanies=this.existingCompanies.filter(x=>x!=company);
    this.toBeRequested.push(company);
  }

  public discardCompany(company){
    this.toBeRequested=this.toBeRequested.filter(x=>x!=company);
    this.baseData.push(company);
    this.existingCompanies=this.baseData;
  }

  public submit(){
    let x;
    this.toBeRequested.forEach(element => {
      x={
        requestingCompanyId:localStorage.getItem('company_id'),
        inquiredCompanyId:element.id,
        inquiredCompanyName:null,
        inquiredContactPerson:null,
        productName:this.inquired.value['productName'],
        productNumber:this.inquired.value['productNumber'],
        message:this.inquired.value['message']
      }
      if(element.hasOwnProperty('custom')==true){

        x={
          requestingCompanyId:localStorage.getItem('company_id'),
          inquiredCompanyId:0,
          inquiredCompanyName:element.companyName,
          inquiredContactPerson:element.contactPersonEmail,
          productName:this.inquired.value['productName'],
          productNumber:this.inquired.value['productNumber'],
          message:this.inquired.value['message']
        }
      }
     
      
      this.shareService.requestData(x).subscribe(ans=>{

      })
    });
    
    this.done=true;
  }
}
