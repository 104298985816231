import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { CompanyService } from 'src/app/services/company.service';
import { Chart } from "chart.js";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit {
  @ViewChild("lineChart", { static: true }) private chartRef;

  public companyId:number;
  public companyUsers: User[];
  public credits: any[];
  public showForm:boolean=false;
  public showDelete:boolean=false;
  public submitted:boolean=false;
  public userToCreate:FormGroup;
  public userToDelete:User;
  LineChart: any;

  constructor(private companyService:CompanyService, private formBuilder:FormBuilder, private toastr: ToastrService, private userService: UserService) { }

  ngOnInit(): void {
    this.companyId = Number(localStorage.getItem("company_id"));
    this.userToCreate = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      role: ["User"],
      lastLogin: new Date(),
      acceptedTerms: false,
      profilePicture: "",
      fullName: [""],
      title: [""],
      department: [""],
      phoneNumber: [""],
      timer: [0],
      permission: ["Premium"],
      permissionDate: new Date().toISOString().split('T')[0],
      companyId: this.companyId,
      access: this.formBuilder.array([]),
    });
    this.userToCreate.get("access").value.push({access:"Gaia Project"});
    this.showUserList();
    this.companyService.getCreditsOverview(this.companyId).subscribe(res=>{
      this.credits=res;
      this.LineChart = new Chart("lineChart", {
        type: "line",
        data: {
          labels: ["January","February","March","April","May","June","July","August","September","October","November","December"],
          datasets: [
            {
              data: res,
              fill: false,
              lineTension: 0.2,
              borderColor: "#97D6D4",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            text: "Line Chart",
            display: false,
          },
          legend: {
            display: false,
            labels: {
              fontColor: "#00000",
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "black",
                  maxTicksLimit: 3,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "black",
                },
              },
            ],
          },
        },
      });
    });
  }

  public showUserList():void{

    this.companyService.getCompanyUsers(this.companyId).subscribe(res => {
      this.companyUsers = res.filter((x:User)=>x.id!=Number(localStorage.getItem("user_id")));
    });
  }

  public showUserForm():void{
    this.showForm=true;
  }

  public addUser():void{
    this.submitted = true;
    if(!this.userToCreate.valid){
      return
    }
    this.userService.addCompanyUser(this.userToCreate.value).subscribe((res) => {
      this.toastr.success("User", "User informations saved succefuly!");
      this.showUserList();
      this.hideForm();
    });
  }

  public hideForm():void{
    this.showForm=false;
  }

  public closeModal():void{
    this.showDelete=false;
  }

  public showDeleteModal(user):void{
    this.showForm=false;
    this.userToDelete=user;
    this.showDelete=true;
  }

  public confirmDelete():void{
    this.userService.deleteUser(this.userToDelete.id).subscribe(res=>{
      this.showUserList();
      this.closeModal();
    });
  }

  get frm() {
    return this.userToCreate.controls;
  }

}
